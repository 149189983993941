import React, { useState } from 'react';

import { Button } from 'antd';
import { observer } from 'mobx-react-lite';

import { translate } from 'modules/localization';
import GeneratorCreateModal from 'modules/routeGenerator/components/RouteGeneratorCreateModal';
import { useStore } from 'services/store';

const RouteSetup = () => {
  const { router, core, routeGenerator } = useStore();

  const [isModalOpened, toggleModal] = useState(false);

  const redirect = () => router.push('/arm2/routes/create');
  const closeModal = (): void => toggleModal(false);
  const openModal = (): void => toggleModal(true);

  const onCancelHandler = (): void => closeModal();
  const onSubmitHandler = (): void => {
    closeModal();
    redirect();
  };

  const openGenerator = async (e: React.MouseEvent) => {
    e.preventDefault();
    if (Array.isArray(routeGenerator.routeDraftList) && routeGenerator.routeDraftList.length > 0) {
      redirect();
    } else {
      openModal();
    }
  };

  return core.permissions['routes.create'] ? (
    <>
      <Button type="primary" onClick={openGenerator} data-testid="generator-openSetupModal">
        {translate('routeGenerator')}
      </Button>

      {isModalOpened && (
        <GeneratorCreateModal
          isModalOpened={isModalOpened}
          onSubmit={onSubmitHandler}
          onCancel={onCancelHandler}
          destroyOnClose
        />
      )}
    </>
  ) : null;
};

export default observer(RouteSetup);
