export const zh_translations: Record<string, string> = {
  // sections & entities
  planning: '计划',
  tariffing: '收费标准',
  directory: '参考指南',
  logout: '退出',
  order: '订单',
  orders: '订单',
  ordersList: '订单列表',
  orderStatus: '订单状态',
  orderDeliveryDateTime: '等待时间',
  orderSizes: '货物参数',
  orderType: '类型',
  orderDetails: '订单详情',
  taskTitle: '任务',
  tasks: '任务',
  tasksList: '任务列表',
  taskType: '任务类型',
  taskStatus: '任务状态',
  taskDeliveryDateTime: '等待时间',
  taskPlannedDeliveryDateTime: '计划时间',
  taskRealDeliveryDateTime: '实际时间',
  taskSizes: '货物参数',
  removeTaskFromRoute: '从路线中删除',
  cancelTheTask: '取消任务',
  taskCancelation: '取消任务',
  finishTheTask: '完成',
  route: '路线',
  routes: '路线',
  routesList: '路线列表',
  routeStatus: '路线列表',
  routeStartDate: '开始日期',
  routeGenerator: '路线生成',
  routePlannedDateTime: '计划时间',
  routeRealDateTime: '实际时间',
  routeCreated: '路线已创建',
  routeFormTitle: '制定路线',
  routeCreateTitle: '创建路线',
  routeCalculated: '路线已计算',
  routeDelete: '删除路线',
  addTasksToRoute: '添加入路线',
  courier: '快递员',
  couriers: '快递员',
  couriersList: '快递员列表',
  courierEditSuccess: '快递员更改成功',
  courierCreateSuccess: '快递员创建成功',
  courierEditTitle: '编辑快递员',
  courierCreateTitle: '添加快递员',
  deliveryMethod: '运送方式',
  deliveryMethods: '运送方式',
  allDeliveryMethod: '全部运送方式',
  withoutDeliveryMethod: '无法运送',
  deliveryMethodEditSuccess: '运送方式更改成功',
  deliveryMethodCreateSuccess: '运送方式创建成功',
  deliveryMethodEditTitle: '编辑运送方式',
  deliveryMethodCreateTitle: '添加运送方式',
  deliveryMethodsParams: '运送方式的参数',
  deliveryMethodTitle: '运送方式的名称',
  deliveryMethodCode: '运送方式的代码',
  clientGroup: '客户组',
  clientGroups: '客户组',
  clientGroupEditSuccess: '客户组更改成功',
  clientGroupCreateSuccess: '客户组创建成功',
  clientGroupEditTitle: '编辑客户组',
  clientGroupCreateTitle: '添加客户组',
  warehouse: '仓库',
  warehouses: '仓库',
  warehouseEdited: '仓管更改成功',
  warehouseCreated: '仓库创建成功',
  warehouseEditTitle: '编辑仓库',
  warehouseCreateTitle: '添加仓库',
  warehouseHasNoCoords: '缺少仓库坐标',
  warehouseSettingsEdited: '仓库设置更改成功',
  client: '客户',
  shop: '店铺',
  shops: '店铺',
  shopEditSuccess: '店铺更改成功',
  shopCreateSuccess: '店铺创建成功',
  shopEditTitle: '编辑店铺',
  shopcCreateTitle: '添加店铺',
  status: '状态',
  transport: '运输工具',
  transportNumber: '车牌号',
  transportWeightWithUnit: '承载能力，公斤',
  transportEditSuccess: '运输工具更改成功',
  transportCreateSuccess: '运输工具创建成功',
  transportEditTitle: '编辑运输工具',
  transportCreateTitle: '添加运输工具',
  user: '用户',
  users: '用户',
  userEdited: '用户已更改',
  userCreated: '用户已创建',
  userEditTitle: '编辑用户信息',
  userCreateTitle: '添加用户',
  privacy: '隐私政策',
  locations: '地点',
  locationsList: '地点列表',
  locationCreateTitle: '添加地点',
  locationEditTitle: '编辑地点',
  locationWillBeSaved: '地点将被保存',
  locationCreated: '地点已被添加',
  locationEdited: '地点已被更改',
  locationDeleted: '地点已被删除',
  rates: '运费',
  ratesList: '运费列表',
  rateDateTimeStart: '运费实行开始',
  rateDateTimeEnd: '运费实行结束',
  rateEditTitle: '编辑运费',
  rateViewTitle: '查看运费',
  rateCreateTitle: '添加运费',
  rateCopyTitle: '复制运费',
  rateCreated: '运费已被添加',
  rateEdited: '运费已被更改',
  rateDeleted: '运费已被删除',
  createRate: '添加运费',
  finishTheRate: '结束运费',
  serviceZone: '服务区',
  serviceZones: '服务区',
  serviceZonesList: '服务区列表',
  withoutServiceZone: '无服务区',
  serviceZoneEditTitle: '编辑服务区',
  serviceZoneViewTitle: '查看服务区',
  serviceZoneCreateTitle: '添加服务区',
  createServiceZone: '添加服务区',
  serviceZoneWillBeSaved: '服务区将被保存',
  serviceZoneCreated: '服务区已被添加',
  serviceZoneEdited: '服务区已被更改',
  serviceZoneDeleted: '服务区已被删除',
  coverages: '覆盖区域',
  coveragesList: '覆盖区域列表',
  allCoverages: '所有覆盖区域',
  coverageDeleted: '覆盖区域已被删除',
  coverageAdded: '覆盖区域已被添加',
  coverageEdited: '覆盖区域已被更改',
  withoutCoverages: '无覆盖区域',
  coverageEditTitle: '编辑覆盖区域',
  coverageViewTitle: '查看覆盖区域',
  coverageCreateTitle: '添加覆盖区域',
  schedules: '时间表',
  scheduleEditTitle: '编辑时间表',
  scheduleViewTitle: '查看时间表',
  scheduleCreateTitle: '添加时间表',
  scheduleDeleted: '时间表已被删除',
  scheduleCopyTitle: '复制时间表',
  sessionsRoutes: '班次路线',
  timeslot: '时间段',
  deliveryTime: '计划或者实际完成任务的时间',
  role: '角色',
  phone: '电话',
  contractor: '生意伙伴',
  contactPerson: '联系人',
  contacts: '联系方式',
  comment: '评论',
  createdDateTime: '创建日期',
  creator: '已创建', // 已创建：V. Sidorov
  resolution: '批注',
  courierSessionOnDate: '由{{content}}的排班',
  courierSessionCreated: '新的班次已设置完成',
  courierSessionEdited: '班次已更改',
  courierSessionDeleted: '班次已删除',
  courierSessionSetup: '新班次的设置',
  courierSessionEditTitle: '编辑班次',
  courierSessionCreateTitle: '添加班次',

  // navigation
  goBackward: '后退',
  goIndexPage: '转到主页面',
  goToCourierSession: '回到班次页面',
  goToServiceZoneCreation: '转到创建服务器页面',
  goToRateCreation: '转到创建运费页面',
  goToCoverageCreation: '转到创建覆盖区域页面',
  goToOrderList: '转到订单列表',
  goToRouteList: '转到路线列表页面',
  goToCourierList: '转到快递员列表页面',
  goToList: '回到列表页面',

  // actions
  authorize: '登录',
  save: '保存',
  calculate: '计算',
  saveAll: '全部保存',
  calculateAll: '全部计算',
  editCourier: '编辑快递员',
  cancelTheRoute: '取消路线',
  cancelTheRoutes: '取消路线',
  add: '添加',
  delete: '删除',
  edit: '编辑',
  createLocation: '添加地点',
  addTask: '添加任务',
  sendForLoading: '前往揽收货物',
  geocode: '地理编码',
  create: '创建',
  selectCourierForRoute: '选择快递员和车辆',
  cleanup: '清理',
  hideInactive: '隐藏无效的',
  editWarehouseSettings: '更改仓库设置',
  addDeliveryMethod: '添加运送方式',
  removeLastDeliveryMethod: '删除最后一种运送方式',
  beginTheSession: '开始轮班',
  endTheSession: '结束轮班',
  refresh: '更新',
  select: '选择',
  apply: '应用',
  showCoveragesByDeliveryMethod: '按照运送方式来展示覆盖区域',
  applyToOtherDays: '适用于一周中的其他日子',
  hidePast: '隐藏过去的',

  // common
  yes: '是',
  no: '否',
  or: '或者',
  cancel: '取消',
  noData: '没有数据',
  nothingWasFound: '没有任何发现',
  error: '错误',
  somethingIsWrong: '出了些问题',
  areYouSure: '您确信？',
  sendToCourier: '派出快递员',
  lastSession: '最后一班',
  saved: '已保存',
  oneMoreCoverage: '还有一个覆盖区域',
  addressTitle: '地址',
  extraTask: '额外任务',
  deliveryDate: '运送日期',
  timeSlotStart: '开始时间段',
  timeSlotEnd: '结束时间段',
  onAddress: '按地址',
  delivery: '运送',
  addressesLength: '地址\u00A0{{content}}',
  tasksLength: '任务\u00A0{{content}}',
  onTheList: '列表',
  onTheMap: '在地图上',
  loadingAtTime: '在 {{content}} 揽收货物',
  comeToWarehouseAtTime: '在 {{content}} 返回仓库',
  sendToLoadTime: '前往揽收货物的时间',
  weightFullness: '按重量装载',
  volumeFullness: '按体积装载',
  filters: '筛选',
  selected: '已选择',
  personalData: '个人信息',
  authentication: '验证',
  parameters: '参数',
  mainWarehouseSettings: '基本设置',
  problem: '有问题的',
  mondayShort: '周一',
  tuesdayShort: '周二',
  wednesdayShort: '周三',
  thursdayShort: '周四',
  fridayShort: '周五',
  saturdayShort: '周六',
  sundayShort: '周日',
  time: '时间',
  period: '有效期',
  number: '路线编号',
  atTime: '在 {{content}}',
  fromTime: '从 {{content}}',
  totalCount: '总共',
  sizes: '尺寸',
  height: '高度',
  heightWithUnit: '高度，米。',
  heightTemplate: '高：{{content}}米。',
  width: '宽度',
  widthWithUnit: '宽度，米。',
  widthTemplate: '宽：{{content}}米。',
  length: '长度',
  lengthWithUnit: '长度，米。',
  lengthTemplate: '长：{{content}}米。',
  type: '类型',
  automatic: '自动',
  changedMyMind: '我改变主意了',
  priceUnitRub: '卢布.',
  averageSpeedUnitKmPerHour: '公里/小时',
  timeUnitDayShort: '天.',
  timeUnitHourShort: '小时.',
  timeUnitMinuteShort: '分钟.',
  weightUnit: '公斤',
  lengthUnit: '米',
  forDate: '在 {{content}}',
  byOrder: '按订单',
  search: '搜索',
  searchByTitle: '按名称搜索',
  searchByLocation: '按地点搜索',
  searchByNumber: '按订单号或路线编号搜索',
  searchByTitleOrNumber: '按名称或车牌号搜索',
  searchByFullNameEmailPhone: '按姓名、邮箱或电话号码搜索',
  searchByTitleAddressPhone: '按名称、地址或电话号码搜索',

  // form fields
  date: '日期',
  from: '开始',
  to: '结束',
  disabled: '无效地',
  activity: '活跃度',
  title: '名称',
  id: '标识符号',
  externalId: '在外部系统中的标识符号',
  isActivated: '已激活',
  isActive: '活跃',
  login: '用户名',
  password: '密码',
  passwordConfirmation: '确认密码',
  sessionPlannedDate: '班次开始日期',
  fullName: '姓名',
  coverageCreation: '创建覆盖区域',
  newCoverage: '新的覆盖区域',
  polygon: '多边形',
  autoDrawPolygon: '绘制多边形',
  autoDraw: '绘制',
  selectAll: '选择全部',
  parentLocation: '主位置',
  positionOnTheMap: '在地图上的位置',
  destination: '目的地',
  sender: '发货人',
  recipient: '收货人',
  plannedDateTime: '预计送达时间',
  taskCancelationReason: '取消任务的原因',
  groupTasksByCoverage: '按照覆盖区域分组',
  dayStart: '工作日开始',
  dayEnd: '工作日结束',
  interval: '区间',
  setUpEqualIntervals: '设置相同的区间',
  orderLimit: '订单限额',
  price: '费用',
  averageSpeed: '平均速度',
  addNewRow: '添加一行',
  shopShortName: '简称',
  shopLegalName: '法定名称',
  shopCurrency: '货币',
  volumeWithUnit: '体积，立方米。',
  loadingTime: '揽收货物时间',
  surname: '姓氏',
  name: '名字',
  patronymic: '父姓',
  userStatus: '用户状态',
  latitude: '纬度',
  longitude: '经度',
  timezone: '时区',
  warehouseGroupId: '仓库组标识符号',
  maxRouteTime: '路线所需的最长时间',
  maxCourierDowntime: '快递员最长间隙休息时间',
  maxDelayTime: '最长迟到时间',
  maxLeadTime: '最长提前到达时间',
  unloadingTime: '卸货时间',
  guardLimit: '受保护的时间区间',
  fullDayRoutes: '使用配送路线填满整个工作日',
  weight: '重量',
  volume: '体积',
  sum: '价格',
  enterTitle: '请输入名称',
  enterId: '请输入标识符号',
  enterLogin: '请输入用户名',
  enterPassword: '请输入密码',
  enterPasswordConfirmation: '请输入密码确认',
  enterTransport: '请选择运输工具',
  enterDate: '请选择日期',
  enterUserName: '请输入用户的名字',
  enterPolygon: '请在地图上标记出已覆盖的区域',
  enterLocationName: '请输入地点',
  enterLocation: '请选择地点',
  enterWarehouse: '请选择仓库',
  enterWarehouses: '请选择仓库',
  enterLocationCenter: '请选择适当的比例并在此地点的中心放置一个标记',
  enterSearchQueryAddress: '请输入地址以进行搜索',
  enterTaskType: '请选择任务类型',
  enterDestination: '请选择目的地',
  enterTime: '请选择时间',
  enterCourier: '请选择快递员',
  enterHeight: '请输入高度',
  enterWidth: '请输入宽度',
  enterLength: '请输入长度',
  enterTaskCancelationReason: '请选择取消任务的原因',
  enterServiceZone: '请选择服务区',
  enterDeliveryMethod: '请选择运送方式',
  enterCoverages: '请选择覆盖区域',
  enterDayStart: '请标示工作日开始的时间',
  enterDayEnd: '请标示工作日结束的时间',
  enterInterval: '请标示时间区间',
  enterOrderLimit: '请标示限额',
  enterPrice: '清表示费用',
  enterAverageSpeed: '请输入速度',
  enterShopShortName: '请输入简称',
  enterShopLegalName: '请输入法定名称',
  enterTransportNumber: '请输入车牌号',
  enterTransportWeight: '请输入承载能力',
  enterLoadingTime: '请输入时间',
  enterSurname: '请输入姓氏',
  enterName: '请输入名字',
  enterPatronymic: '请输入父姓',
  enterEmail: '请输入邮箱',
  enterPhone: '请输入电话号码',
  enterRole: '请选择角色',
  enterAddress: '请输入地址',
  enterLatitude: '请输入纬度',
  enterLongitude: '请输入经度',
  enterPoint: '请在地图上标注地点',
  enterTimezone: '请选择时区',
  enterWarehouseGroupId: '请输入仓库组的标识符号',
  enterShops: '请选择店铺',
  enterMaxRouteTime: '请输入路线时间',
  enterMaxDelayTime: '请输入迟到时间',
  enterMaxCourierDowntime: '请输入快递员休息间隔',
  enterMaxLeadTime: '请输入提前到达时间',
  enterUnloadingTime: '请输入卸货时间',
  enterGuardLimit: '请输入保护时间区间',
  enterWeight: '请输入重量',
  enterVolume: '请输入体积',

  // other
  possiblyLate: '可能迟到',
  latenessTitle: '迟到',
  lateness_one: '迟到',
  lateness_few: '迟到',
  lateness_many: '迟到',
  lateness_other: '迟到',
  possiblyOutrunning: '可能提前到达',
  outrunningTitle: '提前到达',
  outrunning_one: '提前到达',
  outrunning_few: '提前到达',
  outrunning_many: '提前到达',
  outrunning_other: '提前到达',
  task_one: '任务',
  task_few: '任务',
  task_many: '任务',
  task_other: '任务',
  coverage_one: '覆盖区域',
  coverage_few: '覆盖区域',
  coverage_many: '覆盖区域',
  coverage_other: '覆盖区域',
  address_one: '地址',
  address_few: '地址',
  address_many: '地址',
  address_other: '地址',

  // validators
  passwordRequiredValidator: '请输入密码',
  loginRequiredValidator: '请输入用户名',
  transportRequiredValidator: '请选择运输工具',
  requiredValidator: '该字段必填',
  moreThanNullNumberValidator: '该数值应该大于零',
  positiveNumberValidator: '该数值应该为正数',
  integerNumberValidator: '该数值应该为整数',
  compareWithPasswordValidator: '密码应该互相匹配',
  moreThanNullZoomValidator: '比例应该大于零',
  minIntervalValidator: '最小允许间隔为5分钟',
  maxIntervalValidator: '最大允许间隔为1天',
  orderLimitValidator: '订单限额不能小于0',
  priceValidator: '费用不能小于0',
  surnameValidator: '请输入姓氏',
  nameValidator: '请输入名字',
  roleValidator: '请选择角色',
  scheduleRequiredValidator: '您需要添加时间表',

  // confirm
  defaultConfirm: '您确定，您想要{{content}}',
  deliveryMethodDeleteConfirm: '删除运送方式？',
  clientGroupDeleteConfirm: '删除客户组？',
  warehouseDeleteConfirm: '删除仓库？',
  shopDeleteConfirm: '删除店铺？',
  transportDeleteConfirm: '删除运输工具？',
  finishTheRateConfirm: '结束运费？运费将在1分钟后失效。',
  leaveThePageConfirm: '您确定要离开该页面吗？',
  timeSlotDeleteConfirm: '删除时间段？',
  courierDeleteConfirm: '删除快递员？',
  userDeleteConfirm: '删除用户？',
  locationDeleteConfirm: '删除地点？',
  rateDeleteConfirm: '删除运费？',
  serviceZoneDeleteConfirm: '删除快递员？',
  coverageDeleteConfirm: '删除覆盖区域？',
  coverageDeleteFromListConfirm: '删除列表中的覆盖区域？',
  endCourierSessionConfirm: '您确定要完成轮班吗？所有未完成的路线将被取消。',

  // notifications & phrases
  addressIsNotDefined: '地址未确定',
  cannotCallTheClient: '快递员无法联系到客户',
  courierIsNotSelected: '未选择快递员和汽车',
  sessionWillBeCreateAutomatically: '班次将自动创建',
  transportIsNotCompareToWarehouse: '仓库与可用车辆不匹配',
  courierIsNotCompareToWarehouse: '仓库与可用的快递员不匹配',
  transportIsNotCompareToDeliveryMethod: '运送方式与可用车辆不匹配',
  thereIsActiveRoutesInSession: '班次中有未完成的路线',
  locationHasNoRates: '该地点没有设置运费',
  letsCreateRoute: '将任务拖放到此处以创建路线。',
  thereAreNoZones: '这里没有任何服务区',
  thereAreSomeProblems: '已发现问题',
  noSectionFound: '该部分缺失',
  accessDenied: '访问被禁止',
  noTasks: '没有任务',
  notDefined: '为确定',
  thereAreTasksWithNotAvailableDeliveryMethodsForTransport:
    '如果任务无法提供可用的运送方式，则汽车将不会前往',
  thereAreTasksWithNotAvailableWarehouseForTransport:
    '如果任务无法提供可用的仓库，则汽车将不会前往',
  thereAreTasksWithNotAvailableWarehouseForCourier:
    '如果任务无法提供可用的仓库，则快递员将不会前往',
  letsCreateCourierSession: '单击“添加班次”为当天的快递员安排班次',
  warehouseCreatedWithNoCoords: '创建仓库时存在地理编码错误。请指定需要激活的仓库地址',
  maxDelayTimeMeaning: '如果该数值设置为0，则意味着禁止迟到。如果该字段为空，则代表没有限制',
  maxLeadTimeMeaning: '如果该数值设置为0，则意味着禁止提前到达。如果该字段为空，则代表没有限制',
  fullDayRoutesMeaning: '分发全天的订单   ',
  searchByFullName: '按照姓名查找',
  defaultSort: '默认排序',
  ascendingAmountSort: '金额由低到高',
  descendingAmountSort: '金额由高到低',
  ascendingWeightSort: '重量由低到高',
  descendingWeightSort: '重量由高到低',
  ascendingVolumeSort: '体积由低到高',
  descendingVolumeSort: '体积由高到低',
  couriersSuccessfullySend: '快递员已前往装运货物',
  courierHasProcessingRoute: '快递员有在执行中的运输路线',
  courierHasProcessingSession: '快递员有在执行中的班次',
  readyToSendCouriers: '准备前往装运货物的快递员',
  noCouriersToSend: '没有准备前往装运货物的快递员',
  addExtraTask: '创建额外任务',
  taskCreationReason: '请描述原因',
  extraTaskPreRequest: '快递员是否见到了客户？',
  extraTaskReasons: '仅在下列情况下可以创建额外任务：',
  extraTaskReason1: '办理退货',
  extraTaskReason2: '送货上门',
  extraTaskReason3: '更换商品',
  extraTaskWarning: '此类任务将不会显示在统计系统中。',
  extraTaskAction: '如果是其它原因，请联系技术支持',
};
