import { Feature, Point } from 'geojson';
import querystring from 'query-string';

import { isCoordsExist } from 'modules/map/helpers';
import { IWarehousesForm } from 'modules/warehouses/models/types';

export const getAddress = (data): string => {
  if (data.code === 404) {
    return '';
  }

  return data.text;
};

export const getResponse = (response) => response.json();

export const getMapView = (
  warehouse: Pick<IWarehousesForm, 'address' | 'lat' | 'lon'>,
): Point | [] =>
  isCoordsExist(warehouse.lat, warehouse.lon)
    ? {
        type: 'Point',
        coordinates: [warehouse.lon, warehouse.lat],
      }
    : [];

export const getParams = (geojson: Feature<Point>): string => {
  return querystring.stringify({
    search: [...geojson.geometry.coordinates].reverse().join(','),
  });
};
