import { Checkbox, Input } from 'antd';

import { IFieldData } from 'helpers/form';
import { IClientGroups, IClientGroupsForm } from 'modules/client-groups/models/types';
import { translate } from 'modules/localization';

export const getDataToSet = (item: IClientGroups): IClientGroupsForm | Record<string, never> => {
  return {
    name: item.name,
    guid: item.guid,
    disabled: !item.disabled,
  };
};

export const getFields =
  (isView: boolean) =>
  (isEdit: boolean, isCreate: boolean): IFieldData => ({
    name: {
      component: Input,
      formItem: {
        label: translate('title'),
        className: 'client-groups-form__item',
      },
      name: 'name',
      params: {
        rules: [
          {
            message: translate('requiredValidator'),
            required: true,
          },
        ],
      },
      props: {
        placeholder: translate('enterTitle'),
        disabled: isView,
      },
    },
    guid: {
      component: Input,
      formItem: {
        label: translate('id'),
        className: 'client-groups-form__item',
      },
      name: 'guid',
      params: {
        rules: [
          {
            message: translate('requiredValidator'),
            required: true,
          },
        ],
      },
      props: {
        placeholder: translate('enterId'),
        disabled: isEdit || isView,
      },
    },
    disabled: {
      component: Checkbox,
      formItem: {
        label: translate('status'),
        className: 'client-groups-form__item',
      },
      name: 'disabled',
      params: {
        initialValue: isCreate,
        valuePropName: 'checked',
      },
      props: {
        children: translate('activity'),
        disabled: isView,
      },
    },
  });

export const prepareData = (formData: IClientGroupsForm, isEdit: boolean): IClientGroupsForm => {
  const { name, guid, disabled } = formData;
  return isEdit
    ? {
        name: name ? name.trim() : null,
        disabled: !disabled,
      }
    : {
        name: name ? name.trim() : null,
        guid: guid ? guid.trim() : null,
        disabled: !disabled,
      };
};
