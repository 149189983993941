import { makeAutoObservable } from 'mobx';

import { RestException } from 'api/RestException';
import { IPagination } from 'api/types';
import { getLastPagePagination, isOutOfPages } from 'helpers/pagination';
import ClustersApi from 'modules/clusters/api/ClustersApi';
import OrdersApi from 'modules/orders/api/OrdersApi';
import {
  ICluster,
  ICourier,
  ICourierSession,
  IOrder,
  IRoute,
  ITask,
  ITransport,
  IWarehouse,
  IClusterItem,
} from 'modules/qa/models/types';
import OrderCollectorMonitorApi from 'modules/qa-monitor-order-collector/api/OrderCollectorMonitorApi';
import TasksApi from 'modules/tasks/api/TasksApi';
import WarehousesApi from 'modules/warehouses/api/WarehousesApi';

export class OrderCollectorMonitorStore {
  tasksList: ITask[] = [];
  clustersList: ICluster[] = [];
  couriersList: ICourier[] = [];
  courierSessionsList: ICourierSession[] = [];
  ordersList: IOrder[] = [];
  routesList: IRoute[] = [];
  transportsList: ITransport[] = [];
  warehousesList: IWarehouse[] = [];
  clusterItemsList: IClusterItem[] = [];

  sidebarType: string = null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sidebarData: any = null;

  isClearPending = false;

  constructor() {
    makeAutoObservable(this);
  }

  getTasks = async (pagination: IPagination): Promise<ITask[]> => {
    try {
      const { data: res } = await OrderCollectorMonitorApi.getTasks(pagination);
      if (isOutOfPages(res.pagination)) {
        return this.getTasks(getLastPagePagination(res.pagination));
      }
      this.tasksList = res.data;
      return res.data;
    } catch (e) {
      throw new RestException(e);
    }
  };

  loadTask = async (guid: string): Promise<void> => {
    try {
      const { data: res } = await TasksApi.getTask(guid);
      const entity = res.data;
      this.tasksList = this.tasksList.map((e) => {
        if (e.guid === guid) {
          return { ...entity, ...e };
        }
        return e;
      });
    } catch (e) {
      throw new RestException(e);
    }
  };

  getClusters = async (pagination: IPagination): Promise<ICluster[]> => {
    try {
      const { data: res } = await OrderCollectorMonitorApi.getClusters(pagination);
      if (isOutOfPages(res.pagination)) {
        return this.getClusters(getLastPagePagination(res.pagination));
      }
      this.clustersList = res.data;
      return res.data;
    } catch (e) {
      throw new RestException(e);
    }
  };

  loadCluster = async (guid: string): Promise<void> => {
    try {
      const { data: res } = await ClustersApi.getCluster(guid);
      const entity = res.data;
      this.clustersList = this.clustersList.map((e) => {
        if (e.guid === guid) {
          return { ...entity, ...e };
        }
        return e;
      });
    } catch (e) {
      throw new RestException(e);
    }
  };

  getClusterItems = async (pagination: IPagination): Promise<IClusterItem[]> => {
    try {
      const { data: res } = await OrderCollectorMonitorApi.getClustersItems(pagination);
      if (isOutOfPages(res.pagination)) {
        return this.getClusterItems(getLastPagePagination(res.pagination));
      }
      this.clusterItemsList = res.data;
      return res.data;
    } catch (e) {
      throw new RestException(e);
    }
  };

  loadWarehouseForClusterItem = async (guid: string): Promise<void> => {
    try {
      const { data: res } = await WarehousesApi.getWarehouse(guid);
      const warehouse = res.data;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // Странные типы у нас в qa
      this.clusterItemsList = this.clusterItemsList.map((e) => {
        if (e.warehouse_guid === guid) {
          return { ...e, warehouse: warehouse };
        }
        return e;
      });
    } catch (e) {
      throw new RestException(e);
    }
  };

  getOrders = async (pagination: IPagination): Promise<IOrder[]> => {
    try {
      const { data: res } = await OrderCollectorMonitorApi.getOrders(pagination);
      if (isOutOfPages(res.pagination)) {
        return this.getOrders(getLastPagePagination(res.pagination));
      }
      this.ordersList = res.data;
      return res.data;
    } catch (e) {
      throw new RestException(e);
    }
  };

  loadOrder = async (guid: string): Promise<void> => {
    try {
      const { data: res } = await OrdersApi.getOrder(guid);
      const entity = res.data;
      this.ordersList = this.ordersList.map((e) => {
        if (e.guid === guid) {
          return { ...entity, ...e };
        }
        return e;
      });
    } catch (e) {
      throw new RestException(e);
    }
  };

  setSidebarType = (type: string): void => {
    this.sidebarType = type;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSidebarData = (data: any): void => {
    this.sidebarData = data;
  };

  clearSoft = async (): Promise<void> => {
    this.isClearPending = true;
    await OrderCollectorMonitorApi.clearSoft();
    this.isClearPending = false;
  };

  clearHard = async (): Promise<void> => {
    this.isClearPending = true;
    await OrderCollectorMonitorApi.clearHard();
    this.isClearPending = false;
  };
}
