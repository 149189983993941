import './style.less';

import React, { useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Prompt } from 'react-router-dom';

import Navigation from 'modules/arm2/components/Navigation';
import Title from 'modules/common/components/Title';
import { translate } from 'modules/localization';
import RefreshButton from 'modules/routeGenerator/components/common/RefreshButton';
import LeftSide from 'modules/routeGenerator/components/left/LeftSide';
import RightSide from 'modules/routeGenerator/components/right/RightSide';
import { useStore } from 'services/store';

interface IProps {
  baseUrl: string;
}

const GeneratorPage = ({ baseUrl }: IProps) => {
  const { router, routeGenerator, deliveryMethods, tasks, warehouses, currency } = useStore();

  useEffect(() => {
    if (!routeGenerator.routeSettings.deliveryDate) {
      router.push(baseUrl);
    } else {
      deliveryMethods.getList({ pageSize: 100, current: 1 }, false);
      tasks.apiStore.getTasksTypes();
      routeGenerator.apiStore.getTasksWrapperLoading();
      warehouses
        .get(routeGenerator.routeSettings.warehouses)
        .then((item) => routeGenerator.setActiveWarehouse(item));
      currency.getCurrency();
    }

    return routeGenerator.setSearchFilterValue(null);
  }, []);

  return (
    <div className="rg-page">
      <Prompt
        when={routeGenerator.routeDraftList.length > 0}
        message={() => {
          return translate('leaveThePageConfirm');
        }}
      />
      <DndProvider backend={HTML5Backend}>
        <div className="rg-page__leftside">
          <div className="rg-page__header">
            <Navigation />
            <div className="rg-page__title">
              <Title size={Title.SIZE.H3} weight={Title.WEIGHT.SEMIBOLD}>
                {translate('routeGenerator')}
              </Title>
              <RefreshButton />
            </div>
          </div>
          <LeftSide />
        </div>

        <div className="rg-page__rightside">
          <RightSide />
        </div>
      </DndProvider>
    </div>
  );
};

export default observer(GeneratorPage);
