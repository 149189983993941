import React, { ReactNode, useEffect, useState } from 'react';

import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';

import CoveragesItem from 'modules/coverages/components/CoveragesItem';
import { getListMapView } from 'modules/coverages/components/CoveragesList/helpers';
import CreateManager from 'modules/coverages/components/CreateManager';
import { ICoverage, ICoveragesPolygon } from 'modules/coverages/models/types';
import { ILocation } from 'modules/locations/models/types';
import Map from 'modules/map/containers/Map';
import MarkerCluster from 'modules/map/containers/MarkerCluster';
import Ruler from 'modules/map/containers/Ruler';
import WarehouseMarker from 'modules/warehouse/containers/WarehouseMarker';
import { IWarehouses } from 'modules/warehouses/models/types';
import { useStore } from 'services/store';

interface IProps {
  baseUrl: string;
  location?: ILocation;
  guid?: string;
  action?: string;
}

const CoveragesMap = ({ baseUrl, location, guid, action }: IProps) => {
  const { core, coverage } = useStore();

  const editableGuid = guid || coverage.coverageEditableGuid;
  const {
    hoveredGuid,
    setHovered,
    setPolygon,
    coveragesListView,
    adjacentCoveragesListView,
    isCoveragesFormVisible,
    isLoadingCoveragesList,
    loadingForm,
  } = coverage;

  const handleLayerCreate = (polygon: ICoveragesPolygon): void => setPolygon(polygon);

  const getMapView = (): ICoveragesPolygon[] => {
    return coverage.polygon ? [coverage.polygon] : getListMapView(coveragesListView);
  };

  const [mapView, setMapView] = useState<ICoveragesPolygon[]>(getMapView());

  useEffect(() => {
    return () => {
      setPolygon(null);
    };
  }, []);

  useEffect(() => {
    setMapView(getMapView());
  }, [coverage.polygon, coveragesListView]);

  const renderWarehousesPoint = (warehouse: IWarehouses): ReactNode => {
    if (warehouse.point && warehouse.point.lat && warehouse.point.lon) {
      return (
        <WarehouseMarker
          key={warehouse.guid}
          position={[warehouse.point.lat, warehouse.point.lon]}
          name={warehouse.title}
          address={warehouse.address}
        />
      );
    }
  };

  const renderCoverage = (item: ICoverage): ReactNode => {
    return (
      <CoveragesItem
        key={item.guid}
        coverage={item}
        baseUrl={baseUrl}
        onOver={(): void => setHovered(item.guid)}
        onOut={(): void => setHovered(null)}
        isHovered={hoveredGuid === item.guid}
        isLink
      />
    );
  };

  const renderCoverageEdit = (item: ICoverage): ReactNode => {
    return <CoveragesItem key={item.guid} coverage={item} />;
  };

  const renderAdjacentCoverage = (item: ICoverage): ReactNode => {
    return <CoveragesItem key={item.guid} coverage={item} isAdjacent />;
  };

  const coverageFilter = (item: ICoverage): boolean => editableGuid !== item.guid;

  const polygon = coverage.polygon ? toJS(coverage.polygon) : null;

  const isView = !core.permissions['coverages.update'];

  if (isLoadingCoveragesList || loadingForm) {
    return <Map />;
  } else {
    return (
      <Map mapView={mapView} whenCreated={coverage.setMap}>
        {action && isCoveragesFormVisible && (
          <CreateManager onCreate={handleLayerCreate} polygon={polygon} isView={isView} />
        )}

        {!action && toJS(coveragesListView).map(renderCoverage)}
        {action && toJS(coveragesListView).filter(coverageFilter).map(renderCoverageEdit)}

        {adjacentCoveragesListView.length > 0 &&
          toJS(adjacentCoveragesListView).filter(coverageFilter).map(renderAdjacentCoverage)}

        {location && location.warehouses && (
          <MarkerCluster color="#1890FF" withoutForceUpdate>
            {location.warehouses.map((item) => renderWarehousesPoint(item))}
          </MarkerCluster>
        )}

        {action && <Ruler />}
      </Map>
    );
  }
};

export default observer(CoveragesMap);
