import { DEFAULT_DATE } from 'modules/arm2/components/DateFilter';

import { ITasksFilter } from './types';

export const initialFilter: ITasksFilter = {
  current: 1,
  pageSize: 100,
  search: null,
  deliveryDate: DEFAULT_DATE,
  shop: [],
  warehouses: [],
  from: null,
  to: null,
  status: [],
  deliveryMethod: [],
  mapShow: false,
  isProblem: false,
};

export const initialGeneratorFilter: ITasksFilter = {
  current: 1,
  pageSize: 100,
  search: null,
  deliveryDate: DEFAULT_DATE,
  deliveryDateList: [],
  shop: [],
  warehouses: null,
  from: null,
  to: null,
  deliveryMethod: [],
  mapShow: false,
  isProblem: false,
  status: ['new'],
};
