import React from 'react';

import { Descriptions } from 'antd';

import { ICluster } from 'modules/qa/models/types';

import DelorianState from '../DelorianState';
import Diff from '../Diff';

interface IPropsSidebar {
  cluster: ICluster;
}

export const ClusterSidebar = ({ cluster }: IPropsSidebar) => {
  return (
    <>
      <Descriptions bordered title="Кластер" size="small" column={1}>
        <Descriptions.Item label="Кластер">{cluster.guid}</Descriptions.Item>
        {cluster.title ? (
          <Descriptions.Item label="Название">{cluster.title}</Descriptions.Item>
        ) : null}
        <Descriptions.Item label="Элемент кластера">
          {cluster.cluster_item_guid_list && Array.isArray(cluster.cluster_item_guid_list)
            ? cluster.cluster_item_guid_list.map((i) => <p key={i}>{i}</p>)
            : null}
        </Descriptions.Item>
        <Descriptions.Item label="courier_session_guid_list">
          {cluster.courier_session_guid_list && Array.isArray(cluster.courier_session_guid_list)
            ? cluster.courier_session_guid_list.map((i) => <p key={i}>{i}</p>)
            : null}
        </Descriptions.Item>
        <Descriptions.Item label="deleted">{cluster?.deleted.toString()}</Descriptions.Item>
        <Descriptions.Item label="Блокирующий процесс">{cluster?.locked}</Descriptions.Item>
        <Descriptions.Item label="timestamp_locked">{cluster?.timestamp_locked}</Descriptions.Item>
        <Descriptions.Item label="not_found_in_db">
          {cluster?.not_found_in_db?.toString()}
        </Descriptions.Item>
      </Descriptions>

      {cluster.config ? (
        <Descriptions bordered title="Конфиг" size="small" column={1}>
          <Descriptions.Item label="mode">{cluster?.config?.mode}</Descriptions.Item>

          <Descriptions.Item label="account_system">
            <Descriptions bordered size="small" column={1}>
              <Descriptions.Item label="delay">
                {cluster?.config?.account_system?.delay}
              </Descriptions.Item>
              <Descriptions.Item label="delay_strategy">
                {cluster?.config?.account_system?.delay_strategy}
              </Descriptions.Item>
            </Descriptions>
          </Descriptions.Item>

          <Descriptions.Item label="courier_notify">
            <Descriptions bordered size="small" column={1}>
              <Descriptions.Item label="delay">
                {cluster?.config?.courier_notify?.delay}
              </Descriptions.Item>
              <Descriptions.Item label="delay_strategy">
                {cluster?.config?.courier_notify?.delay_strategy}
              </Descriptions.Item>
            </Descriptions>
          </Descriptions.Item>

          <Descriptions.Item label="fast_mine">
            <Descriptions bordered size="small" column={1}>
              <Descriptions.Item label="cluster_item_sort_strategy">
                {cluster?.config?.fast_mine?.cluster_item_sort_strategy}
              </Descriptions.Item>
              <Descriptions.Item label="courier_sort_strategy">
                {cluster?.config?.fast_mine?.courier_sort_strategy}
              </Descriptions.Item>
              <Descriptions.Item label="oc_duration">
                {cluster?.config?.fast_mine?.oc_duration}
              </Descriptions.Item>
              <Descriptions.Item label="route_build_strategy">
                {cluster?.config?.fast_mine?.route_build_strategy}
              </Descriptions.Item>
              <Descriptions.Item label="stop_rebuild_route_delay">
                {cluster?.config?.fast_mine?.stop_rebuild_route_delay}
              </Descriptions.Item>
              <Descriptions.Item label="stop_rebuild_route_strategy">
                {cluster?.config?.fast_mine?.stop_rebuild_route_strategy}
              </Descriptions.Item>
            </Descriptions>
          </Descriptions.Item>

          <Descriptions.Item label="order_collector">
            <Descriptions bordered size="small" column={1}>
              <Descriptions.Item label="order_temperature_strategy">
                {cluster?.config?.order_collector?.order_temperature_strategy}
              </Descriptions.Item>
              <Descriptions.Item label="order_ttl">
                {cluster?.config?.order_collector?.order_ttl}
              </Descriptions.Item>
              <Descriptions.Item label="order_ttl_strategy">
                {cluster?.config?.order_collector?.order_ttl_strategy}
              </Descriptions.Item>
            </Descriptions>
          </Descriptions.Item>

          <Descriptions.Item label="routing_settings">
            <Descriptions bordered size="small" column={1}>
              <Descriptions.Item label="max_delay_time">
                {cluster?.config?.routing_settings?.max_delay_time}
              </Descriptions.Item>
              <Descriptions.Item label="max_lead_time">
                {cluster?.config?.routing_settings?.max_lead_time}
              </Descriptions.Item>
            </Descriptions>
          </Descriptions.Item>
        </Descriptions>
      ) : null}

      {cluster.config && cluster.config.delivery_settings
        ? Object.keys(cluster.config.delivery_settings).map((delivery) => {
            const data = cluster.config.delivery_settings[delivery];
            return (
              <Descriptions
                bordered
                title={`Способ доставки ${delivery}`}
                size="small"
                column={1}
                key={delivery}
              >
                <Descriptions.Item label={'courier_avg_speed'}>
                  {data?.courier_avg_speed}
                </Descriptions.Item>
                <Descriptions.Item label={'delivery_method_guid'}>
                  {data?.delivery_method_guid}
                </Descriptions.Item>
                <Descriptions.Item label={'disabled'}>
                  {data?.disabled.toString()}
                </Descriptions.Item>
                <Descriptions.Item label={'handle_start'}>{data?.handle_start}</Descriptions.Item>
                <Descriptions.Item label={'order_actual_delay'}>
                  {data?.order_actual_delay}
                </Descriptions.Item>
                <Descriptions.Item label={'order_actual_strategy'}>
                  {data?.order_actual_strategy}
                </Descriptions.Item>
                <Descriptions.Item label={'order_unloading_delay'}>
                  {data?.order_unloading_delay}
                </Descriptions.Item>
              </Descriptions>
            );
          })
        : null}

      <DelorianState entity={cluster} />
      <Diff entity={cluster} />

      <pre>{JSON.stringify(cluster, null, 2)}</pre>
    </>
  );
};
