import React from 'react';

import dayjs, { Dayjs, extend } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { checkDateInDate } from 'helpers/date';
import { timeFormat } from 'helpers/string';
import { translate } from 'modules/localization';
import { ClockCircleSvg } from 'modules/routeGenerator/components/common/ClockCircleSvg';
import { IRouteDraft } from 'modules/routeGenerator/models/types';

extend(utc);
extend(timezone);

interface IProps {
  cardData: IRouteDraft;
  date: Dayjs;
}

const Timeslot = ({ cardData, date }: IProps) => {
  const ClockCircle = (props) => <ClockCircleSvg {...props} />;

  if (cardData.tasksList.length === 0) {
    return (
      <div className="rg-routecard__time rg-routecard__time--empty">
        <ClockCircle />
        {translate('notDefined')}
      </div>
    );
  }

  if (cardData.isCalculated) {
    return (
      <div className="rg-routecard__time">
        <ClockCircle />
        {dayjs(cardData.start.datetime)
          .tz(cardData.start.warehouse.timezone)
          .format(timeFormat.simple)}
        {!checkDateInDate(
          dayjs(cardData.start.datetime).tz(cardData.start.warehouse.timezone),
          date.tz(cardData.start.warehouse.timezone),
        ) &&
          `\u00A0/\u00A0${dayjs(cardData.start.datetime)
            .tz(cardData.start.warehouse.timezone)
            .format('DD.MM')}`}
        &nbsp;-&nbsp;
        {dayjs(cardData.finish.datetime)
          .tz(cardData.finish.warehouse.timezone)
          .format(timeFormat.simple)}
        {!checkDateInDate(
          dayjs(cardData.finish.datetime).tz(cardData.finish.warehouse.timezone),
          date.tz(cardData.finish.warehouse.timezone),
        ) &&
          `\u00A0/\u00A0${dayjs(cardData.finish.datetime)
            .tz(cardData.finish.warehouse.timezone)
            .format('DD.MM')}`}
      </div>
    );
  }

  let startSlot = cardData.tasksList[0].time_slot_start;
  let endSlot = cardData.tasksList[0].time_slot_end;
  cardData.tasksList.map((task) => {
    if (task.time_slot_start < startSlot) {
      startSlot = task.time_slot_start;
    }
    if (task.time_slot_end > endSlot) {
      endSlot = task.time_slot_end;
    }
  });
  return (
    <div className="rg-routecard__time">
      <ClockCircle />
      {startSlot}&nbsp;-&nbsp;{endSlot}
    </div>
  );
};

export default Timeslot;
