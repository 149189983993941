import './style.less';

import React from 'react';

import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import icon from 'img/empty.svg?react';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

const ClustersEmpty = () => {
  const { core } = useStore();

  return (
    <div className="clusters-empty">
      <img alt="" className="clusters-empty__icon" src={`${icon}`} />
      <div className="clusters-empty__description">{translate('noClusters')}</div>
      <div className="clusters-empty__action">
        {core.permissions['clusters.create'] && (
          <Link to={'/directories/clusters/create'}>
            <Button type="primary"> {translate('createClusters')}</Button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default observer(ClustersEmpty);
