import { ITask } from 'modules/tasks/models/types';

export const getPointProp = (task: ITask): string => {
  switch (task.destination) {
    case 'sender':
      return 'sender_point';
    case 'recipient':
    default:
      return 'point';
  }
};

export const getAddressProp = (task: ITask): string => {
  switch (task.destination) {
    case 'sender':
      return 'sender_address';
    case 'recipient':
    default:
      return 'address';
  }
};
