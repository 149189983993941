import dayjs, { Dayjs } from 'dayjs';
import uniqueid from 'lodash.uniqueid';

import { timeFormat } from 'helpers/string';
import { minuteStep } from 'modules/schedules/components/TimeslotsList';
import { ISchedules, IWeekdayIDEnum } from 'modules/schedules/models/apitypes';
import { initialWeek } from 'modules/schedules/models/initial';
import { IScheduleFormData, ITimeslotUI, IWeekday } from 'modules/schedules/models/uitypes';

export const getValidatedTimeslotValue = (
  timeslot: ITimeslotUI,
  key: string,
  value: Dayjs,
): Dayjs => {
  const isStart = key === 'start';

  const startTime = isStart
    ? dayjs(value, timeFormat.full)
    : timeslot.start
    ? dayjs(timeslot.start, timeFormat.full)
    : dayjs();
  const endTime = isStart ? dayjs(timeslot.end, timeFormat.full) : dayjs(value, timeFormat.full);

  let newValue = value;

  if (startTime.format('X') >= endTime.format('X')) {
    newValue = isStart ? endTime.add(-minuteStep, 'm') : startTime.add(minuteStep, 'm');
  }

  return newValue;
};

export const fromFormDataToApi = (values: IScheduleFormData, week: IWeekday[]): ISchedules => {
  const time_slots = week.reduce((acc, day) => {
    day.timeslots.forEach((ts) =>
      acc.push({
        delivery_limit: ts.limit === null || ts.limit === '' ? null : +ts.limit,
        end: ts.end ? dayjs(ts.end).format('HH:mm') : null,
        start: ts.start ? dayjs(ts.start).format('HH:mm') : null,
        price: ts.price === null || ts.price === '' ? null : +ts.price,
        settings: {
          average_speed: +values['average_speed'],
          preliminary_call: false,
        },
        weekday: +day.id,
      }),
    );
    return acc;
  }, []);

  return {
    name: values.name.trim(),
    time_slots,
  };
};

export const serializeScheduleResponse = (values: ISchedules): IWeekday[] => {
  return values.time_slots
    ? values.time_slots
        .reduce((_week, slot) => {
          const currentDay = { ..._week.find((day) => +day.id === +slot.weekday) };
          const otherDays = [..._week.filter((day) => +day.id !== +slot.weekday)];
          const timeslot = {
            start: dayjs(slot.start, 'HH:mm'),
            limit: slot.delivery_limit,
            price: slot.price,
            end: dayjs(slot.end, 'HH:mm'),
            id: uniqueid(),
            call: false,
          };
          currentDay.timeslots = [...currentDay.timeslots, timeslot];
          return [...otherDays, currentDay];
        }, initialWeek)
        .sort((a, b) => (a.id > b.id ? 1 : -1))
    : initialWeek;
};

export const getFieldName = (
  weekId: IWeekdayIDEnum,
  intervalId: string,
  paramName: string,
): string => `${weekId}_${intervalId}_${paramName}`;

const isErrorRelevant = (errorKey, index, fieldName) => {
  const errorIndex = errorKey.match(/\[(.*?)]/) && +errorKey.match(/\[(.*?)]/)[1];
  const errorFieldName = errorKey.split('.')[1];
  return errorIndex === index && errorFieldName === fieldName;
};

const getErrorForField = (errors, index, fieldName) => {
  const errorKeys = Object.keys(errors);
  const err = errorKeys.find((i) => isErrorRelevant(i, index, fieldName));
  return err ? errors[err] : null;
};

export const getTimeslotErrors = (errors, index: number) => {
  return {
    error_start: getErrorForField(errors, index, 'start'),
    error_end: getErrorForField(errors, index, 'end'),
    error_limit: getErrorForField(errors, index, 'limit'),
    error_call: getErrorForField(errors, index, 'call'),
    error_price: getErrorForField(errors, index, 'price'),
  };
};
