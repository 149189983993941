import { IServiceZonesForm } from 'modules/service-zones/models/types';

export const prepareData = (formData: IServiceZonesForm): IServiceZonesForm => {
  const { client_groups, coverages, disabled, location_guid, name, warehouses } = formData;
  return {
    client_groups: client_groups || [],
    coverages: coverages || [],
    disabled: !disabled,
    location_guid: location_guid ? location_guid.trim() : null,
    name: name ? name.trim() : null,
    warehouses: warehouses || [],
  };
};
