import './style.less';

import React from 'react';

import { observer } from 'mobx-react-lite';

import { useStore } from 'services/store';

import CardView from './CardView';
import EmptyView from './EmptyView';
import ListView from './ListView';

const GeneratorRightSide = () => {
  const { routeGenerator } = useStore();

  if (routeGenerator.routeDraftList.length === 0) {
    return <EmptyView />;
  } else if (routeGenerator.openCardRouteDraftId === null) {
    return <ListView />;
  } else {
    return <CardView />;
  }
};

export default observer(GeneratorRightSide);
