import { DirectoryApi, DirectoryApiStore } from 'modules/common/store/DirectoryApiStore';

import ReasonsDelayApi from '../api/ReasonsDelayApi';
import { IReasonDelay, IReasonDelayForm } from '../models/types';

const Api: DirectoryApi<IReasonDelay, IReasonDelayForm> = {
  getEntity: ReasonsDelayApi.getReasonDelay,
  getEntityList: ReasonsDelayApi.getReasonsDelayList,
  search: ReasonsDelayApi.searchReasonsDelay,
  createEntity: ReasonsDelayApi.createReasonDelay,
  editEntity: ReasonsDelayApi.editReasonDelay,
  deleteEntity: ReasonsDelayApi.deleteReasonDelay,
};
export class ReasonsDelayStore extends DirectoryApiStore<IReasonDelay, IReasonDelayForm> {
  constructor() {
    super(Api, '/directories/reasons-delay');
  }
}
