import React from 'react';

import dayjs from 'dayjs';

import { dateFormat } from 'helpers/string';
import { CheckIcon } from 'modules/common/components/Icons';
import { ICourier, ISession } from 'modules/couriers/models/types';
import { translate } from 'modules/localization';

interface IProps {
  session: ISession;
  courier: ICourier;
}

const CheckSessionIcons = ({ session, courier }: IProps) => {
  if (session) {
    return (
      <div className="rg-courier-modal__session-field">
        <CheckIcon className="rg-courier-modal__check" />{' '}
        {translate('courierSessionOnDate', {
          content: dayjs(session.planned_date).format(dateFormat.string),
        })}
      </div>
    );
  }
  if (!session && courier) {
    return (
      <div className="rg-courier-modal__session-field">
        <CheckIcon className="rg-courier-modal__check" />
        {translate('sessionWillBeCreateAutomatically')}
      </div>
    );
  }
  return null;
};

export default CheckSessionIcons;
