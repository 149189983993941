import React from 'react';

import { Tag } from 'antd';

import { translate } from 'modules/localization';
import { IWarehouses } from 'modules/warehouses/models/types';

interface IProps {
  warehouses: IWarehouses[];
}

const WarehouseTags = ({ warehouses }: IProps) => {
  if (warehouses && Array.isArray(warehouses)) {
    return (
      <div className="rg-courier-modal__tags">
        <div className="rg-courier-modal__tags-title">{translate('warehouses')}</div>
        <div>
          {warehouses.map((item) => (
            <Tag key={item.guid} className="rg-courier-modal__tag" color="rgba(0, 0, 0, 0.04)">
              {item.title || item.guid} {item.disabled ? ` (${translate('disabled')})` : ''}
            </Tag>
          ))}
        </div>
      </div>
    );
  }
  return null;
};

export default WarehouseTags;
