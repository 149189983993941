import './style.less';

import React, { useMemo, useRef } from 'react';

import { Feature, Point } from 'geojson';
import { LatLngTuple } from 'leaflet';
import { observer } from 'mobx-react-lite';
import { Marker, Tooltip } from 'react-leaflet';
import { MarkerProps } from 'react-leaflet/lib/Marker';

import { iconWarehouse, iconWarehouseHovered } from 'modules/map/icons';

interface IProps {
  name?: string;
  address?: string;
  hovered?: boolean;
  position: LatLngTuple;
  markerDragOnChange?: (point: Feature<Point>) => void;
}

const WarehouseMarker = ({
  name,
  address,
  hovered,
  position,
  markerDragOnChange,
  ...rest
}: IProps & MarkerProps) => {
  const markerRef = useRef(null);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker !== null) {
          markerDragOnChange && markerDragOnChange(marker.toGeoJSON());
        }
      },
    }),
    [],
  );

  return (
    <Marker
      {...rest}
      position={position}
      icon={hovered ? iconWarehouseHovered : iconWarehouse}
      attribution="warehouseMarker"
      eventHandlers={eventHandlers}
      ref={markerRef}
    >
      <Tooltip direction="top" opacity={0.85}>
        {name && <div className="warehouse-marker__name">{name}</div>}
        {address && <div className="warehouse-marker__address">{address}</div>}
      </Tooltip>
    </Marker>
  );
};

export default observer(WarehouseMarker);
