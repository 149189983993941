import './style.less';

import React, { useEffect, useRef } from 'react';

import { Table } from 'antd';
import debounce from 'lodash.debounce';
import { observer } from 'mobx-react-lite';
import { Redirect, useParams } from 'react-router';

import { IPagination } from 'api/types';
import { GEO_BASE_URL } from 'constants/index';
import { getUrlPagination } from 'helpers/url';
import Header from 'modules/locations/components/Header';
import LocationsEmptyList from 'modules/locations/components/LocationsEmptyList';
import { getColumns } from 'modules/locations/components/LocationsList/helpers';
import LocationsMap, { RootLocationsMap } from 'modules/locations/components/LocationsMap';
import LocationsSearch from 'modules/locations/components/LocationsSearch';
import { ILocations } from 'modules/locations/models/types';
import { useStore } from 'services/store';

interface IParams {
  locationGuid: string;
}

const LocationsList = ({ ...rest }: ILocations) => {
  const { locations, router, core } = useStore();

  const { locationGuid } = useParams<IParams>();

  const listRef = useRef(null);
  const scrollIntoView = () => listRef.current.scrollIntoView({ behavior: 'smooth' });

  const loadContent = (): void => {
    const url = new URL(window.location.href);
    if (url.searchParams.get('q')) {
      locations.apiStore.searchLocations(
        url.searchParams.get('q'),
        getUrlPagination(locations.locationsPagination),
        true,
        locationGuid,
      );
    } else if (locationGuid) {
      locations.apiStore.getLocationsChildren(
        getUrlPagination(locations.locationsPagination),
        locationGuid,
      );
    } else {
      locations.apiStore
        .getLocations(getUrlPagination(locations.locationsPagination))
        .then(() => locations.resetFormData());
    }
  };

  const deleteItem = (guid: string): void => {
    locations.apiStore.deleteLocation(guid).then(() => loadContent());
  };

  const columns = getColumns(
    locationGuid ? `${GEO_BASE_URL}/${locationGuid}/locations` : GEO_BASE_URL,
    deleteItem,
    core.permissions,
  );

  const updateList = (pagination: IPagination): void => {
    const url = new URL(window.location.href);
    const query = url.searchParams.get('q');
    if (query) {
      locations.apiStore.searchLocations(query, pagination, true, locationGuid);
    } else if (locationGuid) {
      locations.apiStore.getLocationsChildren(pagination, locationGuid);
    } else {
      locations.apiStore.getLocations(pagination);
    }
  };

  const setHoveredDebounce = debounce(locations.setHoveredGuid, 50);

  const { hoveredGuid } = locations;

  const getRowHandler = (row: ILocations) => ({
    className:
      row.guid === hoveredGuid ? 'locations__row_hovered locations__row' : 'locations__row',
    onClick: (e): void =>
      e.target.innerHTML === row.name && router.push(`${GEO_BASE_URL}/${row.guid}/locations`),
    onMouseOver: (): void => setHoveredDebounce(row.guid),
    onMouseOut: (): void => setHoveredDebounce(null),
  });

  useEffect(() => {
    loadContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationGuid]);

  if (!locations.loadingForm && locations?.viewLocation?.coverages > 0) {
    return <Redirect to="./coverages" />;
  }

  return (
    <div className="locations-layout">
      <div className="locations-layout__content">
        <div ref={listRef} className="locations__list">
          <Header isInner={!!locationGuid} />
          {!locations.isLoadingLocationsList &&
          !locations.loadingForm &&
          locations?.viewLocation?.coverages === 0 &&
          locations.locationsList.length === 0 &&
          locations.searchQuery === null ? (
            <LocationsEmptyList />
          ) : (
            <>
              <LocationsSearch />
              <Table
                className="dir__list locations__list"
                dataSource={locations.locationsList}
                columns={columns}
                bordered={false}
                pagination={{
                  ...locations.locationsPagination,
                  simple: core.isMobile,
                  onChange: scrollIntoView,
                }}
                rowKey="guid"
                loading={locations.isLoadingLocationsList}
                onChange={updateList}
                onRow={getRowHandler}
                showHeader={false}
                {...rest}
              />
            </>
          )}
        </div>
      </div>
      {!core.isMobile && (
        <div className="locations-layout__map">
          {locationGuid ? <LocationsMap /> : <RootLocationsMap />}
        </div>
      )}
    </div>
  );
};

export default observer(LocationsList);
