import React, { useEffect, forwardRef, ReactNode } from 'react';

import { Select, Spin } from 'antd';
import { SelectProps } from 'antd/lib/select';
import debounce from 'lodash.debounce';
import { observer } from 'mobx-react-lite';
import { BaseSelectRef } from 'rc-select/lib/BaseSelect';

import { selectFilter } from 'helpers/form';
import { DEFAULT_SELECT_PROPS } from 'modules/common/components/Select';
import { translate } from 'modules/localization';
import { ITransport } from 'modules/transports/models/types';
import { useStore } from 'services/store';

interface IProps {
  isSelectAllOptionAllowed?: boolean;
  defaultOptions?: ITransport[];
}

const TransportSelect = forwardRef<BaseSelectRef, IProps & SelectProps>(
  ({ onChange, isSelectAllOptionAllowed, defaultOptions, ...rest }, ref) => {
    const { transport } = useStore();

    const isDefaultOptions = defaultOptions && Array.isArray(defaultOptions);

    const getOptions = () => {
      const options = isDefaultOptions ? defaultOptions : transport.list;
      if (options && Array.isArray(options)) {
        return options;
      }
      return [];
    };

    const fetchDefaultOptions = async () => {
      if (!isDefaultOptions) {
        await transport.getList({ current: 1, pageSize: 100 }, false);
      }
    };

    useEffect(() => {
      fetchDefaultOptions();
    }, [defaultOptions]);

    const onChangeValue = (value, option): void => {
      const isAll = value && value.length && value.includes('all');
      const valueToSet = isAll ? getOptions().map((w) => w.guid) : value;
      onChange(valueToSet, option);
    };

    const fetchOptions = async (value: string): Promise<void> => {
      if (value && value.length !== 0) {
        await transport.search(value, { current: 1, pageSize: 100 });
      } else {
        await fetchDefaultOptions();
      }
    };

    const renderOption = (option: ITransport): ReactNode => {
      if (option) {
        return (
          <Select.Option key={option.guid} value={option.guid}>
            {`${option.name} ${option.number}${
              option.disabled ? ` (${translate('disabled')})` : ''
            }`}
          </Select.Option>
        );
      }
      return null;
    };

    const options = getOptions();

    return (
      <Select
        ref={ref}
        allowClear
        loading={transport.isLoadingList}
        filterOption={isDefaultOptions ? selectFilter : false}
        getPopupContainer={(el) => el}
        onBlur={fetchDefaultOptions}
        onChange={onChangeValue}
        placeholder={translate('enterTransport')}
        showSearch
        onSearch={isDefaultOptions ? () => null : debounce(fetchOptions, 300)}
        notFoundContent={
          transport.isLoadingList ? <Spin size="small" /> : translate('nothingWasFound')
        }
        {...rest}
        {...DEFAULT_SELECT_PROPS}
      >
        {isSelectAllOptionAllowed && options.length > 0 ? (
          <Select.Option key="all" value="all">
            {translate('selectAll')}
          </Select.Option>
        ) : null}
        {options.map(renderOption)}
      </Select>
    );
  },
);

export default observer(TransportSelect);
