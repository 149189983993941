import React, { useEffect, useState } from 'react';

import { Button, Select } from 'antd';

import classSet from 'helpers/classSet';
import { IFilterOption } from 'modules/clusters/models/types';
import { FilterIcon } from 'modules/common/components/Icons';
import { DEFAULT_SELECT_PROPS } from 'modules/common/components/Select';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

const ClusterListFilter = () => {
  const { clusters } = useStore();
  const [viewFilter, setViewFilter] = useState(false);
  const [optionsClusters, setOptionsClusters] = useState<IFilterOption[]>([]);
  const [optionsWarehouses, setOptionsWarehouses] = useState<IFilterOption[]>([]);
  const [optionsCouriers, setOptionsCouriers] = useState<IFilterOption[]>([]);

  useEffect(() => {
    if (
      clusters.list.length > 0 &&
      clusters.clusterFilterByCluster === null &&
      clusters.clusterFilterByCourier === null
    ) {
      const { optionsClusters, optionsWarehouses, optionsCouriers } = clusters.filterOptions;
      setOptionsClusters(optionsClusters);
      setOptionsWarehouses(
        optionsWarehouses.map((o) => ({
          value: o.value,
          label: (
            <>
              <p className="cluster-filter__warehouse-title">{o.name}</p>
              <p>{o.address}</p>
            </>
          ),
        })),
      );
      setOptionsCouriers(optionsCouriers);
    }

    return () => {
      clusters.resetFilters();
    };
  }, [clusters.list, clusters.clusterFilterByCluster, clusters.clusterFilterByCourier]);

  const handleChangeCluster = (value: string) => {
    clusters.filterByCluster(value);
  };

  const handleChangeCourier = (value: string) => {
    clusters.filterByCourier(value);
  };

  return (
    <>
      <div className="cluster-filter__button">
        <Button
          type="link"
          icon={<FilterIcon />}
          onClick={() => setViewFilter(!viewFilter)}
          className={classSet({
            'cluster-filter-button ': true,
            'cluster-filter-button--active': viewFilter,
          })}
        >
          {translate('filters')}
        </Button>
      </div>
      {viewFilter ? (
        <div className="cluster-filter__content">
          <Select
            defaultValue={clusters.clusterFilterByCluster}
            disabled={!!clusters.clusterFilterByCourier}
            className="cluster-filter__select-name"
            onChange={handleChangeCluster}
            allowClear
            placeholder={translate('byTitle')}
            showSearch
            popupMatchSelectWidth={false}
            popupClassName="cluster-filter__dropdown"
            optionLabelProp="value"
            options={[
              {
                label: translate('clusters').toUpperCase(),
                options: optionsClusters,
              },
              {
                label: translate('warehouses').toUpperCase(),
                options: optionsWarehouses,
              },
            ]}
            {...DEFAULT_SELECT_PROPS}
          />

          <Select
            defaultValue={clusters.clusterFilterByCourier}
            disabled={!!clusters.clusterFilterByCluster}
            className="cluster-filter__select-courier"
            onChange={handleChangeCourier}
            allowClear
            placeholder={translate('byCourier')}
            showSearch
            popupMatchSelectWidth={false}
            popupClassName="cluster-filter__dropdown cluster-filter__dropdown--courier"
            options={[
              {
                label: translate('couriers').toUpperCase(),
                options: optionsCouriers,
              },
            ]}
            {...DEFAULT_SELECT_PROPS}
          />
        </div>
      ) : null}
    </>
  );
};

export default ClusterListFilter;
