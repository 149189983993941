import React, { ReactNode, forwardRef, useEffect } from 'react';

import { Select, Spin } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { observer } from 'mobx-react-lite';
import { BaseSelectRef } from 'rc-select/lib/BaseSelect';

import { selectFilter } from 'helpers/form';
import { DEFAULT_SELECT_PROPS } from 'modules/common/components/Select';
import { translate } from 'modules/localization';
import { IWarehouses } from 'modules/warehouses/models/types';
import { useStore } from 'services/store';

interface IProps {
  isSelectAllOptionAllowed?: boolean;
  defaultOptions?: IWarehouses[];
}

const WarehousesSelect = forwardRef<BaseSelectRef, IProps & SelectProps>(
  ({ onChange, isSelectAllOptionAllowed, defaultOptions, ...rest }, ref) => {
    const { warehouses } = useStore();

    const getOptions = () => {
      const options = defaultOptions ? defaultOptions : warehouses.listAll;
      if (options && Array.isArray(options)) {
        return options;
      }
      return [];
    };

    const fetchDefaultOptions = async () => {
      if (!defaultOptions) {
        await warehouses.getAll();
      }
    };

    useEffect(() => {
      fetchDefaultOptions();
    }, [defaultOptions]);

    const onChangeValue = (value, option): void => {
      const isAll = value && value.length && value.includes('all');
      const valueToSet = isAll ? getOptions().map((w) => w.guid) : value;
      onChange(valueToSet, option);
    };

    const renderOption = (option: IWarehouses): ReactNode => {
      if (option) {
        return (
          <Select.Option
            key={option.guid}
            value={option.guid}
            data-testid={'warehouseInput-' + option.title}
          >
            {option.title || option.guid} {option.disabled ? ` (${translate('disabled')})` : ''}
          </Select.Option>
        );
      }

      return null;
    };

    const options = getOptions();

    return (
      <Select
        ref={ref}
        allowClear
        loading={warehouses.isLoadingList}
        filterOption={selectFilter}
        getPopupContainer={(el) => el}
        onChange={onChangeValue}
        placeholder={translate('enterWarehouses')}
        mode="multiple"
        notFoundContent={
          warehouses.isLoadingList ? <Spin size="small" /> : translate('nothingWasFound')
        }
        {...DEFAULT_SELECT_PROPS}
        {...rest}
      >
        {isSelectAllOptionAllowed && options.length > 0 ? (
          <Select.Option key="all" value="all">
            {translate('selectAll')}
          </Select.Option>
        ) : null}
        {options.map(renderOption)}
      </Select>
    );
  },
);

export default observer(WarehousesSelect);
