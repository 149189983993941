import React from 'react';

import { Form } from 'antd';

import { ICourier, ISession } from 'modules/couriers/models/types';
import { translate } from 'modules/localization';
import { IRouteDraft } from 'modules/routeGenerator/models/types';
import TransportSelect from 'modules/transports/components/TransportSelect';

interface IProps {
  initialValues: IRouteDraft;
  onChange: (transportGuid: string) => void;
  session: ISession;
  isLoading: boolean;
  courier: ICourier;
}

const TransportFormItem = ({ initialValues, onChange, session, isLoading, courier }: IProps) => {
  return (
    <Form.Item
      label={translate('transport')}
      name="transportGuid"
      rules={[{ required: true, message: translate('transportRequiredValidator') }]}
      initialValue={(initialValues && initialValues.transportGuid) || undefined}
    >
      <TransportSelect
        style={{ minWidth: 200 }}
        disabled={Boolean(session && session.planned_date)}
        loading={isLoading}
        onChange={onChange}
        defaultOptions={courier ? courier.transports : null}
      />
    </Form.Item>
  );
};

export default TransportFormItem;
