import React from 'react';

import Icon from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import { useDrop } from 'react-dnd';

import classSet from 'helpers/classSet';
import { translate } from 'modules/localization';
import { EmptyRouteSmallSvg } from 'modules/routeGenerator/components/common/EmptyRouteSmallSvg';
import { DndTypes, IDndItem } from 'modules/routeGenerator/stores/RouteGeneratorDnDStore';
import { useStore } from 'services/store';

interface IDndProps {
  hovered: boolean;
  highlighted: boolean;
}

const EmptySmallCard = () => {
  const { routeGenerator } = useStore();

  const [{ hovered, highlighted }, drop] = useDrop<IDndItem, void, IDndProps>(() => ({
    accept: [DndTypes.LEFT_TASK, DndTypes.LEFT_COVERAGE],
    drop: (item, monitor) => routeGenerator.dndStore.onDropToRoute(item.id, monitor.getItemType()),
    collect: (monitor) => {
      return {
        highlighted: monitor.canDrop(),
        hovered: monitor.isOver(),
      };
    },
  }));

  if (highlighted) {
    return (
      <div
        className={classSet({
          'rg-rightside__emptyviewsmall': true,
          'rg-rightside__emptyviewsmall--hovered': hovered,
          'rg-rightside__emptyviewsmall--highlighted': highlighted,
        })}
        ref={drop}
      >
        <Icon component={EmptyRouteSmallSvg} />
        <p>{translate('letsCreateRoute')}</p>
      </div>
    );
  }

  return null;
};

export default observer(EmptySmallCard);
