import React from 'react';

import { useMap } from 'react-leaflet';

export function withMap(Component) {
  return function WrappedComponent(props) {
    const map = useMap();
    return <Component {...props} map={map} />;
  };
}
