import React, { ReactNode } from 'react';

import { ColumnsType } from 'antd/es/table';

import { IPermission } from 'modules/core/models/types';
import Actions from 'modules/directory/components/ActionButtons';
import { translate } from 'modules/localization';
import { IReasonDelayDeleteResponse } from 'modules/reasonsDelay/models/types';

export const getColumns = (
  baseUrl: string,
  permissions: IPermission,
  actionDelete: (guid: string) => void,
): ColumnsType<IReasonDelayDeleteResponse> => {
  return [
    {
      dataIndex: 'name',
      title: translate('shopShortName'),
      key: 'name',
    },
    {
      dataIndex: 'priority',
      title: translate('priorityOfReasonDelay'),
      key: 'priority',
    },
    {
      dataIndex: 'comment',
      render: (comment): ReactNode => <span>{comment ? translate('yes') : translate('no')}</span>,
      title: translate('requiredComment'),
      key: 'comment',
      width: '20%',
    },
    {
      dataIndex: 'active',
      render: (active): ReactNode => <span>{active ? translate('yes') : translate('no')}</span>,
      title: translate('activity'),
      key: 'active',
      width: '10%',
    },
    {
      dataIndex: 'guid',
      key: 'action',
      render: (_, item): ReactNode => (
        <Actions
          item={item}
          baseUrl={baseUrl}
          actionDelete={actionDelete}
          message={{
            deleteCancel: translate('cancel'),
            deleteTitle: translate('deleteReasonDelay'),
          }}
          permissions={{
            edit: permissions['reasons-late.update'],
            delete: permissions['reasons-late.delete'],
          }}
        />
      ),
      width: '5%',
    },
  ];
};
