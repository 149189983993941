import React from 'react';

import { Link, useLocation } from 'react-router-dom';

import { getEditUrl } from 'modules/directory/helpers';
import { translate } from 'modules/localization';

interface IProps {
  isPermitted: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: any;
  baseUrl: string;
  className?: string;
}

export default ({ isPermitted, item, baseUrl, className }: IProps) => {
  const location = useLocation<Record<string, never>>();
  const editUrl = getEditUrl(baseUrl, location, item);

  return isPermitted ? (
    <Link className={className} to={editUrl} key="edit">
      {translate('edit')}
    </Link>
  ) : null;
};
