import React from 'react';

import { observer } from 'mobx-react-lite';

import Map from 'modules/map/containers/Map';
import MarkerCluster from 'modules/map/containers/MarkerCluster';
import OrderPoint from 'modules/orders/components/OrderPoint';
import { getPointProp } from 'modules/orders/helpers/propHelper';
import WarehouseMarker from 'modules/warehouse/containers/WarehouseMarker';
import { useStore } from 'services/store';

const OrdersListMap = () => {
  const { orders, warehouses } = useStore();

  const warehousesPoints = warehouses.listAll.filter(
    (w) => orders.filter.warehouses.indexOf(w.guid) !== -1,
  );
  const ordersGeoJSON = orders.orderList
    .filter((order) => order[getPointProp(order)])
    .map((order) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [order[getPointProp(order)].lon, order[getPointProp(order)].lat],
      },
      properties: {},
    }));
  const warehousesGeoJSON = warehousesPoints
    .filter((item) => Boolean(item.point && item.point.lat && item.point.lon))
    .map((w) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [w.point.lon, w.point.lat],
      },
      properties: {},
    }));
  const mapView = [...ordersGeoJSON, ...warehousesGeoJSON];

  return (
    <Map mapView={mapView.length > 0 ? mapView : []} isSearch={false}>
      <MarkerCluster>
        {orders.orderList.map((order) => (
          <OrderPoint key={order.guid} order={order} />
        ))}
      </MarkerCluster>
      {warehousesPoints &&
        warehousesPoints.length > 0 &&
        warehousesPoints.map(
          (w) =>
            w?.point?.lat &&
            w?.point?.lon && (
              <WarehouseMarker
                key={w.guid}
                position={[w.point.lat, w.point.lon]}
                name={w.title}
                address={w.address}
              />
            ),
        )}
    </Map>
  );
};

export default observer(OrdersListMap);
