import { Input } from 'antd';

import { IFieldData } from 'helpers/form';
import { ICourier, ICouriersForm, ICouriersPayload } from 'modules/couriers/models/types';
import { translate } from 'modules/localization';
import TransportSelect from 'modules/transports/components/TransportSelect';
import ProfileSelect from 'modules/user/components/ProfileSelect';

export const getDataToSet = (item: ICourier): ICouriersForm | Record<string, never> => {
  return {
    guid: item.guid,
    profile_guid: {
      key: item.profile_guid,
      label: `${item.profile.surname} ${item.profile.name} ${
        item.profile.patronymic ? item.profile.patronymic : ' '
      }${item.profile.disabled ? `(${translate('disabled')})` : ''}`,
    },
    transports: item.transports
      ? item.transports.map((t) => {
          return {
            key: t.guid,
            label: `${t.name} ${t.number}${t.disabled ? ` (${translate('disabled')})` : ''}`,
          };
        })
      : [],
  };
};

export const getFields =
  (isView: boolean) =>
  (isEdit: boolean): IFieldData => ({
    profile_guid: {
      component: ProfileSelect,
      formItem: {
        label: translate('user'),
        className: 'couriers-form__item',
      },
      name: 'profile_guid',
      params: {
        rules: [
          {
            message: translate('requiredValidator'),
            required: true,
          },
        ],
      },
      props: {
        disabled: isView,
      },
    },
    guid: {
      component: Input,
      formItem: {
        label: translate('id'),
        className: 'couriers-form__item',
      },
      name: 'guid',
      params: {
        rules: [
          {
            message: translate('requiredValidator'),
            required: true,
          },
        ],
      },
      props: {
        placeholder: translate('enterId'),
        disabled: isEdit || isView,
      },
    },
    transports: {
      component: TransportSelect,
      formItem: {
        label: translate('transport'),
        className: 'couriers-form__item',
      },
      name: 'transports',
      props: {
        mode: 'multiple',
        disabled: isView,
      },
    },
  });

export const prepareData = (formData: ICouriersForm, isEdit: boolean): ICouriersPayload => {
  const preparedData = {
    profile_guid: formData.profile_guid
      ? typeof formData.profile_guid === 'string'
        ? formData.profile_guid.trim()
        : formData.profile_guid.key.trim()
      : null,
    transports: formData.transports
      ? formData.transports.map((t) => (typeof t === 'string' ? t : t.key))
      : [],
  };

  return isEdit
    ? preparedData
    : { ...preparedData, guid: formData.guid ? formData.guid.trim() : null };
};
