import Arm2 from 'modules/arm2/components/Arm';
import Directory from 'modules/directory/containers/Directory';
import Geo from 'modules/geo/components/Geo';
import QA from 'modules/qa';

type ISection = Record<
  string,
  {
    access?: string[] | string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: any;
  }
>;

export const SECTION_DATA: ISection = {
  arm2: {
    access: 'orders.list',
    component: Arm2,
  },
  geo: {
    access: 'coverages.list',
    component: Geo,
  },
  directories: {
    access: 'warehouses.list',
    component: Directory,
  },
  qa: {
    component: QA,
  },
};

export const SECTION_LIST: string[] = ['arm2', 'locations', 'directories', 'qa'];
