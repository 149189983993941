import React from 'react';

import AntIcon from '@ant-design/icons';

import { useDynamicSvgImport } from 'modules/common/containers/CustomIcon/useDynamicSvgImport';

interface IProps {
  type: string;
  className?: string;
}

export const CustomIcon = ({ type, ...props }: IProps) => {
  const { SvgIcon } = useDynamicSvgImport(type);
  return SvgIcon && <AntIcon component={SvgIcon} {...props} />;
};
