import Api from 'api/Api';
import {
  IServiceZonesCreateResponse,
  IServiceZonesDeleteResponse,
  IServiceZonesEditResponse,
  IServiceZonesFilter,
  IServiceZonesForm,
  IServiceZonesGetResponse,
  IServiceZonesListGetResponse,
} from 'modules/service-zones/models/types';

export default class ServiceZonesApi {
  static getServiceZonesList(
    filter: IServiceZonesFilter,
    signal?: AbortSignal,
  ): Promise<IServiceZonesListGetResponse> {
    const query = {
      page: filter.current,
      page_size: filter.pageSize,
      is_active: filter.isActive,
      location_guid: filter.locationGuid,
    };
    return Api.get('/api/v1/service-zones', query, signal);
  }

  static createServiceZone(data: IServiceZonesForm): Promise<IServiceZonesCreateResponse> {
    return Api.post(data, '/api/v1/service-zones');
  }

  static editServiceZone(
    serviceZoneGuid: string,
    data: IServiceZonesForm,
  ): Promise<IServiceZonesEditResponse> {
    return Api.patch(data, `/api/v1/service-zones/${serviceZoneGuid}`);
  }

  static deleteServiceZone(serviceZoneGuid: string): Promise<IServiceZonesDeleteResponse> {
    return Api.delete(`/api/v1/service-zones/${serviceZoneGuid}`);
  }

  static getServiceZone(serviceZoneGuid: string): Promise<IServiceZonesGetResponse> {
    return Api.get(`/api/v1/service-zones/${serviceZoneGuid}`);
  }
}
