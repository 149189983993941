import './style.less';

import React, { ReactNode, useEffect, useState } from 'react';

import { Button, Form, Modal } from 'antd';
import HTTPStatus from 'http-status';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import { getFieldDataList, renderFields } from 'helpers/form';
import { ViewNotification } from 'modules/common/containers/Notify';
import { translate } from 'modules/localization';
import ShopsSelect from 'modules/shops/components/ShopsSelect';
import { routerStore, useStore } from 'services/store';

import { getDataToSet, getFields, prepareData } from './helpers';

interface IProps {
  baseUrl: string;
}

const CouriersForm = (
  props: IProps & RouteComponentProps<{ action: string; entityGuid: string }>,
) => {
  const { core, courierDirectory, user } = useStore();
  const { entityGuid, action } = props.match.params;
  const [form] = Form.useForm();
  const [shopsDataForm, setShopsDataForm] = useState<string[]>(null);

  const userGuid = Form.useWatch('profile_guid', form);

  useEffect(() => {
    userGuid &&
      user.get(userGuid?.key || userGuid).then((user) => {
        const shops = user?.meta?.shops.map((item) => item.guid);
        Array.isArray(shops) && form.setFieldValue('shops', shops);
        setShopsDataForm(shops);
      });
  }, [userGuid]);

  useEffect(() => {
    if (entityGuid) {
      courierDirectory.get(entityGuid).then((data) => {
        form.resetFields();
        form.setFieldsValue(getDataToSet(data));
      });
    } else {
      form.resetFields();
    }
    return () => {
      setShopsDataForm(null);
    };
  }, [action]);

  const isEdit = action === 'edit';
  const isCreate = action === 'create';

  const actionReset = () => {
    routerStore.replace(`${props.baseUrl}${props.location.search}`);
  };

  const isView = !core.permissions['couriers.update'];
  const getFormFields = getFields(isView);

  const isPermittedSubmit =
    core.permissions['couriers.create'] && core.permissions['couriers.update'];

  const submitClickHandler = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    form.submit();
  };

  const renderFooter = (): ReactNode => {
    return (
      <div className="dir-form__actions">
        <Button htmlType="button" key="cancel" onClick={handleReset}>
          {translate('cancel')}
        </Button>
        {isPermittedSubmit ? (
          <Button
            htmlType="submit"
            key="save"
            onClick={submitClickHandler}
            type="primary"
            loading={courierDirectory.isLoadingForm}
          >
            {isEdit ? translate('save') : translate('add')}
          </Button>
        ) : null}
      </div>
    );
  };

  const handleReset = (e) => {
    e.preventDefault();
    actionReset();
  };

  const handleResponse = (msg: string) => () => {
    actionReset();
    ViewNotification.success({ message: msg });
  };

  const handleError = (values) => (response) => {
    if (response.http.status === HTTPStatus.BAD_REQUEST) {
      const fieldDataList = getFieldDataList(response, values);
      form.setFields(fieldDataList);
    }
  };

  const handleSubmit = (values): void => {
    const data = prepareData(values, isEdit);

    if (isEdit && entityGuid) {
      courierDirectory
        .edit(entityGuid, data)
        .then(handleResponse(translate('courierEditSuccess')))
        .catch(handleError(values));
    } else {
      courierDirectory
        .create(data)
        .then(handleResponse(translate('courierCreateSuccess')))
        .catch(handleError(values));
    }
  };

  const fieldsData = getFormFields(isEdit);

  return (
    <Modal
      centered
      className="dir-modal"
      footer={renderFooter()}
      maskClosable={false}
      onCancel={handleReset}
      onOk={handleSubmit}
      title={isEdit ? translate('courierEditTitle') : translate('courierCreateTitle')}
      open={isCreate || isEdit}
      width={null}
      destroyOnClose
    >
      <Form
        form={form}
        className="dir-form"
        layout="vertical"
        onFinish={handleSubmit}
        autoComplete="off"
        preserve={false}
      >
        {renderFields(Object.keys(fieldsData), fieldsData)}
        {shopsDataForm && (
          <Form.Item
            key="shops"
            name="shops"
            label={translate('shops')}
            className="couriers-form__item"
          >
            <ShopsSelect disabled mode="multiple" />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default withRouter(observer(CouriersForm));
