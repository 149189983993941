import React, { useState } from 'react';

import { CloseOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Spin, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import { ProductViewIcon } from 'modules/common/components/ProductViewIcon';
import { translate } from 'modules/localization';
import RelativeTasksToggler from 'modules/routes/components/RelativeTasksToggler';
import TaskTime from 'modules/routes/components/RouteInfo/TaskTime';
import { getOrderUrl } from 'modules/tasks/components/TasksList/helpers';
import { getAddressProp } from 'modules/tasks/helpers/propHelper';
import { ITask } from 'modules/tasks/models/types';
import {
  getRelativeTasksCount,
  getStatus,
  getTaskRelativeGuids,
  hasRelativeTasks,
} from 'modules/tasks/models/utils';
import { useStore } from 'services/store';

interface IProps {
  task: ITask;
  routeUpdate: () => Promise<void>;
}

const RenderTasksTimeline = ({ task, routeUpdate }: IProps) => {
  const { routes, tasks } = useStore();

  const [isRelativesVisible, setIsRelativesVisible] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const deleteTaskFromRoute = async (item: ITask) => {
    try {
      setIsLoading(true);
      const guidsToDelete = getTaskRelativeGuids(item);
      await routes.apiStore.deleteTaskFromRoute(guidsToDelete);
      await routeUpdate();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const renderRouteTask = (item: ITask, isMainTask = true) => (
    <div key={item.guid} className={isMainTask ? 'route-task' : 'route-task route-task_relative'}>
      <div className="route-task__status-time">
        <div className="route-task__status">
          <div>{getStatus(item, tasks.tasksStatuses)}</div>
          {(item.status === 'in_route' || item.status === 'in_loading_route') && (
            <Popconfirm
              onConfirm={(): Promise<void> => deleteTaskFromRoute(item)}
              placement="rightTop"
              title={translate('areYouSure')}
            >
              <Button
                type="link"
                size="small"
                icon={<CloseOutlined />}
                className="route-task_remove"
              >
                {translate('removeTaskFromRoute')}
              </Button>
            </Popconfirm>
          )}
        </div>
        <TaskTime className="route-task__time" task={item} />
      </div>
      <div className="route-task__number-address-type">
        <div className="route-task__number-address">
          {hasRelativeTasks(item) ? (
            <>
              <p className="route-task__address">
                {item?.meta?.recipient_company_short_name && (
                  <b>{item?.meta?.recipient_company_short_name}, </b>
                )}
                {item.order ? item.order[getAddressProp(item)] : null}
              </p>

              <RelativeTasksToggler
                count={getRelativeTasksCount(item)}
                isActive={isRelativesVisible}
                onClick={setIsRelativesVisible}
              />
            </>
          ) : (
            <>
              <Link className="route-task__number" to={getOrderUrl(item)}>
                #{item.number}
              </Link>
              {!!item?.order?.sale_number_list && (
                <p className="route-task__sale-numbers">
                  {item.order.sale_number_list.map((n) => (
                    <span key={n}>
                      {n.slice(0, -4)}
                      <mark>{n.slice(-4)}</mark>
                    </span>
                  ))}
                </p>
              )}
              {!!isMainTask && (
                <p className="route-task__address">
                  {item?.meta?.recipient_company_short_name && (
                    <b>{item?.meta?.recipient_company_short_name}, </b>
                  )}
                  {!!item.order && item.order[getAddressProp(item)]}
                </p>
              )}
            </>
          )}

          {!!item.phone_not_answer && (
            <Tooltip title={translate('cannotCallTheClient')}>
              <WarningOutlined style={{ marginLeft: '10px', color: 'red', fontSize: '18px' }} />
            </Tooltip>
          )}
        </div>
        <div className="route-task__type">
          <div>
            {!!task.transport_param &&
            task.transport_param.specialty &&
            Array.isArray(task.transport_param.specialty) ? (
              <ProductViewIcon productTypes={task.transport_param.specialty} />
            ) : null}
          </div>
          <div>
            {Number.isInteger(task?.meta?.total_box) &&
              `${task?.meta?.total_box}\u00A0${translate('bag', {
                count: task?.meta?.total_box,
              })}`}
            <br />
            {Number.isInteger(task?.meta?.order_number) && `№\u00A0${task?.meta?.order_number}`}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Spin spinning={isLoading}>
      {renderRouteTask(task)}
      {!!isRelativesVisible && hasRelativeTasks(task) && (
        <div className="route-task__relatives">
          {task.relative_tasks.map((t) => renderRouteTask(t, false))}
        </div>
      )}
    </Spin>
  );
};

export default observer(RenderTasksTimeline);
