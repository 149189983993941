import React from 'react';

interface IProps {
  title: string;
  content: React.ReactNode | string;
  extra?: React.ReactNode | string;
}

const ClusterSettingInfo = ({ title, content, extra }: IProps) => {
  return (
    <div>
      <div className="cluster-setting-info_title">
        {title}
        &nbsp;{extra}
      </div>
      <div className="cluster-setting-info_content">{content}</div>
    </div>
  );
};

export default ClusterSettingInfo;
