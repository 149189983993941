import React, { ReactNode, useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import { Route, Switch } from 'react-router-dom';

import ClusterModal from 'modules/clusters/components/ClusterModal';
import ClustersDirectoryBody from 'modules/clusters/components/ClustersDirectoryBody';
import ClustersHeader from 'modules/clusters/components/ClustersHeader';
import { useStore } from 'services/store';

const Index = () => {
  const baseUrl = '/directories/clusters';

  const { deliveryMethods, core } = useStore();

  useEffect(() => {
    deliveryMethods.getList({ current: 1, pageSize: 100 });
  }, []);

  const isModalPermitted =
    core.permissions['clusters.create'] || core.permissions['clusters.update'];

  const renderLayout = (): ReactNode => (
    <>
      <ClustersHeader baseUrl={baseUrl} />
      <ClustersDirectoryBody baseUrl={baseUrl} />
      {isModalPermitted && <ClusterModal baseUrl={baseUrl} />}
    </>
  );

  return (
    <Switch>
      <Route exact path={`${baseUrl}/:entityGuid/:action`} render={renderLayout} />
      <Route
        exact
        path={`${baseUrl}/:entityGuid/:action/:guidDeliveryMethod`}
        render={renderLayout}
      />
      <Route exact path={`${baseUrl}/:action`} render={renderLayout} />
      <Route exact path={baseUrl} render={renderLayout} />
    </Switch>
  );
};

export default observer(Index);
