import React, { ReactNode, useEffect } from 'react';

import { ConfigProvider, Empty } from 'antd';
import az from 'antd/es/locale/az_AZ';
import en from 'antd/es/locale/en_US';
import kk from 'antd/es/locale/kk_KZ';
import mn from 'antd/es/locale/mn_MN';
import ru from 'antd/es/locale/ru_RU';
import zh from 'antd/es/locale/zh_CN';
import { configure } from 'mobx';
import { syncHistoryWithStore } from 'mobx-react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Router as ReactRouter } from 'react-router';

import history from 'helpers/browser-history';
import { getLocaleFromLocalStorage } from 'helpers/localization';
import Router from 'modules/common/components/Router';
import { customTheme } from 'modules/common/containers/App/customTheme';
import { coreStore, routerStore } from 'services/store';

import 'dayjs/locale/az';
import 'dayjs/locale/en';
import 'dayjs/locale/kk';
import 'dayjs/locale/ru';
import 'dayjs/locale/zh';
import 'dayjs/locale/mn';

configure({
  enforceActions: 'never',
});

const App = (props: WithTranslation) => {
  const handleResize = () => {
    coreStore.setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderEmpty = (): ReactNode => <Empty />;

  const getLocale = () => {
    const locale = getLocaleFromLocalStorage();
    const getAntdLocale = () => {
      switch (locale) {
        case 'ru':
          return ru;
        case 'az-latn':
          return az;
        case 'kk':
          return kk;
        case 'zh-hans':
          return zh;
        case 'mn':
          return mn;
        default:
          return en;
      }
    };

    const antdLocale = getAntdLocale();

    return {
      ...antdLocale,
      DatePicker: {
        ...antdLocale.DatePicker,
        lang: {
          ...antdLocale.DatePicker.lang,
          placeholder: props.t('date'),
        },
      },
    };
  };

  return (
    <React.StrictMode>
      <ReactRouter history={syncHistoryWithStore(history, routerStore)}>
        <ConfigProvider renderEmpty={renderEmpty} locale={getLocale()} theme={customTheme}>
          <Router />
        </ConfigProvider>
      </ReactRouter>
    </React.StrictMode>
  );
};

export default withTranslation()(App);
