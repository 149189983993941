import { makeAutoObservable } from 'mobx';

import { RestException } from 'api/RestException';
import { IPagination } from 'api/types';
import { getLastPagePagination, isOutOfPages } from 'helpers/pagination';
import ClustersApi from 'modules/clusters/api/ClustersApi';
import { ICluster, IClusterItem, IWarehouse } from 'modules/qa/models/types';
import WarehousesApi from 'modules/warehouses/api/WarehousesApi';

import CourierCouncilMonitorApi from '../api/CourierCouncilMonitorApi';

export class CourierCouncilMonitorStore {
  clustersList: ICluster[] = [];
  warehousesList: IWarehouse[] = [];
  clusterItemsList: IClusterItem[] = [];

  sidebarType: string = null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sidebarData: any = null;

  isClearPending = false;

  constructor() {
    makeAutoObservable(this);
  }

  getWarehouses = async (pagination: IPagination): Promise<IWarehouse[]> => {
    try {
      const { data: res } = await CourierCouncilMonitorApi.getWarehouses(pagination);
      if (isOutOfPages(res.pagination)) {
        return this.getWarehouses(getLastPagePagination(res.pagination));
      }
      this.warehousesList = res.data;
      return res.data;
    } catch (e) {
      throw new RestException(e);
    }
  };

  loadWarehouse = async (guid: string): Promise<void> => {
    try {
      const { data: res } = await WarehousesApi.getWarehouse(guid);
      const entity = res.data;
      this.warehousesList = this.warehousesList.map((e) => {
        if (e.guid === guid) {
          return { ...entity, ...e };
        }
        return e;
      });
    } catch (e) {
      throw new RestException(e);
    }
  };

  getClusters = async (pagination: IPagination): Promise<ICluster[]> => {
    try {
      const { data: res } = await CourierCouncilMonitorApi.getClusters(pagination);
      if (isOutOfPages(res.pagination)) {
        return this.getClusters(getLastPagePagination(res.pagination));
      }
      this.clustersList = res.data;
      return res.data;
    } catch (e) {
      throw new RestException(e);
    }
  };

  loadCluster = async (guid: string): Promise<void> => {
    try {
      const { data: res } = await ClustersApi.getCluster(guid);
      const entity = res.data;
      this.clustersList = this.clustersList.map((e) => {
        if (e.guid === guid) {
          return { ...entity, ...e };
        }
        return e;
      });
    } catch (e) {
      throw new RestException(e);
    }
  };

  getClusterItems = async (pagination: IPagination): Promise<IClusterItem[]> => {
    try {
      const { data: res } = await CourierCouncilMonitorApi.getClustersItems(pagination);
      if (isOutOfPages(res.pagination)) {
        return this.getClusterItems(getLastPagePagination(res.pagination));
      }
      this.clusterItemsList = res.data;
      return res.data;
    } catch (e) {
      throw new RestException(e);
    }
  };

  loadWarehouseForClusterItem = async (guid: string): Promise<void> => {
    try {
      const { data: res } = await WarehousesApi.getWarehouse(guid);
      const warehouse = res.data;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.clusterItemsList = this.clusterItemsList.map((e) => {
        if (e.warehouse_guid === guid) {
          return { ...e, warehouse: warehouse };
        }
        return e;
      });
    } catch (e) {
      throw new RestException(e);
    }
  };

  setSidebarType = (type: string): void => {
    this.sidebarType = type;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSidebarData = (data: any): void => {
    this.sidebarData = data;
  };

  clearSoft = async (): Promise<void> => {
    this.isClearPending = true;
    await CourierCouncilMonitorApi.clearSoft();
    this.isClearPending = false;
  };

  clearHard = async (): Promise<void> => {
    this.isClearPending = true;
    await CourierCouncilMonitorApi.clearHard();
    this.isClearPending = false;
  };
}
