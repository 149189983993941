import React, { useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import querystring from 'query-string';

import { IFilter } from 'modules/arm2/models/types';
import { translate } from 'modules/localization';
import ShopsSelect from 'modules/shops/components/ShopsSelect';
import { useStore } from 'services/store';

interface IProps {
  setFilter: (key: string, value: string[] | string) => void;
  getList: () => void;
  value: string[];
  className?: string;
  getFilterFromLocalStorage?: () => IFilter;
}

const ShopFilter = ({
  setFilter,
  getList,
  value,
  className,
  getFilterFromLocalStorage,
}: IProps) => {
  const { shops } = useStore();

  useEffect(() => {
    const localStorageFilter = getFilterFromLocalStorage && getFilterFromLocalStorage();
    const localStorageShop = localStorageFilter && localStorageFilter.shop;
    const selectedShops = querystring.parse(window.location.search).shop || localStorageShop;

    if (selectedShops && typeof selectedShops === 'string') {
      setFilter('shop', [selectedShops]);
    }
    if (selectedShops && typeof selectedShops === 'object') {
      setFilter('shop', selectedShops);
    }
    shops.getList({ current: 1, pageSize: 100 }, false);
  }, []);

  const handleFilter = (val): void => {
    setFilter('shop', val);
    getList();
  };

  return (
    <div className={className}>
      <ShopsSelect
        mode="multiple"
        onChange={handleFilter}
        placeholder={translate('shop')}
        value={value}
        maxTagCount={3}
      />
    </div>
  );
};

export default observer(ShopFilter);
