import './style.less';

import React from 'react';

import { stopPropagation } from 'helpers/stopPropagation';
import LinkDelete from 'modules/directory/components/ActionLinks/LinkDelete';
import LinkEdit from 'modules/directory/components/ActionLinks/LinkEdit';

interface IMessage {
  deleteTitle: string;
  deleteCancel: string;
}

interface IPermissions {
  edit: boolean;
  delete: boolean;
}

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: any;
  baseUrl: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actionDelete: any;
  message: IMessage;
  permissions: IPermissions;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any;
}

export default ({ item, baseUrl, actionDelete, message, permissions, children }: IProps) => {
  return (
    <div className="action-links" onClick={stopPropagation}>
      {children}
      <LinkEdit
        className="action-link"
        isPermitted={permissions.edit}
        item={item}
        baseUrl={baseUrl}
      />
      <LinkDelete
        isPermitted={permissions.delete}
        item={item}
        actionDelete={actionDelete}
        message={message}
      />
    </div>
  );
};
