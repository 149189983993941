import Api from 'api/Api';
import { IPagination } from 'api/types';
import {
  ICitiesListGetResponse,
  ICitiesCreateResponse,
  ICitiesDeleteResponse,
  ICitiesEditResponse,
  ICityGetResponse,
  ICityPayload,
} from 'modules/cities/models/types';

export default class CitiesApi {
  static getCity(guid: string): Promise<ICityGetResponse> {
    return Api.get(`/api/v1/cities/${guid}`);
  }

  static getCitiesList(
    pagination: IPagination,
    signal?: AbortSignal,
  ): Promise<ICitiesListGetResponse> {
    const query = {
      page: pagination.current,
      page_size: pagination.pageSize,
    };
    return Api.get('/api/v1/cities', query, signal);
  }

  static createCity(data: ICityPayload): Promise<ICitiesCreateResponse> {
    return Api.post(data, '/api/v1/cities');
  }

  static editCity(guid: string, data: ICityPayload): Promise<ICitiesEditResponse> {
    return Api.put(data, `/api/v1/cities/${guid}`);
  }

  static deleteCity(guid: string): Promise<ICitiesDeleteResponse> {
    return Api.delete(`/api/v1/cities/${guid}`);
  }

  static searchCities(
    search?: string,
    pagination?: IPagination,
    signal?: AbortSignal,
  ): Promise<ICitiesListGetResponse> {
    const query = {
      page: pagination.current,
      page_size: pagination.pageSize,
      q: search,
    };
    return Api.get('/api/v1/cities', query, signal);
  }
}
