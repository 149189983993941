import { IPermission } from 'modules/core/models/types';

export const isPermitted = (
  accountPermissions: IPermission,
  targetPermissions?: string[] | string,
) => {
  if (!accountPermissions) {
    return false;
  }
  if (!targetPermissions) {
    return true;
  }
  if (typeof targetPermissions === 'string') {
    return !!accountPermissions[targetPermissions];
  }
  return targetPermissions.reduce((acc, item) => {
    return !!accountPermissions[item] && acc;
  }, true);
};
