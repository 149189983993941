import L from 'leaflet';
import differenceBy from 'lodash.differenceby';
import { makeAutoObservable } from 'mobx';
import querystring from 'query-string';

import { initialFilter } from 'modules/coverages/models/initial';
import {
  ICoverage,
  ICoverageByWarehouse,
  ICoveragesFilter,
  ICoveragesPolygon,
} from 'modules/coverages/models/types';
import { routerStore } from 'services/store';

import { CoverageApiStore } from './CoverageApiStore';

export class CoverageStore {
  apiStore: CoverageApiStore;

  hoveredGuid: string = null;
  coveragesList: ICoverage[] = [];
  coveragesListByOrder: ICoverageByWarehouse[] = [];
  coveragesListView: ICoverage[] = [];
  coveragesListLocal: ICoverage[] = [];
  activeRequestCoveragesList: AbortController[] | null = null;
  loadingForm = false;
  polygon: ICoveragesPolygon = null;
  filter: ICoveragesFilter = initialFilter;
  isCoveragesFormVisible = true;
  coverageEditableGuid: string = null;
  activeRequestNominatimPolygon: AbortController | null = null;
  map: L.Map = null;
  mapZoom = 0;
  adjacentCoveragesListView: ICoverage[] = [];
  activeRequestAdjacentCoveragesList: AbortController[] | null = null;

  get isLoadingCoveragesList() {
    return this.activeRequestCoveragesList !== null;
  }

  get isLoadingAdjacentCoveragesList() {
    return this.activeRequestAdjacentCoveragesList !== null;
  }

  constructor() {
    this.apiStore = new CoverageApiStore(this);
    makeAutoObservable(this);
  }

  setHovered = (guid: string): void => {
    this.hoveredGuid = guid;
  };

  setPolygon = (polygon: ICoveragesPolygon): void => {
    this.polygon = polygon;
  };

  setFilter = (key: string, value: boolean | number | string): void => {
    this.filter[key] = value;
  };

  resetFilter = (): void => {
    this.filter = initialFilter;
  };

  setCoveragesList = (list: ICoverage[]): void => {
    this.coveragesList = list;
  };

  setCoveragesListByOrder = (list: ICoverageByWarehouse[]): void => {
    this.coveragesListByOrder = list;
  };

  setCoveragesListView = (list: ICoverage[]): void => {
    this.coveragesListView = list;
  };

  setAdjacentCoveragesListView = (list: ICoverage[]): void => {
    this.adjacentCoveragesListView = differenceBy(list, this.coveragesListView, 'guid');
  };

  setCoveragesFormVisible = (isCoveragesFormVisible: boolean): void => {
    this.isCoveragesFormVisible = isCoveragesFormVisible;
  };

  setCoveragesListLocal = (list: ICoverage[]): void => {
    this.coveragesListLocal = list;
  };

  removeCoverageFromLocalList = (guid: string): void => {
    this.setCoveragesListLocal(this.coveragesListLocal.filter((c) => c.guid !== guid));
    this.setCoveragesListView(this.coveragesListLocal);
  };

  setCoverageEditableGuid = (guid: string): void => {
    this.coverageEditableGuid = guid;
  };

  setMap = (map: L.Map) => {
    this.map = map;

    this.map.on('zoomend', () => {
      this.setZoom(this.map.getZoom());
    });
  };

  setZoom = (level) => {
    this.mapZoom = level;
  };

  setFilterToUrl = (filter: ICoveragesFilter = this.filter): void => {
    const qs = querystring.stringify(
      {
        delivery_method_guid: filter.deliveryMethodGuid,
        is_active: filter.isActive,
      },
      { skipNull: true },
    );
    routerStore.replace(`${routerStore.location.pathname}?${qs}`);
  };
}
