import { LOCAL_STORAGE } from 'constants/index';
import { ITasksFilter } from 'modules/tasks/models/types';

export const getTasksFilter = (): ITasksFilter => {
  const filterString = localStorage.getItem(LOCAL_STORAGE.TASKS_FILTER);
  return JSON.parse(filterString);
};

export const setTasksFilter = (filter: ITasksFilter): void => {
  const filterString = JSON.stringify(filter);
  localStorage.setItem(LOCAL_STORAGE.TASKS_FILTER, filterString);
};
