import React, { useState } from 'react';

import { Modal } from 'antd';

import { stopPropagation } from 'helpers/stopPropagation';
import { translate } from 'modules/localization';

interface IProps {
  isPermitted: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actionDelete: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  message: any;
}

export default ({ isPermitted, item, actionDelete, message }: IProps) => {
  const handleDelete = (item) => (e) => {
    stopPropagation(e);

    actionDelete(item.guid);
  };

  const [isModalOpen, toggleModal] = useState(false);
  const openModal = () => {
    toggleModal(true);
  };
  const closeModal = () => {
    toggleModal(false);
  };

  return isPermitted ? (
    <>
      <a className="action-link" href="#" onClick={openModal}>
        {translate('delete')}
      </a>
      <Modal
        title={message.deleteTitle}
        open={isModalOpen}
        onCancel={closeModal}
        className="action-link__delete-modal"
        onOk={handleDelete(item)}
        destroyOnClose
        style={{ maxWidth: '360px' }}
      >
        {translate('defaultConfirm', { content: message.deleteTitle.toLowerCase() })}
      </Modal>
    </>
  ) : null;
};
