import './style.less';

import React, { useEffect, useState } from 'react';

import { ArrowLeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { GEO_BASE_URL } from 'constants/index';
import { getFieldDataList } from 'helpers/form';
import { PageTitle } from 'modules/common/components/HeaderMobile';
import Title from 'modules/common/components/Title';
import Loader from 'modules/common/containers/Loader';
import { translate } from 'modules/localization';
import LocationsMap from 'modules/locations/components/LocationsMap';
import LocationsSelect from 'modules/locations/components/LocationsSelect';
import { ILocation, ILocationForm } from 'modules/locations/models/types';
import MapCollapse from 'modules/map/containers/MapCollapse';
import WarehousesSelect from 'modules/warehouses/components/WarehousesSelect';
import { useStore } from 'services/store';

import { prepareData } from './helpers';

interface IParams {
  locationGuid: string;
  childrenLocationGuid: string;
}

interface IProps {
  baseUrl: string;
  actionName: string;
}

const LocationsForm = ({ baseUrl, actionName }: IProps) => {
  const { locations, router, core } = useStore();
  const { locationGuid, childrenLocationGuid } = useParams<IParams>();

  const [isRedirectToCoverageForm, setRedirectToCoverageForm] = useState(false);
  const [form] = Form.useForm<ILocationForm>();

  const [parentLocation, setParentLocation] = useState<ILocation>(null);

  useEffect(() => {
    if (actionName === 'create') {
      form.setFieldsValue({
        scale: locations.coordinatesMap.zoomLevel,
      });
      if (locationGuid) {
        locations.apiStore
          .getLocation(locationGuid)
          .then((values) => {
            form.setFieldsValue({
              parent_guid: locationGuid || null,
            });
            setParentLocation(values);
          })
          .catch(() => router.push(GEO_BASE_URL));
      }
    } else if (actionName === 'edit' && (childrenLocationGuid || locationGuid)) {
      locations.apiStore
        .getLocation(childrenLocationGuid || locationGuid)
        .then((values) => {
          form.setFieldsValue({
            name: values.name,
            disabled: !values.disabled,
            parent_guid: values.parent_guid || undefined,
            scale: values.scale,
            warehouses: values.warehouses
              ? values.warehouses.map((warehouse) => warehouse.guid)
              : [],
          });
          locations.setCoordinatesMap({
            lat: values.point.lat,
            lon: values.point.lon,
          });
        })
        .catch(() => redirectToLocationsList());
    }
  }, []);

  useEffect(() => {
    form.setFieldsValue({ scale: locations.coordinatesMap.zoomLevel });
  }, [locations.coordinatesMap.zoomLevel]);

  const handleReset = (): void => {
    redirectToLocationsList();
  };

  const handleError = (values) => (response) => {
    const fieldDataList = getFieldDataList(response, values);
    form.setFields(fieldDataList);
  };

  const handleSubmit = (values): void => {
    const data = {
      ...values,
      scale: locations.coordinatesMap.zoomLevel,
      point: {
        lat: locations.coordinatesMap.lat,
        lon: locations.coordinatesMap.lon,
      },
    };
    const action =
      actionName === 'create'
        ? locations.apiStore.createLocation(prepareData(data))
        : locations.apiStore.editLocation(childrenLocationGuid || locationGuid, prepareData(data));

    action
      .then((data) =>
        isRedirectToCoverageForm ? redirectToCoveragesForm(data.guid) : redirectToLocationsList(),
      )
      .catch(handleError(values));
  };

  const redirectToLocationsList = () => {
    locations.setViewLocation({ ...locations.viewLocation, coverages: 0 });
    router.push({
      pathname: baseUrl,
      search: router.location.search,
    });
  };

  const redirectToCoveragesForm = (guid: string) => {
    router.push({
      pathname: `${GEO_BASE_URL}/${guid}/coverages/create`,
    });
  };

  const goToCoverageForm = (e) => {
    e.preventDefault();
    setRedirectToCoverageForm(true);
    form.submit();
  };

  const isView = !core.permissions['locations.update'];

  return (
    <div className="locations-layout">
      <div className="locations-layout__content">
        {core.isMobile ? (
          <PageTitle>
            <a onClick={handleReset}>
              <ArrowLeftOutlined />{' '}
              {actionName === 'edit' ? locations.viewLocation.name : translate('locationsList')}
            </a>
          </PageTitle>
        ) : null}
        <Form className="locations__form" layout="vertical" form={form} onFinish={handleSubmit}>
          <div className="locations__form-body">
            <div className="locations__form-header">
              {!core.isMobile && (
                <Button type="link" className="locations__form-back-link" onClick={handleReset}>
                  <ArrowLeftOutlined />{' '}
                  {actionName === 'edit' ? locations.viewLocation.name : translate('locationsList')}
                </Button>
              )}
              <Title size={Title.SIZE.H3} weight={Title.WEIGHT.SEMIBOLD}>
                {actionName === 'edit'
                  ? translate('locationEditTitle')
                  : translate('locationCreateTitle')}
              </Title>
            </div>
            {core.isMobile ? (
              <MapCollapse>
                <LocationsMap action={actionName} />
              </MapCollapse>
            ) : null}
            <div>
              <div className="locations__form-fields">
                <Form.Item
                  label={translate('title')}
                  name="name"
                  rules={[{ required: true, message: translate('requiredValidator') }]}
                >
                  <Input placeholder={translate('enterTitle')} disabled={isView} />
                </Form.Item>
                <Form.Item label={translate('parentLocation')} name="parent_guid">
                  <LocationsSelect
                    placeholder={translate('enterLocation')}
                    disabled
                    locationParent={parentLocation || locations.locationData.parent}
                  />
                </Form.Item>
                <Form.Item label={translate('warehouses')} name="warehouses">
                  <WarehousesSelect disabled={isView} maxTagCount={3} />
                </Form.Item>
                <Form.Item
                  label={translate('status')}
                  name="disabled"
                  valuePropName="checked"
                  initialValue
                >
                  <Checkbox disabled={isView}>{translate('activity')}</Checkbox>
                </Form.Item>
                <Form.Item
                  extra={translate('enterLocationCenter')}
                  label={translate('positionOnTheMap')}
                  name="scale"
                  className="locations__item_hide"
                  rules={[
                    {
                      validator: (_, value) => {
                        return value && value > 0
                          ? Promise.resolve()
                          : Promise.reject(translate('moreThanNullZoomValidator'));
                      },
                    },
                  ]}
                >
                  <Input disabled={isView} />
                </Form.Item>
              </div>
            </div>
          </div>
          <Spin
            indicator={<Loader show />}
            spinning={locations.loadingForm}
            wrapperClassName="locations__form-forward"
          >
            <Link to="#" onClick={goToCoverageForm}>
              {translate('goToCoverageCreation')}
              <RightOutlined className="locations__form-forward-icon" />
            </Link>
            <div className="locations__form-forward-comment">
              {translate('locationWillBeSaved')}
            </div>
          </Spin>

          <Spin
            indicator={<Loader show />}
            spinning={locations.loadingForm}
            wrapperClassName="locations__form-footer"
          >
            <Button htmlType="button" key="cancel" onClick={handleReset}>
              {translate('cancel')}
            </Button>
            {actionName === 'edit' && core.permissions['locations.update'] ? (
              <Button htmlType="submit" key="save" type="primary">
                {translate('save')}
              </Button>
            ) : null}
            {actionName === 'create' && core.permissions['locations.create'] ? (
              <Button htmlType="submit" key="save" type="primary">
                {translate('add')}
              </Button>
            ) : null}
          </Spin>
        </Form>
      </div>

      {!core.isMobile && (
        <div className="locations-layout__map">
          <LocationsMap action={actionName} />
        </div>
      )}
    </div>
  );
};

export default observer(LocationsForm);
