import React from 'react';

import { Tooltip } from 'antd';

import { getCurrentUTCTimezone } from 'helpers/date';
import DatePicker, { IDatePickerProps } from 'modules/common/containers/DatePicker';

type IProps = IDatePickerProps & {
  onVisibleChange?: (isVisible: boolean) => void;
};

const StartTimeRateDatepicker = ({ onVisibleChange, ...rest }: IProps) => {
  return (
    <Tooltip title={getCurrentUTCTimezone()} placement="right" onOpenChange={onVisibleChange}>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/*// @ts-ignore*/}
      <DatePicker {...rest} getPopupContainer={(el) => el} />
    </Tooltip>
  );
};

export default StartTimeRateDatepicker;
