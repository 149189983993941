import './style.less';

import React, { useState } from 'react';

import { Button, Form, Select } from 'antd';
import { MultiPolygon, Polygon } from 'geojson';
import debounce from 'lodash.debounce';
import { observer } from 'mobx-react-lite';

import { DEFAULT_SELECT_PROPS } from 'modules/common/components/Select';
import { ICoveragesPolygon, INominatim } from 'modules/coverages/models/types';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

interface IProps {
  isView: boolean;
}

const SetSearchPolygon = ({ isView }: IProps) => {
  const { coverage } = useStore();

  const [options, setOptions] = useState<INominatim<MultiPolygon | Polygon>[]>([]);
  const [polygon, setPolygonNominatim] = useState<INominatim<MultiPolygon | Polygon>>(null);

  const fetchOptions = (value: string): void => {
    if (value && value.length > 1) {
      coverage.apiStore.getNominatimPolygon(value).then((values) => {
        setOptions(values);
      });
    } else {
      setOptions([]);
    }
  };

  const handleChange = debounce(fetchOptions, 300);

  const onChangePolygon = (itemId: string): void => {
    setPolygonNominatim(options.find((item) => item.place_id === itemId));
  };

  const setPolygonOnMap = (): void => {
    const newPolygon: ICoveragesPolygon = {
      type: 'Feature',
      properties: null,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      geometry: {
        ...polygon.geojson,
      },
    };
    coverage.setPolygon(newPolygon);
  };

  return (
    <Form.Item label={translate('autoDrawPolygon')} className="set-search-polygon">
      <Select
        className="set-search-polygon__input"
        suffixIcon={null}
        placeholder={translate('enterLocationName')}
        showSearch
        filterOption={false}
        onSearch={handleChange}
        onChange={onChangePolygon}
        getPopupContainer={(el) => el}
        disabled={isView}
        {...DEFAULT_SELECT_PROPS}
      >
        {options.map((d) => (
          <Select.Option key={d.place_id} value={d.place_id}>
            {d.display_name}
          </Select.Option>
        ))}
      </Select>
      <Button
        htmlType="button"
        onClick={setPolygonOnMap}
        type="link"
        disabled={!polygon}
        className="set-search-polygon__button"
      >
        {translate('autoDraw')}
      </Button>
    </Form.Item>
  );
};

export default observer(SetSearchPolygon);
