import { IPagination } from 'api/types';
import { DEFAULT_PAGINATION } from 'constants/index';
import { IWeekdayIDEnum } from 'modules/schedules/models/apitypes';
import { IWeekday, IWeekdayTitleEnum } from 'modules/schedules/models/uitypes';

export const initialWeek: IWeekday[] = [
  {
    id: IWeekdayIDEnum.MONDAY,
    title: IWeekdayTitleEnum.MONDAY,
    timeslots: [],
  },
  {
    id: IWeekdayIDEnum.TUESDAY,
    title: IWeekdayTitleEnum.TUESDAY,
    timeslots: [],
  },
  {
    id: IWeekdayIDEnum.WEDNESDAY,
    title: IWeekdayTitleEnum.WEDNESDAY,
    timeslots: [],
  },
  {
    id: IWeekdayIDEnum.THURSDAY,
    title: IWeekdayTitleEnum.THURSDAY,
    timeslots: [],
  },
  {
    id: IWeekdayIDEnum.FRIDAY,
    title: IWeekdayTitleEnum.FRIDAY,
    timeslots: [],
  },
  {
    id: IWeekdayIDEnum.SATURDAY,
    title: IWeekdayTitleEnum.SATURDAY,
    timeslots: [],
  },
  {
    id: IWeekdayIDEnum.SUNDAY,
    title: IWeekdayTitleEnum.SUNDAY,
    timeslots: [],
  },
];

export const initialPagination: IPagination = DEFAULT_PAGINATION;
