import { LOCAL_STORAGE } from 'constants/index';

import { IRoutesFilter } from '../models/types';

export const getRoutesFilter = (): IRoutesFilter => {
  const filterString = localStorage.getItem(LOCAL_STORAGE.ROUTES_FILTER);
  return JSON.parse(filterString);
};

export const setRoutesFilter = (filter: IRoutesFilter): void => {
  const filterString = JSON.stringify(filter);
  localStorage.setItem(LOCAL_STORAGE.ROUTES_FILTER, filterString);
};
