import React, { ReactNode, forwardRef } from 'react';

import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { observer } from 'mobx-react-lite';
import { BaseSelectRef } from 'rc-select/lib/BaseSelect';

import { DEFAULT_SELECT_PROPS } from 'modules/common/components/Select';
import { translate } from 'modules/localization';
import { ITimezone, TIMEZONES } from 'modules/warehouses/components/Form/constants';

const TimezoneSelect = forwardRef<BaseSelectRef, SelectProps>(({ onChange, ...rest }, ref) => {
  const getOptions = () => {
    const options = TIMEZONES;
    if (options && Array.isArray(options)) {
      return options;
    }
    return [];
  };

  const onChangeValue = (value, option): void => {
    onChange(value, option);
  };

  const renderOption = (option: ITimezone): ReactNode => {
    if (option) {
      return (
        <Select.Option key={option.utc} value={option.utc}>
          {option.text}
        </Select.Option>
      );
    }

    return null;
  };

  const options = getOptions();

  return (
    <Select
      ref={ref}
      allowClear
      getPopupContainer={(el) => el}
      onChange={onChangeValue}
      placeholder={translate('enterTimezone')}
      {...rest}
      {...DEFAULT_SELECT_PROPS}
    >
      {options.map(renderOption)}
    </Select>
  );
});

export default observer(TimezoneSelect);
