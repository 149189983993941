import React from 'react';

import { FireOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import classSet from 'helpers/classSet';
import { ITask } from 'modules/tasks/models/types';

interface IProps {
  task: ITask;
}

const TaskDateTime = ({ task }: IProps) => {
  return (
    <div
      className={classSet({
        'rg-task__datetime': true,
        'rg-task__datetime--problems': task.problems && task.problems.length > 0,
      })}
    >
      {task.problems && task.problems.length > 0 && <FireOutlined />}
      {task.time_slot_start}&nbsp;-&nbsp;{task.time_slot_end}
      &nbsp;/&nbsp;
      {dayjs(task.delivery_date).format('DD.MM')}
    </div>
  );
};

export default TaskDateTime;
