import { action, makeObservable, observable } from 'mobx';

import { RestException } from 'api/RestException';
import {
  IApiResponseErrorMD2,
  IApiResponsePaginationMD2,
  IGenApiResponseMD2,
  IGenResponseAxios,
  IPagination,
} from 'api/types';
import { DEFAULT_PAGINATION } from 'constants/index';
import { getLastPagePagination, getResponsePagination, isOutOfPages } from 'helpers/pagination';
import { routerStore } from 'services/store';

interface BaseEntity {
  guid: string;
}

export type DirectoryApi<Entity extends BaseEntity, EntityForm> = {
  getEntity: (
    guid: string,
  ) => Promise<
    IGenResponseAxios<IGenApiResponseMD2<Entity, IApiResponsePaginationMD2, IApiResponseErrorMD2>>
  >;
  getEntityList: (
    pagination: IPagination,
    signal?: AbortSignal,
  ) => Promise<
    IGenResponseAxios<IGenApiResponseMD2<Entity[], IApiResponsePaginationMD2, IApiResponseErrorMD2>>
  >;
  search: (
    q: string,
    pagination: IPagination,
    signal?: AbortSignal,
  ) => Promise<
    IGenResponseAxios<IGenApiResponseMD2<Entity[], IApiResponsePaginationMD2, IApiResponseErrorMD2>>
  >;
  createEntity: (
    data: EntityForm,
  ) => Promise<
    IGenResponseAxios<IGenApiResponseMD2<Entity, IApiResponsePaginationMD2, IApiResponseErrorMD2>>
  >;
  editEntity: (
    guid: string,
    data: EntityForm,
  ) => Promise<
    IGenResponseAxios<IGenApiResponseMD2<Entity, IApiResponsePaginationMD2, IApiResponseErrorMD2>>
  >;
  deleteEntity: (
    guid: string,
  ) => Promise<
    IGenResponseAxios<
      IGenApiResponseMD2<{ guid: string }, IApiResponsePaginationMD2, IApiResponseErrorMD2>
    >
  >;
};

export class DirectoryApiStore<Entity extends BaseEntity, EntityForm> {
  api: DirectoryApi<Entity, EntityForm>;
  baseUrl: string;

  list: Entity[] = [];
  activeRequestlist: AbortController | null = null;
  isLoadingForm = false;
  pagination: IPagination = DEFAULT_PAGINATION;
  searchQuery: string = '';
  listAll: Entity[] = [];

  constructor(api: DirectoryApi<Entity, EntityForm>, baseUrl: string) {
    this.api = api;
    this.baseUrl = baseUrl;

    makeObservable(this, {
      list: observable,
      getList: action.bound,
      get: action.bound,
      searchQuery: observable,
      setSearchQuery: action.bound,
      create: action.bound,
      edit: action.bound,
      delete: action.bound,
      listAll: observable,
      getAll: action.bound,
      search: action.bound,
      isLoadingForm: observable,
      activeRequestlist: observable,
    });
  }

  get isLoadingList() {
    return this.activeRequestlist !== null;
  }

  get = async (guid: string): Promise<Entity> => {
    try {
      const { data: res } = await this.api.getEntity(guid);
      return res.data;
    } catch (e) {
      throw new RestException(e);
    }
  };

  async getList(pagination: IPagination, isRedirect = true): Promise<Entity[]> {
    try {
      this.activeRequestlist && this.activeRequestlist.abort();
      this.activeRequestlist = new AbortController();
      const request = this.activeRequestlist;

      const { data: res } = await this.api.getEntityList(pagination, request.signal);
      if (isOutOfPages(res.pagination)) {
        return this.getList(getLastPagePagination(res.pagination), isRedirect);
      }
      if (isRedirect && !res.errors && res.pagination) {
        routerStore.history.location.pathname !== '/error' &&
          routerStore.history.location.pathname === this.baseUrl &&
          routerStore.replace(
            `${this.baseUrl}?page=${res.pagination.page}&page_size=${res.pagination.page_size}`,
          );
        this.pagination = getResponsePagination(res.pagination);
      }
      this.list = res.data;
      return res.data;
    } catch (e) {
      throw new RestException(e);
    } finally {
      if (this.activeRequestlist && !this.activeRequestlist.signal.aborted) {
        this.activeRequestlist = null;
      }
    }
  }

  async search(query: string, pagination: IPagination): Promise<Entity[]> {
    try {
      this.activeRequestlist && this.activeRequestlist.abort();
      this.activeRequestlist = new AbortController();
      const request = this.activeRequestlist;

      const { data: res } = await this.api.search(query, pagination, request.signal);
      this.list = res.data;
      if (!res.errors && res.pagination) {
        routerStore.history.location.pathname !== '/error' &&
          routerStore.history.location.pathname === this.baseUrl &&
          routerStore.replace(
            `${this.baseUrl}?page=${res.pagination.page}&page_size=${res.pagination.page_size}&q=${query}`,
          );
        this.pagination = getResponsePagination(res.pagination);
      }
      return res.data;
    } catch (e) {
      throw new RestException(e);
    } finally {
      if (this.activeRequestlist && !this.activeRequestlist.signal.aborted) {
        this.activeRequestlist = null;
      }
    }
  }

  setSearchQuery = (query: string): void => {
    this.searchQuery = query;
  };

  create = async (data: EntityForm): Promise<string> => {
    try {
      this.isLoadingForm = true;
      const { data: res } = await this.api.createEntity(data);
      this.setSearchQuery('');
      await this.getList({ ...this.pagination, current: 1 });
      this.isLoadingForm = false;
      return res?.data?.guid;
    } catch (e) {
      this.isLoadingForm = false;
      throw new RestException(e);
    }
  };

  async edit(guid: string, data: EntityForm): Promise<void> {
    try {
      this.isLoadingForm = true;
      await this.api.editEntity(guid, data);
      this.setSearchQuery('');
      await this.getList({ ...this.pagination, current: 1 });
      this.isLoadingForm = false;
    } catch (e) {
      this.isLoadingForm = false;
      throw new RestException(e);
    }
  }

  delete = async (guid: string): Promise<void> => {
    try {
      await this.api.deleteEntity(guid);
      this.setSearchQuery('');
      await this.getList({ ...this.pagination, current: 1 });
    } catch (e) {
      throw new RestException(e);
    }
  };

  async getAll(): Promise<Entity[]> {
    try {
      const pageSize = 100;
      const { data: res } = await this.api.getEntityList({
        current: 1,
        pageSize,
      });
      const count = Math.ceil(res.pagination.total / res.pagination.page_size);

      if (count > 1) {
        const promises = [];
        for (let current = 2; current <= count; current++) {
          promises.push(this.api.getEntityList({ current, pageSize }));
        }
        const shopsList = [
          ...res.data,
          ...(await Promise.all(promises)).reduce(
            (acc, previous) => [...acc, ...previous.data.data],
            [],
          ),
        ];
        this.listAll = shopsList;
        return shopsList;
      }

      this.listAll = res.data;
      return res.data;
    } catch (e) {
      throw new RestException(e);
    }
  }
}
