import Api from 'api/Api';
import {
  ILocation,
  ILocationsCreateResponse,
  ILocationsDeleteResponse,
  ILocationsEditResponse,
  ILocationsGetResponse,
  ILocationsListGetResponse,
} from 'modules/locations/models/types';

export default class LocationsApi {
  static getLocationsList(
    currentPage: number,
    pageSize: number,
    signal?: AbortSignal,
  ): Promise<ILocationsListGetResponse> {
    const query = {
      page: currentPage,
      page_size: pageSize,
    };
    return Api.get('/api/v1/locations', query, signal);
  }

  static getLocationsChildrenList(
    currentPage: number,
    pageSize: number,
    parentGuid: string,
    signal?: AbortSignal,
  ): Promise<ILocationsListGetResponse> {
    const query = {
      page: currentPage,
      page_size: pageSize,
    };
    return Api.get(`/api/v1/locations/${parentGuid}/children`, query, signal);
  }

  static createLocation(data: ILocation): Promise<ILocationsCreateResponse> {
    return Api.post(data, '/api/v1/locations');
  }

  static editLocation(locationGuid: string, data: ILocation): Promise<ILocationsEditResponse> {
    return Api.patch(data, `/api/v1/locations/${locationGuid}`);
  }

  static deleteLocation(locationGuid: string): Promise<ILocationsDeleteResponse> {
    return Api.delete(`/api/v1/locations/${locationGuid}`);
  }

  static getLocation(locationGuid: string): Promise<ILocationsGetResponse> {
    return Api.get(`/api/v1/locations/${locationGuid}`);
  }

  static searchLocations(
    currentPage: number,
    pageSize: number,
    search: string,
    parentGuidList: string,
    signal?: AbortSignal,
  ): Promise<ILocationsListGetResponse> {
    const params = {
      page: currentPage,
      page_size: pageSize,
      q: search,
      parent_guid_list: parentGuidList,
    };
    return Api.get('/api/v1/search/locations', params, signal);
  }
}
