import React, { useState } from 'react';

import { DownOutlined } from '@ant-design/icons';
import { Button, Divider, Dropdown } from 'antd';
import { observer } from 'mobx-react-lite';

import { translate } from 'modules/localization';
import { initialWeek as WEEKDAYS } from 'modules/schedules/models/initial';
import { IWeekday } from 'modules/schedules/models/uitypes';
import { useStore } from 'services/store';

interface IProps {
  weekday: IWeekday;
}

const ApplyToAll = ({ weekday }: IProps) => {
  const { schedules } = useStore();

  const [visible, setVisible] = useState(false);
  const [selectedKeys, updateSelectedKeys] = useState([]);

  const removeDayFromSelected = (dayId): void => {
    updateSelectedKeys([...selectedKeys].filter((key) => key !== dayId));
  };

  const handleVisibleChange = (flag): void => {
    setVisible(flag);
  };

  const addDayToSelected = (dayId): void => {
    updateSelectedKeys([...selectedKeys, dayId]);
  };

  const selectAll = (currentDayId): void => {
    const allDays = WEEKDAYS.filter(({ id }) => id !== currentDayId).map(({ id }) => id.toString());
    updateSelectedKeys(allDays);
  };

  const applyToAll = (): void => {
    schedules.scheduleActionApplyTimeslotsToOtherDays(weekday.id, selectedKeys);
    updateSelectedKeys([]);
    setVisible(false);
  };

  const handleMenuClick = ({ key }): void => {
    addDayToSelected(key);
  };

  const menu = WEEKDAYS.map((day: IWeekday) => ({
    disabled: weekday.id === day.id,
    label: translate(day.title),
    key: day.id,
    className: 'apply-to-all__item',
  }));

  return (
    <div className="apply-to-all">
      <Dropdown
        menu={{
          items: menu,
          multiple: true,
          onDeselect: ({ key }): void => removeDayFromSelected(key),
          onClick: handleMenuClick,
          selectable: true,
          selectedKeys: selectedKeys,
        }}
        dropdownRender={(menu) => (
          <div className="dropdown-content">
            <Button
              className="dropdown-button"
              htmlType="button"
              onClick={(): void => selectAll(weekday.id)}
              type="primary"
              ghost
            >
              {translate('selectAll')}
            </Button>
            <Divider style={{ margin: 0 }} />
            {menu}
            <Divider style={{ margin: 0 }} />
            <Button
              className="dropdown-button"
              htmlType="button"
              onClick={applyToAll}
              type="primary"
            >
              {translate('apply')}
            </Button>
          </div>
        )}
        trigger={['click']}
        onOpenChange={handleVisibleChange}
        open={visible}
      >
        <a className="ant-dropdown-link" href="#" tabIndex={0}>
          {translate('applyToOtherDays')}
          <DownOutlined />
        </a>
      </Dropdown>
    </div>
  );
};

export default observer(ApplyToAll);
