import { DirectoryApi, DirectoryApiStore } from 'modules/common/store/DirectoryApiStore';

import CitiesApi from '../api/CitiesApi';
import { ICities, ICityPayload } from '../models/types';

const Api: DirectoryApi<ICities, ICityPayload> = {
  getEntity: CitiesApi.getCity,
  getEntityList: CitiesApi.getCitiesList,
  search: CitiesApi.searchCities,
  createEntity: CitiesApi.createCity,
  editEntity: CitiesApi.editCity,
  deleteEntity: CitiesApi.deleteCity,
};
export class CitiesStore extends DirectoryApiStore<ICities, ICityPayload> {
  constructor() {
    super(Api, '/directories/cities');
  }
}
