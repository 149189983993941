import { makeAutoObservable } from 'mobx';

import { RestException } from 'api/RestException';
import { IPagination } from 'api/types';
import { getLastPagePagination, getResponsePagination, isOutOfPages } from 'helpers/pagination';
import { getQueryString } from 'helpers/url';
import SchedulesApi from 'modules/schedules/api/SchedulesApi';
import { serializeScheduleResponse } from 'modules/schedules/helpers';
import { ISchedules } from 'modules/schedules/models/apitypes';
import { IScheduleFormData } from 'modules/schedules/models/uitypes';
import { routerStore } from 'services/store';

import { SchedulesStore } from './SchedulesStore';

export class SchedulesApiStore {
  root: SchedulesStore;

  constructor(root: SchedulesStore) {
    this.root = root;
    makeAutoObservable(this);
  }

  getSchedules = async (
    pagination: IPagination,
    locationGuid?: string,
    searchQuery?: string,
  ): Promise<ISchedules[]> => {
    try {
      this.root.isLoadingSchedulesList = true;
      const { data: res } = await SchedulesApi.getSchedulesList(
        pagination.current,
        pagination.pageSize,
        locationGuid,
        searchQuery,
      );
      if (isOutOfPages(res.pagination)) {
        return this.getSchedules(getLastPagePagination(res.pagination), locationGuid, searchQuery);
      }
      this.root.schedulesList = res.data;
      this.root.isLoadingSchedulesList = false;
      if (!res.errors && res.pagination) {
        const qs = getQueryString({
          page: res.pagination.page,
          page_size: res.pagination.page_size,
          q: searchQuery,
        });
        routerStore.history.location.pathname !== '/error' &&
          routerStore.replace(`/geo/schedules?${qs}`);
        this.root.schedulesPagination = getResponsePagination(res.pagination);
      }
      return res.data;
    } catch (e) {
      this.root.isLoadingSchedulesList = false;
      throw new RestException(e);
    }
  };

  createSchedule = async (data: ISchedules): Promise<ISchedules> => {
    try {
      this.root.loadingForm = true;
      const { data: res } = await SchedulesApi.createSchedule(data);
      this.root.resetFormData();
      this.root.loadingForm = false;
      return res.data;
    } catch (e) {
      this.root.loadingForm = false;
      throw new RestException(e);
    }
  };

  editSchedule = async (scheduleGuid: string, data: ISchedules): Promise<ISchedules> => {
    try {
      this.root.loadingForm = true;
      const { data: res } = await SchedulesApi.editSchedule(scheduleGuid, data);
      this.root.resetFormData();
      this.root.loadingForm = false;
      return res.data;
    } catch (e) {
      this.root.loadingForm = false;
      throw new RestException(e);
    }
  };

  getSchedule = async (scheduleGuid: string): Promise<IScheduleFormData> => {
    try {
      const { data: res } = await SchedulesApi.getSchedule(scheduleGuid);
      const averageSpeed =
        res.data.time_slots && res.data.time_slots.length > 0 && res.data.time_slots[0].settings
          ? res.data.time_slots[0].settings.average_speed
          : null;
      this.root.schedulesWeek = serializeScheduleResponse(res.data);
      return {
        name: res.data.name,
        average_speed: averageSpeed,
      };
    } catch (e) {
      throw new RestException(e);
    }
  };
}
