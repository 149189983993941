import './style.less';

import React, { ReactNode, useEffect, useState } from 'react';

import { Button, Form, Modal } from 'antd';
import HTTPStatus from 'http-status';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import classSet from 'helpers/classSet';
import ClusterFormTabs from 'modules/clusters/components/ClusterModal/ClusterFormTabs';
import ClusterInformationForm from 'modules/clusters/components/ClusterModal/ClusterInformationForm';
import ClusterSettingForm from 'modules/clusters/components/ClusterModal/ClusterSettingForm';
import { getDataToSet, getFieldDataList, prepareData } from 'modules/clusters/models/helpers';
import { ViewNotification } from 'modules/common/containers/Notify';
import { translate } from 'modules/localization';
import { routerStore, useStore } from 'services/store';

interface IParams {
  action: string;
  entityGuid: string;
  guidDeliveryMethod: string;
}

interface IProps {
  baseUrl: string;
}

type TProps = IProps & RouteComponentProps<IParams>;

const ClusterModal = ({ baseUrl, match, location }: TProps) => {
  const { clusters } = useStore();
  const { entityGuid, action, guidDeliveryMethod } = match.params;
  const [form] = Form.useForm();
  const [activeTab, setActiveTab] = useState<'information' | 'setting'>('information');
  const [activeSettingIndexEdit, setActiveSettingIndexEdit] = useState<number>(null);

  const isEdit = action === 'edit';
  const isCreate = action === 'create';
  const isEditSettings = action === 'edit-settings';
  const isAddSettings = action === 'add-settings';

  useEffect(() => {
    form.resetFields();
    if (isEditSettings || isAddSettings) {
      setActiveTab('setting');
    }
    if (entityGuid) {
      clusters.get(entityGuid).then((data) => {
        const prepareData = getDataToSet(data);
        if (isAddSettings) {
          setActiveSettingIndexEdit(data.settings.delivery_settings_list.length);
          form.setFieldsValue({
            ...prepareData,
            deliverySettingsList: [...prepareData.deliverySettingsList, {}],
          });
        } else {
          setActiveSettingIndexEdit(
            data.settings.delivery_settings_list.findIndex(
              (item) => guidDeliveryMethod === item.delivery_method_guid,
            ),
          );
          form.setFieldsValue({
            ...prepareData,
            deliverySettingsList: [...prepareData.deliverySettingsList],
          });
        }
      });
    } else {
      form.setFieldsValue({ deliverySettingsList: [{}] });
    }

    return () => {
      form.setFieldsValue({ deliverySettingsList: [{}] });
      form.resetFields();
      setActiveSettingIndexEdit(null);
    };
  }, [action]);

  const handleReset = (e) => {
    e.preventDefault();
    routerStore.replace(`${baseUrl}${location.search}`);
    setActiveTab('information');
  };

  const submitClickHandler = (e): void => {
    e.preventDefault();
    form.submit();
  };

  const actionReset = () => {
    routerStore.replace(`${baseUrl}${location.search}`);
    setActiveTab('information');
  };

  const handleResponse = (message: string) => () => {
    ViewNotification.success({ message });
    actionReset();
  };

  const handleError = (values) => (response) => {
    if (HTTPStatus.BAD_REQUEST === response.http.status) {
      form.setFields(getFieldDataList(response, values));
    }
  };

  const handleSubmit = (values): void => {
    const data = prepareData(values);

    if ((isEdit || isEditSettings || isAddSettings) && entityGuid) {
      clusters
        .edit(entityGuid, data)
        .then(handleResponse(translate('editSuccess')))
        .catch(handleError(values));
    } else {
      clusters
        .create(data)
        .then(handleResponse(translate('clusterCreated')))
        .catch(handleError(values));
    }
  };

  const renderFooter = (): ReactNode => (
    <div className="cluster-form__actions">
      <Button htmlType="button" key="cancel" onClick={handleReset}>
        {translate('cancel')}
      </Button>
      {isCreate && activeTab === 'information' ? (
        <Button htmlType="submit" key="save" type="primary" onClick={() => setActiveTab('setting')}>
          {translate('next')}
        </Button>
      ) : null}
      {isEdit || activeTab === 'setting' ? (
        <Button
          htmlType="submit"
          key="save"
          type="primary"
          loading={clusters.isLoadingForm}
          disabled={clusters.isLoadingForm}
          onClick={submitClickHandler}
        >
          {translate('save')}
        </Button>
      ) : null}
    </div>
  );

  const title = () => {
    if (isEdit) {
      return translate('clusterEditTitle');
    }
    if (isCreate) {
      return translate('clusterCreateTitle');
    }
    if (isEditSettings) {
      return translate('clusterEditTitleSettings');
    }
    if (isAddSettings) {
      return translate('deliveryMethodCreateTitle');
    }
  };

  return (
    <Modal
      title={title()}
      open={isCreate || isEdit || isEditSettings || isAddSettings}
      onCancel={handleReset}
      footer={renderFooter()}
      className={classSet({
        'cluster-form-modal': true,
        'cluster-form-modal__create': isCreate || isEditSettings || isAddSettings,
      })}
      width={null}
    >
      {isCreate ? <ClusterFormTabs setActiveTab={setActiveTab} activeTab={activeTab} /> : null}
      <Form form={form} onFinish={handleSubmit} layout="vertical" className="cluster-form">
        <div className={classSet({ 'cluster-form-invisible': activeTab !== 'information' })}>
          <ClusterInformationForm form={form} isEdit={isEdit} />
        </div>

        <div className={classSet({ 'cluster-form-invisible': activeTab !== 'setting' })}>
          <Form.List name="deliverySettingsList">
            {(fields, { add, remove }) => (
              <>
                <div>
                  {fields.map(({ key, name }, index) => {
                    if (isCreate || activeSettingIndexEdit === index) {
                      return (
                        <ClusterSettingForm
                          key={key}
                          form={form}
                          name={name}
                          settingCount={fields.length}
                          remove={remove}
                          isCreate={isCreate}
                          deliveryMethodGuid={guidDeliveryMethod}
                        />
                      );
                    }
                  })}
                </div>
                {isCreate ? (
                  <div className="cluster-form-add-space">
                    <Button className="cluster-form-add-delivery-method" onClick={() => add()}>
                      {translate('addDeliveryMethod')}
                    </Button>
                  </div>
                ) : null}
              </>
            )}
          </Form.List>
        </div>
      </Form>
    </Modal>
  );
};

export default withRouter(observer(ClusterModal));
