import dayjs, { extend } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import uniq from 'lodash.uniq';
import { makeAutoObservable } from 'mobx';

import { RestException } from 'api/RestException';
import { getDateTime } from 'helpers/date';
import { dateFormat } from 'helpers/string';
import { ViewNotification } from 'modules/common/containers/Notify';
import CouriersApi from 'modules/couriers/api/CouriersApi';
import { ICouriersCreateSessionPayload } from 'modules/couriers/models/types';
import { translate } from 'modules/localization';
import RouteGeneratorApi from 'modules/routeGenerator/api/RouteGeneratorApi';
import {
  ICalculatedPreRoute,
  IRouteCalculateForm,
  IRouteCreateForm,
  IRouteCreateFormYandex,
} from 'modules/routeGenerator/models/types';
import { getTaskListForSaving, putErrorIntoTaskList } from 'modules/routeGenerator/models/utils';
import { IRoute } from 'modules/routes/models/types';
import { groupTasksWithRelatives } from 'modules/tasks/helpers/lists';
import { ITask, ITasksFilter } from 'modules/tasks/models/types';
import { getRelativeGuidsFromList, getTaskRelativeGuids } from 'modules/tasks/models/utils';

import { RouteGeneratorStore } from './RouteGeneratorStore';

extend(utc);
extend(timezone);

interface IErrorDataErrors {
  key: string;
  error: string;
}

interface IErrorData {
  errors: IErrorDataErrors[];
}

interface ICustomError extends Error {
  data: IErrorData;
}

export class RouteGeneratorApiStore {
  root: RouteGeneratorStore;

  constructor(root: RouteGeneratorStore) {
    this.root = root;
    makeAutoObservable(this);
  }

  handleAbortRequest = () => {
    if (
      Array.isArray(this.root.activeRequestTasksList) &&
      this.root.activeRequestTasksList.length > 0
    ) {
      for (let i = 0; i < this.root.activeRequestTasksList.length; i++) {
        this.root.activeRequestTasksList[i].abort();
      }
    }
  };

  handleAbortRequestFinally = () => {
    if (
      Array.isArray(this.root.activeRequestTasksList) &&
      this.root.activeRequestTasksList.every((item) => !item.signal.aborted)
    ) {
      this.root.activeRequestTasksList = null;
    }
  };

  getAbortController = () => {
    const abortController = new AbortController();
    this.root.activeRequestTasksList.push(abortController);
    return abortController;
  };

  getTasksWrapperLoading = async (): Promise<ITask[]> => {
    this.root.switchIsLoadingRefresh(true);
    try {
      const res = await this.getTasks();
      this.root.setTasksListSource(res);
      this.root.refreshRouteDraftList();
      this.root.selectedTaskGuids = [];
      return res;
    } catch (e) {
      // Empty
    } finally {
      this.root.switchIsLoadingRefresh(false);
    }
  };

  getTasks = async (filter: ITasksFilter = this.root.filterTasks): Promise<ITask[]> => {
    try {
      this.handleAbortRequest();
      this.root.activeRequestTasksList = [];
      const abortController = this.getAbortController();
      const { data: res } = await RouteGeneratorApi.getTasksList(filter, abortController.signal);

      let result = res.data;

      const count = Math.ceil(res.pagination.total / res.pagination.page_size);
      if (count > 1) {
        const promises = [];
        for (let i = 2; i <= count; i++) {
          const abortController = this.getAbortController();
          promises.push(
            RouteGeneratorApi.getTasksList(
              {
                ...filter,
                current: i,
              },
              abortController.signal,
            ),
          );
        }
        result = [
          ...result,
          ...(await Promise.all(promises)).reduce(
            (acc, response) => [...acc, ...response.data.data],
            [],
          ),
        ];
      }

      return result;
    } catch (e) {
      throw new RestException(e);
    } finally {
      this.handleAbortRequestFinally();
    }
  };

  calculateRoute = async (routeId: string, tz: string): Promise<ICalculatedPreRoute> => {
    const index = this.root.getRouteDraftIndex(routeId);
    try {
      const routeDraft = this.root.getRouteDraft(routeId);
      this.root.routeDraftList[index].isLoadingCard = true;

      const taskGuids = routeDraft.tasksList.reduce((list, task) => {
        return [...list, ...getTaskRelativeGuids(task)];
      }, []);

      const data: IRouteCalculateForm = {
        courier_guid: routeDraft.courierGuid,
        task_guids: uniq(taskGuids),
        transport_guid: routeDraft.transportGuid,
        route_start_time: getDateTime(this.root.routeSettings.deliveryDate, routeDraft.time, tz),
      };
      const { data: res } = await RouteGeneratorApi.calculateRoute(data);
      ViewNotification.success({ message: translate('routeCalculated') });
      this.root.routeDraftList[index] = {
        ...this.root.routeDraftList[index],
        tasksList: groupTasksWithRelatives(res.data.task_list),
        start: res.data.start,
        finish: res.data.finish,
        isLoadingCard: false,
        isCalculated: true,
      };
      return res.data;
    } catch (e) {
      this.errorExceptionRoute(e, index, 'task_guids');
      this.root.routeDraftList[index].isLoadingCard = false;
      throw new RestException(e);
    }
  };

  saveRoute = async (routeId: string, tz: string): Promise<IRoute> => {
    const index = this.root.getRouteDraftIndex(routeId);
    const currentRouteDraft = this.root.getRouteDraft(routeId);
    try {
      this.root.routeDraftList[index].isLoadingCard = true;
      const data: IRouteCreateForm = {
        date_time_planned_finish: dayjs(currentRouteDraft.finish.datetime)
          .tz(currentRouteDraft.finish?.warehouse?.timezone)
          .format(),
        date_time_planned_start: dayjs(currentRouteDraft.start.datetime)
          .tz(currentRouteDraft.start?.warehouse?.timezone)
          .format(),
        session_guid: currentRouteDraft.sessionGuid,
        tasks: getTaskListForSaving(currentRouteDraft.tasksList),
        date_time_planned_upload: currentRouteDraft.isPlanUploadTimeActive
          ? dayjs(
              getDateTime(
                dayjs(currentRouteDraft.planUploadTime),
                dayjs(currentRouteDraft.planUploadTime),
                tz,
              ),
            ).format()
          : null,
      };
      const { data: res } = await RouteGeneratorApi.createRoute(data);
      this.root.hideSavedTasks(routeId);
      ViewNotification.success({ message: translate('routeCreated') });
      this.root.setOpenCardRouteDraftId(null);
      return res.data;
    } catch (e) {
      this.errorExceptionRoute(e, index, 'tasks');
      this.root.routeDraftList[index].isLoadingCard = false;
      throw new RestException(e);
    }
  };

  saveRoutes = async (): Promise<void> => {
    await Promise.all(
      this.root.routeDraftList.map(async (routeDraft) => {
        if (routeDraft.isCalculated) {
          await this.root.apiStore.createCourierSession(routeDraft.uid);
        }
      }),
    );
    const routes = this.root.routeDraftList.filter(
      (routeDraft) => routeDraft.isCalculated === true,
    );
    try {
      const routesForSave = routes.map((item) => {
        return {
          date_time_planned_finish: dayjs(item.finish.datetime)
            .tz(item.finish?.warehouse?.timezone)
            .format(),
          date_time_planned_start: dayjs(item.start.datetime)
            .tz(item.start?.warehouse?.timezone)
            .format(),
          session_guid: item.sessionGuid,
          tasks: getTaskListForSaving(item.tasksList),
          date_time_planned_upload: item.isPlanUploadTimeActive
            ? dayjs(
                getDateTime(
                  dayjs(item.planUploadTime),
                  dayjs(item.planUploadTime),
                  item.start?.warehouse?.timezone,
                ),
              )
                .utc()
                .format()
            : null,
        };
      });

      const { data: res } = await RouteGeneratorApi.createRoutes(routesForSave);
      ViewNotification.success({ message: translate('routeCreated') });
      this.root.setOpenCardRouteDraftId(null);

      if (res.errors && Array.isArray(res.errors)) {
        res.errors.map((item) => {
          const indexRouteError = item.key.match(/\d+/g)[0];
          if (indexRouteError) {
            const indexRoute = this.root.getRouteDraftIndex(routes[indexRouteError].uid);
            this.root.routeDraftList[indexRoute].error = item.error;
          }
        });
      }
      [...this.root.routeDraftList].reverse().map((item) => {
        if (!item.error && item.isCalculated) {
          this.root.hideSavedTasks(item.uid);
          this.root.removeRoute(item.uid);
        }
      });
    } catch (e) {
      if (e?.data?.errors) {
        e?.data?.errors?.map((item) => {
          const indexRouteError = item.key.match(/\d+/g)[0];
          if (indexRouteError) {
            const indexRoute = this.root.getRouteDraftIndex(routes[indexRouteError].uid);
            this.root.routeDraftList[indexRoute].error = item.error;
          }
        });
      }
      throw new RestException(e);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createRouteByYandex = async (): Promise<any> => {
    const routeListForCreate = this.root.routeDraftList.filter(
      (draft) => !!draft.courierGuid && !!draft.transportGuid,
    );
    const routeUidListForCreate = routeListForCreate.map((route) => route.uid);
    try {
      routeUidListForCreate.map((uid) => {
        const index = this.root.getRouteDraftIndex(uid);
        this.root.routeDraftList[index].isLoadingCard = true;
      });
      const data: IRouteCreateFormYandex = routeListForCreate.reduce(
        (acc, route) => {
          return {
            ...acc,
            task_guids: [
              ...acc.task_guids,
              ...getTaskListForSaving(route.tasksList).map((t) => t.guid),
            ],
            courier_transport_bond: [
              ...acc.courier_transport_bond,
              {
                courier_guid: route.courierGuid,
                transport_guid: route.transportGuid,
              },
            ],
          };
        },
        { task_guids: [], courier_transport_bond: [] },
      );
      const { data: res } = await RouteGeneratorApi.createRouteByYandex(data);
      ViewNotification.success({ message: translate('routeCreated') });
      this.root.setOpenCardRouteDraftId(null);
      return res.data;
    } catch (e) {
      throw new RestException(e);
    } finally {
      routeUidListForCreate.map((uid) => {
        const index = this.root.getRouteDraftIndex(uid);
        this.root.routeDraftList[index].isLoadingCard = true;
      });
      routeUidListForCreate.map((index) => {
        this.root.removeRoute(index);
      });
    }
  };

  createCourierSession = async (routeId: string): Promise<void> => {
    const currentRouteDraft = this.root.getRouteDraft(routeId);
    const { courierGuid, sessionGuid, transportGuid } = currentRouteDraft;

    if (sessionGuid) {
      return;
    }

    this.root.routeDraftList.map((routeDraft, i) => {
      if (routeDraft.courierGuid === courierGuid) {
        this.root.routeDraftList[i] = {
          ...routeDraft,
          isLoadingCard: true,
        };
      }
    });

    try {
      const data: ICouriersCreateSessionPayload = {
        courier_guid: courierGuid,
        planned_date: dayjs(this.root.routeSettings.deliveryDate).format(dateFormat.search),
        transport_guid: transportGuid,
      };
      const { data: res } = await CouriersApi.createCourierSession(data);
      this.root.routeDraftList.map((routeDraft, i) => {
        if (routeDraft.courierGuid === courierGuid) {
          this.root.routeDraftList[i] = {
            ...routeDraft,
            sessionGuid: res.data.guid,
            isLoadingCard: false,
          };
        }
      });
    } catch (e) {
      this.root.routeDraftList.map((routeDraft, i) => {
        if (routeDraft.courierGuid === courierGuid) {
          this.root.routeDraftList[i] = {
            ...routeDraft,
            isLoadingCard: false,
          };
        }
      });
      throw new RestException(e);
    }
  };

  errorExceptionRoute = (e: ICustomError, routeIndex: number, propName: string): void => {
    const errors = e?.data?.errors;

    if (!errors || !Array.isArray(errors) || errors.length === 0) {
      return;
    }

    const taskList = this.root.routeDraftList[routeIndex].tasksList;
    const taskGuids = getRelativeGuidsFromList(taskList);

    for (let i = 0; i < errors.length; i++) {
      const { key, error } = errors[i];
      if (key && key.indexOf(propName) >= 0) {
        const taskIndexList = key.match(/\d+/g);
        if (!taskIndexList) {
          return;
        }
        const targetTaskGuid = taskGuids[taskIndexList[0]];
        this.root.routeDraftList[routeIndex].tasksList = putErrorIntoTaskList(
          taskList,
          targetTaskGuid,
          error,
        );
      }
    }

    this.root.routeDraftList[routeIndex].error = errors[0].error;
  };
}
