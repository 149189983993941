import Api from 'api/Api';
import { IPagination } from 'api/types';
import {
  IReasonsDelayListGetResponse,
  IReasonDelayDeleteResponse,
  IReasonDelayGetResponse,
  IReasonDelayForm,
  IReasonDelayFormCreateResponse,
  IReasonDelayEditResponse,
} from 'modules/reasonsDelay/models/types';

export default class ReasonsDelayApi {
  static getReasonDelay(guid: string): Promise<IReasonDelayGetResponse> {
    return Api.get(`/api/v1/reasons-late/${guid}`);
  }

  static createReasonDelay(data: IReasonDelayForm): Promise<IReasonDelayFormCreateResponse> {
    return Api.post(data, '/api/v1/reasons-late');
  }

  static editReasonDelay(guid: string, data: IReasonDelayForm): Promise<IReasonDelayEditResponse> {
    return Api.patch(data, `/api/v1/reasons-late/${guid}`);
  }

  static getReasonsDelayList(
    pagination: IPagination,
    signal?: AbortSignal,
    name?: string,
  ): Promise<IReasonsDelayListGetResponse> {
    const query = {
      page: pagination.current,
      page_size: pagination.pageSize,
      name: name,
    };
    return Api.get('/api/v1/reasons-late', query, signal);
  }

  static deleteReasonDelay(guid: string): Promise<IReasonDelayDeleteResponse> {
    return Api.delete(`/api/v1/reasons-late/${guid}`);
  }

  static searchReasonsDelay(
    searchQuery?: string,
    pagination?: IPagination,
    signal?: AbortSignal,
  ): Promise<IReasonsDelayListGetResponse> {
    const query = {
      page: pagination.current,
      page_size: pagination.pageSize,
      name: searchQuery,
    };
    return Api.get('/api/v1/reasons-late', query, signal);
  }
}
