import React, { useState } from 'react';

import { observer } from 'mobx-react-lite';

import SelectedActions from 'modules/routeGenerator/components/common/SelectedActions';
import { useStore } from 'services/store';

interface IProps {
  selectedTasks: React.Key[];
  setSelectedTaskGuids: (list: React.Key[]) => void;
  routeId: string;
}

const SelectedTaskActions = ({ selectedTasks, setSelectedTaskGuids, routeId }: IProps) => {
  const { routeGenerator } = useStore();

  const route = routeGenerator.getRouteDraft(routeId);
  const [isClicked, setIsClicked] = useState<boolean>(false);

  const handleClickAddTasksToRoute = () => {
    if (!isClicked) {
      setIsClicked(true);
      setTimeout(() => {
        const selectedTasksList = route.tasksList.filter((t) => selectedTasks.indexOf(t.guid) > -1);
        routeGenerator.removeTaskListFromRouteDraft(selectedTasksList, routeId);
        setSelectedTaskGuids([]);
        setIsClicked(false);
      }, 100);
    }
  };

  const selectedTaskList = routeGenerator.tasksListFromApi.filter((t) =>
    selectedTasks.includes(t.guid),
  );

  return (
    <SelectedActions
      currency={routeGenerator.currency}
      selectedTaskList={selectedTaskList}
      isClicked={isClicked}
      handlePrimary={handleClickAddTasksToRoute}
      handleCleanup={() => setSelectedTaskGuids([])}
      titlePrimary="removeTaskFromRoute"
    />
  );
};

export default observer(SelectedTaskActions);
