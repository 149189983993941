import { Dayjs } from 'dayjs';
import uniqueid from 'lodash.uniqueid';
import { makeAutoObservable } from 'mobx';

import { IPagination } from 'api/types';
import { getTimeslotErrors, getValidatedTimeslotValue } from 'modules/schedules/helpers';
import { ISchedules, IWeekdayIDEnum } from 'modules/schedules/models/apitypes';
import { initialPagination, initialWeek } from 'modules/schedules/models/initial';
import { ITimeslotUI, IWeekday } from 'modules/schedules/models/uitypes';

import { SchedulesApiStore } from './SchedulesApiStore';

const getEmptyTimeslot = (): ITimeslotUI => ({
  id: uniqueid(),
  start: null,
  end: null,
  limit: null,
  price: null,
  call: true,
});

export class SchedulesStore {
  apiStore: SchedulesApiStore;

  schedulesWeek: IWeekday[] = initialWeek;
  isLoadingSchedulesList = false;
  schedulesList: ISchedules[] = [];
  schedulesPagination: IPagination = initialPagination;
  searchQuery: string = null;
  loadingForm = false;

  constructor() {
    this.apiStore = new SchedulesApiStore(this);
    makeAutoObservable(this);
  }

  scheduleActionTimeslotDelete = (weekdayId: IWeekdayIDEnum, timeslotId: string): void => {
    this.schedulesWeek = [...this.schedulesWeek].map((weekday) =>
      weekday.id === weekdayId
        ? { ...weekday, timeslots: weekday.timeslots.filter((ts) => ts.id !== timeslotId) }
        : weekday,
    );
  };

  scheduleActionTimeslotAdd = (weekdayId: IWeekdayIDEnum): void => {
    this.schedulesWeek = [...this.schedulesWeek].map((weekday) =>
      weekday.id === weekdayId
        ? {
            ...weekday,
            timeslots: [...weekday.timeslots, getEmptyTimeslot()],
          }
        : weekday,
    );
  };

  scheduleActionTimeslotEdit = (
    value: Dayjs | boolean | number | string,
    key: 'call' | 'end' | 'limit' | 'price' | 'start',
    timeslotId: string,
    weekdayId: IWeekdayIDEnum,
  ): void => {
    this.schedulesWeek = [...this.schedulesWeek].map((weekday) =>
      weekday.id === weekdayId
        ? {
            ...weekday,
            timeslots: weekday.timeslots.map((timeslot) => {
              if (timeslot.id !== timeslotId) {
                return timeslot;
              }

              if (typeof value === 'object') {
                return {
                  ...timeslot,
                  [`error_${key}`]: null,
                  [key]: getValidatedTimeslotValue(timeslot, key, value),
                };
              }

              return { ...timeslot, [`error_${key}`]: null, [key]: value };
            }),
          }
        : weekday,
    );
  };

  scheduleActionApplyTimeslotsToOtherDays = (
    sourceDayId: IWeekdayIDEnum,
    targetDays: IWeekdayIDEnum[],
  ): void => {
    this.schedulesWeek = [...this.schedulesWeek].map((weekday) => {
      if (targetDays.includes(weekday.id)) {
        return {
          ...weekday,
          timeslots: this.schedulesWeek.find((w) => w.id === sourceDayId).timeslots,
        };
      }

      return weekday;
    });
  };

  scheduleActionSetTimeslotsForDay = (
    weekdayId: IWeekdayIDEnum,
    timeslots: ITimeslotUI[],
  ): void => {
    this.schedulesWeek = [...this.schedulesWeek].map((weekday) =>
      weekday.id === weekdayId ? { ...weekday, timeslots } : weekday,
    );
  };

  resetFormData = (): void => {
    this.schedulesWeek = initialWeek;
  };

  setSearchQuery = (query: string): void => {
    this.searchQuery = query;
  };

  setTimeslotsErrors = (errors): void => {
    let counter = 0;
    this.schedulesWeek = this.schedulesWeek.map((weekday) => ({
      ...weekday,
      timeslots: weekday.timeslots.map((ts) => {
        const err = getTimeslotErrors(errors, counter);
        counter++;
        return {
          ...ts,
          ...err,
        };
      }),
    }));
  };
}
