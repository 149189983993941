import './style.less';

import React, { ReactNode } from 'react';

import { observer } from 'mobx-react-lite';

import Header from 'modules/common/components/Header';
import HeaderMobile from 'modules/common/components/HeaderMobile';
import { useStore } from 'services/store';

interface ILayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: ILayoutProps) => {
  const { core } = useStore();
  return (
    <div className="layout">
      {core.isMobile ? <HeaderMobile /> : <Header />}
      <div className="content">{children}</div>
    </div>
  );
};

export default observer(Layout);
