import './style.less';

import React, { useEffect, useState } from 'react';

import { ArrowLeftOutlined, CloseOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';

import { getFieldDataList } from 'helpers/form';
import { PageTitle } from 'modules/common/components/HeaderMobile';
import Title from 'modules/common/components/Title';
import Loader from 'modules/common/containers/Loader';
import CoveragesFilter from 'modules/coverages/components/CoveragesFilter';
import CoveragesLocalList from 'modules/coverages/components/CoveragesLocalList';
import CoveragesMap from 'modules/coverages/components/CoveragesMap';
import SearchPolygon from 'modules/coverages/components/SearchPolygon';
import { ICoverage } from 'modules/coverages/models/types';
import { translate } from 'modules/localization';
import { getLocationParentGuid } from 'modules/locations/models/helpers';
import MapCollapse from 'modules/map/containers/MapCollapse';
import { MIN_ZOOM_LVL_FOR_GETTING_ADJACENT_COVERAGES } from 'modules/map/helpers';
import { useStore } from 'services/store';

import { prepareData } from './helpers';

interface IParams {
  locationGuid: string;
}

interface IProps {
  baseUrl: string;
}

const CoveragesCreate = ({ baseUrl }: IProps) => {
  const { core, coverage, router, rates, locations } = useStore();

  const [form] = Form.useForm<ICoverage>();

  const { locationGuid } = useParams<IParams>();

  const [isRedirectToServiceZoneForm, setRedirectToServiceZoneForm] = useState(false);

  useEffect(() => {
    coverage.setFilter('locationGuid', locationGuid);
    coverage.apiStore.getCoverages(false);
    coverage.setHovered(null);
    coverage.setAdjacentCoveragesListView([]);

    return () => {
      coverage.setCoveragesList([]);
      coverage.setCoveragesListView([]);
    };
  }, []);

  useEffect(() => {
    form.setFieldsValue({ polygon: coverage.polygon });
  }, [coverage.polygon]);

  useEffect(() => {
    if (coverage.coverageEditableGuid) {
      coverage.apiStore
        .getCoverage(coverage.coverageEditableGuid)
        .then((values) => {
          form.setFieldsValue({
            name: values.name,
            disabled: values.disabled,
            external_id: values.external_id,
            polygon: values.polygon,
          });
          coverage.setPolygon(values.polygon);
        })
        .catch(() => router.push({ pathname: baseUrl, search: router.location.search }));
    } else {
      coverage.setPolygon(null);
    }
  }, [coverage.coverageEditableGuid]);

  const redirectToServiceZoneForm = (coverages: string[]) => {
    rates.setMasterData({ coverages });
    coverage.setCoveragesListLocal([]);
    router.push({
      pathname: baseUrl.replace('coverages', 'service-zones') + '/create',
    });
  };

  const redirectToList = () => {
    coverage.setCoveragesFormVisible(true);
    coverage.setCoveragesListLocal([]);
    coverage.setCoverageEditableGuid(null);
    rates.resetMasterData();

    if (locations?.viewLocation?.coverages === 0) {
      router.push({
        pathname: baseUrl.replace('coverages', 'locations'),
      });
    } else {
      router.push({
        pathname: baseUrl,
        search: router.location.search,
      });
    }
  };

  const handleSuccess = (): void => {
    form.resetFields();
    coverage.setCoveragesFormVisible(false);
  };

  const handleError = (values) => (response) => {
    const fieldDataList = getFieldDataList(response, values);
    form.setFields(fieldDataList);
  };

  const handleSubmit = (values): void => {
    const params = {
      ...values,
      locations: [locationGuid],
    };

    const action = coverage.coverageEditableGuid
      ? coverage.apiStore.editCoverage(coverage.coverageEditableGuid, prepareData(params))
      : coverage.apiStore.createCoverage(prepareData(params));

    action
      .then(() => {
        isRedirectToServiceZoneForm
          ? redirectToServiceZoneForm(coverage.coveragesListLocal.map((c) => c.guid))
          : handleSuccess();
      })
      .catch(handleError(values));
  };

  const handleClickSubmit = () => {
    form.submit();
  };

  const goToServiceZoneForm = (e) => {
    e.preventDefault();
    setRedirectToServiceZoneForm(true);
    if (coverage.isCoveragesFormVisible) {
      form.submit();
    } else {
      coverage.setCoveragesFormVisible(true);
      redirectToServiceZoneForm(coverage.coveragesListLocal.map((c) => c.guid));
    }
  };

  const handleCloseForm = (e) => {
    e.preventDefault();
    e.stopPropagation();
    form.resetFields();
    coverage.setCoverageEditableGuid(null);
    coverage.setCoveragesFormVisible(false);
  };

  const handleCancel = (e) => {
    coverage.setPolygon(null);
    if (coverage.coveragesListLocal.length > 0 && coverage.isCoveragesFormVisible) {
      handleCloseForm(e);
    } else {
      redirectToList();
    }
  };

  const backlinkTitle = [];
  if (locations?.viewLocation?.name) {
    backlinkTitle.push(locations.viewLocation.name);
  }
  if (locations?.viewLocation?.coverages > 0) {
    backlinkTitle.push(translate('coveragesList'));
  }

  const getAdjacentCoverages = () => {
    const bounds = coverage.map.getBounds();
    coverage.apiStore.getAdjacentCoverages(bounds, getLocationParentGuid(locations.viewLocation));
  };

  return (
    <div className="locations-layout">
      <div className="locations-layout__content">
        {core.isMobile && (
          <PageTitle>
            <Button type="link" onClick={redirectToList}>
              <ArrowLeftOutlined /> {`${backlinkTitle.join(' – ')}`}
            </Button>
          </PageTitle>
        )}
        <div className="coverages__create">
          <div className="coverages__create-header">
            {!core.isMobile && (
              <Button type="link" className="coverages__form-back-link" onClick={redirectToList}>
                <ArrowLeftOutlined /> {`${backlinkTitle.join(' – ')}`}
              </Button>
            )}
            <Title
              size={Title.SIZE.H3}
              weight={Title.WEIGHT.SEMIBOLD}
              className="coverages__form-header"
            >
              {translate('coverageCreation')}
            </Title>

            <CoveragesLocalList />

            {coverage.isCoveragesFormVisible && (
              <Title
                size={Title.SIZE.H3}
                weight={Title.WEIGHT.SEMIBOLD}
                className="coverages__create-form-title"
              >
                <span>{translate('newCoverage')}</span>
                {coverage.coveragesListLocal.length > 0 && (
                  <a href="#" onClick={handleCloseForm}>
                    <CloseOutlined />
                  </a>
                )}
              </Title>
            )}
          </div>

          {core.isMobile && (
            <MapCollapse>
              <CoveragesMap baseUrl={baseUrl} location={locations.viewLocation} action="create" />
            </MapCollapse>
          )}

          <div className="coverages__create-body">
            {coverage.isCoveragesFormVisible && (
              <Form
                layout="vertical"
                form={form}
                onFinish={handleSubmit}
                className="coverages__create-form"
              >
                <Form.Item
                  label={translate('title')}
                  name="name"
                  rules={[{ required: true, message: translate('requiredValidator') }]}
                >
                  <Input placeholder={translate('enterTitle')} />
                </Form.Item>
                <CoveragesFilter withToggler isShort />
                <Form.Item label={translate('externalId')} name="external_id">
                  <Input placeholder={translate('enterTitle')} />
                </Form.Item>
                <SearchPolygon isView={false} />
                <div className="coverages__separator" />
                {core.permissions['coverages.create'] && (
                  <Form.Item>
                    <Button
                      onClick={getAdjacentCoverages}
                      className="coverages__get-adjacent"
                      disabled={
                        coverage.isLoadingAdjacentCoveragesList ||
                        !coverage.map ||
                        coverage.mapZoom < MIN_ZOOM_LVL_FOR_GETTING_ADJACENT_COVERAGES
                      }
                    >
                      {translate('getAdjacentCoverages')}
                    </Button>
                  </Form.Item>
                )}
                <Form.Item
                  label={translate('status')}
                  name="disabled"
                  valuePropName="checked"
                  initialValue
                >
                  <Checkbox>{translate('activity')}</Checkbox>
                </Form.Item>
                <Form.Item
                  label={translate('polygon')}
                  extra={translate('enterPolygon')}
                  name="polygon"
                  className="coverages__item_hide"
                  rules={[{ required: true, message: translate('requiredValidator') }]}
                >
                  <Input />
                </Form.Item>
              </Form>
            )}
          </div>

          <div
            className={
              coverage.isCoveragesFormVisible
                ? 'coverages__create-divider--gray'
                : 'coverages__create-divider'
            }
          />

          <div className="coverages__create-footer">
            {!coverage.isCoveragesFormVisible && (
              <Spin
                indicator={<Loader show />}
                spinning={coverage.loadingForm}
                wrapperClassName="coverages__form-forward"
              >
                <Button
                  type="link"
                  onClick={goToServiceZoneForm}
                  disabled={coverage.coveragesListLocal.length === 0}
                >
                  {translate('goToServiceZoneCreation')}
                  <RightOutlined className="coverages__form-forward-icon" />
                </Button>
              </Spin>
            )}

            <Spin
              indicator={<Loader show />}
              spinning={coverage.loadingForm}
              wrapperClassName="coverages__form-footer"
            >
              <Button htmlType="button" key="cancel" onClick={handleCancel}>
                {translate('cancel')}
              </Button>
              {core.permissions['coverages.create'] && (
                <Button
                  htmlType="submit"
                  key="save"
                  type="primary"
                  onClick={handleClickSubmit}
                  disabled={!coverage.isCoveragesFormVisible}
                >
                  {translate('save')}
                </Button>
              )}
            </Spin>
          </div>
        </div>
      </div>

      {!core.isMobile && (
        <div className="locations-layout__map">
          <CoveragesMap baseUrl={baseUrl} location={locations.viewLocation} action="create" />
        </div>
      )}
    </div>
  );
};

export default observer(CoveragesCreate);
