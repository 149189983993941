import React, { ReactNode, forwardRef, useEffect } from 'react';

import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { observer } from 'mobx-react-lite';
import { BaseSelectRef } from 'rc-select/lib/BaseSelect';

import { ICurrency } from 'helpers/currency';
import { selectFilter } from 'helpers/form';
import { DEFAULT_SELECT_PROPS } from 'modules/common/components/Select';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

const CurrencySelect = forwardRef<BaseSelectRef, SelectProps>(
  ({ onChange, ...rest }: SelectProps, ref) => {
    const { currency } = useStore();

    const getOptions = () => {
      const options = currency.currencyList;
      if (options && Array.isArray(options)) {
        return options;
      }
      return [];
    };

    const fetchDefaultOptions = (): void => {
      currency.getCurrency();
    };

    useEffect(() => {
      fetchDefaultOptions();
    }, []);

    const onChangeValue = (value, option): void => {
      onChange(value, option);
    };

    const renderOption = (option: ICurrency): ReactNode => {
      if (option) {
        return (
          <Select.Option key={option.code} value={option.code}>
            {option.name || option.code}
          </Select.Option>
        );
      }
      return null;
    };

    const options = getOptions();

    return (
      <Select
        showSearch
        allowClear
        ref={ref}
        defaultActiveFirstOption={false}
        filterOption={selectFilter}
        getPopupContainer={(el) => el}
        onChange={onChangeValue}
        placeholder={translate('enterDeliveryMethod')}
        {...DEFAULT_SELECT_PROPS}
        {...rest}
      >
        {options.map(renderOption)}
      </Select>
    );
  },
);

export default observer(CurrencySelect);
