import { LOCALES } from 'modules/localization';

export const getLocaleFromLocalStorage = (): LOCALES => {
  const locale = localStorage.getItem('locale') || 'en';
  if (locale.indexOf('ru') > -1) {
    return 'ru';
  }
  if (locale.indexOf('zh') > -1) {
    return 'zh-hans';
  }
  if (locale.indexOf('az') > -1) {
    return 'az-latn';
  }
  if (locale.indexOf('kk') > -1) {
    return 'kk';
  }
  if (locale.indexOf('ky') > -1) {
    return 'ky';
  }
  if (locale.indexOf('mn') > -1) {
    return 'mn';
  }
  return 'en';
};
