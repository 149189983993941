import React from 'react';

import { Alert } from 'antd';
import { observer } from 'mobx-react-lite';
import {
  NavLink,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';

import Layout from 'modules/common/containers/Layout';
import { translate } from 'modules/localization';
import CourierCollectorMonitor from 'modules/qa-monitor-courier-collector/components/CourierCollectorMonitor';
import CourierCouncilMonitor from 'modules/qa-monitor-courier-council/components/CourierCouncilMonitor';
import FastmineMonitor from 'modules/qa-monitor-fastmine/components/FastmineMonitor';
import OrderCollectorMonitor from 'modules/qa-monitor-order-collector/components/OrderCollectorMonitor';
import { useStore } from 'services/store';

interface IDirectoryProps extends RouteComponentProps {
  className: string;
}

interface IDirectoryMenuSubItem {
  access?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any;
  href: string;
  id: string;
  title: string;
}

interface IDirectoryMenuItem {
  access?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: any;
  href?: string;
  id: string;
  title: string;
  subitems?: IDirectoryMenuSubItem[];
}

const SECTION_DATA: IDirectoryMenuItem[] = [
  {
    component: FastmineMonitor,
    href: '/fastmine-monitor',
    id: 'fastmine-monitor',
    title: 'Мониторинг fastmine',
  },
  {
    component: OrderCollectorMonitor,
    href: '/order-collector-monitor',
    id: 'order-collector-monitor',
    title: 'Мониторинг Order collector',
  },
  {
    component: CourierCollectorMonitor,
    href: '/courier-collector-monitor',
    id: 'courier-collector-monitor',
    title: 'Мониторинг Courier collector',
  },
  {
    component: CourierCouncilMonitor,
    href: '/courier-council-monitor',
    id: 'courier-council-monitor',
    title: 'Мониторинг Courier council',
  },
];

const Directory = (props: IDirectoryProps) => {
  const { core } = useStore();

  const checkAccess = (menuItem) => !menuItem.access || core.permissions[menuItem.access];

  const getSectionList = (): IDirectoryMenuItem[] => {
    return SECTION_DATA.filter(checkAccess);
  };

  const renderMain = (props) => (
    <div className="directories">
      {renderNav()}
      {renderSection(props.match.params.section)}
    </div>
  );

  const renderNav = () => {
    const list = getSectionList();

    if (list.length > 1) {
      return (
        <div className="directories__menu-wrap">
          <nav className="directories__menu">
            {list.map((item) => (
              <NavLink
                key={item.id}
                to={`${props.match.url}${item.href}`}
                className="directories__menu-link"
              >
                {item.title}
              </NavLink>
            ))}
          </nav>
        </div>
      );
    }

    return null;
  };

  const renderSection = (sectionName) => {
    if (sectionName) {
      const section = SECTION_DATA.map((s) => (s.subitems ? [...s.subitems] : s))
        .flat()
        .find((s) => s.id === sectionName);

      if (section) {
        if ((section.access && core.permissions[section.access]) || !section.access) {
          const Component = section.component;

          return <Component baseUrl={props.match.url} />;
        }

        return (
          <Alert banner description message={translate('accessDenied')} showIcon type={'warning'} />
        );
      }
    }

    const sectionList = getSectionList();
    return <Redirect to={`${props.match.url}${sectionList[0].href}`} />;
  };

  return (
    <Layout>
      <Switch>
        <Route path={`${props.match.url}/:section`} render={renderMain} />
        <Route path={props.match.url} render={renderMain} />
      </Switch>
    </Layout>
  );
};

export default withRouter(observer(Directory));
