import React, { ReactNode, useEffect } from 'react';

import { Select } from 'antd';
import querystring from 'query-string';

import { selectFilter } from 'helpers/form';
import { IFilter } from 'modules/arm2/models/types';
import { DEFAULT_SELECT_PROPS } from 'modules/common/components/Select';
import { translate } from 'modules/localization';
import { ITasksType } from 'modules/tasks/models/types';

interface IProps {
  setFilter: (key: string, value: string[] | string) => void;
  getList: (filter?) => void;
  value: string[];
  className?: string;
  getFilterFromLocalStorage?: () => IFilter;
  optionsList: ITasksType[];
}

const TaskTypeFilter = ({
  setFilter,
  getList,
  value,
  className,
  getFilterFromLocalStorage,
  optionsList,
}: IProps) => {
  useEffect(() => {
    const localStorageFilter = getFilterFromLocalStorage && getFilterFromLocalStorage();
    const localStorageType = localStorageFilter && localStorageFilter.type;
    const selectedTypes = querystring.parse(window.location.search).type || localStorageType;

    if (selectedTypes && typeof selectedTypes === 'string') {
      setFilter('type', [selectedTypes]);
    }
    if (selectedTypes && typeof selectedTypes === 'object') {
      setFilter('type', selectedTypes);
    }
  }, []);

  const handleFilter = (val): void => {
    setFilter('type', val);
    getList();
  };

  const renderSelectOption = (option: ITasksType): ReactNode => {
    if (option) {
      return (
        <Select.Option key={option.value} value={option.value}>
          {option.title}
        </Select.Option>
      );
    }

    return null;
  };

  return (
    <div className={className}>
      <Select
        allowClear
        mode="multiple"
        filterOption={selectFilter}
        onChange={handleFilter}
        placeholder={translate('type')}
        showSearch
        value={value}
        popupMatchSelectWidth={false}
        getPopupContainer={(el) => el}
        {...DEFAULT_SELECT_PROPS}
      >
        {optionsList.map(renderSelectOption)}
      </Select>
    </div>
  );
};

export default TaskTypeFilter;
