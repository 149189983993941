import { MultiPolygon, Polygon } from 'geojson';
import L from 'leaflet';
import uniqBy from 'lodash.uniqby';
import { makeAutoObservable } from 'mobx';

import { RestException } from 'api/RestException';
import { ViewNotification } from 'modules/common/containers/Notify';
import CoveragesApi from 'modules/coverages/api/CoveragesApi';
import {
  ICoverage,
  ICoveragesFilter,
  ICoveragesForm,
  INominatim,
  ICoverageByWarehouse,
} from 'modules/coverages/models/types';
import { translate } from 'modules/localization';
import { getPolygonFromBounds } from 'modules/map/helpers';

import { CoverageStore } from './CoverageStore';
import { createParams } from '../models/helpers';

export class CoverageApiStore {
  root: CoverageStore;

  constructor(root: CoverageStore) {
    this.root = root;
    makeAutoObservable(this);
  }

  getCoverages = async (
    isRedirect = true,
    filter: ICoveragesFilter = this.root.filter,
  ): Promise<ICoverage[]> => {
    try {
      if (
        Array.isArray(this.root.activeRequestCoveragesList) &&
        this.root.activeRequestCoveragesList.length > 0
      ) {
        for (let i = 0; i < this.root.activeRequestCoveragesList.length; i++) {
          this.root.activeRequestCoveragesList[i].abort();
        }
      }
      this.root.activeRequestCoveragesList = [];
      const abortController = new AbortController();
      this.root.activeRequestCoveragesList.push(abortController);

      const requestParams = createParams(filter);
      const pageSizeMax = 100;
      const { data: res } = await CoveragesApi.getCoveragesList(
        {
          ...requestParams,
          page: 1,
          page_size: pageSizeMax,
        },
        abortController.signal,
      );
      const count = Math.ceil(res.pagination.total / res.pagination.page_size);

      if (count > 1) {
        const promises = [];
        for (let i = 2; i <= count; i++) {
          const abortController = new AbortController();
          this.root.activeRequestCoveragesList.push(abortController);
          promises.push(
            CoveragesApi.getCoveragesList(
              {
                ...requestParams,
                page: i,
                page_size: pageSizeMax,
              },
              abortController.signal,
            ),
          );
        }
        const coveragesList = uniqBy(
          [
            ...res.data,
            ...(await Promise.all(promises)).reduce((acc, res) => {
              return [...acc, ...res.data.data];
            }, []),
          ],
          'guid',
        );
        this.root.setCoveragesList(coveragesList);
        this.root.setCoveragesListView([...coveragesList, ...this.root.coveragesListLocal]);
        isRedirect &&
          this.root.setFilterToUrl({
            ...filter,
          });
        return coveragesList;
      }

      isRedirect &&
        this.root.setFilterToUrl({
          ...filter,
        });
      const coveragesList = uniqBy(res.data, 'guid');
      this.root.setCoveragesList(coveragesList);
      this.root.setCoveragesListView(
        uniqBy([...coveragesList, ...this.root.coveragesListLocal], 'guid'),
      );
      return coveragesList;
    } catch (e) {
      throw new RestException(e);
    } finally {
      if (
        Array.isArray(this.root.activeRequestCoveragesList) &&
        this.root.activeRequestCoveragesList.every((item) => !item.signal.aborted)
      ) {
        this.root.activeRequestCoveragesList = null;
      }
    }
  };

  getCoveragesByWarehouse = async (warehouseGuid: string): Promise<ICoverageByWarehouse[]> => {
    try {
      const pageSizeMax = 100;
      const { data: res } = await CoveragesApi.getCoveragesByWarehouse(warehouseGuid, {
        page: 1,
        page_size: pageSizeMax,
      });
      const count = Math.ceil(res.pagination.total / res.pagination.page_size);

      if (count > 1) {
        const promises = [];
        for (let i = 2; i <= count; i++) {
          promises.push(
            CoveragesApi.getCoveragesByWarehouse(warehouseGuid, {
              page: i,
              page_size: pageSizeMax,
            }),
          );
        }
        const coveragesList = uniqBy(
          [
            ...res.data,
            ...(await Promise.all(promises)).reduce((acc, res) => {
              return [...acc, ...res.data.data];
            }, []),
          ],
          'guid',
        );
        this.root.setCoveragesListByOrder(coveragesList);
        return coveragesList;
      }
      this.root.setCoveragesListByOrder(uniqBy(res.data, 'guid'));
      return uniqBy(res.data, 'guid');
    } catch (e) {
      throw new RestException(e);
    }
  };

  getAdjacentCoverages = async (
    bounds: L.LatLngBounds,
    location_guid: string,
  ): Promise<ICoverage[]> => {
    const polygon = getPolygonFromBounds(bounds).toGeoJSON();
    polygon.properties.location_guid = location_guid;

    try {
      if (
        Array.isArray(this.root.activeRequestAdjacentCoveragesList) &&
        this.root.activeRequestAdjacentCoveragesList.length > 0
      ) {
        for (let i = 0; i < this.root.activeRequestAdjacentCoveragesList.length; i++) {
          this.root.activeRequestAdjacentCoveragesList[i].abort();
        }
      }
      this.root.activeRequestAdjacentCoveragesList = [];
      const abortController = new AbortController();
      this.root.activeRequestAdjacentCoveragesList.push(abortController);

      const pageSizeMax = 100;

      const { data: res } = await CoveragesApi.getIntersectsCoveragesList(
        { page: 1, page_size: pageSizeMax },
        polygon,
        abortController.signal,
      );

      const count = Math.ceil(res.pagination.total / res.pagination.page_size);

      if (count > 1) {
        const promises = [];
        for (let i = 2; i <= count; i++) {
          const abortController = new AbortController();
          this.root.activeRequestAdjacentCoveragesList.push(abortController);
          promises.push(
            CoveragesApi.getIntersectsCoveragesList(
              {
                page: i,
                page_size: pageSizeMax,
              },
              polygon,
              abortController.signal,
            ),
          );
        }
        const coveragesList = uniqBy(
          [
            ...res.data,
            ...(await Promise.all(promises)).reduce((acc, res) => {
              return [...acc, ...res.data.data];
            }, []),
          ],
          'guid',
        );
        this.root.setAdjacentCoveragesListView(coveragesList);
        return coveragesList;
      }

      this.root.setAdjacentCoveragesListView(res.data);
      return res.data;
    } catch (e) {
      throw new RestException(e);
    } finally {
      if (
        Array.isArray(this.root.activeRequestAdjacentCoveragesList) &&
        this.root.activeRequestAdjacentCoveragesList.every((item) => !item.signal.aborted)
      ) {
        this.root.activeRequestAdjacentCoveragesList = null;
      }
    }
  };

  deleteCoverage = async (coveragesGuid: string): Promise<void> => {
    try {
      await CoveragesApi.deleteCoverage(coveragesGuid);
      ViewNotification.success({ message: translate('coverageDeleted') });
    } catch (e) {
      throw new RestException(e);
    }
  };

  getNominatimPolygon = async (name: string): Promise<INominatim<MultiPolygon | Polygon>[]> => {
    try {
      this.root.activeRequestNominatimPolygon && this.root.activeRequestNominatimPolygon.abort();
      this.root.activeRequestNominatimPolygon = new AbortController();
      const request = this.root.activeRequestNominatimPolygon;
      const { data: res } = await CoveragesApi.getNominatimPolygon(name, request.signal);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return res.filter((item) => item.geojson && item.geojson.type !== 'Point');
    } catch (e) {
      throw new RestException(e);
    } finally {
      if (
        Array.isArray(this.root.activeRequestNominatimPolygon) &&
        !this.root.activeRequestNominatimPolygon.signal.aborted
      ) {
        this.root.activeRequestNominatimPolygon = null;
      }
    }
  };

  editCoverage = async (coveragesGuid: string, data: ICoveragesForm): Promise<void> => {
    try {
      this.root.loadingForm = true;
      const coverage = await CoveragesApi.editCoverage(coveragesGuid, data);
      this.root.setPolygon(null);
      ViewNotification.success({ message: translate('coverageEdited') });
      const newLocalList = this.root.coveragesListLocal.map((c) =>
        c.guid === coveragesGuid ? coverage.data.data : c,
      );
      this.root.setCoveragesListLocal(newLocalList);
      this.root.setCoveragesListView(this.root.coveragesListLocal);
      this.root.setCoverageEditableGuid(null);
      this.root.loadingForm = false;
    } catch (e) {
      this.root.loadingForm = false;
      throw new RestException(e);
    }
  };

  createCoverage = async (data: ICoveragesForm): Promise<void> => {
    try {
      this.root.loadingForm = true;
      const coverage = await CoveragesApi.createCoverage(data);
      this.root.setPolygon(null);
      ViewNotification.success({ message: translate('coverageAdded') });
      this.root.setCoveragesListLocal([...this.root.coveragesListLocal, coverage.data.data]);
      this.root.setCoveragesListView(this.root.coveragesListLocal);
      this.root.loadingForm = false;
    } catch (e) {
      this.root.loadingForm = false;
      throw new RestException(e);
    }
  };

  getCoverage = async (coveragesGuid: string): Promise<ICoverage> => {
    try {
      this.root.loadingForm = true;
      const { data: res } = await CoveragesApi.getCoverage(coveragesGuid);
      this.root.loadingForm = false;
      return { ...res.data, disabled: !res.data.disabled };
    } catch (e) {
      this.root.loadingForm = false;
      throw new RestException(e);
    }
  };
}
