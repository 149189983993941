import React from 'react';

import { observer } from 'mobx-react-lite';

import FullScreenButton from 'modules/map/containers/FullScreenButton';
import Map from 'modules/routeGenerator/components/common/Map';
import { ITask } from 'modules/tasks/models/types';
import { getTaskRelativeGuids } from 'modules/tasks/models/utils';
import { useStore } from 'services/store';

interface IProps {
  isFullScreenMap: boolean;
  setIsFullScreenMap: (val: boolean) => void;
  taskList: ITask[];
}

const LeftSideMap = ({ isFullScreenMap, setIsFullScreenMap, taskList }: IProps) => {
  const { routeGenerator } = useStore();

  const toggleTaskSelection = (task: ITask) => {
    const isSelected = routeGenerator.selectedTaskGuids.includes(task.guid);

    return isSelected
      ? routeGenerator.deselectTaskList(getTaskRelativeGuids(task))
      : routeGenerator.selectTaskList(getTaskRelativeGuids(task));
  };
  const warehousesList = [routeGenerator.activeWarehouse];

  return (
    <Map
      taskList={taskList}
      handleSelectList={routeGenerator.selectTaskList}
      handleDeselectList={routeGenerator.deselectTaskList}
      selectedTaskList={routeGenerator.selectedTaskGuids}
      handleSelectTask={toggleTaskSelection}
      warehouses={warehousesList}
    >
      <FullScreenButton
        isFullScreen={isFullScreenMap}
        toggleChangeIsFullScreen={setIsFullScreenMap}
      />
    </Map>
  );
};

export default observer(LeftSideMap);
