import { DEFAULT_DATE } from 'modules/arm2/components/DateFilter';
import { IRoute, IRoutesFilter } from 'modules/routes/models/types';

export const initialFilter: IRoutesFilter = {
  current: 1,
  pageSize: 100,
  search: null,
  deliveryDate: DEFAULT_DATE,
  shop: [],
  warehouses: null,
  from: null,
  to: null,
  status: [],
  deliveryMethod: [],
  mapShow: false,
  isProblem: false,
  columnKey: 'number',
  order: 'descend',
};

export const initialRouteData: IRoute = {
  created_at: null,
  creator_guid: null,
  date_time_finish: null,
  date_time_planned_finish: null,
  date_time_planned_start: null,
  date_time_start: null,
  finish_warehouse_guid: null,
  guid: null,
  number: null,
  session: null,
  session_guid: null,
  start_warehouse_guid: null,
  status: null,
  tasks: [],
  start_warehouse: null,
  finish_warehouse: null,
};
