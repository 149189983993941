import './style.less';

import React from 'react';

import { getStatusIconImg } from 'helpers/statusIcon';
import { ISessionStatus } from 'modules/couriers/models/types';
import { IOrderStatus } from 'modules/orders/models/types';
import { IRoutesStatus } from 'modules/routes/models/types';
import { ITasksStatus } from 'modules/tasks/models/types';

interface IProps {
  status?: IOrderStatus | IRoutesStatus | ISessionStatus | ITasksStatus;
  isLarge?: boolean;
}

const Status = ({ status, isLarge }: IProps) => {
  return status ? (
    <div className="status">
      {getStatusIconImg({ status: status.value, isLarge })}
      <span>{status.title}</span>
    </div>
  ) : null;
};

export default Status;
