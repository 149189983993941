import Api from 'api/Api';
import { IPagination } from 'api/types';
import {
  IClusterCreateResponse,
  IClusterDeleteResponse,
  IClusterEditResponse,
  IClusterForm,
  IClusterGetResponse,
  IClustersListGetResponse,
} from 'modules/clusters/models/types';

export default class ClustersApi {
  static getClustersList(
    pagination: IPagination,
    search?: string,
    profile?: string,
    signal?: AbortSignal,
  ): Promise<IClustersListGetResponse> {
    const query = {
      page: pagination.current,
      page_size: pagination.pageSize,
      q: search,
      profile,
    };
    return Api.get('/api/v1/clusters', query, signal);
  }

  static deleteCluster(guid: string): Promise<IClusterDeleteResponse> {
    return Api.delete(`/api/v1/clusters/${guid}`);
  }

  static createCluster(data: IClusterForm): Promise<IClusterCreateResponse> {
    return Api.post(data, '/api/v1/clusters');
  }

  static editCluster(guid: string, data: IClusterForm): Promise<IClusterEditResponse> {
    return Api.put(data, `/api/v1/clusters/${guid}`);
  }

  static getCluster(guid: string): Promise<IClusterGetResponse> {
    return Api.get(`/api/v1/clusters/${guid}`);
  }
}
