import Api from 'api/Api';
import { IFilter } from 'modules/arm2/models/types';
import {
  IOrderGetResponse,
  IOrderResolutionsGetResponse,
  IOrderSetCoordsResponse,
  IOrderStatusGetResponse,
  IOrderTypesGetResponse,
  IOrdersListGetResponse,
  ITaskCreateResponse,
} from 'modules/orders/models/types';
import { ITaskForm } from 'modules/tasks/models/types';
import { IPoint } from 'modules/warehouses/models/types';

export default class OrdersApi {
  static getOrderResolutions(): Promise<IOrderResolutionsGetResponse> {
    return Api.get('/api/v1/dictionaries/order-resolutions');
  }

  static getOrderStatuses(): Promise<IOrderStatusGetResponse> {
    return Api.get('/api/v1/dictionaries/order-statuses');
  }

  static getOrderTypes(): Promise<IOrderTypesGetResponse> {
    return Api.get('/api/v1/dictionaries/order-types');
  }

  static getOrdersList(filter: IFilter, signal: AbortSignal): Promise<IOrdersListGetResponse> {
    const query = {
      page: filter.current,
      page_size: filter.pageSize,
      q: filter.search,
      delivery_date_list: filter.deliveryDate && [filter.deliveryDate],
      shop_guid_list: filter.shop,
      warehouse_guid_list: filter.warehouses,
      time_slot_start: filter.from,
      time_slot_end: filter.to,
      type_list: filter.type,
      status_list: filter.status,
      delivery_method_guid_list: filter.deliveryMethod,
    };
    return Api.get('/api/v1/orders', query, signal);
  }

  static getOrder(orderGuid: string): Promise<IOrderGetResponse> {
    return Api.get(`/api/v1/orders/${orderGuid}`);
  }

  static createTask(orderGuid: string, data: ITaskForm): Promise<ITaskCreateResponse> {
    return Api.post(data, `/api/v1/orders/${orderGuid}/tasks`);
  }

  static setCoords(
    orderGuid: string,
    point: IPoint,
    destination?: string,
  ): Promise<IOrderSetCoordsResponse> {
    const data = { point, destination };
    return Api.patch(data, `/api/v1/orders/${orderGuid}/point`);
  }

  static editCoords(
    orderGuid: string,
    point: IPoint,
    destination?: string,
  ): Promise<IOrderSetCoordsResponse> {
    const data = { point, destination };
    return Api.patch(data, `/api/v1/orders/${orderGuid}/repoint`);
  }
}
