import React from 'react';

import { observer } from 'mobx-react-lite';

import Title from 'modules/common/components/Title';
import ButtonCreate from 'modules/directory/components/ActionButtons/ButtonCreate';
import ButtonCreateMobile from 'modules/directory/components/ActionButtons/ButtonCreateMobile';
import Search from 'modules/directory/containers/Search';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

interface IProps {
  baseUrl: string;
}

const WarehousesHeader = ({ baseUrl }: IProps) => {
  const { core, warehouses } = useStore();

  return (
    <div className="dir__header">
      <Title className="dir__title" size={Title.SIZE.H3} weight={Title.WEIGHT.SEMIBOLD}>
        {translate('warehouses')}

        {core.isMobile && (
          <ButtonCreateMobile
            baseUrl={baseUrl}
            isPermitted={core.permissions['warehouses.create']}
          />
        )}
      </Title>
      <div className="dir__header-section">
        <Search
          actionSearch={warehouses.search}
          actionListGet={warehouses.getList}
          actionSearchSet={warehouses.setSearchQuery}
          query={warehouses.searchQuery}
          placeholder={translate('searchByTitleAddressPhone')}
        />
        {!core.isMobile && (
          <ButtonCreate baseUrl={baseUrl} isPermitted={core.permissions['warehouses.create']} />
        )}
      </div>
    </div>
  );
};

export default observer(WarehousesHeader);
