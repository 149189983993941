import './style.less';

import React, { useEffect } from 'react';

import { Table } from 'antd';
import debounce from 'lodash.debounce';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';

import { GEO_BASE_URL } from 'constants/index';
import CoveragesFilter from 'modules/coverages/components/CoveragesFilter';
import { getColumns } from 'modules/coverages/components/CoveragesList/helpers';
import { ICoverage } from 'modules/coverages/models/types';
import { getEditUrl } from 'modules/directory/helpers';
import Header from 'modules/locations/components/Header';
import { useStore } from 'services/store';

import CoveragesMap from '../CoveragesMap';

interface IParams {
  locationGuid: string;
}

const CoveragesList = ({ ...rest }: ICoverage) => {
  const { coverage, router, core, locations } = useStore();

  const { locationGuid } = useParams<IParams>();

  const location = useLocation<Record<string, never>>();

  useEffect(() => {
    coverage.setCoveragesListView([]);
    coverage.setAdjacentCoveragesListView([]);
    coverage.setFilter('locationGuid', locationGuid);
    coverage.apiStore.getCoverages();

    return () => {
      coverage.setCoveragesList([]);
      coverage.setCoveragesListView([]);
    };
  }, []);

  const deleteItem = (guid: string): void => {
    coverage.apiStore.deleteCoverage(guid).then(() => {
      locations.apiStore.getLocation(locationGuid);
      coverage.apiStore.getCoverages().then((list) => {
        if (list.length === 0) {
          router.push(`${GEO_BASE_URL}/${locationGuid}/locations`);
        }
      });
    });
  };

  const columns = getColumns(router.location.pathname, deleteItem, core.permissions);

  const setHoveredDebounce = debounce(coverage.setHovered, 50);

  const getRowHandler = (row: ICoverage) => ({
    className: `coverages__item ${row.disabled ? ' coverages__item_disabled' : ''} ${
      row.guid === coverage.hoveredGuid ? ' coverages__item_hovered' : ''
    }`,
    onClick: (e): void =>
      e.target.innerHTML === row.name &&
      router.push(getEditUrl(router.location.pathname, location, row)),
    onMouseOver: (): void => setHoveredDebounce(row.guid),
    onMouseOut: (): void => setHoveredDebounce(null),
  });

  return (
    <div className="locations-layout">
      <div className="locations-layout__content">
        <div className="coverages-list">
          <Header isInner />
          <CoveragesFilter />
          <Table
            className="dir__list coverages__list"
            dataSource={coverage.coveragesList}
            columns={columns}
            bordered={false}
            pagination={false}
            rowKey="guid"
            loading={coverage.isLoadingCoveragesList}
            onRow={getRowHandler}
            showHeader={false}
            {...rest}
          />
        </div>
      </div>
      {!core.isMobile && (
        <div className="locations-layout__map">
          <CoveragesMap baseUrl={router.location.pathname} location={locations.viewLocation} />
        </div>
      )}
    </div>
  );
};

export default observer(CoveragesList);
