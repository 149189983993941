import './style.less';

import React from 'react';

import { Drawer } from 'antd';
import { observer } from 'mobx-react-lite';

import { ClusterSidebar } from 'modules/qa/common/ClusterList';
import { useStore } from 'services/store';

import { SessionSidebar } from '../CourierSessionList';
import { RouteSidebar } from '../RouteList';

const Sidebar = () => {
  const { courierCollectorMonitor } = useStore();

  const getComponent = () => {
    switch (courierCollectorMonitor.sidebarType) {
      case 'cluster':
        return <ClusterSidebar cluster={courierCollectorMonitor.sidebarData} />;

      case 'session':
        return <SessionSidebar session={courierCollectorMonitor.sidebarData} />;

      case 'route':
        return <RouteSidebar route={courierCollectorMonitor.sidebarData} />;

      default:
        return null;
    }
  };

  const component = getComponent();

  const closeSidebar = () => {
    courierCollectorMonitor.setSidebarType(null);
    courierCollectorMonitor.setSidebarData(null);
  };

  return (
    <Drawer
      placement="right"
      onClose={closeSidebar}
      closable={false}
      open={component !== null}
      size="large"
    >
      {component}
    </Drawer>
  );
};

export default observer(Sidebar);
