import { DirectoryApi, DirectoryApiStore } from 'modules/common/store/DirectoryApiStore';

import DeliveryMethodsApi from '../api/DeliveryMethodsApi';
import { IDeliveryMethods, IDeliveryMethodsForm } from '../models/types';

const Api: DirectoryApi<IDeliveryMethods, IDeliveryMethodsForm> = {
  getEntity: DeliveryMethodsApi.getDeliveryMethod,
  getEntityList: DeliveryMethodsApi.getDeliveryMethodsList,
  search: DeliveryMethodsApi.searchDeliveryMethods,
  createEntity: DeliveryMethodsApi.createDeliveryMethod,
  editEntity: DeliveryMethodsApi.editDeliveryMethod,
  deleteEntity: DeliveryMethodsApi.deleteDeliveryMethod,
};
export class DeliveryMethodsStore extends DirectoryApiStore<
  IDeliveryMethods,
  IDeliveryMethodsForm
> {
  constructor() {
    super(Api, '/directories/delivery-methods');
  }
}
