import './style.less';

import React, { ReactNode, useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';
import { Route, Switch } from 'react-router-dom';

import { GEO_BASE_URL } from 'constants/index';
import CoveragesCreate from 'modules/coverages/components/CoveragesCreate';
import CoveragesEdit from 'modules/coverages/components/CoveragesEdit';
import CoveragesList from 'modules/coverages/components/CoveragesList';
import LocationsList from 'modules/locations/components/LocationsList';
import RatesForm from 'modules/rates/components/RatesForm';
import RatesList from 'modules/rates/components/RatesList';
import ServiceZonesForm from 'modules/service-zones/components/ServiceZonesForm';
import ServiceZonesList from 'modules/service-zones/components/ServiceZonesList';
import { useStore } from 'services/store';

import LocationsForm from '../LocationsForm';

interface IParams {
  locationGuid: string;
  section: string;
  action: string;
}

const LocationsViewPage = () => {
  const { locations, router } = useStore();

  const { locationGuid, action } = useParams<IParams>();

  useEffect(() => {
    locationGuid &&
      locations.apiStore.getLocation(locationGuid).catch(() => router.push(GEO_BASE_URL));

    return () => {
      locations.setViewLocation({ ...locations.viewLocation, coverages: 0 });
    };
  }, [locationGuid, action]);

  return (
    <Switch>
      <Route
        path={`${GEO_BASE_URL}/:locationGuid/coverages/:coverageGuid/edit`}
        render={(): ReactNode => (
          <CoveragesEdit baseUrl={`${GEO_BASE_URL}/${locationGuid}/coverages`} />
        )}
      />
      <Route
        path={`${GEO_BASE_URL}/:locationGuid/coverages/create`}
        render={(): ReactNode => (
          <CoveragesCreate baseUrl={`${GEO_BASE_URL}/${locationGuid}/coverages`} />
        )}
      />
      <Route
        path={`${GEO_BASE_URL}/:locationGuid/service-zones/:serviceZonesGuid/edit`}
        render={(): React.ReactNode => (
          <ServiceZonesForm
            actionName="edit"
            baseUrl={`${GEO_BASE_URL}/${locationGuid}/service-zones`}
          />
        )}
      />
      <Route
        path={`${GEO_BASE_URL}/:locationGuid/service-zones/create`}
        render={(): React.ReactNode => (
          <ServiceZonesForm
            actionName="create"
            baseUrl={`${GEO_BASE_URL}/${locationGuid}/service-zones`}
          />
        )}
      />
      <Route
        path={`${GEO_BASE_URL}/:locationGuid/rates/:rateGuid/edit`}
        render={(): React.ReactNode => (
          <RatesForm actionName="edit" baseUrl={`${GEO_BASE_URL}/${locationGuid}/rates`} />
        )}
      />
      <Route
        path={`${GEO_BASE_URL}/:locationGuid/rates/:rateGuid/copy`}
        render={(): React.ReactNode => (
          <RatesForm actionName="copy" baseUrl={`${GEO_BASE_URL}/${locationGuid}/rates`} />
        )}
      />
      <Route
        path={`${GEO_BASE_URL}/:locationGuid/rates/create`}
        render={(): React.ReactNode => (
          <RatesForm actionName="create" baseUrl={`${GEO_BASE_URL}/${locationGuid}/rates`} />
        )}
      />
      <Route
        path={`${GEO_BASE_URL}/:locationGuid/locations/:childrenLocationGuid/edit`}
        render={(): React.ReactNode => (
          <LocationsForm actionName="edit" baseUrl={`${GEO_BASE_URL}/${locationGuid}/locations`} />
        )}
      />
      <Route
        path={`${GEO_BASE_URL}/:locationGuid/locations/create`}
        render={(): React.ReactNode => (
          <LocationsForm
            actionName="create"
            baseUrl={`${GEO_BASE_URL}/${locationGuid}/locations`}
          />
        )}
      />

      <Route exact path={`${GEO_BASE_URL}/:locationGuid/locations`} component={LocationsList} />
      <Route exact path={`${GEO_BASE_URL}/:locationGuid/coverages`} component={CoveragesList} />
      <Route
        exact
        path={`${GEO_BASE_URL}/:locationGuid/service-zones`}
        component={ServiceZonesList}
      />
      <Route exact path={`${GEO_BASE_URL}/:locationGuid/rates`} component={RatesList} />
      <Route path={GEO_BASE_URL} component={LocationsList} />
    </Switch>
  );
};

export default observer(LocationsViewPage);
