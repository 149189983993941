import './style.less';

import { useEffect } from 'react';

import { GeoSearchControl } from 'leaflet-geosearch';
import { observer } from 'mobx-react-lite';
import { useMap } from 'react-leaflet';

import { IConfigGeocoderBMP } from 'helpers/config';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

import SearchProviderBMP from '../SearchProviderBMP';

interface IProps {
  showMarker: boolean;
  geocoderConfig: IConfigGeocoderBMP;
}

const Search = ({ showMarker }: IProps) => {
  const { core } = useStore();
  const map = useMap();
  const provider = new SearchProviderBMP({
    token: core.config.geocoderBMP.token,
    url: core.config.geocoderBMP.url,
  });
  const searchControl = GeoSearchControl({
    provider: provider,
    animateZoom: true,
    autoClose: true,
    autoCompleteDelay: 100,
    keepResult: true,
    style: 'bar',
    showMarker: showMarker,
    showPopup: true,
    popupFormat: ({ result }): string => {
      return `<div class="search-popup">${result.label}</div><div class="search-popup-coords">${result.y}, ${result.x}</div>`;
    },
    retainZoomLevel: false,
    searchLabel: translate('enterSearchQueryAddress'),
    marker: {
      draggable: false,
    },
  });

  useEffect(() => {
    map && map.addControl(searchControl);

    return () => {
      map.removeControl(searchControl);
    };
  }, []);

  return null;
};

export default observer(Search);
