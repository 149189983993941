import Api from 'api/Api';
import { IPagination } from 'api/types';
import {
  IAccountSystemEditResponse,
  IAccountSystemListGetResponse,
} from 'modules/account-system/models/types';

export default class AccountSystemApi {
  static getAccountSystemList(pagination: IPagination): Promise<IAccountSystemListGetResponse> {
    const query = {
      page: pagination.current,
      page_size: pagination.pageSize,
    };
    return Api.get('/api/v1/account-system-params', query);
  }

  static editAccountSystem(guid: string, disabled: boolean): Promise<IAccountSystemEditResponse> {
    return Api.patch({ disabled }, `/api/v1/account-system-params/${guid}`);
  }
}
