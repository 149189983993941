import React from 'react';

import dayjs from 'dayjs';

import { formatCurrency } from 'helpers/currency';
import { dateFormat, timeFormat } from 'helpers/string';
import { translate } from 'modules/localization';
import { getOrderUrl } from 'modules/tasks/components/TasksList/helpers';
import { getAddressProp } from 'modules/tasks/helpers/propHelper';
import { ITask } from 'modules/tasks/models/types';
import { getAmount, getRelativeTasksList, getSize } from 'modules/tasks/models/utils';
import { useStore } from 'services/store';

interface IProps {
  task: ITask;
  isShort?: boolean;
}

export const TaskTooltip = ({ task, isShort }: IProps) => {
  const { currency, routeGenerator } = useStore();
  const tasks: ITask[] = getRelativeTasksList(task);
  const time = task.real_delivery_time || task.planned_delivery_time;
  const timeValue = time && dayjs(time).format(timeFormat.simple);
  const dateValue =
    !isShort && task.delivery_date && dayjs(task.delivery_date).format(dateFormat.short);

  return (
    <div className="task-tooltip">
      <div className="task-tooltip__general">
        <p className="task-tooltip__date">
          {task.time_slot_start} – {task.time_slot_end}{' '}
          {timeValue || dateValue ? `/ ${isShort ? timeValue : dateValue}` : ''}
        </p>
        {task.order && task.order[getAddressProp(task)] && (
          <p className="task-tooltip__address">
            {task?.meta?.recipient_company_short_name && (
              <>
                <b>{task.meta.recipient_company_short_name}</b>,{' '}
              </>
            )}
            {task.order[getAddressProp(task)]}
          </p>
        )}
        {!isShort && (
          <p className="task-tooltip__sizes">
            {formatCurrency(
              getAmount(task),
              task?.meta?.shop?.currency || routeGenerator.currency,
              currency.currencyList,
            )}
            {' / '}
            {getSize('weight', task).toFixed(2)}&nbsp;{translate('weightUnit')}
            {' / '}
            {getSize('volume', task).toFixed(3)}&nbsp;{translate('lengthUnit')}
            <sup>3</sup>
          </p>
        )}
      </div>
      <div className="task-tooltip__tasks">
        {(!isShort || tasks.length > 1) && (
          <p className="task-tooltip__list-title">
            {tasks.length} {translate('task', { count: tasks.length })} {translate('onAddress')}
          </p>
        )}
        <p className="task-tooltip__list">
          {tasks.map((t) => (
            <a key={t.guid} className="task-tooltip__link" href={getOrderUrl(t)}>
              #{t.number}
            </a>
          ))}
        </p>
      </div>
    </div>
  );
};
