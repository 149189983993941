import { DirectoryApi, DirectoryApiStore } from 'modules/common/store/DirectoryApiStore';

import ClientGroupsApi from '../api/ClientGroupsApi';
import { IClientGroups, IClientGroupsForm } from '../models/types';

const Api: DirectoryApi<IClientGroups, IClientGroupsForm> = {
  getEntity: ClientGroupsApi.getClientGroup,
  getEntityList: ClientGroupsApi.getClientGroupsList,
  search: ClientGroupsApi.searchClientGroups,
  createEntity: ClientGroupsApi.createClientGroup,
  editEntity: ClientGroupsApi.editClientGroup,
  deleteEntity: ClientGroupsApi.deleteClientGroup,
};
export class ClientGroupsStore extends DirectoryApiStore<IClientGroups, IClientGroupsForm> {
  constructor() {
    super(Api, '/directories/client-groups');
  }
}
