import React, { ReactNode, useEffect } from 'react';

import { observer } from 'mobx-react-lite';

import OrderTask from 'modules/orders/components/OrderTask';
import { IOrder } from 'modules/orders/models/types';
import { ITask } from 'modules/tasks/models/types';
import { useStore } from 'services/store';

interface IProps {
  order: IOrder;
  onCancelTask: () => Promise<void>;
  orderUpdate: () => Promise<void>;
  changeTaskTimeslot: (taskGuid: string, date: string, from: string, to: string) => Promise<void>;
  isCanBeAddedNewTask: boolean;
  openNewTaskModal: () => void;
}

const OrderTaskList = ({
  order,
  onCancelTask,
  changeTaskTimeslot,
  orderUpdate,
  openNewTaskModal,
  isCanBeAddedNewTask,
}: IProps) => {
  const { tasks } = useStore();

  useEffect(() => {
    tasks.apiStore.getTasksStatuses();
    tasks.apiStore.getTasksResolutions();
    tasks.apiStore.getTasksProblems();
  }, []);

  const renderTask = (task: ITask): ReactNode => {
    const { tasksStatuses, tasksResolutions } = tasks;
    const status = tasksStatuses && tasksStatuses.find((s) => s.value === task.status);

    const resolution =
      tasksResolutions &&
      tasksResolutions['all'] &&
      tasksResolutions['all'].find((r) => r.value === task.resolution);

    return (
      <OrderTask
        key={task.guid}
        task={task}
        status={status}
        resolution={resolution}
        onCancelTask={onCancelTask}
        changeTaskTimeslot={changeTaskTimeslot}
        orderUpdate={orderUpdate}
        isCanBeAddedNewTask={isCanBeAddedNewTask}
        openNewTaskModal={openNewTaskModal}
        order={order}
      />
    );
  };
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{order.tasks && order.tasks.map(renderTask)}</>;
};

export default observer(OrderTaskList);
