import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { az_translations } from './az_translations';
import { en_translations } from './en_translations';
import { kk_translations } from './kk_translations';
import { ky_translations } from './ky_translations';
import { mn_translations } from './mn_translations';
import { ru_translations } from './ru_translations';
import { zh_translations } from './zh_translations';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    returnNull: false,
    detection: {
      lookupLocalStorage: 'locale',
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      ru: {
        translation: ru_translations,
      },
      en: {
        translation: en_translations,
      },
      az: {
        translation: az_translations,
      },
      'az-Latn': {
        translation: az_translations,
      },
      kk: {
        translation: kk_translations,
      },
      ky: {
        translation: ky_translations,
      },
      zh: {
        translation: zh_translations,
      },
      'zh-CN': {
        translation: zh_translations,
      },
      'zh-Hans': {
        translation: zh_translations,
      },
      mn: {
        translation: mn_translations,
      },
    },
  });

export const translate = i18n.t.bind(i18n);

export default i18n;

export type LOCALES = 'az-latn' | 'en' | 'kk' | 'ky' | 'mn' | 'ru' | 'zh-hans';
