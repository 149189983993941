import React, { ReactNode } from 'react';

import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';

import Status from 'modules/common/components/Status';
import { ICourier, ISessionStatus } from 'modules/couriers/models/types';
import { translate } from 'modules/localization';
import { getCourierUrl } from 'modules/routes/components/RoutesList/helpers';
import { routerStore } from 'services/store';

export const getColumns = (sessionStatuses: ISessionStatus[]): ColumnsType<ICourier> => {
  return [
    {
      dataIndex: 'profile_guid',
      title: translate('courier'),
      key: 'profile_guid',
      render: (_, row: ICourier): ReactNode =>
        `${row.profile.surname} ${row.profile.name} ${
          row.profile.patronymic ? row.profile.patronymic : ''
        }${row.profile.disabled ? ` (${translate('disabled')})` : ''}`,
      width: '25%',
    },
    {
      title: translate('lastSession'),
      key: 'session',
      render: (_, row: ICourier): ReactNode => {
        if (row.processed_session) {
          const status =
            sessionStatuses.length > 0 &&
            sessionStatuses.find((s) => s.value === row.processed_session.status);
          return (
            status && (
              <div>
                <Status status={status} />
                <div>
                  {dayjs(row.processed_session.planned_date).format('DD.MM.YYYY')}
                  {status.value === 'in_process' && (
                    <span>
                      {' '}
                      {translate('fromTime', {
                        content: dayjs(row.processed_session.session_start).format('HH:mm'),
                      })}
                    </span>
                  )}
                </div>
              </div>
            )
          );
        } else {
          return '\u2014';
        }
      },
    },
    {
      title: translate('transport'),
      key: 'transports',
      render: (_, row: ICourier): ReactNode => {
        if (row.processed_session && row.processed_session.transport) {
          return `${row.processed_session.transport.name} ${row.processed_session.transport.number}`;
        } else {
          return '\u2014';
        }
      },
    },
  ];
};

export const getRowHandler = () => (row: ICourier) => ({
  className: 'couriers__row',
  onClick: (): void =>
    routerStore.push(getCourierUrl(row.guid, row?.processed_session?.planned_date)),
});
