import { Feature, MultiPolygon, Polygon } from 'geojson';

import Api from 'api/Api';
import {
  ICoverageDeleteResponse,
  ICoveragesCreateResponse,
  ICoveragesEditResponse,
  ICoveragesForm,
  ICoveragesGetResponse,
  ICoveragesListByWarehouse,
  ICoveragesListGetResponse,
  INominatimPolygon,
} from 'modules/coverages/models/types';

export default class CoveragesApi {
  static getCoveragesList(params, signal?: AbortSignal): Promise<ICoveragesListGetResponse> {
    return Api.get('/api/v1/coverages', params, signal);
  }

  static deleteCoverage(coverageGuid: string): Promise<ICoverageDeleteResponse> {
    return Api.delete(`/api/v1/coverages/${coverageGuid}`);
  }

  static getCoverage(coverageGuid: string): Promise<ICoveragesGetResponse> {
    return Api.get(`/api/v1/coverages/${coverageGuid}`);
  }

  static editCoverage(coverageGuid: string, data: ICoveragesForm): Promise<ICoveragesEditResponse> {
    return Api.patch(data, `/api/v1/coverages/${coverageGuid}`);
  }

  static createCoverage(data: ICoveragesForm): Promise<ICoveragesCreateResponse> {
    return Api.post(data, '/api/v1/coverages');
  }

  static getCoveragesByWarehouse(
    warehouseGuid: string,
    params,
  ): Promise<ICoveragesListByWarehouse> {
    return Api.get(`/api/v1/warehouses/${warehouseGuid}/coverages`, params);
  }

  static getNominatimPolygon(name: string, signal?: AbortSignal): Promise<INominatimPolygon> {
    return Api.get(
      `/search.php?q=${name}&format=json&polygon_geojson=1&polygon_threshold=0.002`,
      null,
      signal,
      null,
      'https://nominatim.openstreetmap.org',
    );
  }

  static getIntersectsCoveragesList(
    params,
    polygon: Feature<MultiPolygon | Polygon>,
    signal: AbortSignal,
  ): Promise<ICoveragesListGetResponse> {
    return Api.post(polygon, '/api/v1/coverages/intersects', signal, params);
  }
}
