import { IPagination } from 'api/types';
import { DEFAULT_PAGINATION } from 'constants/index';
import { ICoordinatesMap, ILocation } from 'modules/locations/models/types';

export const initialPagination: IPagination = DEFAULT_PAGINATION;

export const initialCoordinatesMap: ICoordinatesMap = {
  zoomLevel: 2,
  lat: 0,
  lon: 0,
};

export const initialLocationDataForm: ILocation = {
  disabled: true,
  name: null,
  parent_guid: null,
  point: null,
  scale: null,
  created_at: null,
  creator_guid: null,
  guid: null,
  updated_at: null,
  updater_guid: null,
  warehouses: [],
  coverages: 0,
};
