import './style.less';

import React, { ReactNode } from 'react';

import { Route, Switch } from 'react-router-dom';

import UserHeader from 'modules/user/components/UserHeader';
import UserList from 'modules/user/components/UserList';
import UserModal from 'modules/user/components/UserModal';

const User = () => {
  const baseUrl = '/directories/users';

  const renderLayout = (): ReactNode => (
    <>
      <UserHeader baseUrl={baseUrl} />
      <UserList baseUrl={baseUrl} />
      <UserModal baseUrl={baseUrl} />
    </>
  );

  return (
    <Switch>
      <Route exact path={`${baseUrl}/:profileGuid/:action`} render={renderLayout} />
      <Route exact path={`${baseUrl}/:action`} render={renderLayout} />
      <Route exact path={baseUrl} render={renderLayout} />
    </Switch>
  );
};

export default User;
