import React from 'react';

import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';

import Map from 'modules/map/containers/Map';
import MarkerCluster from 'modules/map/containers/MarkerCluster';
import TaskPoint from 'modules/tasks/components/TaskPoint';
import { getPointProp } from 'modules/tasks/helpers/propHelper';
import WarehouseMarker from 'modules/warehouse/containers/WarehouseMarker';
import { useStore } from 'services/store';

const TasksMap = () => {
  const { tasks, warehouses } = useStore();

  const warehousesPoints = toJS(warehouses.listAll).filter(
    (w) => tasks.filter.warehouses.indexOf(w.guid) !== -1,
  );
  const tasksGeoJSON = tasks.tasksList
    .filter((task) => task.order && task.order[getPointProp(task)])
    .map((task) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [
          task.order && task.order[getPointProp(task)].lon,
          task.order && task.order[getPointProp(task)].lat,
        ],
      },
      properties: {},
    }));
  const warehousesGeoJSON = warehousesPoints
    .filter((item) => Boolean(item.point && item.point.lat && item.point.lon))
    .map((w) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [w.point.lon, w.point.lat],
      },
      properties: {},
    }));
  const mapView = [...tasksGeoJSON, ...warehousesGeoJSON];

  return (
    <Map mapView={mapView.length > 0 ? mapView : []} isSearch={false}>
      <MarkerCluster>
        {tasks.tasksList.map((task) => (
          <TaskPoint key={task.guid} task={task} isShort />
        ))}
      </MarkerCluster>
      {warehousesPoints &&
        warehousesPoints.length > 0 &&
        warehousesPoints.map(
          (w) =>
            w?.point?.lat &&
            w?.point?.lon && (
              <WarehouseMarker
                key={w.guid}
                position={[w.point.lat, w.point.lon]}
                name={w.title}
                address={w.address}
              />
            ),
        )}
    </Map>
  );
};

export default observer(TasksMap);
