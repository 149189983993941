import React, { useEffect } from 'react';

import { Form, FormInstance } from 'antd';
import { Feature, Point } from 'geojson';
import { LeafletMouseEvent, marker } from 'leaflet';
import { observer } from 'mobx-react-lite';
import { useMap, useMapEvents } from 'react-leaflet';

import Map from 'modules/map/containers/Map';
import { isCoordsExist } from 'modules/map/helpers';
import WarehouseMarker from 'modules/warehouse/containers/WarehouseMarker';
import { IWarehousesForm } from 'modules/warehouses/models/types';
import { useStore } from 'services/store';

import { getAddress, getMapView, getParams, getResponse } from './helpers';

interface IProps {
  isEdit: boolean;
  warehouse: Pick<IWarehousesForm, 'address' | 'guid' | 'lat' | 'lon' | 'title'>;
  handleMarkerCreate: (geoJSON: Feature<Point>) => void;
  handleAddressSet: (address: string) => void;
  form?: FormInstance;
}

const Resizer = ({ form }: { form: FormInstance }) => {
  const changingFields = Form.useWatch('transports', form);
  const map = useMap();

  useEffect(() => {
    map.invalidateSize();
  }, [changingFields]);

  return null;
};

// eslint-disable-next-line react/prop-types
const MapEventHandler = ({ markerCreate }) => {
  const handleMapClick = (e: LeafletMouseEvent) => {
    if (e.originalEvent.target === e.sourceTarget._container) {
      markerCreate(marker(e.latlng).toGeoJSON());
    }
  };

  const handleShowLocation = (e) => {
    markerCreate(e.marker.toGeoJSON());
  };

  useMapEvents({
    click: handleMapClick,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    'geosearch/showlocation': handleShowLocation,
  });

  return null;
};

const WarehouseMap = ({
  warehouse,
  handleAddressSet,
  handleMarkerCreate,
  isEdit,
  form,
}: IProps) => {
  const { core } = useStore();

  const mapView = getMapView(warehouse);

  const fetchAddress = async (geojson: Feature<Point>) => {
    const response = await fetch(
      `${core.config.geocoderBMP.url}/api/address?${getParams(geojson)}`,
      {
        headers: {
          token: core.config.geocoderBMP.token,
        },
      },
    );
    const data = await getResponse(response);
    return getAddress(data);
  };

  const markerCreate = (marker: Feature<Point>) => {
    if (!form.getFieldValue('address')) {
      fetchAddress(marker).then(handleAddressSet);
    }
    handleMarkerCreate(marker);
  };

  return (
    <Map mapView={mapView}>
      {isCoordsExist(warehouse.lat, warehouse.lon) && (
        <WarehouseMarker
          key={warehouse.guid}
          position={[warehouse.lat, warehouse.lon]}
          name={warehouse.title}
          address={warehouse.address}
          draggable={isEdit}
          markerDragOnChange={markerCreate}
        />
      )}
      <MapEventHandler markerCreate={markerCreate} />
      <Resizer form={form} />
    </Map>
  );
};

export default observer(WarehouseMap);
