import { ICoveragesForm } from 'modules/coverages/models/types';

export const prepareData = (formData: ICoveragesForm): ICoveragesForm => {
  const { name, polygon, disabled, locations, external_id } = formData;
  return {
    name: name ? name.trim() : null,
    polygon,
    disabled: !disabled,
    locations,
    external_id: external_id ? external_id.trim() : null,
  };
};
