import { useEffect } from 'react';

import L from 'leaflet';
import { useMap } from 'react-leaflet';

import { IPoint } from 'modules/locations/models/types';
import { iconCustomerMarker, iconWarehouse } from 'modules/map/icons';

interface IProps {
  onAddPoint: (point: IPoint, layerGroup: L.LayerGroup) => void;
  destination?: 'customer' | 'warehouse';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  layerGroup: any;
}

const OrderMapControl = ({ onAddPoint, destination, layerGroup }: IProps) => {
  const map = useMap();

  const addMarker = (e): void => {
    e.originalEvent.preventDefault();
    e.originalEvent.stopPropagation();
    layerGroup.clearLayers();
    L.marker(e.latlng, {
      icon: destination === 'warehouse' ? iconWarehouse : iconCustomerMarker,
    }).addTo(layerGroup);

    const coordinates: IPoint = {
      lat: e.latlng.lat,
      lon: e.latlng.lng,
    };
    onAddPoint(coordinates, layerGroup);
  };

  useEffect(() => {
    map.off('click');
    map.on('click', addMarker);

    if (!map.hasLayer(layerGroup)) {
      layerGroup.addTo(map);
    }

    return () => {
      map.off('click');
    };
  }, []);

  return null;
};

export default OrderMapControl;
