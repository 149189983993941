import './style.less';

import React, { useState } from 'react';

import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Collapse, CollapseProps, Dropdown, Menu } from 'antd';
import { observer } from 'mobx-react-lite';

import { getLocaleFromLocalStorage } from 'helpers/localization';
import { CustomIcon } from 'modules/common/containers/CustomIcon';
import i18n from 'modules/localization';
import { useStore } from 'services/store';

const LANGUAGES = {
  en: 'English',
  'az-latn': 'Azərbaycan',
  kk: 'Қазақ',
  ky: 'Кыргызча',
  'zh-hans': '简体中文',
  mn: 'Монгол',
  ru: 'Русский',
};

const LANGUAGES_IN_ENGLISH = {
  en: 'English',
  'az-latn': 'Azerbaijani',
  kk: 'Kazakh',
  ky: 'Kyrgyz',
  'zh-hans': 'Simplified Chinese',
  mn: 'Mongolian',
  ru: 'Russian',
};

const Lang = ({ lang }: { lang: string }) => (
  <>
    <div className="language__menu-name">{LANGUAGES[lang]}</div>
    <div className="language__menu-name language__menu-name--in-english">
      {LANGUAGES_IN_ENGLISH[lang]}
    </div>
  </>
);

const LangControl = () => {
  const { core } = useStore();
  const [isMenuVisible, setMenuVisible] = useState(false);
  const currentLang = getLocaleFromLocalStorage();

  const changeLang = (lang) => {
    i18n.changeLanguage(lang);
    window.location.reload();
  };

  const menu = Object.keys(LANGUAGES).map((lang) => ({
    key: lang,
    label: <Lang lang={lang} />,
    icon: <CustomIcon className="language__menu-flag" type={`flag-${lang}-mini`} />,
    className: `language__menu-item ${currentLang === lang && 'language__menu-item--current'}`,
  }));

  return (
    <div className="language">
      <Dropdown
        menu={{
          items: menu,
          onClick: ({ key }) => !(currentLang === key) && changeLang(key),
        }}
        trigger={['click']}
        placement="topRight"
        overlayClassName="language__menu"
        getPopupContainer={(el) => el}
        onOpenChange={(visible) => setMenuVisible(visible)}
      >
        <span className={`language__control ${isMenuVisible && 'language__control--active'}`}>
          <CustomIcon className="language__flag" type={`flag-${currentLang}`} />
          {core.isHeaderOpened && <div className="language__name">{LANGUAGES[currentLang]}</div>}
        </span>
      </Dropdown>
    </div>
  );
};

export const LangControlMobile = () => {
  const currentLang = getLocaleFromLocalStorage();
  const changeLang = (lang) => {
    i18n.changeLanguage(lang);
    window.location.reload();
  };

  const menu = Object.keys(LANGUAGES)
    .filter((lang) => lang !== currentLang)
    .map((lang) => ({
      key: lang,
      label: <Lang lang={lang} />,
      icon: <CustomIcon className="language__menu-flag" type={`flag-${lang}-mini`} />,
      className: 'language__menu-item',
    }));

  const itemsCollapse: CollapseProps['items'] = [
    {
      key: '1',
      label: (
        <span className="language__control">
          <CustomIcon className="language__flag" type={`flag-${currentLang}`} />
          <div className="language__name">{LANGUAGES[currentLang]}</div>
        </span>
      ),
      children: (
        <Menu onClick={({ key }) => !(currentLang === key) && changeLang(key)} items={menu} />
      ),
      className: 'language-mobile__menu',
    },
  ];

  return (
    <Collapse
      bordered={false}
      expandIconPosition="end"
      expandIcon={({ isActive }) => (isActive ? <UpOutlined /> : <DownOutlined />)}
      className="language-mobile"
      items={itemsCollapse}
    />
  );
};

export default observer(LangControl);
