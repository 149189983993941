import './style.less';

import React, { ReactNode, useEffect, useState } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Spin } from 'antd';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import { formatPhone } from 'helpers/string';
import InfoFact from 'modules/common/components/InfoBlock/InfoFact';
import InfoSection from 'modules/common/components/InfoBlock/InfoSection';
import { ProductViewIcon } from 'modules/common/components/ProductViewIcon';
import Status from 'modules/common/components/Status';
import { CustomIcon } from 'modules/common/containers/CustomIcon';
import { translate } from 'modules/localization';
import TaskCanceller from 'modules/orders/components/TaskCanceller';
import TaskCompleter from 'modules/orders/components/TaskCompleter';
import TaskCreationReason from 'modules/orders/components/TaskCreationReason';
import { IOrder } from 'modules/orders/models/types';
import SupportTaskButtons from 'modules/supportFunctional/components/SupportButtons/SupportTaskButtons';
import TaskTime from 'modules/tasks/components/TaskTime';
import { ITask, ITasksResolution, ITasksStatus } from 'modules/tasks/models/types';
import { getFullName } from 'modules/user/models/helpers';
import { IProfile } from 'modules/user/models/types';
import { useStore } from 'services/store';

dayjs.extend(utc);
dayjs.extend(timezone);

interface IProps {
  orderUpdate: () => Promise<void>;
  task: ITask;
  status: ITasksStatus;
  resolution: ITasksResolution;
  changeTaskTimeslot: (taskGuid: string, date: string, from: string, to: string) => Promise<void>;
  onCancelTask: () => Promise<void>;
  isCanBeAddedNewTask: boolean;
  openNewTaskModal: () => void;
  order: IOrder;
}

const getRouteUrl = (task: ITask): string =>
  '/arm2/routes/:itemId'.split(':itemId').join(task.route && task.route.guid);

const OrderTask = ({
  task,
  status,
  resolution,
  onCancelTask,
  orderUpdate,
  isCanBeAddedNewTask,
  openNewTaskModal,
  order,
}: IProps) => {
  const { core, tasks, routes, user } = useStore();

  const [isLoading, setIsLoading] = useState(false);
  const [creator, setCreator] = useState<IProfile>(null);

  useEffect(() => {
    routes.apiStore.getRoutesStatuses();
    user.get(task.creator_guid).then(setCreator);
  }, []);

  const renderRealDeliveryTime = (): ReactNode =>
    task.real_delivery_time && (
      <>
        <p>
          {dayjs(task.real_delivery_time).tz(task.meta.warehouse.timezone).format('DD.MM')} /
          {dayjs(task.real_delivery_time).tz(task.meta.warehouse.timezone).format('HH:mm')}
        </p>
        <TaskTime task={task} isShort />
      </>
    );

  const renderTaskRoute = (): ReactNode => {
    if (!task.route) {
      return null;
    }

    const current =
      routes.routesStatuses && routes.routesStatuses.find((s) => s.value === task.route.status);
    return (
      <>
        <p>
          <Link to={getRouteUrl(task)}>{task.route && `№${task.route.number} `}</Link>
          <span className="order-task__route-status">{current && current.title}</span>
        </p>

        {(task.status === 'in_route' || task.status === 'in_loading_route') && (
          <Popconfirm
            onConfirm={(): Promise<void> => deleteTaskFromRoute(task.guid)}
            placement="rightTop"
            title={translate('areYouSure')}
          >
            <Button type="link" icon={<CloseOutlined />} className="order-task__remove">
              {translate('removeTaskFromRoute')}
            </Button>
          </Popconfirm>
        )}
      </>
    );
  };

  const renderHeader = (): ReactNode => {
    const isActionsPermitted = core.permissions['tasks.update'];
    return (
      <>
        <div className="order-task__title">
          {translate('taskTitle')} #{task.number}
          {order?.sale_number_list && (
            <p className="order-task__sale-numbers">
              {translate('saleNumbers')}:{' '}
              {order.sale_number_list.map((n) => (
                <span key={n}>
                  {n.slice(0, -4)}
                  <mark>{n.slice(-4)}</mark>
                </span>
              ))}
            </p>
          )}
        </div>

        <div className="order-task__actions">
          {isActionsPermitted && (
            <>
              <TaskCanceller task={task} onCancel={onCancelTask} />
              <TaskCompleter task={task} onCancel={onCancelTask} />
            </>
          )}
          {core.permissions['support.scenarios.execute'] &&
            task.available_support_scenario &&
            task.available_support_scenario.length > 0 && <SupportTaskButtons task={task} />}
        </div>
      </>
    );
  };

  const renderType = (): ReactNode => {
    const type = tasks.tasksTypes && tasks.tasksTypes.find((s) => s.value === task.type);
    return type && type.title;
  };

  const deleteTaskFromRoute = async (guid: string) => {
    try {
      setIsLoading(true);
      await routes.apiStore.deleteTaskFromRoute([guid]);
      await orderUpdate();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const renderCourier = () => {
    const profile = task?.route?.session?.courier?.profile;
    if (!profile) {
      return null;
    }
    return (
      <>
        <p>{getFullName(profile)}</p>
        <a href={`tel:+${profile.phone}`}>{formatPhone(profile.phone)}</a>
      </>
    );
  };

  const renderDelay = () => {
    const name = task?.meta?.reason_late?.name;
    const comment = task?.meta?.reason_late?.comment;

    return (
      <>
        {name && <p>{name}</p>}
        {comment && (
          <div className="order-task__late-comment">
            <CustomIcon type="comment" />
            <p>{comment}</p>
          </div>
        )}
      </>
    );
  };

  return (
    <Spin spinning={isLoading}>
      <InfoSection key={task.guid} className="order-task" header={renderHeader()}>
        <InfoFact header={translate('taskStatus')}>
          <Status status={status} />
          {isCanBeAddedNewTask && (
            <Button
              className="order-task__add-extra-task"
              size="small"
              type="link"
              onClick={openNewTaskModal}
            >
              {translate('addExtraTask')}
            </Button>
          )}
        </InfoFact>
        <InfoFact header={translate('courier')}>{renderCourier()}</InfoFact>
        <InfoFact header={translate('route')}>{renderTaskRoute()}</InfoFact>
        <InfoFact header={translate('type')}>{renderType()}</InfoFact>
        <InfoFact header={translate('taskDeliveryDateTime')}>
          {dayjs(task.delivery_date).format('DD.MM.YY')} / {task.time_slot_start} –{' '}
          {task.time_slot_end}
        </InfoFact>
        <InfoFact header={translate('taskPlannedDeliveryDateTime')}>
          {task.planned_delivery_time &&
            `${dayjs(task.planned_delivery_time)
              .tz(task.meta.warehouse.timezone)
              .format('DD.MM')} / ${dayjs(task.planned_delivery_time)
              .tz(task.meta.warehouse.timezone)
              .format('HH:mm')}`}
        </InfoFact>
        <InfoFact header={translate('taskRealDeliveryDateTime')}>
          {renderRealDeliveryTime()}
        </InfoFact>
        <InfoFact header={translate('orderParameters')}>
          {task?.sizes?.weight
            ? `${+task.sizes.weight.toFixed(2)} ${translate('weightUnit')}`
            : `0 ${translate('weightUnit')}`}
          ,{' '}
          {task?.sizes?.volume ? (
            <>
              {+task.sizes.volume.toFixed(3)} {translate('lengthUnit')}
              <sup>3</sup>
            </>
          ) : (
            <>
              0 {translate('lengthUnit')}
              <sup>3</sup>
            </>
          )}
          <div className="order__metrics">
            {task?.sizes?.length ? (
              <span className="order__metric">
                {translate('lengthTemplate', { content: +task.sizes.length.toFixed(2) })}
              </span>
            ) : null}
            {task?.sizes?.width ? (
              <span className="order__metric">
                {translate('widthTemplate', { content: +task.sizes.width.toFixed(2) })}
              </span>
            ) : null}
            {task?.sizes?.height ? (
              <span className="order__metric">
                {translate('heightTemplate', { content: +task.sizes.height.toFixed(2) })}
              </span>
            ) : null}
          </div>
          {Number.isInteger(task?.meta?.total_box) &&
            `${task?.meta?.total_box}\u00A0${translate('bag', {
              count: task?.meta?.total_box,
            })}`}
          {Number.isInteger(task?.meta?.order_number) && `, №\u00A0${task?.meta?.order_number}`}
        </InfoFact>
        <InfoFact header={translate('productView')}>
          {order.transport_param &&
            order.transport_param.specialty &&
            Array.isArray(task.transport_param.specialty) && (
              <ProductViewIcon productTypes={task.transport_param.specialty} />
            )}
        </InfoFact>
        <InfoFact header={translate('creator')}>
          {creator ? `${creator.surname} ${creator.name}` : translate('automatic')}{' '}
          <TaskCreationReason task={task} />
        </InfoFact>
        <InfoFact header={translate('resolution')}>{resolution && resolution.title}</InfoFact>
        <InfoFact header={translate('reasonDelay')}>{renderDelay()}</InfoFact>
      </InfoSection>
    </Spin>
  );
};

export default observer(OrderTask);
