import './style.less';

import React, { PropsWithChildren } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';

const PlusButton = ({ children }: PropsWithChildren) => {
  return (
    <div className="plus-button-container">
      <Popover
        overlayClassName="plus-button-popover"
        content={<div className="plus-button__content">{children}</div>}
        trigger="click"
        getTooltipContainer={(el) => el.parentElement}
        placement="bottomRight"
        autoAdjustOverflow={false}
      >
        <Button className="plus-button" shape="circle" size="small" icon={<PlusOutlined />} />
      </Popover>
    </div>
  );
};

export default PlusButton;
