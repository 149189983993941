import './style.less';

import React, { useEffect, useState } from 'react';

import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import querystring from 'query-string';

import DateFilter from 'modules/arm2/components/DateFilter';
import ProblemFilter from 'modules/arm2/components/ProblemFilter';
import StatusFilter from 'modules/arm2/components/StatusFilter';
import WarehouseFilter from 'modules/arm2/components/WarehouseFilter';
import { FilterIcon } from 'modules/common/components/Icons';
import Title from 'modules/common/components/Title';
import { translate } from 'modules/localization';
import RouteSetup from 'modules/routes/components/RouteSetup';
import { getRoutesFilter } from 'modules/routes/helpers/localStorage';
import { useStore } from 'services/store';

const RoutesHeader = () => {
  const { core, routes } = useStore();
  useEffect(() => {
    const queryMap = querystring.parse(window.location.search).map_show;
    queryMap &&
      typeof queryMap === 'string' &&
      routes.setFilterRoutes('mapShow', queryMap === 'true');
  }, []);

  const [viewFilter, setViewFilter] = useState(!core.isMobile);

  return (
    <div className="routes-header">
      <Title size={Title.SIZE.H3} weight={Title.WEIGHT.SEMIBOLD} className="route-title">
        {translate('routesList')}

        {core.isMobile && (
          <Button
            type="link"
            icon={<FilterIcon />}
            onClick={() => setViewFilter(!viewFilter)}
            className={`routes-generator-source_filter-button ${
              viewFilter ? 'routes-generator-source_filter-button__active' : ''
            }`}
          />
        )}
      </Title>
      {viewFilter && (
        <div className="route-filter">
          <div className="route-filter__group">
            <ProblemFilter
              className="route-filter__problem"
              getList={routes.apiStore.getRoutes}
              setFilter={routes.setFilterRoutes}
              value={routes.filterRoutes.isProblem}
              getFilterFromLocalStorage={getRoutesFilter}
            />
            <DateFilter
              className="route-filter__date"
              getList={routes.apiStore.getRoutes}
              setFilter={routes.setFilterRoutes}
              value={routes.filterRoutes.deliveryDate}
              getFilterFromLocalStorage={getRoutesFilter}
            />
            <StatusFilter
              className="route-filter__status"
              optionsList={routes.routesStatuses}
              getList={routes.apiStore.getRoutes}
              setFilter={routes.setFilterRoutes}
              value={routes.filterRoutes.status}
              getFilterFromLocalStorage={getRoutesFilter}
            />
            <WarehouseFilter
              className="route-filter__warehouse"
              getList={routes.apiStore.getRoutes}
              setFilter={routes.setFilterRoutes}
              value={routes.filterRoutes.warehouses}
              getFilterFromLocalStorage={getRoutesFilter}
            />
          </div>
          {!core.isMobile && <RouteSetup />}
        </div>
      )}
    </div>
  );
};

export default observer(RoutesHeader);
