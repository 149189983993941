import React, { ReactNode } from 'react';

import { Route, Switch } from 'react-router-dom';

import Form from 'modules/couriers/components/directory/Form';
import Header from 'modules/couriers/components/directory/Header';
import List from 'modules/couriers/components/directory/List';

const Couriers = () => {
  const baseUrl = '/directories/couriers';

  const renderLayout = (): ReactNode => (
    <>
      <Header baseUrl={baseUrl} />
      <List baseUrl={baseUrl} />
      <Form baseUrl={baseUrl} />
    </>
  );

  return (
    <Switch>
      <Route exact path={`${baseUrl}/:entityGuid/:action`} render={renderLayout} />
      <Route exact path={`${baseUrl}/:action`} render={renderLayout} />
      <Route exact path={baseUrl} render={renderLayout} />
    </Switch>
  );
};

export default Couriers;
