import React from 'react';

import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';

import { GEO_BASE_URL } from 'constants/index';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

interface IParams {
  locationGuid: string;
}

const LocationsNav = () => {
  const { locations } = useStore();

  const { locationGuid } = useParams<IParams>();

  return (
    locations.viewLocation?.coverages > 0 && (
      <div className="locations-nav__list">
        <NavLink to={`${GEO_BASE_URL}/${locationGuid}/coverages`} className="locations-nav__link">
          {translate('coverages')}
        </NavLink>
        <NavLink
          to={`${GEO_BASE_URL}/${locationGuid}/service-zones`}
          className="locations-nav__link"
        >
          {translate('serviceZones')}
        </NavLink>
        <NavLink to={`${GEO_BASE_URL}/${locationGuid}/rates`} className="locations-nav__link">
          {translate('rates')}
        </NavLink>
      </div>
    )
  );
};

export default observer(LocationsNav);
