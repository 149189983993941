import React from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import { observer } from 'mobx-react-lite';

import Title from 'modules/common/components/Title';
import { translate } from 'modules/localization';
import Route from 'modules/routeGenerator/components/right/Route';
import { IRouteDraft } from 'modules/routeGenerator/models/types';
import { highlight } from 'modules/routeGenerator/models/utils';
import { useStore } from 'services/store';

import RouteActions from '../RouteActions';

const GeneratorRightSideCard = () => {
  const { routeGenerator } = useStore();

  const cardData: IRouteDraft = routeGenerator.getRouteDraft(routeGenerator.openCardRouteDraftId);
  const routeId = routeGenerator.openCardRouteDraftId;
  const serialNumber = routeGenerator.getRouteDraftIndex(routeGenerator.openCardRouteDraftId) + 1;

  return (
    <div className="rg-rightside">
      <div className="rg-rightside__header">
        <Title size={Title.SIZE.H3} weight={Title.WEIGHT.SEMIBOLD} className="rg-rightside__title">
          <Button
            type="link"
            className="rg-rightside__backlink"
            onClick={() => routeGenerator.setOpenCardRouteDraftId(null)}
            data-testid="generator-backLinkToRouteDraftList"
          >
            <ArrowLeftOutlined />
          </Button>
          {translate('goToList')}
        </Title>
        <RouteActions />
      </div>
      <div className="rg-rightside__body">
        <div className="rg-rightside__cardview">
          <Spin spinning={routeGenerator.isLoadingTasksList} wrapperClassName="rg__spin--fill">
            <Route
              cardData={{
                ...cardData,
                tasksList: highlight(cardData.tasksList, routeGenerator.highlightedTasks),
              }}
              serialNumber={serialNumber}
              routeId={routeId}
              key={routeId}
            />
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default observer(GeneratorRightSideCard);
