import './style.less';

import React, { ReactNode, useEffect, useState } from 'react';

import { WarningOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import { getNextHourDate } from 'helpers/date';
import { getFieldDataList } from 'helpers/form';
import { IClientGroups } from 'modules/client-groups/models/types';
import InfoFact from 'modules/common/components/InfoBlock/InfoFact';
import InfoSection from 'modules/common/components/InfoBlock/InfoSection';
import { ProductViewIcon } from 'modules/common/components/ProductViewIcon';
import Status from 'modules/common/components/Status';
import { IDeliveryMethods } from 'modules/delivery-methods/models/types';
import { translate } from 'modules/localization';
import OrderNewTaskForm, { INewTaskForm } from 'modules/orders/components/OrderNewTaskForm';
import { prepareData } from 'modules/orders/components/OrderNewTaskForm/helpers';
import { renderType } from 'modules/orders/components/OrdersList/helpers';
import OrderTaskList from 'modules/orders/components/OrderTaskList';
import {
  getCommentProp,
  getCustomerAddressProp,
  getCustomerPointProp,
  getWarehouseAddressProp,
  getWarehousePointProp,
} from 'modules/orders/helpers/propHelper';
import { IOrder, IOrderStatus } from 'modules/orders/models/types';
import { IShop } from 'modules/shops/models/types';
import { ITaskForm } from 'modules/tasks/models/types';
import { IWarehouses } from 'modules/warehouses/models/types';
import { useStore } from 'services/store';

dayjs.extend(utc);
dayjs.extend(timezone);

interface IProps {
  order: IOrder;
  onCancelTask: () => Promise<void>;
  changeTaskTimeslot: (taskGuid: string, date: string, from: string, to: string) => Promise<void>;
  createTask: (taskForm: ITaskForm) => Promise<void>;
  openMarkerEdit: (target: string, isRepoint?: boolean) => void;
  closeMarkerEdit: () => void;
  isMarkerEdit: boolean;
  orderUpdate: () => Promise<void>;
}

const OrderInfo = ({
  order,
  onCancelTask,
  changeTaskTimeslot,
  createTask,
  openMarkerEdit,
  orderUpdate,
  isMarkerEdit,
}: IProps) => {
  const { core, orders, tasks, deliveryMethods, clientGroups, shops, warehouses } = useStore();

  const [deliveryMethod, setDeliveryMethod] = useState<IDeliveryMethods>(null);
  const [shop, setShop] = useState<IShop>(null);
  const [clientGroup, setClientGroup] = useState<IClientGroups>(null);
  const [warehouse, setWarehouse] = useState<IWarehouses>(null);

  useEffect(() => {
    orders.apiStore.getOrderStatuses();
    orders.apiStore.getOrderTypes();
    orders.apiStore.getOrderResolutions();
    tasks.apiStore.getTasksTypes();
    shops.getList({ current: 1, pageSize: 100 }, false);
  }, []);

  useEffect(() => {
    order.delivery_method_guid &&
      deliveryMethods.get(order.delivery_method_guid).then((item) => setDeliveryMethod(item));
  }, [order.delivery_method_guid]);

  useEffect(() => {
    order.shop_guid && shops.get(order.shop_guid).then((item) => setShop(item));
  }, [order.shop_guid]);

  useEffect(() => {
    order.client_group_guid &&
      clientGroups.get(order.client_group_guid).then((item) => setClientGroup(item));
  }, [order.client_group_guid]);

  useEffect(() => {
    order.warehouse_guid && warehouses.get(order.warehouse_guid).then((item) => setWarehouse(item));
  }, [order.warehouse_guid]);

  const [isLoadingTaskSubmit, setIsLoadingTaskSubmit] = useState(false);

  // создать задачу
  const [isNewTaskModalOpened, updateNewTaskModalOpened] = useState(false);
  const openNewTaskModal = (): void => updateNewTaskModalOpened(true);
  const closeNewTaskModal = (): void => updateNewTaskModalOpened(false);

  const handleError = (values, form: FormInstance) => (response) => {
    const fieldDataList = getFieldDataList(response, values);
    form.setFields(fieldDataList);
  };

  const handleNewTaskSubmit = (values: INewTaskForm, form: FormInstance) => {
    setIsLoadingTaskSubmit(true);
    const preparedData = prepareData(values);
    createTask(preparedData)
      .then(() => {
        form.resetFields();
        closeNewTaskModal();
        setIsLoadingTaskSubmit(false);
      })
      .catch(handleError(values, form))
      .then(() => setIsLoadingTaskSubmit(false));
  };

  const handleNewTaskCancel = (form: FormInstance): void => {
    form.resetFields();
    closeNewTaskModal();
  };
  const isPermittedToAddTask = core.permissions['tasks.create'];
  const isCanBeAddedNewTask = isPermittedToAddTask && order?.config?.extra_task;

  const renderTaskListHeader = (): ReactNode => {
    const tasksLength = (order.tasks && order.tasks.length) || 0;
    return (
      <div className="new-task-header">
        <div>
          {tasksLength > 0
            ? `${tasksLength} ${translate('task', { count: tasksLength })} ${translate('byOrder')}`
            : translate('noTasks')}
        </div>
      </div>
    );
  };

  const isNeedToSetCustomerCoords =
    order.status === 'no_coordinates' && !order[getCustomerPointProp(order)];
  const isCustomerCoordsCanBeRepoint =
    order.status === 'awaiting_completion' ||
    order.status === 'canceled' ||
    order.status === 'completed';
  const isNeedToSetWarehouseCoords = !order[getWarehousePointProp(order)];

  const status: IOrderStatus =
    orders.orderStatuses && orders.orderStatuses.find((s) => s.value === order.status);

  const getAddressFactTitle = () => {
    return (
      <>
        {translate('addressTitle')}{' '}
        {(!order.sender_point || !order.point) && (
          <WarningOutlined className="order__address-warning" />
        )}
      </>
    );
  };

  const newTaskInitialValues =
    order.delivery_method_guid === '529986cb-c469-4c12-adec-98428bf04b57' ||
    order.delivery_method_guid === 'e6aee8a0-aaa2-4eaf-ab89-c592aad9848b'
      ? {
          task_destination: 'recipient',
          delivery_date: dayjs(),
          time_slot_start: getNextHourDate(dayjs()),
          time_slot_end: getNextHourDate(dayjs(), 2),
        }
      : undefined;

  const Intercom = () => {
    let intercomContent = '-';
    if (order?.meta?.call_on_arrival) {
      intercomContent = translate('intercomNotWorking');
    }
    if (!order?.meta?.call_on_arrival && order?.meta?.intercom) {
      intercomContent = `${order.meta.intercom}`;
    }
    return (
      <div className="order-info__intercom">
        {translate('intercom')}: {intercomContent}
      </div>
    );
  };

  const Barrier = () => {
    let barrierContent = translate('barrierNotExist');
    if (order?.meta?.barrier === 'true') {
      barrierContent = translate('barrierExist');
    }
    if (order?.meta?.barrier === 'false') {
      barrierContent = translate('barrierNotExist');
    }
    if (order?.meta?.barrier === 'call') {
      barrierContent = translate('barrierCall');
    }
    return (
      <div className="order-info__barrier">
        {translate('barrier')}: {barrierContent}
      </div>
    );
  };

  return (
    <>
      <InfoSection className="order-info">
        <InfoFact header={translate('orderStatus')}>
          <Status status={status} />
        </InfoFact>
        <InfoFact header={translate('orderDeliveryDateTime')}>
          {dayjs(order.delivery_date).format('DD.MM.YY')} / {order.time_slot_start} –{' '}
          {order.time_slot_end}
        </InfoFact>
        <InfoFact header={translate('deliveryMethod')}>
          {deliveryMethod ? deliveryMethod.name : null}
        </InfoFact>
        <InfoFact header={translate('client')}>
          <div className="order__infofact-item">
            <p className="order__infofact-title">{translate('contractor')}</p>
            <p>
              {order?.meta?.recipient_company_short_name ? (
                order?.meta?.recipient_company_short_name
              ) : (
                <>&mdash;</>
              )}
            </p>
          </div>
        </InfoFact>
        <InfoFact className="order-info__address" header={getAddressFactTitle()}>
          <div className="order__infofact-item">
            <p className="order__infofact-title">{translate('warehouse')}</p>
            <p>{order[getWarehouseAddressProp(order)]}</p>
            {isNeedToSetWarehouseCoords ? (
              <Link
                className={`order-coords__edit ${isMarkerEdit && 'order-coords__edit_disabled'}`}
                to="#"
                onClick={() =>
                  openMarkerEdit(order.type === 'customer_to_warehouse' ? 'recipient' : 'sender')
                }
              >
                {translate('geocode')}
              </Link>
            ) : null}
          </div>
          <div className="order__infofact-item">
            <p className="order__infofact-title">{translate('client')}</p>
            <p>{order[getCustomerAddressProp(order)]}</p>
            {isNeedToSetCustomerCoords ? (
              <Link
                className={`order-coords__edit ${isMarkerEdit && 'order-coords__edit_disabled'}`}
                to="#"
                onClick={() =>
                  openMarkerEdit(order.type === 'customer_to_warehouse' ? 'sender' : 'recipient')
                }
              >
                {translate('geocode')}
              </Link>
            ) : null}

            <Intercom />
            <Barrier />

            {core.permissions['orders.update'] && isCustomerCoordsCanBeRepoint ? (
              <Button
                type="link"
                className={`order-coords__edit ${isMarkerEdit && 'order-coords__edit_disabled'}`}
                onClick={() =>
                  openMarkerEdit(
                    order.type === 'customer_to_warehouse' ? 'sender' : 'recipient',
                    true,
                  )
                }
              >
                {translate('geocode')}
              </Button>
            ) : null}
          </div>
        </InfoFact>
        <InfoFact header={translate('orderType')}>
          <p className="order__type">{renderType(order.type, orders.orderTypes)}</p>
        </InfoFact>
        <InfoFact header={translate('orderParameters')}>
          {order?.sizes?.weight && order?.sizes?.volume ? `${translate('weight')}:\u00A0` : null}
          {order?.sizes?.weight
            ? `${+order.sizes.weight.toFixed(2)} ${translate('weightUnit')}`
            : `0 ${translate('weightUnit')}`}
          ,{' '}
          {order?.sizes?.volume ? (
            <>
              {+order.sizes.volume.toFixed(3)} {translate('lengthUnit')}
              <sup>3</sup>
            </>
          ) : (
            <>
              0 {translate('lengthUnit')}
              <sup>3</sup>
            </>
          )}
          <div className="order__metrics">
            {order?.sizes?.length ? (
              <span className="order__metric">
                {translate('lengthTemplate', { content: +order.sizes.length.toFixed(2) })}
              </span>
            ) : null}
            {order?.sizes?.width ? (
              <span className="order__metric">
                {translate('widthTemplate', { content: +order.sizes.width.toFixed(2) })}
              </span>
            ) : null}
            {order?.sizes?.height ? (
              <span className="order__metric">
                {translate('heightTemplate', { content: +order.sizes.height.toFixed(2) })}
              </span>
            ) : null}
          </div>
          {Number.isInteger(order?.meta?.total_box) &&
            `${order?.meta?.total_box}\u00A0${translate('bag', {
              count: order?.meta?.total_box,
            })}`}
          {Number.isInteger(order?.meta?.order_number) && `, №\u00A0${order?.meta?.order_number}`}
        </InfoFact>
        <InfoFact header={translate('productView')}>
          {order.transport_param &&
          order.transport_param.specialty &&
          Array.isArray(order.transport_param.specialty) ? (
            <ProductViewIcon productTypes={order.transport_param.specialty} />
          ) : null}
        </InfoFact>
        <InfoFact header={translate('comment')}>{order[getCommentProp(order)]}</InfoFact>
      </InfoSection>
      <InfoSection header={renderTaskListHeader()} className="order__task-list">
        <OrderTaskList
          order={order}
          onCancelTask={onCancelTask}
          changeTaskTimeslot={changeTaskTimeslot}
          orderUpdate={orderUpdate}
          openNewTaskModal={openNewTaskModal}
          isCanBeAddedNewTask={isCanBeAddedNewTask}
        />
      </InfoSection>
      <InfoSection header={translate('orderDetails')} className="route__details">
        <InfoFact header={translate('shop')}>{shop ? shop.short_name : null}</InfoFact>
        <InfoFact header={translate('warehouse')}>{warehouse ? warehouse.title : null}</InfoFact>
        <InfoFact header={translate('clientGroup')}>
          {clientGroup ? clientGroup.name : null}
        </InfoFact>
        <InfoFact header={translate('createdDateTime')}>
          {dayjs(order.created_at).format('DD.MM.YY')} /{' '}
          {warehouse && warehouse.timezone
            ? dayjs.tz(order.created_at, warehouse.timezone).format('HH:mm')
            : null}
        </InfoFact>
      </InfoSection>

      {isCanBeAddedNewTask ? (
        <OrderNewTaskForm
          isModalOpened={isNewTaskModalOpened}
          onCancel={handleNewTaskCancel}
          onSubmit={handleNewTaskSubmit}
          order={order}
          loading={isLoadingTaskSubmit}
          initialValues={newTaskInitialValues}
        />
      ) : null}
    </>
  );
};

export default observer(OrderInfo);
