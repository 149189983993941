import React from 'react';

import { getAddressProp } from 'modules/tasks/helpers/propHelper';
import { ITask } from 'modules/tasks/models/types';

import TaskComment from './TaskComment';

interface IProps {
  task: ITask;
}

const TaskAddress = ({ task }: IProps) => {
  const recipientName = task?.meta?.recipient_company_short_name;
  const address = task.order && task.order[getAddressProp(task)];
  return (
    <div className="rg-task__address" title={address}>
      <TaskComment task={task} />
      {recipientName && <b>{recipientName}, </b>}
      {address}
    </div>
  );
};

export default TaskAddress;
