import { LOCAL_STORAGE } from 'constants/index';
import { IFilter } from 'modules/arm2/models/types';

export const getCouriersFilter = (): IFilter => {
  const filterString = localStorage.getItem(LOCAL_STORAGE.COURIERS_FILTER);
  return JSON.parse(filterString);
};

export const setCouriersFilter = (filter: IFilter): void => {
  const filterString = JSON.stringify(filter);
  localStorage.setItem(LOCAL_STORAGE.COURIERS_FILTER, filterString);
};
