import React from 'react';

import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';

import { translate } from 'modules/localization';
import TaskCreationReason from 'modules/orders/components/TaskCreationReason';
import { getOrderUrlGenerator } from 'modules/tasks/components/TasksList/helpers';
import { ITask } from 'modules/tasks/models/types';

interface IProps {
  task: ITask;
}

const TaskNumber = ({ task }: IProps) => {
  const getProblems = (problems: string[]) => {
    return (
      <div>
        {translate('thereAreSomeProblems')}:{' '}
        {problems.map((p, i) => (
          <p key={i}>– {p}</p>
        ))}
      </div>
    );
  };

  if (task.error) {
    return (
      <div className="rg-task__number rg-task__number--error">
        <Link target="_blank" to={getOrderUrlGenerator(task)}>
          #{task.number}
        </Link>
      </div>
    );
  }

  if (task.problems && task.problems.length > 0) {
    return (
      <Tooltip title={getProblems(task.problems)}>
        <div className="rg-task__number rg-task__number--warning">
          <Link target="_blank" to={getOrderUrlGenerator(task)}>
            #{task.number}
          </Link>
        </div>
      </Tooltip>
    );
  }

  return (
    <div className="rg-task__number">
      <TaskCreationReason task={task} />
      <Link target="_blank" to={getOrderUrlGenerator(task)}>
        #{task.number}
      </Link>
    </div>
  );
};

export default TaskNumber;
