import React, { useEffect } from 'react';

import { Checkbox } from 'antd';
import querystring from 'query-string';

import { translate } from 'modules/localization';
import { ITasksFilter } from 'modules/tasks/models/types';

interface IProps {
  setFilter: (key: string, value: boolean) => void;
  getList: (filter?) => void;
  value: boolean;
  className?: string;
  getFilterFromLocalStorage?: () => ITasksFilter;
}

const ProblemFilter = ({
  setFilter,
  getList,
  value,
  className,
  getFilterFromLocalStorage,
}: IProps) => {
  useEffect(() => {
    const localStorageFilter = getFilterFromLocalStorage && getFilterFromLocalStorage();
    const localStorageDate = localStorageFilter && localStorageFilter.isProblem;
    if (querystring.parse(window.location.search).q) {
      return;
    }
    const isProblem = querystring.parse(window.location.search).is_problem || localStorageDate;
    typeof isProblem === 'string' && setFilter('isProblem', isProblem === 'true');
  }, []);

  const handleChange = (isProblem): void => {
    setFilter('isProblem', isProblem.target.checked);
    getList();
  };

  return (
    <div className={className}>
      <Checkbox onChange={handleChange} checked={value}>
        {translate('problem')}
      </Checkbox>
    </div>
  );
};

export default ProblemFilter;
