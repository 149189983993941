import React, { ReactNode } from 'react';

import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';

import Loader from 'modules/common/containers/Loader';
import { SECTION_DATA, SECTION_LIST } from 'modules/common/containers/RouterSection/constants';
import { isPermitted } from 'modules/core/helpers/permissions';
import { useStore } from 'services/store';

const RouterSection = ({ match }: RouteComponentProps<{ section: string }>) => {
  const { core } = useStore();
  const checkAccess = (menuItem) => {
    return isPermitted(core.permissions, menuItem.access);
  };

  const checkPlace = (sectionName: string): boolean => {
    const section = SECTION_DATA[sectionName];

    return section && checkAccess(section);
  };

  const renderSection = (sectionName: string): ReactNode => {
    const Section = SECTION_DATA[sectionName].component;
    return <Section />;
  };

  const redirectSection = (): ReactNode => {
    const sectionName = SECTION_LIST.find(checkPlace);

    if (sectionName) {
      return <Redirect to={`/${sectionName}`} />;
    }

    return null;
  };

  if (core.accountIsLoading || core.permissionsIsLoading) {
    return <Spin indicator={<Loader show />} spinning wrapperClassName="locations__form-footer" />;
  }

  const sectionName = match.params.section;

  return sectionName && checkPlace(sectionName) ? renderSection(sectionName) : redirectSection();
};

export default withRouter(observer(RouterSection));
