import { Location } from 'history';

import { routerStore } from 'services/store';

export const getEditUrl = (
  baseUrl: string,
  location: Location<Record<string, never>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  row: any,
): string => `${baseUrl}/:itemId/edit${location.search}`.split(':itemId').join(row.guid);

export const getRowHandler =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (baseUrl: string, location: Location<Record<string, never>>) => (row: any) => ({
    className: 'dir__row',
    onClick: () => routerStore.push(getEditUrl(baseUrl, location, row)),
  });
