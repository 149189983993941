import React, { ReactNode, useEffect } from 'react';

import { TimePickerProps } from 'antd/lib/time-picker';
import dayjs from 'dayjs';
import querystring from 'query-string';

import { timeFormat } from 'helpers/string';
import { IFilter } from 'modules/arm2/models/types';
import { TimePicker } from 'modules/common/containers/TimePicker';
import { translate } from 'modules/localization';

interface IProps {
  setFilter: (key: string, value: string[] | string) => void;
  getList: (filter?) => void;
  value: {
    from: string;
    to: string;
  };
  className?: string;
  getFilterFromLocalStorage?: () => IFilter;
}

// TODO переписать на <TimePicker.RangePicker />
const defaultTimeParameters = {
  hourMax: 23,
  hourStep: 1,
  minuteMax: 59,
  minuteStep: 1,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getFromDisablePropsData = (fromdayjs, todayjs, params = {}): any => {
  const parameters = {
    ...defaultTimeParameters,
    ...params,
  };
  todayjs.add(-parameters.minuteStep, 'm');
  const hours = todayjs.hour();
  const minutes = todayjs.minute();
  const fromHours = Number(fromdayjs.hour()) || 0;
  const hourList = [];
  const minuteList = [];

  for (let i = hours + parameters.hourStep; i <= parameters.hourMax; i++) {
    hourList.push(i);
  }

  if (fromHours === Number(hours)) {
    for (
      let i = minutes + parameters.minuteStep;
      i <= parameters.minuteMax;
      i += parameters.minuteStep
    ) {
      minuteList.push(i);
    }
  }

  return {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    disabledHours: (): any => hourList,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    disabledMinutes: (): any => minuteList,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getToDisablePropsData = (todayjs, fromdayjs): any => {
  const hours = fromdayjs.hour();
  const minutes = fromdayjs.minute();
  const toHours = todayjs.hour();
  const hourList = [];
  const minuteList = [];

  for (let i = 0; i < hours; i++) {
    hourList.push(i);
  }

  if (!toHours || toHours === hours) {
    for (let i = 0; i < minutes; i++) {
      minuteList.push(i);
    }
  }

  return {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    disabledHours: (): any[] => hourList,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    disabledMinutes: (): any[] => minuteList,
  };
};

const getPropsDisableData = {
  from: getFromDisablePropsData,
  to: getToDisablePropsData,
};
const defaultValue = dayjs('00:00', timeFormat.simple);
const defaultTimeProps: TimePickerProps = {
  className: 'arm-filter-time__item',
  defaultValue,
  format: timeFormat.simple,
  hideDisabledOptions: true,
};
const placeholder = {
  from: translate('from'),
  to: translate('to'),
};

const TimeFilter = ({
  setFilter,
  getList,
  value,
  className,
  getFilterFromLocalStorage,
}: IProps) => {
  useEffect(() => {
    const localStorageFilter = getFilterFromLocalStorage && getFilterFromLocalStorage();
    const localStorageFrom = localStorageFilter && localStorageFilter.from;
    const localStorageTo = localStorageFilter && localStorageFilter.to;

    const from = querystring.parse(window.location.search).from || localStorageFrom;
    const to = querystring.parse(window.location.search).to || localStorageTo;

    typeof from === 'string' && setFilter('from', from);
    typeof to === 'string' && setFilter('to', to);
  }, []);

  const handleFilter =
    (name) =>
    (timevalue): void => {
      const time = timevalue ? dayjs(timevalue).format(timeFormat.simple) : null;
      setFilter(name, time);
      getList();
    };

  const renderTimePicker = (name, oppositeName): ReactNode => {
    const currentvalue = value[name];
    const oppositeValue = value[oppositeName];
    const valuedayjs = dayjs(currentvalue, timeFormat.simple);

    let props = defaultTimeProps;

    if (oppositeValue) {
      const oppositeValuedayjs = dayjs(oppositeValue, timeFormat.simple);
      const disablePropsData = getPropsDisableData[name](valuedayjs, oppositeValuedayjs);

      props = {
        ...props,
        disabledTime: () => disablePropsData,
      };
    }

    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <TimePicker
        {...props}
        showNow={false}
        placeholder={placeholder[name]}
        onChange={handleFilter(name)}
        value={value[name] ? valuedayjs : null}
        id={name}
        allowClear
        getPopupContainer={(el) => el}
      />
    );
  };

  return (
    <div className={className}>
      {renderTimePicker('from', 'to')}
      <div className="separator">&ndash;</div>
      {renderTimePicker('to', 'from')}
    </div>
  );
};

export default TimeFilter;
