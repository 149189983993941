import { DEFAULT_PAGINATION } from 'constants/index';
import { IServiceZonesFilter, IServiceZonesForm } from 'modules/service-zones/models/types';

export const initialFilter: IServiceZonesFilter = {
  ...DEFAULT_PAGINATION,
  locationGuid: null,
  isActive: true,
};

export const initialServiceZoneDataForm: IServiceZonesForm = {
  client_groups: [],
  coverages: [],
  disabled: true,
  location_guid: null,
  name: null,
  warehouses: [],
};
