import React, { useEffect } from 'react';

import { CloudDownloadOutlined, ExpandOutlined } from '@ant-design/icons';
import { Card, Descriptions, List } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';

import DelorianState from 'modules/qa/common/DelorianState';
import Diff from 'modules/qa/common/Diff';
import { DiffFlag, RedisFlag } from 'modules/qa/common/flags';
import { IOrder } from 'modules/qa/models/types';
import { useStore } from 'services/store';

type IPropsSidebar = {
  order: IOrder;
};

const Sidebar = ({ order }: IPropsSidebar) => {
  return (
    <>
      <Descriptions bordered title="Заказ" size="small" column={1}>
        <Descriptions.Item label="Заказ">{order.guid}</Descriptions.Item>
        {order.number && <Descriptions.Item label="Номер">{order.number}</Descriptions.Item>}
        {order.delivery_date && order.time_slot_start && order.time_slot_end && (
          <Descriptions.Item label="Таймслот">
            {dayjs(order.delivery_date).format('DD.MM.YY')} / {order.time_slot_start} –{' '}
            {order.time_slot_end}
          </Descriptions.Item>
        )}
        <Descriptions.Item label="death_time">{order.death_time}</Descriptions.Item>
        <Descriptions.Item label="Блокирующий процесс">{order.locked}</Descriptions.Item>
        <Descriptions.Item label="prev_task_guid">{order.prev_task_guid}</Descriptions.Item>
        <Descriptions.Item label="route_guid">{order.route_guid}</Descriptions.Item>
        <Descriptions.Item label="task_guid">{order.task_guid}</Descriptions.Item>
        <Descriptions.Item label="temperature">{order.temperature}</Descriptions.Item>
        <Descriptions.Item label="warehouse_guid">{order.warehouse_guid}</Descriptions.Item>
      </Descriptions>

      <DelorianState entity={order} />

      <p>{order.redis_stored && <RedisFlag withDescription />}</p>

      <Diff entity={order} />

      <pre>{JSON.stringify(order, null, 2)}</pre>
    </>
  );
};

export const OrderSidebar = Sidebar;

const OrderList = () => {
  const { orderCollectorMonitor } = useStore();

  useEffect(() => {
    orderCollectorMonitor.getOrders({ pageSize: 100, current: 1 });
  }, []);

  return (
    <List
      grid={{ column: 1 }}
      header={<b>Заказы</b>}
      dataSource={orderCollectorMonitor.ordersList}
      renderItem={(order) => {
        const openOrder = () => {
          orderCollectorMonitor.setSidebarType('order');
          orderCollectorMonitor.setSidebarData(order);
        };
        return (
          <List.Item>
            <Card
              title={
                <>
                  {order.redis_stored && <RedisFlag />}
                  {order.difference && <DiffFlag />}
                </>
              }
              extra={
                <>
                  {!order.number && (
                    <>
                      <a
                        href="#"
                        rel="noreferrer"
                        onClick={() => orderCollectorMonitor.loadOrder(order.guid)}
                      >
                        <CloudDownloadOutlined />
                      </a>
                      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </>
                  )}
                  <a href="#" rel="noreferrer" onClick={openOrder}>
                    <ExpandOutlined />
                  </a>
                </>
              }
            >
              {order.guid}
              {order.number && (
                <p>
                  <a target="_blank" rel="noreferrer" href={`/arm2/orders/${order.guid}`}>
                    #{order.number}
                  </a>
                </p>
              )}
              {order.delivery_date && order.time_slot_start && order.time_slot_end && (
                <p>
                  {dayjs(order.delivery_date).format('DD.MM.YY')} / {order.time_slot_start} –{' '}
                  {order.time_slot_end}
                </p>
              )}
            </Card>
          </List.Item>
        );
      }}
    />
  );
};

export default observer(OrderList);
