import { Checkbox, Input, InputNumber } from 'antd';

import { IFieldData } from 'helpers/form';
import DeliveryMethodsSelect from 'modules/delivery-methods/components/DeliveryMethodsSelect';
import { translate } from 'modules/localization';
import { ITransport, ITransportForm, ITransportPayload } from 'modules/transports/models/types';
import WarehousesSelect from 'modules/warehouses/components/WarehousesSelect';

export const getFields =
  (isView: boolean) =>
  (isEdit: boolean, isCreate: boolean): IFieldData => ({
    name: {
      component: Input,
      formItem: {
        label: translate('title'),
        className: 'transports-form__item',
      },
      name: 'name',
      params: {
        rules: [
          {
            message: translate('requiredValidator'),
            required: true,
          },
        ],
      },
      props: {
        placeholder: translate('enterTitle'),
        disabled: isView,
      },
    },
    guid: {
      component: Input,
      formItem: {
        label: translate('id'),
        className: 'transports-form__item',
      },
      name: 'guid',
      params: {
        rules: [
          {
            message: translate('requiredValidator'),
            required: true,
          },
        ],
      },
      props: {
        placeholder: translate('enterId'),
        disabled: isEdit || isView,
      },
    },
    number: {
      component: Input,
      formItem: {
        label: translate('transportNumber'),
        className: 'transports-form__item',
      },
      name: 'number',
      params: {
        rules: [
          {
            message: translate('requiredValidator'),
            required: true,
          },
        ],
      },
      props: {
        placeholder: translate('enterTransportNumber'),
        disabled: isView,
      },
    },
    weight: {
      component: InputNumber,
      formItem: {
        label: translate('transportWeightWithUnit'),
        className: 'transports-form__item',
      },
      name: 'weight',
      params: {
        rules: [
          {
            message: translate('requiredValidator'),
            required: true,
          },
        ],
      },
      props: {
        placeholder: translate('enterTransportWeight'),
        maxLength: 5,
        disabled: isView,
      },
    },
    volume: {
      component: InputNumber,
      formItem: {
        label: translate('volumeWithUnit'),
        className: 'transports-form__item',
      },
      name: 'volume',
      params: {
        rules: [
          {
            message: translate('requiredValidator'),
            required: true,
          },
        ],
      },
      props: {
        placeholder: translate('enterVolume'),
        maxLength: 5,
        disabled: isView,
      },
    },
    width: {
      component: InputNumber,
      formItem: {
        label: translate('widthWithUnit'),
        className: 'transports-form__item',
      },
      name: 'width',
      props: {
        placeholder: translate('enterWidth'),
        maxLength: 5,
        disabled: isView,
      },
    },
    length: {
      component: InputNumber,
      formItem: {
        label: translate('lengthWithUnit'),
        className: 'transports-form__item',
      },
      name: 'length',
      props: {
        placeholder: translate('enterLength'),
        maxLength: 5,
        disabled: isView,
      },
    },
    height: {
      component: InputNumber,
      formItem: {
        label: translate('heightWithUnit'),
        className: 'transports-form__item',
      },
      name: 'height',
      props: {
        placeholder: translate('enterHeight'),
        maxLength: 5,
        disabled: isView,
      },
    },
    deliveryMethods: {
      component: DeliveryMethodsSelect,
      formItem: {
        label: translate('deliveryMethods'),
        className: 'transports-form__item',
      },
      name: 'deliveryMethods',
      props: {
        mode: 'multiple',
        disabled: isView,
      },
    },
    warehouses: {
      component: WarehousesSelect,
      formItem: {
        label: translate('warehouses'),
        className: 'transports-form__item',
      },
      name: 'warehouses',
      props: {
        disabled: isView,
      },
      params: {
        rules: [
          {
            message: translate('requiredValidator'),
            required: true,
          },
        ],
      },
    },
    uploadTime: {
      component: InputNumber,
      formItem: {
        label: translate('loadingTime'),
        className: 'transports-form__item',
      },
      name: 'upload_time',
      props: {
        placeholder: translate('enterLoadingTime'),
        maxLength: 5,
        disabled: isView,
        addonAfter: translate('timeUnitMinuteShort'),
        controls: false,
      },
      params: {
        rules: [
          {
            message: translate('requiredValidator'),
            required: true,
          },
        ],
      },
    },
    disabled: {
      component: Checkbox,
      formItem: {
        label: translate('status'),
        className: 'transports-form__item',
      },
      name: 'disabled',
      params: {
        initialValue: isCreate,
        valuePropName: 'checked',
      },
      props: {
        children: translate('activity'),
        disabled: isView,
      },
    },
  });

export const prepareData = (formData: ITransportForm, isEdit: boolean): ITransportPayload => {
  const preparedData = {
    disabled: !formData.disabled,
    height: +formData.height || null,
    length: +formData.length || null,
    name: formData.name ? formData.name.trim() : null,
    number: formData.number ? formData.number.trim() : null,
    volume: +formData.volume || null,
    weight: +formData.weight || null,
    width: +formData.width || null,
    delivery_methods: formData.deliveryMethods || [],
    warehouses: formData.warehouses ? formData.warehouses : [],
    upload_time: formData.upload_time ? formData.upload_time * 60 : null,
  };

  return isEdit
    ? preparedData
    : { ...preparedData, guid: formData.guid ? formData.guid.trim() : null };
};

export const getDataToSet = (item: ITransport): ITransportForm | Record<string, never> => {
  return {
    guid: item.guid,
    height: item.height,
    length: item.length,
    name: item.name,
    number: item.number,
    volume: item.volume,
    weight: item.weight,
    width: item.width,
    disabled: !item.disabled,
    deliveryMethods: item.delivery_methods ? item.delivery_methods.map((m) => m.guid) : [],
    warehouses: item.warehouses ? item.warehouses.map((m) => m.guid) : undefined,
    upload_time: item.upload_time / 60,
  };
};
