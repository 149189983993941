import React, { ReactNode } from 'react';

import { QuestionCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';

import { stopPropagation } from 'helpers/stopPropagation';
import { formatPhone } from 'helpers/string';
import Status from 'modules/common/components/Status';
import { IDeliveryMethods } from 'modules/delivery-methods/models/types';
import { translate } from 'modules/localization';
import { IRoutesStatus } from 'modules/routes/models/types';
import TaskTime from 'modules/tasks/components/TaskTime';
import { getAddressProp } from 'modules/tasks/helpers/propHelper';
import { ITask, ITasksStatus } from 'modules/tasks/models/types';
import { getFullName } from 'modules/user/models/helpers';
import { routerStore } from 'services/store';

export const getOrderUrl = (row: ITask): string =>
  '/arm2/orders/:itemId'.split(':itemId').join(row.order_guid);

export const getOrderUrlGenerator = (row: ITask): string =>
  '/arm2/orders/:itemId'.split(':itemId').join(row.order.guid);

const getRouteUrl = (row: ITask): string =>
  '/arm2/routes/:itemId'.split(':itemId').join(row.route && row.route.guid);

export const getColumns = (
  statuses: ITasksStatus[],
  statusesRoutes: IRoutesStatus[],
  deliveryMethods: IDeliveryMethods[],
  permissions,
): ColumnsType<ITask> => {
  return [
    {
      dataIndex: 'status',
      title: translate('status'),
      key: 'status',
      render: (status): ReactNode => {
        const current = statuses.find((s) => s.value === status);
        return <Status status={current} />;
      },
      width: '18%',
    },
    {
      dataIndex: 'time',
      title: (): ReactNode => (
        <>
          {translate('time')}
          <Tooltip title={`${translate('timeslot')} / ${translate('deliveryTime')}`}>
            <QuestionCircleOutlined className="task__help-icon" style={{ marginLeft: '3px' }} />
          </Tooltip>
        </>
      ),
      key: 'time',
      render: (_, task): ReactNode => <TaskTime task={task} />,
    },
    {
      dataIndex: 'task',
      title: translate('taskTitle'),
      key: 'task',
      render: (_, task): ReactNode => {
        return (
          <div>
            <p>
              {permissions['tasks.list'] ? (
                <span onClick={stopPropagation}>
                  <Link to={getOrderUrl(task)}>#{task.number}</Link>
                </span>
              ) : (
                `#${task.number}`
              )}
              {Number.isInteger(task?.order?.meta?.order_number) &&
                ` (№\u00A0${task?.order?.meta?.order_number})`}
              {task.phone_not_answer && (
                <Tooltip title={translate('cannotCallTheClient')}>
                  <WarningOutlined style={{ marginLeft: '10px', color: 'red', fontSize: '18px' }} />
                </Tooltip>
              )}
            </p>
            <p style={{ color: '#595959' }}>{task.order && task.order[getAddressProp(task)]}</p>
          </div>
        );
      },
    },
    {
      dataIndex: 'route',
      title: translate('route'),
      key: 'route',
      width: '15%',
      render: (_, task): ReactNode => {
        const current = task.route && statusesRoutes.find((s) => s.value === task.route.status);
        return (
          <div>
            {task.route && (
              <p onClick={stopPropagation}>
                {permissions['routes.list'] ? (
                  <span onClick={stopPropagation}>
                    <Link to={getRouteUrl(task)}>{task.route && `#${task.route.number}`}</Link>
                  </span>
                ) : (
                  task.route && `#${task.route.number}`
                )}
              </p>
            )}
            <p style={{ color: '#8C8C8C' }}>
              <span>{current && current.title}</span>
            </p>
          </div>
        );
      },
    },
    {
      dataIndex: 'route',
      title: translate('courier'),
      key: 'courier',
      render: (route, task): ReactNode => {
        const method = deliveryMethods.find((d) => d.guid === task.delivery_method_guid);
        const profile = route?.session?.courier?.profile;
        if (!profile) {
          return (
            <>
              <div className="task__courier">&mdash;</div>
              <div className="task__delivery-method">{method && method.name}</div>
            </>
          );
        }
        return (
          <>
            <div className="task__courier">
              <p>{getFullName(profile)}</p>
              <a href={`tel:+${profile.phone}`} onClick={(e) => e.stopPropagation()}>
                {formatPhone(profile.phone)}
              </a>
            </div>
            <div className="task__delivery-method">{method && method.name}</div>
          </>
        );
      },
    },
  ];
};

export const getRowHandler = () => (row: ITask) => ({
  className: 'task__row',
  onClick: (): void => routerStore.replace(getOrderUrl(row)),
});
