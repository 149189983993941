import './style.less';

import React from 'react';

import { Marker, Popup } from 'react-leaflet';

import { getStatusIconLeaflet } from 'helpers/statusIcon';
import { isCoordsExist } from 'modules/map/helpers';
import { getPointProp } from 'modules/orders/helpers/propHelper';
import { IOrder } from 'modules/orders/models/types';

import { OrderTooltip } from './helpers';

interface IProps {
  order: IOrder;
}

const OrdersPoint = ({ order }: IProps) => {
  const point = getPointProp(order);

  return isCoordsExist(order[point]?.lat, order[point]?.lon) ? (
    <Marker
      position={[order[point].lat, order[point].lon]}
      icon={getStatusIconLeaflet({ status: order.status })}
    >
      <Popup closeButton={false}>
        <OrderTooltip order={order} />
      </Popup>
    </Marker>
  ) : null;
};

export default OrdersPoint;
