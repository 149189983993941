import React, { ReactNode, forwardRef, useEffect } from 'react';

import { Select, Spin } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { observer } from 'mobx-react-lite';
import { BaseSelectRef } from 'rc-select/lib/BaseSelect';

import { selectFilter } from 'helpers/form';
import { IClientGroups } from 'modules/client-groups/models/types';
import { DEFAULT_SELECT_PROPS } from 'modules/common/components/Select';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

const ClientsGroupsSelect = forwardRef<BaseSelectRef, SelectProps>((props, ref) => {
  const { clientGroups } = useStore();

  const getOptions = () => {
    const options = clientGroups.listAll;
    if (options && Array.isArray(options)) {
      return options;
    }
    return [];
  };

  const fetchDefaultOptions = async () => {
    await clientGroups.getAll();
  };

  useEffect(() => {
    fetchDefaultOptions();
  }, []);

  const renderOption = (option: IClientGroups): ReactNode => {
    if (option) {
      return (
        <Select.Option key={option.guid} value={option.guid}>
          {option.name || option.guid} {option.disabled ? ` (${translate('disabled')})` : ''}
        </Select.Option>
      );
    }

    return null;
  };

  const options = getOptions();

  return (
    <Select
      {...props}
      allowClear
      showSearch
      ref={ref}
      loading={clientGroups.isLoadingList}
      filterOption={selectFilter}
      defaultActiveFirstOption={false}
      getPopupContainer={(el) => el}
      placeholder={translate('enterClientGroup')}
      notFoundContent={
        clientGroups.isLoadingList ? <Spin size="small" /> : translate('nothingWasFound')
      }
      {...DEFAULT_SELECT_PROPS}
    >
      {options.map(renderOption)}
    </Select>
  );
});

export default observer(ClientsGroupsSelect);
