import Api from 'api/Api';
import { IPagination } from 'api/types';
import {
  ICountriesCreateResponse,
  ICountriesDeleteResponse,
  ICountriesEditResponse,
  ICountriesListGetResponse,
  ICountryGetResponse,
  ICountryPayload,
} from 'modules/countries/models/types';

export default class CountriesApi {
  static getCountry(guid: string): Promise<ICountryGetResponse> {
    return Api.get(`/api/v1/countries/${guid}`);
  }

  static getCountriesList(
    pagination: IPagination,
    signal?: AbortSignal,
  ): Promise<ICountriesListGetResponse> {
    const query = {
      page: pagination.current,
      page_size: pagination.pageSize,
    };
    return Api.get('/api/v1/countries', query, signal);
  }

  static createCountry(data: ICountryPayload): Promise<ICountriesCreateResponse> {
    return Api.post(data, '/api/v1/countries');
  }

  static editCountry(guid: string, data: ICountryPayload): Promise<ICountriesEditResponse> {
    return Api.put(data, `/api/v1/countries/${guid}`);
  }

  static deleteCountry(guid: string): Promise<ICountriesDeleteResponse> {
    return Api.delete(`/api/v1/countries/${guid}`);
  }

  static searchCountries(
    search?: string,
    pagination?: IPagination,
    signal?: AbortSignal,
  ): Promise<ICountriesListGetResponse> {
    const query = {
      page: pagination.current,
      page_size: pagination.pageSize,
      q: search,
    };
    return Api.get('/api/v1/countries', query, signal);
  }
}
