import React from 'react';

import { Alert } from 'antd';
import { observer } from 'mobx-react-lite';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { PageTitle } from 'modules/common/components/HeaderMobile';
import Layout from 'modules/common/containers/Layout';
import Couriers from 'modules/couriers/components/arm/Couriers';
import { translate } from 'modules/localization';
import Orders from 'modules/orders/components/Orders';
import Routes from 'modules/routes/components/Routes';
import Tasks from 'modules/tasks/components/Tasks';
import { useStore } from 'services/store';

interface IMenuItem {
  access?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: any;
  href: string;
  id: string;
  title: string;
}

const MENU_ITEMS: IMenuItem[] = [
  {
    access: 'orders.list',
    component: Orders,
    href: '/orders',
    id: 'orders',
    title: translate('orders'),
  },
  {
    access: 'tasks.list',
    component: Tasks,
    href: '/tasks',
    id: 'tasks',
    title: translate('tasks'),
  },
  {
    access: 'routes.list',
    component: Routes,
    href: '/routes',
    id: 'routes',
    title: translate('routes'),
  },
  {
    access: 'courier-sessions.list',
    component: Couriers,
    href: '/couriers',
    id: 'couriers',
    title: translate('couriers'),
  },
];

const Arm = () => {
  const { core } = useStore();
  const match = useRouteMatch();

  const renderSection = (routeProps): React.ReactNode => {
    const sectionName: string = routeProps.match.params.section;

    if (!sectionName) {
      return <Redirect to={`${match.url}${MENU_ITEMS[0].href}`} />;
    }

    const section: IMenuItem = MENU_ITEMS.find((i) => i.id === sectionName);

    if (!section) {
      return <div>{translate('noSectionFound')}</div>;
    }
    if ((section.access && core.permissions[section.access]) || !section.access) {
      const Component = section.component;

      return <Component baseUrl={match.url} />;
    }

    return (
      <Alert banner description message={translate('accessDenied')} showIcon type={'warning'} />
    );
  };

  const renderMain = (routeProps): React.ReactNode => {
    return <>{renderSection(routeProps)}</>;
  };

  const getDefaultSection = (): string => MENU_ITEMS[0].id;

  return (
    <Layout>
      {core.isMobile ? <PageTitle>{translate('planning')}</PageTitle> : null}
      <Switch>
        <Route path={`${match.url}/:section`} render={renderMain} />
        <Redirect to={`/arm2/${getDefaultSection()}`} />
      </Switch>
    </Layout>
  );
};

export default observer(Arm);
