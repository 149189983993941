import './style.less';

import React from 'react';

import { Button } from 'antd';
import { observer } from 'mobx-react-lite';

import { translate } from 'modules/localization';
import { IRouteDraft } from 'modules/routeGenerator/models/types';
import {
  isSomeRoutesReadyForCalculate,
  isRouteReadyToCalculate,
  isSomeRoutesReadyToCalculate,
  isSomeRoutesReadyToSave,
  isSomeRoutesReadyForSave,
} from 'modules/routeGenerator/models/utils';
import { useStore } from 'services/store';

const RouteListActions = () => {
  const { routeGenerator, warehouses, core } = useStore();

  const { routeDraftList } = routeGenerator;

  const calculate = async (routeDraft: IRouteDraft) => {
    const tz = await warehouses.getWarehouseTZ(routeDraft.tasksList[0].warehouse_guid);
    await routeGenerator.apiStore.calculateRoute(routeDraft.uid, tz);
  };

  const calculateAll = async () => {
    routeGenerator.switchIsLoadingCalculate(true);
    await Promise.all(
      routeGenerator.routeDraftList.map(async (routeDraft) => {
        if (isRouteReadyToCalculate(routeDraft)) {
          await calculate(routeDraft);
        }
      }),
    ).finally(() => {
      routeGenerator.switchIsLoadingCalculate(false);
    });
  };

  const saveAll = async () => {
    routeGenerator.switchIsLoadingSave(true);
    routeGenerator.apiStore
      .saveRoutes()
      .then(() => {
        routeGenerator.apiStore.getTasksWrapperLoading();
      })
      .finally(() => {
        routeGenerator.switchIsLoadingSave(false);
      });
  };

  const createByYandex = async () => {
    routeGenerator.switchIsLoadingCalculateYandex(true);
    routeGenerator.apiStore
      .createRouteByYandex()
      .then(() => {
        routeGenerator.apiStore.getTasksWrapperLoading();
      })
      .finally(() => {
        routeGenerator.switchIsLoadingCalculateYandex(false);
      });
  };

  const createByYandexButton = core.permissions['routes.yandex.create'] ? (
    <Button
      type="primary"
      danger
      onClick={createByYandex}
      disabled={
        !isSomeRoutesReadyToCalculate(routeDraftList) ||
        routeGenerator.getIsLoadingCalculate ||
        routeGenerator.getIsLoadingSave ||
        routeGenerator.isLoadingTasksList
      }
      loading={routeGenerator.getIsLoadingCalculateYandex}
    >
      {translate('calculateByYandexAll')}
    </Button>
  ) : null;

  if (routeDraftList.length === 0) {
    return null;
  }

  if (isSomeRoutesReadyForCalculate(routeDraftList)) {
    return (
      <div className="route-actions__button-group">
        {createByYandexButton}
        <Button
          type="primary"
          disabled={
            !isSomeRoutesReadyToCalculate(routeDraftList) ||
            routeGenerator.getIsLoadingSave ||
            routeGenerator.getIsLoadingCalculateYandex ||
            routeGenerator.isLoadingTasksList
          }
          loading={routeGenerator.getIsLoadingCalculate}
          onClick={calculateAll}
        >
          {translate('calculateAll')}
        </Button>
      </div>
    );
  }

  if (isSomeRoutesReadyForSave(routeDraftList)) {
    return (
      <div className="route-actions__button-group">
        {createByYandexButton}
        <Button
          type="primary"
          disabled={
            !isSomeRoutesReadyToSave(routeDraftList) ||
            routeGenerator.getIsLoadingCalculate ||
            routeGenerator.getIsLoadingCalculateYandex ||
            routeGenerator.isLoadingTasksList
          }
          loading={routeGenerator.getIsLoadingSave}
          onClick={saveAll}
        >
          {translate('saveAll')}
        </Button>
      </div>
    );
  }
};

export default observer(RouteListActions);
