import React from 'react';

import { Table } from 'antd';

import { IDifference } from 'modules/qa/models/types';

import { DiffFlag } from './flags';

type DiffData = {
  key: string;
  db: string;
  redis: string;
};

type Entity = {
  difference?: IDifference;
};

type DiffProps = {
  entity: Entity;
};

const Diff = ({ entity }: DiffProps) => {
  if (!entity.difference || Object.keys(entity.difference).length === 0) {
    return null; // or render an error message
  }

  const diffData: DiffData[] = Object.keys(entity.difference).map((key) => ({
    key: key,
    db: entity[key],
    redis: entity.difference[key],
  }));

  const diffColumns = [
    {
      title: 'Prop',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Entity (в базе данных)',
      dataIndex: 'db',
      key: 'db',
    },
    {
      title: 'Difference (в редисе)',
      dataIndex: 'redis',
      key: 'redis',
    },
  ];

  return (
    <div>
      <DiffFlag withDescription />
      <Table pagination={false} columns={diffColumns} dataSource={diffData} />
    </div>
  );
};

export default Diff;
