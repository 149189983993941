import React from 'react';

import icon from 'img/empty-cluster-couriers.svg?react';
import { ICluster } from 'modules/clusters/models/types';
import { translate } from 'modules/localization';

interface IProps {
  cluster: ICluster;
}

const ClusterInformationCouriers = ({ cluster }: IProps) => {
  const couriers = cluster?.profile_list;
  return (
    <div className="cluster-information-couriers">
      <h2>
        {translate('couriers')}&nbsp;({couriers?.length})
      </h2>
      <div className="cluster-information-couriers-list">
        {(!couriers || couriers.length === 0) && (
          <div className="cluster-information-couriers-empty">
            <img alt="" className="cluster-information-couriers-empty__icon" src={`${icon}`} />
            <div className="cluster-information-couriers-empty__description">
              {translate('addCouriers')}
            </div>
          </div>
        )}
        {couriers &&
          couriers.length > 0 &&
          couriers.map(({ guid, name }) => (
            <div className="cluster-information-couriers-item" key={guid}>
              {name}
            </div>
          ))}
      </div>
    </div>
  );
};

export default ClusterInformationCouriers;
