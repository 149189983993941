import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { dateFormat } from './string';

dayjs.extend(utc);
dayjs.extend(timezone);

export const getDateTime = (date: Dayjs, time: Dayjs, tz: string): string => {
  const newDate = dayjs.tz(
    `${date.format(dateFormat.search)} ${dayjs(time).hour()}:${dayjs(time).minute()}`,
    tz,
  );
  return newDate.format();
};

export const isPastDay = (d: string): boolean => {
  const now = dayjs();
  const date = dayjs(d).format(dateFormat.search);
  return now.diff(date, 'days') > 0;
};

const fromUTCOffsetToString = (offset: string): string =>
  `UTC${offset.split(':')[0].replace('+0', '+')}`;

export const getCurrentUTCTimezone = (): string => fromUTCOffsetToString(dayjs().format('Z'));

export const getUTCTimezoneFromName = (name: string): string =>
  fromUTCOffsetToString(dayjs().tz(name).format('Z'));

export const checkDateInDate = (verifiedDate: Dayjs, todayDate: Dayjs): boolean =>
  verifiedDate.isBefore(todayDate.clone().add(1, 'd').startOf('day')) &&
  verifiedDate.isAfter(todayDate.clone().subtract(1, 'd').endOf('day'));

export const getNextHourDate = (date: Dayjs, offset = 1): Dayjs => {
  const hourToSet = date.hour() + offset;
  return date.hour(hourToSet).minute(0);
};
