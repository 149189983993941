/* eslint-disable @typescript-eslint/no-explicit-any */

import L from 'leaflet';

import { LOCAL_STORAGE } from 'constants/index';
import { IConfigTile } from 'helpers/config';

const getWayPoint = (route: any): any => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return new L.Routing.Waypoint(L.GeoJSON.geometryToLayer(route).getLatLng());
};

const getCoordinates = (point: any): any => {
  return [point.lng, point.lat];
};

const getRoute = (route): any => {
  const distance = route.summary.totalDistance / 1000;
  return {
    coordinates: route.coordinates.map(getCoordinates),
    type: 'LineString',
    summary: {
      distance: distance.toFixed(1),
    },
  };
};

export const getPath = (pointList: any, routeConfig: any): any => {
  const wayPointList = pointList.map(getWayPoint);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const router = L.Routing.osrmv1(routeConfig);

  return new Promise(function (resolve, reject) {
    router.route(wayPointList, function (err: any, routes: any[]) {
      if (err) {
        return reject(err);
      }

      if (routes.length > 0) {
        return resolve(getRoute(routes[0]));
      }

      return resolve(null);
    });
  });
};

export const setTileLayer = (name: string): void => {
  localStorage.setItem(LOCAL_STORAGE.TILELAYER, name);
};

export const getTileLayer = (tiles: IConfigTile[]): IConfigTile => {
  const localStorageLayer = localStorage.getItem(LOCAL_STORAGE.TILELAYER);
  const findTile = tiles.find((item) => localStorageLayer === item.name);
  if (!localStorageLayer || !findTile) {
    return tiles[0];
  }
  return findTile;
};

export const getPolygonFromBounds = (latLngBounds: L.LatLngBounds): L.Polygon => {
  const latlngs = [
    latLngBounds.getSouthWest(),
    latLngBounds.getSouthEast(),
    latLngBounds.getNorthEast(),
    latLngBounds.getNorthWest(),
  ];

  return L.polygon(latlngs);
};

export const MIN_ZOOM_LVL_FOR_GETTING_ADJACENT_COVERAGES = 8;

export const isCoordsExist = (lat, lon) => {
  return lat !== null && lat !== undefined && lon !== null && lon !== undefined;
};
