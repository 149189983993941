export default function classSet(classNames: Record<string, boolean>): string {
  let names = '';

  if (typeof classNames === 'object') {
    for (const name in classNames) {
      // eslint-disable-next-line no-prototype-builtins
      if (!classNames.hasOwnProperty(name) || !classNames[name]) {
        continue;
      }
      names += name + ' ';
    }
  }

  return names.trim();
}
