import '@geoman-io/leaflet-geoman-free';

import { useEffect, useCallback } from 'react';

import L, { LatLng, Layer } from 'leaflet';
import { useMap } from 'react-leaflet';

import { ICoveragesPolygon } from 'modules/coverages/models/types';

import { clearPolygon, isValidPolygon } from './helpers';

interface ICreateManagerProps {
  onCreate: (polygon: Partial<ICoveragesPolygon>) => void;
  polygon?: ICoveragesPolygon;
  isView?: boolean;
}

const OPTIONS: L.PM.GlobalOptions = {
  allowSelfIntersection: false,
  cursorMarker: true,
  snappable: true,
  tooltips: true,
  snappingOrder: ['Marker', 'CircleMarker', 'Circle', 'Line', 'Polygon', 'Rectangle'],
  panes: { vertexPane: 'markerPane', layerPane: 'overlayPane', markerPane: 'markerPane' },
};

const CONTROLS: L.PM.ToolbarOptions = {
  position: 'topleft',
  drawCircle: false,
  drawMarker: false,
  drawCircleMarker: false,
  drawRectangle: false,
  drawPolyline: false,
  rotateMode: false,
};

const geoJSONOptions = {
  coordsToLatLng: (coords): LatLng => new LatLng(coords[1], coords[0]),
};

const CreateManager = ({ polygon, onCreate, isView }: ICreateManagerProps) => {
  let layer: Layer = null;
  const map = useMap();

  const createPolygon = useCallback(
    (newPolygon: Partial<ICoveragesPolygon>) =>
      isValidPolygon(newPolygon) ? onCreate(clearPolygon(newPolygon)) : onCreate(null),
    [onCreate],
  );

  const handleCreate = useCallback(
    (layer): void => {
      const polygon = layer?.layer?.toGeoJSON();
      createPolygon(polygon);
      bindLayerEvents();
    },
    [createPolygon],
  );

  const handleRemove = useCallback((): void => {
    removeLayer();
    onCreate(null);
  }, [onCreate]);

  const handleCut = useCallback(
    (_layer): void => {
      layer && layer.off('pm:edit', handleCreate);
      const polygon = _layer?.layer?.toGeoJSON();
      createPolygon(polygon);
      layer = _layer?.layer;
      bindLayerEvents();
    },
    [createPolygon, handleCreate],
  );

  const removeLayer = useCallback(() => {
    layer && map.removeLayer(layer);
    const geomanLayers = map.pm.getGeomanLayers();
    Array.isArray(geomanLayers) && geomanLayers.forEach((l) => l.remove());
  }, [map, layer]);

  const bindLayerEvents = useCallback((): void => {
    layer && layer.on('pm:edit', handleCreate);
    layer && layer.on('pm:cut', handleCut);
  }, [handleCreate, handleCut]);

  const bindMapEvents = useCallback((): void => {
    map.on('pm:create', handleCreate);
    map.on('pm:remove', handleRemove);
  }, [handleCreate, handleRemove, map]);

  const init = useCallback(() => {
    removeLayer();
    layer = L.geoJSON(polygon, geoJSONOptions);
    map.addLayer(layer);

    if (!isView) {
      bindLayerEvents();
      map.pm.setGlobalOptions(OPTIONS);
      map.pm.addControls(CONTROLS);
      bindMapEvents();
    }
  }, [removeLayer, polygon, isView, bindLayerEvents, bindMapEvents, map]);

  useEffect(() => {
    init();

    return () => {
      map.pm.disableDraw();
      map.pm.removeControls();
      removeLayer();
    };
  }, [init, removeLayer, map]);

  return null;
};

export default CreateManager;
