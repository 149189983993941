import React, { ReactNode, useEffect } from 'react';

import { Select, Spin } from 'antd';
import { observer } from 'mobx-react-lite';

import { selectFilter } from 'helpers/form';
import { ICities } from 'modules/cities/models/types';
import { DEFAULT_SELECT_PROPS } from 'modules/common/components/Select';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

const CitiesSelect = ({ ...rest }) => {
  const { cities } = useStore();

  useEffect(() => {
    cities.getAll();
  }, []);

  const getOptions = () => {
    const options = cities.listAll;
    if (options && Array.isArray(options)) {
      return options;
    }
    return [];
  };

  const renderOption = (option: ICities): ReactNode => {
    if (option) {
      return (
        <Select.Option key={option.guid} value={option.guid}>
          {option.name}
        </Select.Option>
      );
    }
    return null;
  };

  const options = getOptions();

  return (
    <Select
      allowClear
      showSearch
      loading={cities.isLoadingList}
      filterOption={selectFilter}
      getPopupContainer={(el) => el}
      placeholder={translate('enterLocality')}
      notFoundContent={cities.isLoadingList ? <Spin size="small" /> : translate('nothingWasFound')}
      {...DEFAULT_SELECT_PROPS}
      {...rest}
    >
      {options.map(renderOption)}
    </Select>
  );
};

export default observer(CitiesSelect);
