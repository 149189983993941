import { makeAutoObservable } from 'mobx';
import querystring from 'query-string';

import { RestException } from 'api/RestException';
import { getLastPagePagination, getResponsePagination, isOutOfPages } from 'helpers/pagination';
import { ViewNotification } from 'modules/common/containers/Notify';
import { translate } from 'modules/localization';
import ServiceZonesApi from 'modules/service-zones/api/ServiceZonesApi';
import { fromServiceZonesToServiceZonesForm } from 'modules/service-zones/models/helpers';
import { initialFilter, initialServiceZoneDataForm } from 'modules/service-zones/models/initial';
import {
  IServiceZones,
  IServiceZonesFilter,
  IServiceZonesForm,
} from 'modules/service-zones/models/types';
import { IWarehouses } from 'modules/warehouses/models/types';
import { routerStore } from 'services/store';

export class ServiceZonesStore {
  serviceZonesList: IServiceZones[] = [];
  activeRequestServiceZonesList: AbortController | null = null;
  serviceZoneDataForm: IServiceZonesForm = initialServiceZoneDataForm;
  loadingForm = false;
  hoveredCoverages: string[] = [];
  hoveredWarehouses: IWarehouses[] = [];
  filter: IServiceZonesFilter = initialFilter;
  hoveredGuid: string = null;

  get isLoadingServiceZonesList() {
    return this.activeRequestServiceZonesList !== null;
  }

  constructor() {
    makeAutoObservable(this);
  }

  getServiceZones = async (
    filter: IServiceZonesFilter = this.filter,
    isRedirect = true,
  ): Promise<IServiceZones[]> => {
    try {
      this.activeRequestServiceZonesList && this.activeRequestServiceZonesList.abort();
      this.activeRequestServiceZonesList = new AbortController();
      const request = this.activeRequestServiceZonesList;

      const { data: res } = await ServiceZonesApi.getServiceZonesList(filter, request.signal);
      if (isOutOfPages(res.pagination)) {
        return this.getServiceZones({ ...filter, ...getLastPagePagination(res.pagination) });
      }
      this.serviceZonesList = res.data;
      if (isRedirect && !res.errors && res.pagination) {
        this.setFilterToUrl({
          ...filter,
          current: res.pagination.page,
          pageSize: res.pagination.page_size,
        });
        const responsePagination = getResponsePagination(res.pagination);
        this.setFilter('current', responsePagination.current);
        this.setFilter('pageSize', responsePagination.pageSize);
        this.setFilter('total', responsePagination.total);
      }
      return res.data;
    } catch (e) {
      throw new RestException(e);
    } finally {
      if (
        this.activeRequestServiceZonesList &&
        !this.activeRequestServiceZonesList.signal.aborted
      ) {
        this.activeRequestServiceZonesList = null;
      }
    }
  };

  createServiceZone = async (data: IServiceZonesForm): Promise<IServiceZones> => {
    try {
      this.loadingForm = true;
      const serviceZone = await ServiceZonesApi.createServiceZone(data);
      this.resetFormData();
      ViewNotification.success({ message: translate('serviceZoneCreated') });
      this.loadingForm = false;
      return serviceZone.data.data;
    } catch (e) {
      this.loadingForm = false;
      throw new RestException(e);
    }
  };

  editServiceZones = async (
    serviceZoneGuid: string,
    data: IServiceZonesForm,
  ): Promise<IServiceZones> => {
    try {
      this.loadingForm = true;
      const serviceZone = await ServiceZonesApi.editServiceZone(serviceZoneGuid, data);
      this.resetFormData();
      ViewNotification.success({ message: translate('serviceZoneEdited') });
      this.loadingForm = false;
      return serviceZone.data.data;
    } catch (e) {
      this.loadingForm = false;
      throw new RestException(e);
    }
  };

  getServiceZone = async (serviceZoneGuid: string): Promise<IServiceZones> => {
    try {
      this.loadingForm = true;
      const { data: res } = await ServiceZonesApi.getServiceZone(serviceZoneGuid);
      this.setFormData(res.data);
      res.data.coverages && this.setHoveredCoverages(res.data.coverages.map((i) => i.guid));
      this.setHoveredWarehouses([]);
      this.loadingForm = false;
      return res.data;
    } catch (e) {
      this.loadingForm = false;
      throw new RestException(e);
    }
  };

  deleteServiceZone = async (serviceZoneGuid: string): Promise<void> => {
    try {
      await ServiceZonesApi.deleteServiceZone(serviceZoneGuid);
      ViewNotification.success({ message: translate('serviceZoneDeleted') });
    } catch (e) {
      throw new RestException(e);
    }
  };

  setFormData = (item: IServiceZones | IServiceZonesForm): void => {
    this.serviceZoneDataForm = {
      ...this.serviceZoneDataForm,
      ...fromServiceZonesToServiceZonesForm(item),
    };
  };

  resetFormData = (): void => {
    this.serviceZoneDataForm = initialServiceZoneDataForm;
  };

  setHoveredCoveragesByZone = (guid: string): void => {
    if (guid) {
      const findServiceZone = this.serviceZonesList.find((sz) => sz.guid === guid);
      findServiceZone.coverages &&
        this.setHoveredCoverages(findServiceZone.coverages.map((i) => i.guid));
      this.setHoveredWarehouses(findServiceZone.warehouses);
    } else {
      this.hoveredCoverages = [];
      this.setHoveredWarehouses([]);
    }
  };

  setHoveredCoverages = (guid: string[] | string): void => {
    if (guid) {
      this.hoveredCoverages = Array.isArray(guid) ? guid : [guid];
    } else {
      this.hoveredCoverages = [];
    }
  };

  setHoveredWarehouses = (warehouses: IWarehouses[]): void => {
    this.hoveredWarehouses = warehouses;
  };

  setFilter = (key: string, value: string[] | boolean | number | string): void => {
    this.filter[key] = value;
  };

  resetFilter = (): void => {
    this.filter = initialFilter;
  };

  setHoveredGuid = (guid: string): void => {
    this.hoveredGuid = guid;
  };

  setFilterToUrl = (filter: IServiceZonesFilter = this.filter): void => {
    const qs = querystring.stringify(
      {
        page: filter.current,
        page_size: filter.pageSize,
        is_active: filter.isActive,
      },
      { skipNull: true },
    );
    routerStore.history.location.pathname !== '/error' &&
      routerStore.replace(`${routerStore.location.pathname}?${qs}`);
  };
}
