import React from 'react';

import { Descriptions } from 'antd';

import { IClusterItem } from 'modules/qa/models/types';

import Diff from '../Diff';

interface IPropsSidebar {
  cluster: IClusterItem;
}

export const ClusterItemSidebar = ({ cluster }: IPropsSidebar) => {
  return (
    <>
      <Descriptions bordered title="Кластер" size="small" column={1}>
        <Descriptions.Item label="Кластер">{cluster.custer_guid}</Descriptions.Item>
        <Descriptions.Item label="Элемент кластера">{cluster.guid}</Descriptions.Item>
        <Descriptions.Item label="not_found_in_db">
          {cluster.not_found_in_db.toString()}
        </Descriptions.Item>
        <Descriptions.Item label="shop_guid">{cluster.shop_guid}</Descriptions.Item>
        <Descriptions.Item label="warehouse_guid">{cluster.warehouse_guid}</Descriptions.Item>
      </Descriptions>

      <Diff entity={cluster} />

      <pre>{JSON.stringify(cluster, null, 2)}</pre>
    </>
  );
};
