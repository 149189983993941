import Api from 'api/Api';
import { IPagination } from 'api/types';
import {
  ITransportGetResponse,
  ITransportPayload,
  ITransportsCreateResponse,
  ITransportsDeleteResponse,
  ITransportsEditResponse,
  ITransportsListGetResponse,
} from 'modules/transports/models/types';

export default class TransportApi {
  static getTransport(guid: string): Promise<ITransportGetResponse> {
    return Api.get(`/api/v1/transports/${guid}`);
  }

  static getTransportsList(
    pagination: IPagination,
    signal?: AbortSignal,
  ): Promise<ITransportsListGetResponse> {
    const query = {
      page: pagination.current,
      page_size: pagination.pageSize,
    };
    return Api.get('/api/v1/transports', query, signal);
  }

  static createTransport(data: ITransportPayload): Promise<ITransportsCreateResponse> {
    return Api.post(data, '/api/v1/transports');
  }

  static editTransport(guid: string, data: ITransportPayload): Promise<ITransportsEditResponse> {
    return Api.patch(data, `/api/v1/transports/${guid}`);
  }

  static deleteTransport(guid: string): Promise<ITransportsDeleteResponse> {
    return Api.delete(`/api/v1/transports/${guid}`);
  }

  static searchTransports(
    search: string,
    pagination: IPagination,
    signal?: AbortSignal,
  ): Promise<ITransportsListGetResponse> {
    const params = {
      page: pagination.current,
      page_size: pagination.pageSize,
      q: search,
    };
    return Api.get('/api/v1/search/transports', params, signal);
  }
}
