import React from 'react';

import { Button } from 'antd';

import { translate } from 'modules/localization';

interface IProps {
  handleCancel: () => void;
  submitClickHandler: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isLoadingModal: boolean;
  isLoadingCourier: boolean;
  isLoadingTransport: boolean;
}

const FooterModal = ({
  handleCancel,
  isLoadingModal,
  isLoadingCourier,
  isLoadingTransport,
  submitClickHandler,
}: IProps) => (
  <div>
    <Button htmlType="button" key="cancel" onClick={handleCancel}>
      {translate('cancel')}
    </Button>
    <Button
      loading={isLoadingModal || isLoadingCourier || isLoadingTransport}
      htmlType="submit"
      key="save"
      onClick={submitClickHandler}
      type="primary"
    >
      {translate('save')}
    </Button>
  </div>
);

export default FooterModal;
