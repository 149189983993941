import './style.less';

import React, { useEffect, useState } from 'react';

import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CarOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';

import Navigation from 'modules/arm2/components/Navigation';
import Status from 'modules/common/components/Status';
import Title from 'modules/common/components/Title';
import { translate } from 'modules/localization';
import { initialRouteData } from 'modules/routes/models/initial';
import { IRoute } from 'modules/routes/models/types';
import { useStore } from 'services/store';

import RouteInfo from '../RouteInfo';
import RoutesMap from '../RoutesMap';

interface IParams {
  entityGuid: string;
}

interface IProps {
  baseUrl: string;
}

const UPDATE_PERIOD = 60000;

const RouteViewPage = ({ baseUrl }: IProps) => {
  const { routes, router, core, currency } = useStore();

  const [isLoadingSendForLoading, setIsLoadingSendForLoading] = useState(false);
  const { entityGuid } = useParams<IParams>();
  const [route, setRoute] = useState<IRoute>(initialRouteData);
  const routeUpdate = (): Promise<void> => routes.apiStore.getRoute(entityGuid).then(setRoute);

  const [period, setPeriod] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setPeriod((p) => p + 1);
    }, UPDATE_PERIOD);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (route && entityGuid && route.status !== 'canceled' && route.status !== 'complete') {
      routeUpdate();
    }
  }, [period]);

  useEffect(() => {
    routes.apiStore.getRoutesStatuses();
    currency.getCurrency();
  }, []);

  useEffect(() => {
    if (entityGuid) {
      routeUpdate();
    }
  }, [entityGuid]);

  const handleBackLink = (): void => router.push({ pathname: baseUrl });

  const setRouteStatus = (item: IRoute, status: string): void => {
    setIsLoadingSendForLoading(true);
    routes.apiStore
      .setRouteStatus(item.guid, status)
      .then(() => {
        setIsLoadingSendForLoading(false);
        routeUpdate();
      })
      .catch(() => setIsLoadingSendForLoading(false));
  };

  const status = routes.routesStatuses.find((s) => s.value === route.status);

  const isCanBeLoaded = route.status === 'planned' && core.permissions['routes.update'];
  const isCanBeCanceled =
    (route.status === 'planned' || route.status === 'at_loading') &&
    core.permissions['routes.update'];
  const isCanBeStatusChanged = isCanBeLoaded || isCanBeCanceled;

  return (
    <div className="route">
      <div className="route__main">
        <div className="route__header">
          <Navigation />
          <Button type="link" className="route__back-link" onClick={handleBackLink}>
            <ArrowLeftOutlined /> {translate('goToRouteList')}
          </Button>
          <div className="route__title">
            <Title size={Title.SIZE.H3} weight={Title.WEIGHT.SEMIBOLD}>
              {translate('route')} #{route.number}
            </Title>
            <Status status={status} isLarge />
          </div>
          {isCanBeStatusChanged && (
            <div className="route__actions-wrap">
              <div className="route__actions">
                {isCanBeLoaded && (
                  <Button
                    type="link"
                    icon={<ArrowRightOutlined />}
                    loading={isLoadingSendForLoading}
                    onClick={(): void => setRouteStatus(route, 'at_loading')}
                  >
                    <CarOutlined style={{ marginLeft: '5px' }} />
                    {translate('sendForLoading')}
                  </Button>
                )}
                {isCanBeCanceled && (
                  <Popconfirm
                    onConfirm={(): void => setRouteStatus(route, 'canceled')}
                    placement="rightTop"
                    title={translate('areYouSure')}
                  >
                    <Button className="route__cancel" type="link" icon={<CloseOutlined />}>
                      {translate('cancelTheRoute')}
                    </Button>
                  </Popconfirm>
                )}
              </div>
            </div>
          )}
        </div>
        {core.isMobile && (
          <div className="route__map">
            {!routes.isLoadingRoute && route.start_warehouse !== null && (
              <RoutesMap route={route} />
            )}
          </div>
        )}
        <RouteInfo route={route} routeUpdate={routeUpdate} />
      </div>
      {!core.isMobile && (
        <div className="route__map">
          {!routes.isLoadingRoute && route.start_warehouse !== null && <RoutesMap route={route} />}
        </div>
      )}
    </div>
  );
};

export default observer(RouteViewPage);
