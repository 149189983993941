import './style.less';

import React, { ReactNode } from 'react';

import { Collapse, CollapseProps, Tabs } from 'antd';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { observer } from 'mobx-react-lite';

import { translate } from 'modules/localization';
import PresetForm from 'modules/schedules/components/PresetForm';
import TimeslotsList from 'modules/schedules/components/TimeslotsList';
import { useStore } from 'services/store';

import AddButton from './AddButton';
import ApplyToAll from './ApplyToAll';

interface IProps {
  form: FormInstance;
}

const Week = ({ form }: IProps) => {
  const { schedules, core } = useStore();
  const isView = !core.permissions['tariffs.update'];

  const getTabLabel = (weekday): ReactNode => {
    const isTimeslots = weekday.timeslots.length > 0;
    const className = isTimeslots
      ? 'schedule-form__tab-dot schedule-form__tab-dot_active'
      : 'schedule-form__tab-dot';

    return (
      <div className="schedule-form__tab">
        <span>{translate(weekday.title)}</span>
        <div className={className} />
      </div>
    );
  };

  const renderWeekday = (weekday): ReactNode => {
    const itemsCollapse: CollapseProps['items'] = [
      {
        key: '1',
        label: translate('setUpEqualIntervals'),
        children: <PresetForm weekday={weekday} form={form} />,
      },
    ];

    return (
      <>
        {!isView && <Collapse items={itemsCollapse} className="schedules-form__preset" />}
        {!isView && <ApplyToAll weekday={weekday} />}
        <TimeslotsList weekday={weekday} />
        {!isView && <AddButton weekday={weekday} />}
      </>
    );
  };

  const items = schedules.schedulesWeek.map((weekday) => ({
    forceRender: true,
    key: weekday.id,
    label: getTabLabel(weekday),
    children: renderWeekday(weekday),
  }));

  return <Tabs className="schedule-form__tabs-wrap" items={items} />;
};

export default observer(Week);
