import './style.less';

import React, { ReactNode } from 'react';

import { TimePicker as AntTimePicker } from 'antd';
import { TimePickerProps } from 'antd/lib/time-picker';
import { Dayjs } from 'dayjs';

export interface ITimePickerProps extends TimePickerProps {
  className?: string;
  onChange?: (value: Dayjs) => void;
}

export class TimePicker extends React.Component<ITimePickerProps> {
  constructor(props: ITimePickerProps) {
    super(props);
  }

  handleChange = (newValue: Dayjs): void => {
    const { onChange } = this.props;

    if (typeof onChange === 'function') {
      onChange(newValue);
    }
  };

  render(): ReactNode {
    const { className, ...props } = this.props;

    return (
      <AntTimePicker
        {...props}
        className={className}
        needConfirm={false}
        onChange={this.handleChange}
      />
    );
  }
}
