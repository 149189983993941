import React from 'react';

import { observer } from 'mobx-react-lite';

import { ICluster } from 'modules/clusters/models/types';
import { translate } from 'modules/localization';

interface IProps {
  cluster: ICluster;
}

const ClusterInformationWarehouses = ({ cluster }: IProps) => {
  const warehouses = cluster?.cluster_item_list?.map((item) => item.warehouse);
  return (
    <div className="cluster-information-warehouses">
      <h2>
        {translate('warehouses')}&nbsp;({warehouses?.length})
      </h2>
      <div className="cluster-information-warehouses-list">
        {warehouses &&
          warehouses.length > 0 &&
          warehouses.map(({ guid, name, address }) => (
            <div className="cluster-information-warehouses-item" key={guid}>
              <div className="cluster-information-warehouses-item-title">{name}</div>
              <div className="cluster-information-warehouses-item-address">{address}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default observer(ClusterInformationWarehouses);
