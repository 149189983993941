import React from 'react';

import { Route, Switch } from 'react-router-dom';

import OrderPage from 'modules/orders/components/OrderPage';
import ListPage from 'modules/orders/components/OrdersListPage';

const Orders = () => {
  const baseUrl = '/arm2/orders';

  const renderForm = (): React.ReactNode => <OrderPage baseUrl={baseUrl} />;

  const renderList = (): React.ReactNode => <ListPage />;

  return (
    <Switch>
      <Route exact path={`${baseUrl}/:entityGuid`} render={renderForm} />
      <Route exact path={baseUrl} render={renderList} />
    </Switch>
  );
};

export default Orders;
