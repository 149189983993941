import React from 'react';

import { BakerSVG } from 'icons/BakerSVG';
import { BottleSVG } from 'icons/BottleSVG';
import { CoolSVG } from 'icons/CoolSVG';
import { TProductType } from 'modules/orders/models/types';

interface IProps {
  productTypes: TProductType;
}

export const ProductViewIcon = ({ productTypes }: IProps) => {
  return (
    <>
      {productTypes.includes('cooled') && <CoolSVG />}
      {productTypes.includes('bakery') && <BakerSVG />}
      {productTypes.includes('bottled') && <BottleSVG />}
    </>
  );
};
