import './style.less';

import React, { useEffect, useRef } from 'react';

import { Table } from 'antd';
import { observer } from 'mobx-react-lite';

import { IPagination } from 'api/types';
import { DEFAULT_PAGE_SIZES } from 'constants/index';
import { getColumns, getRowHandler } from 'modules/tasks/components/TasksList/helpers';
import { useStore } from 'services/store';

const TasksList = () => {
  const { tasks, deliveryMethods, routes, core } = useStore();

  useEffect(() => {
    tasks.apiStore.getTasks();

    return () => {
      tasks.setFilter('current', 1);
    };
  }, []);

  const updateList = ({ current, pageSize }: IPagination): void => {
    tasks.apiStore.getTasks({ ...tasks.filter, current, pageSize });
  };

  const columns = getColumns(
    tasks.tasksStatuses,
    routes.routesStatuses,
    deliveryMethods.list,
    core.permissions,
  );

  const listRef = useRef(null);
  const scrollIntoView = () => listRef.current.nativeElement.scrollIntoView({ behavior: 'smooth' });

  return (
    <Table
      ref={listRef}
      className="dir__list"
      dataSource={tasks.tasksList}
      columns={columns}
      bordered={false}
      pagination={{
        ...tasks.filter,
        simple: core.isMobile,
        defaultPageSize: DEFAULT_PAGE_SIZES[1],
        showSizeChanger: true,
        pageSizeOptions: DEFAULT_PAGE_SIZES,
        onChange: scrollIntoView,
      }}
      onChange={updateList}
      rowKey="guid"
      loading={tasks.isLoadingTasksList}
      onRow={getRowHandler()}
    />
  );
};

export default observer(TasksList);
