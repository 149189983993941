import React from 'react';

export const CoolSVG = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 11.2113V12.7887H16.6573L19.2488 15.3803L18.1221 16.5446L14.4038 12.7887H12.7887V14.4038L16.5446 18.1221L15.3803 19.2488L12.7887 16.6573V20H11.2113V16.6573L8.61972 19.2488L7.4554 18.1221L11.2113 14.4038V12.7887H9.59624L5.87793 16.5446L4.75117 15.3803L7.34272 12.7887H4V11.2113H7.34272L4.75117 8.61972L5.87793 7.4554L9.59624 11.2113H11.2113V9.59624L7.4554 5.87793L8.61972 4.75117L11.2113 7.34272V4H12.7887V7.34272L15.3803 4.75117L16.5446 5.87793L12.7887 9.59624V11.2113H14.4038L18.1221 7.4554L19.2488 8.61972L16.6573 11.2113H20Z"
      fill="#4F65D1"
    />
  </svg>
);
