import React from 'react';

import { WarningFilled, InfoCircleTwoTone } from '@ant-design/icons';

type IFlagProps = {
  withDescription?: boolean;
};

export const RedisFlag = ({ withDescription }: IFlagProps) => (
  <>
    <InfoCircleTwoTone className="notification-icon notification-icon__info" />
    {withDescription && 'Флаг наличия записи о сущности в REDISе'}
  </>
);

export const DiffFlag = ({ withDescription }: IFlagProps) => (
  <>
    <WarningFilled className="notification-icon notification-icon__warning" />
    {withDescription && 'Есть разница данных из БД и локальным хранилищем'}
  </>
);
