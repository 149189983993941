import './style.less';

import React, { useEffect, useState } from 'react';

import {
  CloseOutlined,
  EditOutlined,
  MinusSquareFilled,
  PlusSquareFilled,
} from '@ant-design/icons';
import { Button, Collapse, CollapseProps, Popconfirm } from 'antd';
import { observer } from 'mobx-react-lite';

import { stopPropagation } from 'helpers/stopPropagation';
import { ICoverage } from 'modules/coverages/models/types';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

interface IActionProps {
  item: ICoverage;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setListOpened: any;
}

const Actions = ({ item, setListOpened }: IActionProps) => {
  const { coverage } = useStore();

  useEffect(() => {
    return () => {
      coverage.setCoveragesList([]);
      coverage.setCoveragesListView([]);
    };
  }, []);
  const handleEditCoverage = () => {
    setListOpened(false);
    coverage.setCoveragesFormVisible(true);
    coverage.setCoverageEditableGuid(item.guid);
    coverage.apiStore.getCoverages(false);
  };

  const handleRemoveCoverage = () => {
    coverage.removeCoverageFromLocalList(item.guid);
    coverage.apiStore.deleteCoverage(item.guid);
  };

  return (
    !coverage.isCoveragesFormVisible && (
      <div className="coverages-local__item-actions">
        <span onClick={stopPropagation}>
          <a href="#" onClick={handleEditCoverage}>
            <EditOutlined />
          </a>
        </span>
        <span onClick={stopPropagation}>
          <Popconfirm
            cancelText={translate('cancel')}
            onCancel={stopPropagation}
            onConfirm={handleRemoveCoverage}
            placement="rightTop"
            title={translate('coverageDeleteFromListConfirm')}
          >
            <a href="#">
              <CloseOutlined />
            </a>
          </Popconfirm>
        </span>
      </div>
    )
  );
};

const CoveragesLocalList = () => {
  const { coverage } = useStore();
  useEffect(() => {
    return () => {
      coverage.setCoveragesList([]);
      coverage.setCoveragesListView([]);
    };
  }, []);

  const [isListOpened, setListOpened] = useState(false);
  const coveragesCount = coverage.coveragesListLocal.length;
  const handleCreateCoverage = () => {
    setListOpened(false);
    coverage.setCoveragesFormVisible(true);
    coverage.apiStore.getCoverages(false);
  };

  const itemsCollapse: CollapseProps['items'] = [
    {
      key: '1',
      label: `${coveragesCount} ${translate('coverage', {
        count: coveragesCount,
      })} ${translate('saved')}`,
      children: coverage.coveragesListLocal.map((c) => (
        <div className="coverages-local__item" key={c.guid}>
          <div>{c.name}</div>
          <Actions item={c} setListOpened={setListOpened} />
        </div>
      )),
    },
  ];

  return (
    coveragesCount > 0 && (
      <div className="coverages-local">
        <Collapse
          activeKey={isListOpened ? '1' : null}
          className="coverages-local__collapse"
          expandIcon={({ isActive }) => (isActive ? <MinusSquareFilled /> : <PlusSquareFilled />)}
          onChange={(keys) => setListOpened(keys.length > 0)}
          items={itemsCollapse}
        />

        {!coverage.isCoveragesFormVisible && (
          <Button className="coverages-local__add" size="small" onClick={handleCreateCoverage}>
            + {translate('oneMoreCoverage')}
          </Button>
        )}
      </div>
    )
  );
};

export default observer(CoveragesLocalList);
