import React, { useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import querystring from 'query-string';

import { IFilter } from 'modules/arm2/models/types';
import WarehousesSelect from 'modules/warehouses/components/WarehousesSelect';
import { useStore } from 'services/store';

interface IProps {
  setFilter?: (key: string, value: string[] | string) => void;
  getList?: (filter?) => void;
  value: string[] | string;
  className?: string;
  getFilterFromLocalStorage?: () => IFilter;
  defaultValue?: string[] | string;
  disabled?: boolean;
}

const WarehousesFilter = ({
  setFilter,
  getList,
  value,
  className,
  getFilterFromLocalStorage,
  defaultValue,
  disabled,
}: IProps) => {
  const { core } = useStore();

  const userAvailableWarehouses = core?.accountData?.warehouses;
  const userDefaultWarehouse =
    userAvailableWarehouses && userAvailableWarehouses.length > 0
      ? userAvailableWarehouses[0].guid
      : '3b8fd273-915c-11e2-91a6-001b24936c9e'; // Склад Томск
  const defaultWarehouse = defaultValue ? defaultValue : userDefaultWarehouse;

  useEffect(() => {
    const localStorageFilter = getFilterFromLocalStorage && getFilterFromLocalStorage();
    const localStorageWarehouses = localStorageFilter && localStorageFilter.warehouses;
    const selectedWarehouses =
      querystring.parse(window.location.search).warehouse || localStorageWarehouses;

    if (selectedWarehouses && typeof selectedWarehouses === 'string') {
      setFilter && setFilter('warehouses', [selectedWarehouses]);
    } else if (
      selectedWarehouses &&
      Array.isArray(selectedWarehouses) &&
      selectedWarehouses.length > 0
    ) {
      setFilter && setFilter('warehouses', selectedWarehouses);
    } else {
      setFilter &&
        setFilter(
          'warehouses',
          Array.isArray(defaultWarehouse) ? defaultWarehouse : [defaultWarehouse],
        );
    }
  }, []);

  const handleFilter = (val): void => {
    const newValue =
      val.length > 0
        ? val
        : Array.isArray(defaultWarehouse)
        ? defaultWarehouse
        : [defaultWarehouse];
    setFilter && setFilter('warehouses', newValue);
    getList && getList();
  };

  return (
    <div className={className}>
      <WarehousesSelect
        value={value}
        onChange={handleFilter}
        maxTagCount={2}
        popupMatchSelectWidth={false}
        disabled={disabled}
      />
    </div>
  );
};

export default observer(WarehousesFilter);
