import './style.less';

import React, { ReactNode } from 'react';

import { Button, Form, Modal, Select } from 'antd';
import { FormInstance } from 'antd/lib/form/hooks/useForm';

import { DEFAULT_SELECT_PROPS } from 'modules/common/components/Select';
import { translate } from 'modules/localization';
import { ITasksResolution } from 'modules/tasks/models/types';

interface IProps {
  isModalOpened: boolean;
  tasksResolutions?: ITasksResolution[];
  onSubmit: (values, form: FormInstance) => void;
  onCancel: (form: FormInstance) => void;
}

const TaskCancelForm = ({ isModalOpened, tasksResolutions, onSubmit, onCancel }: IProps) => {
  const [form] = Form.useForm();

  const submitClickHandler = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    form.submit();
  };

  const renderFooter = (): ReactNode => {
    return (
      <div className="task-cancel-form__actions">
        <Button htmlType="button" key="cancel" onClick={() => onCancel(form)}>
          {translate('changedMyMind')}
        </Button>
        <Button htmlType="submit" key="save" onClick={submitClickHandler} type="primary">
          {translate('cancelTheTask')}
        </Button>
      </div>
    );
  };

  return (
    <Modal
      title={translate('taskCancelation')}
      open={isModalOpened}
      footer={renderFooter()}
      onCancel={() => onCancel(form)}
      className="task-cancel-modal"
    >
      <Form name="basic" form={form} onFinish={(values) => onSubmit(values, form)}>
        <Form.Item
          label={translate('taskCancelationReason')}
          name="resolution"
          rules={[{ required: true, message: translate('enterTaskCancelationReason') }]}
        >
          <Select
            placeholder={translate('enterTaskCancelationReason')}
            style={{ minWidth: 200 }}
            popupMatchSelectWidth={false}
            getPopupContainer={(el) => el}
            {...DEFAULT_SELECT_PROPS}
          >
            {Array.isArray(tasksResolutions) &&
              tasksResolutions.map((res) => {
                return (
                  <Select.Option key={res.value} value={res.value}>
                    {res.title}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TaskCancelForm;
