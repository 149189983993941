import React, { ReactNode } from 'react';

import { Route, Switch } from 'react-router-dom';

import Form from 'modules/reasonsDelay/components/Form';
import Header from 'modules/reasonsDelay/components/Header';
import List from 'modules/reasonsDelay/components/List';

const ReasonsDelay = () => {
  const baseUrl = '/directories/reasons-delay';

  const renderLayout = (): ReactNode => (
    <>
      <Header baseUrl={baseUrl} />
      <List baseUrl={baseUrl} />
      <Form baseUrl={baseUrl} />
    </>
  );

  return (
    <Switch>
      <Route exact path={`${baseUrl}/:entityGuid/:action`} render={renderLayout} />
      <Route exact path={`${baseUrl}/:action`} render={renderLayout} />
      <Route exact path={baseUrl} render={renderLayout} />
    </Switch>
  );
};

export default ReasonsDelay;
