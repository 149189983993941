import Api from 'api/Api';
import { IPagination } from 'api/types';
import {
  IShopForm,
  IShopsCreateResponse,
  IShopsDeleteResponse,
  IShopsEditResponse,
  IShopsGetResponse,
  IShopsListGetResponse,
} from 'modules/shops/models/types';

export default class ShopsApi {
  static getShop(guid: string): Promise<IShopsGetResponse> {
    return Api.get(`/api/v1/shops/${guid}`);
  }

  static getShopsList(
    pagination: IPagination,
    signal?: AbortSignal,
  ): Promise<IShopsListGetResponse> {
    const query = {
      page: pagination.current,
      page_size: pagination.pageSize,
    };
    return Api.get('/api/v1/shops', query, signal);
  }

  static createShop(data: IShopForm): Promise<IShopsCreateResponse> {
    return Api.post(data, '/api/v1/shops');
  }

  static editShop(shopsGuid: string, data: IShopForm): Promise<IShopsEditResponse> {
    return Api.patch(data, `/api/v1/shops/${shopsGuid}`);
  }

  static deleteShop(guid: string): Promise<IShopsDeleteResponse> {
    return Api.delete(`/api/v1/shops/${guid}`);
  }

  static searchShopsList(
    q: string,
    pagination: IPagination,
    signal?: AbortSignal,
  ): Promise<IShopsListGetResponse> {
    const query = {
      page: pagination.current,
      page_size: pagination.pageSize,
      q,
    };
    return Api.get('/api/v1/search/shops', query, signal);
  }
}
