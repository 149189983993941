import './style.less';

import React, { useEffect, useState } from 'react';

import { EnvironmentOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import querystring from 'query-string';

import DateFilter from 'modules/arm2/components/DateFilter';
import DeliveryMethodFilter from 'modules/arm2/components/DeliveryMethodFilter';
import ProblemFilter from 'modules/arm2/components/ProblemFilter';
import ShopFilter from 'modules/arm2/components/ShopFilter';
import StatusFilter from 'modules/arm2/components/StatusFilter';
import TaskTypeFilter from 'modules/arm2/components/TaskTypeFilter';
import TimeFilter from 'modules/arm2/components/TimeFilter';
import WarehouseFilter from 'modules/arm2/components/WarehouseFilter';
import { FilterIcon } from 'modules/common/components/Icons';
import Title from 'modules/common/components/Title';
import { translate } from 'modules/localization';
import { getTasksFilter } from 'modules/tasks/helpers/localStorage';
import { useStore } from 'services/store';

import TasksSearch from '../TasksSearch';

const TasksHeader = () => {
  const { tasks, core } = useStore();

  const { filter, setFilter, tasksStatuses, setFilterToUrl, tasksTypes } = tasks;
  useEffect(() => {
    const queryMap = querystring.parse(window.location.search).map_show;

    queryMap && typeof queryMap === 'string' && setFilter('mapShow', queryMap === 'true');
  }, []);

  const [viewFilter, setViewFilter] = useState(!core.isMobile);

  const handleClickMapShow = (): void => {
    setFilter('mapShow', !filter.mapShow);
    setFilterToUrl();
  };

  return (
    <div className="tasks-header">
      <Title size={Title.SIZE.H3} weight={Title.WEIGHT.SEMIBOLD} className="task-title">
        {translate('tasksList')}
      </Title>
      {core.isMobile ? (
        <div className="task-filter--mobile">
          <TasksSearch />
          <Button
            type="link"
            icon={<FilterIcon />}
            onClick={() => setViewFilter(!viewFilter)}
            className={`routes-generator-source_filter-button ${
              viewFilter ? 'routes-generator-source_filter-button__active' : ''
            }`}
          />
        </div>
      ) : null}
      {viewFilter ? (
        <div className="task-filter">
          {!core.isMobile && <TasksSearch />}
          {!core.isMobile && (
            <Button
              className="task-filter__map"
              onClick={handleClickMapShow}
              type={filter.mapShow ? 'primary' : 'default'}
            >
              <EnvironmentOutlined className="task-filter__map-icon" />
            </Button>
          )}
          <ProblemFilter
            className="task-filter__problem"
            getList={tasks.apiStore.getTasks}
            setFilter={setFilter}
            value={filter.isProblem}
            getFilterFromLocalStorage={getTasksFilter}
          />
          <DateFilter
            className="task-filter__date"
            getList={tasks.apiStore.getTasks}
            setFilter={setFilter}
            value={filter.deliveryDate}
            getFilterFromLocalStorage={getTasksFilter}
          />
          <TimeFilter
            className="task-filter__time"
            getList={tasks.apiStore.getTasks}
            setFilter={setFilter}
            value={{ from: filter.from, to: filter.to }}
            getFilterFromLocalStorage={getTasksFilter}
          />
          <StatusFilter
            className="task-filter__status"
            optionsList={tasksStatuses}
            getList={tasks.apiStore.getTasks}
            setFilter={setFilter}
            value={filter.status}
            getFilterFromLocalStorage={getTasksFilter}
          />
          <WarehouseFilter
            className="task-filter__warehouse"
            getList={tasks.apiStore.getTasks}
            setFilter={setFilter}
            value={filter.warehouses}
            getFilterFromLocalStorage={getTasksFilter}
          />
          <ShopFilter
            className="task-filter__shop"
            getList={tasks.apiStore.getTasks}
            setFilter={setFilter}
            value={filter.shop}
            getFilterFromLocalStorage={getTasksFilter}
          />
          <DeliveryMethodFilter
            className="task-filter__delivery"
            getList={tasks.apiStore.getTasks}
            setFilter={setFilter}
            value={filter.deliveryMethod}
            getFilterFromLocalStorage={getTasksFilter}
          />
          <TaskTypeFilter
            className="task-filter__type"
            optionsList={tasksTypes}
            getList={tasks.apiStore.getTasks}
            setFilter={setFilter}
            value={filter.type}
            getFilterFromLocalStorage={getTasksFilter}
          />
        </div>
      ) : null}
    </div>
  );
};

export default observer(TasksHeader);
