import React, { ReactNode, useEffect } from 'react';

import { ArrowRightOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import querystring from 'query-string';

import { selectFilter } from 'helpers/form';
import { IFilter } from 'modules/arm2/models/types';
import { DEFAULT_SELECT_PROPS } from 'modules/common/components/Select';
import { translate } from 'modules/localization';
import { IOrderType } from 'modules/orders/models/types';

interface IProps {
  setFilter: (key: string, value: string[] | string) => void;
  getList: () => void;
  value: string[];
  className?: string;
  getFilterFromLocalStorage?: () => IFilter;
  optionsList: IOrderType[];
}

const OrderTypeFilter = ({
  setFilter,
  getList,
  value,
  className,
  getFilterFromLocalStorage,
  optionsList,
}: IProps) => {
  useEffect(() => {
    const localStorageFilter = getFilterFromLocalStorage && getFilterFromLocalStorage();
    const localStorageType = localStorageFilter && localStorageFilter.type;
    const selectedTypes = querystring.parse(window.location.search).type || localStorageType;

    if (selectedTypes && typeof selectedTypes === 'string') {
      setFilter('type', [selectedTypes]);
    }
    if (selectedTypes && typeof selectedTypes === 'object') {
      setFilter('type', selectedTypes);
    }
  }, []);

  const handleFilter = (val): void => {
    setFilter('type', val);
    getList();
  };

  const renderSelectOption = (option: IOrderType): ReactNode => {
    const renderTitle = (option) => {
      switch (option.value) {
        case 'warehouse_to_customer':
          return (
            <>
              {translate('warehouse')} <ArrowRightOutlined /> {translate('client')}
            </>
          );
        case 'customer_to_warehouse':
          return (
            <>
              {translate('client')} <ArrowRightOutlined /> {translate('warehouse')}
            </>
          );
        case 'customer_to_customer':
          return (
            <>
              {translate('client')} <ArrowRightOutlined /> {translate('client')}
            </>
          );
        case 'warehouse_to_warehouse':
          return (
            <>
              {translate('warehouse')} <ArrowRightOutlined /> {translate('warehouse')}
            </>
          );
        default:
          return option ? option.title : '—';
      }
    };
    if (option) {
      return (
        <Select.Option key={option.value} value={option.value}>
          {renderTitle(option)}
        </Select.Option>
      );
    }

    return null;
  };

  return (
    <div className={className}>
      <Select
        allowClear
        mode="multiple"
        className="order-type-filter"
        filterOption={selectFilter}
        onChange={handleFilter}
        placeholder={translate('type')}
        showSearch
        value={value}
        popupMatchSelectWidth={false}
        getPopupContainer={(el) => el}
        {...DEFAULT_SELECT_PROPS}
      >
        {optionsList.map(renderSelectOption)}
      </Select>
    </div>
  );
};

export default OrderTypeFilter;
