import React, { useEffect } from 'react';

import { CloudDownloadOutlined, ExpandOutlined } from '@ant-design/icons';
import { Card, List, Descriptions } from 'antd';
import { observer } from 'mobx-react-lite';

import DelorianState from 'modules/qa/common/DelorianState';
import Diff from 'modules/qa/common/Diff';
import { DiffFlag } from 'modules/qa/common/flags';
import { ICourier } from 'modules/qa/models/types';
import { getFullName } from 'modules/user/models/helpers';
import { useStore } from 'services/store';

type IPropsSidebar = {
  profile: ICourier;
};

const Sidebar = ({ profile }: IPropsSidebar) => {
  return (
    <>
      <Descriptions bordered title="Курьер" size="small" column={1}>
        <Descriptions.Item label="Пользователь">{profile.guid}</Descriptions.Item>
        <Descriptions.Item label="Курьер">{profile.courier_guid}</Descriptions.Item>
        {profile?.surname && (
          <Descriptions.Item label="ФИО">{getFullName(profile)}</Descriptions.Item>
        )}
        <Descriptions.Item label="Смена">{profile.courier_session_guid}</Descriptions.Item>
        <Descriptions.Item label="Покрытия">
          {profile.coverage_guid_list &&
            profile.coverage_guid_list.length &&
            profile.coverage_guid_list.map((c) => <p key={c}>{c}</p>)}
        </Descriptions.Item>
        <Descriptions.Item label="Время окончания последнего маршрута">
          {profile.last_route_end}
        </Descriptions.Item>
        <Descriptions.Item label="Блокирующий процесс">{profile.locked}</Descriptions.Item>
        <Descriptions.Item label="Координаты">
          {profile.position.lat}, {profile.position.lon}
        </Descriptions.Item>
        <Descriptions.Item label="Маршрут">{profile.route_guid}</Descriptions.Item>
        <Descriptions.Item label="Сиеста">{profile.siesta.toString()}</Descriptions.Item>
        <Descriptions.Item label="Транспорт">{profile.transport_guid}</Descriptions.Item>
        <Descriptions.Item label="Склад">
          {profile.warehouse_guid_list &&
            Array.isArray(profile.warehouse_guid_list) &&
            profile.warehouse_guid_list.map((w) => <p key={w}>{w}</p>)}
        </Descriptions.Item>
      </Descriptions>
      <DelorianState entity={profile} />
      <Diff entity={profile} />

      <pre>{JSON.stringify(profile, null, 2)}</pre>
    </>
  );
};

export const CourierSidebar = Sidebar;

const CourierList = () => {
  const { fastmineMonitor } = useStore();

  useEffect(() => {
    fastmineMonitor.getCouriers({ pageSize: 100, current: 1 });
  }, []);

  return (
    <List
      grid={{ column: 1 }}
      header={<b>Курьеры</b>}
      dataSource={fastmineMonitor.couriersList}
      renderItem={(profile) => {
        const openProfile = () => {
          fastmineMonitor.setSidebarType('courier');
          fastmineMonitor.setSidebarData(profile);
        };
        return (
          <List.Item>
            <Card
              title={profile.difference && <DiffFlag />}
              extra={
                <>
                  {!profile.surname && (
                    <a
                      href="#"
                      rel="noreferrer"
                      onClick={() => fastmineMonitor.loadCourier(profile.guid)}
                    >
                      <CloudDownloadOutlined />
                      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </a>
                  )}
                  <a href="#" rel="noreferrer" onClick={openProfile}>
                    <ExpandOutlined />
                  </a>
                </>
              }
            >
              {profile.guid}
              {profile?.surname && <p>{getFullName(profile)}</p>}
            </Card>
          </List.Item>
        );
      }}
    />
  );
};

export default observer(CourierList);
