import { IPagination } from 'api/types';
import { DEFAULT_PAGINATION } from 'constants/index';
import { IFilter } from 'modules/arm2/models/types';

import { ICourier } from './types';

export const initialFilter: IFilter = {
  ...DEFAULT_PAGINATION,
  status: [],
  warehouses: [],
};

export const initialPagination: IPagination = DEFAULT_PAGINATION;

export const initialCourierData: ICourier = {
  guid: null,
  online: false,
  processed_session: null,
  planned_session: null,
  profile: null,
  profile_guid: null,
  transports: [],
};
