import React from 'react';

import { ITask } from 'modules/tasks/models/types';

interface IProps {
  task: ITask;
}

const TaskError = ({ task }: IProps) => {
  return task.error ? <div className="rg-task__error">{task.error}</div> : null;
};

export default TaskError;
