import './style.less';

import React, { useEffect } from 'react';

import { observer } from 'mobx-react-lite';

import Navigation from 'modules/arm2/components/Navigation';
import { useStore } from 'services/store';

import TasksHeader from '../TasksHeader';
import TasksList from '../TasksList';
import TasksMap from '../TasksMap';

const TasksPage = () => {
  const { tasks, routes } = useStore();

  useEffect(() => {
    tasks.apiStore.getTasksStatuses();
    tasks.apiStore.getTasksProblems();
    tasks.apiStore.getTasksTypes();
    routes.apiStore.getRoutesStatuses();
  }, []);

  const { mapShow } = tasks.filter;

  return (
    <div className="tasks">
      <div className={`tasks__content ${mapShow ? 'tasks__content--map' : ''}`}>
        <Navigation />
        <TasksHeader />
        <div className="tasks__list">
          <TasksList />
        </div>
      </div>
      {mapShow && (
        <div className="tasks__map">
          <TasksMap />
        </div>
      )}
    </div>
  );
};

export default observer(TasksPage);
