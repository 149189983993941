import './style.less';

import React from 'react';

import { Feature, Polygon, MultiPolygon } from 'geojson';

import { IClusterItem } from 'modules/clusters/models/types';
import Map from 'modules/map/containers/Map';
import WarehouseMarker from 'modules/warehouse/containers/WarehouseMarker';

import ClusterPolygon from './ClusterPolygon';

interface IProps {
  polygon: Feature<MultiPolygon | Polygon>;
  warehouses: IClusterItem['warehouse'][];
  guid: string;
}

const ClusterMap = ({ polygon, warehouses, guid }: IProps) => {
  const warehousesGeoJSON = warehouses
    .filter((item) => Boolean(item.point && item.point.lat && item.point.lon))
    .map((w) => {
      if (w?.point?.lat && w?.point?.lon) {
        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [w.point.lon, w.point.lat],
          },
          properties: {},
        };
      }
    });
  const mapView = [polygon, ...warehousesGeoJSON].filter(
    (view) => view !== null && view !== undefined,
  );
  return (
    <div className="cluster-map">
      <Map mapView={mapView} isSearch={false} entityID={guid}>
        {polygon !== null && <ClusterPolygon polygon={polygon} />}
        {warehouses.map(
          (w) =>
            w?.point?.lat &&
            w?.point?.lon && (
              <WarehouseMarker
                key={w.guid}
                position={[w.point.lat, w.point.lon]}
                name={w.name}
                address={w.address}
              />
            ),
        )}
      </Map>
    </div>
  );
};

export default ClusterMap;
