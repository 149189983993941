import './style.less';

import React, { useEffect, useRef } from 'react';

import { Table } from 'antd';
import { observer } from 'mobx-react-lite';

import { IPagination } from 'api/types';
import { DEFAULT_PAGE_SIZES } from 'constants/index';
import { getColumns, getRowHandler } from 'modules/couriers/components/arm/CouriersList/helpers';
import { useStore } from 'services/store';

const CouriersList = () => {
  const { couriers, core } = useStore();

  useEffect(() => {
    couriers.apiStore.getCouriers();

    return () => {
      couriers.setFilter('current', 1);
    };
  }, []);

  const updateList = ({ current, pageSize }: IPagination): void => {
    couriers.apiStore.getCouriers({ ...couriers.filter, current, pageSize });
  };

  const columns = getColumns(couriers.sessionStatuses);

  const listRef = useRef(null);
  const scrollIntoView = () => listRef.current.nativeElement.scrollIntoView({ behavior: 'smooth' });

  return (
    <Table
      ref={listRef}
      className="dir__list"
      dataSource={couriers.couriersList}
      columns={columns}
      bordered={false}
      pagination={{
        ...couriers.filter,
        simple: core.isMobile,
        showSizeChanger: true,
        pageSizeOptions: DEFAULT_PAGE_SIZES,
        onChange: scrollIntoView,
      }}
      rowKey="guid"
      loading={couriers.isLoadingCouriersList}
      onChange={updateList}
      onRow={getRowHandler()}
    />
  );
};

export default observer(CouriersList);
