import React from 'react';

import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import { translate } from 'modules/localization';
import { ITask } from 'modules/tasks/models/types';
import { useStore } from 'services/store';

interface IProps {
  task: ITask;
  onCancel: () => Promise<void>;
}

const TaskCompleter = ({ task, onCancel }: IProps) => {
  const { tasks } = useStore();

  const completeTask = (): void => {
    tasks.apiStore.completeTask(task.guid, 'completed_by_operator').then(onCancel);
  };

  const isTaskCanBeCompleted = task.status === 'not_complete';

  return isTaskCanBeCompleted ? (
    <Link className="order-task__complete" to="#" onClick={completeTask}>
      {translate('finishTheTask')}
    </Link>
  ) : null;
};

export default observer(TaskCompleter);
