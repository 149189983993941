import React, { useEffect } from 'react';

import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import querystring from 'query-string';

import { dateFormat } from 'helpers/string';
import { IFilter } from 'modules/arm2/models/types';

interface IProps {
  setFilter?: (key: string, value: string[] | boolean | number | string) => void;
  getList?: (filter?) => void;
  value: string;
  className?: string;
  getFilterFromLocalStorage?: () => IFilter;
  defaultValue?: string;
  disabled?: boolean;
}

export const DEFAULT_DATE = dayjs().format(dateFormat.search);

const DateFilter = ({
  setFilter,
  getList,
  value,
  className,
  getFilterFromLocalStorage,
  defaultValue,
  disabled,
}: IProps) => {
  useEffect(() => {
    const localStorageFilter = getFilterFromLocalStorage && getFilterFromLocalStorage();
    const localStorageDate = localStorageFilter && localStorageFilter.deliveryDate;
    const deliveryDate = querystring.parse(window.location.search).date || localStorageDate;

    if (deliveryDate && typeof deliveryDate === 'string') {
      setFilter && setFilter('deliveryDate', deliveryDate);
    }
    if (defaultValue) {
      setFilter && setFilter('deliveryDate', defaultValue);
    }
  }, []);

  const handleDateChange = (date): void => {
    const deliveryDate = date ? date.format(dateFormat.search) : DEFAULT_DATE;
    setFilter && setFilter('deliveryDate', deliveryDate);
    getList && getList();
  };

  const currentDatedayjs = dayjs();

  return (
    <div className={className}>
      <DatePicker
        defaultPickerValue={dayjs(currentDatedayjs.format(dateFormat.search))}
        format={dateFormat.string}
        onChange={handleDateChange}
        value={value ? dayjs(value, dateFormat.search) : null}
        disabled={disabled}
        needConfirm={false}
        getPopupContainer={(el) => el}
      />
    </div>
  );
};

export default DateFilter;
