import './style.less';

import React, { ReactNode, useEffect, useState } from 'react';

import { WarningFilled } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, InputNumber, Modal, Radio, Select } from 'antd';
import { RadioChangeEvent } from 'antd/es/radio/interface';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { Dayjs } from 'dayjs';

import { timeFormat } from 'helpers/string';
import { DEFAULT_SELECT_PROPS } from 'modules/common/components/Select';
import { TimePicker } from 'modules/common/containers/TimePicker';
import { translate } from 'modules/localization';
import { IOrder } from 'modules/orders/models/types';

export interface INewTaskForm {
  delivery_date: Dayjs;
  height: number;
  length: number;
  reason: string;
  time_slot_end: Dayjs;
  time_slot_start: Dayjs;
  volume: number;
  weight: number;
  width: number;
}

interface IProps {
  isModalOpened: boolean;
  loading: boolean;
  onSubmit: (values: INewTaskForm, form: FormInstance) => void;
  onCancel: (form: FormInstance) => void;
  order?: IOrder;
  initialValues?: {
    task_destination: string;
    delivery_date: Dayjs;
    time_slot_start: Dayjs;
    time_slot_end: Dayjs;
  };
}

const NewTaskWarning = () => {
  return (
    <div className="new-task__warning">
      <WarningFilled className="new-task__warning-icon" />
      <b>{translate('extraTaskReasons')}</b>
      <ul>
        <li>{translate('extraTaskReason1')}</li>
        <li>{translate('extraTaskReason2')}</li>
        <li>{translate('extraTaskReason3')}</li>
      </ul>
      {translate('extraTaskWarning')}
      <p className="new-task__warning-action">{translate('extraTaskAction')}</p>
    </div>
  );
};

const NewTaskForm = ({
  isModalOpened,
  onSubmit,
  onCancel,
  order,
  loading,
  initialValues,
}: IProps) => {
  const [form] = Form.useForm();

  const setDefaultValueIfWarning = (nameField: string) => {
    if (form.getFieldsError([nameField])[0].errors.length > 0) {
      form.setFieldValue(nameField, 0.1);
      form.validateFields([nameField]);
    }
  };

  const getDestinationAddress = (destination: string) => {
    if (!destination) {
      return (
        <p className="new-task__address new-task__address_undefined">
          {translate('addressIsNotDefined')}
        </p>
      );
    }

    return (
      <p className="new-task__address">
        {destination === 'sender' ? order.sender_address : order.address}
      </p>
    );
  };

  const submitClickHandler = (e: React.MouseEvent): void => {
    e.preventDefault();
    form.submit();
  };

  const renderFooter = (): ReactNode => {
    return (
      <div className="new-task__actions">
        <Button htmlType="button" key="cancel" onClick={() => onCancel(form)}>
          {translate('cancel')}
        </Button>
        <Button
          loading={loading}
          htmlType="submit"
          key="save"
          onClick={submitClickHandler}
          type="primary"
          disabled={!isFormVisible}
        >
          {translate('addTask')}
        </Button>
      </div>
    );
  };

  const isInitialValuesExist = Boolean(
    initialValues &&
      initialValues.delivery_date &&
      initialValues.task_destination &&
      initialValues.time_slot_start &&
      initialValues.time_slot_end,
  );

  const [isFormVisible, toggleFormVisible] = useState(false);
  const [isWarningVisible, toggleWarningVisible] = useState(false);
  const [preRequestValue, setPreRequestValue] = useState(null);

  useEffect(() => {
    toggleWarningVisible(!isInitialValuesExist ? false : null);
    toggleFormVisible(isInitialValuesExist);
    setPreRequestValue(isInitialValuesExist ? true : null);
  }, [isModalOpened]);

  const onChangePreRequest = (e: RadioChangeEvent) => {
    setPreRequestValue(e.target.value);
    toggleFormVisible(e.target.value);
    toggleWarningVisible(!e.target.value);
  };

  return (
    <Modal
      title={translate('extraTask')}
      open={isModalOpened}
      onCancel={() => onCancel(form)}
      footer={renderFooter()}
      className="new-task__modal"
      styles={{ body: { overflowY: 'auto', maxHeight: 'calc(100vh - 250px)' } }}
    >
      <div
        className={`new-task__group new-task__pre-request ${
          isFormVisible && 'new-task__pre-request--inline'
        }`}
      >
        <Form.Item
          label={translate('extraTaskPreRequest')}
          rules={[{ required: true, message: translate('requiredValidator') }]}
          className="new-task__pre-request-field"
        >
          <Radio.Group onChange={onChangePreRequest} value={preRequestValue}>
            <Radio value>{translate('yes')}</Radio>
            <Radio value={false}>{translate('no')}</Radio>
          </Radio.Group>
        </Form.Item>
        {isWarningVisible ? <NewTaskWarning /> : null}
      </div>

      <Form
        name="basic"
        form={form}
        onFinish={(values) => onSubmit(values, form)}
        layout="vertical"
        className={`new-task ${isFormVisible && 'new-task--visible'}`}
        initialValues={initialValues}
      >
        <div className="new-task__group">
          <Form.Item
            label={translate('comment')}
            name="reason"
            rules={[{ required: true, message: translate('requiredValidator') }]}
            className="new-task__reason-field"
          >
            <Input.TextArea autoSize />
          </Form.Item>

          <Form.Item
            label={translate('destination')}
            name="task_destination"
            rules={[{ required: true, message: translate('enterDestination') }]}
            className="new-task__destination-field"
          >
            <Select
              placeholder={translate('enterDestination')}
              style={{ minWidth: 200 }}
              popupMatchSelectWidth={false}
              getPopupContainer={(el) => el}
              {...DEFAULT_SELECT_PROPS}
            >
              <Select.Option key="sender" value="sender">
                {translate('sender')}
              </Select.Option>
              <Select.Option key="recipient" value="recipient">
                {translate('recipient')}
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item shouldUpdate className="new-task__address-field">
            {() => {
              return getDestinationAddress(form.getFieldValue('task_destination'));
            }}
          </Form.Item>
        </div>

        <div className="new-task__group">
          <p className="new-task__group-title">{translate('plannedDateTime')}</p>

          <Form.Item
            label={translate('deliveryDate')}
            name="delivery_date"
            rules={[{ required: true, message: translate('enterDate') }]}
            className="new-task__date"
          >
            <DatePicker placeholder={translate('enterDate')} getPopupContainer={(el) => el} />
          </Form.Item>

          <div className="new-task__stroke">
            <Form.Item
              label={translate('timeSlotStart')}
              name="time_slot_start"
              rules={[{ required: true, message: translate('enterTime') }]}
              className="new-task__time-slot"
            >
              <TimePicker
                minuteStep={15}
                format={timeFormat.simple}
                allowClear
                placeholder={translate('enterTime')}
                getPopupContainer={(el) => el}
              />
            </Form.Item>
            <Form.Item
              label={translate('timeSlotEnd')}
              name="time_slot_end"
              rules={[{ required: true, message: translate('enterTime') }]}
              className="new-task__time-slot"
            >
              <TimePicker
                minuteStep={15}
                format={timeFormat.simple}
                placeholder={translate('enterTime')}
                getPopupContainer={(el) => el}
              />
            </Form.Item>
          </div>
        </div>

        <div className="new-task__group">
          <p className="new-task__group-title">{translate('sizes')}</p>

          <div className="new-task__stroke">
            <Form.Item
              label={translate('weight')}
              name="weight"
              rules={[
                { required: true, message: translate('enterWeight') },
                {
                  pattern: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/,
                  message: translate('moreThanNullNumberValidator'),
                },
              ]}
              initialValue={0.1}
              className="new-task__number-input-wrapper"
            >
              <InputNumber
                min={0}
                placeholder={translate('weight')}
                className="new-task__number-input"
                onBlur={() => setDefaultValueIfWarning('weight')}
              />
            </Form.Item>
            <Form.Item
              label={translate('volume')}
              name="volume"
              rules={[
                { required: true, message: translate('enterVolume') },
                {
                  pattern: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/,
                  message: translate('moreThanNullNumberValidator'),
                },
              ]}
              initialValue={0.1}
              className="new-task__number-input-wrapper"
            >
              <InputNumber
                min={0}
                placeholder={translate('volume')}
                className="new-task__number-input"
                onBlur={() => setDefaultValueIfWarning('volume')}
              />
            </Form.Item>
          </div>

          <div className="new-task__stroke">
            <Form.Item
              label={translate('height')}
              name="height"
              initialValue={order && order.sizes ? order.sizes.height : null}
            >
              <InputNumber
                min={0}
                placeholder={translate('height')}
                className="new-task__number-input"
              />
            </Form.Item>
            <Form.Item
              label={translate('width')}
              name="width"
              initialValue={order && order.sizes ? order.sizes.width : null}
            >
              <InputNumber
                min={0}
                placeholder={translate('width')}
                className="new-task__number-input"
              />
            </Form.Item>
            <Form.Item
              label={translate('length')}
              name="length"
              initialValue={order && order.sizes ? order.sizes.length : null}
            >
              <InputNumber
                min={0}
                placeholder={translate('length')}
                className="new-task__number-input"
              />
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default NewTaskForm;
