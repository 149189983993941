import './style.less';

import React from 'react';

import { Alert } from 'antd';
import { observer } from 'mobx-react-lite';
import {
  NavLink,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';

import AccountSystem from 'modules/account-system/components/AccountSystem';
import ClientGroups from 'modules/client-groups/components/ClientGroups';
import Clusters from 'modules/clusters/components/Clusters';
import { PageTitle } from 'modules/common/components/HeaderMobile';
import Layout from 'modules/common/containers/Layout';
import Couriers from 'modules/couriers/components/directory/Couriers';
import DeliveryMethods from 'modules/delivery-methods/components/DeliveryMethods';
import { translate } from 'modules/localization';
import ReasonsDelay from 'modules/reasonsDelay/components/ReasonsDelay';
import Shops from 'modules/shops/components/Shops';
import Transports from 'modules/transports/components/Transports';
import Users from 'modules/user/components/User';
import Warehouses from 'modules/warehouses/components/Warehouses';
import { useStore } from 'services/store';
// import Countries from 'models/countries/components/Countries';
// import Cities from 'models/cities/components/Cities';

interface IDirectoryProps extends RouteComponentProps {
  className: string;
}

interface IDirectoryMenuSubItem {
  access?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any;
  href: string;
  id: string;
  title: string;
}

interface IDirectoryMenuItem {
  access?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: any;
  href?: string;
  id: string;
  title: string;
  subitems?: IDirectoryMenuSubItem[];
}

const SECTION_DATA: IDirectoryMenuItem[] = [
  {
    access: 'clusters.list',
    component: Clusters,
    href: '/clusters',
    id: 'clusters',
    title: translate('clusters'),
  },
  {
    access: 'shops.list',
    component: Shops,
    href: '/shops',
    id: 'shops',
    title: translate('shops'),
  },
  {
    access: 'client-groups.list',
    component: ClientGroups,
    href: '/client-groups',
    id: 'client-groups',
    title: translate('clientGroups'),
  },
  {
    access: 'transports.list',
    component: Transports,
    href: '/transports',
    id: 'transports',
    title: translate('transport'),
  },
  {
    access: 'warehouses.list',
    component: Warehouses,
    href: '/warehouses',
    id: 'warehouses',
    title: translate('warehouses'),
  },
  {
    access: 'couriers.list',
    component: Couriers,
    href: '/couriers',
    id: 'couriers',
    title: translate('couriers'),
  },
  {
    access: 'delivery-methods.list',
    component: DeliveryMethods,
    href: '/delivery-methods',
    id: 'delivery-methods',
    title: translate('deliveryMethods'),
  },
  {
    access: 'profiles.view',
    component: Users,
    href: '/users',
    id: 'users',
    title: translate('users'),
  },
  {
    access: 'profiles.create',
    component: AccountSystem,
    href: '/account-system',
    id: 'account-system',
    title: translate('accountSystems'),
  },
  {
    access: 'reasons-late.list',
    component: ReasonsDelay,
    href: '/reasons-delay',
    id: 'reasons-delay',
    title: translate('reasonsDelay'),
  },
  // {
  //   // TODO CLUSTER permission for country
  //   access: 'warehouses.list',
  //   component: Countries,
  //   href: '/countries',
  //   id: 'countries',
  //   title: translate('countries'),
  // },
  // {
  //   // TODO CLUSTER permission for country
  //   access: 'warehouses.list',
  //   component: Cities,
  //   href: '/cities',
  //   id: 'cities',
  //   title: translate('cities'),
  // },
];

const Directory = (props: IDirectoryProps) => {
  const { core } = useStore();
  const checkAccess = (menuItem) => !menuItem.access || core.permissions[menuItem.access];

  const getSectionList = (): IDirectoryMenuItem[] => {
    return SECTION_DATA.filter(checkAccess);
  };

  const renderMain = (props) => (
    <div className="directories">
      {renderNav()}
      {renderSection(props.match.params.section)}
    </div>
  );

  const renderNav = () => {
    const list = getSectionList();

    if (list.length > 0) {
      return (
        <div className="directories__menu-wrap">
          <nav className="directories__menu">
            {list.map((item) => (
              <NavLink
                key={item.id}
                to={`${props.match.url}${item.href}`}
                className="directories__menu-link"
              >
                {item.title}
              </NavLink>
            ))}
          </nav>
        </div>
      );
    }

    return null;
  };

  const renderSection = (sectionName) => {
    if (sectionName) {
      const section = SECTION_DATA.map((s) => (s.subitems ? [...s.subitems] : s))
        .flat()
        .find((s) => s.id === sectionName);

      if (section) {
        if ((section.access && core.permissions[section.access]) || !section.access) {
          const Component = section.component;

          return <Component baseUrl={props.match.url} />;
        }

        return (
          <Alert banner description message={translate('accessDenied')} showIcon type={'warning'} />
        );
      }
    }

    const sectionList = getSectionList();
    return <Redirect to={`${props.match.url}${sectionList[0].href}`} />;
  };

  return (
    <Layout>
      {core.isMobile && <PageTitle>{translate('directory')}</PageTitle>}
      <Switch>
        <Route path={`${props.match.url}/:section`} render={renderMain} />
        <Route path={props.match.url} render={renderMain} />
      </Switch>
    </Layout>
  );
};

export default withRouter(observer(Directory));
