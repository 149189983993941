import React from 'react';

import { Feature } from 'geojson';
import insidePolygon from 'point-in-polygon';

import Map from 'modules/map/containers/Map';
import MarkerCluster from 'modules/map/containers/MarkerCluster';
import RectangleSelector from 'modules/map/containers/RectangleSelector';
import TaskPoint from 'modules/tasks/components/TaskPoint';
import { ITask } from 'modules/tasks/models/types';
import { getTasksPoints } from 'modules/tasks/models/utils';
import { IWarehouses } from 'modules/warehouses/models/types';

import { getWarehousesPoint, iconCreateFunction, renderWarehouseMarker } from './helpers';

interface IProps {
  taskList: ITask[];
  selectedTaskList: React.Key[];
  handleSelectTask?: (task: ITask) => void;
  handleSelectList?: (list: string[]) => void;
  handleDeselectList?: (list: string[]) => void;
  showRoute?: boolean;
  warehouses?: IWarehouses[];
  children: React.ReactNode;
}

const RoutesGeneratorMap = ({
  taskList,
  warehouses,
  selectedTaskList,
  handleSelectTask,
  handleSelectList,
  handleDeselectList,
  showRoute,
  children,
}: IProps) => {
  const warehousesPoints: Feature[] = getWarehousesPoint(warehouses);

  const tasksPoints = getTasksPoints(taskList);

  const mapView = warehouses ? [...tasksPoints, ...warehousesPoints] : [...tasksPoints];

  const handlePolygonCreate =
    (taskList) =>
    (e): void => {
      const { layer } = e;

      layer.remove();

      const polygon = layer.toGeoJSON().geometry.coordinates[0];

      const selected = getTasksPoints(taskList)
        .filter((point) => insidePolygon(point.geometry.coordinates, polygon))
        .reduce((list, point) => [...list, ...point.properties.guid], []);

      handleSelectList(selected);
    };

  const handleClusterClick = (event): void => {
    const markersList = event.layer.getAllChildMarkers();
    const markersToSelect = markersList.filter((t) => !t.options.checked);
    const markersToDeselect = markersList.filter((t) => t.options.checked);
    if (markersToSelect.length === 0) {
      const tasksGuidsToDeselect = markersToDeselect.reduce(
        (list, point) => [...list, ...point.options.guid],
        [],
      );
      handleDeselectList(tasksGuidsToDeselect);
    } else {
      const tasksGuidsToSelect = markersToSelect.reduce(
        (list, point) => [...list, ...point.options.guid],
        [],
      );
      handleSelectList(tasksGuidsToSelect);
    }
  };

  const RectangleSelectorEl = () => <RectangleSelector onCreate={handlePolygonCreate(taskList)} />;

  return (
    <Map isSearch={false} mapView={mapView.length > 0 ? mapView : []}>
      <RectangleSelectorEl />
      {warehouses && warehouses.length > 0 && warehouses.map((item) => renderWarehouseMarker(item))}
      <MarkerCluster
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        iconCreateFunction={iconCreateFunction}
        clickHandler={handleClusterClick}
        maxClusterRadius={showRoute ? 5 : 80}
      >
        {taskList.map((task) => (
          <TaskPoint
            key={task.guid}
            task={{ ...task, status: 'new' }}
            isChecked={selectedTaskList.includes(task.guid)}
            isRoute
            isTooltip
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            eventHandlers={{
              click: () => handleSelectTask(task),
            }}
          />
        ))}
      </MarkerCluster>

      {children}
    </Map>
  );
};

export default RoutesGeneratorMap;
