import './style.less';

import React, { useState } from 'react';

import { observer } from 'mobx-react-lite';

import ClusterInformation from 'modules/clusters/components/ClusterInformation';
import ClusterSetting from 'modules/clusters/components/ClusterSetting';
import ClusterWorkspaceTabs from 'modules/clusters/components/ClustersWorkspace/ClusterWorkspaceTabs';
import { useStore } from 'services/store';

interface IProps {
  baseUrl: string;
}

const ClustersWorkspace = ({ baseUrl }: IProps) => {
  const { clusters } = useStore();

  const [activeTab, setActiveTab] = useState<'information' | 'setting'>('information');
  return (
    <div
      className={`cluster-workspace ${activeTab === 'setting' ? 'cluster-workspace_settings' : ''}`}
    >
      <ClusterWorkspaceTabs activeTab={activeTab} setActiveTab={setActiveTab} />
      <div className="cluster-workspace-tab-content">
        {activeTab === 'information' && clusters.clusterWorkspace && (
          <ClusterInformation baseUrl={baseUrl} cluster={clusters.clusterWorkspace} />
        )}
        {activeTab === 'setting' && clusters.clusterWorkspace && (
          <ClusterSetting baseUrl={baseUrl} cluster={clusters.clusterWorkspace} />
        )}
      </div>
    </div>
  );
};

export default observer(ClustersWorkspace);
