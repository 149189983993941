import React from 'react';

import ClusterEditButtons from 'modules/clusters/components/ClusterEditButtons';
import { ICluster } from 'modules/clusters/models/types';

interface IProps {
  baseUrl: string;
  cluster: ICluster;
}

const ClusterInformationHeader = ({ baseUrl, cluster }: IProps) => {
  return (
    <div className="cluster-information-header">
      <div className="cluster-information-title">{cluster?.title}</div>
      <ClusterEditButtons baseUrl={baseUrl} guid={cluster?.guid} />
    </div>
  );
};

export default ClusterInformationHeader;
