export enum TypeNotification {
  success = 'success',
  warning = 'warning',
  error = 'error',
  info = 'info',
}

export enum ActionType {
  ADD = 'ADD_NOTIFICATION',
  REMOVE = 'REMOVE_NOTIFICATION',
}

export interface INotificationForUsing {
  message: string;
  description?: string;
}

export interface INotification extends INotificationForUsing {
  typeNotification: TypeNotification;
  id?: string;
}

export type TNotifyFunc = (params: INotification) => void;
export type TSetNotifyFunc = (notifyFunc: TNotifyFunc) => void;

export type TActionAdd = {
  type: ActionType.ADD;
  payload: INotification;
};

export type TActionRemove = {
  type: ActionType.REMOVE;
  id: string;
};

export type TAction = TActionAdd | TActionRemove;
