export interface ICurrency {
  code: string;
  symbol: string;
  name: string;
}

export const formatCurrency = (
  value: number,
  currencyCode: string,
  currencyList: ICurrency[],
): string => {
  const result = value.toLocaleString('ru-RU', {
    minimumFractionDigits: 2,
  });
  return `${result}\u00A0${getAbbreviatedNameCurrency(currencyCode, currencyList).symbol}`;
};

export const getAbbreviatedNameCurrency = (code: string, currencyList: ICurrency[]): ICurrency => {
  const unknownCurrency = { code, symbol: '-', name: 'unknown' };

  return currencyList.find((c) => c.code === code) || unknownCurrency;
};
