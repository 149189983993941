import './style.less';

import React from 'react';

import { Drawer } from 'antd';
import { observer } from 'mobx-react-lite';

import { ClusterItemSidebar } from 'modules/qa/common/ClusterItemsList';
import { ClusterSidebar } from 'modules/qa/common/ClusterList';
import { useStore } from 'services/store';

import { CourierSidebar } from '../CourierList';
import { SessionSidebar } from '../CourierSessionList';
import { OrderSidebar } from '../OrderList';
import { RouteSidebar } from '../RouteList';
import { TaskSidebar } from '../TaskList';
import { TransportSidebar } from '../TransportList';
import { WarehouseSidebar } from '../WarehouseList';

const Sidebar = () => {
  const { fastmineMonitor } = useStore();

  const getComponent = () => {
    switch (fastmineMonitor.sidebarType) {
      case 'task':
        return <TaskSidebar task={fastmineMonitor.sidebarData} />;

      case 'courier':
        return <CourierSidebar profile={fastmineMonitor.sidebarData} />;

      case 'cluster':
        return <ClusterSidebar cluster={fastmineMonitor.sidebarData} />;

      case 'clusteritem':
        return <ClusterItemSidebar cluster={fastmineMonitor.sidebarData} />;

      case 'session':
        return <SessionSidebar session={fastmineMonitor.sidebarData} />;

      case 'order':
        return <OrderSidebar order={fastmineMonitor.sidebarData} />;

      case 'route':
        return <RouteSidebar route={fastmineMonitor.sidebarData} />;

      case 'transport':
        return <TransportSidebar transport={fastmineMonitor.sidebarData} />;

      case 'warehouse':
        return <WarehouseSidebar warehouse={fastmineMonitor.sidebarData} />;

      default:
        return null;
    }
  };

  const component = getComponent();

  const closeSidebar = () => {
    fastmineMonitor.setSidebarType(null);
    fastmineMonitor.setSidebarData(null);
  };

  return (
    <Drawer
      placement="right"
      onClose={closeSidebar}
      closable={false}
      open={component !== null}
      size="large"
    >
      {component}
    </Drawer>
  );
};

export default observer(Sidebar);
