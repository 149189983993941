import './style.less';

import React from 'react';

import ClusterInformationBody from 'modules/clusters/components/ClusterInformation/ClusterInformationBody';
import ClusterInformationHeader from 'modules/clusters/components/ClusterInformation/ClusterInformationHeader';
import ClusterMap from 'modules/clusters/components/ClusterMap';
import { ICluster } from 'modules/clusters/models/types';

interface IProps {
  baseUrl: string;
  cluster: ICluster;
}

const ClusterInformation = ({ baseUrl, cluster }: IProps) => {
  return (
    <>
      <ClusterInformationHeader baseUrl={baseUrl} cluster={cluster} />
      <ClusterInformationBody cluster={cluster} />
      <ClusterMap
        polygon={cluster.polygon}
        warehouses={cluster.cluster_item_list.map((i) => i.warehouse)}
        guid={cluster.guid}
      />
    </>
  );
};

export default ClusterInformation;
