import './style.less';

import React, { useEffect, useState } from 'react';

import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import querystring from 'query-string';

import Search from 'modules/arm2/components/Search';
import StatusFilter from 'modules/arm2/components/StatusFilter';
import WarehouseFilter from 'modules/arm2/components/WarehouseFilter';
import { FilterIcon } from 'modules/common/components/Icons';
import Title from 'modules/common/components/Title';
import CourierSendMaster from 'modules/couriers/components/arm/CourierSendMaster';
import { DEFAULT_DATE } from 'modules/couriers/components/arm/CourierSendMaster/DateFilter';
import { DEFAULT_WAREHOUSE } from 'modules/couriers/components/arm/CourierSendMaster/WarehouseFilter';
import { getCouriersFilter } from 'modules/couriers/helpers/localStorage';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

const CouriersHeader = () => {
  const { couriers, core } = useStore();
  const [isMasterVisible, setMasterVisible] = useState(false);
  const openMaster = () => setMasterVisible(true);
  const closeMaster = () => setMasterVisible(false);

  useEffect(() => {
    const queryMap = querystring.parse(window.location.search).map_show;

    queryMap && typeof queryMap === 'string' && couriers.setFilter('mapShow', queryMap === 'true');
  }, []);

  const [viewFilter, setViewFilter] = useState(!core.isMobile);

  return (
    <div className="couriers-header">
      <Title size={Title.SIZE.H3} weight={Title.WEIGHT.SEMIBOLD} className="courier-title">
        {translate('couriersList')}
      </Title>
      {core.isMobile && (
        <div className="courier-filter--mobile">
          <Search
            actionSearch={() => couriers.apiStore.getCouriers()}
            actionListGet={() => couriers.apiStore.getCouriers()}
            actionSearchSet={(value) => couriers.setFilter('search', value)}
            query={couriers.filter.search}
            placeholder={translate('searchByFullName')}
          />
          <Button
            type="link"
            icon={<FilterIcon />}
            onClick={() => setViewFilter(!viewFilter)}
            className={`routes-generator-source_filter-button ${
              viewFilter ? 'routes-generator-source_filter-button__active' : ''
            }`}
          />
        </div>
      )}
      {viewFilter && (
        <div className="courier-filter">
          <div className="courier-filter__wrap">
            {!core.isMobile && (
              <Search
                actionSearch={() => couriers.apiStore.getCouriers()}
                actionListGet={() => couriers.apiStore.getCouriers()}
                actionSearchSet={(value) => couriers.setFilter('search', value)}
                query={couriers.filter.search}
                placeholder={translate('searchByFullName')}
              />
            )}
            <StatusFilter
              className="courier-filter__status"
              optionsList={couriers.sessionStatuses}
              getList={couriers.apiStore.getCouriers}
              setFilter={couriers.setFilter}
              value={couriers.filter.status}
              getFilterFromLocalStorage={getCouriersFilter}
            />
            <WarehouseFilter
              className="courier-filter__warehouse"
              getList={couriers.apiStore.getCouriers}
              setFilter={couriers.setFilter}
              value={couriers.filter.warehouses}
              getFilterFromLocalStorage={getCouriersFilter}
            />
          </div>
          {!core.isMobile && (
            <>
              <Button type="primary" onClick={openMaster}>
                {translate('sendForLoading')}
              </Button>
              <CourierSendMaster
                isVisible={isMasterVisible}
                closeMaster={closeMaster}
                defaultFilters={{
                  warehouses: (Array.isArray(couriers.filter.warehouses) &&
                    couriers.filter.warehouses) || [DEFAULT_WAREHOUSE],
                  date: DEFAULT_DATE,
                }}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default observer(CouriersHeader);
