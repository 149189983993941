import React from 'react';

import { Button } from 'antd';
import { Link } from 'react-router-dom';

import { translate } from 'modules/localization';
import { useStore } from 'services/store';

interface IProps {
  baseUrl: string;
  guid: string;
}

const ClusterSettingHeader = ({ baseUrl, guid }: IProps) => {
  const { core } = useStore();
  return (
    <div className="cluster-setting-header">
      <div className="cluster-setting-header-title">{translate('deliveryMethods')}</div>
      {core.permissions['clusters.update'] && (
        <Link to={`${baseUrl}/${guid}/add-settings/${location.search}`}>
          <Button className="cluster-setting-header-button">
            {translate('addDeliveryMethod')}
          </Button>
        </Link>
      )}
    </div>
  );
};

export default ClusterSettingHeader;
