import { Dayjs } from 'dayjs';

import { IWeekdayIDEnum } from 'modules/schedules/models/apitypes';

export interface ITimeslotUI {
  start: Dayjs | string;
  limit: number | '';
  price: number | '';
  end: Dayjs | string;
  id: string;
  call: boolean;
  error_start?: string;
  error_limit?: string;
  error_price?: string;
  error_end?: string;
  error_call?: string;
}

export enum IWeekdayTitleEnum {
  MONDAY = 'mondayShort',
  TUESDAY = 'tuesdayShort',
  WEDNESDAY = 'wednesdayShort',
  THURSDAY = 'thursdayShort',
  FRIDAY = 'fridayShort',
  SATURDAY = 'saturdayShort',
  SUNDAY = 'sundayShort',
}

export interface IWeekday {
  id: IWeekdayIDEnum;
  title: IWeekdayTitleEnum;
  timeslots: ITimeslotUI[];
}

export interface IScheduleFormData {
  name: string;
  average_speed: number;
}
