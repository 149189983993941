import Api from 'api/Api';
import { IPagination } from 'api/types';
import {
  IDeliveryMethodsCreateResponse,
  IDeliveryMethodsDeleteResponse,
  IDeliveryMethodsEditResponse,
  IDeliveryMethodsForm,
  IDeliveryMethodsGetResponse,
  IDeliveryMethodsListGetResponse,
} from 'modules/delivery-methods/models/types';

export default class DeliveryMethodsApi {
  static getDeliveryMethod(guid: string): Promise<IDeliveryMethodsGetResponse> {
    return Api.get(`/api/v1/delivery-methods/${guid}`);
  }

  static getDeliveryMethodsList(
    pagination: IPagination,
    signal?: AbortSignal,
  ): Promise<IDeliveryMethodsListGetResponse> {
    const query = {
      page: pagination.current,
      page_size: pagination.pageSize,
    };
    return Api.get('/api/v1/delivery-methods', query, signal);
  }

  static createDeliveryMethod(data: IDeliveryMethodsForm): Promise<IDeliveryMethodsCreateResponse> {
    return Api.post(data, '/api/v1/delivery-methods');
  }

  static editDeliveryMethod(
    guid: string,
    data: IDeliveryMethodsForm,
  ): Promise<IDeliveryMethodsEditResponse> {
    return Api.patch(data, `/api/v1/delivery-methods/${guid}`);
  }

  static deleteDeliveryMethod(guid: string): Promise<IDeliveryMethodsDeleteResponse> {
    return Api.delete(`/api/v1/delivery-methods/${guid}`);
  }

  static searchDeliveryMethods(
    q: string,
    pagination: IPagination,
    signal?: AbortSignal,
  ): Promise<IDeliveryMethodsListGetResponse> {
    const query = {
      page: pagination.current,
      page_size: pagination.pageSize,
      q,
    };
    return Api.get('/api/v1/search/delivery-methods', query, signal);
  }
}
