import React, { ReactNode } from 'react';

import { observer } from 'mobx-react-lite';
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';

import Error from 'modules/common/containers/Error';
import Privacy from 'modules/common/containers/Privacy';
import RouterSection from 'modules/common/containers/RouterSection';
import Login from 'modules/core/components/Login';
import { useStore } from 'services/store';

interface IPrivateRoute extends RouteProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any;
  token: string;
}

const PrivateRoute = ({ component: Component, token, ...rest }: IPrivateRoute) => {
  return (
    <Route
      {...rest}
      render={(props): ReactNode =>
        token ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Router = () => {
  const { core } = useStore();

  return (
    <Switch>
      <Route component={Login} exact path="/login" />
      <Route component={Privacy} exact path="/privacy" />
      <PrivateRoute component={Error} path="/error" token={core.token} />
      <PrivateRoute component={RouterSection} path="/:section" token={core.token} />
      <PrivateRoute component={RouterSection} path="/" token={core.token} />
    </Switch>
  );
};

export default observer(Router);
