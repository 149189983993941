import './style.less';

import React from 'react';

import L, { Map } from 'leaflet';

import { withMap } from 'modules/map/hoc';

interface IProps {
  isFullScreen: boolean;
  toggleChangeIsFullScreen: (val: boolean) => void;
}

interface IPropsMap extends IProps {
  map: Map;
}

class FullScreenButton extends React.Component<IPropsMap> {
  // eslint-disable-next-line react/no-unused-class-component-methods
  button: HTMLButtonElement = null;
  createButtonControl() {
    const MapHelp = L.Control.extend({
      onAdd: () => {
        const buttonFullMap = L.DomUtil.create('button', '');
        buttonFullMap.className = 'full-screen-map-button leaflet-control';

        buttonFullMap.addEventListener('click', () => {
          this.props.toggleChangeIsFullScreen(!this.props.isFullScreen);
          setTimeout(() => {
            this.props.map.invalidateSize();
            buttonFullMap.className = `${
              this.props.isFullScreen ? 'full-screen-map-button__close' : 'full-screen-map-button'
            } leaflet-control`;
          }, 100);
        });
        // eslint-disable-next-line react/no-unused-class-component-methods
        this.button = buttonFullMap;
        return buttonFullMap;
      },
    });
    return new MapHelp({ position: 'topright' });
  }

  componentDidMount() {
    const { map } = this.props;
    const control = this.createButtonControl();
    control.addTo(map);
  }

  render() {
    return null;
  }
}

export default withMap(FullScreenButton);
