import Api from 'api/Api';
import { IPagination } from 'api/types';
import { IFilter } from 'modules/arm2/models/types';
import {
  ICouriersCreateResponse,
  ICouriersCreateSessionPayload,
  ICouriersDeleteResponse,
  ICouriersEditResponse,
  ICouriersEditSessionPayload,
  ICouriersListGetResponse,
  ICouriersPayload,
  ICouriersSessionsPayload,
  ICouriersSessionsResponse,
  ISessionSessionCreateResponse,
  ISessionStatusGetResponse,
  ICouriersGetResponse,
} from 'modules/couriers/models/types';

export default class CouriersApi {
  static getCouriersList(filter: IFilter, signal?: AbortSignal): Promise<ICouriersListGetResponse> {
    const query = {
      page: filter.current,
      page_size: filter.pageSize,
      q: filter.search,
      warehouse_guid_list: filter.warehouses,
      session_status_list: filter.status,
    };
    return Api.get('/api/v1/couriers', query, signal);
  }

  static deleteCourier(couriersGuid: string): Promise<ICouriersDeleteResponse> {
    return Api.delete(`/api/v1/couriers/${couriersGuid}`);
  }

  static deleteSession(couriersGuid: string): Promise<ICouriersDeleteResponse> {
    return Api.delete(`/api/v1/courier-sessions/${couriersGuid}`);
  }

  static getCourier(couriersGuid: string): Promise<ICouriersGetResponse> {
    return Api.get(`/api/v1/couriers/${couriersGuid}`);
  }

  static editCourier(couriersGuid: string, data: ICouriersPayload): Promise<ICouriersEditResponse> {
    return Api.patch(data, `/api/v1/couriers/${couriersGuid}`);
  }

  static createCourier(data: ICouriersPayload): Promise<ICouriersCreateResponse> {
    return Api.post(data, '/api/v1/couriers');
  }

  static createCourierSession(
    data: ICouriersCreateSessionPayload,
  ): Promise<ISessionSessionCreateResponse> {
    return Api.post(data, '/api/v1/courier-sessions');
  }

  static editCourierSession(
    data: ICouriersEditSessionPayload,
    sessionGuid: string,
  ): Promise<ISessionSessionCreateResponse> {
    return Api.patch(data, `/api/v1/courier-sessions/${sessionGuid}`);
  }

  static setSessionStatus(
    sessionGuid: string,
    status: string,
  ): Promise<ISessionSessionCreateResponse> {
    return Api.patch({ status }, `/api/v1/courier-sessions/${sessionGuid}/status`);
  }

  static getCourierSessions(data: ICouriersSessionsPayload): Promise<ICouriersSessionsResponse> {
    const params = {
      ...data,
      page_size: data.pageSize,
    };
    return Api.get('/api/v1/courier-sessions', params);
  }

  static getSessionStatuses(): Promise<ISessionStatusGetResponse> {
    return Api.get('/api/v1/dictionaries/courier-session-statuses');
  }

  static searchCouriers(
    search: string,
    pagination: IPagination,
    signal?: AbortSignal,
  ): Promise<ICouriersListGetResponse> {
    const params = {
      page: pagination.current,
      page_size: pagination.pageSize,
      q: search,
    };
    return Api.get('/api/v1/couriers', params, signal);
  }
}
