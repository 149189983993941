import { IPagination } from 'api/types';
import { DEFAULT_PAGINATION } from 'constants/index';
import { DEFAULT_DATE } from 'modules/arm2/components/DateFilter';
import { IFilter } from 'modules/arm2/models/types';
import { IOrder } from 'modules/orders/models/types';

export const initialFilter: IFilter = {
  ...DEFAULT_PAGINATION,
  search: null,
  deliveryDate: DEFAULT_DATE,
  shop: [],
  warehouses: [],
  from: null,
  to: null,
  status: [],
  deliveryMethod: [],
  mapShow: false,
};

export const initialPagination: IPagination = DEFAULT_PAGINATION;

export const initialOrderData: IOrder = {
  address: null,
  available_transition: null,
  client_group_guid: null,
  comment: null,
  coverage_guid: null,
  created_at: null,
  creator_guid: null,
  delivery_date: null,
  delivery_method_guid: null,
  guid: null,
  meta: null,
  number: null,
  point: null,
  recipient_name: null,
  recipient_phone: null,
  resolution: null,
  shop_guid: null,
  sizes: null,
  status: null,
  time_slot_end: null,
  time_slot_start: null,
  transport_param: null,
  updated_at: null,
  updater_guid: null,
  warehouse_guid: null,
};
