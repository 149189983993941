import React, { useEffect } from 'react';

import { CloudDownloadOutlined, ExpandOutlined } from '@ant-design/icons';
import { Card, Descriptions, List } from 'antd';
import { observer } from 'mobx-react-lite';

import DelorianState from 'modules/qa/common/DelorianState';
import Diff from 'modules/qa/common/Diff';
import { DiffFlag } from 'modules/qa/common/flags';
import { ITransport } from 'modules/qa/models/types';
import { useStore } from 'services/store';

type IPropsSidebar = {
  transport: ITransport;
};

const Sidebar = ({ transport }: IPropsSidebar) => {
  return (
    <>
      <Descriptions bordered title="Транспорт" size="small" column={1}>
        <Descriptions.Item label="Транспорт">{transport.guid}</Descriptions.Item>
        {transport.name && <Descriptions.Item label="Название">{transport.name}</Descriptions.Item>}
        {transport.number && (
          <Descriptions.Item label="Госномер">{transport.number}</Descriptions.Item>
        )}
        <Descriptions.Item label="Курьер">{transport.courier_guid}</Descriptions.Item>
        <Descriptions.Item label="height">{transport.height}</Descriptions.Item>
        <Descriptions.Item label="length">{transport.length}</Descriptions.Item>
        <Descriptions.Item label="upload_time">{transport.upload_time}</Descriptions.Item>
        <Descriptions.Item label="volume">{transport.volume}</Descriptions.Item>
        <Descriptions.Item label="weight">{transport.weight}</Descriptions.Item>
        <Descriptions.Item label="width">{transport.width}</Descriptions.Item>
        <Descriptions.Item label="Блокирующий процесс">{transport.locked}</Descriptions.Item>
      </Descriptions>

      <DelorianState entity={transport} />

      <Diff entity={transport} />

      <pre>{JSON.stringify(transport, null, 2)}</pre>
    </>
  );
};

export const TransportSidebar = Sidebar;

const TransportList = () => {
  const { fastmineMonitor } = useStore();

  useEffect(() => {
    fastmineMonitor.getTransports({ pageSize: 100, current: 1 });
  }, []);

  return (
    <List
      grid={{ column: 1 }}
      header={<b>Транспорт</b>}
      dataSource={fastmineMonitor.transportsList}
      renderItem={(transport) => {
        const openTransport = () => {
          fastmineMonitor.setSidebarType('transport');
          fastmineMonitor.setSidebarData(transport);
        };

        return (
          <List.Item>
            <Card
              title={transport.difference && <DiffFlag />}
              extra={
                <>
                  {!transport.name && (
                    <>
                      <a
                        href="#"
                        rel="noreferrer"
                        onClick={() => fastmineMonitor.loadTransport(transport.guid)}
                      >
                        <CloudDownloadOutlined />
                      </a>
                      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </>
                  )}
                  <a href="#" rel="noreferrer" onClick={openTransport}>
                    <ExpandOutlined />
                  </a>
                </>
              }
            >
              <p>{transport.guid}</p>
              {transport.name && (
                <p>
                  <b>{transport.name}</b>
                </p>
              )}
              {transport.number && <p>{transport.number}</p>}
            </Card>
          </List.Item>
        );
      }}
    />
  );
};

export default observer(TransportList);
