import uniqBy from 'lodash.uniqby';
import { action, makeObservable, observable } from 'mobx';

import { RestException } from 'api/RestException';
import { IPagination } from 'api/types';
import { getLastPagePagination, getResponsePagination, isOutOfPages } from 'helpers/pagination';
import { getDataToSet, prepareData } from 'modules/clusters/models/helpers';
import { DirectoryApi, DirectoryApiStore } from 'modules/common/store/DirectoryApiStore';
import { routerStore } from 'services/store';

import ClustersApi from '../api/ClustersApi';
import {
  ICluster,
  IClusterForm,
  IClusterFormData,
  IFilterOption,
  IFilterOptionWarehouse,
} from '../models/types';

const Api: DirectoryApi<ICluster, IClusterForm> = {
  getEntity: ClustersApi.getCluster,
  getEntityList: null,
  search: null,
  createEntity: ClustersApi.createCluster,
  editEntity: ClustersApi.editCluster,
  deleteEntity: ClustersApi.deleteCluster,
};

export class ClustersStore extends DirectoryApiStore<ICluster, IClusterForm> {
  clusterWorkspace: ICluster = null;
  clusterFilterByCluster: string = null;
  clusterFilterByCourier: string = null;

  constructor() {
    super(Api, '/directories/clusters');
    makeObservable(this, {
      clusterWorkspace: observable,
      clusterFilterByCluster: observable,
      clusterFilterByCourier: observable,
      deleteDeliveryMethodFromCluster: action.bound,
      setClusterWorkspace: action.bound,
      filterByCluster: action.bound,
      filterByCourier: action.bound,
      resetFilters: action.bound,
    });
  }

  async getList(
    pagination: IPagination,
    isRedirect = true,
    search?: string,
    profile?: string,
  ): Promise<ICluster[]> {
    try {
      this.activeRequestlist && this.activeRequestlist.abort();
      this.activeRequestlist = new AbortController();
      const request = this.activeRequestlist;

      const { data: res } = await ClustersApi.getClustersList(
        pagination,
        search,
        profile,
        request.signal,
      );
      if (isOutOfPages(res.pagination)) {
        return this.getList(getLastPagePagination(res.pagination), isRedirect, search, profile);
      }
      if (isRedirect && !res.errors && res.pagination) {
        routerStore.history.location.pathname !== '/error' &&
          routerStore.history.location.pathname === '/directories/clusters' &&
          routerStore.replace(
            `/directories/clusters?page=${res.pagination.page}&page_size=${res.pagination.page_size}`,
          );
        this.pagination = getResponsePagination(res.pagination);
      }
      this.list = res.data;
      if (res.data.length > 0) {
        this.setClusterWorkspace(res.data[0].guid);
      }

      return res.data;
    } catch (e) {
      throw new RestException(e);
    } finally {
      if (this.activeRequestlist && !this.activeRequestlist.signal.aborted) {
        this.activeRequestlist = null;
      }
    }
  }

  async search(query?: string, pagination?: IPagination, profile?: string): Promise<ICluster[]> {
    return this.getList(pagination, false, query, profile);
  }

  setClusterWorkspace = (guid: string): void => {
    const cluster = this.list.find((c) => c.guid === guid);
    this.clusterWorkspace = cluster;
  };

  filterByCluster = (value: string) => {
    this.clusterFilterByCluster = value ? value : null;
    this.searchCluster();
  };

  filterByCourier = (value: string) => {
    this.clusterFilterByCourier = value ? value.split(' ')[0] : null;
    this.searchCluster();
  };

  searchCluster = () => {
    this.search(
      this.clusterFilterByCluster,
      { current: 1, pageSize: 100 },
      this.clusterFilterByCourier,
    );
  };

  resetFilters = () => {
    this.clusterFilterByCluster = null;
    this.clusterFilterByCourier = null;
  };

  get filterOptions(): {
    optionsClusters: IFilterOption[];
    optionsWarehouses: IFilterOptionWarehouse[];
    optionsCouriers: IFilterOption[];
  } {
    const optionsClusters = [];
    const optionsWarehouses = [];
    const optionsCouriers = [];

    this.list.map((cluster) => {
      optionsClusters.push({ label: cluster.title, value: cluster.title });
      cluster.cluster_item_list.map((item) => {
        optionsWarehouses.push({
          name: item.warehouse.name,
          address: item.warehouse.address,
          value: item.warehouse.name,
        });
      });
      cluster.profile_list.map((profile) => {
        optionsCouriers.push({ label: profile.name, value: profile.name });
      });
    });

    return {
      optionsClusters: uniqBy(optionsClusters, 'value'),
      optionsWarehouses: uniqBy(optionsWarehouses, 'value'),
      optionsCouriers: uniqBy(optionsCouriers, 'value'),
    };
  }

  deleteDeliveryMethodFromCluster(guid: string, deliveryMethodGuid: string) {
    const cluster = this.list.find((item) => item.guid === guid);
    const clusterForForm = getDataToSet(cluster);
    const clusterData: IClusterFormData = {
      ...clusterForForm,
      deliverySettingsList: clusterForForm.deliverySettingsList.filter(
        (item) => item.delivery_method_guid !== deliveryMethodGuid,
      ),
    };
    const data: IClusterForm = prepareData(clusterData);
    this.edit(guid, data);
  }

  async edit(guid: string, data: IClusterForm): Promise<void> {
    try {
      this.isLoadingForm = true;
      await this.api.editEntity(guid, data);
      this.setSearchQuery('');
      await this.getList({ ...this.pagination, current: 1 });
      this.setClusterWorkspace(guid);
      this.isLoadingForm = false;
    } catch (e) {
      this.isLoadingForm = false;
      throw new RestException(e);
    }
  }
}
