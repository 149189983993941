import './style.less';

import React, { ReactNode, useEffect } from 'react';

import { observer } from 'mobx-react-lite';

import Navigation from 'modules/arm2/components/Navigation';
import RoutesHeader from 'modules/routes/components/RoutesHeader';
import { useStore } from 'services/store';

import RoutesList from '../RoutesList';

const RoutesListPage = () => {
  const { routes } = useStore();

  useEffect(() => {
    routes.apiStore.getRoutesStatuses();
  }, []);

  const renderList = (): ReactNode => {
    return (
      <div className="routes__list">
        <RoutesList />
      </div>
    );
  };

  return (
    <div className="routes">
      <div className="routes__content">
        <Navigation />
        <RoutesHeader />
        {renderList()}
      </div>
    </div>
  );
};

export default observer(RoutesListPage);
