import uniq from 'lodash.uniq';
import { makeAutoObservable } from 'mobx';

import { groupTasksByCoverages } from 'modules/tasks/helpers/lists';

import { RouteGeneratorStore } from './RouteGeneratorStore';

export class DndTypes {
  static LEFT_TASK = 'leftTask';
  static RIGHT_TASK = 'rightTask';
  static LEFT_COVERAGE = 'leftCoverage';
  static RIGHT_COVERAGE = 'rightCoverage';
  static ROUTE = 'route';
}

export interface IDndItem {
  id: string;
}

export class RouteGeneratorDnDStore {
  root: RouteGeneratorStore;

  constructor(root: RouteGeneratorStore) {
    this.root = root;
    makeAutoObservable(this);
  }

  onDropToLeftSide = (id: string, itemType: DndTypes): void => {
    switch (itemType) {
      case DndTypes.RIGHT_TASK: {
        const task = this.root
          .getRouteDraft(this.root.openCardRouteDraftId)
          .tasksList.find((task) => task.guid === id);
        this.root.removeTaskListFromRouteDraft([task], this.root.openCardRouteDraftId);
        break;
      }
      case DndTypes.RIGHT_COVERAGE: {
        const task = this.root
          .getRouteDraft(this.root.openCardRouteDraftId)
          .tasksList.filter((task) => task.coverage_guid === id);
        this.root.removeTaskListFromRouteDraft(task, this.root.openCardRouteDraftId);
        break;
      }
      case DndTypes.ROUTE: {
        this.root.setOpenCardRouteDraftId(null);
        this.root.removeRoute(id);
        break;
      }
    }
  };

  onDropToRoute = (id: string, itemType: DndTypes, routeId?: string): void => {
    const destination = routeId ? routeId : 'new';
    switch (itemType) {
      case DndTypes.LEFT_TASK: {
        const selectedTaskGuids = uniq([id, ...this.root.selectedTaskGuids]);
        this.root.moveSourceTasksToRoute(destination, selectedTaskGuids);
        this.root.selectedTaskGuids = [];
        break;
      }
      case DndTypes.LEFT_COVERAGE: {
        const taskList = this.root.taskListSource;
        const tasksGrouped = groupTasksByCoverages(taskList);
        const taskGuids = tasksGrouped[id].tasks.map((item) => item.guid);
        const selectedTaskGuids = uniq([...taskGuids, ...this.root.selectedTaskGuids]);
        this.root.moveSourceTasksToRoute(destination, selectedTaskGuids);
        this.root.selectedTaskGuids = [];
        break;
      }
      case DndTypes.ROUTE: {
        if (id !== routeId) {
          const selectedTaskGuids = this.root.routeDraftList
            .find((item) => item.uid === id)
            .tasksList.map((item) => item.guid);
          this.root.removeRoute(id);
          this.root.moveSourceTasksToRoute(destination, selectedTaskGuids);
        }
        break;
      }
    }
  };
}
