import Api from 'api/Api';

import {
  IStartSupportScenarioData,
  IStartSupportScenarioResponse,
  ISupportScenarioOrderListGetResponse,
  ISupportScenarioTaskListGetResponse,
} from '../models/types';

export default class SupportFunctionalApi {
  static getSupportScenarioOrderList(): Promise<ISupportScenarioOrderListGetResponse> {
    return Api.get('/api/v1/dictionaries/support/scenario/order');
  }

  static getSupportScenarioTaskList(): Promise<ISupportScenarioTaskListGetResponse> {
    return Api.get('/api/v1/dictionaries/support/scenario/task');
  }

  static startSupportScenario(
    scenarioData: IStartSupportScenarioData,
  ): Promise<IStartSupportScenarioResponse> {
    const data = {
      entity_guid: scenarioData.entityGuid,
      key: scenarioData.key,
    };
    return Api.post(data, '/api/v1/support/scenarios');
  }
}
