import './style.less';

import React, { useEffect, useState } from 'react';

import { Checkbox } from 'antd';
import { observer } from 'mobx-react-lite';
import querystring from 'query-string';
import { useParams } from 'react-router';

import DeliveryMethodsSelect from 'modules/delivery-methods/components/DeliveryMethodsSelect';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

interface IProps {
  withToggler?: boolean;
  isShort?: boolean;
}

interface IParams {
  locationGuid: string;
}

const CoveragesFilter = ({ withToggler, isShort }: IProps) => {
  const { coverage } = useStore();

  const { filter, setFilter, resetFilter, setCoveragesListView, coveragesListLocal } = coverage;

  const [isFilterVisible, setFilterVisible] = useState(true);

  const { locationGuid } = useParams<IParams>();

  useEffect(() => {
    if (!filter.locationGuid || filter.locationGuid === locationGuid) {
      const queryDeliveryMethod = querystring.parse(window.location.search).delivery_method_guid;
      const queryIsActive = querystring.parse(window.location.search).is_active;

      queryDeliveryMethod &&
        typeof queryDeliveryMethod === 'string' &&
        setFilter('deliveryMethodGuid', queryDeliveryMethod);
      queryIsActive &&
        typeof queryIsActive === 'string' &&
        setFilter('isActive', queryIsActive === 'true');
    } else {
      resetFilter();
    }

    return () => {
      coverage.setCoveragesList([]);
      coverage.setCoveragesListView([]);
    };
  }, []);

  const handleDeliveryMethodsFilter = (val: string): void => {
    setFilter('deliveryMethodGuid', val);
    coverage.apiStore.getCoverages(!withToggler);
  };

  const handleChangeToggler = (e) => {
    setFilterVisible(e.target.checked);
    if (!e.target.checked) {
      setFilter('deliveryMethodGuid', null);
      setCoveragesListView(coveragesListLocal);
    } else {
      setFilter('deliveryMethodGuid', 'all');
      coverage.apiStore.getCoverages(false);
    }
  };

  const handleChangeActive = (e): void => {
    setFilter('isActive', e.target.checked);
    coverage.apiStore.getCoverages(!withToggler);
  };

  return (
    <div className={withToggler ? 'coverages-filter--with-toggler' : 'coverages-filter'}>
      {withToggler ? (
        <Checkbox onChange={handleChangeToggler} checked={isFilterVisible}>
          {translate('showCoveragesByDeliveryMethod')}
        </Checkbox>
      ) : null}
      {isFilterVisible ? (
        <DeliveryMethodsSelect
          isSelectAllOptionAllowed
          isMissingGeo={!isShort}
          className="coverages__filter-item"
          onChange={handleDeliveryMethodsFilter}
          value={filter.deliveryMethodGuid}
        />
      ) : null}
      {!withToggler && (
        <Checkbox
          onChange={handleChangeActive}
          checked={filter.isActive}
          className="coverages-active_item"
        >
          {translate('hideInactive')}
        </Checkbox>
      )}
    </div>
  );
};

export default observer(CoveragesFilter);
