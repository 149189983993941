import './style.less';

import React, { useEffect } from 'react';

import { Checkbox, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import querystring from 'query-string';
import { useParams } from 'react-router';

import { getUrlPagination } from 'helpers/url';
import DeliveryMethodsSelect from 'modules/delivery-methods/components/DeliveryMethodsSelect';
import { translate } from 'modules/localization';
import ServiceZonesSelect from 'modules/service-zones/components/ServiceZonesSelect';
import { useStore } from 'services/store';

interface IParams {
  locationGuid: string;
}

const RatesFilter = () => {
  const { rates } = useStore();

  const { filter, setFilter } = rates;

  const { locationGuid } = useParams<IParams>();

  useEffect(() => {
    if (!filter.locationGuid || filter.locationGuid === locationGuid) {
      const queryActiveOnly = querystring.parse(window.location.search).active_only;
      const queryServiceZoneGuidList = querystring.parse(
        window.location.search,
      ).service_zone_guid_list;
      const queryDeliveryMethodGuidList = querystring.parse(
        window.location.search,
      ).delivery_method_guid_list;

      const { current, pageSize } = getUrlPagination();

      setFilter('current', current);
      setFilter('pageSize', pageSize);
      queryActiveOnly &&
        typeof queryActiveOnly === 'string' &&
        setFilter('activeOnly', queryActiveOnly === 'true');

      if (queryServiceZoneGuidList && typeof queryServiceZoneGuidList === 'string') {
        setFilter('serviceZoneGuidList', [queryServiceZoneGuidList]);
      }
      if (queryServiceZoneGuidList && typeof queryServiceZoneGuidList === 'object') {
        setFilter('serviceZoneGuidList', queryServiceZoneGuidList);
      }

      if (queryDeliveryMethodGuidList && typeof queryDeliveryMethodGuidList === 'string') {
        setFilter('deliveryMethodGuidList', [queryDeliveryMethodGuidList]);
      }
      if (queryDeliveryMethodGuidList && typeof queryDeliveryMethodGuidList === 'object') {
        setFilter('deliveryMethodGuidList', queryDeliveryMethodGuidList);
      }
    } else {
      rates.resetFilter();
    }
  }, []);

  const handleChangeActive = (e): void => {
    setFilter('activeOnly', e.target.checked);
    rates.apiStore.getRates();
  };

  const handleServiceZonesFilter = (val): void => {
    setFilter('serviceZoneGuidList', val);
    rates.apiStore.getRates();
  };

  const handleDeliveryMethodsFilter = (val): void => {
    setFilter('deliveryMethodGuidList', val);
    rates.apiStore.getRates();
  };

  return (
    <div className="rates-filter">
      <Row>
        <ServiceZonesSelect
          className="rates__filter-item"
          onChange={handleServiceZonesFilter}
          value={filter.serviceZoneGuidList}
          placeholder={translate('serviceZone')}
          mode="multiple"
          maxTagTextLength={25}
          maxTagCount={10}
        />
      </Row>
      <Row>
        <DeliveryMethodsSelect
          className="rates__filter-item"
          onChange={handleDeliveryMethodsFilter}
          placeholder={translate('deliveryMethod')}
          value={filter.deliveryMethodGuidList}
          mode="multiple"
        />
      </Row>

      <Row>
        <Checkbox
          onChange={handleChangeActive}
          checked={filter.activeOnly}
          className="rates__filter-item"
        >
          {translate('hidePast')}
        </Checkbox>
      </Row>
    </div>
  );
};

export default observer(RatesFilter);
