import React, { useEffect } from 'react';

import { SelectProps } from 'antd/lib/select';
import { observer } from 'mobx-react-lite';
import querystring from 'query-string';

import { IFilter } from 'modules/arm2/models/types';
import DeliveryMethodsSelect from 'modules/delivery-methods/components/DeliveryMethodsSelect';

interface IProps {
  setFilter?: (key: string, value: string[] | string) => void;
  getList?: (filter?) => void;
  value: string[];
  className?: string;
  getFilterFromLocalStorage?: () => IFilter;
  disabled?: boolean;
}

const DeliveryMethodFilter = ({
  setFilter,
  getList,
  value,
  className,
  getFilterFromLocalStorage,
  disabled,
  ...rest
}: IProps & SelectProps) => {
  useEffect(() => {
    const localStorageFilter = getFilterFromLocalStorage && getFilterFromLocalStorage();
    const localStorageMethod = localStorageFilter && localStorageFilter.deliveryMethod;
    const deliveryMethod = querystring.parse(window.location.search).method || localStorageMethod;

    if (deliveryMethod && typeof deliveryMethod === 'string') {
      setFilter && setFilter('deliveryMethod', [deliveryMethod]);
    }
    if (deliveryMethod && typeof deliveryMethod === 'object') {
      setFilter && setFilter('deliveryMethod', deliveryMethod);
    }
  }, []);

  const handleFilter = (val): void => {
    setFilter('deliveryMethod', val);
    getList && getList();
  };

  return (
    <div className={className}>
      <DeliveryMethodsSelect
        mode="multiple"
        onChange={handleFilter}
        value={value}
        disabled={disabled}
        {...rest}
      />
    </div>
  );
};

export default observer(DeliveryMethodFilter);
