import '@geoman-io/leaflet-geoman-free';

import { useEffect } from 'react';

import { useMap } from 'react-leaflet';

interface ICreateManagerProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCreate: (layer: any) => void;
}

const CONTROLS = {
  editControls: false,
  drawCircle: false,
  drawMarker: false,
  drawCircleMarker: false,
  drawPolyline: false,
  dragMode: false,
  drawPolygon: false,
  drawRectangle: true,
  drawText: false,
};

const RectangleSelector = ({ onCreate }: ICreateManagerProps) => {
  const map = useMap();

  useEffect(() => {
    map.pm.addControls(CONTROLS);
    map.on('pm:create', onCreate);

    return () => {
      map.pm.disableDraw('Rectangle');
      map.pm.removeControls();
    };
  }, []);

  return null;
};

export default RectangleSelector;
