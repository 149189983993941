import './style.less';

import React, { ReactNode } from 'react';

import { Collapse, CollapseProps } from 'antd';

interface IProps {
  children: ReactNode;
}

const MapCollapse = ({ children }: IProps) => {
  const renderHeader = () => {
    return <div className="map-collapse__header" />;
  };

  const itemsCollapse: CollapseProps['items'] = [
    {
      key: 'map',
      showArrow: false,
      label: renderHeader(),
      children: children,
      className: 'language-mobile__menu',
    },
  ];

  return <Collapse className="map-collapse" ghost items={itemsCollapse} />;
};

export default MapCollapse;
