import React, { PropsWithChildren } from 'react';

import { Button } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import { translate } from 'modules/localization';

interface IProps {
  baseUrl: string;
  isPermitted: boolean;
}

export default ({ baseUrl, isPermitted, children }: IProps & PropsWithChildren) => {
  const location = useLocation();

  return isPermitted ? (
    <Link to={`${baseUrl}/create${location.search}`}>
      <Button type="primary">{children || translate('add')}</Button>
    </Link>
  ) : null;
};
