import React, { useEffect, useState } from 'react';

import { LatLngLiteral } from 'leaflet';
import { observer } from 'mobx-react-lite';
import { Marker, Popup, useMapEvents } from 'react-leaflet';

import { ICoordinatesMap } from 'modules/locations/models/types';
import Map from 'modules/map/containers/Map';
import { useStore } from 'services/store';

interface IProps {
  action?: string;
}

interface IEventHandlerProps {
  setMarkerPosition?: (point: LatLngLiteral) => void;
}

const LocationsMapEventHandler = observer(({ setMarkerPosition }: IEventHandlerProps) => {
  const { locations } = useStore();
  const { locationData, setCoordinatesMap } = locations;

  const setNewCoordinatesMap = (newPoint: LatLngLiteral, zoom: number): void => {
    const coordinates: ICoordinatesMap = {
      lat: newPoint.lat,
      lon: newPoint.lng,
      zoomLevel: zoom,
    };
    setMarkerPosition(newPoint);
    setCoordinatesMap(coordinates);
  };

  const map = useMapEvents({
    move: (event): void => {
      setMarkerPosition(event.target.getCenter());
    },
    moveend: (event): void => {
      setNewCoordinatesMap(event.target.getCenter(), event.target.getZoom());
    },
  });

  useEffect(() => {
    const latLng: LatLngLiteral = locationData.point
      ? { lat: locationData.point.lat, lng: locationData.point.lon }
      : { lat: 0, lng: 0 };
    setMarkerPosition(latLng);
    map.setView(latLng, locationData.scale);
  }, [locationData.point, locationData.scale]);

  return null;
});

const LocationsMap = ({ action }: IProps) => {
  const [markerPosition, setMarkerPosition] = useState<LatLngLiteral>(null);

  return (
    <Map>
      {action && (
        <Marker position={markerPosition ? [markerPosition.lat, markerPosition.lng] : [0, 0]}>
          <Popup>{markerPosition && `${markerPosition.lat} ${markerPosition.lng}`}</Popup>
        </Marker>
      )}
      <LocationsMapEventHandler setMarkerPosition={setMarkerPosition} />
    </Map>
  );
};

export const RootLocationsMap = () => <Map />;

export default LocationsMap;
