import './style.less';

import React from 'react';

import { observer } from 'mobx-react-lite';

import Form from 'modules/directory/containers/Form';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

import { getDataToSet, getFields, prepareData } from './helpers';

interface IProps {
  baseUrl: string;
}

const ClientGroupsForm = ({ baseUrl }: IProps) => {
  const { core, clientGroups } = useStore();

  const isView = !core.permissions['client-groups.update'];
  const getFormFields = getFields(isView);

  return (
    <Form
      baseUrl={baseUrl}
      actionCreate={clientGroups.create}
      actionEdit={clientGroups.edit}
      actionGet={clientGroups.get}
      getDataToSet={getDataToSet}
      getFields={getFormFields}
      prepareData={prepareData}
      message={{
        editSuccess: translate('clientGroupEditSuccess'),
        createSuccess: translate('clientGroupCreateSuccess'),
        editTitle: translate('clientGroupEditTitle'),
        createTitle: translate('clientGroupCreateTitle'),
      }}
      isPermittedSubmit={
        core.permissions['client-groups.create'] && core.permissions['client-groups.update']
      }
      isLoading={clientGroups.isLoadingForm}
    />
  );
};

export default observer(ClientGroupsForm);
