import './style.less';

import React, { useEffect } from 'react';

import { Button, DatePicker, Form, Input, Spin } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';

import { getFieldDataList } from 'helpers/form';
import { dateFormat } from 'helpers/string';
import Loader from 'modules/common/containers/Loader';
import { ICourier, ISession } from 'modules/couriers/models/types';
import { translate } from 'modules/localization';
import TransportSelect from 'modules/transports/components/TransportSelect';
import { useStore } from 'services/store';

interface IProps {
  onClose: () => void;
  onOk: () => void;
  courier: ICourier;
  session?: ISession;
  plannedDate?: string;
}

const CourierEditSession = ({ onClose, courier, onOk, session, plannedDate }: IProps) => {
  const { couriers } = useStore();

  const [form] = Form.useForm();

  const isDisabledDate = (current: Dayjs | undefined): boolean => {
    return current && (current <= dayjs().add(-1, 'days') || current > dayjs().add(14, 'days'));
  };

  useEffect(() => {
    session && form.setFieldsValue({ planned_date: dayjs(session.planned_date) });
    plannedDate &&
      !isDisabledDate(dayjs(plannedDate)) &&
      form.setFieldsValue({ planned_date: dayjs(plannedDate) });
    session && session.transport && form.setFieldsValue({ transport_guid: session.transport.guid });
  }, []);

  const handleError = (values) => (response) => {
    const fieldDataList = getFieldDataList(response, values);
    form.setFields(fieldDataList);
  };

  const onSubmit = (values): void => {
    if (session) {
      const params = {
        transport_guid: values.transport_guid,
      };
      couriers.apiStore
        .editCourierSession(params, session.guid)
        .then(() => {
          onOk();
          onClose();
        })
        .catch(handleError(values));
    } else {
      const params = {
        courier_guid: courier && courier.guid,
        transport_guid: values.transport_guid,
        planned_date: values.planned_date.format('YYYY-MM-DD'),
      };
      couriers.apiStore
        .createCourierSession(params)
        .then(() => {
          onOk();
          onClose();
        })
        .catch(handleError(values));
    }
  };

  return (
    <Form name="basic" form={form} onFinish={onSubmit} colon={false} layout="vertical">
      <div className="courier-edit-session-form">
        <Form.Item label={translate('courier')}>
          <Input
            value={`${courier.profile.surname} ${courier.profile.name} ${
              courier.profile.patronymic ? courier.profile.patronymic : ''
            }${courier.profile.disabled ? ` (${translate('disabled')})` : ''}`}
            disabled
          />
        </Form.Item>
        <Form.Item
          label={translate('transport')}
          name="transport_guid"
          rules={[{ required: true, message: translate('transportRequiredValidator') }]}
        >
          <TransportSelect defaultOptions={courier ? courier.transports : null} />
        </Form.Item>

        <Form.Item
          label={translate('sessionPlannedDate')}
          name="planned_date"
          rules={[{ required: true, message: translate('enterDate') }]}
        >
          <DatePicker
            format={dateFormat.string}
            disabled={!!session}
            disabledDate={isDisabledDate}
            getPopupContainer={(el) => el}
          />
        </Form.Item>
      </div>
      <Spin indicator={<Loader show />} spinning={couriers.loadingForm}>
        <div className="courier-edit-session-actions">
          <Button htmlType="button" key="cancel" onClick={onClose} type="default">
            {translate('cancel')}
          </Button>
          <Button htmlType="submit" key="save" type="primary" style={{ marginLeft: '8px' }}>
            {translate('save')}
          </Button>
        </div>
      </Spin>
    </Form>
  );
};

export default observer(CourierEditSession);
