import React from 'react';

import L from 'leaflet';

const COLOR = {
  WHITE: '#ffffff',
  BLACK: '#595959',
  BLUE: '#1890FF',
  GREEN: '#00CC66',
  PINK: '#FF7875',
  GRAY: '#8C8C8C',
};

const STATUS_ICONS: Record<string, [string, string]> = {
  // Common
  default: [COLOR.WHITE, COLOR.GRAY],
  canceled: [COLOR.WHITE, COLOR.GRAY],
  in_process: [COLOR.WHITE, COLOR.BLUE],
  in_process_problem: [COLOR.WHITE, COLOR.BLUE],
  complete: [COLOR.WHITE, COLOR.GREEN],
  planned: [COLOR.WHITE, COLOR.GRAY],
  // Orders
  awaiting_completion: [COLOR.WHITE, COLOR.GREEN],
  completed: [COLOR.WHITE, COLOR.GRAY],
  no_coordinates: [COLOR.WHITE, COLOR.PINK],
  not_confirmed: [COLOR.WHITE, COLOR.PINK],
  // Sessions
  awaiting_courier: [COLOR.WHITE, COLOR.GRAY],
  // Routes
  at_loading: [COLOR.WHITE, COLOR.GRAY],
  // Tasks
  awaiting_return: [COLOR.WHITE, COLOR.GRAY],
  complete_problem: [COLOR.WHITE, COLOR.GREEN],
  delayed: [COLOR.WHITE, COLOR.PINK],
  new: [COLOR.WHITE, COLOR.GRAY],
  new_problem: [COLOR.WHITE, COLOR.PINK],
  in_loading_route: [COLOR.WHITE, COLOR.GRAY],
  in_process_route: [COLOR.WHITE, COLOR.GRAY],
  in_route: [COLOR.WHITE, COLOR.GRAY],
  in_route_problem: [COLOR.WHITE, COLOR.PINK],
  not_complete: [COLOR.WHITE, COLOR.PINK],
  // специальные статусы для тасков на форме создания маршрута
  in_pre_route: [COLOR.GREEN, COLOR.GREEN],
  in_pre_route_problem: [COLOR.PINK, COLOR.PINK],
};

export interface IIconOption {
  status: string;
  fill?: string;
  content?: number | string;
  isHighlighted?: boolean;
  isLarge?: boolean;
}

const getIcon = (options: IIconOption): string => {
  const { status, isLarge, fill } = options;
  if (status === 'checked') {
    const customFill = fill || '#00CC66';
    return `<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.0202 24C5.38161 24 0 18.6274 0 12C0 5.37258 5.38161 0 12.0202 0C18.6587 0 24.0403 5.37258 24.0403 12C24.0403 18.6274 18.6587 24 12.0202 24Z" fill="${customFill}"/><rect x="16.2427" y="7" width="2.56354" height="10.2542" rx="1" transform="rotate(39 16.2427 7)" fill="white"/><rect x="7" y="12.0918" width="2.56354" height="6.40885" rx="1" transform="rotate(-45 7 12.0918)" fill="white"/></svg>`;
  }
  const [customFill, stroke] =
    fill || status in STATUS_ICONS ? STATUS_ICONS[status] : STATUS_ICONS['default'];
  return isLarge
    ? `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12px" cy="12px" r="9px" fill="${customFill}" stroke="${stroke}" stroke-width="6" /></svg>`
    : `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12px" cy="12px" r="11px" fill="${customFill}" stroke="${stroke}" stroke-width="2" /></svg>`;
};

const getTextElement = (options: IIconOption): string => {
  const { status, content } = options;
  const fontColor =
    status === 'in_pre_route' || status === 'in_pre_route_problem' ? COLOR.WHITE : COLOR.BLACK;
  return `<text x="50%" y="50%" text-anchor="middle" dy="0.3em" dx="0em" font-size="14px" font-family="sans-serif" fill="${fontColor}">${content}</text>`;
};

const getIconWithContent = (options: IIconOption): string => {
  const tail = '</svg>';
  const head = getIcon(options).split(tail)[0];
  const body = getTextElement(options);
  return `${head}${body}${tail}`;
};

const getStatusIconUrl = (options: IIconOption): string => {
  const svg = options.content ? getIconWithContent(options) : getIcon(options);
  return `data:image/svg+xml;base64,${btoa(svg)}`;
};

export const getStatusIconLeaflet = (options: IIconOption): L.Icon => {
  const { isHighlighted } = options;

  const iconUrl = getStatusIconUrl(options);
  return L.icon({
    iconAnchor: [14, 14],
    iconRetinaUrl: iconUrl,
    iconSize: [28, 28],
    iconUrl: iconUrl,
    className: `marker-point ${isHighlighted ? 'marker-point--highlighted' : ''}`,
  });
};

export const getStatusIconImg = (options: IIconOption): React.ReactNode => {
  const iconUrl = getStatusIconUrl(options);
  return <img src={iconUrl} />;
};

export const getGroupIconLeaflet = (fill = '#fff', isHighlighted): L.Icon => {
  const svg = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12px" cy="12px" r="11px" fill="${fill}" stroke="#8C8C8C" stroke-width="2" /><circle cx="12px" cy="12px" r="5px" fill="#fff" stroke="#8C8C8C" stroke-width="2" /></svg>`;
  const iconUrl = `data:image/svg+xml;base64,${btoa(svg)}`;
  return L.icon({
    iconAnchor: [14, 14],
    iconRetinaUrl: iconUrl,
    iconSize: [28, 28],
    iconUrl: iconUrl,
    className: `marker-point ${isHighlighted ? 'marker-point--highlighted' : ''}`,
  });
};
