import './style.less';

import React, { useEffect } from 'react';

import { Table } from 'antd';
import { observer } from 'mobx-react-lite';

import { useStore } from 'services/store';

import { getColumns } from './helpers';

const AccountSystemList = () => {
  const { accountSystem } = useStore();

  useEffect(() => {
    accountSystem.getAll();
  }, []);

  const columns = getColumns(accountSystem.edit);

  return (
    <Table
      className="dir__list"
      dataSource={accountSystem.listAll}
      columns={columns}
      bordered={false}
      rowKey="guid"
      loading={accountSystem.isLoadingList}
      pagination={false}
    />
  );
};

export default observer(AccountSystemList);
