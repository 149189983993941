import React from 'react';

import { formatCurrency } from 'helpers/currency';
import { translate } from 'modules/localization';
import {
  getTasksListAmount,
  getTasksListVolume,
  getTasksListWeight,
} from 'modules/tasks/helpers/lists';
import { ITask } from 'modules/tasks/models/types';
import { useStore } from 'services/store';

interface IProps {
  taskList: ITask[];
  currencyCode: string;
}

const TaskListSizes = ({ taskList, currencyCode }: IProps) => {
  const { currency } = useStore();
  return (
    <div className="rg-tasklist-sizes">
      {formatCurrency(getTasksListAmount(taskList), currencyCode, currency.currencyList)}
      &nbsp;/&nbsp;
      {getTasksListWeight(taskList).toFixed(2)}&nbsp;{translate('weightUnit')}
      &nbsp;/&nbsp;
      {getTasksListVolume(taskList).toFixed(3)}&nbsp;{translate('lengthUnit')}
      <sup>3</sup>
    </div>
  );
};

export default TaskListSizes;
