import Api from 'api/Api';
import { IPagination } from 'api/types';
import {
  IClientGroupsCreateResponse,
  IClientGroupsDeleteResponse,
  IClientGroupsEditResponse,
  IClientGroupsForm,
  IClientGroupsGetResponse,
  IClientGroupsListGetResponse,
} from 'modules/client-groups/models/types';

export default class ClientGroupsApi {
  static getClientGroup(guid: string): Promise<IClientGroupsGetResponse> {
    return Api.get(`/api/v1/client-groups/${guid}`);
  }

  static getClientGroupsList(
    pagination: IPagination,
    signal?: AbortSignal,
  ): Promise<IClientGroupsListGetResponse> {
    const query = {
      page: pagination.current,
      page_size: pagination.pageSize,
    };
    return Api.get('/api/v1/client-groups', query, signal);
  }

  static createClientGroup(data: IClientGroupsForm): Promise<IClientGroupsCreateResponse> {
    return Api.post(data, '/api/v1/client-groups');
  }

  static editClientGroup(
    guid: string,
    data: IClientGroupsForm,
  ): Promise<IClientGroupsEditResponse> {
    return Api.patch(data, `/api/v1/client-groups/${guid}`);
  }

  static deleteClientGroup(guid: string): Promise<IClientGroupsDeleteResponse> {
    return Api.delete(`/api/v1/client-groups/${guid}`);
  }

  static searchClientGroups(
    search: string,
    pagination: IPagination,
    signal?: AbortSignal,
  ): Promise<IClientGroupsListGetResponse> {
    const params = {
      page: pagination.current,
      page_size: pagination.pageSize,
      q: search,
    };
    return Api.get('/api/v1/search/client-groups', params, signal);
  }
}
