import dayjs from 'dayjs';

import { dateFormat, timeFormat } from 'helpers/string';
import { INewTaskForm } from 'modules/orders/components/OrderNewTaskForm';
import { ITaskForm } from 'modules/tasks/models/types';

export const prepareData = (values: INewTaskForm): ITaskForm => {
  return {
    ...values,
    task_type: 'extra',
    delivery_date: dayjs(values.delivery_date).format(dateFormat.search),
    time_slot_start: dayjs(values.time_slot_start).format(timeFormat.simple),
    time_slot_end: dayjs(values.time_slot_end).format(timeFormat.simple),
  };
};
