import React, { useEffect } from 'react';

import { Form } from 'antd';
import { observer } from 'mobx-react-lite';

import CouriersSelect from 'modules/couriers/components/directory/CouriersSelect';
import { translate } from 'modules/localization';
import { IRouteDraft } from 'modules/routeGenerator/models/types';
import { useStore } from 'services/store';

interface IProps {
  initialValues: IRouteDraft;
  isLoading: boolean;
  onChange: (courierGuid: string) => void;
  setupCourier: (courierGuid: string, transportGuid?: string) => void;
}

const CourierFormItem = ({ initialValues, isLoading, onChange, setupCourier }: IProps) => {
  const { routeGenerator } = useStore();

  useEffect(() => {
    initialValues && setupCourier(initialValues.courierGuid, initialValues.transportGuid);
  }, []);

  return (
    <Form.Item
      label={translate('courier')}
      name="courierGuid"
      rules={[{ required: true, message: translate('enterCourier') }]}
      initialValue={(initialValues && initialValues.courierGuid) || undefined}
    >
      <CouriersSelect
        warehouses={[routeGenerator.routeSettings.warehouses]}
        style={{ minWidth: 200 }}
        popupMatchSelectWidth={false}
        onChange={onChange}
        loading={isLoading}
      />
    </Form.Item>
  );
};

export default observer(CourierFormItem);
