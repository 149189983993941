import './style.less';

import React from 'react';

interface INavProps {
  className?: string;
  children: React.ReactNode;
}

const Nav = (props: INavProps) => {
  return <div className={`nav ${props.className}`}>{props.children}</div>;
};

export default Nav;
