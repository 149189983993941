import React from 'react';

import HintedText from 'modules/common/components/HintedText';
import { ICoverage } from 'modules/coverages/models/types';

interface IProps {
  coverages: Partial<ICoverage>[];
}

const Coverages = ({ coverages }: IProps) => {
  if (coverages.length === 0) {
    return <div className="rg-routecard__coverages">&mdash;</div>;
  }
  return (
    Array.isArray(coverages) && (
      <div className="rg-routecard__coverages">
        <HintedText>
          {coverages.map((item) => (item && item.name ? item.name : '')).join(', ')}
        </HintedText>
      </div>
    )
  );
};

export default Coverages;
