import React from 'react';

import ClusterInformationCouriers from 'modules/clusters/components/ClusterInformation/ClusterInformationCouriers';
import ClusterInformationWarehouses from 'modules/clusters/components/ClusterInformation/ClusterInformationWarehouses';
import { ICluster } from 'modules/clusters/models/types';
import DeliveryMethodName from 'modules/delivery-methods/components/DeliveryMethodName';
import { translate } from 'modules/localization';

interface IProps {
  cluster: ICluster;
}

const ClusterInformationBody = ({ cluster }: IProps) => {
  return (
    <>
      <div className="cluster-information-body-header">
        <div className="cluster-information-body-header_item">
          <div className="cluster-information-body-header_item-title">
            {translate('country')}&nbsp;/&nbsp;{translate('city')}:
          </div>
          <div>{cluster?.city?.name}</div>
        </div>
        <div className="cluster-information-body-header_item">
          <div className="cluster-information-body-header_item-title">{translate('shops')}:</div>
          <div>{cluster?.cluster_item_list[0]?.shop?.name}</div>
        </div>

        <div className="cluster-information-body-header_item">
          <div className="cluster-information-body-header_item-title">
            {translate('deliveryMethods')}:
          </div>
          {cluster?.settings &&
            cluster?.settings?.delivery_settings_list &&
            cluster?.settings?.delivery_settings_list.map((dm) => (
              <div key={dm.delivery_method_guid}>
                <DeliveryMethodName guid={dm.delivery_method_guid} />
              </div>
            ))}
        </div>
      </div>
      <div className="cluster-information-body-lists">
        <ClusterInformationCouriers cluster={cluster} />
        <ClusterInformationWarehouses cluster={cluster} />
      </div>
    </>
  );
};

export default ClusterInformationBody;
