import React from 'react';

import { ColumnsType } from 'antd/es/table';

import { IPermission } from 'modules/core/models/types';
import { IDeliveryMethods } from 'modules/delivery-methods/models/types';
import Actions from 'modules/directory/components/ActionButtons';
import { translate } from 'modules/localization';

export const getColumns = (
  baseUrl: string,
  permissions: IPermission,
  actionDelete: (guid: string) => void,
): ColumnsType<IDeliveryMethods> => {
  return [
    {
      dataIndex: 'name',
      title: translate('title'),
      key: 'name',
      width: '30%',
    },
    {
      dataIndex: 'disabled',
      title: translate('activity'),
      key: 'isActive',
      width: '10%',
      render: (disabled: boolean): React.ReactNode => (
        <span>{!disabled ? translate('yes') : translate('no')}</span>
      ),
    },
    {
      dataIndex: 'guid',
      key: 'action',
      render: (_, item): React.ReactNode => (
        <Actions
          item={item}
          baseUrl={baseUrl}
          actionDelete={actionDelete}
          permissions={{
            edit: permissions['delivery-methods.update'],
            delete: permissions['delivery-methods.delete'],
          }}
          message={{
            deleteCancel: translate('cancel'),
            deleteTitle: translate('deliveryMethodDeleteConfirm'),
          }}
        />
      ),
      width: '10%',
    },
  ];
};
