import { action, makeObservable, observable } from 'mobx';

import { RestException } from 'api/RestException';
import { DirectoryApi, DirectoryApiStore } from 'modules/common/store/DirectoryApiStore';
import { IIdentityForm, IProfile, IProfileForm, IRoles } from 'modules/user/models/types';

import UserApi from '../api/UserApi';

const Api: DirectoryApi<IProfile, IProfileForm> = {
  getEntity: UserApi.getProfile,
  getEntityList: UserApi.getProfiles,
  search: UserApi.searchProfile,
  createEntity: UserApi.createProfile,
  editEntity: UserApi.editProfile,
  deleteEntity: UserApi.deleteProfile,
};
export class UserStore extends DirectoryApiStore<IProfile, IProfileForm> {
  roles: IRoles = {};

  constructor() {
    super(Api, '/directories/users');
    makeObservable(this, {
      roles: observable,
      getRoles: action.bound,
      createIdentity: action.bound,
      editIdentity: action.bound,
      deleteIdentity: action.bound,
    });
  }

  getRoles = async (): Promise<IRoles> => {
    try {
      const { data: res } = await UserApi.getRoles();
      this.roles = res.data;
      return res.data;
    } catch (e) {
      throw new RestException(e);
    }
  };

  createIdentity = async (profileGuid: string, identityData: IIdentityForm): Promise<void> => {
    try {
      this.isLoadingForm = true;
      (identityData.login || identityData.password) &&
        (await UserApi.createIdentity(profileGuid, identityData));
      this.isLoadingForm = false;
    } catch (e) {
      await this.delete(profileGuid);
      this.isLoadingForm = false;
      throw new RestException(e);
    }
  };

  editIdentity = async (
    profileGuid: string,
    identityGuid: string,
    identityData: IIdentityForm,
  ): Promise<void> => {
    try {
      this.isLoadingForm = true;
      await UserApi.editIdentity(profileGuid, identityGuid, identityData);
      this.isLoadingForm = false;
    } catch (e) {
      this.isLoadingForm = false;
      throw new RestException(e);
    }
  };

  deleteIdentity = async (profileGuid: string, identityGuid: string): Promise<void> => {
    try {
      this.isLoadingForm = true;
      await UserApi.deleteIdentity(profileGuid, identityGuid);
      this.isLoadingForm = false;
    } catch (e) {
      this.isLoadingForm = false;
      throw new RestException(e);
    }
  };
}
