import Api from 'api/Api';
import {
  ISchedules,
  ISchedulesCreateResponse,
  ISchedulesEditResponse,
  ISchedulesGetResponse,
  ISchedulesListGetResponse,
} from 'modules/schedules/models/apitypes';

export default class SchedulesApi {
  static getSchedulesList(
    page: number,
    page_size: number,
    location_guid?: string,
    search?: string,
    signal?: AbortSignal,
  ): Promise<ISchedulesListGetResponse> {
    const query = {
      page,
      page_size,
      location_guid,
      search,
    };
    return Api.get('/api/v1/schedules', query, signal);
  }

  static createSchedule(data: ISchedules): Promise<ISchedulesCreateResponse> {
    return Api.post(data, '/api/v1/schedules');
  }

  static editSchedule(scheduleGuid: string, data: ISchedules): Promise<ISchedulesEditResponse> {
    return Api.put(data, `/api/v1/schedules/${scheduleGuid}`);
  }

  static getSchedule(scheduleGuid: string): Promise<ISchedulesGetResponse> {
    return Api.get(`/api/v1/schedules/${scheduleGuid}`);
  }
}
