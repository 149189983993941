import './style.less';

import React, { useEffect, useState } from 'react';

import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, DatePicker, Modal } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import querystring from 'query-string';
import { useParams } from 'react-router';

import { dateFormat, formatPhone } from 'helpers/string';
import Navigation from 'modules/arm2/components/Navigation';
import Title from 'modules/common/components/Title';
import Form from 'modules/couriers/components/directory/Form';
import { initialCourierData } from 'modules/couriers/models/initial';
import { ICourier, ISession } from 'modules/couriers/models/types';
import { translate } from 'modules/localization';
import { getCourierUrl } from 'modules/routes/components/RoutesList/helpers';
import RoutesMap from 'modules/routes/components/RoutesMap';
import { initialRouteData } from 'modules/routes/models/initial';
import { IRoute } from 'modules/routes/models/types';
import { useStore } from 'services/store';

import CourierAddSession from '../CourierEditSession';
import CourierSessions from '../CourierSessions';

interface IParams {
  entityGuid: string;
}

interface IProps {
  baseUrl: string;
}

const CourierPage = ({ baseUrl }: IProps) => {
  const { couriers, router, routes, core } = useStore();

  const { entityGuid } = useParams<IParams>();
  const [route, setRoute] = useState<IRoute>(initialRouteData);

  const routeUpdate = (guid: string): Promise<void> =>
    routes.apiStore.getRoute(guid).then(setRoute);

  useEffect(() => {
    couriers.apiStore.getSessionStatuses();
  }, []);

  const initialSessionDate = querystring.parse(window.location.search).planned_date
    ? dayjs(querystring.parse(window.location.search).planned_date as string)
    : dayjs();

  const [courier, setCourier] = useState<ICourier>(initialCourierData);
  const [sessionDate, setSessionDate] = useState(initialSessionDate);
  const [isOpenSessionModal, setIsOpenSessionModal] = useState<boolean>(false);
  const [sessions, setSessions] = useState<ISession[]>([]);

  const courierUpdate = (): Promise<void> =>
    couriers.apiStore.getCourier(entityGuid).then(setCourier);

  const courierSessionsUpdate = (): Promise<void> =>
    couriers.apiStore
      .getCourierSessions({
        current: 1,
        pageSize: 100,
        courier_guid: [entityGuid],
        planned_date: [sessionDate.format('YYYY-MM-DD')],
      })
      .then((items) => setSessions(items));

  useEffect(() => {
    if (entityGuid) {
      courierUpdate().catch(() =>
        router.push({ pathname: baseUrl, search: router.location.search }),
      );
    }
  }, [entityGuid]);

  useEffect(() => {
    courierSessionsUpdate();
  }, [sessionDate]);

  const handleBackLink = (): void => router.push({ pathname: baseUrl });

  const onCloseModalAddSession = (): void => {
    setIsOpenSessionModal(false);
  };

  const onChangeSessionDate = (date): void => {
    const newDate = date || dayjs();
    router.replace(getCourierUrl(entityGuid, newDate.format('YYYY-MM-DD')));
    setSessionDate(newDate);
  };

  return (
    <div className="courier">
      <div className="courier__main">
        <div className="courier__header">
          <Navigation />
          <div className="courier__title">
            <Button type="link" className="courier__back-link" onClick={handleBackLink}>
              <ArrowLeftOutlined /> {translate('goToCourierList')}
            </Button>
            <div className="courier__title_wrap">
              <div className="courier__contacts">
                <Title size={Title.SIZE.H3} weight={Title.WEIGHT.SEMIBOLD}>
                  {courier.profile &&
                    `${courier.profile.surname} ${courier.profile.name} ${
                      courier.profile.patronymic ? courier.profile.patronymic : ''
                    }`}
                </Title>
                <div className="courier__phone">{formatPhone(courier?.profile?.phone)}</div>
              </div>

              <div className="courier__controls">
                <DatePicker
                  style={{ minWidth: '115px' }}
                  defaultPickerValue={sessionDate}
                  format={dateFormat.string}
                  onChange={onChangeSessionDate}
                  value={sessionDate}
                  getPopupContainer={(el) => el}
                />
                {core.permissions['courier-sessions.create'] && (
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={(): void => setIsOpenSessionModal(!isOpenSessionModal)}
                  >
                    {translate('courierSessionCreateTitle')}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
        {sessions.length > 0 ? (
          <CourierSessions
            courierSessions={sessions}
            onUpdate={courierSessionsUpdate}
            courier={courier}
            routeUpdate={routeUpdate}
          />
        ) : (
          <div className="courier__not-session">{translate('letsCreateCourierSession')}</div>
        )}
      </div>
      {!core.isMobile && <div className="courier__map">{<RoutesMap route={route} />}</div>}
      <Modal
        title={translate('courierSessionSetup')}
        open={isOpenSessionModal}
        onCancel={onCloseModalAddSession}
        footer={null}
        destroyOnClose
      >
        <CourierAddSession
          onClose={onCloseModalAddSession}
          courier={courier}
          onOk={courierSessionsUpdate}
          plannedDate={sessionDate.format('YYYY-MM-DD')}
        />
      </Modal>

      <Form baseUrl={`${baseUrl}/${entityGuid}`} />
    </div>
  );
};

export default observer(CourierPage);
