import React, { ReactNode } from 'react';

import { Feature } from 'geojson';
import L from 'leaflet';

import { translate } from 'modules/localization';
import { getRelativeTasksCount } from 'modules/tasks/models/utils';
import WarehouseMarker from 'modules/warehouse/containers/WarehouseMarker';
import { IWarehouses } from 'modules/warehouses/models/types';

export const iconCreateFunction = (cluster): L.DivIcon => {
  const markerList = cluster.getAllChildMarkers();
  const selectedMarkerList = markerList.filter((m) => m.options.checked);
  const isAnySelected = selectedMarkerList.length > 0;
  const isAllSelected = selectedMarkerList.length === markerList.length;
  const isAnyHighlighted = markerList.some((m) => m.options.task.isHighlighted);

  const background = isAllSelected
    ? '#00cc66'
    : isAnySelected
    ? 'conic-gradient(#00cc66 50%, gray 50%)'
    : 'grey';

  const getTasksCount = (markers): number => {
    return markers.reduce((acc, item) => {
      return acc + getRelativeTasksCount(item.options.task);
    }, 0);
  };

  return L.divIcon({
    className: '',
    html: `<div class="marker-cluster ${
      isAnyHighlighted ? 'marker-cluster--highlighted' : ''
    }" style="color: #808080" title="${cluster.getChildCount()} ${translate('address', {
      count: cluster.getChildCount(),
    })}, ${getTasksCount(cluster.getAllChildMarkers())} ${translate('task', {
      count: getTasksCount(cluster.getAllChildMarkers()),
    })}">
                <div class="marker-cluster__content" style="background: ${background}">
                <span class="marker-cluster__content-inner">${cluster.getChildCount()}</span>
              </div>
            </div>`,
    iconSize: new L.Point(30, 30),
  });
};

export const getWarehousesPoint = (warehouses: IWarehouses[]): Feature[] =>
  warehouses
    ? warehouses
        .filter((item) => Boolean(item.point && item.point.lat && item.point.lon))
        .map((item) => {
          return {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [item.point.lon, item.point.lat],
            },
            properties: {
              name: item.title,
              address: item.address,
              guid: item.guid,
            },
          };
        })
    : [];

export const renderWarehouseMarker = (warehouse: IWarehouses): ReactNode => {
  if (warehouse.point && warehouse.point.lat && warehouse.point.lon) {
    return (
      <WarehouseMarker
        key={warehouse.guid}
        position={[warehouse.point.lat, warehouse.point.lon]}
        name={warehouse.title}
        address={warehouse.address}
      />
    );
  }
};
