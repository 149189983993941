import React from 'react';

import { observer } from 'mobx-react-lite';

import Title from 'modules/common/components/Title';
import ButtonCreate from 'modules/directory/components/ActionButtons/ButtonCreate';
import ButtonCreateMobile from 'modules/directory/components/ActionButtons/ButtonCreateMobile';
import Search from 'modules/directory/containers/Search';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

interface IProps {
  baseUrl: string;
}

const ClientGroupsHeader = ({ baseUrl }: IProps) => {
  const { clientGroups, core } = useStore();

  return (
    <div className="dir__header">
      <Title className="dir__title" size={Title.SIZE.H3} weight={Title.WEIGHT.SEMIBOLD}>
        {translate('clientGroups')}

        {core.isMobile && (
          <ButtonCreateMobile
            baseUrl={baseUrl}
            isPermitted={core.permissions['client-groups.create']}
          />
        )}
      </Title>
      <div className="dir__header-section">
        <Search
          actionSearch={clientGroups.search}
          actionListGet={clientGroups.getList}
          actionSearchSet={clientGroups.setSearchQuery}
          query={clientGroups.searchQuery}
          placeholder={translate('searchByTitle')}
        />
        {!core.isMobile && (
          <ButtonCreate baseUrl={baseUrl} isPermitted={core.permissions['client-groups.create']} />
        )}
      </div>
    </div>
  );
};

export default observer(ClientGroupsHeader);
