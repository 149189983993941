import React, { ReactNode, useEffect } from 'react';

import { Select, Spin } from 'antd';
import { SelectProps } from 'antd/lib/select';
import debounce from 'lodash.debounce';
import { observer } from 'mobx-react-lite';

import { DEFAULT_SELECT_PROPS } from 'modules/common/components/Select';
import { translate } from 'modules/localization';
import { IProfile } from 'modules/user/models/types';
import { useStore } from 'services/store';

const ProfileSelect = ({ onChange, ...rest }: SelectProps) => {
  const { user } = useStore();

  useEffect(() => {
    fetchDefaultOptions();
  }, []);

  const getOptions = () => {
    const options = user.list;
    if (options && Array.isArray(options)) {
      return options;
    }
    return [];
  };

  const fetchDefaultOptions = async () => {
    await user.getList({ current: 1, pageSize: 100 }, false);
  };

  const fetchOptions = async (value: string): Promise<void> => {
    if (value && value.length !== 0) {
      await user.search(value, { current: 1, pageSize: 100 });
    } else {
      await fetchDefaultOptions();
    }
  };

  const handleChange = (value, option): void => {
    onChange(value, option);
  };

  const renderOption = (option: IProfile): ReactNode => {
    if (option) {
      return (
        <Select.Option key={option.guid} value={option.guid}>
          {`${option.surname} ${option.name} ${option.patronymic ? option.patronymic : ' '}${
            option.disabled ? `(${translate('disabled')})` : ''
          }`}
        </Select.Option>
      );
    }
    return null;
  };

  const options = getOptions();

  return (
    <Select
      showSearch
      loading={user.isLoadingList}
      notFoundContent={user.isLoadingList ? <Spin size="small" /> : translate('nothingWasFound')}
      filterOption={false}
      onSearch={debounce(fetchOptions, 300)}
      onBlur={fetchDefaultOptions}
      onChange={handleChange}
      getPopupContainer={(el) => el}
      placeholder={translate('enterUserName')}
      {...rest}
      {...DEFAULT_SELECT_PROPS}
    >
      {options.map(renderOption)}
    </Select>
  );
};

export default observer(ProfileSelect);
