import { ICoveragesFilter } from 'modules/coverages/models/types';

export const createParams = (filter: ICoveragesFilter) => {
  if (filter.deliveryMethodGuid === 'all') {
    return {
      location_guid: filter.locationGuid,
      is_active: filter.isActive,
    };
  }

  if (filter.deliveryMethodGuid === 'missing_tariff') {
    return {
      location_guid: filter.locationGuid,
      missing_tariff: true,
      is_active: filter.isActive,
    };
  }

  if (filter.deliveryMethodGuid === 'missing_service_zone') {
    return {
      location_guid: filter.locationGuid,
      missing_service_zone: true,
      is_active: filter.isActive,
    };
  }

  return {
    location_guid: filter.locationGuid,
    delivery_method_guid: filter.deliveryMethodGuid,
    is_active: filter.isActive,
  };
};
