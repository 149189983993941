import './style.less';

import React, { FC } from 'react';

import { translate } from 'modules/localization';
import { ITask } from 'modules/qa/models/types';

type IPropsSizes = {
  task: ITask;
};

const Sizes: FC<IPropsSizes> = ({ task }) => {
  return (
    <>
      {task?.sizes?.weight
        ? `${+task.sizes.weight.toFixed(2)} ${translate('weightUnit')}`
        : `0 ${translate('weightUnit')}`}
      ,{' '}
      {task?.sizes?.volume ? (
        <>
          {+task.sizes.volume.toFixed(3)} {translate('lengthUnit')}
          <sup>3</sup>
        </>
      ) : (
        <>
          0 {translate('lengthUnit')}
          <sup>3</sup>
        </>
      )}
      <div className="size__list">
        {task?.sizes?.length ? (
          <span className="size__item">
            {translate('lengthTemplate', { content: +task.sizes.length.toFixed(2) })}
          </span>
        ) : null}
        {task?.sizes?.width ? (
          <span className="size__item">
            {translate('widthTemplate', { content: +task.sizes.width.toFixed(2) })}
          </span>
        ) : null}
        {task?.sizes?.height ? (
          <span className="size__item">
            {translate('heightTemplate', { content: +task.sizes.height.toFixed(2) })}
          </span>
        ) : null}
      </div>
    </>
  );
};

export default Sizes;
