import React, { useEffect } from 'react';

import { useLeafletContext, createControlComponent } from '@react-leaflet/core';
import L from 'leaflet';

import './style.less';
import { translate } from 'modules/localization';

const LINE_OPTIONS = {
  color: '#6F6F6F',
  weight: 4,
};
const POINT_OPTIONS = {
  stroke: true,
  color: '#fff',
  fillColor: '#6F6F6F',
  weight: 2,
  fill: true,
  fillOpacity: 1,
  radius: 6,
};
const TOOLTIP_OPTIONS = { permanent: true, className: 'result-tooltip' };

const Ruler = () => {
  const context = useLeafletContext();

  const layer = L.layerGroup([], { attribution: 'ruler' }).addTo(context.map);
  const button = L.DomUtil.create('div', 'ruler-button leaflet-bar leaflet-control');
  let markers = [];
  let totalDistance = 0;
  let clickCount = 0;
  let enabled = false;
  let otherLayers = {};

  useEffect(() => {
    return () => {
      resetRuler();
    };
  }, []);

  const resetRuler = () => {
    enabled = false;
    clickCount = 0;
    markers.length = 0;
    totalDistance = 0;
    layer && layer.clearLayers();
  };

  const bindGlobalEvents = (button) => {
    context.map.on('pm:globaldrawmodetoggled', (e) =>
      e.enabled ? disableRuler(button) : enableRuler(button),
    );
    context.map.on('pm:globaleditmodetoggled', (e) =>
      e.enabled ? disableRuler(button) : enableRuler(button),
    );
    context.map.on('pm:globaldragmodetoggled', (e) =>
      e.enabled ? disableRuler(button) : enableRuler(button),
    );
    context.map.on('pm:globalremovalmodetoggled', (e) =>
      e.enabled ? disableRuler(button) : enableRuler(button),
    );
    context.map.on('pm:globalcutmodetoggled', (e) =>
      e.enabled ? disableRuler(button) : enableRuler(button),
    );

    document
      .getElementsByClassName('coverages-filter--with-toggler')[0]
      ?.addEventListener('click', () => (enabled ? stopRuling() : enableRuler(button)));

    document
      .getElementsByClassName('coverages__get-adjacent')[0]
      ?.addEventListener('click', () => (enabled ? stopRuling() : enableRuler(button)));

    document
      .getElementsByClassName('set-search-polygon__button')[0]
      ?.addEventListener('click', () => (enabled ? stopRuling() : enableRuler(button)));
  };

  const enableRuler = (button) => {
    button.classList.remove('leaflet-ruler-disabled');
    L.DomEvent.on(button, 'click', handleButtonClick);
  };

  const disableRuler = (button) => {
    stopRuling();
    button.classList.add('leaflet-ruler-disabled');
    L.DomEvent.off(button, 'click', handleButtonClick);
  };

  const enableOtherLayers = () => {
    context.map.eachLayer((layer) => {
      if (layer.options.attribution) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        layer._events = otherLayers[layer._leaflet_id];
      }
    });
    otherLayers = {};
  };

  const disableOtherLayers = () => {
    // блокирует слои с кастомными attribution в опциях (маркеры складов и полигоны покрытий)
    context.map.eachLayer((layer) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (layer.options.attribution && layer._events) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        otherLayers[layer._leaflet_id] = layer._events;
        layer.off();
      }
    });
  };

  const handleButtonClick = (e) => {
    e.stopPropagation();
    enabled = !enabled;

    if (enabled) {
      startRuling();
    } else {
      stopRuling();
    }
  };

  const startRuling = () => {
    const container = context.map.getContainer();
    container.style.cursor = 'crosshair';
    button && button.classList.add('enabled');
    context.map.on('click', handleMapClick);
    context.map.on('keydown', handleEscape);
    disableOtherLayers();
  };

  const stopRuling = () => {
    const container = context.map.getContainer();
    button && button.classList.remove('enabled');
    container.style.cursor = '';
    context.map.off('click', handleMapClick);
    context.map.off('keydown', handleEscape);
    resetRuler();
    enableOtherLayers();
  };

  const renderMarkers = () => {
    totalDistance = 0;
    layer.clearLayers();
    markers.map((point, index) => {
      const circleMarker = L.circleMarker(point, POINT_OPTIONS);
      let text;
      if (index === 0) {
        text = '<p data-descr="Начало"></p>';
      } else {
        const prevPoint = markers[index - 1];
        const currentDistance = calculateDistance(point, prevPoint);
        totalDistance += currentDistance;
        L.polyline([point, prevPoint], LINE_OPTIONS).addTo(layer);

        text = `<p data-descr="${totalDistance.toFixed(2)} ${translate('distanceUnit')}"></p>`;

        // иначе линия перекрывает маркеры, не знаю как починить
        const prevCircleMarker = L.circleMarker(prevPoint, POINT_OPTIONS);
        prevCircleMarker.addTo(layer);
      }
      const tooltip = L.tooltip(TOOLTIP_OPTIONS);
      tooltip.setContent(text);
      circleMarker.addTo(layer).bindTooltip(tooltip);
      const tooltipElement = tooltip.getElement();
      tooltipElement &&
        tooltipElement.addEventListener('click', (e) => {
          e.stopPropagation();
          removePoint(index);
        });
    });
  };

  const removePoint = (index = markers.length - 1) => {
    clickCount = clickCount - 1;
    markers = [...markers.slice(0, index), ...markers.slice(index + 1)];
    renderMarkers();
  };

  const addPoint = (point) => {
    markers = [...markers, point];
  };

  const handleEscape = (e) => {
    if (e.originalEvent.code === 'Escape') {
      markers.length > 0 ? removePoint() : stopRuling();
    }
  };

  const handleMapClick = (e) => {
    addPoint(e.latlng);
    renderMarkers();
  };

  const calculateDistance = (firstMarker, lastMarker) => {
    const EARTH_RADIUS_KM = 6371;
    const TO_RADIAN = Math.PI / 180;
    const deltaF = (lastMarker.lat - firstMarker.lat) * TO_RADIAN;
    const deltaL = (lastMarker.lng - firstMarker.lng) * TO_RADIAN;
    const angle =
      Math.sin(deltaF / 2) * Math.sin(deltaF / 2) +
      Math.cos(firstMarker.lat * TO_RADIAN) *
        Math.cos(lastMarker.lat * TO_RADIAN) *
        Math.sin(deltaL / 2) *
        Math.sin(deltaL / 2);
    const distance = 2 * Math.atan2(Math.sqrt(angle), Math.sqrt(1 - angle)) * EARTH_RADIUS_KM;

    return distance;
  };

  const createRulerControl = (props) => {
    const RulerControl = L.Control.extend({
      onAdd: function () {
        L.DomEvent.on(button, 'click', handleButtonClick);
        bindGlobalEvents(button);
        return button;
      },
      onRemove: function () {
        L.DomEvent.off(button, 'click', handleButtonClick);
      },
    });
    return new RulerControl(props);
  };

  const RulerControlComponent = createControlComponent(createRulerControl);

  return <RulerControlComponent position="topleft" />;
};

export default Ruler;
