import './style.less';

import React from 'react';

import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';

import { IPagination } from 'api/types';
import SearchForm from 'modules/directory/containers/Search';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

interface IParams {
  locationGuid: string;
}

const LocationsSearch = () => {
  const { locations } = useStore();

  const { setSearchQuery, searchQuery } = locations;

  const { locationGuid } = useParams<IParams>();

  const updateList = (pagination: IPagination): void => {
    if (locationGuid) {
      locations.apiStore.getLocationsChildren(pagination, locationGuid);
    } else {
      locations.apiStore.getLocations(pagination);
    }
  };

  const actionSearch = (search: string, pagination: IPagination): void => {
    locations.apiStore.searchLocations(search, pagination, true, locationGuid);
  };

  return (
    <div className="locations__search">
      <SearchForm
        actionSearch={actionSearch}
        actionListGet={updateList}
        actionSearchSet={setSearchQuery}
        query={searchQuery}
        placeholder={translate('searchByLocation')}
      />
    </div>
  );
};

export default observer(LocationsSearch);
