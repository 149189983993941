import React from 'react';

import { observer } from 'mobx-react-lite';

import { ITask } from 'modules/tasks/models/types';
import { getDeliveryMethod, getType } from 'modules/tasks/models/utils';
import { useStore } from 'services/store';

interface IProps {
  task: ITask;
}

const TaskDeliveryType = ({ task }: IProps) => {
  const { deliveryMethods, tasks } = useStore();

  const deliveryMethod = getDeliveryMethod(task, deliveryMethods.list);
  const type = getType(task, tasks.tasksTypes);

  return (
    <div className="rg-task__delivery-type" title={`${deliveryMethod}, ${type}`}>
      {deliveryMethod}, {type}
    </div>
  );
};

export default observer(TaskDeliveryType);
