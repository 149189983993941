import { makeAutoObservable } from 'mobx';
import querystring from 'query-string';

import { LOCAL_STORAGE } from 'constants/index';
import { getInitialPagination } from 'helpers/pagination';
import { IFilter } from 'modules/arm2/models/types';
import { setOrdersFilter } from 'modules/orders/helpers/localStorage';
import { initialFilter } from 'modules/orders/models/initial';
import {
  IOrder,
  IOrderResolutionList,
  IOrderStatus,
  IOrderType,
} from 'modules/orders/models/types';
import { routerStore } from 'services/store';

import { OrdersApiStore } from './OrdersApiStore';

export class OrdersStore {
  apiStore: OrdersApiStore;

  orderResolutions: IOrderResolutionList = {};
  orderStatuses: IOrderStatus[] = [];
  orderTypes: IOrderType[] = [];
  orderList: IOrder[] = [];
  activeRequestOrderList: AbortController | null = null;
  filter: IFilter = { ...initialFilter, ...getInitialPagination(LOCAL_STORAGE.ORDERS_FILTER) };

  get isLoadingOrdersList() {
    return this.activeRequestOrderList !== null;
  }

  constructor() {
    this.apiStore = new OrdersApiStore(this);
    makeAutoObservable(this);
  }

  setFilterToUrl = (filter: IFilter = this.filter): void => {
    const qs = querystring.stringify(
      {
        page: filter.current,
        page_size: filter.pageSize,
        q: filter.search,
        date: filter.deliveryDate,
        shop: filter.shop,
        warehouse: filter.warehouses,
        from: filter.from,
        to: filter.to,
        status: filter.status,
        method: filter.deliveryMethod,
        type: filter.type,
        map_show: filter.mapShow,
      },
      { skipNull: true },
    );
    routerStore.history.location.pathname !== '/error' &&
      routerStore.history.location.pathname === '/arm2/orders' &&
      routerStore.replace(`/arm2/orders?${qs}`);
  };

  setSearchQuery = (query: string): void => {
    if (query && query.length > 0) {
      this.filter = {
        ...initialFilter,
        deliveryDate: null,
        search: query,
        mapShow: this.filter.mapShow,
      };
    } else {
      this.filter = { ...this.filter, search: null, mapShow: this.filter.mapShow };
    }
  };

  setFilter = (
    key: string,
    value: Record<string, never> | string[] | boolean | number | string,
  ): void => {
    this.filter[key] = value;
    setOrdersFilter(this.filter);
  };
}
