import { makeAutoObservable } from 'mobx';

import { RestException } from 'api/RestException';
import { getLastPagePagination, getResponsePagination, isOutOfPages } from 'helpers/pagination';
import TasksApi from 'modules/tasks/api/TasksApi';

import { TasksStore } from './TasksStore';
import {
  ITask,
  ITasksFilter,
  ITasksResolutionList,
  ITasksStatus,
  ITasksType,
} from '../models/types';

export class TasksApiStore {
  root: TasksStore;

  constructor(root: TasksStore) {
    this.root = root;
    makeAutoObservable(this);
  }

  getTasks = async (filter: ITasksFilter = this.root.filter): Promise<ITask[]> => {
    try {
      if (
        Array.isArray(this.root.activeRequestTasksList) &&
        this.root.activeRequestTasksList.length > 0
      ) {
        for (let i = 0; i < this.root.activeRequestTasksList.length; i++) {
          this.root.activeRequestTasksList[i].abort();
        }
      }
      this.root.activeRequestTasksList = [];
      const abortController = new AbortController();
      this.root.activeRequestTasksList.push(abortController);

      const { data: res } = await TasksApi.getTasksList(filter, abortController.signal);
      if (isOutOfPages(res.pagination)) {
        return this.getTasks({ ...filter, ...getLastPagePagination(res.pagination) });
      }
      this.root.tasksList = res.data;
      if (!res.errors && res.pagination) {
        this.root.setFilterToUrl({
          ...filter,
          current: res.pagination.page,
          pageSize: res.pagination.page_size,
        });
        const responsePagination = getResponsePagination(res.pagination);
        this.root.setFilter('current', responsePagination.current);
        this.root.setFilter('pageSize', responsePagination.pageSize);
        this.root.setFilter('total', responsePagination.total);
        this.root.setFilter('deliveryDate', filter.deliveryDate);
      }
      return res.data;
    } catch (e) {
      throw new RestException(e);
    } finally {
      if (
        Array.isArray(this.root.activeRequestTasksList) &&
        this.root.activeRequestTasksList.every((item) => !item.signal.aborted)
      ) {
        this.root.activeRequestTasksList = null;
      }
    }
  };

  getTasksStatuses = async (): Promise<ITasksStatus[]> => {
    try {
      const { data: res } = await TasksApi.getTasksStatuses();
      const statuses = Object.keys(res.data).map((k) => ({ value: k, title: res.data[k] }));
      this.root.tasksStatuses = statuses;
      return statuses;
    } catch (e) {
      throw new RestException(e);
    }
  };

  getTasksResolutions = async (): Promise<ITasksResolutionList> => {
    try {
      const { data: res } = await TasksApi.getTasksResolutions();
      const plain = res.data.plain;
      const dependent = res.data.dependent;

      const resolutions = Object.keys(dependent).reduce(
        (acc, k) => {
          acc[k] = dependent[k].map((r) => ({ value: r, title: plain[r] }));
          return acc;
        },
        {
          all: Object.keys(plain).map((r) => ({ value: r, title: plain[r] })),
        },
      );
      this.root.tasksResolutions = resolutions;
      return resolutions;
    } catch (e) {
      throw new RestException(e);
    }
  };

  getTasksTypes = async (): Promise<ITasksType[]> => {
    try {
      const { data: res } = await TasksApi.getTasksTypes();
      this.root.tasksTypes = res.data;
      return res.data;
    } catch (e) {
      throw new RestException(e);
    }
  };

  getTasksProblems = async (): Promise<ITasksStatus[]> => {
    try {
      const { data: res } = await TasksApi.getTasksProblems();
      const statuses = Object.keys(res.data).map((k) => ({ value: k, title: res.data[k] }));
      this.root.tasksProblems = statuses;
      return statuses;
    } catch (e) {
      throw new RestException(e);
    }
  };

  cancelTask = async (taskGuid: string, resolution: string): Promise<ITask> => {
    try {
      const { data: res } = await TasksApi.changeTaskStatus(taskGuid, 'canceled', resolution);
      return res.data;
    } catch (e) {
      throw new RestException(e);
    }
  };

  completeTask = async (taskGuid: string, resolution: string): Promise<ITask> => {
    try {
      const { data: res } = await TasksApi.changeTaskStatus(taskGuid, 'complete', resolution);
      return res.data;
    } catch (e) {
      throw new RestException(e);
    }
  };

  changeTaskTimeslot = async (
    taskGuid: string,
    date: string,
    from: string,
    to: string,
  ): Promise<ITask> => {
    try {
      const { data: res } = await TasksApi.changeTaskTimeSlot(taskGuid, date, from, to);
      return res.data;
    } catch (e) {
      throw new RestException(e);
    }
  };
}
