import { useEffect } from 'react';

import { Feature, MultiPolygon, Polygon } from 'geojson';
import L, { LatLng, Layer } from 'leaflet';
import { useMap } from 'react-leaflet';

interface ICreateManagerProps {
  polygon?: Feature<MultiPolygon | Polygon>;
}

const geoJSONOptions = {
  coordsToLatLng: (coords): LatLng => new LatLng(coords[1], coords[0]),
  color: '#88C1A9',
};

const ClusterPolygon = ({ polygon }: ICreateManagerProps) => {
  let layer: Layer = null;
  const map = useMap();

  const removeLayer = () => {
    layer && map.removeLayer(layer);
  };

  const init = () => {
    removeLayer();
    layer = L.geoJSON(polygon, geoJSONOptions);
    map.addLayer(layer);
  };

  useEffect(() => {
    init();

    return () => {
      removeLayer();
    };
  }, [polygon]);

  return null;
};

export default ClusterPolygon;
