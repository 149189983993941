import './style.less';

import React from 'react';

import { observer } from 'mobx-react-lite';

import Cluster from 'modules/clusters/components/ClustersList/Cluster';
import ClusterListFilter from 'modules/clusters/components/ClustersList/ClusterListFilter';
import { useStore } from 'services/store';

const ClustersList = () => {
  const { clusters } = useStore();

  return (
    <>
      <ClusterListFilter />
      <div className="clusters-list">
        {clusters.list.length > 0 &&
          clusters.list.map((item) => (
            <Cluster
              cluster={item}
              key={item.guid}
              onClick={() => clusters.setClusterWorkspace(item.guid)}
              isActive={clusters.clusterWorkspace?.guid === item.guid}
            />
          ))}
      </div>
    </>
  );
};

export default observer(ClustersList);
