import { makeAutoObservable } from 'mobx';

import { RestException } from 'api/RestException';
import SupportFunctionalApi from 'modules/supportFunctional/api/SupportFunctionalApi';
import {
  ISupportScenarioOrder,
  ISupportScenarioTask,
} from 'modules/supportFunctional/models/types';

export class SupportFunctionalStore {
  scenarioOrderList: ISupportScenarioOrder = null;
  scenarioTaskList: ISupportScenarioTask = null;
  isStartScenario = false;

  constructor() {
    makeAutoObservable(this);
  }

  getSupportScenarioOrderList = async (): Promise<ISupportScenarioOrder> => {
    try {
      const res = await SupportFunctionalApi.getSupportScenarioOrderList();
      this.scenarioOrderList = res.data.data;
      return res.data.data;
    } catch (e) {
      throw new RestException(e);
    }
  };

  getSupportScenarioTaskList = async (): Promise<ISupportScenarioTask> => {
    try {
      const res = await SupportFunctionalApi.getSupportScenarioTaskList();
      this.scenarioTaskList = res.data.data;
      return res.data.data;
    } catch (e) {
      throw new RestException(e);
    }
  };

  startSupportScenario = async (entityGuid: string, key: string): Promise<void> => {
    try {
      this.isStartScenario = true;
      await SupportFunctionalApi.startSupportScenario({ entityGuid, key });
      this.isStartScenario = false;
    } catch (e) {
      this.isStartScenario = false;
      throw new RestException(e);
    }
  };
}
