import './style.less';

import React from 'react';

import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { translate } from 'modules/localization';

interface IProps {
  onClick: (isActive: boolean) => void;
  isActive: boolean;
  count: number;
}

const RelativeTasksToggler = ({ count, isActive, onClick }: IProps) => {
  return (
    <Button
      size="small"
      onClick={() => onClick(!isActive)}
      className={
        isActive ? 'relative-tasks-toggler relative-tasks-toggler_active' : 'relative-tasks-toggler'
      }
    >
      {count} {translate('task', { count })} {translate('onAddress')}
      {isActive ? <UpOutlined /> : <DownOutlined />}
    </Button>
  );
};

export default RelativeTasksToggler;
