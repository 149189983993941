import { Checkbox, Input, InputNumber } from 'antd';

import { IFieldData, integerNumberValidator, positiveNumberValidator } from 'helpers/form';
import { translate } from 'modules/localization';
import { IReasonDelay, IReasonDelayForm } from 'modules/reasonsDelay/models/types';

const priorityValidator = async (_, value) => {
  if (value === 0 || (value && (value < 1 || value > 9999))) {
    return Promise.reject(new Error(translate('priorityNumberValidator')));
  }
};

export const getDataToSet = (item: IReasonDelay): IReasonDelayForm | Record<string, never> => {
  return {
    name: item.name,
    comment: item.comment,
    active: item.active,
    priority: item.priority,
  };
};

export const getFields = (isView) => (): IFieldData => ({
  name: {
    component: Input,
    formItem: {
      label: translate('titleOfReasonDelay'),
      className: 'reasons-delay-form__item reasons-delay-form__item-name',
    },
    name: 'name',
    params: {
      rules: [
        {
          message: translate('requiredValidator'),
          required: true,
        },
      ],
    },
    props: {
      placeholder: translate('enterTitle'),
      disabled: isView,
    },
  },
  priority: {
    component: InputNumber,
    formItem: {
      label: translate('priorityOfReasonDelay'),
      className: 'reasons-delay-form__item reasons-delay-form__item-priority',
    },
    name: 'priority',
    params: {
      rules: [
        {
          message: translate('requiredValidator'),
          required: true,
        },
        { validator: positiveNumberValidator },
        { validator: integerNumberValidator },
        { validator: priorityValidator },
      ],
    },
    props: {
      placeholder: translate('enterPriority'),
      disabled: isView,
    },
  },
  active: {
    component: Checkbox,
    formItem: {
      className: 'reasons-delay-form__item',
    },
    name: 'active',
    params: {
      initialValue: true,
      valuePropName: 'checked',
    },
    props: {
      children: translate('activity'),
      disabled: isView,
    },
  },
  comment: {
    component: Checkbox,
    formItem: {
      className: 'reasons-delay-form__item',
    },
    name: 'comment',
    params: {
      initialValue: false,
      valuePropName: 'checked',
    },
    props: {
      children: translate('requiredComment'),
      disabled: isView,
    },
  },
});

export const prepareData = (formData: IReasonDelayForm): IReasonDelayForm => {
  const { name, active, comment, priority } = formData;
  return {
    name: name ? name.trim() : null,
    active: active,
    comment: comment,
    priority: priority,
  };
};
