import React from 'react';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { timeFormat } from 'helpers/string';
import { translate } from 'modules/localization';
import { IRoute } from 'modules/routes/models/types';

dayjs.extend(utc);
dayjs.extend(timezone);

interface IProps {
  route: IRoute;
}

const RouteTimeFact = ({ route }: IProps) => {
  const countIsLateTasks =
    route.tasks &&
    route.tasks.reduce((accumulator, task) => {
      if (task.problems && task.problems.indexOf('late_complete') !== -1) {
        return ++accumulator;
      }
      return accumulator;
    }, 0);
  const countIsEarlyTasks =
    route.tasks &&
    route.tasks.reduce((accumulator, task) => {
      if (task.problems && task.problems.indexOf('early_complete') !== -1) {
        return ++accumulator;
      }
      return accumulator;
    }, 0);

  const timeStart = route.date_time_start || route.date_time_planned_start;
  const timeEnd = route.date_time_finish || route.date_time_planned_finish;
  const differenceOfDay =
    dayjs(timeStart).format('YYYY-MM-DD') !== dayjs(timeEnd).format('YYYY-MM-DD');

  return route.start_warehouse && route.start_warehouse.timezone ? (
    <>
      <div>
        {differenceOfDay &&
          `${dayjs(timeStart).tz(route.start_warehouse.timezone).format('DD.MM')} \u2014 ${dayjs(
            timeEnd,
          )
            .tz(
              route.finish_warehouse
                ? route.finish_warehouse.timezone
                : route.start_warehouse.timezone,
            )
            .format('DD.MM')} / `}
        {`${dayjs(timeStart)
          .tz(route.start_warehouse.timezone)
          .format(timeFormat.simple)} \u2014 ${dayjs(timeEnd)
          .tz(
            route.finish_warehouse
              ? route.finish_warehouse.timezone
              : route.start_warehouse.timezone,
          )
          .format(timeFormat.simple)}`}
      </div>
      <div className="routes__late-fast-count">
        {countIsLateTasks > 0 &&
          `${countIsLateTasks} ${translate('lateness', { count: countIsLateTasks })}`}
        {countIsLateTasks > 0 && countIsEarlyTasks > 0 && ', '}
        {countIsEarlyTasks > 0 &&
          `${countIsEarlyTasks} ${translate('outrunning', { count: countIsEarlyTasks })}`}
      </div>
    </>
  ) : null;
};

export default RouteTimeFact;
