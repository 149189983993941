// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line import/named
import { BaseProvider } from 'leaflet-geosearch';

interface IProviderOptions {
  url: string;
  token: string;
}

type BoundsTuple = [[number, number], [number, number]];

interface IRaw {
  code?: number;
  message?: string;
  kind: string;
  lat: number;
  long: number;
  name: string;
  precision: string;
  text: string;
  bounded_by?: {
    lower_lat: number;
    lower_long: number;
    upper_lat: number;
    upper_long: number;
  };
}

interface ISearchResult<IRaw> {
  x: string;
  y: string;
  label: string;
  bounds: BoundsTuple | null;
  raw: IRaw;
}

const MAGIC_DISTANCE_FACTOR = 0.2;

const getCalculatedBounds = (raw: IRaw): BoundsTuple => {
  return raw.kind === 'house'
    ? null
    : [
        [raw.lat - MAGIC_DISTANCE_FACTOR, raw.long - MAGIC_DISTANCE_FACTOR],
        [raw.lat + MAGIC_DISTANCE_FACTOR, raw.long + MAGIC_DISTANCE_FACTOR],
      ];
};

const getBounds = (raw: IRaw): BoundsTuple => [
  [raw.bounded_by.lower_lat, raw.bounded_by.lower_long],
  [raw.bounded_by.upper_lat, raw.bounded_by.upper_long],
];

export default class SearchProviderBMP implements BaseProvider {
  options: IProviderOptions;

  constructor(options: IProviderOptions) {
    this.options = options;
  }

  parse(raw: IRaw): ISearchResult<IRaw>[] {
    if (raw.code !== 404) {
      const bounds: BoundsTuple = raw.bounded_by ? getBounds(raw) : getCalculatedBounds(raw);
      return [
        {
          label: raw.text,
          x: raw.long.toString(),
          y: raw.lat.toString(),
          bounds,
          raw,
        },
      ];
    }
  }

  async search({ query }): Promise<ISearchResult<IRaw>[]> {
    const { token, url } = this.options;

    const request = await fetch(`${url}/api/address?search=${query}`, {
      headers: {
        token,
      },
    });

    const raw: IRaw = await request.json();

    return this.parse(raw);
  }
}
