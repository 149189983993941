import React from 'react';

import { Descriptions } from 'antd';

import { IDelorianState } from 'modules/qa/models/types';

type IProps = {
  entity: {
    delorian_state?: IDelorianState[];
  };
};

const DelorianState = ({ entity }: IProps) => {
  return (
    entity.delorian_state &&
    entity.delorian_state.length > 0 && (
      <>
        <h2>Cостояние записи в сервис Delorian</h2>
        {entity.delorian_state.map((stateItem, index) => (
          <Descriptions
            bordered
            title={`${index + 1}. ${stateItem.job_type}`}
            size="small"
            column={1}
            key={index}
          >
            <Descriptions.Item label="job_type">{stateItem.job_type}</Descriptions.Item>
            <Descriptions.Item label="payload">{stateItem.payload}</Descriptions.Item>
            <Descriptions.Item label="trigger_type">{stateItem.trigger_type}</Descriptions.Item>
            <Descriptions.Item label="trigger">{stateItem.trigger}</Descriptions.Item>
          </Descriptions>
        ))}
      </>
    )
  );
};

export default DelorianState;
