import React from 'react';

import { CheckCircleFilled, CloseCircleFilled, WarningFilled } from '@ant-design/icons';

import { isProblemsCardRoute } from 'modules/routeGenerator/components/right/RouteCard/helpers';
import { IRouteDraft } from 'modules/routeGenerator/models/types';

interface IProps {
  cardData: IRouteDraft;
}

const CheckCourierIcons = ({ cardData }: IProps) => {
  if (cardData.error) {
    return <CloseCircleFilled className="rg-routecard__icon--error" />;
  }
  if (cardData.isCalculated) {
    if (isProblemsCardRoute(cardData)) {
      return <WarningFilled className="rg-routecard__icon--warning" />;
    } else {
      return <CheckCircleFilled className="rg-routecard__icon--ok" />;
    }
  }
  return null;
};

export default CheckCourierIcons;
