import React, { useEffect } from 'react';

import { CloudDownloadOutlined, ExpandOutlined } from '@ant-design/icons';
import { Card, List, Descriptions } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';

import DelorianState from 'modules/qa/common/DelorianState';
import Diff from 'modules/qa/common/Diff';
import { DiffFlag, RedisFlag } from 'modules/qa/common/flags';
import { ITask } from 'modules/qa/models/types';
import Sizes from 'modules/tasks/components/TaskSizes';
import { useStore } from 'services/store';

type IPropsSidebar = {
  task: ITask;
};

const Sidebar = ({ task }: IPropsSidebar) => {
  return (
    <>
      <Descriptions bordered title="Задача" size="small" column={1}>
        <Descriptions.Item label="Задача">{task.guid}</Descriptions.Item>
        {task.number ? <Descriptions.Item label="Номер">{task.number}</Descriptions.Item> : null}
        {task.delivery_date && task.time_slot_start && task.time_slot_end ? (
          <Descriptions.Item label="Таймслот">
            {dayjs(task.delivery_date).format('DD.MM.YY')} / {task.time_slot_start} –{' '}
            {task.time_slot_end}
          </Descriptions.Item>
        ) : null}
        <Descriptions.Item
          label={
            <a target="_blank" rel="noreferrer" href={`/arm2/orders/${task.order_guid}`}>
              Заказ
            </a>
          }
        >
          {task.order_guid}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <a target="_blank" rel="noreferrer" href={`/arm2/routes/${task.route_guid}`}>
              Маршрут
            </a>
          }
        >
          {task.route_guid}
        </Descriptions.Item>
        <Descriptions.Item label="Статус">{task.status}</Descriptions.Item>
        <Descriptions.Item label="Магазин">{task.shop_guid}</Descriptions.Item>
        <Descriptions.Item label="Склад">{task.warehouse_guid}</Descriptions.Item>
        <Descriptions.Item label="Время окончания сборки">
          {task.assembly_end_time}
        </Descriptions.Item>
        <Descriptions.Item label="Способ доставки">{task.delivery_method_guid}</Descriptions.Item>
        <Descriptions.Item label="Блокирующий процесс">{task.locked}</Descriptions.Item>
        <Descriptions.Item label="Время запланированного прибытия">
          {task.planed_arrival}
        </Descriptions.Item>
        <Descriptions.Item label="Координаты">
          {task.point.lat}; {task.point.lon}
        </Descriptions.Item>
        <Descriptions.Item label="Размеры">
          <Sizes task={task} />
        </Descriptions.Item>
        <Descriptions.Item label="Время, когда задачу надо поместить в алгоритм расчета">
          {task.routing_start_time}
        </Descriptions.Item>
        <Descriptions.Item label="Таймслот">
          {task.slot_start} – {task.slot_end}
        </Descriptions.Item>
      </Descriptions>

      <DelorianState entity={task} />

      <p>{task.redis_stored ? <RedisFlag withDescription /> : null}</p>

      <Diff entity={task} />

      <pre>{JSON.stringify(task, null, 2)}</pre>
    </>
  );
};

export const TaskSidebar = observer(Sidebar);

const TaskList = () => {
  const { orderCollectorMonitor } = useStore();

  useEffect(() => {
    orderCollectorMonitor.getTasks({ pageSize: 100, current: 1 });
  }, []);

  return (
    <List
      grid={{ column: 1 }}
      header={<b>Задачи</b>}
      dataSource={orderCollectorMonitor.tasksList}
      renderItem={(task) => {
        const openTask = () => {
          orderCollectorMonitor.setSidebarType('task');
          orderCollectorMonitor.setSidebarData(task);
        };

        return (
          <List.Item>
            <Card
              title={
                <>
                  {task.redis_stored ? <RedisFlag /> : null}
                  {task.difference ? <DiffFlag /> : null}
                </>
              }
              extra={
                <>
                  {!task.number && (
                    <>
                      <a
                        href="#"
                        rel="noreferrer"
                        onClick={() => orderCollectorMonitor.loadTask(task.guid)}
                      >
                        <CloudDownloadOutlined />
                      </a>
                      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </>
                  )}
                  <a href="#" rel="noreferrer" onClick={openTask}>
                    <ExpandOutlined />
                  </a>
                </>
              }
            >
              <p>{task.guid}</p>
              {task.number ? (
                <p>
                  <a target="_blank" rel="noreferrer" href={`/arm2/orders/${task.order_guid}`}>
                    #{task.number}
                  </a>
                </p>
              ) : null}
              {task.delivery_date && task.time_slot_start && task.time_slot_end ? (
                <p>
                  {dayjs(task.delivery_date).format('DD.MM.YY')} / {task.time_slot_start} –{' '}
                  {task.time_slot_end}
                </p>
              ) : null}
            </Card>
          </List.Item>
        );
      }}
    />
  );
};

export default observer(TaskList);
