import './style.less';

import React from 'react';

import { Button, Form, Input } from 'antd';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { observer } from 'mobx-react-lite';

import { getAbbreviatedNameCurrency } from 'helpers/currency';
import { timeFormat } from 'helpers/string';
import { TimePicker } from 'modules/common/containers/TimePicker';
import { translate } from 'modules/localization';
import { IWeekday } from 'modules/schedules/models/uitypes';
import { useStore } from 'services/store';

import { IPresetFormValues, getTimeslotsList } from './helpers';

interface IProps {
  form: FormInstance;
  weekday: IWeekday;
}

const PresetForm = ({ weekday, form }: IProps) => {
  const { schedules, locations, currency } = useStore();

  const setTimeslots = (values: IPresetFormValues): void =>
    schedules.scheduleActionSetTimeslotsForDay(weekday.id, getTimeslotsList(values));

  const handleSubmit = (): void => {
    const validateValuesNames = ['start', 'end', 'interval', 'limit', 'price'];

    form.validateFields(validateValuesNames).then((values) => {
      if (values.interval < 5) {
        form.setFields([
          {
            name: 'interval',
            errors: [translate('minIntervalValidator')],
          },
        ]);
      } else if (values.interval > 1440) {
        form.setFields([
          {
            name: 'interval',
            errors: [translate('maxIntervalValidator')],
          },
        ]);
      } else {
        setTimeslots(values);
      }
    });
  };

  return (
    <div className="schedule-preset">
      <div className="schedule-preset__form">
        <Form.Item
          label={translate('dayStart')}
          name="start"
          rules={[{ required: true, message: translate('enterDayStart') }]}
          className="schedule-preset__from"
        >
          <TimePicker
            placeholder={translate('dayStart')}
            minuteStep={15}
            format={timeFormat.simple}
            allowClear
            showNow={false}
          />
        </Form.Item>
        <Form.Item
          label={translate('dayEnd')}
          name="end"
          rules={[{ required: true, message: translate('enterDayEnd') }]}
          className="schedule-preset__to"
        >
          <TimePicker
            placeholder={translate('dayEnd')}
            minuteStep={15}
            format={timeFormat.simple}
            allowClear
          />
        </Form.Item>
        <Form.Item
          label={translate('interval')}
          name="interval"
          rules={[{ required: true, message: translate('enterInterval') }]}
          className="schedule-preset__interval"
        >
          <Input
            placeholder={translate('interval')}
            addonAfter={translate('timeUnitMinuteShort')}
            type="number"
            min={0}
          />
        </Form.Item>
        <Form.Item
          label={translate('orderLimit')}
          name="limit"
          rules={[
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.resolve();
                }
                return value && value >= 0
                  ? Promise.resolve()
                  : Promise.reject(translate('orderLimitValidator'));
              },
            },
          ]}
          className="schedule-preset__limit"
        >
          <Input placeholder={translate('orderLimit')} type="number" min={0} />
        </Form.Item>
        <Form.Item
          label={translate('price')}
          name="price"
          rules={[
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.resolve();
                }
                return value && value >= 0
                  ? Promise.resolve()
                  : Promise.reject(translate('priceValidator'));
              },
            },
          ]}
          className="schedule-preset__price"
        >
          <Input
            placeholder={translate('price')}
            type="number"
            min={0}
            addonAfter={
              getAbbreviatedNameCurrency(locations.currency, currency.currencyList).symbol
            }
          />
        </Form.Item>

        <div className="schedule-preset__save">
          <Button type="primary" ghost onClick={handleSubmit}>
            {translate('apply')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default observer(PresetForm);
