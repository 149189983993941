import React from 'react';

export const EmptyRouteSvg = () => (
  <svg
    width="152"
    height="152"
    viewBox="0 0 152 152"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103.843 73L111.767 129.488L76 138.221V90.5C84.2967 78.7308 90.5833 75.3308 103.843 73Z"
        fill="#E4E8EC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 81L47.7933 73L40 129L6.08301 136.594L21 81Z"
        fill="#E4E8EC"
      />
      <path
        d="M77.5044 89.3093C77.4436 89.3795 77.3782 89.4453 77.3087 89.5062L78.6266 91.0106L77.3087 89.5063C76.4782 90.2338 75.2214 90.1481 74.4956 89.3093C64.8796 78.1958 57.7225 68.5211 52.9795 60.2745C48.2135 51.9881 46 45.3389 46 40.226C46 23.6445 59.3634 11 76 11C92.6366 11 106 23.6445 106 40.226C106 45.3389 103.786 51.9881 99.0205 60.2745C94.2775 68.5211 87.1204 78.1958 77.5044 89.3093Z"
        stroke="#A3B1BF"
        strokeWidth="4"
      />
      <path
        d="M105.007 81.5737L106.104 88.4975"
        stroke="#A3B1BF"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M107.729 100.125L110.508 122"
        stroke="#A3B1BF"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M117.291 100.651L106.701 93.7612L76.0001 107L57.1086 101L54.9559 115.692L41.792 112L8.95752 121"
        stroke="#A3B1BF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeDasharray="2 4"
      />
      <path d="M45.5868 87L40.9004 122" stroke="#A3B1BF" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M63.3319 76L48.0945 72.1398C47.7339 72.0484 47.3549 72.0594 47.0002 72.1716L19.701 80.8059L4 138.221L39.4361 129.917L75.8346 139L111.745 129.917L147.209 138.221L131.863 80.8059L104.331 72.1842C103.947 72.0642 103.537 72.0624 103.152 72.179L88.2521 76.6996"
        stroke="#A3B1BF"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.7951 36.8421L90.0094 35.456C89.9364 35.3288 89.774 35.2852 89.648 35.3589L87.967 36.35L85.3709 29.1111C85.0891 28.2238 84.2735 27.6245 83.3517 27.6245H68.9855C67.835 27.6245 66.8138 28.3745 66.459 29.4828L63.9856 36.3533L62.3013 35.3622C62.1753 35.2886 62.0129 35.3321 61.9399 35.4593L61.1541 36.8421C61.0812 36.9694 61.1243 37.1301 61.2503 37.2038L63.2529 38.3823L62.7721 39.7216C62.7323 39.8288 62.7125 39.9426 62.7125 40.0564V51.7149C62.7125 52.2406 63.1037 52.6658 63.5844 52.6658H65.8258C66.2336 52.6658 66.5883 52.3544 66.6745 51.9192L66.9298 50.6569H85.0195L85.2748 51.9192C85.3643 52.3544 85.7157 52.6658 86.1235 52.6658H88.3649C88.8456 52.6658 89.2368 52.2406 89.2368 51.7149V40.0564C89.2368 39.9426 89.2169 39.8288 89.1772 39.7216L88.6964 38.3823L90.6957 37.2038C90.8217 37.1301 90.8648 36.9694 90.7951 36.8421ZM67.753 43.7926C67.0203 43.7926 66.4268 43.1933 66.4268 42.4533C66.4268 41.7134 67.0203 41.114 67.753 41.114C68.4858 41.114 69.0793 41.7134 69.0793 42.4533C69.0793 43.1933 68.4858 43.7926 67.753 43.7926ZM80.6174 46.3038C80.6174 46.4511 80.498 46.5716 80.3521 46.5716H71.5991C71.4532 46.5716 71.3338 46.4511 71.3338 46.3038V43.4913C71.3338 43.3439 71.4532 43.2234 71.5991 43.2234H72.9253C73.0712 43.2234 73.1905 43.3439 73.1905 43.4913V44.6966H78.7607V43.4913C78.7607 43.3439 78.88 43.2234 79.0259 43.2234H80.3521C80.498 43.2234 80.6174 43.3439 80.6174 43.4913V46.3038ZM84.1982 43.7926C83.4654 43.7926 82.8719 43.1933 82.8719 42.4533C82.8719 41.7134 83.4654 41.114 84.1982 41.114C84.9309 41.114 85.5244 41.7134 85.5244 42.4533C85.5244 43.1933 84.9309 43.7926 84.1982 43.7926ZM66.2942 36.9957L68.7046 30.3026L68.7212 30.2591L68.7344 30.2156C68.7709 30.1051 68.8704 30.0314 68.9864 30.0314H83.1637L85.6636 36.9957H66.2942Z"
        fill="#A3B1BF"
      />
    </g>
  </svg>
);
