import { makeAutoObservable } from 'mobx';

import { RestException } from 'api/RestException';
import { IPagination } from 'api/types';
import { getLastPagePagination, isOutOfPages } from 'helpers/pagination';
import ClustersApi from 'modules/clusters/api/ClustersApi';
import CouriersApi from 'modules/couriers/api/CouriersApi';
import {
  ICluster,
  ICourier,
  ICourierSession,
  IOrder,
  IRoute,
  ITask,
  ITransport,
  IWarehouse,
} from 'modules/qa/models/types';
import RoutesApi from 'modules/routes/api/RoutesApi';

import CourierCollectorMonitorApi from '../api/CourierCollectorMonitorApi';

export class CourierCollectorMonitorStore {
  tasksList: ITask[] = [];
  clustersList: ICluster[] = [];
  couriersList: ICourier[] = [];
  courierSessionsList: ICourierSession[] = [];
  ordersList: IOrder[] = [];
  routesList: IRoute[] = [];
  transportsList: ITransport[] = [];
  warehousesList: IWarehouse[] = [];

  sidebarType: string = null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sidebarData: any = null;

  isClearPending = false;

  constructor() {
    makeAutoObservable(this);
  }

  getClusters = async (pagination: IPagination): Promise<ICluster[]> => {
    try {
      const { data: res } = await CourierCollectorMonitorApi.getClusters(pagination);
      if (isOutOfPages(res.pagination)) {
        return this.getClusters(getLastPagePagination(res.pagination));
      }
      this.clustersList = res.data;
      return res.data;
    } catch (e) {
      throw new RestException(e);
    }
  };

  loadCluster = async (guid: string): Promise<void> => {
    try {
      const { data: res } = await ClustersApi.getCluster(guid);
      const entity = res.data;
      this.clustersList = this.clustersList.map((e) => {
        if (e.guid === guid) {
          return { ...entity, ...e };
        }
        return e;
      });
    } catch (e) {
      throw new RestException(e);
    }
  };

  getCouriesSessions = async (pagination: IPagination): Promise<ICourierSession[]> => {
    try {
      const { data: res } = await CourierCollectorMonitorApi.getCoiriersSessions(pagination);
      if (isOutOfPages(res.pagination)) {
        return this.getCouriesSessions(getLastPagePagination(res.pagination));
      }
      this.courierSessionsList = res.data;
      return res.data;
    } catch (e) {
      throw new RestException(e);
    }
  };

  loadCourierForSession = async (guid: string): Promise<void> => {
    try {
      const { data: res } = await CouriersApi.getCourier(guid);
      const courier = res.data;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.courierSessionsList = this.courierSessionsList.map((e) => {
        if (e.courier_guid === guid) {
          return { ...e, courier: courier };
        }
        return e;
      });
    } catch (e) {
      throw new RestException(e);
    }
  };

  getRoutes = async (pagination: IPagination): Promise<IRoute[]> => {
    try {
      const { data: res } = await CourierCollectorMonitorApi.getRoutes(pagination);
      if (isOutOfPages(res.pagination)) {
        return this.getRoutes(getLastPagePagination(res.pagination));
      }
      this.routesList = res.data;
      return res.data;
    } catch (e) {
      throw new RestException(e);
    }
  };

  loadRoute = async (guid: string): Promise<void> => {
    try {
      const { data: res } = await RoutesApi.getRoute(guid);
      const entity = res.data;
      this.routesList = this.routesList.map((e) => {
        if (e.guid === guid) {
          return { ...entity, ...e };
        }
        return e;
      });
    } catch (e) {
      throw new RestException(e);
    }
  };

  setSidebarType = (type: string): void => {
    this.sidebarType = type;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSidebarData = (data: any): void => {
    this.sidebarData = data;
  };

  clearSoft = async (): Promise<void> => {
    this.isClearPending = true;
    await CourierCollectorMonitorApi.clearSoft();
    this.isClearPending = false;
  };

  clearHard = async (): Promise<void> => {
    this.isClearPending = true;
    await CourierCollectorMonitorApi.clearHard();
    this.isClearPending = false;
  };
}
