import { IPagination } from 'api/types';

// TODO: избавиться от этого
export const getUrlPagination = (pagination = {}): IPagination => {
  const url = new URL(window.location.href);
  const getPageSize = (): number => {
    const urlPageSize = +url.searchParams.get('page_size');
    if (!urlPageSize) {
      return 50;
    }
    if (urlPageSize > 100) {
      return 100;
    }

    if (urlPageSize < 5) {
      return 5;
    }

    return urlPageSize;
  };

  const getPageNumber = (): number => {
    const urlPageNumber = +url.searchParams.get('page');
    if (!urlPageNumber) {
      return 1;
    }
    if (urlPageNumber < 1) {
      return 1;
    }
    return urlPageNumber;
  };

  return {
    ...pagination,
    current: getPageNumber(),
    pageSize: getPageSize(),
  };
};

export const getQueryString = (obj): string => {
  const str = [];
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key) && obj[key]) {
      str.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
    }
  }
  return str.join('&');
};
