import React from 'react';

import { Progress } from 'antd';
import { observer } from 'mobx-react-lite';

import { getAbbreviatedNameCurrency } from 'helpers/currency';
import { translate } from 'modules/localization';
import { IRouteDraft } from 'modules/routeGenerator/models/types';
import {
  getTasksListAmount,
  getTasksListVolume,
  getTasksListWeight,
} from 'modules/tasks/helpers/lists';
import { useStore } from 'services/store';

interface IProps {
  cardData: IRouteDraft;
}

const FullnessProgress = ({ cardData }: IProps) => {
  const { routeGenerator, currency } = useStore();

  const tasksAmount = getTasksListAmount(cardData.tasksList);
  const tasksWeight = getTasksListWeight(cardData.tasksList);
  const tasksVolume = getTasksListVolume(cardData.tasksList);

  const weightPercent = cardData.transport ? (tasksWeight * 100) / cardData.transport.weight : 0;
  const volumePercent = cardData.transport ? (tasksVolume * 100) / cardData.transport.volume : 0;

  return (
    <div className="rg-routecard__progress">
      <div className="rg-routecard__progress-item">
        <div className={weightPercent > 100 ? 'rg-routecard__progress-overload' : ''}>
          {tasksWeight.toFixed(2)}&nbsp;
          <span>{translate('weightUnit')}</span>
        </div>
        {cardData.transportGuid && (
          <Progress
            className="rg-routecard__progress-line"
            percent={weightPercent}
            showInfo={false}
            size="small"
            strokeColor={weightPercent < 100 ? '#00CC66' : '#F5222D'}
            trailColor="#E3E3E3"
            strokeWidth={2}
          />
        )}
      </div>
      <div className="rg-routecard__progress-item">
        <div className={volumePercent > 100 ? 'rg-routecard__progress-overload' : ''}>
          {tasksVolume.toFixed(3)}&nbsp;
          <span>
            {translate('lengthUnit')}
            <sup>3</sup>
          </span>
        </div>
        {cardData.transportGuid && (
          <Progress
            className="rg-routecard__progress-line"
            percent={volumePercent}
            showInfo={false}
            strokeColor={volumePercent < 100 ? '#00CC66' : '#F5222D'}
            trailColor="#E3E3E3"
            strokeWidth={2}
          />
        )}
      </div>
      <div className="rg-routecard__progress-item rg-routecard__progress-item--currency">
        {tasksAmount.toLocaleString('ru-RU', {
          minimumFractionDigits: 2,
        })}
        &nbsp;
        <div className="rg-routecard__currency-icon">
          {getAbbreviatedNameCurrency(routeGenerator.currency, currency.currencyList).symbol}
        </div>
      </div>
    </div>
  );
};

export default observer(FullnessProgress);
