import './style.less';

import React from 'react';

import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { GEO_BASE_URL } from 'constants/index';
import icon from 'img/empty.svg?react';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

interface IParams {
  locationGuid: string;
}

const ServiceZonesEmptyList = () => {
  const { core } = useStore();

  const { locationGuid } = useParams<IParams>();

  return (
    <div className="empty-list">
      <img alt="" className="empty-list__icon" src={`${icon}`} />
      <div className="empty-list__description">{translate('thereAreNoZones')}</div>
      <div className="empty-list__action">
        {core.permissions['service-zones.create'] && (
          <Link to={`${GEO_BASE_URL}/${locationGuid}/service-zones/create`}>
            <Button type="primary">{translate('createServiceZone')}</Button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default observer(ServiceZonesEmptyList);
