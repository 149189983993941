import './style.less';

import React, { useState } from 'react';

import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { useDrop } from 'react-dnd';

import classSet from 'helpers/classSet';
import { translate } from 'modules/localization';
import Tabs from 'modules/routeGenerator/components/common/Tabs';
import TaskList from 'modules/routeGenerator/components/common/TaskList';
import TaskListCounter from 'modules/routeGenerator/components/common/TaskListCounter';
import TaskListSizes from 'modules/routeGenerator/components/common/TaskListSizes';
import Filter from 'modules/routeGenerator/components/left/Filter';
import Map from 'modules/routeGenerator/components/left/Map';
import SelectedTaskActions from 'modules/routeGenerator/components/left/SelectedTaskActions';
import { highlight } from 'modules/routeGenerator/models/utils';
import { DndTypes, IDndItem } from 'modules/routeGenerator/stores/RouteGeneratorDnDStore';
import { useStore } from 'services/store';

interface IDndProps {
  hovered: boolean;
}

const GeneratorLeftSide = () => {
  const { routeGenerator } = useStore();

  const [{ hovered }, drop] = useDrop<IDndItem, void, IDndProps>(() => ({
    accept: [DndTypes.ROUTE, DndTypes.RIGHT_TASK, DndTypes.RIGHT_COVERAGE],
    drop: (item, monitor) =>
      routeGenerator.dndStore.onDropToLeftSide(item.id, monitor.getItemType()),
    collect: (monitor) => {
      return {
        hovered: monitor.isOver(),
      };
    },
  }));

  const taskList = highlight(routeGenerator.taskListSource, routeGenerator.highlightedTasks);
  const taskListProps = {
    isLeft: !routeGenerator.isGroupedByCoverage,
    isLeftGrouped: routeGenerator.isGroupedByCoverage,
  };

  const [isMap, setIsMap] = useState(false);
  const [isFullScreenMap, setIsFullScreenMap] = useState(false);

  return (
    <div
      className={classSet({
        'rg-leftside': true,
        'rg-leftside--fullscreen': isFullScreenMap,
      })}
    >
      {!isFullScreenMap && (
        <div className="rg-leftside__header">
          <div className="rg-leftside__controls">
            <Tabs isMap={isMap} setIsMap={setIsMap} />
            <Filter />
          </div>
          <div className="rg-leftside__stat">
            <TaskListCounter taskList={taskList} />
            <TaskListSizes taskList={taskList} currencyCode={routeGenerator.currency} />
          </div>
          <SelectedTaskActions />
        </div>
      )}
      {isFullScreenMap ? (
        <div className="rg-leftside__header rg-leftside__header--fullscreen">
          <div className="rg-leftside__title">{translate('fullScreenMode')}</div>
          <SelectedTaskActions />
        </div>
      ) : null}
      <div
        ref={drop}
        className={classSet({
          'rg-leftside__body': true,
          'rg-leftside__body--hovered': hovered,
        })}
      >
        <Spin spinning={routeGenerator.isLoadingTasksList} wrapperClassName="rg__spin--fill">
          {isMap ? (
            <Map
              isFullScreenMap={isFullScreenMap}
              setIsFullScreenMap={setIsFullScreenMap}
              taskList={taskList}
            />
          ) : (
            <div className="rg-leftside__listview">
              <TaskList taskList={taskList} {...taskListProps} />
            </div>
          )}
        </Spin>
      </div>
    </div>
  );
};

export default observer(GeneratorLeftSide);
