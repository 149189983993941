import React, { ReactNode, forwardRef, useEffect } from 'react';

import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { observer } from 'mobx-react-lite';
import { BaseSelectRef } from 'rc-select/lib/BaseSelect';

import { selectFilter } from 'helpers/form';
import { DEFAULT_SELECT_PROPS } from 'modules/common/components/Select';
import { translate } from 'modules/localization';
import { IShop } from 'modules/shops/models/types';
import { useStore } from 'services/store';

const ShopsSelect = forwardRef<BaseSelectRef, SelectProps>(({ onChange, ...rest }, ref) => {
  const { shops } = useStore();

  const getDefaultOptions = () => {
    const options = shops.listAll;

    if (options && Array.isArray(options)) {
      return options;
    }
    return [];
  };

  const fetchDefaultOptions = async () => {
    if (shops.listAll.length === 0) {
      await shops.getAll();
    }
  };

  useEffect(() => {
    fetchDefaultOptions();
  }, []);

  const onChangeValue = (value, option): void => {
    onChange(value, option);
  };

  const renderOption = (option: IShop): ReactNode => {
    if (option) {
      return (
        <Select.Option key={option.legal_name} value={option.guid}>
          {option.legal_name}
        </Select.Option>
      );
    }
    return null;
  };

  const options = getDefaultOptions();

  return (
    <Select
      {...rest}
      ref={ref}
      allowClear
      loading={shops.isLoadingList}
      filterOption={selectFilter}
      getPopupContainer={(el) => el}
      onChange={onChangeValue}
      placeholder={translate('enterShops')}
      {...DEFAULT_SELECT_PROPS}
    >
      {options.map(renderOption)}
    </Select>
  );
});

export default observer(ShopsSelect);
