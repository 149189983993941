import './style.less';

import React, { useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import { Route, Switch } from 'react-router-dom';

import { GEO_BASE_URL } from 'constants/index';
import LocationsForm from 'modules/locations/components/LocationsForm';
import LocationsView from 'modules/locations/components/LocationsView';
import { useStore } from 'services/store';

const LocationsPage = () => {
  const { locations, router } = useStore();

  useEffect(() => {
    if (router.location.pathname === GEO_BASE_URL) {
      locations.resetFormData();
    }
  }, [router.location.pathname]);

  return (
    <Switch>
      <Route
        exact
        path={`${GEO_BASE_URL}/:locationGuid/edit`}
        render={(): React.ReactNode => <LocationsForm baseUrl={GEO_BASE_URL} actionName="edit" />}
      />
      <Route
        exact
        path={`${GEO_BASE_URL}/create`}
        render={(): React.ReactNode => <LocationsForm baseUrl={GEO_BASE_URL} actionName="create" />}
      />
      <Route path={`${GEO_BASE_URL}/:locationGuid/:section/:action`} component={LocationsView} />
      <Route path={`${GEO_BASE_URL}/:locationGuid/:section`} component={LocationsView} />
      <Route path={GEO_BASE_URL} component={LocationsView} />
    </Switch>
  );
};

export default observer(LocationsPage);
