import React from 'react';

import { Tag } from 'antd';

import { IDeliveryMethods } from 'modules/delivery-methods/models/types';
import { translate } from 'modules/localization';

interface IProps {
  deliveryMethods: IDeliveryMethods[];
}

const DeliveryMethodsTags = ({ deliveryMethods }: IProps) => {
  if (deliveryMethods && Array.isArray(deliveryMethods)) {
    return (
      <div className="rg-courier-modal__tags">
        <div className="rg-courier-modal__tags-title">{translate('deliveryMethods')}</div>
        <div>
          {deliveryMethods.map((item) => (
            <Tag key={item.guid} className="rg-courier-modal__tag" color="rgba(0, 0, 0, 0.04)">
              {item.name || item.guid} {item.disabled ? ` (${translate('disabled')})` : ''}
            </Tag>
          ))}
        </div>
      </div>
    );
  }
  return null;
};

export default DeliveryMethodsTags;
