import React from 'react';

import { observer } from 'mobx-react-lite';

import ListMobX from 'modules/directory/containers/List/List';
import { useStore } from 'services/store';

import { getColumns } from './helpers';

interface IProps {
  baseUrl: string;
}

const TransportsList = ({ baseUrl }: IProps) => {
  const { transport } = useStore();

  return (
    <ListMobX
      baseUrl={baseUrl}
      actionListGet={transport.getList}
      actionSearch={transport.search}
      list={transport.list}
      pagination={transport.pagination}
      getColumns={getColumns}
      isLoading={transport.isLoadingList}
      actionDelete={transport.delete}
    />
  );
};

export default observer(TransportsList);
