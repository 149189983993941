import { ISession } from 'modules/couriers/models/types';
import { IRouteDraft } from 'modules/routeGenerator/models/types';
import { getTasksListWeight } from 'modules/tasks/helpers/lists';

export const getSessionByDate = (list: ISession[], _date: string): ISession => {
  return list.find(
    (s) =>
      s.planned_date === _date &&
      ['planned', 'processes', 'awaiting_courier', 'in_process'].includes(s.status),
  );
};

export const isProblemsCardRoute = (cardData: IRouteDraft): boolean => {
  const tasksWeight = getTasksListWeight(cardData.tasksList);
  const weightPercent = cardData.transport ? (tasksWeight * 100) / cardData.transport.weight : 0;

  if (weightPercent > 100 && weightPercent > 100) {
    return true;
  }
  for (let i = 0; i < cardData.tasksList.length; i++) {
    if (cardData.tasksList[i].problems && cardData.tasksList[i].problems.length > 0) {
      return true;
    }
  }
  return false;
};
