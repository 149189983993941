import './style.less';

import React, { ReactNode, useEffect } from 'react';

import { observer } from 'mobx-react-lite';

import Navigation from 'modules/arm2/components/Navigation';
import CouriersHeader from 'modules/couriers/components/arm/CouriersHeader';
import { useStore } from 'services/store';

import CouriersList from '../CouriersList';

const CouriersPage = () => {
  const { couriers, currency } = useStore();

  useEffect(() => {
    couriers.apiStore.getSessionStatuses();
    currency.getCurrency();
  }, []);

  const renderList = (): ReactNode => {
    return (
      <div className="couriers__list">
        <CouriersList />
      </div>
    );
  };

  return (
    <div className="couriers">
      <div className="couriers__content">
        <Navigation />
        <CouriersHeader />
        {renderList()}
      </div>
    </div>
  );
};

export default observer(CouriersPage);
