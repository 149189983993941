import {
  ICourier,
  ICouriersWithSessions,
  ISession,
  ISessionForLoading,
} from 'modules/couriers/models/types';
import { translate } from 'modules/localization';
import { IRoute } from 'modules/routes/models/types';

export type ISelectedRoute = {
  courierGuid: string;
  sessionGuid: string;
  sessionStatus: string;
  routeGuid: string;
};

export const getFullCouriersData = (
  couriersList: ICourier[],
  sessionsList: ISession[],
): ICouriersWithSessions[] =>
  couriersList.map(extendCourierBySessions(sessionsList)).filter(isSessionForLoadingExist);

const extendCourierBySessions =
  (sessionsList: ISession[]) =>
  (c: ICourier): ICouriersWithSessions => ({
    ...c,
    sessionForLoading: sessionsList
      .filter(isSessionFitsByCourierAndStatus(c.guid))
      .map(extendSessionByWarningsAndPlannedRoutes(c.processed_session))
      .filter(isRoutesInSession)[0],
  });

const isSessionForLoadingExist = (c: ICouriersWithSessions) => !!c.sessionForLoading;

const isSessionFitsByCourierAndStatus = (courierGuid: string) => (s: ISession) =>
  s.courier_guid === courierGuid &&
  (s.status === 'awaiting_courier' || s.status === 'in_process' || s.status === 'planned');

const isRoutesInSession = (s: ISessionForLoading) =>
  (s.routes && s.routes.length > 0) || !!s.routeWarning;

const extendSessionByWarningsAndPlannedRoutes =
  (processedSession: ISession) =>
  (s: ISession): ISessionForLoading => ({
    ...s,
    routeWarning: getRouteWarning(s.routes),
    routes: getPlannedRoutes(s.routes),
    sessionWarning: getSessionWarning(getPlannedRoutes(s.routes), processedSession),
  });

const getPlannedRoutes = (routes = []) => routes.filter((r) => r.status === 'planned');

const getRouteWarning = (routes = []) => {
  const isProcessedExist =
    routes.filter((r) => r.status === 'at_loading' || r.status === 'in_process').length > 0;
  const isPlannedRoutesExist = getPlannedRoutes(routes).length > 0;
  return isProcessedExist && isPlannedRoutesExist ? translate('courierHasProcessingRoute') : null;
};

const getSessionWarning = (routes: IRoute[] = [], processedSession: ISession = undefined) => {
  if (processedSession && routes.some((r) => r.session_guid !== processedSession.guid)) {
    return translate('courierHasProcessingSession');
  }
  return null;
};

export const getWarning = (c: ICouriersWithSessions): string | null =>
  c.sessionForLoading.routeWarning || c.sessionForLoading.sessionWarning;

export const getDefaultSelectedRoutes = (couriers: ICouriersWithSessions[]): ISelectedRoute[] =>
  couriers.filter((c) => !getWarning(c)).map(getDefaultSelectedRoute);

export const getAllRoutes = (couriers: ICouriersWithSessions[]): ISelectedRoute[] =>
  couriers
    .filter((c) => !getWarning(c))
    .reduce((acc, item) => {
      return [...acc, ...getRoutes(item)];
    }, []);

export const isInclude = (allRoutes: ISelectedRoute[], routes: ISelectedRoute[]): boolean => {
  return routes.every((item) => allRoutes.some((i) => i.routeGuid === item.routeGuid));
};

export const getDefaultSelectedRoute = (c: ICouriersWithSessions): ISelectedRoute => ({
  courierGuid: c.guid,
  sessionGuid: c.sessionForLoading.guid,
  sessionStatus: c.sessionForLoading.status,
  routeGuid: c.sessionForLoading.routes[0].guid,
});

export const getRoutes = (c: ICouriersWithSessions): ISelectedRoute[] => {
  return c.sessionForLoading.routes.map((r) => ({
    courierGuid: c.guid,
    sessionGuid: c.sessionForLoading.guid,
    sessionStatus: c.sessionForLoading.status,
    routeGuid: r.guid,
  }));
};
