import './style.less';

import React, { PropsWithChildren } from 'react';

import { Button } from 'antd';
import { Link } from 'react-router-dom';

import { translate } from 'modules/localization';

interface IProps {
  baseUrl: string;
  isPermitted: boolean;
}

const ButtonCreate = ({ baseUrl, isPermitted, children }: IProps & PropsWithChildren) => {
  return isPermitted ? (
    <Link to={`${baseUrl}/create`}>
      <Button type="primary" ghost size="small">
        {children || translate('add')}
      </Button>
    </Link>
  ) : null;
};

export default ButtonCreate;
