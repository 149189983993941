import React, { ReactNode } from 'react';

import { Route, Switch } from 'react-router-dom';

import TasksPage from '../TasksPage';

const Tasks = () => {
  const baseUrl = '/arm2/tasks';

  const renderTasksPage = (): ReactNode => <TasksPage />;

  return (
    <Switch>
      <Route exact path={baseUrl} render={renderTasksPage} />
    </Switch>
  );
};

export default Tasks;
