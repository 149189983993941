import React, { useEffect, useState } from 'react';

import { Form, FormInstance, Input, Select } from 'antd';

import CitiesSelect from 'modules/cities/components/CitiesSelect';
import CouriersSelect from 'modules/couriers/components/directory/CouriersSelect';
import { translate } from 'modules/localization';
import ShopsSelect from 'modules/shops/components/ShopsSelect';
import WarehousesSelect from 'modules/warehouses/components/WarehousesSelect';

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: FormInstance;
  isEdit: boolean;
}

const ClusterInformationForm = ({ form, isEdit }: IProps) => {
  const [warehousesGuid, setWarehousesGuid] = useState<string[]>(null);
  const deliverySettingsListChangeTrigger = Form.useWatch('warehouseGuids', form);

  useEffect(() => {
    const guids = form.getFieldValue(['warehouseGuids']);
    setWarehousesGuid(guids);
  }, [deliverySettingsListChangeTrigger]);

  return (
    <div>
      {!isEdit && <h2 className="cluster-form-h2">{translate('fieldPlaceholder')}</h2>}
      <Form.Item
        className="cluster-form-item"
        label={translate('cluster_mode')}
        name="mode"
        rules={[{ required: true, message: translate('requiredValidator') }]}
        required
      >
        <Select placeholder={translate('cluster_mode')}>
          <Select.Option key="fast_mine" value="fast_mine">
            {translate('fast_mine_mode')}
          </Select.Option>
          <Select.Option key="courier_council" value="courier_council">
            {translate('courier_council_mode')}
          </Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        className="cluster-form-item"
        label={translate('locality')}
        name="city_guid"
        rules={[{ required: true, message: translate('requiredValidator') }]}
        required
      >
        <CitiesSelect disabled={isEdit} />
      </Form.Item>
      <Form.Item
        className="cluster-form-item"
        label={translate('warehouses')}
        name="warehouseGuids"
        rules={[{ required: true, message: translate('requiredValidator') }]}
        required
      >
        <WarehousesSelect maxTagCount={'responsive'} />
      </Form.Item>
      <Form.Item
        className="cluster-form-item"
        label={translate('title')}
        name="title"
        rules={[{ required: true, message: translate('requiredValidator') }]}
        required
      >
        <Input placeholder={translate('enterClusterName')} />
      </Form.Item>
      <Form.Item
        className="cluster-form-item"
        label={translate('couriers')}
        name="profile_guid_list"
        rules={[{ required: true, message: translate('requiredValidator') }]}
        required
      >
        <CouriersSelect
          mode="multiple"
          maxTagCount={'responsive'}
          warehouses={warehousesGuid}
          disabled={!warehousesGuid || warehousesGuid.length === 0}
          isSelectProfile
        />
      </Form.Item>
      <Form.Item
        className="cluster-form-item"
        label={translate('shops')}
        name="shopGuid"
        rules={[{ required: true, message: translate('requiredValidator') }]}
        required
      >
        <ShopsSelect disabled={isEdit} />
      </Form.Item>
    </div>
  );
};

export default ClusterInformationForm;
