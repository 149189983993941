import './style.less';

import React from 'react';

import { Button } from 'antd';
import { observer } from 'mobx-react-lite';

import Title from 'modules/common/components/Title';
import { useStore } from 'services/store';

import ClusterItemsList from '../ClusterItemsList';
import ClusterList from '../ClusterList';
import OrderList from '../OrderList';
import Sidebar from '../Sidebar';
import TaskList from '../TaskList';

const OrderCollectorMonitor = () => {
  const { orderCollectorMonitor } = useStore();

  return (
    <div className="fastmine">
      <Sidebar />
      <div className="fastmine__header">
        <Title size={Title.SIZE.H3} weight={Title.WEIGHT.SEMIBOLD}>
          Мониторинг order collector
        </Title>
        <div className="fastmine__actions">
          <Button
            onClick={orderCollectorMonitor.clearSoft}
            type="primary"
            disabled={orderCollectorMonitor.isClearPending}
          >
            Clear soft
          </Button>
          <Button
            onClick={orderCollectorMonitor.clearHard}
            danger
            disabled={orderCollectorMonitor.isClearPending}
          >
            Clear hard
          </Button>
        </div>
      </div>
      <div className="fastmine__list list">
        <ClusterList />
        <ClusterItemsList />
        <TaskList />
        <OrderList />
      </div>
    </div>
  );
};

export default observer(OrderCollectorMonitor);
