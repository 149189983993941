import React, { useEffect } from 'react';

import { ExpandOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { Card, Descriptions, List } from 'antd';
import { observer } from 'mobx-react-lite';

import DelorianState from 'modules/qa/common/DelorianState';
import Diff from 'modules/qa/common/Diff';
import { DiffFlag, RedisFlag } from 'modules/qa/common/flags';
import { ICourierSession } from 'modules/qa/models/types';
import { useStore } from 'services/store';

type IPropsSidebar = {
  session: ICourierSession;
};

const Sidebar = ({ session }: IPropsSidebar) => {
  return (
    <>
      <Descriptions bordered title="Смена" size="small" column={1}>
        <Descriptions.Item label="Смена">{session.guid}</Descriptions.Item>
        <Descriptions.Item label="Курьер">{session.courier_guid}</Descriptions.Item>
        <Descriptions.Item label="Блокирующий процесс">{session.locked}</Descriptions.Item>
        <Descriptions.Item label="Статус">{session.status}</Descriptions.Item>
      </Descriptions>

      <DelorianState entity={session} />

      <p>{session.redis_stored ? <RedisFlag withDescription /> : null}</p>

      <Diff entity={session} />

      <pre>{JSON.stringify(session, null, 2)}</pre>
    </>
  );
};

export const SessionSidebar = Sidebar;

const CourierSessionList = () => {
  const { courierCollectorMonitor } = useStore();

  useEffect(() => {
    courierCollectorMonitor.getCouriesSessions({ pageSize: 100, current: 1 });
  }, []);

  return (
    <List
      grid={{ column: 1 }}
      header={<b>Смены</b>}
      dataSource={courierCollectorMonitor.courierSessionsList}
      renderItem={(session) => {
        const openSession = () => {
          courierCollectorMonitor.setSidebarType('session');
          courierCollectorMonitor.setSidebarData(session);
        };
        return (
          <List.Item>
            <Card
              title={
                <>
                  {session.redis_stored ? <RedisFlag /> : null}
                  {session.difference ? <DiffFlag /> : null}
                </>
              }
              extra={
                <>
                  {!session.courier && (
                    <>
                      <a
                        href="#"
                        rel="noreferrer"
                        onClick={() =>
                          courierCollectorMonitor.loadCourierForSession(session.courier_guid)
                        }
                      >
                        <CloudDownloadOutlined />
                      </a>
                      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </>
                  )}
                  <a href="#" rel="noreferrer" onClick={openSession}>
                    <ExpandOutlined />
                  </a>
                </>
              }
            >
              {session.guid}
              {session.courier ? (
                <p>
                  <b>{`${session.courier?.profile?.surname} ${session.courier?.profile?.name} ${
                    session.courier?.profile?.patronymic ? session.courier?.profile?.patronymic : ''
                  }`}</b>
                </p>
              ) : null}
            </Card>
          </List.Item>
        );
      }}
    />
  );
};

export default observer(CourierSessionList);
