import React from 'react';

import { observer } from 'mobx-react-lite';

import WarehousesSelect from 'modules/warehouses/components/WarehousesSelect';
import { useStore } from 'services/store';

interface IProps {
  className?: string;
  onChange: (value: string[]) => void;
  value: string[];
}

export const DEFAULT_WAREHOUSE = '3b8fd273-915c-11e2-91a6-001b24936c9e'; // Склад Томск

const WarehousesFilter = ({ className, value, onChange }: IProps) => {
  const { core } = useStore();

  const userAvailableWarehouses = core?.accountData?.warehouses;
  const defaultValue =
    userAvailableWarehouses && userAvailableWarehouses.length > 0
      ? userAvailableWarehouses[0].guid
      : DEFAULT_WAREHOUSE; // Склад Томск

  const handleChange = (val): void => {
    const newValue =
      val.length > 0 ? val : Array.isArray(defaultValue) ? defaultValue : [defaultValue];
    onChange(newValue);
  };

  return (
    <div className={className}>
      <WarehousesSelect
        onChange={handleChange}
        value={value}
        popupMatchSelectWidth={false}
        maxTagCount={3}
      />
    </div>
  );
};

export default observer(WarehousesFilter);
