import './style.less';

import React, { useEffect, useRef } from 'react';

import { Table } from 'antd';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';

import { getUrlPagination } from 'helpers/url';
import { getRowHandler } from 'modules/directory/helpers';
import { useStore } from 'services/store';

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actionListGet: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actionSearch?: any;
  baseUrl: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  list: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pagination: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getColumns: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actionDelete: any;
  isLoading?: boolean;
}

const List = (props: IProps) => {
  const { core } = useStore();

  const {
    baseUrl,
    actionListGet,
    actionSearch,
    list,
    pagination,
    getColumns,
    isLoading,
    actionDelete,
  } = props;
  const location = useLocation<Record<string, never>>();

  const updateList = (pagination) => {
    const url = new URL(window.location.href);
    const query = url.searchParams.get('q');

    query && actionSearch ? actionSearch(query, pagination) : actionListGet(pagination);
  };

  // componentDidMount
  useEffect(() => {
    updateList(getUrlPagination(pagination));
  }, []);

  // render
  const columns = getColumns(baseUrl, core.permissions, actionDelete);

  const listRef = useRef(null);
  const scrollIntoView = () => listRef.current.nativeElement.scrollIntoView({ behavior: 'smooth' });

  return (
    <Table
      ref={listRef}
      className="dir__list"
      dataSource={list}
      columns={columns}
      bordered={false}
      pagination={{
        ...pagination,
        simple: core.isMobile,
        showSizeChanger: true,
        onChange: scrollIntoView,
      }}
      rowKey="guid"
      onChange={updateList}
      onRow={getRowHandler(baseUrl, location)}
      loading={isLoading}
    />
  );
};

export default observer(List);
