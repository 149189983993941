import { useEffect } from 'react';

import L from 'leaflet';
import { observer } from 'mobx-react-lite';
import { useMap } from 'react-leaflet';

import { ICoverage } from 'modules/coverages/models/types';
import { useStore } from 'services/store';

interface IProps {
  coverage: ICoverage;
  onOver?: () => void;
  onOut?: () => void;
  baseUrl?: string;
  isLink?: boolean;
  isHovered?: boolean;
  isActive?: boolean;
  isAdjacent?: boolean;
}

const COLOR = {
  HOVERED: '#00cc66',
  ACTIVE: '#00a658',
  DEFAULT: 'gray',
  ADJACENT: '#FFC53D',
};

const geoJSONOptions = {
  pmIgnore: true,
  snapIgnore: false,
  color: 'grey',
  attribution: 'coverageItem',
};

const CoveragesItem = ({
  isHovered,
  isActive,
  isAdjacent,
  coverage,
  onOver,
  onOut,
  baseUrl,
  isLink,
}: IProps) => {
  const { router } = useStore();

  let layer = null;

  const map = useMap();

  const clickHandler = (): void => router.push(`${baseUrl}/${coverage.guid}/edit`);

  const getColor = () => {
    if (isAdjacent) {
      return COLOR.ADJACENT;
    }
    if (isHovered) {
      return COLOR.HOVERED;
    }
    if (isActive) {
      return COLOR.ACTIVE;
    }
    return COLOR.DEFAULT;
  };

  const createLayer = () => {
    layer = L.geoJSON(coverage.polygon, geoJSONOptions);
    onOver && layer.on('mouseover', onOver);
    onOut && layer.on('mouseout', onOut);
    isLink && baseUrl && layer.on('click', clickHandler);

    layer && layer.setStyle({ color: getColor() });
    map && map.addLayer(layer);
  };

  const deleteLayer = () => {
    if (layer) {
      layer.off();
      layer.remove();
      map && map.removeLayer(layer);
    }
  };

  useEffect(() => {
    deleteLayer();
    createLayer();

    return () => deleteLayer();
  }, [coverage, isHovered]);

  return null;
};

export default observer(CoveragesItem);
