import './style.less';

import React, { PropsWithChildren } from 'react';

import { Link } from 'react-router-dom';

import { translate } from 'modules/localization';

interface IProps {
  baseUrl: string;
  isPermitted: boolean;
}

const ButtonCreate = ({ baseUrl, isPermitted, children }: IProps & PropsWithChildren) => {
  return isPermitted ? (
    <Link className="create-link" to={`${baseUrl}/create`}>
      {children || translate('add')}
    </Link>
  ) : null;
};

export default ButtonCreate;
