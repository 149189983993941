import 'style/normalize.less';
import 'style/antd.less';
import '@fontsource/roboto';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/300.css';
import '@fontsource/raleway/600.css';

import React from 'react';

import LogRocket from 'logrocket';
import 'modules/localization';
import { createRoot } from 'react-dom/client';

import App from './App';

const container: HTMLElement | null = document.getElementById('root');

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (import.meta.env.MODE !== 'development') {
  LogRocket.init('vvvlq0/delmag');
}

createRoot(container as Element).render(<App />);
