import Api from 'api/Api';
import { IPagination } from 'api/types';
import {
  IClearResponse,
  IClustersListGetResponse,
  ICourierSessionsListGetResponse,
  ICouriersListGetResponse,
  IOrdersListGetResponse,
  IRoutesListGetResponse,
  ITasksListGetResponse,
  ITransportsListGetResponse,
  IWarehousesListGetResponse,
  IClusterItemsListGetResponse,
} from 'modules/qa/models/types';

export default class FastmineMonitorApi {
  static getTasks(pagination: IPagination, signal?: AbortSignal): Promise<ITasksListGetResponse> {
    const query = {
      page: pagination.current,
      page_size: pagination.pageSize,
    };
    return Api.get('/api/v1/fastmine/qa/tasks', query, signal);
  }

  static getClusters(
    pagination: IPagination,
    signal?: AbortSignal,
  ): Promise<IClustersListGetResponse> {
    const query = {
      page: pagination.current,
      page_size: pagination.pageSize,
    };
    return Api.get('/api/v1/fastmine/qa/clusters', query, signal);
  }

  static getClustersItems(
    pagination: IPagination,
    signal?: AbortSignal,
  ): Promise<IClusterItemsListGetResponse> {
    const query = {
      page: pagination.current,
      page_size: pagination.pageSize,
    };
    return Api.get('/api/v1/fastmine/qa/cluster-items', query, signal);
  }

  static getCouriers(
    pagination: IPagination,
    signal?: AbortSignal,
  ): Promise<ICouriersListGetResponse> {
    const query = {
      page: pagination.current,
      page_size: pagination.pageSize,
    };
    return Api.get('/api/v1/fastmine/qa/couriers', query, signal);
  }

  static getCoiriersSessions(
    pagination: IPagination,
    signal?: AbortSignal,
  ): Promise<ICourierSessionsListGetResponse> {
    const query = {
      page: pagination.current,
      page_size: pagination.pageSize,
    };
    return Api.get('/api/v1/fastmine/qa/courier-sessions', query, signal);
  }

  static getOrders(pagination: IPagination, signal?: AbortSignal): Promise<IOrdersListGetResponse> {
    const query = {
      page: pagination.current,
      page_size: pagination.pageSize,
    };
    return Api.get('/api/v1/fastmine/qa/orders', query, signal);
  }

  static getRoutes(pagination: IPagination, signal?: AbortSignal): Promise<IRoutesListGetResponse> {
    const query = {
      page: pagination.current,
      page_size: pagination.pageSize,
    };
    return Api.get('/api/v1/fastmine/qa/routes', query, signal);
  }

  static getTransports(
    pagination: IPagination,
    signal?: AbortSignal,
  ): Promise<ITransportsListGetResponse> {
    const query = {
      page: pagination.current,
      page_size: pagination.pageSize,
    };
    return Api.get('/api/v1/fastmine/qa/transports', query, signal);
  }

  static getWarehouses(
    pagination: IPagination,
    signal?: AbortSignal,
  ): Promise<IWarehousesListGetResponse> {
    const query = {
      page: pagination.current,
      page_size: pagination.pageSize,
    };
    return Api.get('/api/v1/fastmine/qa/warehouses', query, signal);
  }

  static clearHard(): Promise<IClearResponse> {
    return Api.delete('/api/v1/fastmine/qa/clear/hard');
  }

  static clearSoft(): Promise<IClearResponse> {
    return Api.delete('/api/v1/fastmine/qa/clear/soft');
  }
}
