import './style.less';

import React, { ReactNode } from 'react';

import { Feature, Point } from 'geojson';
import { observer } from 'mobx-react-lite';

import Map from 'modules/map/containers/Map';
import MarkerCluster from 'modules/map/containers/MarkerCluster';
import { getBounds } from 'modules/map/containers/ViewPort';
import RoutePath from 'modules/routes/components/RoutePath';
import { IRoute } from 'modules/routes/models/types';
import TaskPoint from 'modules/tasks/components/TaskPoint';
import { groupTasksWithRelativesRouteInfo } from 'modules/tasks/helpers/lists';
import { getPointProp } from 'modules/tasks/helpers/propHelper';
import { ITask } from 'modules/tasks/models/types';
import WarehouseMarker from 'modules/warehouse/containers/WarehouseMarker';
import { IWarehouses } from 'modules/warehouses/models/types';

interface IProps {
  route: IRoute;
}

const RoutesMap = ({ route }: IProps) => {
  const tasksGroupedByRelative = groupTasksWithRelativesRouteInfo(route.tasks);

  const warehousePointStart: Feature<Point> = route.start_warehouse &&
    route.start_warehouse.point &&
    route.start_warehouse.point.lon &&
    route.start_warehouse.point.lat && {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [route.start_warehouse.point.lon, route.start_warehouse.point.lat],
      },
      properties: {
        name: route.start_warehouse.title,
        address: route.start_warehouse.address,
        guid: route.start_warehouse.guid,
      },
    };

  const warehousePointFinish: Feature<Point> = route.finish_warehouse &&
    route.finish_warehouse.point &&
    route.finish_warehouse.point.lon &&
    route.finish_warehouse.point.lat && {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [route.finish_warehouse.point.lon, route.finish_warehouse.point.lat],
      },
      properties: {
        name: route.finish_warehouse.title,
        address: route.finish_warehouse.address,
        guid: route.finish_warehouse.guid,
      },
    };

  const renderPoint = (item: ITask, index: number | string): ReactNode => {
    if (item.guid) {
      return (
        <TaskPoint key={item.guid} task={item} content={Number(index) + 1} isTooltip isShort />
      );
    }
  };

  const renderPointWarehouses = (warehouse: IWarehouses): ReactNode => {
    if (warehouse.point && warehouse.point.lat & warehouse.point.lon) {
      return (
        <WarehouseMarker
          key={warehouse.guid}
          position={[warehouse.point.lat, warehouse.point.lon]}
          name={warehouse.title}
          address={warehouse.address}
        />
      );
    }
  };

  const mapView: Feature<Point>[] =
    (tasksGroupedByRelative &&
      tasksGroupedByRelative
        .filter((task) => task.order && task.order[getPointProp(task)])
        .map((task) => ({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [
              task.order && task.order[getPointProp(task)].lon,
              task.order && task.order[getPointProp(task)].lat,
            ],
          },
          properties: {
            guid: task.guid,
          },
        }))) ||
    [];

  if (warehousePointStart) {
    mapView.push(warehousePointStart);
  }

  if (warehousePointFinish) {
    mapView.push(warehousePointFinish);
  }

  const renderItemsMarkers = route && tasksGroupedByRelative ? [...tasksGroupedByRelative] : [];

  const tasksPoints =
    route && tasksGroupedByRelative
      ? tasksGroupedByRelative
          .filter((task) => task.order[getPointProp(task)] && task.order[getPointProp(task)].lat)
          .filter((item) =>
            Boolean(
              item.order &&
                item.order[getPointProp(item)].lat &&
                item.order[getPointProp(item)].lon,
            ),
          )
          .map((task) => ({
            type: 'Point',
            coordinates: [
              task.order && task.order[getPointProp(task)].lon,
              task.order && task.order[getPointProp(task)].lat,
            ],
          }))
      : [];
  const warehousesPoint = route.start_warehouse &&
    route.start_warehouse.point &&
    route.start_warehouse.point.lon &&
    route.start_warehouse.point.lat && {
      type: 'Point',
      coordinates: [route.start_warehouse.point.lon, route.start_warehouse.point.lat],
    };
  const pointList = warehousesPoint ? [warehousesPoint, ...tasksPoints, warehousesPoint] : null;

  return (
    <Map
      mapView={mapView.length > 0 ? mapView : []}
      isSearch={false}
      center={mapView && mapView.length > 0 && getBounds(mapView).getCenter()}
      zoom={14}
    >
      {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
      {/*@ts-ignore*/}
      <MarkerCluster maxClusterRadius={5}>
        {renderItemsMarkers.map(renderPoint)}
        {route.start_warehouse && renderPointWarehouses(route.start_warehouse)}
        {route.finish_warehouse &&
          route.start_warehouse_guid !== route.finish_warehouse_guid &&
          renderPointWarehouses(route.finish_warehouse)}
      </MarkerCluster>
      {pointList && <RoutePath pointList={pointList} />}
    </Map>
  );
};

export default observer(RoutesMap);
