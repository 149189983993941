import './style.less';

import React from 'react';

import { translate } from 'modules/localization';

interface IProps {
  value?: number;
}

const DistanceInfo = ({ value }: IProps) => {
  return (
    <div className="distance-info">
      {translate('distanceShort')}:{' '}
      <span>
        {value} {translate('distanceUnit')}
      </span>
    </div>
  );
};

export default DistanceInfo;
