import './style.less';

import React, { useEffect, useRef } from 'react';

import { Table } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { observer } from 'mobx-react-lite';

import { IPagination } from 'api/types';
import { DEFAULT_PAGE_SIZES } from 'constants/index';
import { getColumns, getRowHandler } from 'modules/routes/components/RoutesList/helpers';
import { IRoutes } from 'modules/routes/models/types';
import { useStore } from 'services/store';

const RoutesList = () => {
  const { routes, core } = useStore();

  useEffect(() => {
    routes.apiStore.getRoutes();

    return () => {
      routes.setFilterRoutes('current', 1);
    };
  }, []);

  const updateList = ({ current, pageSize }: IPagination, _, sort: SorterResult<IRoutes>): void => {
    routes.apiStore.getRoutes({
      ...routes.filterRoutes,
      current,
      pageSize,
      columnKey: String(sort.columnKey),
      order: sort.order,
    });
  };

  const columns = getColumns(routes.routesStatuses, core.permissions, routes.filterRoutes);

  const listRef = useRef(null);
  const scrollIntoView = () => listRef.current.nativeElement.scrollIntoView({ behavior: 'smooth' });

  return (
    <Table<IRoutes>
      ref={listRef}
      className="dir__list"
      dataSource={routes.routesList}
      columns={columns}
      bordered={false}
      pagination={{
        ...routes.filterRoutes,
        simple: core.isMobile,
        defaultPageSize: DEFAULT_PAGE_SIZES[1],
        showSizeChanger: true,
        pageSizeOptions: DEFAULT_PAGE_SIZES,
        onChange: scrollIntoView,
      }}
      onChange={updateList}
      rowKey="guid"
      onRow={getRowHandler()}
      loading={routes.isLoadingRoutesList}
    />
  );
};

export default observer(RoutesList);
