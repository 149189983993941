import './style.less';

import React, { useEffect, useRef, useState } from 'react';

import { observer } from 'mobx-react-lite';
import { useDrag } from 'react-dnd';

import classSet from 'helpers/classSet';
import { DndTypes } from 'modules/routeGenerator/stores/RouteGeneratorDnDStore';
import RelativeTasksToggler from 'modules/routes/components/RelativeTasksToggler';
import { ITask } from 'modules/tasks/models/types';
import { getRelativeTasksCount, hasRelativeTasks } from 'modules/tasks/models/utils';
import { useStore } from 'services/store';

import TaskAddress from './TaskAddress';
import TaskCheckbox from './TaskCheckbox';
import TaskComment from './TaskComment';
import TaskDateTime from './TaskDateTime';
import TaskDateTimePlanned from './TaskDateTimePlanned';
import TaskDeliveryType from './TaskDeliveryType';
import TaskError from './TaskError';
import TaskNumber from './TaskNumber';
import TaskRemove from './TaskRemove';
import TaskSizes from './TaskSizes';

interface IProps {
  task: ITask;
  isRemovable?: boolean;
  isSelectable?: boolean;
  classNames?: string;
}

const Task = ({ task, isRemovable, isSelectable, classNames }: IProps) => {
  const { routeGenerator } = useStore();

  const [{ isDragging }, drag] = useDrag(() => ({
    type: isRemovable ? DndTypes.RIGHT_TASK : DndTypes.LEFT_TASK,
    item: {
      id: task.guid,
      selectedGuids: () => routeGenerator.selectedTaskGuids,
    },
    isDragging: (monitor) => {
      return (
        monitor.getItem().selectedGuids().includes(task.guid) || monitor.getItem().id === task.guid
      );
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [isRelativesVisible, toggleRelativesVisible] = useState(false);

  const renderRelatives = () => {
    return (
      isRelativesVisible &&
      hasRelativeTasks(task) && (
        <div className="rg-task__relatives">
          {task.relative_tasks.map((t) => (
            <div
              key={t.guid}
              className={classSet({
                'rg-task': true,
                'rg-task--relative': true,
                'rg-task--highlighted': t.isHighlighted,
              })}
            >
              <div className="rg-task__wrapper">
                <div className="rg-task__left">
                  {isRemovable ? <TaskDateTimePlanned task={t} /> : <TaskDateTime task={t} />}
                  <div className="rg-task__comment-number">
                    <TaskComment task={t} />
                    <TaskNumber task={t} />
                  </div>
                </div>
                <div className="rg-task__right">
                  <TaskSizes task={t} />
                  <TaskDeliveryType task={t} />
                </div>
                {isRemovable ? (
                  <div className="rg-task__actions">
                    <TaskRemove task={t} />
                  </div>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      )
    );
  };

  const scrollIntoViewRef = useRef(null);
  const scrollIntoView = () => {
    scrollIntoViewRef?.current?.scrollIntoView();
  };

  useEffect(() => {
    scrollIntoView();
  }, [task.isScrollableIntoView]);

  return (
    <div
      className={classSet({
        'rg-task': true,
        'rg-task--dragging': isDragging,
        'rg-task--highlighted': task.isHighlighted,
        [classNames]: !!classNames,
      })}
      ref={drag}
    >
      {isRemovable ? <TaskError task={task} /> : null}
      <div className="rg-task__wrapper" ref={task.isScrollableIntoView ? scrollIntoViewRef : null}>
        {isSelectable ? (
          <div className="rg-task__actions">
            <TaskCheckbox task={task} />
          </div>
        ) : null}
        <div className="rg-task__left">
          {isRemovable ? <TaskDateTimePlanned task={task} /> : <TaskDateTime task={task} />}
          <TaskAddress task={task} />
          {hasRelativeTasks(task) ? (
            <div className="rg-task__relative-toggler">
              <RelativeTasksToggler
                count={getRelativeTasksCount(task)}
                isActive={isRelativesVisible}
                onClick={toggleRelativesVisible}
              />
            </div>
          ) : (
            <TaskNumber task={task} />
          )}
        </div>
        <div className="rg-task__right">
          <TaskSizes task={task} />
          <TaskDeliveryType task={task} />
        </div>
        {isRemovable ? (
          <div className="rg-task__actions">
            <TaskRemove task={task} />
          </div>
        ) : null}
      </div>

      {renderRelatives()}
    </div>
  );
};

export default observer(Task);
