export const LOCAL_STORAGE = {
  TOKEN: 'mag-delivery.token',
  REFRESH: 'mag-delivery.refresh',
  COUNTRY_CURRENT_ID: 'mag-delivery.country.currentId',
  TILELAYER: 'mag-delivery.tilelayer',
  ORDERS_FILTER: 'mag-delivery.ordersfilter',
  TASKS_FILTER: 'mag-delivery.tasksfilter',
  COURIERS_FILTER: 'mag-delivery.couriersfilter',
  ROUTES_FILTER: 'mag-delivery.routesfilter',
  SAVED_GENERATOR_SETTINGS: 'mag-delivery.generatorsettings',
  SAVED_ROUTE_DRAFTS: 'mag-delivery.routedrafts',
};

export const GEO_BASE_URL = '/geo/locations';

export const DEFAULT_PAGE_SIZES = [20, 50, 100];

export const DEFAULT_PAGINATION = {
  current: 1,
  pageSize: DEFAULT_PAGE_SIZES[1],
};

// можно добавить LARGE_PAGINATION { pageSize: 100, current: 1 }
