import React from 'react';

export const BottleSVG = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.85303 4.14453C9.85303 3.59225 10.3007 3.14453 10.853 3.14453H13.1501C13.7024 3.14453 14.1501 3.59225 14.1501 4.14453V5.88379C14.1501 5.96187 14.1412 6.03786 14.1243 6.11079C16.2116 6.95174 17.6849 8.99603 17.6849 11.3844V11.4219L17.685 11.4373V17.8567C17.685 19.5135 16.3419 20.8567 14.685 20.8567H9.31847C7.66162 20.8567 6.31847 19.5136 6.31847 17.8567L6.31836 11.3844C6.31836 8.99607 7.79161 6.9518 9.87891 6.11083C9.86198 6.03789 9.85303 5.96188 9.85303 5.88379V4.14453ZM7.81847 17.8567V16.2103L16.1849 16.2103L16.185 17.8567C16.185 18.6851 15.5135 19.3567 14.685 19.3567H9.31847C8.49005 19.3567 7.81847 18.6851 7.81847 17.8567ZM12.0016 7.20115C13.9863 7.20115 15.6481 8.58318 16.0773 10.4373H7.92602C8.35517 8.58318 10.017 7.20115 12.0016 7.20115ZM16.1849 14.7103L16.1849 11.9373H7.81847V14.7103L16.1849 14.7103Z"
      fill="#43A8ED"
    />
  </svg>
);
