import { LOCAL_STORAGE } from 'constants/index';
import { IFilter } from 'modules/arm2/models/types';

export const getOrdersFilter = (): IFilter => {
  const filterString = localStorage.getItem(LOCAL_STORAGE.ORDERS_FILTER);
  return JSON.parse(filterString);
};

export const setOrdersFilter = (filter: IFilter): void => {
  const filterString = JSON.stringify(filter);
  localStorage.setItem(LOCAL_STORAGE.ORDERS_FILTER, filterString);
};
