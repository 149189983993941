import React, { useState } from 'react';

import { Feature } from 'geojson';
import { observer } from 'mobx-react-lite';

import Distance from 'modules/map/containers/Distance';
import Map from 'modules/routeGenerator/components/common/Map';
import { getWarehousesPoint } from 'modules/routeGenerator/components/common/Map/helpers';
import SelectedTaskActions from 'modules/routeGenerator/components/right/SelectedTaskActions';
import RoutePath from 'modules/routes/components/RoutePath';
import { ITask } from 'modules/tasks/models/types';
import { getTaskRelativeGuids, getTasksPoints } from 'modules/tasks/models/utils';
import { useStore } from 'services/store';

interface IProps {
  routeId: string;
  taskList: ITask[];
}

const RightSideMap = ({ routeId, taskList }: IProps) => {
  const { routeGenerator, routes } = useStore();

  const route = routeGenerator.getRouteDraft(routeId);
  const { isCalculated } = route;

  const tasksPoints = getTasksPoints(taskList);

  const warehousesList = [routeGenerator.activeWarehouse];
  const warehousesPoints: Feature[] = getWarehousesPoint(warehousesList);

  const pointList = [
    warehousesPoints[0],
    ...tasksPoints,
    warehousesPoints.length > 1 ? warehousesPoints[1] : warehousesPoints[0],
  ];

  const [selectedTaskGuids, setSelectedTaskGuids] = useState<React.Key[]>([]);

  const toggleSelectionTask = (task: ITask): void => {
    const taskToSelect = taskList.find((t) => t.guid === task.guid);
    const guidsToSelect = getTaskRelativeGuids(taskToSelect);

    let newSelectedTaskGuids = selectedTaskGuids.slice();

    guidsToSelect.map((guid) => {
      if (selectedTaskGuids.indexOf(guid) === -1) {
        newSelectedTaskGuids.push(guid);
      } else {
        newSelectedTaskGuids = newSelectedTaskGuids.filter((g) => g !== guid);
      }
    });

    setSelectedTaskGuids(newSelectedTaskGuids);
  };

  const handleSelectList = (taskGuidList: string[]): void => {
    const tasksToSelect = taskList.filter((t) => taskGuidList.includes(t.guid));
    const guidsToSelect = tasksToSelect.reduce(
      (acc, t) => [...acc, ...getTaskRelativeGuids(t)],
      [],
    );

    let newSelectedTaskGuids = selectedTaskGuids.slice();

    guidsToSelect.map((guid) => {
      if (selectedTaskGuids.indexOf(guid) === -1) {
        newSelectedTaskGuids.push(guid);
      } else {
        newSelectedTaskGuids = newSelectedTaskGuids.filter((g) => g !== guid);
      }
    });

    setSelectedTaskGuids(newSelectedTaskGuids);
  };

  const handleDeselectList = (taskGuidList: string[]): void => {
    const tasksToDeselect = taskList.filter((t) => taskGuidList.includes(t.guid));
    const guidsToDeselect = tasksToDeselect.reduce(
      (acc, t) => [...acc, ...getTaskRelativeGuids(t)],
      [],
    );

    const newSelectedTaskGuids = selectedTaskGuids
      .slice()
      .filter((t) => !guidsToDeselect.includes(t));

    setSelectedTaskGuids(newSelectedTaskGuids);
  };

  return (
    <>
      <SelectedTaskActions
        routeId={routeId}
        selectedTasks={selectedTaskGuids}
        setSelectedTaskGuids={setSelectedTaskGuids}
      />
      <Map
        taskList={taskList}
        handleSelectList={handleSelectList}
        handleDeselectList={handleDeselectList}
        selectedTaskList={selectedTaskGuids}
        handleSelectTask={toggleSelectionTask}
        warehouses={warehousesList}
        showRoute={isCalculated}
      >
        {isCalculated ? <RoutePath pointList={pointList} customColor="#00CC66" /> : null}
        {isCalculated && routes.visibleRouteDistance ? (
          <Distance value={routes.visibleRouteDistance} />
        ) : null}
      </Map>
    </>
  );
};

export default observer(RightSideMap);
