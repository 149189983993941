import { Feature, Point } from 'geojson';
import uniq from 'lodash.uniq';

import { IDeliveryMethods } from 'modules/delivery-methods/models/types';
import { ITasksGroupCoverages } from 'modules/routeGenerator/models/types';
import { getPointProp } from 'modules/tasks/helpers/propHelper';
import { ITask, ITasksStatus, ITasksType } from 'modules/tasks/models/types';

import { getTasksListAmount, getTasksListVolume, getTasksListWeight } from '../helpers/lists';

// Получить гуид таски
export const getTaskGuid = (task: ITask): string => {
  return task.guid;
};

export const hasRelativeTasks = (task: ITask): boolean => {
  return task && task.relative_tasks && task.relative_tasks.length > 1;
};

export const getRelativeTasksCount = (task: ITask): number => {
  return hasRelativeTasks(task) ? task.relative_tasks.length : 1;
};

export const getRelativeTasksList = (task: ITask): ITask[] => {
  return hasRelativeTasks(task) ? task.relative_tasks : [task];
};

export const hasRelativeTasksError = (task: ITask): boolean => {
  return hasRelativeTasks(task) ? task.relative_tasks.some((item) => Boolean(item.error)) : false;
};

// Получить гуиды всех родственных тасок
export const getTaskRelativeGuids = (task: ITask): string[] => {
  return hasRelativeTasks(task) ? task.relative_tasks.map(({ guid }) => guid) : [task.guid];
};

export const getRelativeGuidsFromList = (taskList: ITask[]): string[] =>
  taskList.reduce((acc, t) => [...acc, ...getTaskRelativeGuids(t)], []);

export const getStatus = (task: ITask, statusList: ITasksStatus[]): string => {
  return statusList.find((s) => s.value === task.status)?.title || '';
};

export const getType = (task: ITask, types: ITasksType[]): string => {
  if (hasRelativeTasks(task)) {
    const uniqTypes = uniq(task.relative_tasks.map((t) => t.type));
    return uniqTypes.map((t) => types.find((s) => s.value === t)?.title).join(', ');
  }
  return types.find((s) => s.value === task.type)?.title || '';
};

export const getDeliveryMethod = (item: ITask, deliveryMethods: IDeliveryMethods[]): string => {
  if (hasRelativeTasks(item)) {
    const uniqDeliveryMethods = uniq(item.relative_tasks.map((t) => t.delivery_method_guid));
    return uniqDeliveryMethods
      .map((t) => deliveryMethods.find((s) => s.guid === t)?.name)
      .join(', ');
  }
  return deliveryMethods.find((s) => s.guid === item.delivery_method_guid)?.name;
};

export const getSize = (sizeName: string, task: ITask): number => {
  if (task && hasRelativeTasks(task)) {
    return task.relative_tasks.reduce((sum, t) => {
      if (t.sizes && t.sizes[sizeName]) {
        return sum + t.sizes[sizeName];
      }
      return sum;
    }, 0);
  }

  return task && task.sizes && task.sizes[sizeName];
};

export const getAmount = (task: ITask): number => {
  if (task && hasRelativeTasks(task)) {
    return task.relative_tasks.reduce((sum, t) => {
      if (t.meta && t.meta.amount_without_tax) {
        return sum + t.meta.amount_without_tax;
      }
      return sum;
    }, 0);
  }

  return task?.meta?.amount_without_tax || 0;
};

export const getWeightTasks = (tasksList: ITask[]): number => {
  return (
    Array.isArray(tasksList) && tasksList.reduce((sum, item) => sum + getSize('weight', item), 0)
  );
};

export const getVolumeTasks = (tasksList: ITask[]): number => {
  return (
    Array.isArray(tasksList) && tasksList.reduce((sum, item) => sum + getSize('volume', item), 0)
  );
};

export const getTasksPoints = (tasksList: ITask[]): Feature<Point, { guid: string[] }>[] =>
  tasksList
    .filter((task) => task.order[getPointProp(task)] && task.order[getPointProp(task)].lat)
    .map((task) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [
          task.order && task.order[getPointProp(task)].lon,
          task.order && task.order[getPointProp(task)].lat,
        ],
      },
      properties: {
        guid: getTaskRelativeGuids(task),
      },
    }));

export const tasksSortSizesAsc = (tasks: ITask[], name: string): ITask[] => {
  return tasks.sort((a, b) => {
    if (getSize(name, a) < getSize(name, b)) {
      return -1;
    } else if (getSize(name, a) > getSize(name, b)) {
      return 1;
    }
    return 0;
  });
};

export const tasksSortSizesDesc = (tasks: ITask[], name: string): ITask[] => {
  return tasks.sort((a, b) => {
    if (getSize(name, a) > getSize(name, b)) {
      return -1;
    } else if (getSize(name, a) < getSize(name, b)) {
      return 1;
    }
    return 0;
  });
};

export const tasksSortAmountAsc = (tasks: ITask[]): ITask[] => {
  return tasks.sort((a, b) => {
    if (getAmount(a) < getAmount(b)) {
      return -1;
    } else if (getAmount(a) > getAmount(b)) {
      return 1;
    }
    return 0;
  });
};

export const tasksSortAmountDesc = (tasks: ITask[]): ITask[] => {
  return tasks.sort((a, b) => {
    if (getAmount(a) > getAmount(b)) {
      return -1;
    } else if (getAmount(a) < getAmount(b)) {
      return 1;
    }
    return 0;
  });
};

export const sortTaskList = (taskList: ITask[], sortType: string): ITask[] => {
  let sortedList;

  switch (sortType) {
    case 'defaultSort':
      sortedList = taskList;
      break;
    case 'ascendingAmountSort':
      sortedList = tasksSortAmountAsc(taskList);
      break;
    case 'descendingAmountSort':
      sortedList = tasksSortAmountDesc(taskList);
      break;
    case 'ascendingWeightSort':
      sortedList = tasksSortSizesAsc(taskList, 'weight');
      break;
    case 'descendingWeightSort':
      sortedList = tasksSortSizesDesc(taskList, 'weight');
      break;
    case 'ascendingVolumeSort':
      sortedList = tasksSortSizesAsc(taskList, 'volume');
      break;
    case 'descendingVolumeSort':
      sortedList = tasksSortSizesDesc(taskList, 'volume');
      break;
    default:
      sortedList = taskList;
      break;
  }

  return sortedList;
};

export interface ICoverageData {
  name: string;
  guid: string;
  price: number;
  weight: number;
  volume: number;
}

export const sortCoverages = (
  coverages: { name: string; guid: string }[],
  tasks: ITasksGroupCoverages,
  sortType?: string,
): ICoverageData[] => {
  let coveragesData: ICoverageData[] = coverages.map((c) => ({
    ...c,
    price: getTasksListAmount(tasks[c.guid].tasks),
    weight: getTasksListWeight(tasks[c.guid].tasks),
    volume: getTasksListVolume(tasks[c.guid].tasks),
  }));

  switch (sortType) {
    case 'defaultSort':
      break;
    case 'ascendingAmountSort':
      coveragesData = coveragesDataSortAsc(coveragesData, 'price');
      break;
    case 'descendingAmountSort':
      coveragesData = coveragesDataSortDesc(coveragesData, 'price');
      break;
    case 'ascendingWeightSort':
      coveragesData = coveragesDataSortAsc(coveragesData, 'weight');
      break;
    case 'descendingWeightSort':
      coveragesData = coveragesDataSortDesc(coveragesData, 'weight');
      break;
    case 'ascendingVolumeSort':
      coveragesData = coveragesDataSortAsc(coveragesData, 'volume');
      break;
    case 'descendingVolumeSort':
      coveragesData = coveragesDataSortDesc(coveragesData, 'volume');
      break;
    default:
      break;
  }

  return coveragesData;
};

export const coveragesDataSortAsc = (data: ICoverageData[], name: string): ICoverageData[] => {
  return data.sort((a, b) => {
    if (a[name] < b[name]) {
      return -1;
    } else if (a[name] > b[name]) {
      return 1;
    }
    return 0;
  });
};

export const coveragesDataSortDesc = (data: ICoverageData[], name: string): ICoverageData[] => {
  return data.sort((a, b) => {
    if (a[name] > b[name]) {
      return -1;
    } else if (a[name] < b[name]) {
      return 1;
    }
    return 0;
  });
};
