import React from 'react';

import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';

import { dateFormat } from 'helpers/string';
import Title from 'modules/common/components/Title';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

const GeneratorRightSideTitle = () => {
  const { routeGenerator } = useStore();

  return (
    <Title size={Title.SIZE.H3} weight={Title.WEIGHT.SEMIBOLD} className="rg-rightside__title">
      {translate('routeFormTitle')}
      <span className="rg-rightside__date">
        {translate('forDate', {
          content:
            routeGenerator.routeSettings.deliveryDate &&
            dayjs(routeGenerator.routeSettings.deliveryDate).format(dateFormat.string),
        })}
      </span>
    </Title>
  );
};

export default observer(GeneratorRightSideTitle);
