import React from 'react';

import { observer } from 'mobx-react-lite';

import Title from 'modules/common/components/Title';
import ButtonCreate from 'modules/directory/components/ActionButtons/ButtonCreate';
import ButtonCreateMobile from 'modules/directory/components/ActionButtons/ButtonCreateMobile';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

interface IProps {
  baseUrl: string;
}

const ClustersHeader = ({ baseUrl }: IProps) => {
  const { core } = useStore();

  return (
    <div className="dir__header">
      <Title className="dir__title" size={Title.SIZE.H3} weight={Title.WEIGHT.SEMIBOLD}>
        {translate('listOfClusters')}
        {core.isMobile && core.permissions['clusters.create'] && (
          <ButtonCreateMobile baseUrl={baseUrl} isPermitted />
        )}
        {!core.isMobile && core.permissions['clusters.create'] && (
          <ButtonCreate baseUrl={baseUrl} isPermitted>
            {translate('createClusters')}
          </ButtonCreate>
        )}
      </Title>
    </div>
  );
};

export default observer(ClustersHeader);
