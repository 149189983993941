import React from 'react';

import { DatePicker } from 'antd';
import dayjs from 'dayjs';

import { dateFormat } from 'helpers/string';

interface IProps {
  className?: string;
  onChange: (value) => void;
  value: string;
}

export const DEFAULT_DATE = dayjs().format(dateFormat.search);

const DateFilter = ({ className, value, onChange }: IProps) => {
  const currentDatedayjs = dayjs();

  const handleDateChange = (date): void => {
    const newValue = date ? date.format(dateFormat.search) : DEFAULT_DATE;
    onChange(newValue);
  };

  return (
    <div className={className}>
      <DatePicker
        className="arm-filter-date"
        defaultPickerValue={dayjs(currentDatedayjs.format(dateFormat.search))}
        format={dateFormat.string}
        onChange={handleDateChange}
        value={value ? dayjs(value, dateFormat.search) : null}
        getPopupContainer={(el) => el}
      />
    </div>
  );
};

export default DateFilter;
