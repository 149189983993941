import React, { ReactNode } from 'react';

import { EllipsisOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { IPermission } from 'modules/core/models/types';
import { ICoverage, ICoveragesPolygon } from 'modules/coverages/models/types';
import Actions from 'modules/directory/components/ActionLinks';
import { translate } from 'modules/localization';

export const getColumns = (
  baseUrl: string,
  actionDelete: (guid: string) => void,
  permissions: IPermission,
): ColumnsType<ICoverage> => {
  const columns: ColumnsType<ICoverage> = [
    {
      dataIndex: 'name',
      title: translate('title'),
      key: 'name',
      render: (text): ReactNode => text,
    },
  ];

  if (permissions['coverages.update'] || permissions['coverages.delete']) {
    columns.push({
      dataIndex: 'guid',
      key: 'action',
      render: (_, item): ReactNode => (
        <Popover
          trigger="hover"
          overlayClassName="action-links-popover"
          placement="bottomLeft"
          getTooltipContainer={(el) => el}
          arrow={false}
          content={() => (
            <Actions
              item={item}
              baseUrl={baseUrl}
              actionDelete={actionDelete}
              message={{
                deleteCancel: translate('cancel'),
                deleteTitle: translate('coverageDeleteConfirm'),
              }}
              permissions={{
                edit: permissions['coverages.update'],
                delete: permissions['coverages.delete'],
              }}
            />
          )}
        >
          <div className="actions-links-wrapper">
            <EllipsisOutlined className="actions-links" />
          </div>
        </Popover>
      ),
      width: '5%',
    });
  }

  return columns;
};

export const getListMapView = (list: ICoverage[]): ICoveragesPolygon[] =>
  list.length ? list.map((coverage) => coverage.polygon) : [];
