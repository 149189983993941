import React from 'react';

import { observer } from 'mobx-react-lite';

import ListMobX from 'modules/directory/containers/List/List';
import { useStore } from 'services/store';

import { getColumns } from './helpers';

interface IProps {
  baseUrl: string;
}

const CouriersList = ({ baseUrl }: IProps) => {
  const { courierDirectory } = useStore();

  return (
    <ListMobX
      baseUrl={baseUrl}
      actionListGet={courierDirectory.getList}
      list={courierDirectory.list}
      pagination={courierDirectory.filter}
      getColumns={getColumns}
      isLoading={courierDirectory.isLoadingList}
      actionDelete={courierDirectory.delete}
    />
  );
};

export default observer(CouriersList);
