import React from 'react';

import { Button, Popconfirm, Tooltip } from 'antd';
import { DatePickerProps } from 'antd/lib/date-picker';

import { getCurrentUTCTimezone } from 'helpers/date';
import DatePicker from 'modules/common/containers/DatePicker';
import { translate } from 'modules/localization';

type IProps = DatePickerProps & {
  endRate: () => void;
  isView: boolean;
  onVisibleChange?: (isVisible: boolean) => void;
};

const EndTimeRateDatepicker = ({ isView, endRate, onVisibleChange, ...rest }: IProps) => {
  return (
    <div className="rates-picker">
      <Tooltip title={getCurrentUTCTimezone()} placement="right" onOpenChange={onVisibleChange}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/*// @ts-ignore*/}
        <DatePicker {...rest} getPopupContainer={(el) => el} />
      </Tooltip>
      {isView && (
        <Popconfirm
          cancelText={translate('cancel')}
          onConfirm={endRate}
          title={<>{translate('finishTheRateConfirm')}</>}
        >
          <Button type="link">{translate('finishTheRate')}</Button>
        </Popconfirm>
      )}
    </div>
  );
};

export default EndTimeRateDatepicker;
