import { ILocation } from './types';

export const getLocationParentGuid = (location: ILocation): string => {
  if (!location.parent_guid) {
    return location.guid;
  }

  if (location.parent) {
    return getLocationParentGuid(location.parent);
  }

  return location.parent_guid;
};
