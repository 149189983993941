import React, { ReactNode } from 'react';

import { ArrowRightOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';

import { stopPropagation } from 'helpers/stopPropagation';
import Status from 'modules/common/components/Status';
import { IPermission } from 'modules/core/models/types';
import { IDeliveryMethods } from 'modules/delivery-methods/models/types';
import { translate } from 'modules/localization';
import { getAddressProp } from 'modules/orders/helpers/propHelper';
import { IOrder, IOrderStatus, IOrderType } from 'modules/orders/models/types';
import { routerStore } from 'services/store';

export const getOrderUrl = (row: IOrder): string => `/arm2/orders/${row.guid}`;

export const renderType = (orderType: string, typesList: IOrderType[]): ReactNode => {
  switch (orderType) {
    case 'warehouse_to_customer':
      return (
        <>
          {translate('warehouse')} <ArrowRightOutlined /> {translate('client')}
        </>
      );
    case 'customer_to_warehouse':
      return (
        <>
          {translate('client')} <ArrowRightOutlined /> {translate('warehouse')}
        </>
      );
    case 'customer_to_customer':
      return (
        <>
          {translate('client')} <ArrowRightOutlined /> {translate('client')}
        </>
      );
    case 'warehouse_to_warehouse':
      return (
        <>
          {translate('warehouse')} <ArrowRightOutlined /> {translate('warehouse')}
        </>
      );
    default: {
      const type = typesList && typesList.find((s) => s.value === orderType);
      return type ? type.title : '—';
    }
  }
};

export const getColumns = (
  statuses: IOrderStatus[],
  deliveryMethods: IDeliveryMethods[],
  permissions: IPermission,
): ColumnsType<IOrder> => {
  return [
    {
      dataIndex: 'status',
      title: translate('status'),
      key: 'status',
      render: (status): ReactNode => {
        const current = statuses && statuses.find((s) => s.value === status);
        return <Status status={current} />;
      },
      width: '18%',
    },
    {
      dataIndex: 'time',
      title: translate('time'),
      key: 'time',
      render: (_, order): ReactNode => {
        return `${order.time_slot_start} – ${order.time_slot_end}`;
      },
      width: '15%',
    },
    {
      dataIndex: 'number',
      title: translate('order'),
      key: 'number',
      render: (_, order): ReactNode => {
        const address = getAddressProp(order);
        return (
          <div>
            <p>
              {permissions['tasks.list'] ? (
                <span onClick={stopPropagation}>
                  <Link to={getOrderUrl(order)}>{order.number}</Link>
                </span>
              ) : (
                order.number
              )}
              {Number.isInteger(order?.meta?.order_number) &&
                ` (№\u00A0${order?.meta?.order_number})`}
            </p>
            <p>{order[address]}</p>
          </div>
        );
      },
    },
    {
      dataIndex: 'warehouse',
      title: translate('warehouse'),
      key: 'warehouse',
      render: (_, order): ReactNode => {
        const current = order?.meta?.warehouse;
        return `${current && current.title}`;
      },
      width: '18%',
    },
    {
      dataIndex: 'deliveryMethod',
      title: translate('deliveryMethod'),
      key: 'deliveryMethod',
      render: (_, order): ReactNode => {
        const method =
          deliveryMethods && deliveryMethods.find((d) => d.guid === order.delivery_method_guid);
        return <p>{method && method.name}</p>;
      },
      width: '18%',
    },
  ];
};

export const getRowHandler = () => (row: IOrder) => ({
  className: 'orders__row',
  onClick: (): void => routerStore.push(getOrderUrl(row)),
});
