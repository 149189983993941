import { makeAutoObservable } from 'mobx';

import { RestException } from 'api/RestException';
import { IPagination } from 'api/types';
import { DEFAULT_PAGE_SIZES } from 'constants/index';
import { getLastPagePagination, isOutOfPages } from 'helpers/pagination';
import { ViewNotification } from 'modules/common/containers/Notify';
import { translate } from 'modules/localization';
import LocationsApi from 'modules/locations/api/LocationsApi';
import { ILocation, ILocations } from 'modules/locations/models/types';
import WarehousesApi from 'modules/warehouses/api/WarehousesApi';
import { routerStore } from 'services/store';

import { LocationsStore } from './LocationsStore';

export class LocationsApiStore {
  root: LocationsStore;

  constructor(root: LocationsStore) {
    this.root = root;
    makeAutoObservable(this);
  }

  getLocations = async (pagination: IPagination, isRedirect = true): Promise<ILocations[]> => {
    try {
      this.root.activeRequestLocationsList && this.root.activeRequestLocationsList.abort();
      this.root.activeRequestLocationsList = new AbortController();
      const request = this.root.activeRequestLocationsList;

      const { data: res } = await LocationsApi.getLocationsList(
        pagination.current,
        pagination.pageSize,
        request.signal,
      );
      if (isOutOfPages(res.pagination)) {
        return this.getLocations(getLastPagePagination(res.pagination), isRedirect);
      }
      this.root.locationsList = res.data;
      if (isRedirect && !res.errors && res.pagination) {
        routerStore.history.location.pathname !== '/error' &&
          routerStore.replace(
            `${routerStore.location.pathname}?page=${res.pagination.page}&page_size=${res.pagination.page_size}`,
          );
        this.root.locationsPagination = {
          current: res.pagination.page,
          pageSize: res.pagination.page_size,
          total: res.pagination.total,
        };
      }
      return res.data;
    } catch (e) {
      throw new RestException(e);
    } finally {
      if (
        this.root.activeRequestLocationsList &&
        !this.root.activeRequestLocationsList.signal.aborted
      ) {
        this.root.activeRequestLocationsList = null;
      }
    }
  };

  getLocationsChildren = async (
    pagination: IPagination,
    locationGuid: string,
  ): Promise<ILocations[]> => {
    try {
      this.root.activeRequestLocationsList && this.root.activeRequestLocationsList.abort();
      this.root.activeRequestLocationsList = new AbortController();
      const request = this.root.activeRequestLocationsList;

      const { data: res } = await LocationsApi.getLocationsChildrenList(
        pagination.current,
        pagination.pageSize,
        locationGuid,
        request.signal,
      );
      if (isOutOfPages(res.pagination)) {
        return this.getLocationsChildren(getLastPagePagination(res.pagination), locationGuid);
      }
      this.root.locationsList = res.data;
      if (
        !res.errors &&
        res.pagination &&
        res.data.length > 0 &&
        this.root.viewLocation.coverages === 0
      ) {
        routerStore.history.location.pathname !== '/error' &&
          routerStore.replace(
            `/geo/locations/${locationGuid}/locations?page=${res.pagination.page}&page_size=${res.pagination.page_size}`,
          );
        this.root.locationsPagination = {
          current: res.pagination.page,
          pageSize: res.pagination.page_size,
          total: res.pagination.total,
        };
      }
      return res.data;
    } catch (e) {
      throw new RestException(e);
    } finally {
      if (
        this.root.activeRequestLocationsList &&
        !this.root.activeRequestLocationsList.signal.aborted
      ) {
        this.root.activeRequestLocationsList = null;
      }
    }
  };

  searchLocations = async (
    query: string,
    pagination: IPagination,
    isRedirect = true,
    locationGuid: string = null,
  ): Promise<ILocations[]> => {
    try {
      this.root.activeRequestLocationsList && this.root.activeRequestLocationsList.abort();
      this.root.activeRequestLocationsList = new AbortController();
      const request = this.root.activeRequestLocationsList;

      const { data: res } = await LocationsApi.searchLocations(
        (pagination && pagination.current) || 1,
        (pagination && pagination.pageSize) || DEFAULT_PAGE_SIZES[1],
        query,
        locationGuid,
        request.signal,
      );
      if (isOutOfPages(res.pagination)) {
        return this.searchLocations(
          query,
          getLastPagePagination(res.pagination),
          isRedirect,
          locationGuid,
        );
      }
      this.root.locationsList = res.data;
      if (isRedirect && !res.errors && res.pagination) {
        routerStore.history.location.pathname !== '/error' &&
          routerStore.replace(
            `${routerStore.location.pathname}?page=${res.pagination.page}&page_size=${res.pagination.page_size}&q=${query}`,
          );
        this.root.locationsPagination = {
          current: res.pagination.page,
          pageSize: res.pagination.page_size,
          total: res.pagination.total,
        };
      }
      return res.data;
    } catch (e) {
      throw new RestException(e);
    } finally {
      if (
        this.root.activeRequestLocationsList &&
        !this.root.activeRequestLocationsList.signal.aborted
      ) {
        this.root.activeRequestLocationsList = null;
      }
    }
  };

  createLocation = async (data: ILocation): Promise<ILocation> => {
    try {
      this.root.loadingForm = true;
      const { data: res } = await LocationsApi.createLocation(data);
      ViewNotification.success({ message: translate('locationCreated') });
      this.root.loadingForm = false;
      return res.data;
    } catch (e) {
      this.root.loadingForm = false;
      throw new RestException(e);
    }
  };

  editLocation = async (locationGuid: string, data: ILocation): Promise<ILocation> => {
    try {
      this.root.loadingForm = true;
      const { data: res } = await LocationsApi.editLocation(locationGuid, data);
      ViewNotification.success({ message: translate('locationEdited') });
      this.root.loadingForm = false;
      return res.data;
    } catch (e) {
      this.root.loadingForm = false;
      throw new RestException(e);
    }
  };

  getLocation = async (locationGuid: string): Promise<ILocation> => {
    try {
      this.root.loadingForm = true;
      const { data: res } = await LocationsApi.getLocation(locationGuid);
      const resCoveragesCount = res.data.coverages ? res.data.coverages.length : 0;
      const viewLocation = { ...res.data, coverages: resCoveragesCount };
      await this.getLocationCurrency(viewLocation);
      this.root.setFormData(viewLocation);
      this.root.setCoordinatesMap({
        zoomLevel: res.data.scale,
        lat: res.data.point.lat,
        lon: res.data.point.lon,
      });
      this.root.loadingForm = false;

      this.root.setViewLocation(viewLocation);
      return viewLocation;
    } catch (e) {
      this.root.loadingForm = false;
      throw new RestException(e);
    }
  };

  getLocationCurrency = async (location: ILocation): Promise<void> => {
    const warehouseGuid =
      location?.warehouses && location?.warehouses.length > 0 ? location?.warehouses[0].guid : null;
    if (warehouseGuid) {
      const { data: warehouseRes } = await WarehousesApi.getWarehouse(warehouseGuid);
      this.root.currency =
        warehouseRes.data?.shops && warehouseRes.data?.shops.length > 0
          ? warehouseRes.data?.shops[0]?.currency
          : null;
    }
  };

  deleteLocation = async (locationGuid: string): Promise<void> => {
    try {
      await LocationsApi.deleteLocation(locationGuid);
      ViewNotification.success({ message: translate('locationDeleted') });
    } catch (e) {
      throw new RestException(e);
    }
  };
}
