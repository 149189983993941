import React from 'react';

import { Checkbox } from 'antd';
import { observer } from 'mobx-react-lite';

import { ITask } from 'modules/tasks/models/types';
import { getTaskRelativeGuids } from 'modules/tasks/models/utils';
import { useStore } from 'services/store';

interface IProps {
  task: ITask;
}

const TaskCheckbox = ({ task }: IProps) => {
  const { routeGenerator } = useStore();

  const isSelected = routeGenerator.selectedTaskGuids.includes(task.guid);

  return (
    <Checkbox
      className="rg-task__checkbox"
      checked={isSelected}
      onChange={(e) => {
        if (e.target.checked) {
          routeGenerator.selectTaskList(getTaskRelativeGuids(task));
        } else {
          routeGenerator.deselectTaskList(getTaskRelativeGuids(task));
        }
      }}
      data-testid="generator-taskCheckbox"
    />
  );
};

export default observer(TaskCheckbox);
