import './style.less';

import React, { CSSProperties, useEffect, useState } from 'react';

import {
  CheckCircleFilled,
  CloseCircleFilled,
  CloseOutlined,
  InfoCircleTwoTone,
  WarningFilled,
} from '@ant-design/icons';
import { createPortal } from 'react-dom';

import { ViewNotification } from 'modules/common/containers/Notify';
import { TypeNotification } from 'modules/common/containers/Notify/types';

import { createContainerNotification } from '../createContainerNotification';

interface IProps {
  typeNotification: string;
  onDelete: () => void;
  message: string;
  lengthNotifications: number;
  onViewNotification: boolean;
  indexNotification: number;
  description?: string;
}

export const Notification = ({
  typeNotification = TypeNotification.info,
  onDelete,
  message,
  indexNotification,
  lengthNotifications,
  description,
  onViewNotification,
}: IProps) => {
  const [timer, setTimer] = useState<number>(0);
  const [exit, setExit] = useState<boolean>(false);
  const [intervalID, setIntervalID] = useState<NodeJS.Timeout>(null);

  const container = createContainerNotification();

  useEffect(() => {
    handleStartTimer();
  }, []);

  useEffect(() => {
    if (timer === 100) {
      handleCloseNotification();
    }
  }, [timer]);

  const handleStartTimer = () => {
    const id = setInterval(() => {
      setTimer((prev) => {
        if (prev < 100) {
          return prev + 0.5;
        }
        clearInterval(intervalID);
        return prev;
      });
    }, ViewNotification.timeToDelete / 200);
    setIntervalID(id);
  };

  const handlePauseTimer = () => {
    clearInterval(intervalID);
  };

  const handleCloseNotification = () => {
    handlePauseTimer();
    setExit(true);
    setTimeout(() => {
      onDelete();
    }, 200);
  };

  const getStyle = (): CSSProperties => {
    const blocksCount = lengthNotifications > 5 ? 6 : lengthNotifications;
    const bottom = (blocksCount - indexNotification - 1) * 12;
    const bottomCSS =
      indexNotification === 0 && lengthNotifications > 1 ? `${bottom + 100}px` : `${bottom}px`;
    return {
      bottom: bottomCSS,
    };
  };

  return createPortal(
    <div
      className={`notification ${typeNotification} ${indexNotification} ${
        !onViewNotification ? 'none' : ''
      }  ${exit ? 'shrink' : ''}`}
      onMouseEnter={handlePauseTimer}
      onMouseLeave={handleStartTimer}
      style={getStyle()}
    >
      {typeNotification === TypeNotification.info && (
        <InfoCircleTwoTone className="notification-icon notification-icon__info" />
      )}
      {typeNotification === TypeNotification.success && (
        <CheckCircleFilled className="notification-icon notification-icon__success" />
      )}
      {typeNotification === TypeNotification.error && (
        <WarningFilled className="notification-icon notification-icon__warning" />
      )}
      {typeNotification === TypeNotification.warning && (
        <CloseCircleFilled className="notification-icon notification-icon__error" />
      )}
      <div>
        <div className="notification-message">{message[0].toUpperCase() + message.slice(1)}</div>
        <div className="notification-description">
          {description && description[0].toUpperCase() + description.slice(1)}
        </div>
      </div>
      <button onClick={() => handleCloseNotification()} className="closeButton">
        <CloseOutlined />
      </button>
    </div>,
    container,
  );
};

export default Notification;
