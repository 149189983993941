import React, { useEffect } from 'react';

import { CloudDownloadOutlined, ExpandOutlined } from '@ant-design/icons';
import { Card, List } from 'antd';
import { observer } from 'mobx-react-lite';

import { DiffFlag } from 'modules/qa/common/flags';
import { useStore } from 'services/store';

const ClusterList = () => {
  const { orderCollectorMonitor } = useStore();

  useEffect(() => {
    orderCollectorMonitor.getClusters({ pageSize: 100, current: 1 });
  }, []);

  return (
    <List
      grid={{ column: 1 }}
      header={<b>Кластеры</b>}
      dataSource={orderCollectorMonitor.clustersList}
      renderItem={(cluster) => {
        const openCluster = () => {
          orderCollectorMonitor.setSidebarType('cluster');
          orderCollectorMonitor.setSidebarData(cluster);
        };
        return (
          <List.Item>
            <Card
              title={cluster.difference ? <DiffFlag /> : null}
              extra={
                <>
                  {!cluster.title && (
                    <>
                      <a
                        href="#"
                        rel="noreferrer"
                        onClick={() => orderCollectorMonitor.loadCluster(cluster.guid)}
                      >
                        <CloudDownloadOutlined />
                      </a>
                      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </>
                  )}
                  <a href="#" rel="noreferrer" onClick={openCluster}>
                    <ExpandOutlined />
                  </a>
                </>
              }
            >
              {cluster.guid}
              {cluster.title ? (
                <p>
                  <b>{cluster.title}</b>
                </p>
              ) : null}
            </Card>
          </List.Item>
        );
      }}
    />
  );
};

export default observer(ClusterList);
