import { Checkbox, Input } from 'antd';

import { IFieldData } from 'helpers/form';
import { IDeliveryMethods, IDeliveryMethodsForm } from 'modules/delivery-methods/models/types';
import { translate } from 'modules/localization';

export const getFields =
  (isView: boolean) =>
  (_, isCreate: boolean): IFieldData => ({
    name: {
      component: Input,
      formItem: {
        label: translate('deliveryMethodTitle'),
        className: 'delivery-methods-form__item',
      },
      name: 'name',
      params: {
        rules: [
          {
            message: translate('requiredValidator'),
            required: true,
          },
        ],
      },
      props: {
        placeholder: translate('enterTitle'),
        disabled: isView,
      },
    },
    code: {
      component: Input,
      formItem: {
        label: translate('deliveryMethodCode'),
        className: 'delivery-methods-form__item',
      },
      name: 'code',
      params: {
        rules: [
          {
            message: translate('requiredValidator'),
            required: true,
          },
        ],
      },
      props: {
        placeholder: translate('enterTitle'),
        disabled: isView,
      },
    },
    disabled: {
      component: Checkbox,
      formItem: {
        label: translate('status'),
        className: 'delivery-methods-form__item',
      },
      name: 'disabled',
      params: {
        initialValue: isCreate,
        valuePropName: 'checked',
      },
      props: {
        children: translate('activity'),
        disabled: isView,
      },
    },
  });

export const getDataToSet = (
  item: IDeliveryMethods,
): IDeliveryMethodsForm | Record<string, never> => {
  return {
    name: item.name,
    code: item.code,
    disabled: !item.disabled,
  };
};

export const prepareData = (formData: IDeliveryMethodsForm): IDeliveryMethodsForm => {
  return {
    disabled: !formData.disabled,
    name: formData.name ? formData.name.trim() : null,
    code: formData.code ? formData.code.trim() : null,
  };
};
