import { ICoveragesPolygon } from 'modules/coverages/models/types';

export const isValidPolygon = (polygon: Partial<ICoveragesPolygon>): boolean =>
  polygon && polygon.geometry.coordinates[0].length > 3;

export const clearPolygon = (polygon: Partial<ICoveragesPolygon>): Partial<ICoveragesPolygon> => {
  return {
    ...polygon,
    geometry: {
      ...polygon.geometry,
      coordinates: polygon.geometry.coordinates.filter((arr) => arr.length > 2),
    },
  };
};
