import React from 'react';

import classSet from 'helpers/classSet';
import { translate } from 'modules/localization';
interface IProps {
  setActiveTab: (name: 'information' | 'setting') => void;
  activeTab: string;
}

const ClusterWorkspaceTabs = ({ setActiveTab, activeTab }: IProps) => {
  return (
    <div className="cw-tabs">
      <button
        className={classSet({
          'cw-tab': true,
          'cw-tab--active': activeTab === 'information',
        })}
        onClick={(): void => setActiveTab('information')}
      >
        {translate('information')}
      </button>
      <button
        className={classSet({
          'cw-tab': true,
          'cw-tab--active': activeTab === 'setting',
        })}
        onClick={(): void => setActiveTab('setting')}
      >
        {translate('setting')}
      </button>
    </div>
  );
};

export default ClusterWorkspaceTabs;
