import './style.less';

import React from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import { translate } from 'modules/localization';
import { useStore } from 'services/store';

interface IProps {
  baseUrl: string;
  guid: string;
}

const ClusterEditButtons = ({ baseUrl, guid }: IProps) => {
  const { clusters, core } = useStore();
  const location = useLocation();

  const remove = () => {
    clusters.delete(guid);
  };

  return (
    <>
      {core.permissions['clusters.update'] && (
        <>
          <div className="cluster-vertical-line" />
          <Link to={`${baseUrl}/${guid}/edit${location.search}`}>
            <Button type="link" className="cluster-button">
              {translate('edit')}
            </Button>
          </Link>
        </>
      )}
      {core.permissions['clusters.delete'] && (
        <>
          <div className="cluster-vertical-line" />
          <Popconfirm onConfirm={remove} placement="rightTop" title={translate('areYouSure')}>
            <Button type="link" className="cluster-button">
              <DeleteOutlined /> {translate('delete')}
            </Button>
          </Popconfirm>
        </>
      )}
    </>
  );
};

export default ClusterEditButtons;
