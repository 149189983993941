import React from 'react';

import { Route, Switch } from 'react-router-dom';

import GeneratorPage from 'modules/routeGenerator/components/RouteGeneratorPage';
import RoutesListPage from 'modules/routes/components/RoutesListPage';
import RouteViewPage from 'modules/routes/components/RoutesViewPage';

const Orders = () => {
  const baseUrl = '/arm2/routes';

  const renderForm = (): React.ReactNode => <GeneratorPage baseUrl={baseUrl} />;

  const renderRoute = (): React.ReactNode => <RouteViewPage baseUrl={baseUrl} />;

  const renderList = (): React.ReactNode => <RoutesListPage />;

  return (
    <Switch>
      <Route exact path={`${baseUrl}/create`} render={renderForm} />
      <Route exact path={`${baseUrl}/:entityGuid/edit`} render={renderForm} />
      <Route exact path={`${baseUrl}/:entityGuid`} render={renderRoute} />
      <Route exact path={baseUrl} render={renderList} />
    </Switch>
  );
};

export default Orders;
