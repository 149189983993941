import { makeAutoObservable } from 'mobx';

import { RestException } from 'api/RestException';
import { getLastPagePagination, getResponsePagination, isOutOfPages } from 'helpers/pagination';
import { IFilter } from 'modules/arm2/models/types';
import OrdersApi from 'modules/orders/api/OrdersApi';
import {
  IOrder,
  IOrderResolutionList,
  IOrderStatus,
  IOrderType,
} from 'modules/orders/models/types';
import { ITask, ITaskForm } from 'modules/tasks/models/types';
import { IPoint } from 'modules/warehouses/models/types';

import { OrdersStore } from './OrdersStore';

export class OrdersApiStore {
  root: OrdersStore;

  constructor(root: OrdersStore) {
    this.root = root;
    makeAutoObservable(this);
  }

  getOrderResolutions = async (): Promise<IOrderResolutionList> => {
    try {
      const { data: res } = await OrdersApi.getOrderResolutions();
      const plain = res.data.plain;
      const dependent = res.data.dependent;

      const resolutions = Object.keys(dependent).reduce(
        (acc, k) => {
          acc[k] = dependent[k].map((r) => ({ value: r, title: plain[r] }));
          return acc;
        },
        {
          all: Object.keys(plain).map((r) => ({ value: r, title: plain[r] })),
        },
      );

      this.root.orderResolutions = resolutions;
      return resolutions;
    } catch (e) {
      throw new RestException(e);
    }
  };

  getOrderStatuses = async (): Promise<IOrderStatus[]> => {
    try {
      const { data: res } = await OrdersApi.getOrderStatuses();
      const statuses = Object.keys(res.data).map((k) => ({ value: k, title: res.data[k] }));
      this.root.orderStatuses = statuses;
      return statuses;
    } catch (e) {
      throw new RestException(e);
    }
  };

  getOrderTypes = async (): Promise<IOrderType[]> => {
    try {
      const { data: res } = await OrdersApi.getOrderTypes();
      const types = Object.keys(res.data).map((k) => ({ value: k, title: res.data[k] }));
      this.root.orderTypes = types;
      return types;
    } catch (e) {
      throw new RestException(e);
    }
  };

  getOrderList = async (filter: IFilter = this.root.filter): Promise<IOrder[]> => {
    try {
      this.root.activeRequestOrderList && this.root.activeRequestOrderList.abort();
      this.root.activeRequestOrderList = new AbortController();
      const request = this.root.activeRequestOrderList;

      const { data: res } = await OrdersApi.getOrdersList(filter, request.signal);
      if (isOutOfPages(res.pagination)) {
        return this.getOrderList({ ...filter, ...getLastPagePagination(res.pagination) });
      }
      this.root.orderList = res.data;
      if (!res.errors && res.pagination) {
        this.root.setFilterToUrl({
          ...filter,
          current: res.pagination.page,
          pageSize: res.pagination.page_size,
        });
        const responsePagination = getResponsePagination(res.pagination);
        this.root.setFilter('current', responsePagination.current);
        this.root.setFilter('pageSize', responsePagination.pageSize);
        this.root.setFilter('total', responsePagination.total);
        this.root.setFilter('deliveryDate', filter.deliveryDate);
      }
      return res.data;
    } catch (e) {
      throw new RestException(e);
    } finally {
      if (this.root.activeRequestOrderList && !this.root.activeRequestOrderList.signal.aborted) {
        this.root.activeRequestOrderList = null;
      }
    }
  };

  getOrder = async (orderGuid: string): Promise<IOrder> => {
    try {
      const { data: res } = await OrdersApi.getOrder(orderGuid);
      this.root.orderList = [res.data];
      return res.data;
    } catch (e) {
      throw new RestException(e);
    }
  };

  createTask = async (orderGuid: string, form: ITaskForm): Promise<ITask> => {
    try {
      const { data: res } = await OrdersApi.createTask(orderGuid, form);
      return res.data;
    } catch (e) {
      throw new RestException(e);
    }
  };

  setCoords = async (orderGuid: string, point: IPoint, destination?: string): Promise<IOrder> => {
    try {
      const { data: res } = await OrdersApi.setCoords(orderGuid, point, destination);
      return res.data;
    } catch (e) {
      throw new RestException(e);
    }
  };

  editCoords = async (orderGuid: string, point: IPoint, destination?: string): Promise<IOrder> => {
    try {
      const { data: res } = await OrdersApi.editCoords(orderGuid, point, destination);
      return res.data;
    } catch (e) {
      throw new RestException(e);
    }
  };
}
