import { makeAutoObservable } from 'mobx';

import { IPagination } from 'api/types';
import { ICoordinatesMap, ILocation, ILocations } from 'modules/locations/models/types';

import { LocationsApiStore } from './LocationsApiStore';
import {
  initialCoordinatesMap,
  initialLocationDataForm,
  initialPagination,
} from '../models/initial';

export class LocationsStore {
  apiStore: LocationsApiStore;

  locationsList: ILocations[] = [];
  activeRequestLocationsList: AbortController | null = null;
  locationsPagination: IPagination = initialPagination;
  searchQuery: string = null;
  locationData: ILocation = initialLocationDataForm;
  loadingForm = false;
  coordinatesMap: ICoordinatesMap = initialCoordinatesMap;
  hoveredGuid: string = null;
  viewLocation: ILocation = initialLocationDataForm;
  currency: string = null;

  get isLoadingLocationsList() {
    return this.activeRequestLocationsList !== null;
  }

  constructor() {
    this.apiStore = new LocationsApiStore(this);
    makeAutoObservable(this);
  }

  setFormData = (item: ILocation): void => {
    this.locationData = item;
  };

  resetFormData = (): void => {
    this.locationData = initialLocationDataForm;
  };

  setCoordinatesMap = (coordinates: ICoordinatesMap): void => {
    this.coordinatesMap = { ...this.coordinatesMap, ...coordinates };
  };

  setSearchQuery = (query: string): void => {
    this.searchQuery = query;
  };

  setViewLocation = (location: ILocation): void => {
    this.viewLocation = location;
  };

  setHoveredGuid = (guid: string): void => {
    this.hoveredGuid = guid;
  };
}
