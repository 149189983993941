import { makeAutoObservable } from 'mobx';
import querystring from 'query-string';

import { LOCAL_STORAGE } from 'constants/index';
import { getInitialPagination } from 'helpers/pagination';
import { setTasksFilter } from 'modules/tasks/helpers/localStorage';
import { initialFilter } from 'modules/tasks/models/initial';
import { routerStore } from 'services/store';

import { TasksApiStore } from './TasksApiStore';
import {
  ITask,
  ITasksFilter,
  ITasksProblem,
  ITasksResolutionList,
  ITasksStatus,
  ITasksType,
} from '../models/types';

export class TasksStore {
  apiStore: TasksApiStore;

  tasksStatuses: ITasksStatus[] = [];
  tasksResolutions: ITasksResolutionList = {};
  tasksTypes: ITasksType[] = [];
  tasksProblems: ITasksProblem[] = [];
  tasksList: ITask[] = [];
  activeRequestTasksList: AbortController[] | null = null;
  filter: ITasksFilter = {
    ...initialFilter,
    ...getInitialPagination(LOCAL_STORAGE.TASKS_FILTER),
  };

  get isLoadingTasksList() {
    return this.activeRequestTasksList !== null;
  }

  constructor() {
    this.apiStore = new TasksApiStore(this);
    makeAutoObservable(this);
  }

  setFilterToUrl = (filter: ITasksFilter = this.filter): void => {
    const qs = querystring.stringify(
      {
        page: filter.current,
        page_size: filter.pageSize,
        q: filter.search,
        date: filter.deliveryDate,
        shop: filter.shop,
        warehouse: filter.warehouses,
        from: filter.from,
        to: filter.to,
        status: filter.status,
        method: filter.deliveryMethod,
        map_show: filter.mapShow,
        type: filter.type,
        is_problem: filter.isProblem,
      },
      { skipNull: true },
    );
    routerStore.history.location.pathname !== '/error' &&
      routerStore.history.location.pathname === '/arm2/tasks' &&
      routerStore.replace(`/arm2/tasks?${qs}`);
  };

  setSearchQuery = (query: string): void => {
    if (query && query.length > 0) {
      this.filter = {
        ...initialFilter,
        deliveryDate: null,
        search: query,
        mapShow: this.filter.mapShow,
      };
    } else {
      this.filter = { ...this.filter, search: null, mapShow: this.filter.mapShow };
    }
  };

  setFilter = (
    key: string,
    value: Record<string, never> | string[] | boolean | number | string,
  ): void => {
    this.filter[key] = value;
    setTasksFilter(this.filter);
  };
}
