import React, { useEffect, useState } from 'react';

import { Button, Form, Modal } from 'antd';
import HTTPStatus from 'http-status';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import { getFieldDataList, renderFields } from 'helpers/form';
import { ViewNotification } from 'modules/common/containers/Notify';
import { IPermission } from 'modules/core/models/types';
import { translate } from 'modules/localization';
import {
  getFields,
  prepareDataToSetFieldsValue,
  prepareSettings,
} from 'modules/warehouses/components/SettingsForm/helpers';
import { routerStore, useStore } from 'services/store';

interface IProps {
  baseUrl: string;
  permissions: IPermission;
}

const SettingsForm = (
  props: IProps &
    RouteComponentProps<{
      entityGuid: string;
      action: string;
    }>,
) => {
  const { warehouses } = useStore();

  const { match, location, baseUrl, permissions } = props;
  const { action, entityGuid } = match.params;
  const [form] = Form.useForm();

  const [settingsCount, updateSettingsCount] = useState(0);

  useEffect(() => {
    if (entityGuid && action === 'set') {
      warehouses.get(entityGuid).then((data) => {
        form.resetFields();
        form.setFieldsValue(prepareDataToSetFieldsValue(data));
        updateSettingsCount(data?.settings?.length || 0);
      });
    } else {
      form.resetFields();
    }
  }, [action]);

  const actionReset = () => {
    updateSettingsCount(0);
    routerStore.replace(`${baseUrl}${location.search}`);
  };

  const handleReset = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    actionReset();
  };

  const handleResponse = (message: string) => (): void => {
    ViewNotification.success({ message });
    actionReset();
  };

  const handleError = (values) => (response) => {
    if (HTTPStatus.BAD_REQUEST === response.http.status) {
      const fieldDataList = getFieldDataList(response, values);
      form.setFields(fieldDataList);
    }
  };

  const submitClickHandler = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    form.submit();
  };

  const handleSubmit = (values) => {
    warehouses
      .editSettings(entityGuid, prepareSettings(values))
      .then(handleResponse(translate('warehouseSettingsEdited')))
      .catch(handleError(values));
  };

  const renderFooter = (): React.ReactNode => {
    const isPermittedSubmit = permissions['warehouses.create'] && permissions['warehouses.update'];
    return (
      <>
        <Button htmlType="button" key="cancel" onClick={handleReset}>
          {translate('cancel')}
        </Button>
        {isPermittedSubmit ? (
          <Button
            htmlType="submit"
            key="save"
            onClick={submitClickHandler}
            type="primary"
            loading={warehouses.isLoadingSettingsForm}
          >
            {translate('save')}
          </Button>
        ) : null}
      </>
    );
  };

  const addAnotherYetForm = (): void => {
    updateSettingsCount(settingsCount + 1);
  };

  const removeAnotherYetForm = (): void => {
    updateSettingsCount(settingsCount - 1);
  };

  const renderSettingsFields = () => {
    const fields = [];
    for (let i = 0; i < settingsCount; i++) {
      const fieldsData = getFields(`${i}`);
      fields.push(
        <div key={i} className="warehouses__settings-item">
          <h2 className="warehouses-form__section-title">{translate('deliveryMethod')}</h2>
          {renderFields(Object.keys(fieldsData), fieldsData)}
        </div>,
      );
    }
    return fields;
  };

  return (
    <Modal
      centered
      className="dir-modal"
      footer={renderFooter()}
      maskClosable={false}
      onCancel={handleReset}
      onOk={handleSubmit}
      title={translate('deliveryMethodsParams')}
      open={action === 'set'}
      width={null}
      forceRender
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit} autoComplete="off">
        {renderSettingsFields()}
        <div className="warehouses__form-actions">
          <Button type="link" onClick={addAnotherYetForm}>
            {translate('addDeliveryMethod')}
          </Button>
          {settingsCount > 0 && (
            <Button danger type="link" onClick={removeAnotherYetForm}>
              {translate('removeLastDeliveryMethod')}
            </Button>
          )}
        </div>
      </Form>
    </Modal>
  );
};

export default withRouter(observer(SettingsForm));
