import React from 'react';

import groupBy from 'lodash.groupby';
import uniqBy from 'lodash.uniqby';

import { translate } from 'modules/localization';
import { ITasksGroupCoverages } from 'modules/routeGenerator/models/types';
import { ITask } from 'modules/tasks/models/types';
import {
  getAmount,
  getRelativeTasksCount,
  getRelativeTasksList,
  getSize,
} from 'modules/tasks/models/utils';

// TODO: может юзать только в представлении, убрать из стора
// TODO: предложить изменения в api
export const groupTasksWithRelatives = (tasks: ITask[], prop = 'group_hash'): ITask[] => {
  const groupedByHash = groupBy(tasks, (t) => t.order[prop]);
  const uniqByHash: ITask[] = uniqBy(tasks, (t) => (t.order[prop] === '' ? t.guid : t.order[prop]));
  return uniqByHash.map((task) => ({
    ...task,
    relative_tasks: task.order[prop] === '' ? [] : groupedByHash[`${task.order[prop]}`],
  }));
};

export const ungroupTasksWithRelatives = (tasks: ITask[]): ITask[] => {
  return tasks.reduce((acc, task) => {
    return [...acc, ...getRelativeTasksList(task)];
  }, []);
};

const splitTasksByType = (tasks: ITask[] = []): { filtered: ITask[]; rest: ITask[] } => {
  const filtered = [];
  const rest = [];

  tasks.forEach((task) => {
    if (task.type === 'delivery_to_warehouse') {
      filtered.push(task);
    } else {
      rest.push(task);
    }
  });

  return { filtered, rest };
};

export const groupTasksWithRelativesRouteInfo = (tasks: ITask[]): ITask[] => {
  const { filtered, rest } = splitTasksByType(tasks);

  return filtered.length === 0
    ? groupTasksWithRelatives(rest)
    : [...groupTasksWithRelatives(rest), { ...filtered[0], relative_tasks: filtered }];
};

export const getTasksWithoutProblems = (tasks: ITask[]): ITask[] =>
  tasks.map((item) => {
    return { ...item, problems: null };
  });

export const getTasksListCount = (tasksList: ITask[]): number => {
  return Array.isArray(tasksList)
    ? tasksList.reduce((acc, task) => acc + getRelativeTasksCount(task), 0)
    : 0;
};

export const getTasksListAddressCount = (tasksList: ITask[]): number => {
  return Array.isArray(tasksList) ? tasksList.length : 0;
};

export const getTasksListAmount = (tasksList: ITask[]): number => {
  if (getTasksListCount(tasksList) === 0) {
    return 0;
  }
  return tasksList.reduce((sum, item) => sum + getAmount(item), 0);
};

export const getTasksListWeight = (tasksList: ITask[]): number => {
  if (getTasksListCount(tasksList) === 0) {
    return 0;
  }
  return tasksList.reduce((sum, item) => sum + getSize('weight', item), 0);
};

export const getTasksListVolume = (tasksList: ITask[]): number => {
  if (getTasksListCount(tasksList) === 0) {
    return 0;
  }
  return tasksList.reduce((sum, item) => sum + getSize('volume', item), 0);
};

export const getTasksListCurrency = (tasksList: ITask[]): string => {
  if (getTasksListCount(tasksList) === 0) {
    return '-';
  }
  return tasksList[0]?.meta?.shop?.currency;
};

const isCoverageExist = (task: ITask): boolean => {
  return (
    task.coverage !== null &&
    task.coverage !== undefined &&
    task.coverage_guid !== null &&
    task.coverage_guid !== undefined &&
    task.coverage_guid !== ''
  );
};

export const groupTasksByCoverages = (tasksList: ITask[]): ITasksGroupCoverages => {
  const tasksGroupCoverages = groupBy(tasksList, (t) =>
    isCoverageExist(t) ? t.coverage_guid : '',
  );
  let newTasksGroupCoverages = null;
  Object.keys(tasksGroupCoverages).map((coverageGuid) => {
    newTasksGroupCoverages = {
      ...newTasksGroupCoverages,
      [coverageGuid]: {
        tasks: tasksGroupCoverages[coverageGuid],
      },
    };
  });
  return newTasksGroupCoverages;
};

export const combineTaskLists = (listA: ITask[], listB: ITask[]): ITask[] => {
  return groupTasksWithRelatives([
    ...ungroupTasksWithRelatives(listA),
    ...ungroupTasksWithRelatives(listB),
  ]);
};

export const filterTasksDuplicates = (
  tasks: ITask[],
  blackList: React.Key[] | string[],
): ITask[] => {
  if (blackList.length === 0) {
    return tasks;
  }
  return tasks.reduce((result, item) => {
    if (!concurrenceCheck(item, blackList)) {
      result.push(item);
    }
    return result;
  }, []);
};

export const filterTasksByRecipient = (tasks: ITask[], recipient: React.Key | string): ITask[] => {
  if (!recipient) {
    return tasks;
  }
  return tasks.filter((task) => recipient === task.meta.recipient_company_short_name);
};

export const filterTaskList = (taskList: ITask[], duplicates: React.Key[] | string[]) => {
  return filterTasksDuplicates(taskList, duplicates);
};

export const getCoveragesFromTaskList = (taskList: ITask[]): { name: string; guid: string }[] => {
  return uniqBy(taskList, (item) => (isCoverageExist(item) ? item.coverage.guid : ''))
    .map((item) => {
      return isCoverageExist(item)
        ? {
            guid: item.coverage.guid,
            name: item.coverage.name,
          }
        : {
            guid: '',
            name: translate('withoutCoverages'),
          };
    })
    .sort((a, b) => (a.name > b.name ? 1 : -1));
};

export const concurrenceCheck = (task: ITask, guids: React.Key[]): boolean => {
  for (let i = 0; i < guids.length; i++) {
    if (task.guid === guids[i]) {
      return true;
    }
  }
  return false;
};
