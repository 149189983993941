import './style.less';

export const createContainerNotification = (): HTMLElement => {
  const portalId = 'notifyContainer';
  let element = document.getElementById(portalId);
  if (element) {
    return element;
  }

  element = document.createElement('div');
  element.setAttribute('id', portalId);
  element.className = 'container-notify';
  document.body.appendChild(element);
  return element;
};
