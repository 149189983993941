import './style.less';

import React from 'react';

import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { useDrop } from 'react-dnd';

import classSet from 'helpers/classSet';
import Card from 'modules/routeGenerator/components/right/RouteCard';
import Content from 'modules/routeGenerator/components/right/RouteContent';
import { IRouteDraft } from 'modules/routeGenerator/models/types';
import { DndTypes, IDndItem } from 'modules/routeGenerator/stores/RouteGeneratorDnDStore';
import { useStore } from 'services/store';

interface IProps {
  cardData: IRouteDraft;
  serialNumber: number;
  routeId: string;
}

interface IDndProps {
  hovered: boolean;
}

const Route = ({ cardData, serialNumber, routeId }: IProps) => {
  const { routeGenerator } = useStore();

  const [{ hovered }, drop] = useDrop<IDndItem, void, IDndProps>(() => ({
    accept: [DndTypes.LEFT_COVERAGE, DndTypes.LEFT_TASK, DndTypes.ROUTE],
    type: DndTypes.ROUTE,
    item: {
      id: cardData.uid,
    },
    drop: (item, monitor) =>
      routeGenerator.dndStore.onDropToRoute(item.id, monitor.getItemType(), routeId),
    collect: (monitor) => {
      return {
        hovered: monitor.isOver(),
      };
    },
  }));

  return (
    <Spin spinning={cardData.isLoadingCard} wrapperClassName="rg__spin--fill">
      <div className={classSet({ 'rg-route': true, 'rg-route--hovered': hovered })}>
        <Card cardData={cardData} serialNumber={serialNumber} routeId={routeId} key={routeId} />
        <div className="rg-route__content" ref={(node) => drop(node)}>
          <Content card={cardData} routeId={routeId} />
        </div>
      </div>
    </Spin>
  );
};

export default observer(Route);
