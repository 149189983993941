import L from 'leaflet';

import customerMarkerIcon from 'icons/customer-icon.svg?react';
import warehouseIconHovered from 'icons/warehouse-select.svg?react';
import warehouseIcon from 'icons/warehouse.svg?react';

export const iconWarehouse = L.icon({
  iconAnchor: [14, 14],
  iconRetinaUrl: `${warehouseIcon}`,
  iconSize: [28, 28],
  iconUrl: `${warehouseIcon}`,
  tooltipAnchor: [0, -12],
});

export const iconWarehouseHovered = L.icon({
  iconAnchor: [14, 14],
  iconRetinaUrl: `${warehouseIconHovered}`,
  iconSize: [28, 28],
  iconUrl: `${warehouseIconHovered}`,
  tooltipAnchor: [0, -12],
});

export const iconCustomerMarker = L.icon({
  iconAnchor: [20, 45],
  iconRetinaUrl: `${customerMarkerIcon}`,
  iconSize: [50, 50],
  iconUrl: `${customerMarkerIcon}`,
  tooltipAnchor: [0, -12],
});
