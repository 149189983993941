import React from 'react';

import { Progress } from 'antd';

import { translate } from 'modules/localization';
import { ITransport } from 'modules/transports/models/types';

interface IProps {
  transport: ITransport;
  weight: string;
  volume: string;
}

const Fullness = ({ transport, weight, volume }: IProps) => {
  const getWeightPercent = (): number => {
    return transport ? (Number(weight) * 100) / transport.weight : 0;
  };

  const getVolumePercent = (): number => {
    return transport ? (Number(volume) * 100) / transport.volume : 0;
  };

  if (transport) {
    return (
      <div className="rg-courier-modal__fullness">
        <div>
          <div className="rg-courier-modal__fullness-header">{translate('weightFullness')}</div>
          <div className="rg-courier-modal__fullness-text">
            <Progress
              type="circle"
              percent={getWeightPercent()}
              size={22}
              strokeWidth={15}
              strokeColor={getWeightPercent() < 100 ? '#00CC66' : '#FAAD14'}
              trailColor="#E3E3E3"
              format={() => null}
            />
            {weight}&nbsp;/&nbsp;{transport.weight}&nbsp;{translate('weightUnit')}
          </div>
        </div>
        <div>
          <div className="rg-courier-modal__fullness-header">{translate('volumeFullness')}</div>
          <div className="rg-courier-modal__fullness-text">
            <Progress
              type="circle"
              percent={getVolumePercent()}
              size={22}
              strokeWidth={15}
              strokeColor={getVolumePercent() < 100 ? '#00CC66' : '#FAAD14'}
              trailColor="#E3E3E3"
              format={() => null}
            />
            {volume}&nbsp;/&nbsp;{transport.volume}&nbsp;{translate('lengthUnit')}
            <sup>3</sup>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default Fullness;
