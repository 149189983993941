import React, { ReactNode, useEffect } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { Select, Spin } from 'antd';
import { SelectProps } from 'antd/lib/select';
import debounce from 'lodash.debounce';
import { observer } from 'mobx-react-lite';
import { BaseSelectRef } from 'rc-select/lib/BaseSelect';

import { DEFAULT_SELECT_PROPS } from 'modules/common/components/Select';
import { translate } from 'modules/localization';
import { ILocation, ILocations } from 'modules/locations/models/types';
import { useStore } from 'services/store';

interface IProps {
  locationParent?: ILocation;
}

const LocationsSelect = React.forwardRef<BaseSelectRef, IProps & SelectProps>(
  ({ locationParent, ...rest }: IProps, ref) => {
    const { locations } = useStore();

    const fetchDefaultOptions = async (): Promise<void> => {
      await locations.apiStore.getLocations({ pageSize: 100, current: 1 }, false);
    };

    useEffect(() => {
      fetchDefaultOptions();
    }, []);

    const fetchOptions = (value: string): void => {
      if (value && value.length > 0) {
        locations.apiStore.searchLocations(value, { pageSize: 100, current: 1 }, false);
      } else {
        fetchDefaultOptions();
      }
    };

    const renderOptions = (): ReactNode => {
      let items: ILocations[] = [...locations.locationsList];
      if (locationParent && locationParent.guid) {
        items = locations.locationsList.filter((item) => item.guid !== locationParent.guid);
        items.push(locationParent);
      }
      return (
        items &&
        items.map((d) => (
          <Select.Option key={d.guid} value={d.guid}>
            {d.name || d.guid} {d.disabled ? ` (${translate('disabled')})` : ''}
          </Select.Option>
        ))
      );
    };

    return (
      <Select
        {...rest}
        ref={ref}
        showSearch
        allowClear
        onSearch={debounce(fetchOptions, 300)}
        notFoundContent={locations.isLoadingLocationsList ? <Spin size="small" /> : null}
        suffixIcon={<SearchOutlined />}
        loading={locations.isLoadingLocationsList}
        filterOption={false}
        defaultActiveFirstOption={false}
        getPopupContainer={(el) => el}
        {...DEFAULT_SELECT_PROPS}
      >
        {renderOptions()}
      </Select>
    );
  },
);

export default observer(LocationsSelect);
