import React from 'react';

import { observer } from 'mobx-react-lite';

import { DEFAULT_DATE } from 'modules/arm2/components/DateFilter';
import Search from 'modules/arm2/components/Search';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

const OrdersSearch = () => {
  const { orders } = useStore();

  const actionSearch = (searchQuery: string): void => {
    orders.apiStore.getOrderList({ ...orders.filter, deliveryDate: null, search: searchQuery });
  };

  const actionListGet = (): void => {
    orders.apiStore.getOrderList({ ...orders.filter, deliveryDate: DEFAULT_DATE, search: null });
  };

  return (
    <Search
      actionSearch={actionSearch}
      actionListGet={actionListGet}
      actionSearchSet={orders.setSearchQuery}
      query={orders.filter.search}
      placeholder={translate('searchByNumber')}
    />
  );
};

export default observer(OrdersSearch);
