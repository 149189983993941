import React from 'react';

import { translate } from 'modules/localization';
import { getTasksListAddressCount, getTasksListCount } from 'modules/tasks/helpers/lists';
import { ITask } from 'modules/tasks/models/types';

interface IProps {
  taskList: ITask[];
  isSimple?: boolean;
}

const TaskListCounter = ({ taskList, isSimple }: IProps) => {
  const addressCount = getTasksListAddressCount(taskList);
  const tasksCount = getTasksListCount(taskList);

  const content = `${addressCount} ${translate('address', {
    count: addressCount,
  })}, ${tasksCount} ${translate('task', { count: tasksCount })}`;

  if (isSimple) {
    return content;
  }

  return (
    <div>
      <b>{translate('totalCount')}: </b>
      {content}
    </div>
  );
};

export default TaskListCounter;
