import './style.less';

import React from 'react';

import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { observer } from 'mobx-react-lite';

import { getFieldDataList } from 'helpers/form';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

const LoginForm = () => {
  const { core } = useStore();
  const [form] = Form.useForm();

  const handleSubmit = (values): void => {
    core.login(values).catch((error) => {
      form.setFields(getFieldDataList(error, values));
    });
  };

  return (
    <Form className="login-form" onFinish={handleSubmit} form={form}>
      <Form.Item
        name="login"
        rules={[{ required: true, message: translate('loginRequiredValidator') }]}
      >
        <Input
          placeholder={translate('login')}
          prefix={<UserOutlined className="login-form__icon" />}
        />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[{ required: true, message: translate('passwordRequiredValidator') }]}
      >
        <Input.Password
          placeholder={translate('password')}
          prefix={<LockOutlined className="login-form__icon" />}
        />
      </Form.Item>
      <div>
        <Button htmlType="submit" type="primary">
          {translate('authorize')}
        </Button>
      </div>
    </Form>
  );
};

export default observer(LoginForm);
