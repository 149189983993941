import './style.less';

import React, { useEffect } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import debounce from 'lodash.debounce';

import { translate } from 'modules/localization';

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actionListGet: (pagination: any) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actionSearch: (query: string, pagination: any) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actionSearchSet: (query: string) => any;
  placeholder: string;
  query: string;
}

export const Search = (props: IProps) => {
  const url = new URL(window.location.href);
  const query = url.searchParams.get('q');

  useEffect(() => {
    props.actionSearchSet(query);
  }, []);

  const sendRequest = (query) => {
    const pagination = {
      current: 1,
      pageSize: +url.searchParams.get('page_size') || 40,
      total: 1,
    };

    if (query.length === 0) {
      props.actionListGet(pagination);
    }
    if (query && query.trim()) {
      props.actionSearch(query.trim(), pagination);
    }
  };

  const search = debounce(sendRequest, 300);

  const handleChange = (e) => {
    props.actionSearchSet(e.target.value);
    search(e.target.value);
  };

  return (
    <Form.Item className="dir__search" key="query">
      <Input
        onChange={handleChange}
        placeholder={props.placeholder || translate('search')}
        allowClear
        value={props.query}
        suffix={<SearchOutlined className="dir__search-icon" />}
      />
    </Form.Item>
  );
};

export default Search;
