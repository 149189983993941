import './style.less';

import React from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Link, useLocation } from 'react-router-dom';

interface IProps {
  baseUrl: string;
  isPermitted: boolean;
  buttonName?: string;
}

export default ({ baseUrl, isPermitted }: IProps) => {
  const location = useLocation();

  return isPermitted ? (
    <Link to={`${baseUrl}/create${location.search}`}>
      <Button
        className="action-button-mobile"
        shape="circle"
        size="small"
        icon={<PlusOutlined />}
      />
    </Link>
  ) : null;
};
