import './style.less';

import React from 'react';

import L from 'leaflet';
import { Marker, Popup, Tooltip } from 'react-leaflet';

import { getGroupIconLeaflet, getStatusIconLeaflet } from 'helpers/statusIcon';
import { isCoordsExist } from 'modules/map/helpers';
import { TaskTooltip } from 'modules/tasks/components/TaskPoint/helpers';
import { getPointProp } from 'modules/tasks/helpers/propHelper';
import { ITask } from 'modules/tasks/models/types';
import { getTaskRelativeGuids, hasRelativeTasks } from 'modules/tasks/models/utils';

interface IProps {
  task: ITask;
  isChecked?: boolean;
  content?: number | string;
  isRoute?: boolean;
  isTooltip?: boolean;
  isShort?: boolean;
  fill?: string;
}

const TaskPoint = ({
  task,
  isRoute,
  isTooltip,
  isChecked,
  content,
  isShort,
  fill,
  ...rest
}: IProps) => {
  const pointProp = getPointProp(task);

  const getIcon = (): L.Icon => {
    if (isRoute && hasRelativeTasks(task)) {
      return isChecked
        ? getStatusIconLeaflet({ status: 'checked', fill, isHighlighted: task.isHighlighted })
        : getGroupIconLeaflet(fill, task.isHighlighted);
    }
    const isProblem = task.problems && task.problems.length > 0;
    return isChecked
      ? getStatusIconLeaflet({ status: 'checked', fill, isHighlighted: task.isHighlighted })
      : getStatusIconLeaflet({
          status: isProblem ? `${task.status}_problem` : task.status,
          fill,
          content,
          isHighlighted: task.isHighlighted,
        });
  };

  const InfoComponent = isTooltip ? Tooltip : Popup;

  return isCoordsExist(task?.order[pointProp]?.lat, task?.order[pointProp]?.lon) ? (
    <Marker
      position={[task.order[pointProp].lat, task.order[pointProp].lon]}
      icon={getIcon()}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      guid={getTaskRelativeGuids(task)}
      task={task}
      checked={isChecked}
      {...rest}
    >
      <InfoComponent className="task-point" closeButton={false}>
        <TaskTooltip task={task} isShort={isShort} />
      </InfoComponent>
    </Marker>
  ) : null;
};

export default TaskPoint;
