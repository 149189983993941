import './style.less';

import React, { useEffect } from 'react';

import { EllipsisOutlined } from '@ant-design/icons';
import { Dropdown, Modal } from 'antd';
import { observer } from 'mobx-react-lite';

import { translate } from 'modules/localization';
import { ITask } from 'modules/tasks/models/types';
import { useStore } from 'services/store';

interface IProps {
  task: ITask;
}

const SupportTaskButtons = ({ task }: IProps) => {
  const { supportFunctional } = useStore();

  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    !supportFunctional.scenarioTaskList && supportFunctional.getSupportScenarioTaskList();
  }, []);

  const startSupportScenario = (key: string, name: string) => {
    modal.confirm({
      cancelText: translate('cancel'),
      okText: translate('yes'),
      icon: null,
      title: name,
      content: translate('areYouSure'),
      onOk: () => {
        supportFunctional.startSupportScenario(task.guid, key);
      },
    });
  };

  const menu =
    supportFunctional.scenarioTaskList &&
    Object.keys(supportFunctional.scenarioTaskList).map((key) => {
      if (
        Array.isArray(task.available_support_scenario) &&
        task.available_support_scenario.indexOf(key) !== -1
      ) {
        return {
          label: supportFunctional.scenarioTaskList[key],
          key: key,
        };
      }
    });

  const handleMenuClick = ({ key }): void =>
    startSupportScenario(String(key), supportFunctional.scenarioTaskList[key]);

  return (
    <div id="fixScroll" style={{ position: 'relative' }}>
      {contextHolder}
      {Array.isArray(task.available_support_scenario) && supportFunctional.scenarioTaskList && (
        <Dropdown
          menu={{
            items: menu,
            onClick: handleMenuClick,
          }}
          trigger={['click']}
          placement="bottomRight"
          getPopupContainer={() => document.getElementById('fixScroll')}
        >
          <EllipsisOutlined className="support-dropdown-icon" />
        </Dropdown>
      )}
    </div>
  );
};

export default observer(SupportTaskButtons);
