import './style.less';

import React, { useEffect, useRef } from 'react';

import { Table } from 'antd';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';

import { IPagination } from 'api/types';
import { DEFAULT_PAGE_SIZES } from 'constants/index';
import { getUrlPagination } from 'helpers/url';
import { getEditUrl } from 'modules/directory/helpers';
import Header from 'modules/locations/components/Header';
import RatesEmptyList from 'modules/rates/components/RatesEmptyList';
import RatesFilter from 'modules/rates/components/RatesFilter';
import { getColumns } from 'modules/rates/components/RatesList/helpers';
import RatesMap from 'modules/rates/components/RatesMap';
import { IRates } from 'modules/rates/models/types';
import { useStore } from 'services/store';

interface IParams {
  locationGuid: string;
}

const RatesList = ({ ...rest }: IRates) => {
  const { rates, router, core, locations, serviceZones } = useStore();

  const { locationGuid } = useParams<IParams>();

  const listRef = useRef(null);
  const scrollIntoView = () => listRef.current.scrollIntoView({ behavior: 'smooth' });

  const location = useLocation<Record<string, never>>();

  useEffect(() => {
    rates.setFilter('locationGuid', locationGuid);
    rates.apiStore.getRates();
  }, []);

  const loadContent = (): void => {
    rates.apiStore.getRates({ ...rates.filter, ...getUrlPagination(rates.filter) });
  };

  const deleteItem = (guid: string): void => {
    rates.apiStore.deleteRates(guid).then(() => loadContent());
  };

  const columns = getColumns(router.location.pathname, location, deleteItem, core.permissions);

  const updateList = ({ current, pageSize }: IPagination): void => {
    rates.setFilter('current', current);
    rates.setFilter('pageSize', pageSize);
    rates.apiStore.getRates();
  };

  const { hoveredGuid } = rates;

  const getRowHandler = (row: IRates) => ({
    className: row.guid === hoveredGuid ? 'rates__row rates__row_hovered' : 'rates__row',
    onClick: (e): void =>
      e.target.parentElement.innerHTML.indexOf(row.name) > -1 &&
      router.push(getEditUrl(router.location.pathname, location, row)),
    onMouseEnter: (): void => {
      serviceZones.setHoveredCoveragesByZone(row.service_zone_guid);
      rates.setHoveredGuid(row.guid);
    },
    onMouseLeave: (): void => {
      serviceZones.setHoveredCoveragesByZone(null);
      serviceZones.setHoveredWarehouses(null);
      rates.setHoveredGuid(null);
    },
  });

  const isLoaded = !rates.isLoadingRatesList && !serviceZones.isLoadingServiceZonesList;

  return (
    <div className="locations-layout">
      <div className="locations-layout__content">
        <div ref={listRef} className="rates-list">
          <Header isInner />
          <RatesFilter />
          {isLoaded && rates.ratesList.length ? (
            <Table
              className="dir__list rates__list"
              dataSource={rates.ratesList}
              columns={columns}
              bordered={false}
              pagination={{
                ...rates.filter,
                simple: core.isMobile,
                showSizeChanger: true,
                pageSizeOptions: DEFAULT_PAGE_SIZES,
                onChange: scrollIntoView,
              }}
              rowKey="guid"
              loading={rates.isLoadingRatesList}
              onChange={updateList}
              onRow={getRowHandler}
              showHeader={false}
              {...rest}
            />
          ) : (
            <RatesEmptyList isServiceZonesExist={serviceZones.serviceZonesList.length > 0} />
          )}
        </div>
      </div>
      {!core.isMobile && (
        <div className="locations-layout__map">
          <RatesMap location={locations.viewLocation} />
        </div>
      )}
    </div>
  );
};

export default observer(RatesList);
