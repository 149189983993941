import Api from 'api/Api';

import {
  IRatesCreateResponse,
  IRatesDeleteResponse,
  IRatesEditResponse,
  IRatesFilter,
  IRatesForm,
  IRatesGetResponse,
  IRatesListGetResponse,
} from '../models/types';

export default class RatesApi {
  static getRatesList(filter: IRatesFilter, signal?: AbortSignal): Promise<IRatesListGetResponse> {
    const query = {
      page: filter.current,
      page_size: filter.pageSize,
      location_guid: filter.locationGuid,
      service_zone_guid_list: filter.serviceZoneGuidList,
      delivery_method_guid_list: filter.deliveryMethodGuidList,
      active_only: filter.activeOnly,
    };
    return Api.get('/api/v1/tariffs', query, signal);
  }

  static deleteLRate(rateGuid: string): Promise<IRatesDeleteResponse> {
    return Api.delete(`/api/v1/tariffs/${rateGuid}`);
  }

  static createRate(data: IRatesForm): Promise<IRatesCreateResponse> {
    return Api.post(data, '/api/v1/tariffs');
  }

  static editRate(rateGuid: string, data: IRatesForm): Promise<IRatesEditResponse> {
    return Api.patch(data, `/api/v1/tariffs/${rateGuid}`);
  }

  static getRate(rateGuid: string): Promise<IRatesGetResponse> {
    return Api.get(`/api/v1/tariffs/${rateGuid}`);
  }
}
