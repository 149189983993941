import './style.less';

import React from 'react';

import { Button } from 'antd';
import { observer } from 'mobx-react-lite';

import Title from 'modules/common/components/Title';
import { useStore } from 'services/store';

import ClusterItemsList from '../ClusterItemsList';
import ClusterList from '../ClusterList';
import Sidebar from '../Sidebar';
import WarehouseList from '../WarehouseList';

const CourierCouncilMonitor = () => {
  const { courierCouncilMonitor } = useStore();

  return (
    <div className="fastmine">
      <Sidebar />
      <div className="fastmine__header">
        <Title size={Title.SIZE.H3} weight={Title.WEIGHT.SEMIBOLD}>
          Мониторинг courier council
        </Title>
        <div className="fastmine__actions">
          <Button
            onClick={courierCouncilMonitor.clearHard}
            danger
            disabled={courierCouncilMonitor.isClearPending}
          >
            Clear hard
          </Button>
        </div>
      </div>
      <div className="fastmine__list list">
        <ClusterList />
        <ClusterItemsList />
        <WarehouseList />
      </div>
    </div>
  );
};

export default observer(CourierCouncilMonitor);
