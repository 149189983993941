import './style.less';

import React from 'react';

import { Link } from 'react-router-dom';

import { translate } from 'modules/localization';
import { routerStore } from 'services/store';

type IEmptyPageProps = {
  description?: string;
  header?: string;
  icon?: string;
};

const EmptyPage = ({ description, header, icon }: IEmptyPageProps) => {
  return (
    <div className="empty-page">
      <div className="empty-page__inner">
        {icon && <img alt="" className="empty-page__icon" src={icon} />}
        {header && <div className="empty-page__header">{header}</div>}
        {description && <div className="empty-page__description">{description}</div>}
        <div className="empty-page__navigation">
          <a href="#" onClick={routerStore.goBack}>
            {translate('goBackward')}
          </a>
          {` ${translate('or')} `}
          <Link to="/">{translate('goIndexPage')}</Link>
        </div>
      </div>
    </div>
  );
};

export default EmptyPage;
