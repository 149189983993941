import {
  IApiResponseErrorMD2,
  IApiResponsePaginationMD2,
  IGenApiResponseMD2,
  IGenResponseAxios,
} from 'api/types';

export enum IWeekdayIDEnum {
  MONDAY = '1',
  TUESDAY = '2',
  WEDNESDAY = '3',
  THURSDAY = '4',
  FRIDAY = '5',
  SATURDAY = '6',
  SUNDAY = '7',
}

interface ITimeslotSettings {
  average_speed?: number;
  preliminary_call?: boolean;
}

interface ITimeslot {
  created_at?: string;
  creator_guid?: string;
  delivery_limit: number;
  end: string; // "10:00"
  guid?: string;
  price: number;
  settings?: ITimeslotSettings;
  start: string; // "09:00"
  updated_at?: string;
  updater_guid?: string;
  weekday: IWeekdayIDEnum;
}

export interface ISchedules {
  created_at?: string;
  creator_guid?: string;
  guid?: string;
  name: string;
  time_slots: ITimeslot[];
  updated_at?: string;
  updater_guid?: string;
}

type ISchedulesListGetApiResponse = IGenApiResponseMD2<
  ISchedules[],
  IApiResponsePaginationMD2,
  IApiResponseErrorMD2
>;

type ISchedulesCreateApiResponse = IGenApiResponseMD2<
  ISchedules,
  IApiResponsePaginationMD2,
  IApiResponseErrorMD2
>;

type ISchedulesEditApiResponse = IGenApiResponseMD2<
  ISchedules,
  IApiResponsePaginationMD2,
  IApiResponseErrorMD2
>;

type ISchedulesGetApiResponse = IGenApiResponseMD2<
  ISchedules,
  IApiResponsePaginationMD2,
  IApiResponseErrorMD2
>;

export type ISchedulesListGetResponse = IGenResponseAxios<ISchedulesListGetApiResponse>;

export type ISchedulesCreateResponse = IGenResponseAxios<ISchedulesCreateApiResponse>;

export type ISchedulesEditResponse = IGenResponseAxios<ISchedulesEditApiResponse>;

export type ISchedulesGetResponse = IGenResponseAxios<ISchedulesGetApiResponse>;
