import './style.less';
import 'leaflet.markercluster/dist/MarkerCluster.css';

import React from 'react';

import L from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
interface IProps extends L.MarkerClusterGroupOptions {
  color?: string;
  clickHandler?: (e) => void;
  withoutForceUpdate?: boolean;
  children: React.ReactNode;
}

const MarkerCluster = ({
  children,
  color = 'gray',
  clickHandler,
  withoutForceUpdate,
  ...rest
}: IProps) => {
  const iconCreateFunction = (cluster): L.DivIcon => {
    return L.divIcon({
      className: '',
      html: `<div class="marker-cluster" style="color: ${color}">
                <div class="marker-cluster__content"><span class="marker-cluster__content-inner">${cluster.getChildCount()}</span></div>
            </div>`,
      iconSize: new L.Point(30, 30),
    });
  };
  return (
    <MarkerClusterGroup
      iconCreateFunction={iconCreateFunction}
      key={withoutForceUpdate ? null : Date.now()}
      zoomToBoundsOnClick={false}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onClick={(event) => {
        if (clickHandler && event.originalEvent.altKey) {
          event.layer.zoomToBounds({ padding: [20, 20] });
        } else if (clickHandler) {
          clickHandler(event);
        } else {
          event.layer.zoomToBounds({ padding: [20, 20] });
        }
      }}
      attribution="markerCluster"
      {...rest}
    >
      {children}
    </MarkerClusterGroup>
  );
};

export default MarkerCluster;
