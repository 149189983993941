import React from 'react';

import Icon from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import { useDrop } from 'react-dnd';

import classSet from 'helpers/classSet';
import { translate } from 'modules/localization';
import { EmptyRouteSvg } from 'modules/routeGenerator/components/common/EmptyRouteSvg';
import { DndTypes, IDndItem } from 'modules/routeGenerator/stores/RouteGeneratorDnDStore';
import { useStore } from 'services/store';

import Title from './Title';

interface IDndProps {
  hovered: boolean;
  highlighted: boolean;
}

const GeneratorRightSideEmpty = () => {
  const { routeGenerator } = useStore();

  const [{ hovered, highlighted }, drop] = useDrop<IDndItem, void, IDndProps>(() => ({
    accept: [DndTypes.LEFT_TASK, DndTypes.LEFT_COVERAGE],
    drop: (item, monitor) => routeGenerator.dndStore.onDropToRoute(item.id, monitor.getItemType()),
    collect: (monitor) => {
      return {
        highlighted: monitor.canDrop(),
        hovered: monitor.isOver(),
      };
    },
  }));

  return (
    <div className="rg-rightside">
      <div className="rg-rightside__header">
        <Title />
      </div>
      <div className="rg-rightside__body">
        <div
          className={classSet({
            'rg-rightside__emptyview': true,
            'rg-rightside__emptyview--hovered': hovered,
            'rg-rightside__emptyview--highlighted': highlighted,
          })}
          ref={drop}
        >
          <Icon component={EmptyRouteSvg} />
          <p>{translate('letsCreateRoute')}</p>
        </div>
      </div>
    </div>
  );
};

export default observer(GeneratorRightSideEmpty);
