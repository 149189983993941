import './style.less';

import React from 'react';

import { Popover } from 'antd';

import { CustomIcon } from 'modules/common/containers/CustomIcon';
import { ITask } from 'modules/tasks/models/types';

interface IProps {
  task: ITask;
}
const TaskCreationReason = ({ task }: IProps) => {
  return task.reason ? (
    <Popover content={task.reason} className="task-reason" overlayClassName="task-reason__popup">
      <CustomIcon type="comment" className="task-reason__icon" />{' '}
    </Popover>
  ) : null;
};

export default TaskCreationReason;
