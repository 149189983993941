import React from 'react';

import { Route, Switch } from 'react-router-dom';

import CourierPage from 'modules/couriers/components/arm/CourierPage';
import CouriersPage from 'modules/couriers/components/arm/CouriersPage';

const Couriers = () => {
  const baseUrl = '/arm2/couriers';
  const renderCouriersPage = (): React.ReactNode => <CouriersPage />;
  const renderCourierPage = (): React.ReactNode => <CourierPage baseUrl={baseUrl} />;

  return (
    <Switch>
      <Route exact path={baseUrl} render={renderCouriersPage} />
      <Route exact path={`${baseUrl}/:entityGuid`} render={renderCourierPage} />
      <Route exact path={`${baseUrl}/:entityGuid/:action`} render={renderCourierPage} />
    </Switch>
  );
};

export default Couriers;
