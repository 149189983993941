import React from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';

import { ITask } from 'modules/tasks/models/types';
import { useStore } from 'services/store';

interface IProps {
  task: ITask;
}

const TaskRemove = ({ task }: IProps) => {
  const { routeGenerator } = useStore();

  return (
    <CloseOutlined
      className="rg-task__remove"
      onClick={() =>
        routeGenerator.removeTaskListFromRouteDraft([task], routeGenerator.openCardRouteDraftId)
      }
    />
  );
};

export default observer(TaskRemove);
