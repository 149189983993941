import React, { ReactNode, useEffect } from 'react';

import { Select } from 'antd';
import querystring from 'query-string';

import { selectFilter } from 'helpers/form';
import { IFilter } from 'modules/arm2/models/types';
import { DEFAULT_SELECT_PROPS } from 'modules/common/components/Select';
import { ISessionStatus } from 'modules/couriers/models/types';
import { translate } from 'modules/localization';
import { IOrderStatus } from 'modules/orders/models/types';
import { IRoutesStatus } from 'modules/routes/models/types';
import { ITasksStatus } from 'modules/tasks/models/types';

interface IProps {
  setFilter: (key: string, value: string[] | string) => void;
  getList: () => void;
  value: string[];
  optionsList: IOrderStatus[] | IRoutesStatus[] | ISessionStatus[] | ITasksStatus[];
  className?: string;
  getFilterFromLocalStorage?: () => IFilter;
}

const StatusFilter = ({
  setFilter,
  getList,
  value,
  optionsList,
  className,
  getFilterFromLocalStorage,
}: IProps) => {
  useEffect(() => {
    const localStorageFilter = getFilterFromLocalStorage && getFilterFromLocalStorage();
    const localStorageStatus = localStorageFilter && localStorageFilter.status;
    const status = querystring.parse(window.location.search).status || localStorageStatus;

    if (status && typeof status === 'string') {
      setFilter('status', [status]);
    }
    if (status && typeof status === 'object') {
      setFilter('status', status);
    }
  }, []);

  const handleFilter = (status): void => {
    setFilter('status', status);
    getList();
  };

  const renderSelectOption = (option: IOrderStatus): ReactNode => {
    if (option) {
      return (
        <Select.Option key={option.value} value={option.value}>
          {option.title}
        </Select.Option>
      );
    }

    return null;
  };

  return (
    <div className={className}>
      <Select
        allowClear
        mode="multiple"
        filterOption={selectFilter}
        onChange={handleFilter}
        placeholder={translate('status')}
        showSearch
        value={value}
        maxTagCount={2}
        popupMatchSelectWidth={false}
        getPopupContainer={(el) => el}
        {...DEFAULT_SELECT_PROPS}
      >
        {optionsList.map(renderSelectOption)}
      </Select>
    </div>
  );
};

export default StatusFilter;
