import { makeAutoObservable } from 'mobx';

import { RestException } from 'api/RestException';
import { getLastPagePagination, getResponsePagination, isOutOfPages } from 'helpers/pagination';
import { IFilter } from 'modules/arm2/models/types';
import { ViewNotification } from 'modules/common/containers/Notify';
import CouriersApi from 'modules/couriers/api/CouriersApi';
import {
  ICourier,
  ICouriersCreateSessionPayload,
  ICouriersEditSessionPayload,
  ICouriersSessionsPayload,
  ISession,
  ISessionStatus,
} from 'modules/couriers/models/types';
import { translate } from 'modules/localization';

import { CouriersStore } from './CouriersStore';

export class CouriersApiStore {
  root: CouriersStore;

  isLoadingSendMaster = false;

  constructor(root: CouriersStore) {
    this.root = root;
    makeAutoObservable(this);
  }

  getCouriers = async (filter: IFilter = this.root.filter): Promise<ICourier[]> => {
    try {
      this.root.activeRequestCouriersList && this.root.activeRequestCouriersList.abort();
      this.root.activeRequestCouriersList = new AbortController();
      const request = this.root.activeRequestCouriersList;

      const { data: res } = await CouriersApi.getCouriersList(filter, request.signal);
      if (isOutOfPages(res.pagination)) {
        return this.getCouriers({ ...filter, ...getLastPagePagination(res.pagination) });
      }
      this.root.couriersList = res.data;
      if (!res.errors && res.pagination) {
        this.root.setFilterToUrl({
          ...filter,
          current: res.pagination.page,
          pageSize: res.pagination.page_size,
        });
        const responsePagination = getResponsePagination(res.pagination);
        this.root.setFilter('current', responsePagination.current);
        this.root.setFilter('pageSize', responsePagination.pageSize);
        this.root.setFilter('total', responsePagination.total);
      }
      return res.data;
    } catch (e) {
      throw new RestException(e);
    } finally {
      if (
        this.root.activeRequestCouriersList &&
        !this.root.activeRequestCouriersList.signal.aborted
      ) {
        this.root.activeRequestCouriersList = null;
      }
    }
  };

  getCouriersForSendMaster = async (warehouses: string[]): Promise<ICourier[]> => {
    try {
      this.isLoadingSendMaster = true;
      const { data: res } = await CouriersApi.getCouriersList({
        current: 1,
        pageSize: 100,
        warehouses,
      });
      this.isLoadingSendMaster = false;
      return res.data;
    } catch (e) {
      this.isLoadingSendMaster = false;
      throw new RestException(e);
    }
  };

  getCourier = async (couriersGuid: string): Promise<ICourier> => {
    try {
      const { data: res } = await CouriersApi.getCourier(couriersGuid);
      this.root.courier = res.data;
      return res.data;
    } catch (e) {
      throw new RestException(e);
    }
  };

  createCourierSession = async (data: ICouriersCreateSessionPayload): Promise<ISession> => {
    try {
      this.root.loadingForm = true;
      const { data: res } = await CouriersApi.createCourierSession(data);
      ViewNotification.success({ message: translate('courierSessionCreated') });
      this.root.loadingForm = false;
      return res.data;
    } catch (e) {
      this.root.loadingForm = false;
      throw new RestException(e);
    }
  };

  editCourierSession = async (
    data: ICouriersEditSessionPayload,
    sessionGuid: string,
  ): Promise<ISession> => {
    try {
      this.root.loadingForm = true;
      const { data: res } = await CouriersApi.editCourierSession(data, sessionGuid);
      ViewNotification.success({ message: translate('courierSessionEdited') });
      this.root.loadingForm = false;
      return res.data;
    } catch (e) {
      this.root.loadingForm = false;
      throw new RestException(e);
    }
  };

  setSessionStatus = async (sessionGuid: string, status: string): Promise<ISession> => {
    try {
      const { data: res } = await CouriersApi.setSessionStatus(sessionGuid, status);
      return res.data;
    } catch (e) {
      throw new RestException(e);
    }
  };

  getCourierSessions = async (data: ICouriersSessionsPayload): Promise<ISession[]> => {
    try {
      this.isLoadingSendMaster = true;
      const { data: res } = await CouriersApi.getCourierSessions(data);
      this.isLoadingSendMaster = false;
      return res.data;
    } catch (e) {
      this.isLoadingSendMaster = false;
      throw new RestException(e);
    }
  };

  deleteSession = async (sessionGuid: string) => {
    try {
      await CouriersApi.deleteSession(sessionGuid);
      ViewNotification.success({ message: translate('courierSessionDeleted') });
    } catch (e) {
      throw new RestException(e);
    }
  };

  getSessionStatuses = async (): Promise<ISessionStatus[]> => {
    try {
      const { data: res } = await CouriersApi.getSessionStatuses();
      const statuses = Object.keys(res.data).map((k) => ({ value: k, title: res.data[k] }));
      this.root.sessionStatuses = statuses;
      return statuses;
    } catch (e) {
      throw new RestException(e);
    }
  };
}
