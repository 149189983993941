import React from 'react';

import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';

import { GEO_BASE_URL } from 'constants/index';
import { translate } from 'modules/localization';
import CreateLink from 'modules/locations/components/LocationsAddButton/CreateLink';
import PlusButton from 'modules/locations/components/LocationsAddButton/PlusButton';
import { useStore } from 'services/store';

interface IParams {
  locationGuid: string;
}

const LocationPlusButton = () => {
  const { core } = useStore();
  const { locationGuid } = useParams<IParams>();

  if (core.permissions['locations.create'] || core.permissions['coverages.create']) {
    return (
      <PlusButton>
        <CreateLink
          baseUrl={`${GEO_BASE_URL}/${locationGuid}/locations`}
          isPermitted={core.permissions['locations.create']}
        >
          {translate('createLocation')}
        </CreateLink>
        <CreateLink
          baseUrl={`${GEO_BASE_URL}/${locationGuid}/coverages`}
          isPermitted={core.permissions['coverages.create']}
        >
          {translate('coverageCreateTitle')}
        </CreateLink>
      </PlusButton>
    );
  } else {
    return null;
  }
};

export default observer(LocationPlusButton);
