import React, { ReactNode } from 'react';

import { Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { Link } from 'react-router-dom';

import { stopPropagation } from 'helpers/stopPropagation';
import Status from 'modules/common/components/Status';
import { IPermission } from 'modules/core/models/types';
import { translate } from 'modules/localization';
import RouteTimeFact from 'modules/routes/components/RouteTimeFact';
import { IRoutes, IRoutesFilter, IRoutesStatus } from 'modules/routes/models/types';
import { groupTasksWithRelativesRouteInfo } from 'modules/tasks/helpers/lists';
import { routerStore } from 'services/store';

export const getCourierUrl = (guid: string, date?: string): string =>
  date ? `/arm2/couriers/${guid}?planned_date=${date}` : `/arm2/couriers/${guid}`;
export const getRoutesUrl = (guid: string): string => `/arm2/routes/${guid}`;

export const getColumns = (
  statuses: IRoutesStatus[],
  permissions: IPermission,
  sortOptions: IRoutesFilter,
): ColumnType<IRoutes>[] => {
  return [
    {
      dataIndex: 'status',
      title: translate('status'),
      key: 'status',
      render: (status): ReactNode => {
        const current = statuses.find((s) => s.value === status);
        return <Status status={current} />;
      },
      width: '18%',
    },
    {
      dataIndex: 'time',
      title: translate('time'),
      key: 'time',
      render: (_, route): ReactNode => <RouteTimeFact route={route} />,
    },
    {
      dataIndex: 'number',
      title: translate('number'),
      key: 'number',
      sorter: true,
      sortOrder: sortOptions.columnKey === 'number' ? sortOptions.order : null,
      render: (number, route): ReactNode => {
        const tasksCount = (route.tasks && route.tasks.length) || 0;
        const addressCount =
          (route.tasks && groupTasksWithRelativesRouteInfo(route.tasks).length) || 0;
        return (
          <>
            <div>
              {permissions['tasks.list'] ? (
                <span onClick={stopPropagation}>
                  <Link to={getRoutesUrl(route.guid)}>#{number}</Link>
                </span>
              ) : (
                `#${number}`
              )}
            </div>
            <div className="routes__gray-text">
              {addressCount} {translate('address', { count: addressCount })}, {tasksCount}{' '}
              {translate('task', { count: tasksCount })}
            </div>
          </>
        );
      },
    },
    {
      title: translate('courier'),
      key: 'courier_full_name',
      sorter: true,
      sortOrder: sortOptions.columnKey === 'courier_full_name' ? sortOptions.order : null,
      render: (_, route): ReactNode => {
        return (
          <>
            <div>
              {permissions['courier-sessions.list']
                ? route.session &&
                  route.session.courier && (
                    <span onClick={stopPropagation}>
                      <Link
                        to={getCourierUrl(route.session.courier_guid, route.session.planned_date)}
                      >
                        {route.session &&
                          route.session.courier &&
                          `${route.session.courier.profile.surname} ${
                            route.session.courier.profile.name
                          } ${
                            route.session.courier.profile.patronymic
                              ? route.session.courier.profile.patronymic
                              : ''
                          }`}
                      </Link>
                    </span>
                  )
                : route.session &&
                  route.session.courier &&
                  route.session &&
                  route.session.courier &&
                  `${route.session.courier.profile.surname} ${route.session.courier.profile.name} ${
                    route.session.courier.profile.patronymic
                      ? route.session.courier.profile.patronymic
                      : ''
                  }`}
            </div>
            {route.session && route.session.transport && (
              <div className="routes__gray-text">
                {route.session.transport.name} {route.session.transport.number}
              </div>
            )}
          </>
        );
      },
    },
    {
      title: translate('coverages'),
      key: 'coverage_list',
      width: '22%',
      render: (_, route): ReactNode => {
        const coveragesList = Array.isArray(route.coverage_list)
          ? route.coverage_list.map((item) => item.name).join(', ')
          : '';
        return (
          <Typography.Paragraph
            ellipsis={{
              rows: 3,
              expandable: false,
              expanded: false,
              suffix: null,
              tooltip: coveragesList,
            }}
          >
            {coveragesList}
          </Typography.Paragraph>
        );
      },
    },
  ];
};

export const getRowHandler = () => (row: IRoutes) => ({
  className: 'routes__raw',
  onClick: (): void => routerStore.push(getRoutesUrl(row.guid)),
});
