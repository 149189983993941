import './style.less';

import React, { useEffect } from 'react';

import { Checkbox, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import querystring from 'query-string';
import { useParams } from 'react-router';

import { getUrlPagination } from 'helpers/url';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

interface IParams {
  locationGuid: string;
}

const ServiceZonesFilter = () => {
  const { serviceZones } = useStore();

  const { filter, setFilter, resetFilter, getServiceZones } = serviceZones;

  const { locationGuid } = useParams<IParams>();

  useEffect(() => {
    if (!filter.locationGuid || filter.locationGuid === locationGuid) {
      const queryIsActive = querystring.parse(window.location.search).is_active;
      const { current, pageSize } = getUrlPagination();
      setFilter('current', current);
      setFilter('pageSize', pageSize);
      queryIsActive &&
        typeof queryIsActive === 'string' &&
        setFilter('isActive', queryIsActive === 'true');
    } else {
      resetFilter();
    }
  }, []);

  const handleChangeActive = (e): void => {
    setFilter('isActive', e.target.checked);
    getServiceZones();
  };

  return (
    <div className="service-zones-filter">
      <Row>
        <Checkbox
          onChange={handleChangeActive}
          checked={filter.isActive}
          className="service-zones__filter-item"
        >
          {translate('hideInactive')}
        </Checkbox>
      </Row>
    </div>
  );
};

export default observer(ServiceZonesFilter);
