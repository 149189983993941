import React from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';

import { stopPropagation } from 'helpers/stopPropagation';

interface IProps {
  isPermitted: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actionDelete: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  message: any;
}

export default ({ isPermitted, item, actionDelete, message }: IProps) => {
  const handleDelete = (item) => (e) => {
    stopPropagation(e);

    actionDelete(item.guid);
  };

  return isPermitted ? (
    <span onClick={stopPropagation} key="delete">
      <Popconfirm
        cancelText={message.deleteCancel}
        onCancel={stopPropagation}
        onConfirm={handleDelete(item)}
        placement="rightTop"
        title={message.deleteTitle}
      >
        <a className="action-button" href="#">
          <CloseOutlined className="action-icon" />
        </a>
      </Popconfirm>
    </span>
  ) : null;
};
