import React, { ReactNode } from 'react';

import { ColumnsType } from 'antd/es/table';

import { IClientGroups } from 'modules/client-groups/models/types';
import { IPermission } from 'modules/core/models/types';
import Actions from 'modules/directory/components/ActionButtons';
import { translate } from 'modules/localization';

export const getColumns = (
  baseUrl: string,
  permissions: IPermission,
  actionDelete: (guid: string) => void,
): ColumnsType<IClientGroups> => {
  return [
    {
      dataIndex: 'name',
      title: translate('title'),
      key: 'name',
    },
    {
      dataIndex: 'disabled',
      render: (disabled): ReactNode => (
        <span>{!disabled ? translate('yes') : translate('no')}</span>
      ),
      title: translate('activity'),
      key: 'isActive',
      width: '10%',
    },
    {
      dataIndex: 'guid',
      key: 'action',
      render: (_, item): ReactNode => (
        <Actions
          item={item}
          baseUrl={baseUrl}
          actionDelete={actionDelete}
          permissions={{
            edit: permissions['client-groups.update'],
            delete: permissions['client-groups.delete'],
          }}
          message={{
            deleteCancel: translate('cancel'),
            deleteTitle: translate('clientGroupDeleteConfirm'),
          }}
        />
      ),
      width: '5%',
    },
  ];
};
