import dayjs from 'dayjs';

import { getField, getFieldErrorList } from 'helpers/form';
import { ICluster, IClusterForm, IClusterFormData } from 'modules/clusters/models/types';

export const getDataToSet = (cluster: ICluster): IClusterFormData => {
  return {
    city_guid: cluster.city.guid,
    warehouseGuids: cluster.cluster_item_list.map((item) => item.warehouse.guid),
    title: cluster.title,
    profile_guid_list: cluster.profile_list.map((item) => item.guid),
    shopGuid: cluster.cluster_item_list[0].shop.guid,
    mode: cluster.settings.mode,
    deliverySettingsList: cluster.settings.delivery_settings_list.map((item) => {
      return {
        delivery_method_guid: item.delivery_method_guid,
        handle_start: dayjs(item.handle_start),
        courier_avg_speed: item.courier_avg_speed,
        order_unloading_delay: item.order_unloading_delay,
        order_actual_strategy: item.order_actual_strategy,
        order_actual_delay: item.order_actual_delay,
        active: !item.disabled,
      };
    }),
  };
};

export const getFieldDataList = (response, valueList) => {
  const fieldErrorList = getFieldErrorList(response);

  return Object.keys(valueList)
    .map((item) => {
      if (item === 'deliverySettingsList') {
        return valueList['deliverySettingsList'].map((a, index) =>
          Object.keys(a).map((item) => {
            return {
              name: ['deliverySettingsList', index, item],
              errors:
                fieldErrorList[`settings.delivery_settings_list[${index}].${item}`] ||
                fieldErrorList[`delivery_settings_list[${index}].${item}`] ||
                [],
              value: valueList.deliverySettingsList[index][item],
            };
          }),
        );
      }
      return getField(fieldErrorList, valueList)(item);
    })
    .flat(2);
};

export const prepareData = (formData: IClusterFormData): IClusterForm => {
  return {
    city_guid: formData.city_guid,
    title: formData.title ? formData.title.trim() : null,
    cluster_item_list: formData.warehouseGuids.map((item) => {
      return {
        warehouse_guid: item,
        shop_guid: formData.shopGuid,
      };
    }),
    profile_guid_list: formData.profile_guid_list,
    settings: {
      delivery_settings_list: formData.deliverySettingsList
        .filter((item) => !!item.delivery_method_guid)
        .map((item) => {
          return {
            courier_avg_speed: Number(item.courier_avg_speed),
            delivery_method_guid: item.delivery_method_guid,
            disabled: !item.active,
            order_actual_delay: Number(item.order_actual_delay),
            order_actual_strategy: item.order_actual_strategy,
            handle_start: dayjs(item.handle_start).format('YYYY-MM-DD[T]HH:mm:ssZ'),
            order_unloading_delay: Number(item.order_unloading_delay),
          };
        }),
      mode: formData.mode,
    },
  };
};
