import React, { ReactNode, useEffect } from 'react';

import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';

import CoveragesItem from 'modules/coverages/components/CoveragesItem';
import { getListMapView } from 'modules/coverages/components/CoveragesList/helpers';
import { ICoverage, ICoveragesPolygon } from 'modules/coverages/models/types';
import { ILocation } from 'modules/locations/models/types';
import Map from 'modules/map/containers/Map';
import MarkerCluster from 'modules/map/containers/MarkerCluster';
import WarehouseMarker from 'modules/warehouse/containers/WarehouseMarker';
import { IWarehouses } from 'modules/warehouses/models/types';
import { useStore } from 'services/store';

interface IParams {
  locationGuid: string;
}

interface IProps {
  location?: ILocation;
}

const RatesMap = ({ location }: IProps) => {
  const { coverage, serviceZones } = useStore();

  const { hoveredCoverages, hoveredWarehouses } = serviceZones;
  const { coveragesList } = coverage;
  const { getServiceZones } = serviceZones;

  const { locationGuid } = useParams<IParams>();

  useEffect(() => {
    coverage.apiStore.getCoverages(false, {
      locationGuid: locationGuid,
      deliveryMethodGuid: 'all',
      isActive: true,
    });
    getServiceZones(
      { pageSize: 100, current: 1, locationGuid: locationGuid, isActive: true },
      false,
    );

    return () => {
      coverage.setCoveragesList([]);
      coverage.setCoveragesListView([]);
    };
  }, []);

  const getMapView = (): ICoveragesPolygon[] => getListMapView(coveragesList);

  const renderCoverage = (item: ICoverage): ReactNode => (
    <CoveragesItem
      key={item.guid}
      coverage={item}
      isHovered={hoveredCoverages.includes(item.guid)}
    />
  );

  const renderWarehousesPoint = (warehouse: IWarehouses): ReactNode => {
    if (warehouse.point && warehouse.point.lat && warehouse.point.lon) {
      const hovered =
        Array.isArray(hoveredWarehouses) &&
        hoveredWarehouses.some((item) => item.guid === warehouse.guid);

      return (
        <WarehouseMarker
          key={warehouse.guid}
          position={[warehouse.point.lat, warehouse.point.lon]}
          name={warehouse.title}
          address={warehouse.address}
          hovered={hovered}
        />
      );
    }
  };

  return (
    <Map mapView={getMapView()}>
      {location && location.warehouses && (
        <MarkerCluster color="#1890FF">
          {location.warehouses.map((item) => renderWarehousesPoint(item))}
        </MarkerCluster>
      )}
      {toJS(coveragesList).map(renderCoverage)}
    </Map>
  );
};

export default observer(RatesMap);
