import { ThemeConfig } from 'antd';

export const customTheme: ThemeConfig = {
  token: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    colorLink: '#00cc66',
    colorLinkHover: '#23D978',
    colorLinkActive: '#23D978',
    colorPrimary: '#00cc66',
    borderRadius: 4,
    colorPrimaryHover: '#49E68D',
    colorPrimaryBgHover: '#E6FFEE',
    colorSuccess: '#00cc66',
    colorWarning: '#faad14',
    colorError: '#f5222d',
    colorInfo: '#1890ff',
    colorTextBase: '#262626',
    colorText: '#595959',
    colorTextTertiary: '#BFBFBF',
    colorBorderSecondary: '#E8E8E8',
    colorBorder: 'rgba(0, 0, 0, 0.15)',
    colorBgLayout: '#fafafa',
    colorFillSecondary: 'rgba(0, 0, 0, 0.04)',
    lineHeightSM: 1.333333,
    lineHeight: 1.42857,
  },
  components: {
    Table: {
      colorFillSecondary: '#ffffff',
      colorFillAlter: '#ffffff',
      padding: 24,
      paddingContentVerticalLG: 12,
    },
  },
};
