import React from 'react';

import { ICluster } from 'modules/clusters/models/types';
import { translate } from 'modules/localization';

interface IProps {
  cluster: ICluster;
  onClick?: (any) => void;
  isActive?: boolean;
}

const Cluster = ({ cluster, onClick, isActive }: IProps) => {
  return (
    <div className={`cluster ${isActive && 'cluster-active'}`} onClick={onClick}>
      <div className="cluster-title">{cluster.title}</div>
      <div className="cluster-count">
        {`${cluster.cluster_item_list.length}\u00A0${translate('warehouse', {
          count: cluster.cluster_item_list.length,
        })}`}
        {`, ${cluster.profile_list.length}\u00A0${translate('courier', {
          count: cluster.profile_list.length,
        })}`}
      </div>
    </div>
  );
};

export default Cluster;
