import React from 'react';

import { observer } from 'mobx-react-lite';

import List from 'modules/directory/containers/List/List';
import { useStore } from 'services/store';

import { getColumns } from './helpers';

interface IProps {
  baseUrl: string;
}

const DeliveryMethodsList = ({ baseUrl }: IProps) => {
  const { deliveryMethods } = useStore();

  return (
    <List
      baseUrl={baseUrl}
      actionListGet={deliveryMethods.getList}
      actionSearch={deliveryMethods.search}
      list={deliveryMethods.list}
      pagination={deliveryMethods.pagination}
      getColumns={getColumns}
      isLoading={deliveryMethods.isLoadingList}
      actionDelete={deliveryMethods.delete}
    />
  );
};

export default observer(DeliveryMethodsList);
