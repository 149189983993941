import React from 'react';

import classSet from 'helpers/classSet';
import { translate } from 'modules/localization';
import { getTasksListCount } from 'modules/tasks/helpers/lists';
import { ITask } from 'modules/tasks/models/types';

interface IProps {
  tasksList: ITask[];
}

const Counter = ({ tasksList }: IProps) => {
  const tasksCount = getTasksListCount(tasksList);

  return (
    <div
      className={classSet({
        'rg-routecard__counter': true,
        'rg-routecard__counter--empty': tasksList.length === 0,
      })}
    >
      {translate('addressesLength', { content: tasksList.length })},{' '}
      {translate('tasksLength', { content: tasksCount })}
    </div>
  );
};

export default Counter;
