import { IOrder } from 'modules/orders/models/types';

export const getPointProp = (order: IOrder): string =>
  order.type === 'customer_to_warehouse' ? 'sender_point' : 'point';

export const getAddressProp = (order: IOrder): string =>
  order.type === 'customer_to_warehouse' ? 'sender_address' : 'address';

export const getWarehouseAddressProp = (order: IOrder): string =>
  order.type === 'customer_to_warehouse' ? 'address' : 'sender_address';

export const getCustomerAddressProp = (order: IOrder): string =>
  order.type === 'customer_to_warehouse' ? 'sender_address' : 'address';

export const getWarehousePointProp = (order: IOrder): string =>
  order.type === 'customer_to_warehouse' ? 'point' : 'sender_point';

export const getCustomerPointProp = (order: IOrder): string =>
  order.type === 'customer_to_warehouse' ? 'sender_point' : 'point';

export const getNameProp = (order: IOrder): string =>
  order.type === 'customer_to_warehouse' ? 'sender_name' : 'recipient_name';

export const getCommentProp = (order: IOrder): string =>
  order.type === 'customer_to_warehouse' ? 'sender_comment' : 'comment';

export const getPhoneProp = (order: IOrder): string =>
  order.type === 'customer_to_warehouse' ? 'sender_phone' : 'recipient_phone';
