import { IApiResponsePaginationMD2, IPagination } from 'api/types';
import { DEFAULT_PAGE_SIZES, DEFAULT_PAGINATION } from 'constants/index';

export const isOutOfPages = (pagination: IApiResponsePaginationMD2): boolean =>
  pagination &&
  pagination.page !== 1 &&
  pagination.page > Math.ceil(pagination.total / pagination.page_size);

export const getLastPagePagination = (pagination: IApiResponsePaginationMD2): IPagination => ({
  current: Math.ceil(pagination.total / pagination.page_size) || 1,
  pageSize: pagination.page_size,
  total: null,
});

export const getResponsePagination = (pagination: IApiResponsePaginationMD2): IPagination => ({
  current: pagination.page,
  pageSize: pagination.page_size,
  total: pagination.total,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export const reduceItemGUID = (acc, item): any => {
  const prevItem = acc[item.guid] || {};
  return {
    ...acc,
    [item.guid]: {
      ...prevItem,
      ...item,
    },
  };
};

const getPaginationFromURL = (): Partial<IPagination> => {
  const url = new URL(window.location.href);
  const urlPageSize = +url.searchParams.get('page_size');
  const urlPageNumber = +url.searchParams.get('page');

  const getPageSize = (): number => {
    if (!urlPageSize) {
      return DEFAULT_PAGE_SIZES[1];
    }
    if (urlPageSize > DEFAULT_PAGE_SIZES[2]) {
      return DEFAULT_PAGE_SIZES[2];
    }

    if (urlPageSize < DEFAULT_PAGE_SIZES[0]) {
      return DEFAULT_PAGE_SIZES[0];
    }

    return urlPageSize;
  };

  const getPageNumber = (): number => {
    if (!urlPageNumber) {
      return 1;
    }
    if (urlPageNumber < 1) {
      return 1;
    }
    return urlPageNumber;
  };

  const pagination: Partial<IPagination> = {};

  if (urlPageSize) {
    pagination.pageSize = getPageSize();
  }

  if (urlPageNumber) {
    pagination.current = getPageNumber();
  }

  return pagination;
};

const getPaginationFromLocalStorage = (key): Partial<IPagination> => {
  const filterString = localStorage.getItem(key);
  return JSON.parse(filterString);
};

export const getInitialPagination = (section) => {
  const localStoragePagination = getPaginationFromLocalStorage(section);
  const urlPagination = getPaginationFromURL();

  return { ...DEFAULT_PAGINATION, ...localStoragePagination, ...urlPagination };
};
