import React from 'react';

import { Tooltip } from 'antd';

import { CustomIcon } from 'modules/common/containers/CustomIcon';
import { translate } from 'modules/localization';
import { ITask } from 'modules/tasks/models/types';
import { hasRelativeTasks } from 'modules/tasks/models/utils';

interface IProps {
  task: ITask;
}

const TaskComment = ({ task }: IProps) => {
  if (hasRelativeTasks(task)) {
    const commentCount = task.relative_tasks.reduce((amount, t) => {
      if (t?.order?.comment || t?.order?.sender_comment) {
        amount++;
      }
      return amount;
    }, 0);

    return commentCount > 0 ? (
      <Tooltip
        trigger="click"
        placement="top"
        color={'#FFFFFF'}
        overlayInnerStyle={{ color: '#262626' }}
        title={
          <>
            {commentCount} {translate('comment', { commentCount })}
          </>
        }
      >
        <span>
          <CustomIcon type="comment" className="rg-task__comment" />
        </span>
      </Tooltip>
    ) : null;
  }

  const comment = task?.order?.comment || task?.order?.sender_comment;

  return comment ? (
    <Tooltip trigger="click" placement="bottom" title={comment}>
      <span>
        <CustomIcon type="comment" className="rg-task__comment" />
      </span>
    </Tooltip>
  ) : null;
};

export default TaskComment;
