import './style.less';

import React, { useEffect, useState } from 'react';

import { Button, Checkbox, Empty, Modal, Spin } from 'antd';
import { observer } from 'mobx-react-lite';

import empty from 'img/empty-couriers.svg?react';
import Loader from 'modules/common/containers/Loader';
import { ViewNotification } from 'modules/common/containers/Notify';
import { ICouriersWithSessions } from 'modules/couriers/models/types';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

import DateFilter from './DateFilter';
import {
  ISelectedRoute,
  getDefaultSelectedRoutes,
  getFullCouriersData,
  getAllRoutes,
  isInclude,
} from './helpers';
import List from './List';
import WarehouseFilter from './WarehouseFilter';

interface IProps {
  isVisible: boolean;
  closeMaster: () => void;
  preselectedRoutes?: ISelectedRoute[];
  defaultFilters: { warehouses: string[]; date: string };
}

const CourierSendMaster = ({
  isVisible,
  closeMaster,
  preselectedRoutes,
  defaultFilters,
}: IProps) => {
  const { couriers, routes } = useStore();

  const [isLoadingSendForLoading, setIsLoadingSendForLoading] = useState(false);
  const [filter, setFilter] = useState(defaultFilters);
  const [fullCouriersData, setFullCouriersData] = useState<ICouriersWithSessions[]>([]);
  const [selectedRoutes, setSelectedRoutes] = useState<ISelectedRoute[]>(preselectedRoutes || []);
  const reset = () => {
    setFullCouriersData([]);
    setSelectedRoutes([]);
  };
  const close = () => {
    reset();
    couriers.apiStore.getCouriers();
    closeMaster();
  };

  const getFullData = (filter) => {
    if (couriers.apiStore.isLoadingSendMaster) {
      return;
    }
    couriers.apiStore.getCouriersForSendMaster(filter.warehouses).then((couriersList) => {
      if (couriersList.length > 0) {
        couriers.apiStore
          .getCourierSessions({
            courier_guid: couriersList.map((i) => i.guid),
            planned_date: [filter.date],
            current: 1,
            pageSize: 100,
          })
          .then((sessionsList) => {
            const data = getFullCouriersData(couriersList, sessionsList);
            setFullCouriersData(data);
            const allRoutesInData = getAllRoutes(data);
            const defaultRoutesInData = getDefaultSelectedRoutes(data);
            const newSelectedRoutes =
              preselectedRoutes && isInclude(allRoutesInData, preselectedRoutes)
                ? preselectedRoutes
                : defaultRoutesInData;
            setSelectedRoutes(newSelectedRoutes);
          });
      } else {
        reset();
      }
    });
  };

  useEffect(() => {
    isVisible && getFullData(filter);
  }, [isVisible]);

  useEffect(() => {
    setFilter(defaultFilters);
  }, [defaultFilters]);

  const getAction = (sessionGuid, sessionStatus, routeGuid) => {
    switch (sessionStatus) {
      case 'planned': {
        return couriers.apiStore
          .setSessionStatus(sessionGuid, 'awaiting_courier')
          .then(() =>
            couriers.apiStore
              .setSessionStatus(sessionGuid, 'in_process')
              .then(() => routes.apiStore.setRouteStatus(routeGuid, 'at_loading')),
          );
      }

      case 'awaiting_courier': {
        return couriers.apiStore
          .setSessionStatus(sessionGuid, 'in_process')
          .then(() => routes.apiStore.setRouteStatus(routeGuid, 'at_loading'));
      }

      case 'in_process': {
        return routes.apiStore.setRouteStatus(routeGuid, 'at_loading');
      }
    }
  };

  const sendCouriers = () => {
    const actions = selectedRoutes.map(({ sessionGuid, sessionStatus, routeGuid }) =>
      getAction(sessionGuid, sessionStatus, routeGuid),
    );
    setIsLoadingSendForLoading(true);
    Promise.all(actions)
      .then(() => {
        ViewNotification.success({ message: translate('couriersSuccessfullySend') });
        setIsLoadingSendForLoading(false);
        close();
      })
      .catch(() => setIsLoadingSendForLoading(false));
  };

  const onChangeSelectAll = (e) =>
    setSelectedRoutes(e.target.checked ? getDefaultSelectedRoutes(fullCouriersData) : []);

  return (
    <Modal
      title={translate('readyToSendCouriers')}
      open={isVisible}
      destroyOnClose
      confirmLoading={isLoadingSendForLoading}
      onCancel={close}
      footer={null}
      styles={{ body: { padding: '0' } }}
      width={564}
    >
      <Spin indicator={<Loader show />} spinning={couriers.apiStore.isLoadingSendMaster}>
        <header className="send-couriers__header">
          <Checkbox
            className="send-couriers__select-all"
            onChange={onChangeSelectAll}
            checked={selectedRoutes.length === getDefaultSelectedRoutes(fullCouriersData).length}
          >
            {translate('selectAll')}
          </Checkbox>
          <div className="send-couriers__filter">
            <WarehouseFilter
              className="send-couriers__warehouse-filter"
              value={filter.warehouses}
              onChange={(warehouses) => {
                setFilter({ ...filter, warehouses });
                getFullData({ ...filter, warehouses });
              }}
            />
            <DateFilter
              className="send-couriers__date-filter"
              value={filter.date}
              onChange={(date) => {
                setFilter({ ...filter, date });
                getFullData({ ...filter, date });
              }}
            />
          </div>
        </header>

        <div className="send-couriers__body">
          {fullCouriersData.length > 0 ? (
            <List
              couriersList={fullCouriersData}
              selectedRoutes={selectedRoutes}
              selectRoutes={setSelectedRoutes}
            />
          ) : (
            <Empty
              className="send-couriers__empty-list"
              image={`${empty}`}
              imageStyle={{
                height: 152,
              }}
              description={translate('noCouriersToSend')}
            />
          )}
        </div>

        <footer className="send-couriers__footer">
          <Button onClick={close}>{translate('cancel')}</Button>
          <Button
            disabled={couriers.apiStore.isLoadingSendMaster || selectedRoutes.length === 0}
            onClick={sendCouriers}
            type="primary"
            loading={isLoadingSendForLoading}
          >
            {translate('sendForLoading')}
          </Button>
        </footer>
      </Spin>
    </Modal>
  );
};

export default observer(CourierSendMaster);
