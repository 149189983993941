import { useStore } from 'services/store';

interface IProps {
  guid: string;
}

const DeliveryMethodName = ({ guid }: IProps) => {
  const { deliveryMethods } = useStore();
  const { list } = deliveryMethods;

  const dm = list && list.length > 0 && list.find((dm) => dm.guid === guid);

  return dm && dm.name;
};

export default DeliveryMethodName;
