export const dateFormat = {
  search: 'YYYY-MM-DD',
  string: 'DD.MM.YYYY',
  short: 'DD.MM',
};

export const timeFormat = {
  full: 'HH:mm:ss',
  simple: 'HH:mm',
  day: 'DD.MM.YYYY HH:mm',
};

const addSpacesToPhone = (value: string): string => {
  switch (value.length) {
    case 11:
      return (
        value.slice(0, 1) +
        ' ' +
        value.slice(1, 4) +
        ' ' +
        value.slice(4, 7) +
        ' ' +
        value.slice(7, 9) +
        ' ' +
        value.slice(9, 11)
      ).trim();
    case 12:
      return (
        value.slice(0, 2) +
        ' ' +
        value.slice(2, 4) +
        ' ' +
        value.slice(4, 8) +
        ' ' +
        value.slice(8, 12)
      ).trim();
    case 13:
      return (
        value.slice(0, 2) +
        ' ' +
        value.slice(2, 5) +
        ' ' +
        value.slice(5, 9) +
        ' ' +
        value.slice(9, 13)
      ).trim();
    case 14:
      return (
        value.slice(0, 2) +
        ' ' +
        value.slice(2, 6) +
        ' ' +
        value.slice(6, 10) +
        ' ' +
        value.slice(10, 14)
      ).trim();
    case 15:
      return (
        value.slice(0, 3) +
        ' ' +
        value.slice(3, 7) +
        ' ' +
        value.slice(7, 11) +
        ' ' +
        value.slice(11, 15)
      ).trim();
    default:
      return value.trim();
  }
};

// 11 digits => +X XXX XXX XX XX Russia
// 12 digits => +XX XX XXXX XXXX China
// 13 digits => +XX XXX XXXX XXXX China
// 14 digits => +XX XXXX XXXX XXXX China
// 15 digits => +XXX XXXX XXXX XXXX ???
export const formatPhone = (value: string): string => {
  if (!value) {
    return '';
  }
  return `+${addSpacesToPhone(value.replace(/\D/g, ''))}`;
};
