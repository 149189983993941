import './style.less';

import React from 'react';

import { Button, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { GEO_BASE_URL } from 'constants/index';
import icon from 'img/empty.svg?react';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

interface IParams {
  locationGuid: string;
}

const LocationsEmptyList = () => {
  const { core } = useStore();
  const { locationGuid } = useParams<IParams>();

  return (
    <div className="empty-list">
      <img alt="" className="empty-list__icon" src={`${icon}`} />
      <div className="empty-list__description">{translate('locationHasNoRates')}</div>
      <div className="empty-list__action">
        {core.permissions['coverages.create'] ? (
          <Link component={Typography.Link} to={`${GEO_BASE_URL}/${locationGuid}/coverages/create`}>
            <Button type="primary">{translate('goToRateCreation')}</Button>
          </Link>
        ) : null}
      </div>
    </div>
  );
};

export default observer(LocationsEmptyList);
