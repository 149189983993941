import React, { ReactNode } from 'react';

import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';

import { stopPropagation } from 'helpers/stopPropagation';
import { getEditUrl } from 'modules/directory/helpers';

interface IProps {
  isPermitted: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: any;
  baseUrl: string;
  children?: ReactNode;
}

export default ({ isPermitted, item, baseUrl, children }: IProps) => {
  const location = useLocation<Record<string, never>>();
  const editUrl = getEditUrl(baseUrl, location, item);

  if (isPermitted) {
    return (
      <span key="edit" onClick={stopPropagation}>
        <Link className="action-button" to={editUrl}>
          {children ? children : <EditOutlined className="action-icon" />}
        </Link>
      </span>
    );
  }

  return (
    <span key="view" onClick={stopPropagation}>
      <Link className="action-button" to={editUrl}>
        <EyeOutlined className="action-icon" />
      </Link>
    </span>
  );
};
