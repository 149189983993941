import React, { useState } from 'react';

import { observer } from 'mobx-react-lite';

import SelectedActions from 'modules/routeGenerator/components/common/SelectedActions';
import { useStore } from 'services/store';

const SelectedTaskActions = () => {
  const { routeGenerator } = useStore();

  const selectedTasks = routeGenerator.selectedTaskGuids;

  const [isClicked, setIsClicked] = useState<boolean>(false);

  const handleClickAddTasksToRoute = () => {
    if (!isClicked) {
      setIsClicked(true);
      setTimeout(() => {
        routeGenerator.moveSelectedTasksToRoute();
        setIsClicked(false);
      }, 100);
    }
  };

  const selectedTaskList = routeGenerator.tasksListFromApi.filter((t) =>
    selectedTasks.includes(t.guid),
  );

  return (
    <SelectedActions
      currency={routeGenerator.currency}
      selectedTaskList={selectedTaskList}
      isClicked={isClicked}
      handlePrimary={handleClickAddTasksToRoute}
      handleCleanup={() => routeGenerator.setSelectedTaskGuids([])}
      titlePrimary="addTasksToRoute"
    />
  );
};

export default observer(SelectedTaskActions);
