import React, { useContext } from 'react';

import dayjs from 'dayjs';

import { checkDateInDate } from 'helpers/date';
import { timeFormat } from 'helpers/string';
import { translate } from 'modules/localization';
import { RouteCardContext } from 'modules/routeGenerator/components/right/RouteContent';
import { ITask } from 'modules/tasks/models/types';

interface IProps {
  task: ITask;
}

const TaskDateTime = ({ task }: IProps) => {
  const { isCalculated, deliveryDate, startTZ, finishTZ } = useContext(RouteCardContext);

  return (
    <div className="rg-task__datetime">
      {isCalculated && task.planned_delivery_time && (
        <b>
          {translate('atTime', {
            content: dayjs(task.planned_delivery_time).tz(startTZ).format(timeFormat.simple),
          })}
          {!checkDateInDate(
            dayjs(task.planned_delivery_time).tz(finishTZ),
            deliveryDate.tz(finishTZ),
          ) && ` / ${dayjs(task.planned_delivery_time).tz(finishTZ).format('DD.MM')}`}
          &nbsp;&nbsp;/&nbsp;
        </b>
      )}
      {task.time_slot_start}&nbsp;-&nbsp;{task.time_slot_end}&nbsp;
    </div>
  );
};

export default TaskDateTime;
