import React from 'react';

import { Select } from 'antd';
import debounce from 'lodash.debounce';
import { observer } from 'mobx-react-lite';

import { CrossIcon } from 'modules/common/components/Icons';
import { DEFAULT_SELECT_PROPS } from 'modules/common/components/Select';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

const SearchFilter = () => {
  const { routeGenerator } = useStore();

  const handleChange = (val): void => {
    routeGenerator.setSearchFilterValue(val);
  };

  const handleBlur = () => {
    routeGenerator.setDefaultSearchOptions();
  };

  const handleSearch = (query) => {
    routeGenerator.search(query);
  };

  return (
    <div className="rg-filter__search">
      <Select
        allowClear={{ clearIcon: <CrossIcon /> }}
        loading={routeGenerator.isLoadingTasksList}
        disabled={routeGenerator.isLoadingTasksList}
        onChange={handleChange}
        placeholder={translate('searchByNumberContractor')}
        showSearch
        onSearch={debounce(handleSearch, 300)}
        filterOption={false}
        onBlur={handleBlur}
        value={routeGenerator.searchFilterValue}
        popupMatchSelectWidth={false}
        getPopupContainer={(el) => el}
        {...DEFAULT_SELECT_PROPS}
      >
        {routeGenerator.currentSearchOptions.map(({ name, value }) => (
          <Select.Option key={value} value={value}>
            {name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default observer(SearchFilter);
