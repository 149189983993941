import './style.less';

import React, { ReactNode, useEffect, useState } from 'react';

import { Button, Checkbox, Form, Input, Modal } from 'antd';
import HTTPStatus from 'http-status';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import { compareWithPasswordValidator, getFieldDataList } from 'helpers/form';
import { ViewNotification } from 'modules/common/containers/Notify';
import { translate } from 'modules/localization';
import ShopsSelect from 'modules/shops/components/ShopsSelect';
import WarehousesSelect from 'modules/warehouses/components/WarehousesSelect';
import { useStore } from 'services/store';

import { getDataToSet, prepareIdentityData, prepareProfileData } from './helpers';
import RoleSelect from '../RoleSelect';

interface IOwnProps {
  baseUrl: string;
}

type IProps = IOwnProps & RouteComponentProps<{ action: string; profileGuid: string }>;

const UserModal = (props: IProps) => {
  const { user } = useStore();

  const { baseUrl, match, history } = props;
  const { action, profileGuid } = match.params;
  const [form] = Form.useForm();
  const [actualProfileData, setActualProfileData] = useState(null);

  const rolesFormData: string[] = Form.useWatch('roles', form);

  useEffect(() => {
    if (profileGuid) {
      user.get(profileGuid).then((data) => {
        setActualProfileData(data);
        form.resetFields();
        form.setFieldsValue(getDataToSet(data));
      });
    } else {
      form.resetFields();
    }
  }, [action]);

  const actionReset = () => {
    const { current, pageSize } = user.pagination;
    const q = user.searchQuery ? `&q=${user.searchQuery}` : '';
    history.replace(`${baseUrl}?page=${current}&page_size=${pageSize}${q}`);
  };

  const handleReset = (e) => {
    e.preventDefault();
    actionReset();
  };

  const handleResponse = (message: string) => () => {
    actionReset();
    ViewNotification.success({ message });
  };

  const handleError = (values) => (response) => {
    if (response.http.status === HTTPStatus.BAD_REQUEST) {
      const fieldDataList = getFieldDataList(response, values);
      form.setFields(fieldDataList);
    }
  };

  const submitClickHandler = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    form.submit();
  };

  const handleSubmit = async (values): Promise<void> => {
    const profileData = prepareProfileData(values);
    const identityData = prepareIdentityData(values);

    if (action === 'edit' && profileGuid) {
      const identityGuid =
        actualProfileData && actualProfileData.identity ? actualProfileData.identity.guid : null;

      if (!identityGuid) {
        await user.createIdentity(profileGuid, identityData);
      } else if (identityData.login || identityData.password) {
        await user.editIdentity(profileGuid, identityGuid, identityData);
      } else {
        await user.deleteIdentity(profileGuid, identityGuid);
      }

      user
        .edit(profileGuid, profileData)
        .then(handleResponse(translate('userEdited')))
        .catch(handleError(values));
    } else {
      const tempProfileGuid = await user.create(profileData);
      user
        .createIdentity(tempProfileGuid, identityData)
        .then(handleResponse(translate('userCreated')))
        .catch(handleError(values));
    }
  };

  const renderFooter = (): ReactNode => {
    return (
      <>
        <Button htmlType="button" key="cancel" onClick={handleReset}>
          {translate('cancel')}
        </Button>
        <Button
          loading={user.isLoadingForm}
          htmlType="submit"
          key="save"
          onClick={submitClickHandler}
          type="primary"
        >
          {action === 'edit' ? translate('save') : translate('add')}
        </Button>
      </>
    );
  };

  return (
    <Modal
      className="dir-modal"
      footer={renderFooter()}
      maskClosable={false}
      onCancel={handleReset}
      onOk={handleSubmit}
      title={action === 'edit' ? translate('userEditTitle') : translate('userCreateTitle')}
      open={action === 'create' || action === 'edit'}
      width={null}
      destroyOnClose
    >
      <Form
        className="user-form"
        form={form}
        onFinish={handleSubmit}
        preserve={false}
        layout="vertical"
      >
        <div className="user-form__section">
          <h2 className="user-form__section-title">{translate('personalData')}</h2>
          <Form.Item
            label={translate('surname')}
            name="surname"
            rules={[{ required: true, message: translate('surnameValidator') }]}
            className="user-form__item"
          >
            <Input placeholder={translate('enterSurname')} />
          </Form.Item>
          <Form.Item
            label={translate('name')}
            name="name"
            rules={[{ required: true, message: translate('nameValidator') }]}
            className="user-form__item"
          >
            <Input placeholder={translate('enterName')} />
          </Form.Item>
          <Form.Item label={translate('patronymic')} name="patronymic" className="user-form__item">
            <Input placeholder={translate('enterPatronymic')} />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            className="user-form__item"
            rules={[{ type: 'email' }]}
          >
            <Input placeholder={translate('enterEmail')} />
          </Form.Item>
          <Form.Item
            label={translate('phone')}
            name="phone"
            className="user-form__item"
            rules={[{ min: 7 }, { max: 15 }, { pattern: /^-?[0-9]*(\\.[0-9]*)?$/ }]}
          >
            <Input placeholder={translate('enterPhone')} />
          </Form.Item>
          <Form.Item
            label={translate('userStatus')}
            name="disabled"
            className="user-form__item"
            valuePropName="checked"
            initialValue={action === 'create'}
          >
            <Checkbox>{translate('activity')}</Checkbox>
          </Form.Item>
        </div>
        <div className="user-form__section">
          <h2 className="user-form__section-title">{translate('authentication')}</h2>
          <Form.Item label={translate('login')} name="login" className="user-form__item">
            <Input placeholder={translate('enterLogin')} autoComplete="new-login" />
          </Form.Item>
          <Form.Item label={translate('password')} name="password" className="user-form__item">
            <Input.Password placeholder={translate('enterPassword')} autoComplete="new-password" />
          </Form.Item>
          <Form.Item
            label={translate('passwordConfirmation')}
            name="password-confirm"
            rules={[
              {
                validator: compareWithPasswordValidator(form),
              },
            ]}
            className="user-form__item"
          >
            <Input.Password placeholder={translate('enterPasswordConfirmation')} />
          </Form.Item>
        </div>
        <div className="user-form__section">
          <h2 className="user-form__section-title">{translate('parameters')}</h2>
          <Form.Item
            label={translate('role')}
            name="roles"
            rules={[{ required: true, message: translate('roleValidator') }]}
            className="user-form__item"
          >
            <RoleSelect />
          </Form.Item>
          <Form.Item label={translate('warehouses')} name="warehouses" className="user-form__item">
            <WarehousesSelect isSelectAllOptionAllowed />
          </Form.Item>
          {rolesFormData?.includes('courier') && (
            <Form.Item label={translate('shops')} name="shops" className="user-form__item">
              <ShopsSelect mode="multiple" />
            </Form.Item>
          )}
        </div>
      </Form>
    </Modal>
  );
};

export default withRouter(observer(UserModal));
