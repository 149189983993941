import React from 'react';

import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';

import { GEO_BASE_URL } from 'constants/index';
import { translate } from 'modules/localization';
import CreateLink from 'modules/locations/components/LocationsAddButton/CreateLink';
import PlusButton from 'modules/locations/components/LocationsAddButton/PlusButton';
import { useStore } from 'services/store';

interface IParams {
  locationGuid: string;
}

const UniversalPlusButton = () => {
  const { core, coverage, serviceZones } = useStore();

  const { locationGuid } = useParams<IParams>();

  if (
    core.permissions['coverages.create'] ||
    core.permissions['service-zones.create'] ||
    core.permissions['tariffs.create']
  ) {
    return (
      <PlusButton>
        <CreateLink
          baseUrl={`${GEO_BASE_URL}/${locationGuid}/coverages`}
          isPermitted={core.permissions['coverages.create']}
        >
          {translate('coverageCreateTitle')}
        </CreateLink>
        {coverage.coveragesList.length > 0 && (
          <CreateLink
            baseUrl={`${GEO_BASE_URL}/${locationGuid}/service-zones`}
            isPermitted={core.permissions['service-zones.create']}
          >
            {translate('createServiceZone')}
          </CreateLink>
        )}
        {serviceZones.serviceZonesList.length > 0 && (
          <CreateLink
            baseUrl={`${GEO_BASE_URL}/${locationGuid}/rates`}
            isPermitted={core.permissions['tariffs.create']}
          >
            {translate('createRate')}
          </CreateLink>
        )}
      </PlusButton>
    );
  } else {
    return null;
  }
};

export default observer(UniversalPlusButton);
