import './style.less';

import React, { ReactNode, useState } from 'react';

import { Button } from 'antd';
import L from 'leaflet';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';

import CoveragesItem from 'modules/coverages/components/CoveragesItemWithOrders';
import { ICoverageByWarehouse } from 'modules/coverages/models/types';
import { translate } from 'modules/localization';
import Map from 'modules/map/containers/Map';
import MarkerCluster from 'modules/map/containers/MarkerCluster';
import OrderMapControl from 'modules/orders/components/OrderMapControl';
import OrderPoint from 'modules/orders/components/OrderPoint';
import Search from 'modules/orders/components/OrdersMapSearch';
import {
  getCustomerPointProp,
  getWarehouseAddressProp,
  getWarehousePointProp,
} from 'modules/orders/helpers/propHelper';
import { IOrder } from 'modules/orders/models/types';
import WarehouseMarker from 'modules/warehouse/containers/WarehouseMarker';
import { IPoint } from 'modules/warehouses/models/types';
import { useStore } from 'services/store';

interface IProps {
  order: IOrder;
  isMarkerEdit?: boolean;
  onSubmitMarketEdit?: () => void;
  onCancelMarkerEdit?: () => void;
  onAddPoint?: (point: IPoint, layerGroup: L.LayerGroup) => void;
  addPointDestination?: 'recipient' | 'sender';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  layerGroup: any;
}

const OrdersMap = ({
  order,
  isMarkerEdit,
  onSubmitMarketEdit,
  onCancelMarkerEdit,
  onAddPoint,
  addPointDestination,
  layerGroup,
}: IProps) => {
  const [hoveredCoverage, setHoveredCoverage] = useState<string>(null);

  const { coverage } = useStore();
  const warehousePoint = order[getWarehousePointProp(order)]
    ? {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [
            order[getWarehousePointProp(order)].lon,
            order[getWarehousePointProp(order)].lat,
          ],
        },
        properties: {
          name: order?.meta?.warehouse?.title,
          address: order[getWarehouseAddressProp(order)],
        },
      }
    : null;

  const customerPoint = order[getCustomerPointProp(order)]
    ? {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [
            order[getCustomerPointProp(order)].lon,
            order[getCustomerPointProp(order)].lat,
          ],
        },
        properties: {},
      }
    : null;

  const mapView =
    layerGroup?.toGeoJSON()?.features?.length > 0
      ? layerGroup?.toGeoJSON()?.features
      : [customerPoint, warehousePoint].filter((point) => point);

  const getAddPointDestination = (): 'customer' | 'warehouse' => {
    if (order.type === 'customer_to_warehouse') {
      return addPointDestination === 'sender' ? 'customer' : 'warehouse';
    }

    return addPointDestination === 'sender' ? 'warehouse' : 'customer';
  };

  const renderCoverage = (item: ICoverageByWarehouse): ReactNode => {
    return (
      <CoveragesItem
        key={item.guid}
        coverage={item}
        onOver={(): void => setHoveredCoverage(item.guid)}
        onOut={(): void => setHoveredCoverage(null)}
        isHovered={hoveredCoverage === item.guid}
        isTooltip
      />
    );
  };

  return (
    order && (
      <>
        <Map isSearch={false} mapView={mapView.length > 0 ? mapView : []}>
          {isMarkerEdit && (
            <Search
              query={addPointDestination === 'sender' ? order.sender_address : order.address}
              onAddPoint={onAddPoint}
              layerGroup={layerGroup}
              destination={getAddPointDestination()}
              existedPoint={customerPoint}
            />
          )}

          {toJS(coverage.coveragesListByOrder).map(renderCoverage)}

          <MarkerCluster>
            {warehousePoint && (
              <WarehouseMarker
                key="warehouse"
                position={[
                  order[getWarehousePointProp(order)].lat,
                  order[getWarehousePointProp(order)].lon,
                ]}
                name={order?.meta?.warehouse?.title}
                address={order[getWarehouseAddressProp(order)]}
              />
            )}
            {!isMarkerEdit && customerPoint && <OrderPoint key="customer" order={order} />}
          </MarkerCluster>

          {isMarkerEdit && (
            <OrderMapControl
              onAddPoint={onAddPoint}
              destination={getAddPointDestination()}
              layerGroup={layerGroup}
            />
          )}
        </Map>

        {isMarkerEdit && (
          <div className="order-map__footer">
            <Button onClick={onCancelMarkerEdit}>{translate('cancel')}</Button>
            <Button type="primary" onClick={onSubmitMarketEdit}>
              {translate('save')}
            </Button>
          </div>
        )}
      </>
    )
  );
};

export default observer(OrdersMap);
