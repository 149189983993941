import React from 'react';

import { ControlOutlined, WarningOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';

import { stopPropagation } from 'helpers/stopPropagation';
import { IPermission } from 'modules/core/models/types';
import Actions from 'modules/directory/components/ActionButtons';
import ButtonEdit from 'modules/directory/components/ActionButtons/ButtonEdit';
import { translate } from 'modules/localization';
import { IWarehouses } from 'modules/warehouses/models/types';

export const getColumns = (
  baseUrl: string,
  permissions: IPermission,
  actionDelete: (guid: string) => void,
): ColumnsType<IWarehouses> => {
  return [
    {
      dataIndex: 'title',
      title: translate('title'),
      key: 'title',
      width: '30%',
    },
    {
      dataIndex: 'address',
      title: translate('addressTitle'),
      key: 'address',
      width: '35%',
    },
    {
      dataIndex: 'phone',
      title: translate('phone'),
      key: 'phone',
      width: '15%',
      ellipsis: true,
    },
    {
      dataIndex: 'disabled',
      render: (disabled, item): React.ReactNode => (
        <>
          <span>{!disabled ? translate('yes') : translate('no')}</span>
          {disabled && !item.point && (
            <ButtonEdit isPermitted item={item} baseUrl={baseUrl}>
              <Tooltip title={translate('warehouseHasNoCoords')}>
                <WarningOutlined className="warehouse__warning-icon" />
              </Tooltip>
            </ButtonEdit>
          )}
        </>
      ),
      title: translate('activity'),
      key: 'isActive',
      width: '10%',
    },
    {
      dataIndex: 'guid',
      key: 'action',
      render: (_, item): React.ReactNode => {
        return (
          <div className="warehouses__actions">
            {permissions['warehouses.update'] && (
              <span onClick={stopPropagation}>
                <Tooltip title={translate('editWarehouseSettings')}>
                  <Link
                    to={`${baseUrl}/${item.guid}/settings/set`}
                    className="warehouse-settings-button"
                  >
                    <ControlOutlined className="warehouse-settings-icon" />
                  </Link>
                </Tooltip>
              </span>
            )}
            <Actions
              item={item}
              baseUrl={baseUrl}
              actionDelete={actionDelete}
              permissions={{
                edit: permissions['warehouses.update'],
                delete: permissions['warehouses.delete'],
              }}
              message={{
                deleteCancel: translate('cancel'),
                deleteTitle: translate('warehouseDeleteConfirm'),
              }}
            />
          </div>
        );
      },
      width: '100px',
    },
  ];
};
