import React, { ReactNode } from 'react';

import { EllipsisOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { ColumnsType } from 'antd/es/table';

import Actions from 'modules/directory/components/ActionLinks';
import { translate } from 'modules/localization';
import { IServiceZones } from 'modules/service-zones/models/types';

export const getColumns = (
  baseUrl: string,
  actionDelete,
  permissions,
): ColumnsType<IServiceZones> => {
  const columns: ColumnsType<IServiceZones> = [
    {
      dataIndex: 'name',
      title: translate('title'),
      key: 'name',
      render: (text): ReactNode => text,
    },
  ];

  if (permissions['service-zones.update'] || permissions['service-zones.delete']) {
    columns.push({
      dataIndex: 'action',
      key: 'action',
      render: (_, item): ReactNode => (
        <Popover
          trigger="hover"
          overlayClassName="action-links-popover"
          placement="bottomRight"
          getTooltipContainer={(el) => el}
          arrow={false}
          content={() => (
            <Actions
              item={item}
              baseUrl={baseUrl}
              actionDelete={actionDelete}
              message={{
                deleteCancel: translate('cancel'),
                deleteTitle: translate('serviceZoneDeleteConfirm'),
              }}
              permissions={{
                edit: permissions['service-zones.update'],
                delete: permissions['service-zones.delete'],
              }}
            />
          )}
        >
          <div className="actions-links-wrapper">
            <EllipsisOutlined className="actions-links" />
          </div>
        </Popover>
      ),
      width: '5%',
    });
  }

  return columns;
};
