import Api from 'api/Api';
import { IPagination } from 'api/types';
import {
  IWarehouseGetResponse,
  IWarehousesCreateResponse,
  IWarehousesDeleteResponse,
  IWarehousesEditResponse,
  IWarehousesListGetResponse,
  IWarehousesPayload,
  IWarehousesSettings,
} from 'modules/warehouses/models/types';

export default class WarehousesApi {
  static getWarehouse(guid: string): Promise<IWarehouseGetResponse> {
    return Api.get(`/api/v1/warehouses/${guid}`);
  }

  static getWarehousesList(
    pagination: IPagination,
    signal?: AbortSignal,
  ): Promise<IWarehousesListGetResponse> {
    const query = {
      page: pagination.current,
      page_size: pagination.pageSize,
    };
    return Api.get('/api/v1/warehouses', query, signal);
  }

  static createWarehouse(data: IWarehousesPayload): Promise<IWarehousesCreateResponse> {
    return Api.post(data, '/api/v1/warehouses');
  }

  static editWarehouse(guid: string, data: IWarehousesPayload): Promise<IWarehousesEditResponse> {
    return Api.patch(data, `/api/v1/warehouses/${guid}`);
  }

  static deleteWarehouse(guid: string): Promise<IWarehousesDeleteResponse> {
    return Api.delete(`/api/v1/warehouses/${guid}`);
  }

  static searchWarehouses(
    q: string,
    pagination: IPagination,
    signal?: AbortSignal,
  ): Promise<IWarehousesListGetResponse> {
    const query = {
      page: pagination.current,
      page_size: pagination.pageSize,
      q,
    };
    return Api.get('/api/v1/search/warehouses', query, signal);
  }

  static editWarehouseSettings(
    guid: string,
    data: IWarehousesSettings[],
  ): Promise<IWarehousesEditResponse> {
    return Api.put(data, `/api/v1/warehouses/${guid}/settings`);
  }
}
