import { makeAutoObservable } from 'mobx';

import { RestException } from 'api/RestException';
import { IPagination } from 'api/types';
import { getLastPagePagination, isOutOfPages } from 'helpers/pagination';
import ClustersApi from 'modules/clusters/api/ClustersApi';
import CouriersApi from 'modules/couriers/api/CouriersApi';
import OrdersApi from 'modules/orders/api/OrdersApi';
import {
  ICluster,
  ICourier,
  ICourierSession,
  IOrder,
  IRoute,
  ITask,
  ITransport,
  IWarehouse,
  IClusterItem,
} from 'modules/qa/models/types';
import FastmineMonitorApi from 'modules/qa-monitor-fastmine/api/FastmineMonitorApi';
import RoutesApi from 'modules/routes/api/RoutesApi';
import TasksApi from 'modules/tasks/api/TasksApi';
import TransportApi from 'modules/transports/api/TransportApi';
import UserApi from 'modules/user/api/UserApi';
import WarehousesApi from 'modules/warehouses/api/WarehousesApi';

export class FastmineMonitorStore {
  tasksList: ITask[] = [];
  clustersList: ICluster[] = [];
  couriersList: ICourier[] = [];
  courierSessionsList: ICourierSession[] = [];
  ordersList: IOrder[] = [];
  routesList: IRoute[] = [];
  transportsList: ITransport[] = [];
  warehousesList: IWarehouse[] = [];
  clusterItemsList: IClusterItem[];

  sidebarType: string = null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sidebarData: any = null;

  isClearPending = false;

  constructor() {
    makeAutoObservable(this);
  }

  getTasks = async (pagination: IPagination): Promise<ITask[]> => {
    try {
      const { data: res } = await FastmineMonitorApi.getTasks(pagination);
      if (isOutOfPages(res.pagination)) {
        return this.getTasks(getLastPagePagination(res.pagination));
      }
      this.tasksList = res.data;
      return res.data;
    } catch (e) {
      throw new RestException(e);
    }
  };

  loadTask = async (guid: string): Promise<void> => {
    try {
      const { data: res } = await TasksApi.getTask(guid);
      const entity = res.data;
      this.tasksList = this.tasksList.map((e) => {
        if (e.guid === guid) {
          return { ...entity, ...e };
        }
        return e;
      });
    } catch (e) {
      throw new RestException(e);
    }
  };

  getCouriers = async (pagination: IPagination): Promise<ICourier[]> => {
    try {
      const { data: res } = await FastmineMonitorApi.getCouriers(pagination);
      if (isOutOfPages(res.pagination)) {
        return this.getCouriers(getLastPagePagination(res.pagination));
      }
      this.couriersList = res.data;
      return res.data;
    } catch (e) {
      throw new RestException(e);
    }
  };

  loadCourier = async (guid: string): Promise<void> => {
    try {
      const { data: res } = await UserApi.getProfile(guid);
      const entity = res.data;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // Странные типы у нас в qa
      this.couriersList = this.couriersList.map((e) => {
        if (e.guid === guid) {
          return { ...entity, ...e };
        }
        return e;
      });
    } catch (e) {
      throw new RestException(e);
    }
  };

  getTransports = async (pagination: IPagination): Promise<ITransport[]> => {
    try {
      const { data: res } = await FastmineMonitorApi.getTransports(pagination);
      if (isOutOfPages(res.pagination)) {
        return this.getTransports(getLastPagePagination(res.pagination));
      }
      this.transportsList = res.data;
      return res.data;
    } catch (e) {
      throw new RestException(e);
    }
  };

  loadTransport = async (guid: string): Promise<void> => {
    try {
      const { data: res } = await TransportApi.getTransport(guid);
      const entity = res.data;
      this.transportsList = this.transportsList.map((e) => {
        if (e.guid === guid) {
          return { ...entity, ...e };
        }
        return e;
      });
    } catch (e) {
      throw new RestException(e);
    }
  };

  getWarehouses = async (pagination: IPagination): Promise<IWarehouse[]> => {
    try {
      const { data: res } = await FastmineMonitorApi.getWarehouses(pagination);
      if (isOutOfPages(res.pagination)) {
        return this.getWarehouses(getLastPagePagination(res.pagination));
      }
      this.warehousesList = res.data;
      return res.data;
    } catch (e) {
      throw new RestException(e);
    }
  };

  loadWarehouse = async (guid: string): Promise<void> => {
    try {
      const { data: res } = await WarehousesApi.getWarehouse(guid);
      const entity = res.data;
      this.warehousesList = this.warehousesList.map((e) => {
        if (e.guid === guid) {
          return { ...entity, ...e };
        }
        return e;
      });
    } catch (e) {
      throw new RestException(e);
    }
  };

  getClusters = async (pagination: IPagination): Promise<ICluster[]> => {
    try {
      const { data: res } = await FastmineMonitorApi.getClusters(pagination);
      if (isOutOfPages(res.pagination)) {
        return this.getClusters(getLastPagePagination(res.pagination));
      }
      this.clustersList = res.data;
      return res.data;
    } catch (e) {
      throw new RestException(e);
    }
  };

  loadCluster = async (guid: string): Promise<void> => {
    try {
      const { data: res } = await ClustersApi.getCluster(guid);
      const entity = res.data;
      this.clustersList = this.clustersList.map((e) => {
        if (e.guid === guid) {
          return { ...entity, ...e };
        }
        return e;
      });
    } catch (e) {
      throw new RestException(e);
    }
  };

  getClusterItems = async (pagination: IPagination): Promise<IClusterItem[]> => {
    try {
      const { data: res } = await FastmineMonitorApi.getClustersItems(pagination);
      if (isOutOfPages(res.pagination)) {
        return this.getClusterItems(getLastPagePagination(res.pagination));
      }
      this.clusterItemsList = res.data;
      return res.data;
    } catch (e) {
      throw new RestException(e);
    }
  };

  loadWarehouseForClusterItem = async (guid: string): Promise<void> => {
    try {
      const { data: res } = await WarehousesApi.getWarehouse(guid);
      const warehouse = res.data;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // Странные типы у нас в qa
      this.clusterItemsList = this.clusterItemsList.map((e) => {
        if (e.warehouse_guid === guid) {
          return { ...e, warehouse: warehouse };
        }
        return e;
      });
    } catch (e) {
      throw new RestException(e);
    }
  };

  getCouriesSessions = async (pagination: IPagination): Promise<ICourierSession[]> => {
    try {
      const { data: res } = await FastmineMonitorApi.getCoiriersSessions(pagination);
      if (isOutOfPages(res.pagination)) {
        return this.getCouriesSessions(getLastPagePagination(res.pagination));
      }
      this.courierSessionsList = res.data;
      return res.data;
    } catch (e) {
      throw new RestException(e);
    }
  };

  loadCourierForSession = async (guid: string): Promise<void> => {
    try {
      const { data: res } = await CouriersApi.getCourier(guid);
      const courier = res.data;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // Странные типы у нас в qa
      this.courierSessionsList = this.courierSessionsList.map((e) => {
        if (e.courier_guid === guid) {
          return { ...e, courier: courier };
        }
        return e;
      });
    } catch (e) {
      throw new RestException(e);
    }
  };

  getOrders = async (pagination: IPagination): Promise<IOrder[]> => {
    try {
      const { data: res } = await FastmineMonitorApi.getOrders(pagination);
      if (isOutOfPages(res.pagination)) {
        return this.getOrders(getLastPagePagination(res.pagination));
      }
      this.ordersList = res.data;
      return res.data;
    } catch (e) {
      throw new RestException(e);
    }
  };

  loadOrder = async (guid: string): Promise<void> => {
    try {
      const { data: res } = await OrdersApi.getOrder(guid);
      const entity = res.data;
      this.ordersList = this.ordersList.map((e) => {
        if (e.guid === guid) {
          return { ...entity, ...e };
        }
        return e;
      });
    } catch (e) {
      throw new RestException(e);
    }
  };

  getRoutes = async (pagination: IPagination): Promise<IRoute[]> => {
    try {
      const { data: res } = await FastmineMonitorApi.getRoutes(pagination);
      if (isOutOfPages(res.pagination)) {
        return this.getRoutes(getLastPagePagination(res.pagination));
      }
      this.routesList = res.data;
      return res.data;
    } catch (e) {
      throw new RestException(e);
    }
  };

  loadRoute = async (guid: string): Promise<void> => {
    try {
      const { data: res } = await RoutesApi.getRoute(guid);
      const entity = res.data;
      this.routesList = this.routesList.map((e) => {
        if (e.guid === guid) {
          return { ...entity, ...e };
        }
        return e;
      });
    } catch (e) {
      throw new RestException(e);
    }
  };

  setSidebarType = (type: string): void => {
    this.sidebarType = type;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSidebarData = (data: any): void => {
    this.sidebarData = data;
  };

  clearSoft = async (): Promise<void> => {
    this.isClearPending = true;
    await FastmineMonitorApi.clearSoft();
    this.isClearPending = false;
  };

  clearHard = async (): Promise<void> => {
    this.isClearPending = true;
    await FastmineMonitorApi.clearHard();
    this.isClearPending = false;
  };
}
