import './style.less';

import React from 'react';

import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';
import { Link, Route } from 'react-router-dom';

import { GEO_BASE_URL } from 'constants/index';
import { PageTitle } from 'modules/common/components/HeaderMobile';
import Title from 'modules/common/components/Title';
import { translate } from 'modules/localization';
import CreateButton from 'modules/locations/components/LocationsAddButton';
import ButtonCreate from 'modules/locations/components/LocationsAddButton/CreateButton';
import LocationsNav from 'modules/locations/components/LocationsNav';
import { useStore } from 'services/store';

interface IProps {
  isInner?: boolean;
}

interface IParams {
  locationGuid: string;
}

const Header = ({ isInner }: IProps) => {
  const { core, locations, router } = useStore();

  const { locationGuid } = useParams<IParams>();

  const handleReset = (): void => {
    const pathname = locations.viewLocation.parent_guid
      ? `${GEO_BASE_URL}/${locations.viewLocation.parent_guid}/locations`
      : GEO_BASE_URL;
    locations.setViewLocation({ ...locations.viewLocation, coverages: 0 });
    router.push({ pathname });
  };

  return isInner ? (
    <Route exact path={`${GEO_BASE_URL}/:locationGuid/:section`}>
      {core.isMobile && (
        <PageTitle>
          <Button type="link" onClick={handleReset}>
            <ArrowLeftOutlined />{' '}
            {locations.viewLocation.parent_guid
              ? locations.viewLocation.parent.name
              : translate('locations')}
          </Button>
        </PageTitle>
      )}
      <div className="locations__header locations__header_inner">
        {!core.isMobile && (
          <Button type="link" className="locations__backlink" onClick={handleReset}>
            <ArrowLeftOutlined />{' '}
            {locations.viewLocation.parent_guid
              ? locations.viewLocation.parent.name
              : translate('locations')}
          </Button>
        )}
        <div className="locations__title">
          <Title size={Title.SIZE.H3} weight={Title.WEIGHT.SEMIBOLD}>
            {locations.viewLocation.name}
            {core.permissions['locations.update'] && (
              <Link
                className="locations__edit"
                replace
                to={`${GEO_BASE_URL}/${locationGuid}/locations/${locationGuid}/edit`}
              >
                <EditOutlined className="locations__edit-icon" />
              </Link>
            )}
          </Title>
          <CreateButton />
        </div>
      </div>
      <LocationsNav />
    </Route>
  ) : (
    <>
      {core.isMobile && <PageTitle>{translate('tariffing')}</PageTitle>}
      <div className="locations__header">
        <div className="locations__title">
          <Title size={Title.SIZE.H3} weight={Title.WEIGHT.SEMIBOLD}>
            {translate('locations')}
          </Title>
          {core.permissions['locations.create'] && (
            <ButtonCreate baseUrl={GEO_BASE_URL} isPermitted={core.permissions['locations.create']}>
              {translate('createLocation')}
            </ButtonCreate>
          )}
        </div>
      </div>
    </>
  );
};

export default observer(Header);
