import React from 'react';

import List from 'modules/account-system/components/AccountSystemList';
import Title from 'modules/common/components/Title';
import { translate } from 'modules/localization';

const AccountSystem = () => {
  return (
    <>
      <div className="dir__header">
        <Title className="dir__title" size={Title.SIZE.H3} weight={Title.WEIGHT.SEMIBOLD}>
          {translate('accountSystems')}
        </Title>
      </div>
      <List />
    </>
  );
};

export default AccountSystem;
