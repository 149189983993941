import React, { ReactNode } from 'react';

import { EllipsisOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { Location } from 'history';
import { Link } from 'react-router-dom';

import { IPermission } from 'modules/core/models/types';
import Actions from 'modules/directory/components/ActionLinks';
import { translate } from 'modules/localization';
import { IRates } from 'modules/rates/models/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getCopyUrl = (baseUrl: string, location: Location<Record<string, never>>, row: any): string =>
  `${baseUrl}/:itemId/copy${location.search}`.split(':itemId').join(row.guid);

export const getColumns = (
  baseUrl: string,
  location: Location<Record<string, never>>,
  actionDelete: (guid: string) => void,
  permissions: IPermission,
): ColumnsType<IRates> => {
  const columns: ColumnsType<IRates> = [
    {
      dataIndex: 'name',
      title: translate('title'),
      key: 'name',
      render: (text, item): ReactNode => (
        <>
          <div>{text}</div>
          <div style={{ color: '#aeaeae' }}>
            {translate('period')}: {dayjs(item.date_time_start).format('DD.MM.YYYY')} -{' '}
            {dayjs(item.date_time_end).format('DD.MM.YYYY')}
          </div>
        </>
      ),
    },
  ];

  if (permissions['tariffs.update'] || permissions['tariffs.delete']) {
    columns.push({
      dataIndex: 'action',
      key: 'action',
      render: (_, item): ReactNode => (
        <Popover
          trigger="hover"
          overlayClassName="action-links-popover"
          placement="bottomRight"
          getTooltipContainer={(el) => el}
          arrow={false}
          content={() => (
            <Actions
              item={item}
              baseUrl={baseUrl}
              actionDelete={actionDelete}
              message={{
                deleteCancel: translate('cancel'),
                deleteTitle: translate('rateDeleteConfirm'),
              }}
              permissions={{
                edit: permissions['tariffs.update'],
                delete: permissions['tariffs.delete'],
              }}
            >
              {permissions['tariffs.create'] && (
                <Link className="action-link" replace to={getCopyUrl(baseUrl, location, item)}>
                  {translate('scheduleCopyTitle')}
                </Link>
              )}
            </Actions>
          )}
        >
          <div className="actions-links-wrapper">
            <EllipsisOutlined className="actions-links" />
          </div>
        </Popover>
      ),
      width: '5%',
    });
  }

  return columns;
};
