import React from 'react';

export const EmptyRouteSmallSvg = () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M25.066 46.1239C25.0309 46.1645 24.9931 46.2025 24.9529 46.2377L25.6118 46.9899L24.9529 46.2378C24.4725 46.6586 23.7453 46.6089 23.3256 46.1239C18.1398 40.1305 14.2779 34.9106 11.7175 30.4589C9.14556 25.9872 7.94434 22.3882 7.94434 19.6115C7.94434 10.6265 15.1863 3.77734 24.1958 3.77734C33.2053 3.77734 40.4473 10.6265 40.4473 19.6115C40.4473 22.3882 39.246 25.9872 36.6741 30.4589C34.1137 34.9106 30.2518 40.1305 25.066 46.1239Z"
        stroke="#A3B1BF"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.668 18.4788L33.1819 17.6298C33.1368 17.5519 33.0363 17.5252 32.9584 17.5703L31.9186 18.1773L30.3129 13.7436C30.1386 13.2001 29.6341 12.833 29.0639 12.833H20.1779C19.4663 12.833 18.8346 13.2924 18.6152 13.9712L17.0853 18.1794L16.0435 17.5724C15.9656 17.5272 15.8651 17.5539 15.82 17.6318L15.334 18.4788C15.2888 18.5567 15.3155 18.6552 15.3934 18.7003L16.6321 19.4222L16.3347 20.2425C16.3101 20.3081 16.2978 20.3778 16.2978 20.4476V27.5884C16.2978 27.9104 16.5398 28.1708 16.8372 28.1708H18.2235C18.4758 28.1708 18.6952 27.9801 18.7485 27.7135L18.9064 26.9403H30.0955L30.2534 27.7135C30.3088 27.9801 30.5262 28.1708 30.7784 28.1708H32.1647C32.4621 28.1708 32.7041 27.9104 32.7041 27.5884V20.4476C32.7041 20.3778 32.6918 20.3081 32.6672 20.2425L32.3698 19.4222L33.6064 18.7003C33.6844 18.6552 33.711 18.5567 33.668 18.4788ZM19.4152 22.7362C18.962 22.7362 18.5949 22.3691 18.5949 21.9159C18.5949 21.4627 18.962 21.0956 19.4152 21.0956C19.8684 21.0956 20.2355 21.4627 20.2355 21.9159C20.2355 22.3691 19.8684 22.7362 19.4152 22.7362ZM27.373 24.2743C27.373 24.3645 27.2991 24.4384 27.2089 24.4384H21.7949C21.7046 24.4384 21.6308 24.3645 21.6308 24.2743V22.5516C21.6308 22.4614 21.7046 22.3876 21.7949 22.3876H22.6152C22.7054 22.3876 22.7792 22.4614 22.7792 22.5516V23.2899H26.2245V22.5516C26.2245 22.4614 26.2984 22.3876 26.3886 22.3876H27.2089C27.2991 22.3876 27.373 22.4614 27.373 22.5516V24.2743ZM29.5869 22.7362C29.1337 22.7362 28.7666 22.3691 28.7666 21.9159C28.7666 21.4627 29.1337 21.0956 29.5869 21.0956C30.0401 21.0956 30.4072 21.4627 30.4072 21.9159C30.4072 22.3691 30.0401 22.7362 29.5869 22.7362ZM18.513 18.5726L20.004 14.4731L20.0142 14.4465L20.0224 14.4198C20.045 14.3521 20.1065 14.307 20.1783 14.307H28.9474L30.4937 18.5726H18.513Z"
        fill="#A3B1BF"
      />
    </g>
  </svg>
);
