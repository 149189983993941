import { Checkbox, Input } from 'antd';

import { IFieldData } from 'helpers/form';
import CurrencySelect from 'modules/currency/components/CurrencySelect';
import { translate } from 'modules/localization';
import { IShop, IShopForm } from 'modules/shops/models/types';

export const getDataToSet = (item: IShop): IShopForm | Record<string, never> => {
  return {
    short_name: item.short_name,
    legal_name: item.legal_name,
    currency: item.currency,
    disabled: !item.disabled,
  };
};

export const getFields =
  (isView) =>
  (_, isCreate: boolean): IFieldData => ({
    short_name: {
      component: Input,
      formItem: {
        label: translate('shopShortName'),
        className: 'shops-form__item',
      },
      name: 'short_name',
      params: {
        rules: [
          {
            message: translate('requiredValidator'),
            required: true,
          },
        ],
      },
      props: {
        placeholder: translate('enterShopShortName'),
        disabled: isView,
      },
    },
    legal_name: {
      component: Input,
      formItem: {
        label: translate('shopLegalName'),
        className: 'shops-form__item',
      },
      name: 'legal_name',
      params: {
        rules: [
          {
            message: translate('requiredValidator'),
            required: true,
          },
        ],
      },
      props: {
        placeholder: translate('enterShopLegalName'),
        disabled: isView,
      },
    },
    currency: {
      component: CurrencySelect,
      formItem: {
        label: translate('shopCurrency'),
        className: 'shops-form__item',
      },
      name: 'currency',
      params: {
        initialValue: 'RUB',
      },
      props: {
        disabled: isView,
      },
    },
    disabled: {
      component: Checkbox,
      formItem: {
        label: translate('status'),
        className: 'shops-form__item',
      },
      name: 'disabled',
      params: {
        initialValue: isCreate,
        valuePropName: 'checked',
      },
      props: {
        children: translate('activity'),
        disabled: isView,
      },
    },
  });

export const prepareData = (formData: IShopForm): IShopForm => {
  const { short_name, legal_name, currency, disabled } = formData;
  return {
    short_name: short_name ? short_name.trim() : null,
    legal_name: legal_name ? legal_name.trim() : null,
    currency: currency ? currency.trim() : null,
    disabled: !disabled,
  };
};
