import './style.less';

import React, { useState } from 'react';

import { Divider, Form, Modal } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';

import { dateFormat } from 'helpers/string';
import { ICourier, ISession } from 'modules/couriers/models/types';
import { translate } from 'modules/localization';
import CheckSessionIcons from 'modules/routeGenerator/components/common/CourierModal/CheckSessionIcons';
import CourierItem from 'modules/routeGenerator/components/common/CourierModal/CourierFormItem';
import DeliveryMethodsTags from 'modules/routeGenerator/components/common/CourierModal/DeliveryMethodsTags';
import FooterModal from 'modules/routeGenerator/components/common/CourierModal/FooterModal';
import Fullness from 'modules/routeGenerator/components/common/CourierModal/Fullness';
import TimeFormItem from 'modules/routeGenerator/components/common/CourierModal/TimeFormItem';
import TransportFormItem from 'modules/routeGenerator/components/common/CourierModal/TransportFormItem';
import WarehouseTags from 'modules/routeGenerator/components/common/CourierModal/WarehouseTags';
import { IRouteDraft } from 'modules/routeGenerator/models/types';
import { getVolumeTasks, getWeightTasks } from 'modules/tasks/models/utils';
import { ITransport } from 'modules/transports/models/types';
import { getNameInitials } from 'modules/user/models/helpers';
import { useStore } from 'services/store';

import PlanUploadTimeFormItem from './PlanUploadTimeFormItem';
import { getSessionByDate } from '../../right/RouteCard/helpers';

interface IProps {
  isModalOpened: boolean;
  routeId: string;
  initialValues: IRouteDraft;
  closeModal: () => void;
}

const CourierModal = ({ isModalOpened, routeId, initialValues, closeModal }: IProps) => {
  const { routeGenerator, couriers, transport } = useStore();

  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [isLoadingCourier, setIsLoadingCourier] = useState(false);
  const [isLoadingTransport, setIsLoadingTransport] = useState(false);
  const [session, setSession] = useState<ISession>(null);
  const [courierData, setCourierData] = useState<ICourier>(null);
  const [transportData, setTransportData] = useState<ITransport>(null);
  const [form] = Form.useForm();

  const volume = getVolumeTasks(initialValues.tasksList).toFixed(3);
  const weight = getWeightTasks(initialValues.tasksList).toFixed(2);

  const isRouteBuidConfigExist = Boolean(routeGenerator.activeWarehouse?.config?.route_build);

  const handleCancel = (): void => {
    form.resetFields();
    setSession(null);
    setCourierData(null);
    setTransportData(null);
    closeModal();
  };

  const handleSubmit = (values: IRouteDraft) => {
    setIsLoadingModal(true);
    submit(values, session ? session.guid : null);
  };

  const submit = (values: IRouteDraft, guid: string) => {
    routeGenerator.setCourierDataRouteDraft(
      {
        ...values,
        planUploadTime:
          isRouteBuidConfigExist && !values.isPlanUploadTimeActive
            ? dayjs(values.planUploadDate)
                .hour(values.planUploadTime.hour())
                .minute(values.planUploadTime.minute())
                .second(0)
            : null,
        sessionGuid: guid,
        fullName: getNameInitials(courierData.profile),
        courier: courierData,
        transport: transportData,
        isPlanUploadTimeActive: isRouteBuidConfigExist ? !values.isPlanUploadTimeActive : false,
      },
      routeId,
    );
    setIsLoadingModal(false);

    form.resetFields();

    closeModal();
  };

  const resetPlanUploadTime = () => {
    routeGenerator.setCourierDataRouteDraft(
      {
        ...initialValues,
        planUploadTime: null,
      },
      routeId,
    );
  };

  const submitClickHandler = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    form.submit();
  };

  const setupCourier = async (courierGuid: string, transportGuid?: string) => {
    const dateFormatted = routeGenerator.routeSettings.deliveryDate
      ? routeGenerator.routeSettings.deliveryDate.format(dateFormat.search)
      : null;

    if (courierGuid) {
      setIsLoadingCourier(true);

      const courier = await couriers.apiStore.getCourier(courierGuid);
      setCourierData(courier);

      const sessions = await couriers.apiStore.getCourierSessions({
        current: 1,
        pageSize: 100,
        courier_guid: [courierGuid],
        planned_date: [dayjs(routeGenerator.routeSettings.deliveryDate).format(dateFormat.search)],
      });
      const newSession = getSessionByDate(sessions, dateFormatted);
      const newTransportGuid: string = newSession
        ? newSession.transport.guid
        : transportGuid || null;
      onTransportChange(newTransportGuid);
      form.setFieldsValue({
        transportGuid: newTransportGuid,
      });
      setSession(newSession);
      setIsLoadingCourier(false);
    } else if (initialValues.transport) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setCourierData({ transports: [initialValues.transport] });
    } else {
      return;
    }
  };

  const onCourierChange = (courierGuid: string): void => {
    if (!courierGuid) {
      setCourierData(null);
      setTransportData(null);
      setSession(null);
    } else {
      setupCourier(courierGuid);
    }
  };

  const onTransportChange = (transportGuid: string): void => {
    if (!transportGuid) {
      form.setFieldsValue({
        transportGuid: null,
      });
      setTransportData(null);
    } else {
      setIsLoadingTransport(true);
      transport.get(transportGuid).then((newTransport) => {
        setTransportData(newTransport);
        setIsLoadingTransport(false);
      });
    }
  };

  return (
    <Modal
      title={translate('selectCourierForRoute')}
      open={isModalOpened}
      footer={
        <FooterModal
          isLoadingTransport={isLoadingTransport}
          isLoadingCourier={isLoadingCourier}
          isLoadingModal={isLoadingModal}
          handleCancel={handleCancel}
          submitClickHandler={submitClickHandler}
        />
      }
      onCancel={handleCancel}
      className="rg-courier-modal"
      destroyOnClose
    >
      <Form name="basic" form={form} onFinish={handleSubmit} layout="vertical" preserve={false}>
        {isRouteBuidConfigExist && (
          <>
            <PlanUploadTimeFormItem
              initialValues={initialValues}
              deltaMinus={routeGenerator?.activeWarehouse?.config?.route_build?.duration_min}
              deltaPlus={routeGenerator?.activeWarehouse?.config?.route_build?.duration_max}
              resetValue={resetPlanUploadTime}
              currentPlanDate={routeGenerator?.routeSettings?.deliveryDate}
            />
            <Divider />
          </>
        )}

        <TimeFormItem initialValues={initialValues} />
        <Divider />
        <CourierItem
          initialValues={initialValues}
          isLoading={isLoadingCourier}
          setupCourier={setupCourier}
          onChange={onCourierChange}
        />
        <WarehouseTags
          warehouses={courierData && courierData.profile && courierData.profile.warehouses}
        />
        <Divider />
        <TransportFormItem
          initialValues={initialValues}
          onChange={onTransportChange}
          session={session}
          isLoading={isLoadingCourier}
          courier={courierData}
        />
        <WarehouseTags warehouses={transportData && transportData.warehouses} />
        <DeliveryMethodsTags deliveryMethods={transportData && transportData.delivery_methods} />
        <Divider />
        <Fullness transport={transportData} volume={volume} weight={weight} />
        <Divider />
        <CheckSessionIcons session={session} courier={courierData} />
      </Form>
    </Modal>
  );
};

export default observer(CourierModal);
