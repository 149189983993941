import { DEFAULT_PAGINATION } from 'constants/index';
import { IRatesFilter, IRatesForm } from 'modules/rates/models/types';

export const initialRateDataForm: IRatesForm = {
  date_time_end: null,
  date_time_start: null,
  delivery_method_guid: null,
  name: null,
  schedule_guid: null,
  service_zone_guid: null,
};

export const initialFilter: IRatesFilter = {
  ...DEFAULT_PAGINATION,
  locationGuid: null,
  serviceZoneGuidList: [],
  deliveryMethodGuidList: [],
  activeOnly: true,
};
