import React from 'react';

import { observer } from 'mobx-react-lite';

import { DEFAULT_DATE } from 'modules/arm2/components/DateFilter';
import Search from 'modules/arm2/components/Search';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

const TasksSearch = () => {
  const { tasks } = useStore();

  const { filter, setSearchQuery } = tasks;

  const actionSearch = (searchQuery: string): void => {
    tasks.apiStore.getTasks({ ...filter, deliveryDate: null, search: searchQuery });
  };

  const actionListGet = (): void => {
    tasks.apiStore.getTasks({ ...filter, deliveryDate: DEFAULT_DATE, search: null });
  };

  return (
    <Search
      actionSearch={actionSearch}
      actionListGet={actionListGet}
      actionSearchSet={setSearchQuery}
      query={filter.search}
      placeholder={translate('searchByNumber')}
    />
  );
};

export default observer(TasksSearch);
