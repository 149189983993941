import { makeAutoObservable } from 'mobx';

import { RestException } from 'api/RestException';
import { ICurrency } from 'helpers/currency';
import CurrencyApi from 'modules/currency/api/CurrencyApi';

export class CurrencyStore {
  currencyList: ICurrency[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  getCurrency = async (): Promise<ICurrency[]> => {
    try {
      const { data: res } = await CurrencyApi.getCurrencies();
      const currencyData: ICurrency = res.data;
      const currencyList = Object.keys(currencyData).map((code) => ({
        code,
        name: currencyData[code].name,
        symbol: currencyData[code].symbol,
      }));
      this.currencyList = currencyList;
      return currencyList;
    } catch (e) {
      throw new RestException(e);
    }
  };
}
