import './style.less';

import React, { useEffect, useState } from 'react';

import { ArrowLeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { getFieldDataList } from 'helpers/form';
import ClientsGroupsSelect from 'modules/client-groups/components/ClientGroupsSelect';
import { PageTitle } from 'modules/common/components/HeaderMobile';
import Title from 'modules/common/components/Title';
import Loader from 'modules/common/containers/Loader';
import CoveragesSelect from 'modules/coverages/components/CoveragesSelect';
import { translate } from 'modules/localization';
import MapCollapse from 'modules/map/containers/MapCollapse';
import { prepareData } from 'modules/service-zones/components/ServiceZonesForm/helpers';
import ServiceZonesMap from 'modules/service-zones/components/ServiceZonesMap';
import { fromServiceZonesToServiceZonesForm } from 'modules/service-zones/models/helpers';
import { IServiceZonesForm } from 'modules/service-zones/models/types';
import WarehousesSelect from 'modules/warehouses/components/WarehousesSelect';
import { useStore } from 'services/store';

interface IParams {
  serviceZonesGuid: string;
  locationGuid: string;
}

interface IProps {
  baseUrl: string;
  actionName: string;
}

const ServiceZonesForm = ({ baseUrl, actionName }: IProps) => {
  const { serviceZones, router, locations, core, rates, coverage } = useStore();

  const { locationGuid, serviceZonesGuid } = useParams<IParams>();
  const { viewLocation } = locations;
  const [form] = Form.useForm<IServiceZonesForm>();
  const [isRedirectToRatesForm, setRedirectToRatesForm] = useState(false);

  useEffect(() => {
    window && window.scrollTo(0, 0);

    return () => {
      serviceZones.resetFormData();
      coverage.setCoveragesList([]);
      coverage.setCoveragesListView([]);
    };
  }, []);

  useEffect(() => {
    serviceZones.setHoveredCoverages(rates.masterData.coverages);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    form.setFieldsValue({ coverages: rates.masterData.coverages });
  }, [rates.masterData.coverages]);

  useEffect(() => {
    if (serviceZonesGuid) {
      serviceZones
        .getServiceZone(serviceZonesGuid)
        .then((values) => {
          form.setFieldsValue(fromServiceZonesToServiceZonesForm(values, true));
        })
        .catch(() => router.push({ pathname: baseUrl, search: router.location.search }));
    }
  }, [serviceZonesGuid]);

  const handleReset = (): void => {
    serviceZones.resetFormData();
    serviceZones.setHoveredCoverages([]);
    serviceZones.setHoveredWarehouses([]);
    rates.resetMasterData();
    router.push({
      pathname: baseUrl,
      search: router.location.search,
    });
  };

  const handleError = (values) => (response) => {
    const fieldDataList = getFieldDataList(response, values);
    form.setFields(fieldDataList);
  };

  const redirectToRatesForm = (serviceZoneGuid: string) => {
    rates.setMasterData({ serviceZoneGuid });
    router.push({
      pathname: baseUrl.replace('service-zones', 'rates') + '/create',
    });
  };

  const redirectToServiceZonesList = () => {
    rates.resetMasterData();
    router.push({
      pathname: baseUrl,
      search: router.location.search,
    });
  };

  const handleSubmit = (values): void => {
    const params = {
      ...values,
      location_guid: locationGuid,
    };
    const action =
      actionName === 'create'
        ? serviceZones.createServiceZone(prepareData(params))
        : serviceZones.editServiceZones(serviceZonesGuid, prepareData(params));
    action
      .then((data) =>
        isRedirectToRatesForm ? redirectToRatesForm(data.guid) : redirectToServiceZonesList(),
      )
      .catch(handleError(values));
  };

  const onValuesChange = (values) => {
    serviceZones.setFormData(values);
  };

  const isView = !core.permissions['service-zones.update'];

  const goToRateForm = (e) => {
    e.preventDefault();
    setRedirectToRatesForm(true);
    form.submit();
  };

  return (
    <div className="locations-layout">
      <div className="locations-layout__content">
        {core.isMobile ? (
          <PageTitle>
            <a onClick={handleReset}>
              <ArrowLeftOutlined />{' '}
              {`${
                locations?.viewLocation?.name ? locations.viewLocation.name + ' — ' : ''
              } ${translate('serviceZonesList')}`}
            </a>
          </PageTitle>
        ) : null}
        <Form
          className="service-zones__form"
          layout="vertical"
          form={form}
          onFinish={handleSubmit}
          autoComplete="off"
          onValuesChange={onValuesChange}
        >
          <div className="service-zones__form-body">
            <div className="service-zones__form-header">
              {!core.isMobile && (
                <Button type="link" className="service-zones__form-back-link" onClick={handleReset}>
                  <ArrowLeftOutlined />{' '}
                  {`${
                    locations?.viewLocation?.name ? locations.viewLocation.name + ' — ' : ''
                  } ${translate('serviceZonesList')}`}
                </Button>
              )}
              <Title size={Title.SIZE.H3} weight={Title.WEIGHT.SEMIBOLD}>
                {actionName === 'edit'
                  ? core.permissions['service-zones.update']
                    ? translate('serviceZoneEditTitle')
                    : translate('serviceZoneViewTitle')
                  : translate('serviceZoneCreateTitle')}
              </Title>
            </div>

            {core.isMobile ? (
              <MapCollapse>
                <ServiceZonesMap location={locations.viewLocation} />
              </MapCollapse>
            ) : null}

            <div className="service-zones__form-fields">
              <Form.Item
                label={translate('title')}
                name="name"
                rules={[{ required: true, message: translate('requiredValidator') }]}
              >
                <Input placeholder={translate('enterTitle')} disabled={isView} />
              </Form.Item>
              <Form.Item
                label={translate('clientGroups')}
                name="client_groups"
                rules={[{ required: true, message: translate('requiredValidator') }]}
              >
                <ClientsGroupsSelect disabled={isView} mode="multiple" />
              </Form.Item>
              <Form.Item
                label={translate('coverages')}
                name="coverages"
                rules={[{ required: true, message: translate('requiredValidator') }]}
              >
                <CoveragesSelect disabled={isView} maxTagCount={10} />
              </Form.Item>
              <Form.Item
                label={translate('warehouses')}
                name="warehouses"
                rules={[{ required: true, message: translate('requiredValidator') }]}
              >
                <WarehousesSelect disabled={isView} defaultOptions={viewLocation.warehouses} />
              </Form.Item>
              <Form.Item
                label={translate('status')}
                name="disabled"
                valuePropName="checked"
                initialValue
              >
                <Checkbox>{translate('activity')}</Checkbox>
              </Form.Item>
            </div>
          </div>
          {!isView && (
            <Spin
              indicator={<Loader show />}
              spinning={serviceZones.loadingForm}
              wrapperClassName="service-zones__form-forward"
            >
              <Link to="#" onClick={goToRateForm}>
                {translate('goToRateCreation')}
                <RightOutlined className="service-zones__form-forward-icon" />
              </Link>
              <div className="service-zones__form-forward-comment">
                {translate('serviceZoneWillBeSaved')}
              </div>
            </Spin>
          )}
          <Spin
            indicator={<Loader show />}
            spinning={serviceZones.loadingForm}
            wrapperClassName="service-zones__form-footer"
          >
            <Button htmlType="button" key="cancel" onClick={handleReset}>
              {translate('cancel')}
            </Button>
            {actionName === 'edit' && core.permissions['service-zones.update'] ? (
              <Button htmlType="submit" key="save" type="primary">
                {translate('save')}
              </Button>
            ) : null}
            {actionName === 'create' && core.permissions['service-zones.create'] ? (
              <Button htmlType="submit" key="save" type="primary">
                {translate('add')}
              </Button>
            ) : null}
          </Spin>
        </Form>
      </div>
      {!core.isMobile && (
        <div className="locations-layout__map">
          <ServiceZonesMap location={locations.viewLocation} />
        </div>
      )}
    </div>
  );
};

export default observer(ServiceZonesForm);
