import React, { ReactNode } from 'react';

import { translate } from 'modules/localization';
import { ITransport } from 'modules/transports/models/types';

export const getTransportName = (transport: Partial<ITransport>): string => {
  if (!transport) {
    return '';
  }
  return `${transport.name} ${transport.number}`;
};

export const getTransportWeight = (transport: Partial<ITransport>): string => {
  if (!transport) {
    return '';
  }
  return `${transport.weight} ${translate('weightUnit')}`;
};

export const getTransportVolume = (transport: Partial<ITransport>): ReactNode => {
  if (!transport) {
    return '';
  }
  return (
    <>
      {transport.volume} {translate('lengthUnit')}
      <sup>3</sup>
    </>
  );
};
