import React from 'react';

export const ClockCircleSvg = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M6 0C2.69166 0 0 2.69166 0 6C0 9.30834 2.69166 12 6 12C9.30834 12 12 9.30834 12 6C12 2.69166 9.30834 0 6 0ZM6 11.25C3.1051 11.25 0.750003 8.8949 0.750003 6C0.750003 3.1051 3.1051 0.750003 6 0.750003C8.8949 0.750003 11.25 3.1051 11.25 6C11.25 8.8949 8.8949 11.25 6 11.25Z"
        fill="#8C8C8C"
      />
      <path
        d="M6.375 2.25049H5.625V6.15575L7.98486 8.51561L8.51513 7.98534L6.375 5.8452V2.25049Z"
        fill="#8C8C8C"
      />
    </g>
  </svg>
);
