import React from 'react';

import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';

import { GEO_BASE_URL } from 'constants/index';
import { translate } from 'modules/localization';
import AddButton from 'modules/locations/components/LocationsAddButton/CreateButton';
import LocationPlusButton from 'modules/locations/components/LocationsAddButton/LocationPlusButton';
import UniversalPlusButton from 'modules/locations/components/LocationsAddButton/UniversalPlusButton';
import { useStore } from 'services/store';

interface IParams {
  locationGuid: string;
  section: string;
}

const CreateButton = () => {
  const { core, locations } = useStore();
  const { locationGuid, section } = useParams<IParams>();

  switch (section) {
    case 'locations':
      if (
        !locations.isLoadingLocationsList &&
        !locations.loadingForm &&
        locations?.viewLocation?.coverages === 0 &&
        locations?.locationsList?.length === 0
      ) {
        return <LocationPlusButton />;
      }
      return (
        <AddButton
          baseUrl={`${GEO_BASE_URL}/${locationGuid}/locations`}
          isPermitted={core.permissions['locations.create']}
        >
          {translate('createLocation')}
        </AddButton>
      );

    case 'coverages':
      return <UniversalPlusButton />;
    case 'service-zones':
      return <UniversalPlusButton />;
    case 'rates':
      return <UniversalPlusButton />;
    default:
      return null;
  }
};

export default observer(CreateButton);
