import './style.less';

import React, { useEffect, useState } from 'react';

import { EnvironmentOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import querystring from 'query-string';

import DateFilter from 'modules/arm2/components/DateFilter';
import DeliveryMethodFilter from 'modules/arm2/components/DeliveryMethodFilter';
import OrderTypeFilter from 'modules/arm2/components/OrderTypeFilter';
import ShopFilter from 'modules/arm2/components/ShopFilter';
import StatusFilter from 'modules/arm2/components/StatusFilter';
import TimeFilter from 'modules/arm2/components/TimeFilter';
import WarehouseFilter from 'modules/arm2/components/WarehouseFilter';
import { FilterIcon } from 'modules/common/components/Icons';
import Title from 'modules/common/components/Title';
import { translate } from 'modules/localization';
import { getOrdersFilter } from 'modules/orders/helpers/localStorage';
import { useStore } from 'services/store';

import OrdersSearch from '../OrdersSearch';

const OrdersHeader = () => {
  const { orders, core } = useStore();

  useEffect(() => {
    const queryMap = querystring.parse(window.location.search).map_show;

    queryMap && typeof queryMap === 'string' && orders.setFilter('mapShow', queryMap === 'true');
  }, []);

  const [viewFilter, setViewFilter] = useState(!core.isMobile);

  const handleClickMapShow = (): void => {
    orders.setFilter('mapShow', !orders.filter.mapShow);
    orders.setFilterToUrl();
  };

  return (
    <div className="orders-header">
      <Title size={Title.SIZE.H3} weight={Title.WEIGHT.SEMIBOLD} className="order-title">
        {translate('ordersList')}
      </Title>

      {core.isMobile ? (
        <div className="order-filter--mobile">
          <OrdersSearch />
          <Button type="link" icon={<FilterIcon />} onClick={() => setViewFilter(!viewFilter)} />
        </div>
      ) : null}

      {viewFilter ? (
        <div className="order-filter">
          {!core.isMobile && <OrdersSearch />}
          {!core.isMobile && (
            <Button
              className="order-filter__map"
              onClick={handleClickMapShow}
              type={orders.filter.mapShow ? 'primary' : 'default'}
            >
              <EnvironmentOutlined className="order-filter__map-icon" />
            </Button>
          )}
          <DateFilter
            className="order-filter__date"
            getList={orders.apiStore.getOrderList}
            setFilter={orders.setFilter}
            value={orders.filter.deliveryDate}
            getFilterFromLocalStorage={getOrdersFilter}
          />
          <TimeFilter
            className="order-filter__time"
            getList={orders.apiStore.getOrderList}
            setFilter={orders.setFilter}
            value={{ from: orders.filter.from, to: orders.filter.to }}
            getFilterFromLocalStorage={getOrdersFilter}
          />
          <StatusFilter
            className="order-filter__status"
            optionsList={orders.orderStatuses}
            getList={orders.apiStore.getOrderList}
            setFilter={orders.setFilter}
            value={orders.filter.status}
            getFilterFromLocalStorage={getOrdersFilter}
          />
          <WarehouseFilter
            className="order-filter__warehouse"
            getList={orders.apiStore.getOrderList}
            setFilter={orders.setFilter}
            value={orders.filter.warehouses}
            getFilterFromLocalStorage={getOrdersFilter}
          />
          <ShopFilter
            className="order-filter__shop"
            getList={orders.apiStore.getOrderList}
            setFilter={orders.setFilter}
            value={orders.filter.shop}
            getFilterFromLocalStorage={getOrdersFilter}
          />
          <DeliveryMethodFilter
            className="order-filter__delivery"
            getList={orders.apiStore.getOrderList}
            setFilter={orders.setFilter}
            value={orders.filter.deliveryMethod}
            getFilterFromLocalStorage={getOrdersFilter}
          />
          <OrderTypeFilter
            className="order-filter__type"
            getList={orders.apiStore.getOrderList}
            setFilter={orders.setFilter}
            value={orders.filter.type}
            getFilterFromLocalStorage={getOrdersFilter}
            optionsList={orders.orderTypes}
          />
        </div>
      ) : null}
    </div>
  );
};

export default observer(OrdersHeader);
