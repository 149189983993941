import React from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { observer } from 'mobx-react-lite';

import { translate } from 'modules/localization';
import { IWeekday } from 'modules/schedules/models/uitypes';
import { useStore } from 'services/store';

interface IProps {
  weekday: IWeekday;
}

const AddButton = ({ weekday }: IProps) => {
  const { schedules } = useStore();

  const handleAddLine = (e): void => {
    if (e) {
      e.preventDefault();
    }

    schedules.scheduleActionTimeslotAdd(weekday.id);
  };

  return (
    <div className="schedules-add-button">
      <Button tabIndex={0} onClick={handleAddLine}>
        <PlusOutlined />
        {translate('addNewRow')}
      </Button>
    </div>
  );
};

export default observer(AddButton);
