import React from 'react';

import { observer } from 'mobx-react-lite';
import { Route, Switch } from 'react-router-dom';

import Form from 'modules/warehouses/components/Form';
import Header from 'modules/warehouses/components/Header';
import List from 'modules/warehouses/components/List';
import SettingsForm from 'modules/warehouses/components/SettingsForm';
import { useStore } from 'services/store';

const Warehouses = () => {
  const { core } = useStore();

  const baseUrl = '/directories/warehouses';

  const renderLayout = (): React.ReactNode => (
    <>
      <Header baseUrl={baseUrl} />
      <List baseUrl={baseUrl} />
      <Form baseUrl={baseUrl} permissions={core.permissions} />
      <SettingsForm baseUrl={baseUrl} permissions={core.permissions} />
    </>
  );

  return (
    <Switch>
      <Route exact path={`${baseUrl}/:entityGuid/settings/:action`} render={renderLayout} />
      <Route exact path={`${baseUrl}/:entityGuid/:action`} render={renderLayout} />
      <Route exact path={`${baseUrl}/:action`} render={renderLayout} />
      <Route exact path={baseUrl} render={renderLayout} />
    </Switch>
  );
};

export default observer(Warehouses);
