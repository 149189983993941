import './style.less';

import React, { useEffect } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';

import { getFieldDataList } from 'helpers/form';
import { PageTitle } from 'modules/common/components/HeaderMobile';
import Title from 'modules/common/components/Title';
import Loader from 'modules/common/containers/Loader';
import CoveragesFilter from 'modules/coverages/components/CoveragesFilter';
import CoveragesMap from 'modules/coverages/components/CoveragesMap';
import SearchPolygon from 'modules/coverages/components/SearchPolygon';
import { ICoverage } from 'modules/coverages/models/types';
import { translate } from 'modules/localization';
import { getLocationParentGuid } from 'modules/locations/models/helpers';
import MapCollapse from 'modules/map/containers/MapCollapse';
import { MIN_ZOOM_LVL_FOR_GETTING_ADJACENT_COVERAGES } from 'modules/map/helpers';
import { useStore } from 'services/store';

import { prepareData } from './helpers';

interface IParams {
  coverageGuid: string;
  locationGuid: string;
}

interface IProps {
  baseUrl: string;
}

const CoveragesEdit = ({ baseUrl }: IProps) => {
  const { coverage, core, router, locations } = useStore();

  const [form] = Form.useForm<ICoverage>();

  const { locationGuid, coverageGuid } = useParams<IParams>();

  const isView = !core.permissions['coverages.update'];

  useEffect(() => {
    window && window.scrollTo(0, 0);
    coverage.setFilter('locationGuid', locationGuid);
    coverage.apiStore.getCoverages(false);
    coverage.setHovered(null);
    coverage.setAdjacentCoveragesListView([]);

    return () => {
      coverage.setCoveragesList([]);
      coverage.setCoveragesListView([]);
      coverage.setCoverageEditableGuid(null);
      form.resetFields();
    };
  }, []);

  useEffect(() => {
    form.setFieldsValue({ polygon: coverage.polygon });
  }, [coverage.polygon]);

  useEffect(() => {
    if (coverageGuid) {
      coverage.apiStore
        .getCoverage(coverageGuid)
        .then((values) => {
          form.setFieldsValue({
            name: values.name,
            disabled: values.disabled,
            external_id: values.external_id,
            polygon: values.polygon,
          });
          coverage.setPolygon(values.polygon);
        })
        .catch(() => router.push({ pathname: baseUrl, search: router.location.search }));
    } else {
      coverage.setPolygon(null);
    }
  }, [coverageGuid]);

  const handleReset = (): void => {
    coverage.setPolygon(null);
    router.push({
      pathname: baseUrl,
      search: router.location.search,
    });
  };

  const handleError = (values) => (response) => {
    const fieldDataList = getFieldDataList(response, values);
    form.setFields(fieldDataList);
  };

  const handleSubmit = (values): void => {
    const params = {
      ...values,
      locations: [locationGuid],
    };
    coverage.apiStore
      .editCoverage(coverageGuid, prepareData(params))
      .then(() => handleReset())
      .catch(handleError(values));
  };

  const getAdjacentCoverages = () => {
    const bounds = coverage.map.getBounds();
    coverage.apiStore.getAdjacentCoverages(bounds, getLocationParentGuid(locations.viewLocation));
  };

  return (
    <div className="locations-layout">
      <div className="locations-layout__content">
        {core.isMobile && (
          <PageTitle>
            <a onClick={handleReset}>
              <ArrowLeftOutlined />{' '}
              {`${
                locations?.viewLocation?.name ? locations.viewLocation.name + ' — ' : ''
              } ${translate('coveragesList')}`}
            </a>
          </PageTitle>
        )}
        <Form className="coverages__form" layout="vertical" form={form} onFinish={handleSubmit}>
          <div className="coverages__form-body">
            {!core.isMobile && (
              <Button type="link" className="coverages__form-back-link" onClick={handleReset}>
                <ArrowLeftOutlined />{' '}
                {`${
                  locations?.viewLocation?.name ? locations.viewLocation.name + ' — ' : ''
                } ${translate('coveragesList')}`}
              </Button>
            )}
            <Title
              size={Title.SIZE.H3}
              weight={Title.WEIGHT.SEMIBOLD}
              className="coverages__form-header"
            >
              {core.permissions['coverages.update']
                ? translate('coverageEditTitle')
                : translate('coverageViewTitle')}
            </Title>

            {core.isMobile && (
              <MapCollapse>
                <CoveragesMap
                  baseUrl={baseUrl}
                  location={locations.viewLocation}
                  guid={coverageGuid}
                  action="edit"
                />
              </MapCollapse>
            )}

            <div className="coverages__form-fields">
              <Form.Item
                label={translate('title')}
                name="name"
                rules={[{ required: true, message: translate('requiredValidator') }]}
              >
                <Input placeholder={translate('enterTitle')} disabled={isView} />
              </Form.Item>
              <CoveragesFilter withToggler />
              <Form.Item label={translate('externalId')} name="external_id">
                <Input placeholder={translate('enterId')} disabled={isView} />
              </Form.Item>
              <SearchPolygon isView={isView} />

              <div className="coverages__separator" />
              {core.permissions['coverages.update'] && (
                <Form.Item>
                  <Button
                    onClick={getAdjacentCoverages}
                    className="coverages__get-adjacent"
                    disabled={
                      coverage.isLoadingAdjacentCoveragesList ||
                      !coverage.map ||
                      coverage.mapZoom < MIN_ZOOM_LVL_FOR_GETTING_ADJACENT_COVERAGES
                    }
                  >
                    {translate('getAdjacentCoverages')}
                  </Button>
                </Form.Item>
              )}
              <Form.Item
                label={translate('status')}
                name="disabled"
                valuePropName="checked"
                initialValue
              >
                <Checkbox disabled={isView}>{translate('activity')}</Checkbox>
              </Form.Item>
              <Form.Item
                label={translate('polygon')}
                extra={translate('enterPolygon')}
                name="polygon"
                className="coverages__item_hide"
                rules={[{ required: true, message: translate('requiredValidator') }]}
              >
                <Input disabled={isView} />
              </Form.Item>
            </div>
          </div>

          <Spin
            indicator={<Loader show />}
            spinning={coverage.loadingForm}
            wrapperClassName="coverages__form-footer"
          >
            <Button htmlType="button" key="cancel" onClick={handleReset}>
              {translate('cancel')}
            </Button>
            {core.permissions['coverages.update'] && (
              <Button htmlType="submit" key="save" type="primary">
                {translate('save')}
              </Button>
            )}
          </Spin>
        </Form>
      </div>
      {!core.isMobile && (
        <div className="locations-layout__map">
          <CoveragesMap
            baseUrl={baseUrl}
            location={locations.viewLocation}
            guid={coverageGuid}
            action="edit"
          />
        </div>
      )}
    </div>
  );
};

export default observer(CoveragesEdit);
