import React from 'react';

import Actions from 'modules/directory/components/ActionButtons';
import { translate } from 'modules/localization';
import { IRoles } from 'modules/user/models/types';

export const getColumns = (
  baseUrl: string,
  roles: IRoles,
  actionDelete: (guid: string) => void,
) => {
  return [
    {
      dataIndex: 'name',
      title: translate('fullName'),
      key: 'name',
      textWrap: 'word-break',
      render: (_name, row) => `${row.surname} ${row.name} ${row.patronymic ? row.patronymic : ''}`,
    },
    {
      dataIndex: 'roles',
      title: translate('role'),
      key: 'guid',
      render: (_, row) => {
        return row.roles && Array.isArray(row.roles) && roles
          ? row.roles.map((role) => roles[role]).join(', ')
          : '';
      },
      width: '15%',
      ellipsis: true,
    },
    {
      dataIndex: 'phone',
      title: translate('phone'),
      key: 'phone',
      width: '15%',
      ellipsis: true,
    },
    {
      dataIndex: 'email',
      title: 'Email',
      key: 'email',
      width: '15%',
      ellipsis: true,
    },
    {
      dataIndex: 'meta',
      title: translate('shops'),
      key: 'shops',
      width: '15%',
      ellipsis: true,
      render: (userMeta) => {
        const current = userMeta?.shops;
        return `${
          current && Array.isArray(current) ? current.map((item) => item.legal_name).join(', ') : ''
        }`;
      },
    },
    {
      dataIndex: 'disabled',
      render: (disabled) => <span>{!disabled ? translate('yes') : translate('no')}</span>,
      title: translate('activity'),
      key: 'isActive',
      width: '10%',
    },
    {
      dataIndex: 'guid',
      key: 'action',
      render: (_, item) => (
        <Actions
          item={item}
          baseUrl={baseUrl}
          actionDelete={actionDelete}
          message={{
            deleteTitle: translate('userDeleteConfirm'),
            deleteCancel: translate('cancel'),
          }}
          permissions={{ edit: true, delete: true }}
        />
      ),
      width: '10%',
    },
  ];
};
