import './style.less';

import React, { useEffect } from 'react';

import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';

import ClustersList from 'modules/clusters/components/ClustersList';
import ClustersWorkspace from 'modules/clusters/components/ClustersWorkspace';
import { useStore } from 'services/store';

import ClustersEmpty from '../ClustersEmpty';

interface IProps {
  baseUrl: string;
}

const ClustersDirectoryBody = ({ baseUrl }: IProps) => {
  const { clusters } = useStore();

  useEffect(() => {
    clusters.getList({ current: 1, pageSize: 100 });
  }, []);

  if (!clusters.list) {
    return null;
  }

  return clusters.list.length > 0 ? (
    <div className="clusters-body">
      <div className="clusters-body-left">
        <Spin spinning={clusters.isLoadingList}>
          <ClustersList />
        </Spin>
      </div>
      <div className="clusters-body-right">
        <Spin spinning={clusters.isLoadingList}>
          <ClustersWorkspace baseUrl={baseUrl} />
        </Spin>
      </div>
    </div>
  ) : (
    <ClustersEmpty />
  );
};

export default observer(ClustersDirectoryBody);
