import { makeAutoObservable } from 'mobx';
import querystring from 'query-string';

import { LOCAL_STORAGE } from 'constants/index';
import { getInitialPagination } from 'helpers/pagination';
import { setRoutesFilter } from 'modules/routes/helpers/localStorage';
import { initialFilter } from 'modules/routes/models/initial';
import { IRoute, IRoutes, IRoutesFilter, IRoutesStatus } from 'modules/routes/models/types';
import { routerStore } from 'services/store';

import { RoutesApiStore } from './RoutesApiStore';

export class RoutesStore {
  apiStore: RoutesApiStore;

  route: IRoute = null;

  routesStatuses: IRoutesStatus[] = [];
  routesList: IRoutes[] = [];
  filterRoutes: IRoutesFilter = {
    ...initialFilter,
    ...getInitialPagination(LOCAL_STORAGE.ROUTES_FILTER),
  };
  activeRequestRoutesList: AbortController | null = null;
  activeRequestRoute: AbortController | null = null;

  visibleRouteDistance: number = null;

  get isLoadingRoutesList() {
    return this.activeRequestRoutesList !== null;
  }

  get isLoadingRoute() {
    return this.activeRequestRoute !== null;
  }

  constructor() {
    this.apiStore = new RoutesApiStore(this);
    makeAutoObservable(this);
  }

  setFilterToUrl = (filter: IRoutesFilter = this.filterRoutes): void => {
    const qs = querystring.stringify(
      {
        page: filter.current,
        page_size: filter.pageSize,
        q: filter.search,
        date: filter.deliveryDate,
        shop: filter.shop,
        warehouse: filter.warehouses,
        from: filter.from,
        to: filter.to,
        status: filter.status,
        method: filter.deliveryMethod,
        map_show: filter.mapShow,
        is_problem: filter.isProblem,
      },
      { skipNull: true },
    );
    routerStore.history.location.pathname !== '/error' &&
      routerStore.history.location.pathname === '/arm2/routes' &&
      routerStore.replace(`/arm2/routes?${qs}`);
  };

  setFilterRoutes = (key: string, value: string[] | boolean | number | string): void => {
    this.filterRoutes[key] = value;
    setRoutesFilter(this.filterRoutes);
  };

  setVisibleRouteDistance = (value: number): void => {
    this.visibleRouteDistance = value;
  };
}
