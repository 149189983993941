import React, { useEffect } from 'react';

import { CloudDownloadOutlined, ExpandOutlined } from '@ant-design/icons';
import { Card, Descriptions, List } from 'antd';
import { observer } from 'mobx-react-lite';

import DelorianState from 'modules/qa/common/DelorianState';
import Diff from 'modules/qa/common/Diff';
import { DiffFlag } from 'modules/qa/common/flags';
import { IWarehouse } from 'modules/qa/models/types';
import { useStore } from 'services/store';

type IPropsSidebar = {
  warehouse: IWarehouse;
};

const Sidebar = ({ warehouse }: IPropsSidebar) => {
  return (
    <>
      <Descriptions bordered title="Склад" size="small" column={1}>
        <Descriptions.Item label="Склад">{warehouse.guid}</Descriptions.Item>
        {warehouse.title ? (
          <Descriptions.Item label="Название">{warehouse.title}</Descriptions.Item>
        ) : null}
        {warehouse.address ? (
          <Descriptions.Item label="Адрес">{warehouse.address}</Descriptions.Item>
        ) : null}
        <Descriptions.Item label="cluster_item_guid">
          {warehouse.cluster_item_guid}
        </Descriptions.Item>
        <Descriptions.Item label="Курьеры">
          {warehouse.courier_guid_list && Array.isArray(warehouse.courier_guid_list)
            ? warehouse.courier_guid_list.map((c) => <p key={c}>{c}</p>)
            : null}
        </Descriptions.Item>
        <Descriptions.Item label="distance_to_courier">
          {warehouse.distance_to_courier
            ? Object.keys(warehouse.distance_to_courier).map((d) => (
                <p key={d}>
                  {d}: {warehouse.distance_to_courier[d]}
                </p>
              ))
            : null}
        </Descriptions.Item>
        <Descriptions.Item label="round_distance_to_courier">
          {warehouse.round_distance_to_courier
            ? Object.keys(warehouse.round_distance_to_courier).map((d) => (
                <p key={d}>
                  {d}: {warehouse.round_distance_to_courier[d]}
                </p>
              ))
            : null}
        </Descriptions.Item>
        <Descriptions.Item label="Блокирующий процесс">{warehouse.locked}</Descriptions.Item>
        <Descriptions.Item label="Заказы">
          {warehouse.order_guid_list && Array.isArray(warehouse.order_guid_list)
            ? warehouse.order_guid_list.map((o) => <p key={o}>{o}</p>)
            : null}
        </Descriptions.Item>
        <Descriptions.Item label="Маршруты">
          {warehouse.route_guid_list && Array.isArray(warehouse.route_guid_list)
            ? warehouse.route_guid_list.map((r) => <p key={r}>{r}</p>)
            : null}
        </Descriptions.Item>
        <Descriptions.Item label="Координаты">
          {warehouse.point.lat}, {warehouse.point.lon}
        </Descriptions.Item>
        <Descriptions.Item label="Таймзона">{warehouse.timezone}</Descriptions.Item>
      </Descriptions>
      <DelorianState entity={warehouse} />
      <Diff entity={warehouse} />

      <pre>{JSON.stringify(warehouse, null, 2)}</pre>
    </>
  );
};

export const WarehouseSidebar = Sidebar;

const WarehouseList = () => {
  const { courierCouncilMonitor } = useStore();

  useEffect(() => {
    courierCouncilMonitor.getWarehouses({ pageSize: 100, current: 1 });
  }, []);

  return (
    <List
      grid={{ column: 1 }}
      className="list__warehouses"
      header={<b>Склады</b>}
      dataSource={courierCouncilMonitor.warehousesList}
      renderItem={(warehouse: IWarehouse) => {
        const openWarehouse = () => {
          courierCouncilMonitor.setSidebarType('warehouse');
          courierCouncilMonitor.setSidebarData(warehouse);
        };

        return (
          <List.Item>
            <Card
              title={warehouse.difference ? <DiffFlag /> : null}
              extra={
                <>
                  {!warehouse.title && (
                    <>
                      <a
                        href="#"
                        rel="noreferrer"
                        onClick={() => courierCouncilMonitor.loadWarehouse(warehouse.guid)}
                      >
                        <CloudDownloadOutlined />
                      </a>
                      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </>
                  )}
                  <a href="#" rel="noreferrer" onClick={openWarehouse}>
                    <ExpandOutlined />
                  </a>
                </>
              }
            >
              {warehouse.guid}
              {warehouse.title ? (
                <p>
                  <b>{warehouse.title}</b>
                </p>
              ) : null}
              {warehouse.address ? <p>{warehouse.address}</p> : null}
            </Card>
          </List.Item>
        );
      }}
    />
  );
};

export default observer(WarehouseList);
