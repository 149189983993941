import './style.less';

import React, { useEffect, useState } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Spin, Button } from 'antd';
import L from 'leaflet';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';

import Navigation from 'modules/arm2/components/Navigation';
import Title from 'modules/common/components/Title';
import { translate } from 'modules/localization';
import OrderInfo from 'modules/orders/components/OrderInfo';
import OrdersMap from 'modules/orders/components/OrdersMap';
import { initialOrderData } from 'modules/orders/models/initial';
import { IOrder } from 'modules/orders/models/types';
import SupportOrderButtons from 'modules/supportFunctional/components/SupportButtons/SupportOrderButtons';
import { ITaskForm } from 'modules/tasks/models/types';
import { IPoint } from 'modules/warehouses/models/types';
import { useStore } from 'services/store';

interface IParams {
  entityGuid: string;
}

interface IProps {
  baseUrl: string;
}

const OrderPage = ({ baseUrl }: IProps) => {
  const { coverage, orders, router, tasks, core, supportFunctional } = useStore();

  const { entityGuid } = useParams<IParams>();
  const [order, setOrder] = useState<IOrder>(initialOrderData);
  const orderUpdate = (): Promise<void> =>
    orders.apiStore
      .getOrder(entityGuid)
      .then(setOrder)
      .catch(() => router.push({ pathname: baseUrl, search: router.location.search }));

  useEffect(() => {
    if (entityGuid && supportFunctional.isStartScenario === false) {
      orderUpdate();
    }
  }, [entityGuid, supportFunctional.isStartScenario]);

  useEffect(() => {
    window && window.scrollTo(0, 0);
    return () => coverage.setCoveragesListByOrder([]);
  }, []);

  const handleBackLink = (): void => router.push({ pathname: baseUrl });

  // изменение времени доставки
  const changeTaskTimeslot = (
    taskGuid: string,
    date: string,
    from: string,
    to: string,
  ): Promise<void> => tasks.apiStore.changeTaskTimeslot(taskGuid, date, from, to).then(orderUpdate);

  // создать задачу
  const createTask = (taskForm: ITaskForm): Promise<void> =>
    orders.apiStore.createTask(entityGuid, taskForm).then(orderUpdate);

  // изменить координату клиента/склада
  const [isMarkerEdit, updateMarkerEdit] = useState(false);
  const [newPoint, updatePoint] = useState({ point: null, layerGroup: L.layerGroup() });
  const [newPointTarget, updatePointTarget] = useState(null);
  const [isRepoint, setRepoint] = useState(false);
  const openMarkerEdit = (target: string, isRepoint = false): void => {
    coverage.apiStore.getCoveragesByWarehouse(order.warehouse_guid);
    setRepoint(isRepoint);
    updatePoint({ point: null, layerGroup: L.layerGroup() });
    updatePointTarget(target);
    updateMarkerEdit(true);
  };
  const closeMarkerEdit = (): void => {
    setRepoint(false);
    updatePointTarget(null);
    updateMarkerEdit(false);
  };
  const onCancelMarkerEdit = (): void => {
    newPoint && newPoint.layerGroup && newPoint.layerGroup.clearLayers();
    closeMarkerEdit();
    coverage.setCoveragesListByOrder([]);
  };
  const onSubmitMarkerEdit = async (): Promise<void> => {
    if (isRepoint) {
      newPoint &&
        newPoint.point &&
        (await orders.apiStore.editCoords(entityGuid, newPoint.point, newPointTarget));
    } else {
      newPoint &&
        newPoint.point &&
        (await orders.apiStore.setCoords(entityGuid, newPoint.point, newPointTarget));
    }
    closeMarkerEdit();
    newPoint && newPoint.layerGroup.clearLayers();
    orderUpdate();
    coverage.setCoveragesListByOrder([]);
  };

  return (
    <div className="order">
      <div className="order__main">
        <Spin spinning={supportFunctional.isStartScenario}>
          <div className="order__header">
            <Navigation />
            <div className="order__title">
              <Button type="link" className="order__back-link" onClick={handleBackLink}>
                <ArrowLeftOutlined /> {translate('goToOrderList')}
              </Button>
              <div className="order__title-navigation">
                <Title size={Title.SIZE.H3} weight={Title.WEIGHT.SEMIBOLD}>
                  {translate('order')} #{order.number}
                </Title>
                {core.permissions['support.scenarios.execute'] &&
                  order.available_support_scenario &&
                  order.available_support_scenario.length && <SupportOrderButtons order={order} />}
              </div>
            </div>
          </div>
          {core.isMobile && (
            <div className="order__map">
              <OrdersMap
                order={order}
                isMarkerEdit={isMarkerEdit}
                onAddPoint={(point: IPoint, layerGroup: L.LayerGroup): void =>
                  updatePoint({ point, layerGroup })
                }
                layerGroup={newPoint.layerGroup}
                onSubmitMarketEdit={onSubmitMarkerEdit}
                onCancelMarkerEdit={onCancelMarkerEdit}
                addPointDestination={newPointTarget}
              />
            </div>
          )}

          <OrderInfo
            order={order}
            onCancelTask={orderUpdate}
            changeTaskTimeslot={changeTaskTimeslot}
            createTask={createTask}
            openMarkerEdit={openMarkerEdit}
            closeMarkerEdit={closeMarkerEdit}
            isMarkerEdit={isMarkerEdit}
            orderUpdate={orderUpdate}
          />
        </Spin>
      </div>
      {!core.isMobile && (
        <div className="order__map">
          <OrdersMap
            order={order}
            isMarkerEdit={isMarkerEdit}
            onAddPoint={(point: IPoint, layerGroup: L.LayerGroup): void =>
              updatePoint({ point, layerGroup })
            }
            layerGroup={newPoint.layerGroup}
            onSubmitMarketEdit={onSubmitMarkerEdit}
            onCancelMarkerEdit={onCancelMarkerEdit}
            addPointDestination={newPointTarget}
          />
        </div>
      )}
    </div>
  );
};

export default observer(OrderPage);
