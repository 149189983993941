import './style.less';

import { useEffect } from 'react';

import L from 'leaflet';
import { GeoSearchControl } from 'leaflet-geosearch';
import { observer } from 'mobx-react-lite';
import { useMap } from 'react-leaflet';

import { translate } from 'modules/localization';
import { IPoint } from 'modules/locations/models/types';
import SearchProviderBMP from 'modules/map/containers/SearchProviderBMP';
import { iconCustomerMarker, iconWarehouse } from 'modules/map/icons';
import { useStore } from 'services/store';

interface ISearchProps {
  query?: string;
  onAddPoint: (point: IPoint, layerGroup: L.LayerGroup) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  layerGroup: any;
  destination?: 'customer' | 'warehouse';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  existedPoint?: any;
}

const Search = ({ query, onAddPoint, layerGroup, destination, existedPoint }: ISearchProps) => {
  const { core } = useStore();

  const map = useMap();
  const provider = new SearchProviderBMP({
    token: core.config.geocoderBMP.token,
    url: core.config.geocoderBMP.url,
  });
  const searchControl = GeoSearchControl({
    autoClose: true,
    keepResult: true,
    provider: provider,
    searchLabel: translate('enterSearchQueryAddress'),
    showMarker: false,
    showPopup: false,
    style: 'bar',
    autoCompleteDelay: 100,
    marker: {
      draggable: false,
    },
  });

  const onGetResultHandler = (data) => {
    if (data && data[0] && data[0].x) {
      const coords = {
        lat: +data[0].y,
        lng: +data[0].x,
      };
      map.fireEvent('geosearch/showlocation', {
        marker: {
          _latlng: coords,
        },
      });
      map.setView([+data[0].y, +data[0].x], 18);
    }
  };

  const onSelectHandler = (layerGroup, onAddPoint, destination) => {
    return function hadler(data) {
      layerGroup.clearLayers();
      L.marker(data.marker._latlng, {
        icon: destination === 'warehouse' ? iconWarehouse : iconCustomerMarker,
      }).addTo(layerGroup);
      const coordinates: IPoint = {
        lat: data.marker._latlng.lat,
        lon: data.marker._latlng.lng,
      };
      onAddPoint(coordinates, layerGroup);
    };
  };

  const setSearchQuery = () => {
    searchControl.searchElement.setQuery(query);
    provider.search({ query }).then(onGetResultHandler);

    if (map && onAddPoint && layerGroup) {
      if (!map.hasLayer(layerGroup)) {
        layerGroup.addTo(map);
      }
    }
  };

  const placeExistedPoint = () => {
    const [lng, lat] = existedPoint.geometry.coordinates;
    L.marker(
      { lat, lng },
      {
        icon: iconCustomerMarker,
      },
    ).addTo(layerGroup);
    map.setView([lat, lng], 18);
  };

  useEffect(() => {
    map && map.addControl(searchControl);
    if (existedPoint) {
      placeExistedPoint();
    } else {
      query && setSearchQuery();
    }
    map.on('geosearch/showlocation', onSelectHandler(layerGroup, onAddPoint, destination));

    return () => {
      searchControl.clearResults(null, true);
      map.off('geosearch/showlocation');
      map.removeControl(searchControl);
    };
  }, [query]);

  return null;
};

export default observer(Search);
