import { DirectoryApi, DirectoryApiStore } from 'modules/common/store/DirectoryApiStore';

import CountriesApi from '../api/CountriesApi';
import { ICountries, ICountryPayload } from '../models/types';

const Api: DirectoryApi<ICountries, ICountryPayload> = {
  getEntity: CountriesApi.getCountry,
  getEntityList: CountriesApi.getCountriesList,
  search: CountriesApi.searchCountries,
  createEntity: CountriesApi.createCountry,
  editEntity: CountriesApi.editCountry,
  deleteEntity: CountriesApi.deleteCountry,
};
export class CountriesStore extends DirectoryApiStore<ICountries, ICountryPayload> {
  constructor() {
    super(Api, '/directories/countries');
  }
}
