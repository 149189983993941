import React from 'react';

import Icon from '@ant-design/icons';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { observer } from 'mobx-react-lite';

import { checkDateInDate } from 'helpers/date';
import { timeFormat } from 'helpers/string';
import { translate } from 'modules/localization';
import { IRouteDraft } from 'modules/routeGenerator/models/types';
import { useStore } from 'services/store';

dayjs.extend(utc);
dayjs.extend(timezone);

interface IProps {
  card: IRouteDraft;
  pointName: 'finish' | 'start';
}

const CommentSvg = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.43 1.07861H2.55441C1.41733 1.07861 0.492188 2.00375 0.492188 3.14084V9.79645C0.492188 10.9313 1.4133 11.8548 2.54709 11.8587V14.8791L6.88733 11.8589H13.43C14.567 11.8589 15.4922 10.9338 15.4922 9.79645V3.14084C15.4922 2.00375 14.567 1.07861 13.43 1.07861ZM11.4774 8.81326H4.50696V7.93436H11.4774V8.81326ZM11.4774 6.93826H4.50696V6.05936H11.4774V6.93826ZM11.4774 5.06326H4.50696V4.18436H11.4774V5.06326Z"
      fill="#BFBFBF"
    />
  </svg>
);

const WarehousePoint = ({ card, pointName }: IProps) => {
  const { routeGenerator } = useStore();

  const getTimeRoute = (): string => {
    let time = `${dayjs(card[pointName].datetime)
      .tz(card[pointName].warehouse.timezone)
      .format(timeFormat.simple)}`;
    if (
      !checkDateInDate(
        dayjs(card[pointName].datetime).tz(card[pointName].warehouse.timezone),
        routeGenerator.routeSettings.deliveryDate.tz(card[pointName].warehouse.timezone),
      )
    ) {
      time =
        time +
        ` / ${dayjs(card[pointName].datetime)
          .tz(card[pointName].warehouse.timezone)
          .format('DD.MM')}`;
    }
    return time;
  };

  return (
    card.isCalculated && (
      <div className="rg-routecontent__warehouse">
        <Icon component={CommentSvg} />

        {pointName === 'start'
          ? `${translate('atTime', { content: getTimeRoute() }).toUpperCase()} ${translate(
              'routeStart',
            ).toLowerCase()}`
          : translate('comeToWarehouseAtTime', { content: getTimeRoute() })}
      </div>
    )
  );
};

export default observer(WarehousePoint);
