import { RestException } from 'api/RestException';
import { DirectoryApi, DirectoryApiStore } from 'modules/common/store/DirectoryApiStore';

import AccountSystemApi from '../api/AccountSystemApi';
import { IAccountSystem } from '../models/types';

const Api: DirectoryApi<IAccountSystem, null> = {
  getEntity: null,
  getEntityList: AccountSystemApi.getAccountSystemList,
  search: null,
  createEntity: null,
  editEntity: AccountSystemApi.editAccountSystem,
  deleteEntity: null,
};
export class AccountSystemStore extends DirectoryApiStore<IAccountSystem, null> {
  constructor() {
    super(Api, '/directories/transports');
  }

  async edit(guid: string, disabled: boolean): Promise<void> {
    try {
      await AccountSystemApi.editAccountSystem(guid, disabled);
      await this.getAll();
    } catch (e) {
      throw new RestException(e);
    }
  }
}
