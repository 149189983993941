import { makeAutoObservable } from 'mobx';
import querystring from 'query-string';

import { LOCAL_STORAGE } from 'constants/index';
import { getInitialPagination } from 'helpers/pagination';
import { IFilter } from 'modules/arm2/models/types';
import { setCouriersFilter } from 'modules/couriers/helpers/localStorage';
import { initialCourierData, initialFilter } from 'modules/couriers/models/initial';
import { ICourier, ISessionStatus } from 'modules/couriers/models/types';
import { routerStore } from 'services/store';

import { CouriersApiStore } from './CouriersApiStore';

export class CouriersStore {
  apiStore: CouriersApiStore;
  couriersList: ICourier[] = [];
  courier: ICourier = initialCourierData;
  activeRequestCouriersList: AbortController | null = null;
  filter: IFilter = { ...initialFilter, ...getInitialPagination(LOCAL_STORAGE.COURIERS_FILTER) };
  sessionStatuses: ISessionStatus[] = [];
  loadingForm = false;

  get isLoadingCouriersList() {
    return this.activeRequestCouriersList !== null;
  }

  constructor() {
    this.apiStore = new CouriersApiStore(this);
    makeAutoObservable(this);
  }

  setFilterToUrl = (filter: IFilter = this.filter): void => {
    const qs = querystring.stringify(
      {
        page: filter.current,
        page_size: filter.pageSize,
        q: filter.search,
        warehouse: filter.warehouses,
        status: filter.status,
        map_show: filter.mapShow,
      },
      { skipNull: true },
    );
    routerStore.history.location.pathname !== '/error' &&
      routerStore.history.location.pathname === '/arm2/couriers' &&
      routerStore.replace(`/arm2/couriers?${qs}`);
  };

  setFilter = (key: string, value: string[] | boolean | number | string): void => {
    this.filter[key] = value;
    setCouriersFilter(this.filter);
  };
}
