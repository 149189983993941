import './style.less';

import React, { createContext, useState } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';

import classSet from 'helpers/classSet';
import { translate } from 'modules/localization';
import Tabs from 'modules/routeGenerator/components/common/Tabs';
import TaskList from 'modules/routeGenerator/components/common/TaskList';
import TaskListCounter from 'modules/routeGenerator/components/common/TaskListCounter';
import TaskListSizes from 'modules/routeGenerator/components/common/TaskListSizes';
import Map from 'modules/routeGenerator/components/right/Map';
import { IRouteDraft } from 'modules/routeGenerator/models/types';
import { useStore } from 'services/store';

import SortButton from './SortButton';
import WarehousePoint from './Warehouse';

interface IProps {
  card: IRouteDraft;
  routeId: string;
}

interface IRouteCardContext {
  isCalculated?: boolean;
  deliveryDate?: Dayjs;
  startTZ?: string;
  finishTZ?: string;
}
export const RouteCardContext = createContext<IRouteCardContext>({});

const RouteContent = ({ card, routeId }: IProps) => {
  const { routeGenerator } = useStore();

  const removeRoute = () => {
    routeGenerator.setOpenCardRouteDraftId(null);
    routeGenerator.removeRoute(routeId);
  };

  const routeCardContext: IRouteCardContext = {
    deliveryDate: routeGenerator.routeSettings.deliveryDate,
    isCalculated: card.isCalculated,
    startTZ: card.isCalculated ? card.start.warehouse.timezone : null,
    finishTZ: card.isCalculated ? card.finish.warehouse.timezone : null,
  };

  const [isMap, setIsMap] = useState(false);
  const [sortType, setSortType] = useState<string>('defaultSort');

  return (
    <div className="rg-routecontent">
      <div className="rg-routecontent__header">
        <Tabs isMap={isMap} setIsMap={setIsMap} />
        <div className="rg-routecontent__delete" data-testid="generator-deleteRouteConfirm">
          <Popconfirm onConfirm={removeRoute} placement="rightTop" title={translate('areYouSure')}>
            <DeleteOutlined /> {translate('routeDelete')}
          </Popconfirm>
        </div>
      </div>

      <div className="rg-routecontent__body">
        {isMap ? (
          <Map routeId={card.uid} taskList={card.tasksList} />
        ) : (
          <>
            <div className="rg-routecontent__stat">
              <TaskListCounter taskList={card.tasksList} />
              <div className="rg-routecontent__sizes-wrap">
                <SortButton sortType={sortType} setSortType={setSortType} />
                <TaskListSizes taskList={card.tasksList} currencyCode={routeGenerator.currency} />
              </div>
            </div>
            <RouteCardContext.Provider value={routeCardContext}>
              <div
                className={classSet({
                  'rg-routecontent__body--calculated': card.isCalculated,
                })}
              >
                <WarehousePoint card={card} pointName="start" />
                <TaskList
                  taskList={[...card.tasksList]}
                  isRightGrouped={!card.isCalculated}
                  isRight={card.isCalculated}
                  sortType={sortType}
                />
                <WarehousePoint card={card} pointName="finish" />
              </div>
            </RouteCardContext.Provider>
          </>
        )}
      </div>
    </div>
  );
};

export default observer(RouteContent);
