import './style.less';

import React from 'react';

import { CheckOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { translate } from 'modules/localization';
import TaskListSizes from 'modules/routeGenerator/components/common/TaskListSizes';
import { ITask } from 'modules/tasks/models/types';

interface IProps {
  selectedTaskList: ITask[];
  handlePrimary: () => void;
  handleCleanup: () => void;
  currency: string;
  isClicked: boolean;
  titlePrimary: string;
}

const SelectedActions = ({
  selectedTaskList,
  handlePrimary,
  handleCleanup,
  currency,
  isClicked,
  titlePrimary,
}: IProps) => {
  return (
    selectedTaskList.length > 0 && (
      <div className="rg-selected">
        <div className="rg-selected__info">
          <span>
            {translate('selected')}:&nbsp;{selectedTaskList.length}
          </span>
          <TaskListSizes taskList={selectedTaskList} currencyCode={currency} />
        </div>
        <div className="rg-selected__actions">
          <Button type="link" onClick={handleCleanup}>
            {translate('cleanup')}
          </Button>
          <Button
            type="primary"
            className="rg-selected__action-add"
            disabled={selectedTaskList.length === 0}
            onClick={handlePrimary}
            icon={isClicked ? <CheckOutlined /> : null}
          >
            {!isClicked && translate(titlePrimary)}
          </Button>
        </div>
      </div>
    )
  );
};

export default SelectedActions;
