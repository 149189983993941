import React from 'react';

import { EditOutlined } from '@ant-design/icons';

import HintedText from 'modules/common/components/HintedText';
import { translate } from 'modules/localization';
import { IRouteDraft } from 'modules/routeGenerator/models/types';
import { getTransportName } from 'modules/transports/models/helpers';

import CheckCourierIcons from './CheckCourierIcons';

interface IProps {
  openModal: (e: React.MouseEvent) => void;
  cardData: IRouteDraft;
  number: number | string;
}

const Courier = ({ cardData, openModal, number }: IProps) => {
  const courierName = () => {
    return (
      <>
        <CheckCourierIcons cardData={cardData} />
        <HintedText content={`${cardData.fullName}, ${getTransportName(cardData.transport)}`}>
          <b>{cardData.fullName}</b>, {getTransportName(cardData.transport)}
        </HintedText>
      </>
    );
  };

  const emptyCourier = () => {
    return <span className="rg-routecard__courier-empty">{translate('courierIsNotSelected')}</span>;
  };

  return (
    <div className="rg-routecard__courier">
      <div className="rg-routecard__courier-name" onClick={openModal}>
        <div className="rg-routecard__index">
          {number}
          {'.'}
        </div>
        {cardData.courierGuid ? courierName() : emptyCourier()}
        <EditOutlined className="rg-routecard__edit-courier-button" />
      </div>
    </div>
  );
};

export default Courier;
