import React from 'react';

import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { useDrop } from 'react-dnd';
import withScrolling from 'react-dnd-scrolling';

import TaskListCounter from 'modules/routeGenerator/components/common/TaskListCounter';
import TaskListSizes from 'modules/routeGenerator/components/common/TaskListSizes';
import EmptySmallCard from 'modules/routeGenerator/components/right/RightSide/EmptySmallCard';
import RouteCard from 'modules/routeGenerator/components/right/RouteCard';
import RouteListActions from 'modules/routeGenerator/components/right/RouteListActions';
import { highlightRouteDrafts } from 'modules/routeGenerator/models/utils';
import { DndTypes, IDndItem } from 'modules/routeGenerator/stores/RouteGeneratorDnDStore';
import { useStore } from 'services/store';

import Title from './Title';
interface IDndProps {
  highlighted: boolean;
}

const ScrollingComponent = withScrolling('div');

const GeneratorRightSideList = () => {
  const { routeGenerator } = useStore();

  const taskList = routeGenerator.routeDraftList.map((item) => [...item.tasksList]).flat();

  const [{ highlighted }] = useDrop<IDndItem, void, IDndProps>(() => ({
    accept: [DndTypes.LEFT_TASK, DndTypes.LEFT_COVERAGE],
    collect: (monitor) => {
      return {
        highlighted: monitor.canDrop(),
      };
    },
  }));

  return (
    <div className="rg-rightside">
      {!highlighted && (
        <div className="rg-rightside__header">
          <Title />
          <RouteListActions />
        </div>
      )}
      <div className="rg-rightside__body">
        <EmptySmallCard />
        {!highlighted && (
          <div className="rg-rightside__stat">
            <TaskListCounter taskList={taskList} />
            <TaskListSizes taskList={taskList} currencyCode={routeGenerator.currency} />
          </div>
        )}
        <ScrollingComponent
          className="rg-rightside__listview"
          data-testid="generator-routeDraftList"
        >
          <Spin spinning={routeGenerator.isLoadingTasksList} wrapperClassName="rg__spin--fill">
            {highlightRouteDrafts(
              routeGenerator.routeDraftList,
              routeGenerator.highlightedTasks,
            ).map((item, index) => (
              <RouteCard
                cardData={item}
                serialNumber={index + 1}
                routeId={item.uid}
                key={item.uid}
                isList
              />
            ))}
          </Spin>
        </ScrollingComponent>
      </div>
    </div>
  );
};

export default observer(GeneratorRightSideList);
