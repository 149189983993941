import { IServiceZones, IServiceZonesForm } from 'modules/service-zones/models/types';

export const fromServiceZonesToServiceZonesForm = (
  item: IServiceZones | IServiceZonesForm,
  isChangeDisabled = false,
): IServiceZonesForm => ({
  client_groups: item.client_groups ? item.client_groups.map((cg) => (cg.guid ? cg.guid : cg)) : [],
  coverages: item.coverages ? item.coverages.map((c) => (c.guid ? c.guid : c)) : [],
  disabled: isChangeDisabled ? !item.disabled : item.disabled,
  name: item.name,
  warehouses: item.warehouses ? item.warehouses.map((w) => (w.guid ? w.guid : w)) : [],
});
