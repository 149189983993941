export interface ITimezone {
  offset: number;
  text: string;
  utc: string;
}

export const TIMEZONES: ITimezone[] = [
  {
    offset: 2,
    text: 'UTC +2',
    utc: 'Europe/Kaliningrad',
  },
  {
    offset: 3,
    text: 'UTC +3',
    utc: 'Europe/Moscow',
  },
  {
    offset: 4,
    text: 'UTC +4',
    utc: 'Europe/Samara',
  },
  {
    offset: 5,
    text: 'UTC +5',
    utc: 'Asia/Yekaterinburg',
  },
  {
    offset: 6,
    text: 'UTC +6',
    utc: 'Asia/Omsk',
  },
  {
    offset: 7,
    text: 'UTC +7',
    utc: 'Asia/Novosibirsk',
  },
  {
    offset: 8,
    text: 'UTC +8',
    utc: 'Asia/Irkutsk',
  },
  {
    offset: 9,
    text: 'UTC +9',
    utc: 'Asia/Yakutsk',
  },
  {
    offset: 10,
    text: 'UTC +10',
    utc: 'Asia/Vladivostok',
  },
  {
    offset: 11,
    text: 'UTC +11',
    utc: 'Asia/Sakhalin',
  },
  {
    offset: 12,
    text: 'UTC +12',
    utc: 'Asia/Kamchatka',
  },
];
