import { makeAutoObservable } from 'mobx';

import { RestException } from 'api/RestException';
import { getLastPagePagination, getResponsePagination, isOutOfPages } from 'helpers/pagination';
import RoutesApi from 'modules/routes/api/RoutesApi';
import { IRoute, IRoutes, IRoutesFilter, IRoutesStatus } from 'modules/routes/models/types';

import { RoutesStore } from './RoutesStore';

export class RoutesApiStore {
  root: RoutesStore;

  constructor(root: RoutesStore) {
    this.root = root;
    makeAutoObservable(this);
  }

  getRoutes = async (filter: IRoutesFilter = this.root.filterRoutes): Promise<IRoutes[]> => {
    try {
      this.root.activeRequestRoutesList && this.root.activeRequestRoutesList.abort();
      this.root.activeRequestRoutesList = new AbortController();
      const request = this.root.activeRequestRoutesList;

      const { data: res } = await RoutesApi.getRoutesList(filter, request.signal);
      if (isOutOfPages(res.pagination)) {
        return this.getRoutes({ ...filter, ...getLastPagePagination(res.pagination) });
      }
      this.root.routesList = res.data;
      if (!res.errors && res.pagination) {
        this.root.setFilterToUrl({
          ...filter,
          current: res.pagination.page,
          pageSize: res.pagination.page_size,
        });
        const responsePagination = getResponsePagination(res.pagination);
        this.root.setFilterRoutes('current', responsePagination.current);
        this.root.setFilterRoutes('pageSize', responsePagination.pageSize);
        this.root.setFilterRoutes('total', responsePagination.total);
        this.root.setFilterRoutes('columnKey', filter.columnKey);
        this.root.setFilterRoutes('order', filter.order);
      }
      return res.data;
    } catch (e) {
      throw new RestException(e);
    } finally {
      if (this.root.activeRequestRoutesList && !this.root.activeRequestRoutesList.signal.aborted) {
        this.root.activeRequestRoutesList = null;
      }
    }
  };

  getRoutesStatuses = async (): Promise<IRoutesStatus[]> => {
    try {
      const { data: res } = await RoutesApi.getRoutesStatuses();
      const statuses = Object.keys(res.data).map((k) => ({ value: k, title: res.data[k] }));
      this.root.routesStatuses = statuses;
      return statuses;
    } catch (e) {
      throw new RestException(e);
    }
  };

  setRouteStatus = async (routeGuid: string, status: string): Promise<IRoute> => {
    try {
      const { data: res } = await RoutesApi.setRouteStatus(routeGuid, status);
      return res.data;
    } catch (e) {
      throw new RestException(e);
    }
  };

  getRoute = async (routeGuid: string): Promise<IRoute> => {
    try {
      this.root.activeRequestRoute && this.root.activeRequestRoute.abort();
      this.root.activeRequestRoute = new AbortController();
      const request = this.root.activeRequestRoute;

      const { data: res } = await RoutesApi.getRoute(routeGuid, request.signal);
      this.root.route = res.data;
      return res.data;
    } catch (e) {
      throw new RestException(e);
    } finally {
      if (this.root.activeRequestRoute && !this.root.activeRequestRoute.signal.aborted) {
        this.root.activeRequestRoute = null;
      }
    }
  };

  deleteTaskFromRoute = async (tasksGuidList: string[]): Promise<void> => {
    try {
      await Promise.all(tasksGuidList.map((t) => RoutesApi.deleteTaskFromRoute(t)));
    } catch (e) {
      throw new RestException(e);
    }
  };
}
