import React, { useEffect, useState } from 'react';

import { Feature, Geometry } from 'geojson';
import { observer } from 'mobx-react-lite';
import { useMap } from 'react-leaflet';

import Api from 'api/Api';
import MapRoute from 'modules/map/containers/MapRoute';
import { getPath } from 'modules/map/helpers';
import { useStore } from 'services/store';

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pointList: (Feature<Geometry, Record<string, any>> | { type: string; coordinates: any[] })[];
  customColor?: string;
}

const RoutePath = ({ pointList, customColor }: IProps) => {
  const { routes } = useStore();

  const map = useMap();
  const [route, updateRoute] = useState({ coordinates: [], type: 'LineString' });
  useEffect(() => {
    getPath(pointList, {
      serviceUrl: Api.serviceUrl,
    }).then((res) => {
      routes.setVisibleRouteDistance(res?.summary?.distance || null);
      updateRoute(res);
    });
  }, [pointList]);

  return <MapRoute leaflet={map} route={route} customColor={customColor} />;
};

export default observer(RoutePath);
