import React from 'react';

import { createRoot } from 'react-dom/client';

import { createContainerNotification } from 'modules/common/containers/Notify/createContainerNotification';

import NotificationsManager from './NotificationsManager';
import { INotificationForUsing, TNotifyFunc, TypeNotification } from './types';

const containerElement = createContainerNotification();
export const rootElement = createRoot(containerElement);
let notify: TNotifyFunc;

rootElement.render(
  <NotificationsManager
    setNotify={(notifyFunc) => {
      notify = notifyFunc;
    }}
  />,
);

export class ViewNotification {
  static timeToDelete = 4500;

  static info = (params: INotificationForUsing): void => {
    return notify({
      typeNotification: TypeNotification.info,
      ...params,
    });
  };

  static success = (params: INotificationForUsing): void => {
    return notify({
      typeNotification: TypeNotification.success,
      ...params,
    });
  };

  static warning = (params: INotificationForUsing): void => {
    return notify({
      typeNotification: TypeNotification.warning,
      ...params,
    });
  };

  static error = (params: INotificationForUsing): void => {
    return notify({
      typeNotification: TypeNotification.error,
      ...params,
    });
  };
}
