import React from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import { GEO_BASE_URL } from 'constants/index';
import Layout from 'modules/common/containers/Layout';
import LocationsPage from 'modules/locations/components/LocationsPage';

const Geo = () => {
  return (
    <Layout>
      <Switch>
        <Route path={GEO_BASE_URL} component={LocationsPage} />
        <Route path={'/geo'} render={() => <Redirect to={GEO_BASE_URL} />} />
      </Switch>
    </Layout>
  );
};

export default Geo;
