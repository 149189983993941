import React from 'react';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { Checkbox, InputNumber, Tooltip } from 'antd';

import {
  IFieldData,
  integerNumberValidator,
  moreThanNullNumberValidator,
  positiveNumberValidator,
} from 'helpers/form';
import DeliveryMethodsSelect from 'modules/delivery-methods/components/DeliveryMethodsSelect';
import { translate } from 'modules/localization';
import { IWarehouses, IWarehousesSettings } from 'modules/warehouses/models/types';

type IFormData = Record<string, boolean | number | string>;

const renderHelpLabel = (label, message): React.ReactNode => {
  return (
    <>
      <span>{label} </span>
      <Tooltip title={message}>
        <QuestionCircleOutlined className="warehouses-form__help-icon" />
      </Tooltip>
    </>
  );
};

export const getFields = (prefix: string): IFieldData => ({
  deliveryMethodGuid: {
    component: DeliveryMethodsSelect,
    formItem: {
      label: translate('deliveryMethod'),
      className: 'warehouses-form__item--full',
    },
    name: prefix + 'delivery_method_guid',
    props: {},
    params: {
      rules: [
        {
          message: translate('requiredValidator'),
          required: true,
        },
      ],
    },
  },
  maxRouteTime: {
    component: InputNumber,
    formItem: {
      label: translate('maxRouteTime'),
      className: 'warehouses-form__item--settings',
    },
    name: prefix + 'max_route_time',
    props: {
      placeholder: translate('enterMaxRouteTime'),
      addonAfter: translate('timeUnitMinuteShort'),
    },
    params: {
      rules: [
        {
          message: translate('requiredValidator'),
          required: true,
        },
        {
          validator: integerNumberValidator,
        },
        {
          validator: positiveNumberValidator,
        },
      ],
    },
  },
  maxCourierDowntime: {
    component: InputNumber,
    formItem: {
      label: translate('maxCourierDowntime'),
      className: 'warehouses-form__item--settings',
    },
    name: prefix + 'max_courier_downtime',
    props: {
      placeholder: translate('enterMaxCourierDowntime'),
      addonAfter: translate('timeUnitMinuteShort'),
    },
    params: {
      rules: [
        {
          validator: integerNumberValidator,
        },
        {
          validator: positiveNumberValidator,
        },
      ],
    },
  },
  maxDelayTime: {
    component: InputNumber,
    formItem: {
      label: renderHelpLabel(translate('maxDelayTime'), translate('maxDelayTimeMeaning')),
      className: 'warehouses-form__item--settings',
    },
    name: prefix + 'max_delay_time',
    props: {
      placeholder: translate('enterMaxDelayTime'),
      addonAfter: translate('timeUnitMinuteShort'),
    },
    params: {
      rules: [
        {
          validator: integerNumberValidator,
        },
        {
          validator: positiveNumberValidator,
        },
      ],
    },
  },
  maxLeadTime: {
    component: InputNumber,
    formItem: {
      label: renderHelpLabel(translate('maxLeadTime'), translate('maxLeadTimeMeaning')),
      className: 'warehouses-form__item--settings',
    },
    name: prefix + 'max_lead_time',
    props: {
      placeholder: translate('enterMaxLeadTime'),
      addonAfter: translate('timeUnitMinuteShort'),
    },
    params: {
      rules: [
        {
          validator: integerNumberValidator,
        },
        {
          validator: positiveNumberValidator,
        },
      ],
    },
  },
  unloadingTime: {
    component: InputNumber,
    formItem: {
      label: translate('unloadingTime'),
      className: 'warehouses-form__item--settings',
    },
    name: prefix + 'unloading_time',
    props: {
      placeholder: translate('enterUnloadingTime'),
      addonAfter: translate('timeUnitMinuteShort'),
    },
    params: {
      rules: [
        {
          message: translate('requiredValidator'),
          required: true,
        },
        {
          validator: integerNumberValidator,
        },
        {
          validator: positiveNumberValidator,
        },
      ],
    },
  },
  guardLimit: {
    component: InputNumber,
    formItem: {
      label: translate('guardLimit'),
      className: 'warehouses-form__item--settings',
    },
    name: prefix + 'guard_limit',
    props: {
      placeholder: translate('enterGuardLimit'),
      addonAfter: translate('timeUnitMinuteShort'),
    },
    params: {
      rules: [
        {
          message: translate('requiredValidator'),
          required: true,
        },
        {
          validator: integerNumberValidator,
        },
      ],
    },
  },
  fullDayRoutes: {
    component: Checkbox,
    formItem: {
      label: translate('fullDayRoutes'),
      className: 'warehouses-form__item--settings',
    },
    name: prefix + 'full_day_routes',
    params: {
      initialValue: false,
      valuePropName: 'checked',
    },
    props: {
      children: translate('fullDayRoutesMeaning'),
    },
  },
  averageSpeed: {
    component: InputNumber,
    formItem: {
      label: translate('averageSpeed'),
      className: 'warehouses-form__item--settings',
    },
    name: prefix + 'average_speed',
    props: {
      placeholder: translate('enterAverageSpeed'),
      addonAfter: translate('averageSpeedUnitKmPerHour'),
    },
    params: {
      rules: [
        {
          message: translate('requiredValidator'),
          required: true,
        },
        {
          validator: positiveNumberValidator,
        },
        {
          validator: moreThanNullNumberValidator,
        },
      ],
    },
  },
});

export const getDataToSet = (data: IWarehouses): IWarehousesSettings[] => {
  const settings = data.settings || [];

  return settings.map((set) => ({
    delivery_method_guid: set.delivery_method_guid,
    max_route_time: set.max_route_time || set.max_route_time === 0 ? set.max_route_time / 60 : null,
    max_courier_downtime:
      set.max_courier_downtime || set.max_courier_downtime === 0
        ? set.max_courier_downtime / 60
        : null,
    max_delay_time: set.max_delay_time || set.max_delay_time === 0 ? set.max_delay_time / 60 : null,
    max_lead_time: set.max_lead_time || set.max_lead_time === 0 ? set.max_lead_time / 60 : null,
    unloading_time: set.unloading_time || set.unloading_time === 0 ? set.unloading_time / 60 : null,
    guard_limit: set.guard_limit || set.guard_limit === 0 ? set.guard_limit / 60 : null,
    full_day_routes: set.full_day_routes,
    average_speed: set.average_speed,
  }));
};

export const prepareDataToSetFieldsValue = (data: IWarehouses): IFormData => {
  return getDataToSet(data)
    .map((item, index) => {
      const keys = Object.keys(item);
      return keys.reduce((acc, k) => {
        acc[`${index}${k}`] = item[k];
        return acc;
      }, {});
    })
    .reduce((acc, item) => {
      return { ...acc, ...item };
    }, {});
};

const getTimeValue = (val): number => (val || val === 0 ? Number(val) * 60 : null);

export const prepareSettings = (fd: IFormData): IWarehousesSettings[] => {
  const keys = Object.keys(fd);

  return keys.reduce((acc, k) => {
    const i = Number(k[0]);
    const key = k.slice(1);

    if (!acc[i]) {
      acc[i] = {};
    }

    if (key === 'average_speed') {
      acc[i][key] = +fd[k];
    } else {
      acc[i][key] =
        key === 'delivery_method_guid' || key === 'full_day_routes' ? fd[k] : getTimeValue(fd[k]);
    }

    return acc;
  }, []);
};
