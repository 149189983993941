import React from 'react';

import dayjs from 'dayjs';
import duration, { Duration as dayjsDuration } from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { translate } from 'modules/localization';
import { ITask } from 'modules/tasks/models/types';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

interface ITasksProps {
  task: ITask;
  isShort?: boolean;
  className?: string;
}

// опоздание/опережение выполненного задания
const Duration = ({ task }: ITasksProps) => {
  const isLate = Array.isArray(task.problems) && task.problems.includes('late_complete');
  const isEarly = Array.isArray(task.problems) && task.problems.includes('early_complete');

  const getEarlyDiff = () => {
    const time = task.real_delivery_time ? task.real_delivery_time : dayjs();
    return time && dayjs(task.delivery_slot_start).diff(dayjs(time));
  };
  const getLateDiff = () => {
    const time = task.real_delivery_time ? task.real_delivery_time : dayjs();
    return time && dayjs(time).diff(dayjs(task.delivery_slot_end));
  };

  const diff = (isEarly && getEarlyDiff()) || (isLate && getLateDiff());
  const duration = diff && dayjs.duration(diff);

  return (
    duration && (
      <p style={{ color: '#8c8c8c' }}>
        {isEarly ? `${translate('outrunningTitle')}: ` : `${translate('latenessTitle')}: `}
        {!!duration.days() && `${duration.days()} ${translate('timeUnitDayShort')} `}
        {!!duration.hours() && `${duration.hours()} ${translate('timeUnitHourShort')} `}
        {!!duration.minutes() && `${duration.minutes()} ${translate('timeUnitMinuteShort')}`}
      </p>
    )
  );
};

// опоздание на текущий момент
const LateDuration = ({ task }: ITasksProps) => {
  const getDiff = () => {
    const now = dayjs();
    return dayjs(now).diff(dayjs(task.delivery_slot_end));
  };
  const isMoreThanMinute = (lateDuration: dayjsDuration): boolean => lateDuration.asMinutes() >= 1;
  const diff = getDiff();
  const duration = diff && dayjs.duration(diff);

  return (
    duration &&
    isMoreThanMinute(duration) && (
      <p style={{ color: 'red' }}>
        {translate('latenessTitle')}:{' '}
        {!!duration.days() && `${duration.days()} ${translate('timeUnitDayShort')} `}
        {!!duration.hours() && `${duration.hours()} ${translate('timeUnitHourShort')} `}
        {!!duration.minutes() && `${duration.minutes()} ${translate('timeUnitMinuteShort')}`}
      </p>
    )
  );
};

const TaskTime = ({ task, isShort, className }: ITasksProps) => {
  const slot = `${task.time_slot_start} – ${task.time_slot_end}`;
  const deliveryTime =
    task.real_delivery_time &&
    dayjs(task.real_delivery_time).tz(task.meta.warehouse.timezone).format('HH:mm');
  const plannedTime =
    task.planned_delivery_time &&
    dayjs(task.planned_delivery_time).tz(task.meta.warehouse.timezone).format('HH:mm');

  const isLatePossible = Array.isArray(task.problems) && task.problems.includes('late_possible');
  const isEarlyPossible = Array.isArray(task.problems) && task.problems.includes('early_possible');

  const isLate = Array.isArray(task.problems) && task.problems.includes('late');

  const isCompleteWithProblem =
    Array.isArray(task.problems) &&
    (task.problems.includes('late_complete') || task.problems.includes('early_complete'));

  return (
    <div className={`task-time ${className}`}>
      {!isShort && <p>{`${slot} / ${deliveryTime || plannedTime || '--:--'}`}</p>}

      {isLate && <LateDuration task={task} />}

      {isCompleteWithProblem && <Duration task={task} />}

      {isLatePossible && <p style={{ color: '#FAAD14' }}>{translate('possiblyLate')}</p>}
      {isEarlyPossible && <p style={{ color: '#FAAD14' }}>{translate('possiblyOutrunning')}</p>}
    </div>
  );
};

export default TaskTime;
