import './style.less';

import React from 'react';

import { Spin, Typography } from 'antd';
import { Location } from 'history';
import { observer } from 'mobx-react-lite';
import { Link, Redirect } from 'react-router-dom';

import Title from 'modules/common/components/Title';
import { CustomIcon } from 'modules/common/containers/CustomIcon';
import Loader from 'modules/common/containers/Loader';
import LoginForm from 'modules/core/components/LoginForm';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

const year: number = new Date().getFullYear();

interface IProps {
  location: Location<{ from: { pathname: string } }>;
}

const Login = ({ location }: IProps) => {
  const { core } = useStore();

  const loader = <Loader show size={7} />;

  const { loginIsLoading, token } = core;

  if (token) {
    const { from } = location.state || { from: { pathname: '/' } };

    return <Redirect to={from} />;
  }

  return (
    <div className="login">
      <Spin
        indicator={loader}
        spinning={loginIsLoading}
        wrapperClassName="login__content"
        className="login__loader"
      >
        <div className="login__form">
          <Title className="login__title">
            <CustomIcon className="login__title-icon" type="md-logo" />
            <span>Mag Delivery</span>
          </Title>
          <LoginForm />
        </div>
      </Spin>
      <footer className="login__footer">
        <div
          style={{
            fontSize: '16px',
            lineHeight: '24px',
            textAlign: 'center',
            fontWeight: 500,
          }}
        >
          {year}
        </div>
        <Link to="/privacy" component={Typography.Link}>
          {translate('privacy')}
        </Link>
      </footer>
    </div>
  );
};

export default observer(Login);
