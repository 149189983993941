import './style.less';

import React from 'react';

import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';

import Nav from 'modules/common/components/Nav';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

interface IMenuItem {
  access?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: any;
  href: string;
  id: string;
  title: string;
}

const MENU_ITEMS: IMenuItem[] = [
  {
    access: 'orders.list',
    href: '/orders',
    id: 'orders',
    title: translate('orders'),
  },
  {
    access: 'tasks.list',
    href: '/tasks',
    id: 'tasks',
    title: translate('tasks'),
  },
  {
    access: 'routes.list',
    href: '/routes',
    id: 'routes',
    title: translate('routes'),
  },
  {
    access: 'courier-sessions.list',
    href: '/couriers',
    id: 'couriers',
    title: translate('couriers'),
  },
];

const Navigation = () => {
  const { core } = useStore();
  const checkAccess = (menuItem: IMenuItem): boolean =>
    !menuItem.access || core.permissions[menuItem.access];

  const getSectionList = (): IMenuItem[] => MENU_ITEMS.filter(checkAccess);

  const list = getSectionList();

  if (list.length > 0) {
    return (
      <Nav className="nav-wrap">
        <div className="nav__list">
          {list.map((item) => (
            <NavLink key={item.id} className="nav__link" to={`/arm2${item.href}`}>
              {item.title}
            </NavLink>
          ))}
        </div>
      </Nav>
    );
  }

  return null;
};

export default observer(Navigation);
