import React, { ReactNode } from 'react';

import { ColumnsType } from 'antd/es/table';

import { IPermission } from 'modules/core/models/types';
import { ICourier } from 'modules/couriers/models/types';
import Actions from 'modules/directory/components/ActionButtons';
import { translate } from 'modules/localization';
import { ITransport } from 'modules/transports/models/types';

export const getColumns = (
  baseUrl: string,
  permissions: IPermission,
  actionDelete: (guid: string) => void,
): ColumnsType<ICourier> => {
  return [
    {
      dataIndex: 'profile_guid',
      title: translate('fullName'),
      key: 'profile_guid',
      render: (_, row: ICourier): ReactNode =>
        `${row.profile.surname} ${row.profile.name} ${
          row.profile.patronymic ? row.profile.patronymic : ''
        }`,
      width: '25%',
    },
    {
      dataIndex: 'transports',
      title: translate('transport'),
      key: 'transports',
      render: (transports: ITransport[]): ReactNode =>
        transports ? transports.map((t) => `${t.name} ${t.number}`).join(', ') : null,
      width: '70%',
    },
    {
      dataIndex: 'guid',
      key: 'action',
      render: (_, item: ICourier): ReactNode => (
        <Actions
          item={item}
          baseUrl={baseUrl}
          actionDelete={actionDelete}
          message={{
            deleteCancel: translate('cancel'),
            deleteTitle: translate('courierDeleteConfirm'),
          }}
          permissions={{
            edit: permissions['couriers.update'],
            delete: permissions['couriers.delete'],
          }}
        />
      ),
      width: '5%',
    },
  ];
};
