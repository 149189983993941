import { action, makeObservable, observable } from 'mobx';
import querystring from 'query-string';

import { RestException } from 'api/RestException';
import {
  getLastPagePagination,
  getResponsePagination,
  isOutOfPages,
  reduceItemGUID,
} from 'helpers/pagination';
import { IFilter } from 'modules/arm2/models/types';
import { DirectoryApi, DirectoryApiStore } from 'modules/common/store/DirectoryApiStore';
import { routerStore } from 'services/store';

import CouriersApi from '../api/CouriersApi';
import { initialFilter } from '../models/initial';
import { ICourier, ICouriersPayload } from '../models/types';

const Api: DirectoryApi<ICourier, ICouriersPayload> = {
  getEntity: CouriersApi.getCourier,
  getEntityList: CouriersApi.getCouriersList,
  search: CouriersApi.searchCouriers,
  createEntity: CouriersApi.createCourier,
  editEntity: CouriersApi.editCourier,
  deleteEntity: CouriersApi.deleteCourier,
};
export class CourierDirectoryStore extends DirectoryApiStore<ICourier, ICouriersPayload> {
  filter: IFilter = initialFilter;
  couriersMap = {};

  constructor() {
    super(Api, '/directories/couriers');
    makeObservable(this, {
      filter: observable,
      couriersMap: observable,
      setFilter: action.bound,
      setFilterToUrl: action.bound,
    });
  }

  async getList(filter: IFilter = this.filter, isRedirect = true): Promise<ICourier[]> {
    try {
      this.activeRequestlist && this.activeRequestlist.abort();
      this.activeRequestlist = new AbortController();
      const request = this.activeRequestlist;

      const { data: res } = await CouriersApi.getCouriersList(filter, request.signal);
      if (isOutOfPages(res.pagination)) {
        return this.getList({ ...filter, ...getLastPagePagination(res.pagination) });
      }
      this.list = res.data;
      this.couriersMap = res.data.reduce(reduceItemGUID, {});
      if (!res.errors && res.pagination && isRedirect) {
        this.setFilterToUrl({
          ...filter,
          current: res.pagination.page,
          pageSize: res.pagination.page_size,
        });
        const responsePagination = getResponsePagination(res.pagination);
        this.setFilter('current', responsePagination.current);
        this.setFilter('pageSize', responsePagination.pageSize);
        this.setFilter('total', responsePagination.total);
      }
    } catch (e) {
      throw new RestException(e);
    } finally {
      if (this.activeRequestlist && !this.activeRequestlist.signal.aborted) {
        this.activeRequestlist = null;
      }
    }
  }

  async getAll(warehouses?: string[]): Promise<ICourier[]> {
    try {
      const pageSize = 100;
      const { data: res } = await CouriersApi.getCouriersList({
        current: 1,
        pageSize,
        warehouses,
      });
      const count = Math.ceil(res.pagination.total / res.pagination.page_size);

      if (count > 1) {
        const promises = [];
        for (let current = 2; current <= count; current++) {
          promises.push(CouriersApi.getCouriersList({ current, pageSize, warehouses }));
        }
        const couriersList = [
          ...res.data,
          ...(await Promise.all(promises)).reduce(
            (acc, previous) => [...acc, ...previous.data.data],
            [],
          ),
        ];
        this.listAll = couriersList;
        return couriersList;
      }

      this.listAll = res.data;
      return res.data;
    } catch (e) {
      throw new RestException(e);
    }
  }

  setFilter = (key: string, value: string[] | boolean | number | string): void => {
    this.filter[key] = value;
  };

  setFilterToUrl = (filter: IFilter = this.filter): void => {
    const qs = querystring.stringify(
      {
        page: filter.current,
        page_size: filter.pageSize,
        q: filter.search,
        warehouse: filter.warehouses,
        status: filter.status,
        map_show: filter.mapShow,
      },
      { skipNull: true },
    );

    routerStore.history.location.pathname !== '/error' &&
      routerStore.history.location.pathname === '/directories/couriers' &&
      routerStore.replace(`/directories/couriers?${qs}`);
  };

  async edit(guid: string, data: ICouriersPayload): Promise<void> {
    try {
      this.isLoadingForm = true;
      await this.api.editEntity(guid, data);
      this.setSearchQuery('');
      await this.getList();
      this.isLoadingForm = false;
    } catch (e) {
      this.isLoadingForm = false;
      throw new RestException(e);
    }
  }
}
