import React, { useEffect, useState } from 'react';

import { ClockCircleOutlined } from '@ant-design/icons';
import { Radio } from 'antd';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { formatCurrency } from 'helpers/currency';
import { timeFormat } from 'helpers/string';
import { ISessionForLoading } from 'modules/couriers/models/types';
import { translate } from 'modules/localization';
import { IRoute } from 'modules/routes/models/types';
import {
  getTasksListAddressCount,
  getTasksListAmount,
  getTasksListCount,
  getTasksListVolume,
  getTasksListWeight,
} from 'modules/tasks/helpers/lists';
import { useStore } from 'services/store';

dayjs.extend(utc);
dayjs.extend(timezone);

interface IProps {
  session: ISessionForLoading;
  route: IRoute;
  isSingle: boolean;
}

const SendRouteCard = ({ session, route, isSingle }: IProps) => {
  const { currency, warehouses } = useStore();
  const [currencyCode, setCurrencyCode] = useState<string>(null);

  useEffect(() => {
    route &&
      route.start_warehouse &&
      warehouses
        .get(route.start_warehouse.guid)
        .then((w) => setCurrencyCode(w?.shops[0]?.currency));
  }, [route.start_warehouse]);

  return (
    <div
      className={`send-courier-spoiler__route ${isSingle && 'send-courier-spoiler__route--single'}`}
      key={route.guid}
    >
      <Radio value={route.guid}>
        <div className="send-courier-spoiler__route-content">
          <div className="send-courier-spoiler__route-content-column">
            <span className="send-courier-spoiler__route-content-transport">
              {session.transport.name} {session.transport.number}, {session.transport.weight}{' '}
              {translate('weightUnit')}, {session.transport.volume} {translate('lengthUnit')}
              <sup>3</sup>
            </span>
            <span>
              <ClockCircleOutlined />{' '}
              {dayjs
                .tz(route.date_time_planned_start, route.start_warehouse.timezone)
                .format(timeFormat.simple)}{' '}
              -{' '}
              {dayjs
                .tz(
                  route.date_time_planned_finish,
                  route.finish_warehouse
                    ? route.finish_warehouse.timezone
                    : route.start_warehouse.timezone,
                )
                .format(timeFormat.simple)}
            </span>
          </div>
          <div className="send-courier-spoiler__route-content-column">
            <span className="send-courier-spoiler__route-content-params">
              <span>
                {formatCurrency(
                  getTasksListAmount(route.tasks),
                  currencyCode,
                  currency.currencyList,
                )}
              </span>
              {' / '}
              <span>
                {getTasksListWeight(route.tasks).toFixed(2)}&nbsp;{translate('weightUnit')}
              </span>
              {' / '}
              <span>
                {getTasksListVolume(route.tasks).toFixed(3)}&nbsp;{translate('lengthUnit')}
                <sup>3</sup>
              </span>
            </span>
            <span className="send-courier-spoiler__route-content-tasks">
              {translate('addressesLength', { content: getTasksListAddressCount(route.tasks) })}
              ,&nbsp;
              {translate('tasksLength', { content: getTasksListCount(route.tasks) })}
            </span>
          </div>
        </div>
      </Radio>
    </div>
  );
};

export default SendRouteCard;
