import './style.less';

import React from 'react';

import { observer } from 'mobx-react-lite';

import Form from 'modules/directory/containers/Form';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

import { getDataToSet, getFields, prepareData } from './helpers';

interface IProps {
  baseUrl: string;
}

const TransportsForm = ({ baseUrl }: IProps) => {
  const { transport, core } = useStore();

  const isView = !core.permissions['transports.update'];
  const getFormFields = getFields(isView);

  const isPermittedSubmit =
    core.permissions['transports.create'] && core.permissions['transports.update'];

  return (
    <Form
      baseUrl={baseUrl}
      actionCreate={transport.create}
      actionEdit={transport.edit}
      actionGet={transport.get}
      getDataToSet={getDataToSet}
      getFields={getFormFields}
      prepareData={prepareData}
      message={{
        editSuccess: translate('transportEditSuccess'),
        createSuccess: translate('transportCreateSuccess'),
        editTitle: translate('transportEditTitle'),
        createTitle: translate('transportCreateTitle'),
      }}
      isPermittedSubmit={isPermittedSubmit}
      isLoading={transport.isLoadingForm}
    />
  );
};

export default observer(TransportsForm);
