import React from 'react';

import { Checkbox, Collapse, CollapseProps, Radio } from 'antd';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { ICouriersWithSessions } from 'modules/couriers/models/types';

import { ISelectedRoute, getDefaultSelectedRoute, getWarning } from './helpers';
import SendRouteCard from './SendRouteCard';

dayjs.extend(utc);
dayjs.extend(timezone);

interface IProps {
  couriersList: ICouriersWithSessions[];
  selectedRoutes: ISelectedRoute[];
  selectRoutes: (routes: ISelectedRoute[]) => void;
}

const SendList = ({ couriersList, selectRoutes, selectedRoutes }: IProps) => {
  const onCourierSelect = (courierGuid) => (e) => {
    if (e.target.checked) {
      const targetCourier = couriersList.find((c) => c.guid === courierGuid);
      selectRoutes([...selectedRoutes, getDefaultSelectedRoute(targetCourier)]);
    } else {
      selectRoutes(selectedRoutes.filter((r) => r.courierGuid !== courierGuid));
    }
  };

  const onRouteSelect = (courierGuid) => (e) => {
    const filteredRoutes = selectedRoutes.filter((r) => r.courierGuid !== courierGuid);
    const route: ISelectedRoute = {
      routeGuid: e.target.value,
      courierGuid,
      sessionGuid: couriersList.find((c) => c.guid === courierGuid).sessionForLoading.guid,
      sessionStatus: couriersList.find((c) => c.guid === courierGuid).sessionForLoading.status,
    };
    selectRoutes([...filteredRoutes, route]);
  };

  const renderSpoilerHeader = (courier: ICouriersWithSessions) => (
    <>
      <div className="send-courier-spoiler__warning">{getWarning(courier)}</div>
      <div className="send-courier-spoiler__name">
        {`${courier.profile.surname} ${courier.profile.name.substring(0, 1)}. ${
          courier.profile.patronymic ? courier.profile.patronymic.substring(0, 1) + '.' : ''
        }`}
        {courier.sessionForLoading.routes.length > 1 && (
          <span className="send-courier-spoiler__route-count">
            {' '}
            ({courier.sessionForLoading.routes.length})
          </span>
        )}
      </div>
    </>
  );

  const renderCourier = (courier: ICouriersWithSessions) => {
    const collapseItem: CollapseProps['items'] = [
      {
        key: courier.guid,
        label: renderSpoilerHeader(courier),
        extra: (
          <Checkbox
            disabled={Boolean(getWarning(courier))}
            onChange={onCourierSelect(courier.guid)}
            checked={selectedRoutes.map((r) => r.courierGuid).indexOf(courier.guid) > -1}
            onClick={(e) => e.stopPropagation()}
          />
        ),
        children: getWarning(courier) ? null : (
          <Radio.Group
            value={selectedRoutes.find((r) => r.courierGuid === courier.guid)?.routeGuid}
            onChange={onRouteSelect(courier.guid)}
          >
            {courier.sessionForLoading.routes.map((r) => (
              <SendRouteCard
                key={r.guid}
                session={courier.sessionForLoading}
                route={r}
                isSingle={courier.sessionForLoading.routes.length === 1}
              />
            ))}
          </Radio.Group>
        ),
      },
    ];

    return (
      <Collapse
        key={courier.guid}
        expandIconPosition="end"
        bordered={false}
        className={`send-courier-spoiler ${
          Boolean(getWarning(courier)) && 'send-courier-spoiler--disabled'
        }`}
        items={collapseItem}
      />
    );
  };

  return couriersList.length > 0 && couriersList.map((courier) => renderCourier(courier));
};

export default SendList;
