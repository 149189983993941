import './style.less';

import React, { ReactNode } from 'react';

type InfoFactTypeProps = {
  children: ReactNode;
  header: ReactNode | string;
  className?: string;
};

const InfoFact = (props: InfoFactTypeProps) => {
  const { children, header, className } = props;

  if (header) {
    return (
      <div className={`info-fact ${className}`}>
        <div className="info-fact__header">{header}</div>
        <div className="info-fact__content">{children || '\u2014'}</div>
      </div>
    );
  }

  return null;
};

export default InfoFact;
