import './style.less';

import React, { ReactNode, useEffect, useState } from 'react';

import {
  ArrowRightOutlined,
  CarOutlined,
  EditOutlined,
  EnvironmentOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
} from '@ant-design/icons';
import { Button, Col, Empty, Modal, Popconfirm, Row } from 'antd';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import { timeFormat } from 'helpers/string';
import InfoFact from 'modules/common/components/InfoBlock/InfoFact';
import InfoSection from 'modules/common/components/InfoBlock/InfoSection';
import Status from 'modules/common/components/Status';
import CourierAddSession from 'modules/couriers/components/arm/CourierEditSession';
import { ICourier, ISession } from 'modules/couriers/models/types';
import { translate } from 'modules/localization';
import { getRoutesUrl } from 'modules/routes/components/RoutesList/helpers';
import { IRoute } from 'modules/routes/models/types';
import { useStore } from 'services/store';

dayjs.extend(utc);
dayjs.extend(timezone);

interface IProps {
  courierSessions: ISession[];
  onUpdate: () => void;
  courier: ICourier;
  routeUpdate: (guid: string) => void;
}

const CourierSessions = ({ courierSessions, onUpdate, courier, routeUpdate }: IProps) => {
  const { couriers, router, routes, core } = useStore();

  const [modal, contextHolder] = Modal.useModal();

  const [isOpenSessionModal, setIsOpenSessionModal] = useState<boolean>(false);
  const [editSession, setEditSession] = useState<ISession>(null);
  const [showRouteGuid, setShowRouteGuid] = useState<string>(null);

  useEffect(() => {
    routes.apiStore.getRoutesStatuses();
  }, []);

  const cancelSession = (item: ISession): void => {
    couriers.apiStore.deleteSession(item.guid).then(() => onUpdate());
  };

  const onCloseModalEditSession = (): void => {
    setIsOpenSessionModal(false);
    setEditSession(null);
  };

  const onOpenModalEditSession = (item: ISession): void => {
    setIsOpenSessionModal(!isOpenSessionModal);
    setEditSession(item);
  };

  const setSessionStatus = (item: ISession, status: string): void => {
    couriers.apiStore.setSessionStatus(item.guid, status).then(() => onUpdate());
  };

  const endSession = (item: ISession): void => {
    const completedRoutes = Array.isArray(item.routes)
      ? item.routes.filter((route) => route.status === 'complete' || route.status === 'canceled')
      : [];
    if (Array.isArray(item.routes) && completedRoutes.length !== item.routes.length) {
      modal.confirm({
        cancelText: translate('goToCourierSession'),
        okText: translate('cancelTheRoutes'),
        icon: null,
        title: translate('thereIsActiveRoutesInSession'),
        content: translate('endCourierSessionConfirm'),
        onOk: () => {
          setSessionStatus(item, 'complete');
        },
      });
    } else {
      setSessionStatus(item, 'complete');
    }
  };

  const headerSession = (item: ISession): ReactNode => (
    <div className="session__header">
      {contextHolder}
      <span>
        {translate('courierSessionOnDate', {
          content: dayjs(item.planned_date).format('DD.MM.YYYY'),
        })}
        {core.permissions['courier-sessions.delete'] &&
          (item.status === 'planned' || item.status === 'awaiting_courier') &&
          (!item.routes || (Array.isArray(item.routes) && item.routes.length === 0)) && (
            <Popconfirm
              onConfirm={(): void => cancelSession(item)}
              placement="rightTop"
              title={translate('areYouSure')}
            >
              <Button type="link" className="session__cancel-link">
                {translate('cancel')}
              </Button>
            </Popconfirm>
          )}
      </span>
      {core.permissions['courier-sessions.update'] && item.status === 'planned' && (
        <Button
          type="link"
          icon={<ArrowRightOutlined />}
          onClick={(): void => setSessionStatus(item, 'awaiting_courier')}
        >
          <CarOutlined style={{ marginLeft: '5px' }} />
          {translate('sendToCourier')}
        </Button>
      )}
      {core.permissions['courier-sessions.update'] && item.status === 'awaiting_courier' && (
        <span>
          <Popconfirm
            onConfirm={(): void => setSessionStatus(item, 'in_process')}
            placement="rightTop"
            title={translate('areYouSure')}
          >
            <Button type="link" icon={<UserAddOutlined />}>
              {translate('beginTheSession')}
            </Button>
          </Popconfirm>
        </span>
      )}
      {core.permissions['courier-sessions.update'] && item.status === 'in_process' && (
        <Popconfirm
          onConfirm={(): void => endSession(item)}
          placement="bottom"
          title={translate('areYouSure')}
        >
          <Button type={core.isMobile ? 'default' : 'link'} danger icon={<UserDeleteOutlined />}>
            {translate('endTheSession')}
          </Button>
        </Popconfirm>
      )}
    </div>
  );

  const headerSessionTransport = (item: ISession): ReactNode => (
    <span>
      {translate('transport')}
      {core.permissions['courier-sessions.update'] &&
        (item.status === 'planned' || item.status === 'awaiting_courier') && (
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={(): void => onOpenModalEditSession(item)}
          />
        )}
    </span>
  );

  const showRouteOnMap = (guid: string): void => {
    setShowRouteGuid(guid);
    if (showRouteGuid !== guid) {
      routeUpdate(guid);
    }
  };

  const renderRoute = (route: IRoute): ReactNode => {
    const current = routes.routesStatuses.find((s) => s.value === route.status);

    const countIsLateTasks =
      route.tasks &&
      route.tasks.reduce((accumulator, task) => {
        if (task.problems && task.problems.indexOf('late_complete') !== -1) {
          return ++accumulator;
        }
        return accumulator;
      }, 0);
    const countIsEarlyTasks =
      route.tasks &&
      route.tasks.reduce((accumulator, task) => {
        if (task.problems && task.problems.indexOf('early_complete') !== -1) {
          return ++accumulator;
        }
        return accumulator;
      }, 0);

    const timeStart = route.date_time_start || route.date_time_planned_start;
    const timeEnd = route.date_time_finish || route.date_time_planned_finish;
    const differenceOfDay =
      dayjs(timeStart).format('YYYY-MM-DD') !== dayjs(timeEnd).format('YYYY-MM-DD');

    return (
      <Row
        className="session-route"
        key={route.guid}
        onClick={(): void => router.push(getRoutesUrl(route.guid))}
      >
        <Col span={core.isMobile ? 24 : 7}>
          <Status status={current} />
        </Col>
        <Col span={core.isMobile ? 24 : 7}>
          <div>
            {differenceOfDay &&
              `${dayjs.tz(timeStart, route.start_warehouse.timezone).format('DD.MM')} \u2014 ${dayjs
                .tz(
                  timeEnd,
                  route.finish_warehouse
                    ? route.finish_warehouse.timezone
                    : route.start_warehouse.timezone,
                )
                .format('DD.MM')} / `}
            {`${dayjs(timeStart)
              .tz(route.start_warehouse.timezone)
              .format(timeFormat.simple)} \u2014 ${dayjs(timeEnd)
              .tz(
                route.finish_warehouse
                  ? route.finish_warehouse.timezone
                  : route.start_warehouse.timezone,
              )
              .format(timeFormat.simple)}`}
          </div>

          <div className="routes__late-fast-count">
            {countIsLateTasks > 0 &&
              `${countIsLateTasks} ${translate('lateness', { count: countIsLateTasks })}`}
            {countIsLateTasks > 0 && countIsEarlyTasks > 0 && ', '}
            {countIsEarlyTasks > 0 &&
              `${countIsEarlyTasks} ${translate('outrunning', { count: countIsEarlyTasks })}`}
          </div>
        </Col>
        <Col span={core.isMobile ? 24 : 7}>
          <Link to={getRoutesUrl(route.guid)}>#{route.number}</Link>
          <div className="routes__gray-text">
            {route.tasks &&
              `${route.tasks.length} ${translate('task', { count: route.tasks.length })}`}
          </div>
        </Col>
        {!core.isMobile && (
          <Col span={3} style={{ textAlign: 'right' }}>
            <EnvironmentOutlined
              className={`session-route__show-button${
                showRouteGuid === route.guid ? '_selected' : ''
              }`}
              onClick={(e): void => {
                e.preventDefault();
                e.stopPropagation();
                showRouteOnMap(route.guid);
              }}
            />
          </Col>
        )}
      </Row>
    );
  };

  const renderSessionInfo = (session: ISession): ReactNode => {
    const status = couriers.sessionStatuses.find((s) => s.value === session.status);
    return (
      <div key={session.guid}>
        <InfoSection header={headerSession(session)}>
          <div className="session-info">
            <InfoFact header={translate('status')}>
              <Status status={status} />
              {session.status === 'in_process' && (
                <div>c {dayjs(session.session_start).format('HH:mm')}</div>
              )}
              {session.status === 'complete' && (
                <div>
                  {dayjs(session.session_start).format('DD.MM')}
                  {' \u2014 '}
                  {dayjs(session.session_end).format('DD.MM')}
                  {' \u0020 '}/{' \u0020 '}
                  {dayjs(session.session_start).format('HH:mm')}
                  {' \u2014 '}
                  {dayjs(session.session_end).format('HH:mm')}
                </div>
              )}
            </InfoFact>
            <InfoFact header={headerSessionTransport(session)}>
              {session.transport && `${session.transport.name} ${session.transport.number}`}
            </InfoFact>
          </div>
        </InfoSection>
        <InfoSection header={translate('sessionsRoutes')} className="session-routes-list">
          {session.routes && session.routes.length > 0 ? (
            session.routes.map(renderRoute)
          ) : (
            <Empty />
          )}
        </InfoSection>
      </div>
    );
  };

  return (
    <>
      {courierSessions.map((session) => renderSessionInfo(session))}

      <Modal
        title={translate('courierSessionEditTitle')}
        open={isOpenSessionModal}
        onCancel={onCloseModalEditSession}
        footer={null}
        destroyOnClose
      >
        <CourierAddSession
          onClose={onCloseModalEditSession}
          courier={courier}
          onOk={onUpdate}
          session={editSession}
        />
      </Modal>
    </>
  );
};

export default observer(CourierSessions);
