import React, { useEffect } from 'react';

import { ExpandOutlined } from '@ant-design/icons';
import { Card, Descriptions, List } from 'antd';
import { observer } from 'mobx-react-lite';

import Status from 'modules/common/components/Status';
import DelorianState from 'modules/qa/common/DelorianState';
import Diff from 'modules/qa/common/Diff';
import { DiffFlag, RedisFlag } from 'modules/qa/common/flags';
import { IRoute } from 'modules/qa/models/types';
import { useStore } from 'services/store';

type IPropsSidebar = {
  route: IRoute;
};

const Sidebar = ({ route }: IPropsSidebar) => {
  return (
    <>
      <Descriptions bordered title="Маршрут" size="small" column={1}>
        <Descriptions.Item label="Маршрут">{route.guid}</Descriptions.Item>
        <Descriptions.Item label="account_system_notify_time">
          {route.account_system_notify_time}
        </Descriptions.Item>
        <Descriptions.Item label="Курьер">{route.courier_guid}</Descriptions.Item>
        <Descriptions.Item label="courier_notify_time">
          {route.courier_notify_time}
        </Descriptions.Item>
        <Descriptions.Item label="Блокирующий процесс">{route.locked}</Descriptions.Item>
        <Descriptions.Item label="Номер">{route.number}</Descriptions.Item>
        <Descriptions.Item label="Заказы">
          {route.order_guid_list && Array.isArray(route.order_guid_list)
            ? route.order_guid_list.map((o) => <p key={o}>{o}</p>)
            : null}
        </Descriptions.Item>
        <Descriptions.Item label="order_list_prev_hash">
          {route.order_list_prev_hash}
        </Descriptions.Item>
        <Descriptions.Item label="planed_end_time">{route.planed_end_time}</Descriptions.Item>
        <Descriptions.Item label="planned_start_time">{route.planned_start_time}</Descriptions.Item>
        <Descriptions.Item label="Статус">{route.status}</Descriptions.Item>
        <Descriptions.Item label="stop_routing_time">{route.planned_start_time}</Descriptions.Item>
        <Descriptions.Item label="Склад">{route.warehouse_guid}</Descriptions.Item>
      </Descriptions>

      <DelorianState entity={route} />

      <p>{route.redis_stored ? <RedisFlag withDescription /> : null}</p>

      <Diff entity={route} />

      <pre>{JSON.stringify(route, null, 2)}</pre>
    </>
  );
};

export const RouteSidebar = Sidebar;

const RouteList = () => {
  const { courierCollectorMonitor, routes } = useStore();

  useEffect(() => {
    courierCollectorMonitor.getRoutes({ pageSize: 100, current: 1 });
    routes.apiStore.getRoutesStatuses();
  }, []);

  return (
    <List
      grid={{ column: 1 }}
      header={<b>Маршруты</b>}
      dataSource={courierCollectorMonitor.routesList}
      renderItem={(route) => {
        const openRoute = () => {
          courierCollectorMonitor.setSidebarType('route');
          courierCollectorMonitor.setSidebarData(route);
        };
        const status = routes.routesStatuses.find((s) => s.value === route.status);
        return (
          <List.Item>
            <Card
              title={
                <>
                  {route.redis_stored ? <RedisFlag /> : null}
                  {route.difference ? <DiffFlag /> : null}
                </>
              }
              extra={
                <a href="#" rel="noreferrer" onClick={openRoute}>
                  <ExpandOutlined />
                </a>
              }
            >
              <p>
                <a target="_blank" rel="noreferrer" href={`/arm2/routes/${route.guid}`}>
                  {route.guid}
                </a>
              </p>
              {route.number ? (
                <p>
                  <a target="_blank" rel="noreferrer" href={`/arm2/routes/${route.guid}`}>
                    #{route.number}
                  </a>
                </p>
              ) : null}
              {route.status && status ? <Status status={status} /> : null}
            </Card>
          </List.Item>
        );
      }}
    />
  );
};

export default observer(RouteList);
