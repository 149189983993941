import React from 'react';

import classSet from 'helpers/classSet';
import { translate } from 'modules/localization';

interface IProps {
  setActiveTab: (name: 'information' | 'setting') => void;
  activeTab: string;
}

const ClusterFormTabs = ({ setActiveTab, activeTab }: IProps) => {
  return (
    <div className="cf-tabs">
      <button
        className={classSet({
          'cf-tab': true,
          'cf-tab--active': activeTab === 'information',
        })}
        onClick={(): void => setActiveTab('information')}
      >
        {translate('step') + ' 1. ' + translate('information')}
      </button>
      <button
        className={classSet({
          'cf-tab': true,
          'cf-tab--active': activeTab === 'setting',
        })}
        onClick={(): void => setActiveTab('setting')}
      >
        {translate('step') + ' 2. ' + translate('setting')}
      </button>
    </div>
  );
};

export default ClusterFormTabs;
