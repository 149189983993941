import './style.less';

import React from 'react';

import { Drawer } from 'antd';
import { observer } from 'mobx-react-lite';

import { ClusterItemSidebar } from 'modules/qa/common/ClusterItemsList';
import { ClusterSidebar } from 'modules/qa/common/ClusterList';
import { useStore } from 'services/store';

import { OrderSidebar } from '../OrderList';
import { TaskSidebar } from '../TaskList';

const Sidebar = () => {
  const { orderCollectorMonitor } = useStore();

  const getComponent = () => {
    switch (orderCollectorMonitor.sidebarType) {
      case 'task':
        return <TaskSidebar task={orderCollectorMonitor.sidebarData} />;

      case 'cluster':
        return <ClusterSidebar cluster={orderCollectorMonitor.sidebarData} />;

      case 'clusteritem':
        return <ClusterItemSidebar cluster={orderCollectorMonitor.sidebarData} />;

      case 'order':
        return <OrderSidebar order={orderCollectorMonitor.sidebarData} />;

      default:
        return null;
    }
  };

  const component = getComponent();

  const closeSidebar = () => {
    orderCollectorMonitor.setSidebarType(null);
    orderCollectorMonitor.setSidebarData(null);
  };

  return (
    <Drawer
      placement="right"
      onClose={closeSidebar}
      closable={false}
      open={component !== null}
      size="large"
    >
      {component}
    </Drawer>
  );
};

export default observer(Sidebar);
