import React from 'react';

import { observer } from 'mobx-react-lite';

import ListMobX from 'modules/directory/containers/List/List';
import { useStore } from 'services/store';

import { getColumns } from './helpers';

interface IProps {
  baseUrl: string;
}

const ClientGroupsList = ({ baseUrl }: IProps) => {
  const { clientGroups } = useStore();

  return (
    <ListMobX
      baseUrl={baseUrl}
      actionListGet={clientGroups.getList}
      actionSearch={clientGroups.search}
      list={clientGroups.list}
      pagination={clientGroups.pagination}
      getColumns={getColumns}
      isLoading={clientGroups.isLoadingList}
      actionDelete={clientGroups.delete}
    />
  );
};

export default observer(ClientGroupsList);
