import { ILocation } from 'modules/locations/models/types';

export const prepareData = (formData: ILocation): ILocation => {
  const { name, disabled, parent_guid, point, scale, warehouses, ...data } = formData;
  return {
    ...data,
    name: name ? name.trim() : null,
    parent_guid: parent_guid ? parent_guid.trim() : null,
    disabled: !disabled,
    point: point,
    scale: scale,
    warehouses: warehouses,
  };
};
