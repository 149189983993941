import { makeAutoObservable } from 'mobx';

import { RestException } from 'api/RestException';
import { getLastPagePagination, getResponsePagination, isOutOfPages } from 'helpers/pagination';
import { ViewNotification } from 'modules/common/containers/Notify';
import { translate } from 'modules/localization';
import RatesApi from 'modules/rates/api/RatesApi';
import { IRates, IRatesFilter, IRatesForm } from 'modules/rates/models/types';

import { RatesStore } from './RatesStore';

export class RatesApiStore {
  root: RatesStore;

  constructor(root: RatesStore) {
    this.root = root;
    makeAutoObservable(this);
  }

  getRates = async (filter: IRatesFilter = this.root.filter): Promise<IRates[]> => {
    try {
      this.root.activeRequestRatesList && this.root.activeRequestRatesList.abort();
      this.root.activeRequestRatesList = new AbortController();
      const request = this.root.activeRequestRatesList;

      const { data: res } = await RatesApi.getRatesList(filter, request.signal);
      if (isOutOfPages(res.pagination)) {
        return this.getRates({ ...filter, ...getLastPagePagination(res.pagination) });
      }
      this.root.ratesList = res.data;
      if (!res.errors && res.pagination) {
        this.root.setFilterToUrl({
          ...filter,
          current: res.pagination.page,
          pageSize: res.pagination.page_size,
        });
        const responsePagination = getResponsePagination(res.pagination);
        this.root.setFilter('current', responsePagination.current);
        this.root.setFilter('pageSize', responsePagination.pageSize);
        this.root.setFilter('total', responsePagination.total);
      }
      return res.data;
    } catch (e) {
      throw new RestException(e);
    } finally {
      if (this.root.activeRequestRatesList && !this.root.activeRequestRatesList.signal.aborted) {
        this.root.activeRequestRatesList = null;
      }
    }
  };

  createRate = async (data: IRatesForm): Promise<void> => {
    try {
      this.root.loadingForm = true;
      await RatesApi.createRate(data);
      this.root.resetFormData();
      ViewNotification.success({ message: translate('rateCreated') });
      this.root.loadingForm = false;
    } catch (e) {
      this.root.loadingForm = false;
      throw new RestException(e);
    }
  };

  editRate = async (rateGuid: string, data: IRatesForm): Promise<void> => {
    try {
      this.root.loadingForm = true;
      await RatesApi.editRate(rateGuid, data);
      this.root.resetFormData();
      ViewNotification.success({ message: translate('rateEdited') });
      this.root.loadingForm = false;
    } catch (e) {
      this.root.loadingForm = false;
      throw new RestException(e);
    }
  };

  getRate = async (rateGuid: string): Promise<IRatesForm> => {
    try {
      const { data: res } = await RatesApi.getRate(rateGuid);
      const formData = { ...res.data };
      this.root.setFormData(formData);
      return formData;
    } catch (e) {
      throw new RestException(e);
    }
  };

  deleteRates = async (rateGuid: string): Promise<void> => {
    try {
      await RatesApi.deleteLRate(rateGuid);
      ViewNotification.success({ message: translate('rateDeleted') });
    } catch (e) {
      throw new RestException(e);
    }
  };
}
