import './style.less';

import React from 'react';

import ClusterSettingDeliveryMethod from 'modules/clusters/components/ClusterSetting/ClusterSettingDeliveryMethod';
import ClusterSettingHeader from 'modules/clusters/components/ClusterSetting/ClusterSettingHeader';
import { ICluster } from 'modules/clusters/models/types';

interface IProps {
  baseUrl: string;
  cluster: ICluster;
}

const ClusterSetting = ({ baseUrl, cluster }: IProps) => {
  const deliveryMethods = cluster?.settings?.delivery_settings_list;

  return (
    <div className="cluster-setting">
      <ClusterSettingHeader baseUrl={baseUrl} guid={cluster.guid} />
      {deliveryMethods?.length > 0 &&
        deliveryMethods
          .sort((a, b) => (a.delivery_method_guid > b.delivery_method_guid ? 1 : -1))
          .map((dm) => {
            return (
              <ClusterSettingDeliveryMethod
                baseUrl={baseUrl}
                data={dm}
                key={dm.delivery_method_guid}
                clusterGuid={cluster?.guid}
              />
            );
          })}
    </div>
  );
};

export default ClusterSetting;
