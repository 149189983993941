import React from 'react';

import { Empty } from 'antd';

import CoverageGroup from 'modules/routeGenerator/components/common/CoverageGroup';
import Task from 'modules/routeGenerator/components/common/Task';
import { highlightCoverages } from 'modules/routeGenerator/models/utils';
import { getCoveragesFromTaskList, groupTasksByCoverages } from 'modules/tasks/helpers/lists';
import { ITask } from 'modules/tasks/models/types';
import { sortCoverages, sortTaskList } from 'modules/tasks/models/utils';

interface IProps {
  taskList: ITask[];
  isLeft?: boolean;
  isLeftGrouped?: boolean;
  isRight?: boolean;
  isRightGrouped?: boolean;
  sortType?: string;
}

const TaskList = ({
  taskList,
  isRight,
  isRightGrouped,
  isLeft,
  isLeftGrouped,
  sortType,
}: IProps) => {
  if (taskList.length === 0) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  if (isLeft) {
    return taskList.map((item) => (
      <Task classNames="rg-task--left" key={item.guid} task={item} isSelectable />
    ));
  }

  if (isLeftGrouped) {
    const coverages = getCoveragesFromTaskList(taskList);
    const tasksGrouped = groupTasksByCoverages(taskList);

    return (
      <>
        {highlightCoverages(coverages, tasksGrouped).map((coverage) => (
          <CoverageGroup
            key={coverage.guid}
            coverage={coverage}
            taskList={tasksGrouped[coverage.guid].tasks}
            isRight={isRightGrouped}
          />
        ))}
      </>
    );
  }

  if (isRight) {
    const sortedTaskList = sortTaskList(taskList, sortType);
    return (
      <>
        {sortedTaskList.map((task) => (
          <Task key={task.guid} task={task} isRemovable classNames="rg-task--right-in-group" />
        ))}
      </>
    );
  }

  if (isRightGrouped) {
    const sortedTaskList = sortTaskList(taskList, sortType);
    const coverages = getCoveragesFromTaskList(sortedTaskList);
    const tasksGrouped = groupTasksByCoverages(sortedTaskList);
    const sortedCoverages = sortCoverages(coverages, tasksGrouped, sortType);

    return (
      <>
        {highlightCoverages(sortedCoverages, tasksGrouped).map((coverage) => (
          <CoverageGroup
            key={coverage.guid}
            coverage={coverage}
            taskList={tasksGrouped[coverage.guid].tasks}
            isRight={isRightGrouped}
          />
        ))}
      </>
    );
  }

  return null;
};

export default TaskList;
