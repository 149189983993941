import Api from 'api/Api';
import { IPagination } from 'api/types';
import {
  IClearResponse,
  IClustersListGetResponse,
  ICourierSessionsListGetResponse,
  IRoutesListGetResponse,
} from 'modules/qa/models/types';

export default class CourierCollectorMonitorApi {
  static getClusters(
    pagination: IPagination,
    signal?: AbortSignal,
  ): Promise<IClustersListGetResponse> {
    const query = {
      page: pagination.current,
      page_size: pagination.pageSize,
    };
    return Api.get('/api/v1/courier-collector/qa/clusters', query, signal);
  }

  static getCoiriersSessions(
    pagination: IPagination,
    signal?: AbortSignal,
  ): Promise<ICourierSessionsListGetResponse> {
    const query = {
      page: pagination.current,
      page_size: pagination.pageSize,
    };
    return Api.get('/api/v1/courier-collector/qa/courier-sessions', query, signal);
  }

  static getRoutes(pagination: IPagination, signal?: AbortSignal): Promise<IRoutesListGetResponse> {
    const query = {
      page: pagination.current,
      page_size: pagination.pageSize,
    };
    return Api.get('/api/v1/courier-collector/qa/routes', query, signal);
  }

  static clearHard(): Promise<IClearResponse> {
    return Api.delete('/api/v1/courier-collector/qa/clear/hard');
  }

  static clearSoft(): Promise<IClearResponse> {
    return Api.delete('/api/v1/courier-collector/qa/clear/soft');
  }
}
