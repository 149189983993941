import './style.less';

import React from 'react';

import { observer } from 'mobx-react-lite';

import Title from 'modules/common/components/Title';

import ClusterList from '../ClusterList';
import CourierSessionList from '../CourierSessionList';
import RouteList from '../RouteList';
import Sidebar from '../Sidebar';

const CourierCollectorMonitor = () => {
  return (
    <div className="fastmine">
      <Sidebar />
      <div className="fastmine__header">
        <Title size={Title.SIZE.H3} weight={Title.WEIGHT.SEMIBOLD}>
          Мониторинг courier collector
        </Title>
      </div>
      <div className="fastmine__list list">
        <ClusterList />
        <CourierSessionList />
        <RouteList />
      </div>
    </div>
  );
};

export default observer(CourierCollectorMonitor);
