import React from 'react';

export const BakerSVG = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.3496 14.2959C17.364 14.2781 17.3782 14.2602 17.3924 14.2422L17.3496 14.2959Z"
      fill="#EE7505"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.21061 15.9011L5.14995 15.8298C3.80995 14.2547 3 12.2083 3 9.97101V9.94058C3 7.20755 5.22921 5 7.95506 5C9.58018 5 11.0172 5.78522 11.9192 6.98852L12 7.09633L12.0808 6.98852C12.9828 5.78531 14.4298 5 16.0449 5C18.7801 5 21 7.22704 21 9.97101C21 12.2083 20.1901 14.2547 18.85 15.8298L18.7893 15.9011L19.8002 16.9151C19.9982 17.1138 19.9976 17.4359 19.799 17.6338L19.2241 18.2065C19.0264 18.4034 18.7072 18.4028 18.5102 18.2051L17.4602 17.1517L17.3901 17.2042C15.8901 18.3278 13.9999 19 12 19C10.0001 19 8.10992 18.3278 6.60994 17.2042L6.53981 17.1517L5.48972 18.2051C5.29276 18.4028 4.97359 18.4034 4.77593 18.2065L4.20108 17.6338C4.00243 17.4359 4.00186 17.1138 4.19981 16.9151L5.21061 15.9011ZM16.0619 15.9081L16.1619 15.8388L12 11.6741L7.83808 15.8388L7.93806 15.9081C9.0972 16.7108 10.4924 17.1739 12 17.1739C13.5076 17.1739 14.9028 16.7108 16.0619 15.9081ZM6.43041 14.5189L6.50101 14.6063L10.7632 10.3401C10.9702 10.1329 11.1023 9.84296 11.0603 9.53737C10.849 8.00317 9.5335 6.82609 7.95506 6.82609C6.22055 6.82609 4.82022 8.23093 4.82022 9.97101C4.82022 11.6997 5.42553 13.2742 6.43041 14.5189ZM17.499 14.6063L17.5696 14.5189C18.5744 13.2742 19.1798 11.6997 19.1798 9.97101C19.1798 8.22034 17.7689 6.82609 16.0449 6.82609C14.4665 6.82609 13.151 8.00317 12.9397 9.53737C12.8977 9.84296 13.0298 10.1329 13.2368 10.3401L17.499 14.6063Z"
      fill="#EE7505"
    />
  </svg>
);
