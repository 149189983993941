import React from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { observer } from 'mobx-react-lite';
import { Link, useLocation } from 'react-router-dom';

import { translate } from 'modules/localization';
import { useStore } from 'services/store';

interface IProps {
  baseUrl: string;
  clusterGuid: string;
  deliveryMethodGuid: string;
}

const ClusterSettingEditButtons = ({ baseUrl, clusterGuid, deliveryMethodGuid }: IProps) => {
  const { clusters } = useStore();
  const location = useLocation();

  const remove = () => {
    clusters.deleteDeliveryMethodFromCluster(clusterGuid, deliveryMethodGuid);
  };

  return (
    <>
      <div className="cluster-vertical-line" />
      <Link to={`${baseUrl}/${clusterGuid}/edit-settings/${deliveryMethodGuid}${location.search}`}>
        <Button type="link" className="cluster-button">
          {translate('edit')}
        </Button>
      </Link>
      <div className="cluster-vertical-line" />
      <Popconfirm onConfirm={remove} placement="rightTop" title={translate('areYouSure')}>
        <Button type="link" className="cluster-button">
          <DeleteOutlined /> {translate('delete')}
        </Button>
      </Popconfirm>
    </>
  );
};

export default observer(ClusterSettingEditButtons);
