import './style.less';

import React, { useEffect, useRef, useState } from 'react';

import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import { observer } from 'mobx-react-lite';
import { useDrag } from 'react-dnd';

import classSet from 'helpers/classSet';
import { stopPropagation } from 'helpers/stopPropagation';
import HintedText from 'modules/common/components/HintedText';
import { ICoverage } from 'modules/coverages/models/types';
import Task from 'modules/routeGenerator/components/common/Task';
import TaskListCounter from 'modules/routeGenerator/components/common/TaskListCounter';
import TaskListSizes from 'modules/routeGenerator/components/common/TaskListSizes';
import { DndTypes } from 'modules/routeGenerator/stores/RouteGeneratorDnDStore';
import { ITask } from 'modules/tasks/models/types';
import { getRelativeGuidsFromList } from 'modules/tasks/models/utils';
import { useStore } from 'services/store';

interface IProps {
  coverage: Partial<ICoverage>;
  taskList: ITask[];
  isRight?: boolean;
}

const CoverageGroup = ({ coverage, taskList, isRight }: IProps) => {
  const { routeGenerator } = useStore();

  const [{ isDragging }, drag] = useDrag(() => ({
    type: isRight ? DndTypes.RIGHT_COVERAGE : DndTypes.LEFT_COVERAGE,
    item: {
      id: coverage.guid,
      selectedGuids: () => routeGenerator.selectedTaskGuids,
      currentGuids: taskList.map((t) => t.guid),
    },
    isDragging: (monitor) => {
      return taskList.some(
        (task) =>
          monitor.getItem().selectedGuids().includes(task.guid) ||
          monitor.getItem().currentGuids.includes(task.guid),
      );
    },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  }));

  const [isHideTasks, setIsHideTasks] = useState(true);
  const toggleTasks = (e) => {
    stopPropagation(e);
    setIsHideTasks(!isHideTasks);
  };

  const isSelected =
    taskList && !taskList.some((item) => !routeGenerator.selectedTaskGuids.includes(item.guid));

  const toggleSelect = (e) => {
    if (e.target.checked) {
      routeGenerator.selectTaskList(getRelativeGuidsFromList(taskList));
    } else {
      routeGenerator.deselectTaskList(getRelativeGuidsFromList(taskList));
    }
  };

  const scrollIntoViewRef = useRef(null);
  const scrollIntoView = () => {
    scrollIntoViewRef?.current?.scrollIntoView();
  };

  useEffect(() => {
    scrollIntoView();
  }, [coverage.isScrollableIntoView]);

  return (
    <div
      className={classSet({
        'rg-coverage-group': true,
        'rg-coverage-group--dragging': isDragging,
        'rg-coverage-group--highlighted': isHideTasks && coverage.isHighlighted,
      })}
      ref={drag}
    >
      <div
        ref={coverage.isScrollableIntoView ? scrollIntoViewRef : null}
        className={classSet({
          'rg-coverage-group__head': true,
          'rg-coverage-group__head--active': !isHideTasks,
        })}
      >
        {!isRight && (
          <Checkbox
            className="rg-coverage-group__checkbox"
            checked={isSelected}
            onChange={toggleSelect}
            data-testid="generator-taskCheckbox"
          />
        )}
        <HintedText
          content={
            <>
              {coverage ? coverage.name : null}
              {': '}
              <TaskListCounter taskList={taskList} isSimple />
            </>
          }
        >
          {coverage ? coverage.name : null}
          {' ('}
          {taskList.length}
          {')'}
        </HintedText>
        {isHideTasks ? (
          <DownOutlined className="rg-coverage-group__toggler" onClick={toggleTasks} />
        ) : (
          <UpOutlined className="rg-coverage-group__toggler" onClick={toggleTasks} />
        )}
        <div className="rg-coverage-group__sizes">
          <TaskListSizes taskList={taskList} currencyCode={routeGenerator.currency} />
        </div>
      </div>
      {!isHideTasks && (
        <div className="rg-coverage-group__body">
          {taskList.map((item) => (
            <Task
              classNames={`${isRight ? 'rg-task--right-in-group' : 'rg-task--left-in-group'}`}
              key={item.guid}
              task={item}
              isSelectable={!isRight}
              isRemovable={isRight}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default observer(CoverageGroup);
