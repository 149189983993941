import './style.less';

import React, { ReactNode } from 'react';

import { Popover } from 'antd';

interface IProps {
  content?: ReactNode;
  children: ReactNode;
}

const HintedText = ({ children, content }: IProps) => {
  return (
    <div className="text_flex-hidden">
      <Popover
        content={content ? content : children}
        overlayStyle={{
          maxWidth: '50vw',
        }}
      >
        <div className="text_ellipsis">{children}</div>
      </Popover>
    </div>
  );
};

export default HintedText;
