import React from 'react';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { Checkbox, Input, InputNumber, Tooltip } from 'antd';

import { IFieldData, latitudeValidator, longitudeValidator } from 'helpers/form';
import { translate } from 'modules/localization';
import { isCoordsExist } from 'modules/map/helpers';
import ShopsSelect from 'modules/shops/components/ShopsSelect';
import TransportSelect from 'modules/transports/components/TransportSelect';
import { IWarehouses, IWarehousesForm, IWarehousesPayload } from 'modules/warehouses/models/types';

import TimezoneSelect from '../TimezoneSelect';

const renderHelpLabel = (label, message) => {
  return (
    <>
      <span>{label} </span>
      <Tooltip title={message}>
        <QuestionCircleOutlined className="warehouses-form__help-icon" />
      </Tooltip>
    </>
  );
};

export const getFields = (
  isEdit: boolean,
  isView: boolean,
  isCreate: boolean,
  onLatChange,
  onLonChange,
): IFieldData => ({
  guid: {
    component: Input,
    formItem: {
      label: translate('id'),
      className: 'warehouses-form__item',
    },
    name: 'guid',
    params: {
      rules: [
        {
          message: translate('requiredValidator'),
          required: true,
        },
      ],
    },
    props: {
      placeholder: translate('enterId'),
      disabled: isEdit || isView,
    },
  },
  title: {
    component: Input,
    formItem: {
      label: translate('title'),
      className: 'warehouses-form__item',
    },
    name: 'title',
    params: {
      rules: [
        {
          message: translate('requiredValidator'),
          required: true,
        },
      ],
    },
    props: {
      placeholder: translate('enterTitle'),
      disabled: isView,
    },
  },
  address: {
    component: Input,
    formItem: {
      label: translate('addressTitle'),
      className: 'warehouses-form__item',
    },
    name: 'address',
    params: {
      rules: [
        {
          message: translate('requiredValidator'),
          required: true,
        },
      ],
    },
    props: {
      placeholder: translate('enterAddress'),
      disabled: isView,
    },
  },
  phone: {
    component: Input,
    formItem: {
      label: translate('phone'),
      className: 'warehouses-form__item',
    },
    name: 'phone',
    params: {
      rules: [
        {
          message: translate('requiredValidator'),
          required: true,
        },
        { min: 7 },
        { max: 15 },
        { pattern: /^-?[0-9]*(\\.[0-9]*)?$/ },
      ],
    },
    props: {
      placeholder: translate('enterPhone'),
      disabled: isView,
      controls: false,
    },
  },
  lat: {
    component: InputNumber,
    formItem: {
      label: renderHelpLabel(translate('latitude'), translate('enterPoint')),
      className: 'warehouses-form__item',
    },
    name: 'lat',
    params: {
      rules: [
        {
          message: translate('requiredValidator'),
          required: true,
        },
        {
          validator: latitudeValidator,
        },
      ],
    },
    props: {
      placeholder: translate('enterLatitude'),
      disabled: isView,
      controls: false,
      onChange: onLatChange,
      step: '0.0001',
    },
  },
  lon: {
    component: InputNumber,
    formItem: {
      label: renderHelpLabel(translate('longitude'), translate('enterPoint')),
      className: 'warehouses-form__item',
    },
    name: 'lon',
    params: {
      rules: [
        {
          message: translate('requiredValidator'),
          required: true,
        },
        {
          validator: longitudeValidator,
        },
      ],
    },
    props: {
      placeholder: translate('enterLongitude'),
      disabled: isView,
      controls: false,
      onChange: onLonChange,
      step: '0.0001',
    },
  },
  timezone: {
    component: TimezoneSelect,
    formItem: {
      label: translate('timezone'),
      className: 'warehouses-form__item',
    },
    name: 'timezone',
    params: {
      rules: [
        {
          message: translate('requiredValidator'),
          required: true,
        },
      ],
    },
    props: {
      disabled: isView,
    },
  },
  groupGuid: {
    component: Input,
    formItem: {
      label: translate('warehouseGroupId'),
      className: 'warehouses-form__item',
    },
    name: 'group_guid',
    props: {
      placeholder: translate('enterWarehouseGroupId'),
      disabled: isView,
    },
  },
  shops: {
    component: ShopsSelect,
    formItem: {
      label: translate('shops'),
      className: 'warehouses-form__item',
    },
    name: 'shops',
    params: {
      rules: [
        {
          message: translate('requiredValidator'),
          required: true,
        },
      ],
    },
    props: {
      mode: 'multiple',
      disabled: isView,
    },
  },
  transports: {
    component: TransportSelect,
    formItem: {
      label: translate('transport'),
      className: 'warehouses-form__item',
    },
    name: 'transports',
    props: {
      mode: 'multiple',
      disabled: isView,
    },
  },
  disabled: {
    component: Checkbox,
    formItem: {
      label: translate('status'),
      className: 'warehouses-form__item',
    },
    name: 'disabled',
    params: {
      initialValue: isCreate,
      valuePropName: 'checked',
    },
    props: {
      children: translate('activity'),
      disabled: isView,
    },
  },
});

export const getFieldsList = (isEdit) => {
  const fieldsTitle = ['guid', 'title', 'address'];
  const fieldsCoords = ['lat', 'lon'];
  const fieldsContacts = ['phone', 'timezone', 'groupGuid', 'shops', 'transports', 'disabled'];
  return isEdit
    ? [...fieldsTitle, ...fieldsCoords, ...fieldsContacts]
    : [...fieldsTitle, ...fieldsContacts];
};

export const getDataToSet = (item: IWarehouses): IWarehousesForm => {
  return {
    guid: item.guid,
    group_guid: item.group_guid,
    address: item.address,
    phone: item.phone,
    lat: item.point ? item.point.lat : null,
    lon: item.point ? item.point.lon : null,
    timezone: item.timezone,
    title: item.title,
    disabled: !item.disabled,
    shops: item.shops ? item.shops.map((i) => i.guid) : [],
    transports: item.transports
      ? item.transports.map((m) => ({
          key: m.guid,
          label: `${m.name} ${m.number}${m.disabled ? ` (${translate('disabled')})` : ''}`,
        }))
      : undefined,
  };
};

export const getEmptyWarehouseMarker = (): Pick<
  IWarehousesForm,
  'address' | 'guid' | 'lat' | 'lon' | 'title'
> => {
  return {
    lat: null,
    lon: null,
    address: null,
    guid: null,
    title: null,
  };
};

export const prepareData = (
  formData: IWarehousesForm | Record<string, never>,
  isEdit: boolean,
): IWarehousesPayload => {
  const preparedData = {
    group_guid: formData.group_guid ? formData.group_guid.trim() : null,
    address: formData.address ? formData.address.trim() : null,
    phone: formData.phone ? formData.phone.trim() : null,
    timezone: formData.timezone ? formData.timezone.trim() : null,
    title: formData.title ? formData.title.trim() : null,
    point: isCoordsExist(formData.lat, formData.lon)
      ? {
          lat: Number(formData.lat),
          lon: Number(formData.lon),
        }
      : { lat: null, lon: null },
    shops: formData.shops,
    transports: formData.transports
      ? formData.transports.map((t) => (typeof t === 'string' ? t : t.key))
      : [],
    disabled: !formData.disabled,
  };
  return isEdit
    ? preparedData
    : {
        guid: formData.guid ? formData.guid.trim() : null,
        ...preparedData,
      };
};
