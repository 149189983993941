import './style.less';

import React, { ReactNode } from 'react';

import Layout from 'modules/common/containers/Layout';

class Privacy extends React.Component {
  renderMain(): ReactNode {
    return (
      <div className="privacy">
        <div className="privacy__content">
          <h1>
            <strong>Политика конфиденциальности персональных данных </strong>
          </h1>
          <p>
            Настоящая Политика конфиденциальности персональных данных (далее – Политика
            конфиденциальности) действует в отношении всей информации, которую Общество с
            ограниченной ответственностью «МАГ Девелопмент» (ООО «МАГ Девелопмент») может получить о
            Пользователе посредством информационно-телекоммуникационной сети «Интернет» через сайт
            или через{' '}
            <span>
              <a
                href="https://ru.wikipedia.org/wiki/%D0%9C%D0%BE%D0%B1%D0%B8%D0%BB%D1%8C%D0%BD%D0%BE%D0%B5_%D0%BF%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5"
                rel="nofollow"
              >
                мобильное приложение
              </a>
            </span>{' '}
            программы для ЭВМ «Автоматизация курьерской службы» (MAG Delivery), далее по тексту –
            программа.
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ.</strong>
          </p>
          <p>1.1. В настоящей Политике конфиденциальности используются следующие термины:</p>
          <p>
            1.1.1. Персональные данные - любая информация, относящаяся прямо или косвенно к
            определенному или к определяемому физическому лицу (субъекту персональных данных).
          </p>
          <p>
            1.1.2. Обработка персональных данных - любое действие (операция) или совокупность
            действий (операций), совершаемых с использованием средств автоматизации или без
            использования таких средств с персональными данными, включая сбор, запись,
            систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение,
            использование, передачу (распространение, предоставление, доступ), обезличивание,
            блокирование, удаление, уничтожение персональных данных.
          </p>
          <p>
            1.1.3. Конфиденциальность персональных данных - обязательное для соблюдения ООО «МАГ
            Девелопмент» или иным получившим доступ к персональным данным лицом требование не
            допускать их распространения без согласия субъекта персональных данных или наличия иного
            законного основания.
          </p>
          <p>
            1.1.4. Пользователь – любое лицо, имеющее доступ к программе и использующее её
            посредством информационно-телекоммуникационной сети «Интернет».
          </p>
          <p>
            1.1.5. Cookies — небольшой фрагмент данных, отправленный веб-сервером и хранимый на
            устройстве пользователя (компьютере, планшете, смартфоне и т.п.), который веб-клиент или
            веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть
            страницу соответствующего сайта.
          </p>
          <p>
            1.1.6. IP-адрес — уникальный сетевой адрес узла в компьютерной сети, построенной по
            протоколу IP.
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>2. ОБЩИЕ ПОЛОЖЕНИЯ.</strong>
          </p>
          <p>
            2.1. Использование программы означает согласие Пользователя с настоящей Политикой
            конфиденциальности и условиями обработки персональных данных Пользователя.
          </p>
          <p>
            2.2. В случае несогласия с условиями Политики конфиденциальности Пользователь должен
            прекратить использование программы.
          </p>
          <p>
            2.3. Настоящая Политика конфиденциальности применяется к программе. ООО «МАГ
            Девелопмент» не контролирует и не несет ответственность за сайты или иные
            интернет-ресурсы третьих лиц, на которые Пользователь может перейти по ссылкам,
            доступным в программе.
          </p>
          <p>
            2.4. ООО «МАГ Девелопмент» не проверяет достоверность персональных данных,
            предоставляемых Пользователем.
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>3. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ.</strong>
          </p>
          <p>
            3.1. Настоящая Политика конфиденциальности устанавливает обязательства ООО «МАГ
            Девелопмент» по неразглашению и обеспечению режима конфиденциальности персональных
            данных, которые Пользователь предоставляет при регистрации в программе.
          </p>
          <p>
            3.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики
            конфиденциальности, предоставляются Пользователем добровольно и включают в себя
            следующую информацию:
          </p>
          <p>3.2.1. фамилию, имя, отчество Пользователя;</p>
          <p>3.2.2. контактный телефон Пользователя;</p>
          <p>3.2.3. адрес электронной почты (e-mail) Пользователя;</p>
          <p>3.2.4. адрес места жительства/нахождения Пользователя;</p>
          <p>
            3.2.5. государственный номер и марка автомобиля Пользователя, на котором он осуществляет
            деятельность курьера.
          </p>
          <p>
            3.3. ООО «МАГ Девелопмент» обрабатывает и защищает данные, которые автоматически
            передаются в процессе просмотра рекламных блоков и при посещении страниц, на которых
            установлен статистический скрипт системы («пиксель»):
          </p>
          <ul>
            <li>IP адрес;</li>
            <li>информация из cookies;</li>
            <li>
              информация о браузере (или иной программе, которая осуществляет доступ к показу
              рекламы);
            </li>
            <li>время доступа;</li>
            <li>адрес страницы, на которой расположен рекламный блок;</li>
            <li>информация о геолокации;</li>
            <li>реферер (адрес предыдущей страницы).</li>
          </ul>
          <p>
            3.3.1. Отключение cookies может повлечь невозможность доступа к некоторым сервисам
            интернет-магазина, требующим авторизации. Если вы не хотите использовать файлы cookie,
            измените настройки браузера.
          </p>
          <p>
            3.3.2. ООО «МАГ Девелопмент» осуществляет сбор статистики об IP-адресах своих
            посетителей. Данная информация используется с целью выявления и решения технических
            проблем, для контроля законности проводимых финансовых платежей.
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>4. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ.</strong>
          </p>
          <p>4.1. Персональные данные Пользователя ООО «МАГ Девелопмент» использует в целях:</p>
          <p>4.1.1. идентификации Пользователя в программе;</p>
          <p>4.1.2. использования программы;</p>
          <p>
            4.1.3. предоставления Пользователю доступа к персонализированным ресурсам программы;
          </p>
          <p>
            4.1.4. установления с Пользователем обратной связи, включая направление информационных
            рассылок, касающихся использования программы (о регистрации в программе, об изменении
            учетных данных, о значительных изменениях в работе программы и т.п.), обработки запросов
            и заявок от Пользователя;
          </p>
          <p>
            4.1.5. определения места нахождения Пользователя для обеспечения безопасности,
            предотвращения мошенничества;
          </p>
          <p>
            4.1.6. предоставления Пользователю эффективной клиентской и технической поддержки при
            возникновении проблем/вопросов, связанных с использованием программы;
          </p>
          <p>
            4.1.7. осуществления рекламной деятельности (при получении отдельного согласия
            Пользователя), в том числе: получение рекламных рассылок на адрес электронной почты и на
            номер мобильного телефона Пользователя.
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>5. СПОСОБЫ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ.</strong>
          </p>
          <p>
            5.1. Обработка персональных данных Пользователя осуществляется любым законным способом,
            в том числе посредством сбора, записи, систематизации, накопления, хранения, уточнения
            (обновления, изменения), извлечения, использования, передачи (распространения,
            предоставления, доступа), обезличивания, блокирования, удаления, уничтожения
            персональных данных, осуществляемую с использованием средств автоматизации, в том числе
            в информационно-телекоммуникационных сетях, или без использования таких средств в целях
            и в объеме, указанных в разделах 3-4 настоящей Политики конфиденциальности.
          </p>
          <p>
            5.2. Пользователь соглашается с тем, что ООО «МАГ Девелопмент» вправе передавать
            персональные данные третьим лицам, в частности, курьерским службам, организациями
            почтовой связи, операторам электросвязи, получателям товаров/корреспонденции в целях
            выполнения Пользователем заказов по доставке товаров/корреспонденции.
          </p>
          <p>
            5.3. Персональные данные Пользователя могут быть переданы уполномоченным органам
            государственной власти Российской Федерации по основаниям и в порядке, установленным
            законодательством Российской Федерации.
          </p>
          <p>
            5.4. При утрате или разглашении персональных данных ООО «МАГ Девелопмент» информирует
            Пользователя об утрате или разглашении персональных данных.
          </p>
          <p>
            5.5. ООО «МАГ Девелопмент» принимает необходимые организационные и технические меры для
            защиты персональных данных Пользователя от неправомерного или случайного доступа,
            уничтожения, изменения, блокирования, копирования, распространения, а также от иных
            неправомерных действий третьих лиц.
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>6. ОБЯЗАТЕЛЬСТВА СТОРОН.</strong>
          </p>
          <p>6.1. Пользователь обязан:</p>
          <p>
            6.1.1. Предоставить информацию о персональных данных, необходимую для использования
            программы.
          </p>
          <p>
            6.1.2. Обновить, дополнить предоставленную информацию о персональных данных в случае
            изменения данной информации.
          </p>
          <p>6.2. ООО «МАГ Девелопмент» обязано:</p>
          <p>
            6.2.1. Использовать полученную информацию исключительно для целей, указанных в разделе 4
            настоящей Политики конфиденциальности.
          </p>
          <p>
            6.2.2. Обеспечить хранение персональных данных в тайне, не разглашать их без
            предварительного письменного разрешения Пользователя, а также не осуществлять продажу,
            обмен, опубликование, либо разглашение иными возможными способами переданных
            персональных данных Пользователя, за исключением п.п. 5.2. и 5.3. настоящей Политики
            конфиденциальности.
          </p>
          <p>
            6.2.3. Принимать меры для защиты конфиденциальности персональных данных Пользователя
            согласно порядку, обычно используемого для защиты такого рода информации.
          </p>
          <p>
            6.2.4. Осуществить блокирование персональных данных, относящихся к соответствующему
            Пользователю, с момента обращения Пользователя или его законного представителя, или
            уполномоченного органа по защите прав субъектов персональных данных.
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>7.</strong> <strong>ОТВЕТСТВЕННОСТЬ СТОРОН И РАЗРЕШЕНИЕ СПОРОВ.</strong>
          </p>
          <p>
            7.1. ООО «МАГ Девелопмент», не исполнившее свои обязательства, несёт ответственность за
            убытки, понесённые Пользователем в связи с неправомерным использованием персональных
            данных, в соответствии с законодательством Российской Федерации, за исключением случаев,
            предусмотренных п.п. 5.2., 5.3. и 7.2. настоящей Политики конфиденциальности.
          </p>
          <p>
            7.2. В случае утраты или разглашения персональных данных ООО «МАГ Девелопмент» не несёт
            ответственность, если персональные данные Пользователя:
          </p>
          <p>7.2.1. стали публичным достоянием до их утраты или разглашения;</p>
          <p>
            7.2.2. были получены от третьей стороны до момента их получения ООО «МАГ Девелопмент»;
          </p>
          <p>7.2.3. были разглашены с согласия Пользователя.</p>
          <p>
            7.3. Все споры и разногласия, которые могут возникнуть из настоящей политики
            конфиденциальности и в связи с ней, будут по возможности разрешаться путем проведения
            дополнительных переговоров и консультаций между Сторонами. В случае если Стороны не
            смогут прийти к соглашению, возникающие споры, разногласия или требования, из настоящего
            документа или в связи с ним, в том числе, касающиеся его исполнения, нарушения,
            прекращения или недействительности, могут быть переданы на рассмотрение в суд по месту
            нахождения ООО «МАГ Девелопмент» с обязательным соблюдением претензионного порядка. Срок
            ответа на претензию составляет 15 (пятнадцать) рабочих дней с момента ее получения.
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>8. ПОДТВЕРЖДЕНИЕ СОГЛАШЕНИЯ.</strong>
          </p>
          <p>
            8.1. Пользователь вправе отказаться от подтверждения настоящей политики
            конфиденциальности, в случае если какое-либо условие является для Пользователя
            неприемлемым.
          </p>
          <p>
            8.2. Пользователь подтверждает, что его принятие Политики конфиденциальности (отметка
            чекбокса «Принимаю политику конфиденциальности») означает полное согласие Пользователя
            со всеми её условиями без исключения, а также гарантирует достоверность предоставленных
            им персональных данных.
          </p>
          <p>
            В том числе, Пользователь, принимая настоящую Политику конфиденциальности, дает свое
            согласие ООО «МАГ Девелопмент» на обработку своих персональных данных в целях и в
            объеме, указанных в разделе 3-4 настоящей Политики конфиденциальности.
          </p>
          <p>
            Указанное согласие Пользователя с условиями Политики конфиденциальности действует 1 год
            с автоматической пролонгацией, если оно не было отозвано в соответствии со статьей 9
            Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных».
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ.</strong>
          </p>
          <p>
            9.1. Изменения в настоящую Политику конфиденциальности вносятся без согласия
            Пользователя.
          </p>
          <p>
            9.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения в
            программе, если иное не предусмотрено новой редакцией Политики конфиденциальности.
          </p>
          <p>
            9.3. Все предложения, претензии, требования о прекращении обработки персональных данных
            или вопросы принимаются по адресу: 634057, г. Томск, пр. Мира, 20 или по адресу
            электронной почты:{' '}
            <span>
              <a href="mailto:maginfo@magdv.com" rel="nofollow">
                <u>maginfo@magdv.com</u>
              </a>
            </span>
            .
          </p>
        </div>
      </div>
    );
  }

  render(): ReactNode {
    return <Layout>{this.renderMain()}</Layout>;
  }
}

export default Privacy;
