import { DirectoryApi, DirectoryApiStore } from 'modules/common/store/DirectoryApiStore';

import TransportApi from '../api/TransportApi';
import { ITransport, ITransportPayload } from '../models/types';

const Api: DirectoryApi<ITransport, ITransportPayload> = {
  getEntity: TransportApi.getTransport,
  getEntityList: TransportApi.getTransportsList,
  search: TransportApi.searchTransports,
  createEntity: TransportApi.createTransport,
  editEntity: TransportApi.editTransport,
  deleteEntity: TransportApi.deleteTransport,
};
export class TransportStore extends DirectoryApiStore<ITransport, ITransportPayload> {
  constructor() {
    super(Api, '/directories/transports');
  }
}
