import React from 'react';

import { getOrderUrl } from 'modules/orders/components/OrdersList/helpers';
import { getAddressProp } from 'modules/orders/helpers/propHelper';
import { IOrder } from 'modules/orders/models/types';

interface IProps {
  order: IOrder;
  isShort?: boolean;
}

export const OrderTooltip = ({ order }: IProps) => {
  const getAddress = (): string => {
    const address = getAddressProp(order);
    return order && order[address] ? order[address] : null;
  };

  return (
    <div className="order-tooltip">
      <div className="order-tooltip__general">
        <p className="order-tooltip__date">
          {order.time_slot_start} – {order.time_slot_end}
        </p>
        <p className="order-tooltip__address">
          {order?.meta?.recipient_company_short_name && (
            <>
              <b>{order.meta.recipient_company_short_name}</b>,{' '}
            </>
          )}
          {getAddress()}
        </p>
      </div>

      <div className="order-tooltip__tasks">
        <p className="order-tooltip__list">
          <a className="order-tooltip__link" href={getOrderUrl(order)}>
            #{order.number}
          </a>
        </p>
      </div>
    </div>
  );
};
