import React, { ReactNode } from 'react';

import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { Redirect } from 'react-router-dom';

import img403 from 'img/403.svg?react';
import img404 from 'img/404.svg?react';
import img500 from 'img/500.svg?react';
import EmptyPage from 'modules/common/containers/EmptyPage';
import Layout from 'modules/common/containers/Layout';
import Loader from 'modules/common/containers/Loader';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

interface IErrorProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  history: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  location: any;
}

const iconMap = {
  '403': img403,
  '404': img404,
  '500': img500,
};

const Error = ({ location }: IErrorProps) => {
  const { core } = useStore();

  const getIcon = (state) => {
    if (state && state.status) {
      return iconMap[state.status];
    }
    return iconMap['404'];
  };

  const getErrorTitle = (prev, error) => {
    if (error.title) {
      return [...prev, error.title];
    }

    return prev;
  };

  const renderMain = (state): ReactNode => {
    return (
      <EmptyPage
        description={getDescription(state)}
        header={getHeader(state)}
        icon={getIcon(state)}
      />
    );
  };

  const getDescription = (state): string => {
    if (state && state.data && state.data.errors.length > 0) {
      const errorTitleList = state.data.errors.reduce(getErrorTitle, []);
      return errorTitleList.join('; ');
    }
    return '';
  };

  const getHeader = (state): string => {
    if (state && state.status) {
      return `${translate('error')} ${state.status}`;
    }
    return translate('somethingIsWrong');
  };

  if (location && location.state) {
    if (!core.accountIsLoading && !core.permissionsIsLoading) {
      return <Layout>{renderMain(location.state)}</Layout>;
    } else {
      return <Spin indicator={<Loader show />} spinning />;
    }
  }

  return <Redirect to="/" />;
};

export default observer(Error);
