import React from 'react';

import { observer } from 'mobx-react-lite';

import { formatCurrency } from 'helpers/currency';
import { translate } from 'modules/localization';
import { ITask } from 'modules/tasks/models/types';
import { getAmount, getSize } from 'modules/tasks/models/utils';
import { useStore } from 'services/store';

interface IProps {
  task: ITask;
}

const TaskSizes = ({ task }: IProps) => {
  const { routeGenerator, currency } = useStore();

  return (
    <div className="rg-task__sizes">
      {formatCurrency(getAmount(task), routeGenerator?.currency, currency.currencyList)}
      &nbsp;/&nbsp;
      {getSize('weight', task).toFixed(2)}&nbsp;{translate('weightUnit')}
      &nbsp;/&nbsp;
      {getSize('volume', task).toFixed(3)}&nbsp;{translate('lengthUnit')}
      <sup>3</sup>
    </div>
  );
};

export default observer(TaskSizes);
