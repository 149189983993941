import { DirectoryApi, DirectoryApiStore } from 'modules/common/store/DirectoryApiStore';
import ShopsApi from 'modules/shops/api/ShopsApi';

import { IShop, IShopForm } from '../models/types';

const Api: DirectoryApi<IShop, IShopForm> = {
  getEntity: ShopsApi.getShop,
  getEntityList: ShopsApi.getShopsList,
  search: ShopsApi.searchShopsList,
  createEntity: ShopsApi.createShop,
  editEntity: ShopsApi.editShop,
  deleteEntity: ShopsApi.deleteShop,
};
export class ShopsStore extends DirectoryApiStore<IShop, IShopForm> {
  constructor() {
    super(Api, '/directories/shops');
  }
}
