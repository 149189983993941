const getField = (fieldErrorList: Record<string, never>, valueList: Record<string, never>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (prev: any, valueKey: string) => {
    if (fieldErrorList[valueKey]) {
      return {
        ...prev,
        [valueKey]: {
          errors: [new Error(fieldErrorList[valueKey])],
          value: valueList[valueKey],
        },
      };
    }

    return prev;
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getFieldError = (prev: Record<string, never>, error: any): Record<string, never> => {
  return {
    ...prev,
    [error.key]: error.error,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFieldErrorList = (response): any => response.data.errors.reduce(getFieldError, {});

export const getFieldDataList = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response: any,
  valueList: Record<string, never>,
): Record<string, never> => {
  const fieldErrorList: Record<string, never> = getFieldErrorList(response);

  return Object.keys(valueList).reduce(getField(fieldErrorList, valueList), {});
};
