import React from 'react';

import { Checkbox, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { IAccountSystem } from 'modules/account-system/models/types';
import { translate } from 'modules/localization';

export const getColumns = (
  actionEdit: (guid: string, disabled: boolean) => void,
): ColumnsType<IAccountSystem> => {
  return [
    {
      dataIndex: 'warehouse',
      title: translate('warehouse'),
      key: 'warehouse',
      render: (warehouse) => (warehouse && warehouse.title ? warehouse.title : '—'),
    },
    {
      dataIndex: 'guid',
      title: 'guid',
      key: 'guid',
      render: (guid) => guid || '',
    },
    {
      dataIndex: 'shop',
      title: translate('shop'),
      key: 'shop',
      render: (shop) => (shop && shop.legal_name ? shop.legal_name : '—'),
    },
    {
      dataIndex: 'disabled',
      render: (disabled, item) => {
        const confirm = () => actionEdit(item.guid, !disabled);

        return (
          <Popconfirm title={translate('areYouSure')} onConfirm={confirm}>
            <Checkbox checked={!disabled} />
          </Popconfirm>
        );
      },
      title: translate('activity'),
      key: 'isActive',
      width: '10%',
    },
  ];
};
