import 'leaflet-routing-machine';

import React, { ReactNode } from 'react';

import L from 'leaflet';

type TRouteProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  leaflet: any;
  route;
  customColor?: string;
};

class MapRoute extends React.Component<TRouteProps> {
  static defaultProps = {
    leaflet: null,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  layer: any;

  drawRoute(route): void {
    const { leaflet } = this.props;

    this.layer = L.GeoJSON.geometryToLayer(route);

    if (this.layer) {
      this.props.customColor && this.layer.setStyle({ color: this.props.customColor });
      leaflet.addLayer(this.layer);
    }
  }

  render(): ReactNode {
    return null;
  }
  componentDidMount(): void {
    this.routeClean();
    this.renderRoute();
  }
  componentWillUnmount(): void {
    this.routeClean();
  }
  shouldComponentUpdate(nextProps: TRouteProps) {
    return nextProps.route !== this.props.route;
  }
  componentDidUpdate(): void {
    this.routeClean();
    this.renderRoute();
  }

  routeClean(): void {
    if (this.layer) {
      this.layer.remove();
    }
  }

  renderRoute(): void {
    const { leaflet, route } = this.props;
    if (leaflet) {
      this.drawRoute(route);
    }
  }
}

export default MapRoute;
