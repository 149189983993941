import React, { useEffect, useReducer } from 'react';

import {
  ActionType,
  INotification,
  TAction,
  TSetNotifyFunc,
} from 'modules/common/containers/Notify/types';

import Notification from './Notification';

interface IProps {
  setNotify: TSetNotifyFunc;
}

export const NotificationsManager = ({ setNotify }: IProps) => {
  const [state, dispatch] = useReducer<React.Reducer<INotification[], TAction>>(
    (notification, action) => {
      switch (action.type) {
        case 'ADD_NOTIFICATION':
          return [{ ...action.payload }, ...notification];
        case 'REMOVE_NOTIFICATION':
          return [...notification].filter((el) => el.id !== action.id);
        default:
          return notification;
      }
    },
    [],
  );

  useEffect(() => {
    setNotify(createNotification);
  }, [setNotify]);

  const createNotification = ({ ...params }: INotification) => {
    dispatch({
      type: ActionType.ADD,
      payload: {
        id: Math.random().toString(36).substring(2, 9),
        ...params,
      },
    });
  };

  return (
    <>
      {state.map(({ id, ...props }, index, array) => {
        return (
          <Notification
            key={id}
            onDelete={() =>
              dispatch({
                type: ActionType.REMOVE,
                id: id,
              })
            }
            lengthNotifications={array.length}
            onViewNotification={index < 6}
            indexNotification={index}
            {...props}
          />
        );
      })}
    </>
  );
};

export default NotificationsManager;
