import './style.less';

import React from 'react';

import classSet from 'helpers/classSet';
import { translate } from 'modules/localization';

interface IProps {
  isMap: boolean;
  setIsMap: (val: boolean) => void;
}

const Tabs = ({ isMap, setIsMap }: IProps) => {
  return (
    <div className="rg-tabs">
      <button
        className={classSet({
          'rg-tab': true,
          'rg-tab--active': !isMap,
        })}
        onClick={(): void => setIsMap(false)}
      >
        {translate('onTheList')}
      </button>
      <button
        className={classSet({
          'rg-tab': true,
          'rg-tab--active': isMap,
        })}
        onClick={(): void => setIsMap(true)}
      >
        {translate('onTheMap')}
      </button>
    </div>
  );
};

export default Tabs;
