import './style.less';

import React, { useEffect, useState } from 'react';

import { Button, Form, Modal } from 'antd';
import { Feature, Point } from 'geojson';
import HTTPStatus from 'http-status';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import { getFieldDataList, renderFields } from 'helpers/form';
import { ViewNotification } from 'modules/common/containers/Notify';
import { IPermission } from 'modules/core/models/types';
import { translate } from 'modules/localization';
import {
  getDataToSet,
  getEmptyWarehouseMarker,
  getFields,
  getFieldsList,
  prepareData,
} from 'modules/warehouses/components/Form/helpers';
import Map from 'modules/warehouses/components/Map';
import { routerStore, useStore } from 'services/store';

interface IProps {
  baseUrl: string;
  permissions: IPermission;
}

const WarehousesForm = (
  props: IProps &
    RouteComponentProps<{
      action: string;
      entityGuid: string;
    }>,
) => {
  const { warehouses } = useStore();

  const { match, baseUrl, location, permissions } = props;
  const { action, entityGuid } = match.params;
  const [warehouseMarker, setWarehouseMarker] = useState(getEmptyWarehouseMarker());
  const [form] = Form.useForm();

  useEffect(() => {
    if (entityGuid && action !== 'set') {
      warehouses.get(entityGuid).then((data) => {
        form.resetFields();
        form.setFieldsValue(getDataToSet(data));
        setWarehouseMarker(getDataToSet(data));
      });
    } else {
      form.resetFields();
      setWarehouseMarker(getEmptyWarehouseMarker());
    }
  }, [action]);

  const actionReset = () => {
    routerStore.replace(`${baseUrl}${location.search}`);
  };

  const handleReset = (e: React.MouseEvent) => {
    e.preventDefault();
    actionReset();
  };

  const handleResponse = (message: string) => (response) => {
    if (
      response &&
      response.data &&
      response.data.errors &&
      response.data.errors[0].key === 'address'
    ) {
      ViewNotification.warning({
        message: translate('warehouseCreatedWithNoCoords'),
      });
    } else {
      ViewNotification.success({ message });
    }
    actionReset();
  };

  const handleError = (values) => (response) => {
    if (HTTPStatus.BAD_REQUEST === response?.http?.status) {
      const fieldDataList = getFieldDataList(response, values);
      form.setFields(fieldDataList);
    }
  };

  const submitClickHandler = (e: React.MouseEvent): void => {
    e.preventDefault();
    form.submit();
  };

  const handleSubmit = (values) => {
    const data = prepareData({ ...values }, action === 'edit');

    if (action === 'edit' && entityGuid) {
      warehouses
        .edit(entityGuid, data)
        .then(handleResponse(translate('warehouseEdited')))
        .catch(handleError(values));
    } else {
      warehouses
        .create(data)
        .then(handleResponse(translate('warehouseCreated')))
        .catch(handleError(values));
    }
  };

  const renderFooter = () => {
    const isPermittedSubmit = permissions['warehouses.create'] && permissions['warehouses.update'];
    return (
      <>
        <Button htmlType="button" key="cancel" onClick={handleReset}>
          {translate('cancel')}
        </Button>
        {isPermittedSubmit ? (
          <Button
            htmlType="submit"
            key="save"
            onClick={submitClickHandler}
            type="primary"
            loading={warehouses.isLoadingForm}
          >
            {action === 'edit' ? translate('save') : translate('add')}
          </Button>
        ) : null}
      </>
    );
  };

  const handleAddressSet = (address: string) => {
    form.setFieldValue('address', address);
  };

  const handleMarkerCreate = (geoJSON: Feature<Point>) => {
    setWarehouseMarker({
      ...warehouseMarker,
      lat: geoJSON.geometry.coordinates[1],
      lon: geoJSON.geometry.coordinates[0],
    });
    form.setFieldValue('lat', geoJSON.geometry.coordinates[1]);
    form.setFieldValue('lon', geoJSON.geometry.coordinates[0]);
  };

  const handleLatChange = (lat) => {
    setWarehouseMarker({
      ...warehouseMarker,
      lat,
    });
  };

  const handleLonChange = (lon) => {
    setWarehouseMarker({
      ...warehouseMarker,
      lon,
    });
  };

  const isEdit = action === 'edit';
  const isView = !permissions['warehouses.update'];
  const isCreate = action === 'create';
  const fieldsData = getFields(isEdit, isView, isCreate, handleLatChange, handleLonChange);
  const fieldsList = getFieldsList(isEdit);

  return (
    <Modal
      centered
      className="dir-modal"
      footer={renderFooter()}
      maskClosable={false}
      onCancel={handleReset}
      onOk={handleSubmit}
      title={isEdit ? translate('warehouseEditTitle') : translate('warehouseCreateTitle')}
      open={isCreate || isEdit}
      width={null}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        autoComplete="off"
        preserve={false}
        className="warehouses-form"
      >
        <div className="warehouses-modal__content">
          <div className="warehouses-modal__form">
            <h2 className="warehouses-form__section-title">{translate('mainWarehouseSettings')}</h2>
            {renderFields(fieldsList, fieldsData)}
          </div>
          <div className="warehouses-modal__map">
            <Map
              handleAddressSet={handleAddressSet}
              handleMarkerCreate={handleMarkerCreate}
              isEdit
              warehouse={{ ...warehouseMarker, address: form.getFieldValue('address') }}
              form={form}
            />
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default withRouter(observer(WarehousesForm));
