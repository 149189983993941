import './style.less';

import React, { useEffect, useRef } from 'react';

import { Table } from 'antd';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';

import { IPagination } from 'api/types';
import { DEFAULT_PAGE_SIZES } from 'constants/index';
import { getEditUrl } from 'modules/directory/helpers';
import Header from 'modules/locations/components/Header';
import ServiceZonesEmptyList from 'modules/service-zones/components/ServiceZonesEmptyList';
import ServiceZonesFilter from 'modules/service-zones/components/ServiceZonesFilter';
import { getColumns } from 'modules/service-zones/components/ServiceZonesList/helpers';
import ServiceZonesMap from 'modules/service-zones/components/ServiceZonesMap';
import { IServiceZones } from 'modules/service-zones/models/types';
import { useStore } from 'services/store';

interface IParams {
  locationGuid: string;
}

const ServiceZonesList = ({ ...rest }: IServiceZones) => {
  const { serviceZones, router, core, locations } = useStore();

  const location = useLocation<Record<string, never>>();

  const listRef = useRef(null);
  const scrollIntoView = () => listRef.current.scrollIntoView({ behavior: 'smooth' });

  const { locationGuid } = useParams<IParams>();

  const loadContent = (): void => {
    serviceZones.getServiceZones();
  };

  const deleteItem = (guid: string): void => {
    serviceZones.deleteServiceZone(guid).then(() => loadContent());
  };

  const columns = getColumns(router.location.pathname, deleteItem, core.permissions);

  const updateList = ({ current, pageSize }: IPagination): void => {
    serviceZones.setFilter('current', current);
    serviceZones.setFilter('pageSize', pageSize);
    serviceZones.getServiceZones();
  };

  useEffect(() => {
    serviceZones.setFilter('locationGuid', locationGuid);
    loadContent();
  }, [locationGuid]);

  const { hoveredGuid } = serviceZones;

  const getRowHandler = (row: IServiceZones) => ({
    className: `service-zones__item ${row.disabled ? ' service-zones__item_disabled' : ''} ${
      row.guid === hoveredGuid ? ' service-zones__item_hovered' : ''
    }`,
    onClick: (e): void =>
      e.target.innerHTML === row.name &&
      router.push(getEditUrl(router.location.pathname, location, row)),
    onMouseEnter: (): void => {
      serviceZones.setHoveredCoveragesByZone(row.guid);
      serviceZones.setHoveredGuid(row.guid);
    },
    onMouseLeave: (): void => {
      serviceZones.setHoveredCoveragesByZone(null);
      serviceZones.setHoveredWarehouses(null);
      serviceZones.setHoveredGuid(null);
    },
  });

  return (
    <div className="locations-layout">
      <div className="locations-layout__content">
        <div ref={listRef} className="service-zones-list">
          <Header isInner />
          <ServiceZonesFilter />
          {serviceZones.serviceZonesList.length ? (
            <Table
              className="dir__list service-zones__list"
              dataSource={serviceZones.serviceZonesList}
              columns={columns}
              bordered={false}
              pagination={{
                ...serviceZones.filter,
                simple: core.isMobile,
                showSizeChanger: true,
                pageSizeOptions: DEFAULT_PAGE_SIZES,
                onChange: scrollIntoView,
              }}
              showHeader={false}
              rowKey="guid"
              loading={serviceZones.isLoadingServiceZonesList}
              onChange={updateList}
              onRow={getRowHandler}
              {...rest}
            />
          ) : (
            <ServiceZonesEmptyList />
          )}
        </div>
      </div>
      {!core.isMobile && (
        <div className="locations-layout__map">
          <ServiceZonesMap location={locations.viewLocation} />
        </div>
      )}
    </div>
  );
};

export default observer(ServiceZonesList);
