import React, { useState } from 'react';

import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import { translate } from 'modules/localization';
import TaskCancelForm from 'modules/orders/components/TaskCancelForm';
import { ITask } from 'modules/tasks/models/types';
import { useStore } from 'services/store';

interface IProps {
  task: ITask;
  onCancel: () => Promise<void>;
}

const TaskCanceller = ({ task, onCancel }: IProps) => {
  const { tasks } = useStore();

  const [isModalOpened, updateModalOpened] = useState(false);
  const openModal = (): void => updateModalOpened(true);
  const closeModal = (): void => updateModalOpened(false);

  const cancelTask = (taskGuid: string, resolution: string): void => {
    tasks.apiStore.cancelTask(taskGuid, resolution).then(onCancel);
  };

  const handleSubmit = (values, form: FormInstance) => {
    cancelTask(task.guid, values.resolution);
    form.resetFields();
    closeModal();
  };

  const handleCancel = (form: FormInstance) => {
    form.resetFields();
    closeModal();
  };

  const isTaskCanBeCanceled = task.status !== 'complete' && task.status !== 'canceled';

  return isTaskCanBeCanceled ? (
    <>
      <Link className="order-task__cancel" to="#" onClick={openModal}>
        {translate('cancel')}
      </Link>
      <TaskCancelForm
        isModalOpened={isModalOpened}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        tasksResolutions={tasks.tasksResolutions['canceled']}
      />
    </>
  ) : null;
};

export default observer(TaskCanceller);
